import { headers } from 'screens/client/CryptoBot/components/ControlPanel/libs/constants/constants'
import {
  IExchangeSettings,
  IChannelSettings,
  GlobalConfig,
  TChannels
} from 'screens/client/CryptoBot/components/ControlPanel/ControlPanel.types'
import { API } from 'services/api'
import { sendPatchRequest } from './sendPatchRequest'
import { sendPostRequest } from './sendPostRequest'

export async function sendInitialSettings(
  exchangeSettings: IExchangeSettings,
  channels: IChannelSettings[] | TChannels[],
  globalSettings: GlobalConfig,
  botId: string
) {
  const channelsPromises = channels.map(channel =>
    sendPostRequest(`${API.URL}/bot/${botId}/channels-settings`, channel, headers)
  )

  const globalPromises = [
    sendPostRequest(`${API.URL}/bot/${botId}/exchanges-settings`, exchangeSettings, headers),
    sendPatchRequest(`${API.URL}/bot/${botId}/settings`, globalSettings, headers)
  ]

  return await Promise.all([...channelsPromises, ...globalPromises])
}
