import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import TaskInfoComponent from './TaskInfo'
import { acceptTask, signTaskThunk } from 'screens/client/Web3/web3.thunk'
import { updateTaskInfo } from '../../../screens/client/Subcontract/components/CreateTask/CreateTask.thunk'
import { clearTask } from 'screens/client/Subcontract/Subcontract.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    loading: state.client.createTask.loading,
    tasks: state.client.subcontract.tasks,
    accessToken: state.auth.data ? state.auth.data.accessToken : null
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      clearTask,
      signTaskThunk,
      acceptTask,
      updateTaskInfo
    },
    dispatch
  )

export const TaskInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskInfoComponent)
