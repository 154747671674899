import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import CreateInvoiceComponent from './CreateInvoice'
import { getUsers, getRankPosition, setProjectInvoice } from './CreateInvoice.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    users: state.admin.currentTeam.members,
    rank: state.admin.getUser.options.rank,
    position: state.admin.getUser.options.positions,
    loading: state.admin.getUser.loading
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getUsers,
      getRankPosition,
      setProjectInvoice
    },
    dispatch
  )

export const CreateInvoice = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateInvoiceComponent)
