import Goods from '../../screens/client/Goods'
// import UserRating from '../../screens/client/UserRating'
import Information from '../../screens/client/Information'
import { UserRating } from '../../screens/client/Profile/components/UserRating'
// import Information from '../../screens/client/Information'
// import Basket from '../../screens/client/Basket'
import { Profile } from '../../screens/client/Profile'
import { NewRequestTimeOff } from '../../screens/client/NewRequestTimeOff'
// import Lottery from '../../screens/client/Lottery'
import { Achievements } from '../../screens/client/Achievements'
import { Users } from 'screens/admin/Users'
import { Settings } from 'screens/admin/Settings'
import TimeOffRequests from '../../screens/client/TimeOffRequests'
import { Basket } from '../../screens/client/Basket'
import { Shop } from 'screens/admin/Shop'
import { GoodsItem } from 'screens/admin/GoodsItem'
import { GoodsItemDetails } from '../../screens/client/GoodsItemDetails'
import { AchievementsAdm } from '../../screens/admin/AchievementsAdm'
import { AchievementItem } from 'screens/admin/AchievementsAdm/components/AchievementItem'
import { Requests } from 'screens/admin/Requests'
import { AchievementsItemDetails } from 'screens/client/AchievementsItemDetails'
import { EditProfile } from '../../screens/client/EditProfile'
import { Team } from '../../screens/client/Team'
import { ForeignProfile } from '../../screens/client/ForeignProfile'
import { TeamAdmin } from '../../screens/admin/Team'
import { Subcontract } from 'screens/client/Subcontract'
import { Task } from 'screens/client/Subcontract/components/Task'
import { TaskRequest } from '../../screens/client/TaskRequest'
import { CreateTask } from 'screens/client/Subcontract/components/CreateTask'
import { OfferTasks } from 'components/Subcontract/OfferTasks'
import { TaskPayment } from '../../screens/client/TaskPayment'
import { InterviewInvitation } from '../../screens/client/InterviewInvitation'
import { SubcontractTasks } from 'screens/admin/SubcontractTasks'
import { PlaningCalendar } from 'screens/client/Subcontract/components/PlaningCalendar'
import { Projects } from 'screens/admin/Projects'
import { Project } from 'screens/admin/Projects/components/Project'
import { ProjectHistory } from 'screens/admin/Projects/components/ProjectHistory'
import { Verification } from 'screens/client/Subcontract/components/Verification'
import { UserVerifiInfo } from 'screens/admin/SubcontractTasks/Verification/UserVerifiInfo'
import { VerificationAdmin } from 'screens/admin/SubcontractTasks/Verification'
import { CreateInvoice } from 'screens/admin/CreateInvoice'
import { ProjectInvoices } from 'screens/admin/ProjectInvoices'
import { Entrepreneurs } from '../../screens/admin/Entrepreneurs'
import { CreateInvoices } from 'screens/admin/Entrepreneurs/CreteInvoices'
import { CreateProject } from 'screens/admin/CreateProject'
import { Claims } from 'screens/client/Subcontract/components/Verification/components/Claims'
import { CryptoBot } from 'screens/client/CryptoBot'
import ProjectInvoicesTable from '../../screens/client/Subcontract/components/ProjectInvoicesTable/ProjectInvoicesTable'
import { Vacancies } from 'screens/admin/Vacancies'
// import Vacancies from '../../screens/admin/Vacancies/Vacancies'
import { CreateVacancy } from '../../screens/admin/Vacancies/CreateVacancy'
import { Vacancy } from '../../screens/admin/Vacancies/Vacancy'
import InvoiceModal from '../../components/ProjectModals/InvoiceModal'
import { SubcontractHistoryTransaction } from 'screens/client/Profile/components/SubcontractHistoryTransaction'
import { SettingsTaskCancellationInfo } from 'screens/admin/Settings/components/SettingsTaskCancellationInfo'
import { ChannelsSettings } from 'screens/client/CryptoBot/components/AdvancedBotSettings'
import { Policy } from 'screens/client/Policy'
import { Notifications } from 'screens/client/Notifications'
import { VotingList } from 'screens/client/VotingList'
import { VotingDetails } from 'screens/client/VotingList/components/VotingDetails'
import { Performers } from 'screens/client/Subcontract/components/Performers/Performers.container'

export type TRoutes = typeof adminRoutes | typeof clientRoutes
export const adminRoutes = [
  {
    path: ['/dashboard/requests'],
    exact: true,
    component: TimeOffRequests
  },
  {
    path: ['/dashboard/requests/create', '/dashboard/requests/:id/edit'],
    exact: true,
    component: NewRequestTimeOff
  },
  {
    path: '/dashboard/point-system/store',
    exact: true,
    component: Shop
  },
  {
    path: '/dashboard/point-system/store/create',
    exact: true,
    component: GoodsItem
  },
  {
    path: `/dashboard/point-system/store/:id/edit`,
    exact: true,
    component: GoodsItem
  },
  {
    path: '/dashboard/point-system/achievements',
    exact: true,
    component: AchievementsAdm
  },
  {
    path: [
      '/dashboard/point-system/achievements/create',
      `/dashboard/point-system/achievements/:id/edit`
    ],
    exact: true,
    component: AchievementItem
  },
  {
    path: '/dashboard/point-system/requests',
    exact: true,
    component: Requests
  },
  // {
  //   path: '/dashboard/point-system/history',
  //   exact: true,
  //   component: () => '/dashboard/point-system/history'
  // },
  {
    path: '/dashboard/settings',
    exact: true,
    component: Settings
  },
  {
    path: '/dashboard/users',
    exact: true,
    component: Users
  },
  {
    path: '/dashboard/faq',
    exact: true,
    component: Information
  },
  {
    path: '/dashboard/teamAdmin',
    exact: true,
    component: TeamAdmin
  },
  {
    path: '/dashboard/vacancies',
    exact: true,
    component: Vacancies
  },
  {
    path: ['/dashboard/vacancies/create', `/dashboard/vacancies/:id/edit`],
    exact: true,
    component: CreateVacancy
  },
  {
    path: '/dashboard/vacancies/:id',
    exact: true,
    component: Vacancy
  },
  {
    path: '/dashboard/foreign-profile',
    exact: true,
    component: ForeignProfile
  },
  {
    path: '/dashboard/profile/editprofile',
    exact: true,
    component: EditProfile
  },
  {
    path: '/dashboard/subcontract/verification',
    exact: true,
    component: VerificationAdmin
  },
  {
    path: '/dashboard/create-invoice',
    exact: true,
    component: CreateInvoice
  },
  {
    path: '/dashboard/projects/invoice/:id',
    exact: true,
    component: InvoiceModal
  },
  {
    path: '/dashboard/projects/invoices',
    exact: true,
    component: ProjectInvoices
  },
  {
    path: '/dashboard/entrepreneurs',
    exact: true,
    component: Entrepreneurs
  },
  {
    path: '/dashboard/entrepreneurs/create-invoices',
    exact: true,
    component: CreateInvoices
  },
  {
    path: '/dashboard/projects',
    exact: true,
    component: Projects
  },
  {
    path: '/dashboard/create-project',
    exact: true,
    component: CreateProject
  },
  {
    path: '/dashboard/projects/:id/edit',
    exact: true,
    component: CreateProject
  },
  {
    path: '/dashboard/subcontract',
    exact: true,
    component: SubcontractTasks
  },
  {
    path: '/dashboard/voting',
    exact: true,
    component: VotingList
  },
  {
    path: '/dashboard/projects/:id/history',
    exact: true,
    component: ProjectHistory
  },
  {
    path: '/dashboard/projects/:id',
    exact: true,
    component: Project
  },
  {
    path: '/dashboard/subcontract/verification/:id',
    exact: true,
    component: UserVerifiInfo
  },
  {
    path: '/dashboard/subcontract/:id',
    exact: true,
    component: Task
  },
  {
    path: '/dashboard/settings/task-cancellation/:id',
    exact: true,
    component: SettingsTaskCancellationInfo
  }
]

export const clientRoutes = [
  {
    path: '/dashboard/requests',
    exact: true,
    component: TimeOffRequests
  },
  {
    path: ['/dashboard/requests/create', '/dashboard/requests/:id/edit'],
    exact: true,
    component: NewRequestTimeOff
  },
  {
    path: '/dashboard/profile',
    exact: true,
    component: Profile
  },
  {
    path: '/dashboard/profile/editprofile',
    exact: true,
    component: EditProfile
  },
  {
    path: '/dashboard/point-system/profile/rating',
    exact: true,
    component: UserRating
  },
  {
    path: '/dashboard/point-system/store',
    exact: true,
    component: Goods
  },
  {
    path: '/dashboard/point-system/store/:id',
    exact: true,
    component: GoodsItemDetails
  },
  // {
  //   path: '/dashboard/point-system/store/item',
  //   // path: "/dashboard/point-system/store/:id", // todo - need to take product by id
  //   exact: true,
  //   component: () => 'Item GoodBoy'
  // },
  {
    path: '/dashboard/point-system/achievements',
    exact: true,
    component: Achievements
  },
  {
    path: '/dashboard/point-system/achievements/:id',
    exact: true,
    component: AchievementsItemDetails
  },
  {
    path: '/dashboard/point-system/basket',
    exact: true,
    component: Basket
  },
  {
    path: '/dashboard/point-system/notifications',
    exact: true,
    component: Notifications
  },
  // {
  //   path: '/dashboard/point-system/lottery',
  //   exact: true,
  //   component: Lottery
  // },
  {
    path: '/dashboard/faq',
    exact: true,
    component: Information
  },
  {
    path: '/dashboard/team',
    exact: true,
    component: Team
  },
  {
    path: '/dashboard/foreign-profile',
    exact: true,
    component: ForeignProfile
  },
  {
    path: '/dashboard/subcontract/profile/history-transaction',
    exact: true,
    component: SubcontractHistoryTransaction
  },
  {
    path: '/dashboard/Subcontract',
    exact: true,
    component: Subcontract
  },
  {
    path: '/dashboard/subcontract/planing-calendar',
    exact: true,
    component: PlaningCalendar
  },
  {
    path: '/dashboard/subcontract/verification',
    exact: true,
    component: Verification
  },
  {
    path: '/dashboard/subcontract/create-task',
    exact: false,
    component: CreateTask
  },
  {
    path: '/dashboard/subcontract/offer-task',
    exact: true,
    component: OfferTasks
  },
  {
    path: '/dashboard/subcontract/edittask',
    exact: false,
    component: CreateTask
  },
  {
    path: '/dashboard/subcontract/task-payment',
    exact: true,
    component: TaskPayment
  },
  {
    path: '/dashboard/subcontract/task-request',
    exact: true,
    component: TaskRequest
  },
  {
    path: '/dashboard/subcontract/interview-invitation',
    exact: true,
    component: InterviewInvitation
  },
  {
    path: '/dashboard/subcontract/verification/claims',
    exact: true,
    component: Claims
  },
  {
    path: '/dashboard/subcontract/voting',
    exact: true,
    component: VotingList
  },
  {
    path: '/dashboard/subcontract/voting/:id',
    exact: true,
    component: VotingDetails
  },
  {
    path: '/dashboard/cryptobot',
    exact: true,
    component: CryptoBot
  },
  {
    path: '/dashboard/terms-of-service',
    exact: true,
    component: Policy
  },
  {
    path: '/dashboard/privacy-policy',
    exact: true,
    component: Policy
  },
  {
    path: '/dashboard/cookie-policy',
    exact: true,
    component: Policy
  },
  {
    path: '/dashboard/subcontract/performers',
    exact: true,
    component: Performers
  },
  {
    path: '/dashboard/subcontract/invoices',
    exact: true,
    component: ProjectInvoicesTable
  },
  {
    path: '/dashboard/subcontract/:id',
    exact: true,
    component: Task
  },
  {
    path: '/dashboard/subcontract/invoices/:id',
    exact: true,
    component: ProjectInvoicesTable
  },
  {
    path: '/dashboard/channels-settings',
    exact: true,
    component: ChannelsSettings
  }
]
