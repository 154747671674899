/* eslint-disable no-console */
import { Dispatch } from 'redux'
import { API, api } from '../../../services/api'
import {
  updateUserRequest,
  updateUserSuccess,
  updateUserError,
  getPositions
} from './EditProfile.actions'
import toastr from '../../../utils/toastr'
import { TState } from 'store'
import { transformApiResToFabricFormat } from 'utils/transformApiResToFabricFormat'
import { bankValuesToWords } from 'utils/bankValuesToWords'
import { AxiosError } from 'axios'

export const updateUser = (body: any, infoType: string, isAdmin = false) => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  dispatch(updateUserRequest())
  const words = getData().global.language.words

  try {
    await api.put(`${API.URL}${isAdmin ? API.ADMIN_UPDATE : API.CLIENT_UPDATE}/${infoType}`, body)
    dispatch(updateUserSuccess())
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(updateUserError(err.response))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}

export const updateUserInfo = (user: any) => async (dispatch: Dispatch) => {
  try {
    dispatch(updateUserRequest())
    const { data } = await api.put(`${API.URL}/user/user-info/${user.id}`, user)

    toastr('success', data.message)
    dispatch(updateUserSuccess())
  } catch (error) {
    const err = error as AxiosError<any>

    if (err.response) {
      //@ts-ignore
      dispatch(updateUserError(err.response))
    }
    toastr('error', 'Failed to update user info')
  }
}

export const getSelectOptions = () => async (dispatch: Dispatch, getData: () => TState) => {
  const words = getData().global.language.words
  try {
    const positions = (await api.get(`${API.URL}${API.POSITIONS}`)).data.payload.result.map(
      (item: any) => transformApiResToFabricFormat(words, item)
    )

    const rank = (await api.get(`${API.URL}${API.RANK}`)).data.payload.result.map((item: any) =>
      transformApiResToFabricFormat(words, item)
    )

    const banksFromServer = (await api.get(`${API.URL}${API.BANKS}`)).data.payload.result
    const banksWords = bankValuesToWords(banksFromServer)
    const banks = banksFromServer.map((item: any) =>
      transformApiResToFabricFormat(banksWords, item)
    )
    banks.unshift({ value: null, label: words['user.editProfile.notSelected'] })

    const maritalStatus = (await api.get(
      `${API.URL}${API.MARITIAL_STATUS}`
    )).data.payload.result.map((item: any) => transformApiResToFabricFormat(words, item))
    maritalStatus.unshift({ value: null, label: words['user.editProfile.notSelected'] })

    const englishLevel = (await api.get(`${API.URL}${API.ENGLISH_LEVEL}`)).data.payload.result.map(
      (item: any) => transformApiResToFabricFormat(words, item)
    )

    const academicDegree = (await api.get(
      `${API.URL}${API.ACADEMIC_DEGREE}`
    )).data.payload.result.map((item: any) => transformApiResToFabricFormat(words, item))
    academicDegree.unshift({ value: null, label: words['user.editProfile.notSelected'] })

    const department = (await api.get(`${API.URL}${API.DEPARTMENT}`)).data.payload.result.map(
      (item: any) => transformApiResToFabricFormat(words, item)
    )

    const typeOfEmployment = (await api.get(
      `${API.URL}${API.TYPE_OF_EMPLOYMENT}`
    )).data.payload.result.map((item: any) => transformApiResToFabricFormat(words, item))
    dispatch(
      getPositions({
        positions,
        rank,
        banks,
        maritalStatus,
        englishLevel,
        academicDegree,
        department,
        typeOfEmployment
      })
    )
  } catch (error) {
    const err = error as AxiosError<any>
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}
