import React, { useRef } from 'react'
import styles from '../Table.module.scss'
import { Icons } from '../../../../../components/Icons'
import InvoiceModal from '../../../../../components/ProjectModals/InvoiceModal'
import { generatePDF } from '../../../../../components/Project/helpers/getPDF'

const DownloadInvoice = ({ invoice }: any) => {
  const downloadInvoiceRef = useRef<any>()

  const handleDownloadInvoice = () => {
    generatePDF(downloadInvoiceRef, invoice.id)
  }
  return (
    <div>
      <div
        onClick={() => handleDownloadInvoice()}
        className={`${styles['download']} ${styles['button']}`}
      >
        <Icons icon="download" />
      </div>
      <InvoiceModal ref={downloadInvoiceRef} invoice={invoice} isHidden={true} toggle={true} />
    </div>
  )
}

export default DownloadInvoice
