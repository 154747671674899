import React, { FC, useEffect } from 'react'
import styles from './style.module.scss'
import { TPolicyProps } from './Policy.model'
import Spinner from 'components/Spinner'

const Policy: FC<TPolicyProps> = ({ data, loading, history, getPolicy }) => {
  // const words = useSelector((state: TState) => state.global.language.words)
  // const [isChecked, setIsChecked] = useState<any>(false)
  console.log(history.location.pathname, data, loading)
  console.log(data.result ? data.result[0].link : null)
  useEffect(() => {
    getPolicy(history.location.pathname)
  }, [])

  return (
    <div className={styles.container}>
      {history.location.pathname === '/dashboard/terms-of-service' && !loading ? (
        <>
          <div className={styles.text}>
            <iframe srcDoc={data.result ? data.result[0].link : null} />
          </div>
        </>
      ) : history.location.pathname === '/dashboard/privacy-policy' && !loading ? (
        <>
          <div className={styles.text}>
            <iframe srcDoc={data.result ? data.result[0].link : null} />
          </div>
        </>
      ) : !loading ? (
        <>
          <div className={styles.text}>
            <iframe srcDoc={data.result ? data.result[0].link : null} />
          </div>
        </>
      ) : (
        <div className={styles.loader}>
          <Spinner />
        </div>
      )}
    </div>
  )
}

export default Policy
