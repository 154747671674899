import { IToken } from 'screens/admin/Token/Token.model'

export function toggleTokenActive(tokens: IToken[], tokenId: number): IToken[] {
  const tokenIndex = tokens.findIndex(token => token.id === tokenId)

  if (tokenIndex === -1) {
    return tokens
  }

  const updatedToken = {
    ...tokens[tokenIndex],
    isActive: !tokens[tokenIndex].isActive
  }
  const updatedTokens = [
    ...tokens.slice(0, tokenIndex),
    updatedToken,
    ...tokens.slice(tokenIndex + 1)
  ]

  return updatedTokens
}
