import { TBaseFilterConfigData } from 'components/UiKit/Filter/Filter'
import { TWords } from 'elements/SideBar/SideBar.config'

export const getFilterConfig = ({
  displayAllFields,
  words,
  inputCurrencyClass,
  inputTimeClass,
  currentPeriodFrom,
  currentPeriodTo
}: {
  displayAllFields: boolean
  words: TWords
  inputCurrencyClass: string
  inputTimeClass: string
  currentPeriodFrom?: string | (string | null)[] | null
  currentPeriodTo?: string | (string | null)[] | null
}): TBaseFilterConfigData[] => {
  const periodSelectData = [
    { value: '1', label: 'placeholder 1' },
    { value: '2', label: 'placeholder 2' },
    { value: '3', label: 'placeholder 3' },
    { value: '4', label: 'placeholder 4' },
    { value: '5', label: 'placeholder 5' },
    { value: '6', label: 'placeholder 6' },
    { value: '7', label: 'placeholder 7' },
    { value: '8', label: 'placeholder 8' },
    { value: '9', label: 'placeholder 9' },
    { value: '10', label: 'placeholder 10' },
    { value: '11', label: 'placeholder 11' },
    { value: '12', label: 'placeholder 12' },
    { value: '13', label: 'placeholder 13' }
  ]

  const periodMinIndex = periodSelectData.findIndex(elem => elem.value === currentPeriodFrom)
  const periodMaxIndex = periodSelectData.findIndex(elem => elem.value === currentPeriodTo)

  const filter: TBaseFilterConfigData[] = [
    {
      name: 'name',
      type: 'input',
      label: words['project.team.name'],
      width: '25%'
    },
    {
      name: 'specialization',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }],
      label: words['project.team.specialization'],
      placeholder: '',
      width: '25%'
    },
    {
      name: 'level',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }],
      label: words['project.team.level'],
      placeholder: '',
      width: '16%'
    },
    {
      name: 'location',
      type: 'input',
      fieldData: [{ value: '@ALL@', label: words['all'] }],
      label: words['project.team.location'],
      placeholder: '',
      width: '23%'
    }
  ]
  if (displayAllFields) {
    filter.push(
      {
        name: 'periodFrom',
        secondName: 'periodTo',
        type: 'selectRange',
        placeholder: '',
        fieldData: [{ value: '@ALL@', label: words['all'] }, ...periodSelectData],
        label: words['project.team.period'],
        rangeContainerWidth: '27%',
        width: '50%',
        selectRangeBoundsIndex: {
          min: periodMinIndex >= 0 ? periodMinIndex : null,
          max: periodMaxIndex >= 0 ? periodMaxIndex : null
        }
      },
      {
        name: 'type',
        type: 'select',
        fieldData: [{ value: '@ALL@', label: words['all'] }],
        label: words['project.team.type'],
        placeholder: '',
        width: '18%'
      },
      {
        name: 'fromHoursPerDay',
        secondName: 'toHoursPerDay',
        type: 'inputRange',
        inputType: 'number',
        label: words['project.team.hoursPerDay'],
        placeholder: '',
        inputContainerClass: inputTimeClass,
        rangeContainerWidth: '19%',
        fieldData: {
          minValue: '',
          maxValue: ''
        }
      },
      {
        name: 'fromPaymentLevel',
        secondName: 'toPaymentLevel',
        type: 'inputRange',
        inputType: 'number',
        label: words['project.team.paymentLevel'],
        placeholder: '',
        inputContainerClass: inputCurrencyClass,
        rangeContainerWidth: '25%',
        fieldData: {
          minValue: '',
          maxValue: ''
        }
      },
      {
        name: 'fromRate',
        secondName: 'toRate',
        type: 'inputRange',
        inputType: 'number',
        label: words['project.team.rate'],
        placeholder: '',
        inputContainerClass: inputCurrencyClass,
        rangeContainerWidth: '20%',
        fieldData: {
          minValue: '',
          maxValue: ''
        }
      }
    )
  }
  return filter
}
