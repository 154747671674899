import GraphComponent from 'components/GraphComponent/GraphComponent'
import { Filter } from 'components/UiKit/Filter'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getProfitGraph } from './ProfitGraph.filter.config'
import styles from './ProfitGraph.module.scss'
import { Table } from 'components/UiKit/Table'
import { formattedDate } from 'utils/formattedDate'
import { Columns } from './ProfitGraph.columns'
import { TProfitGraphProps } from './ProfitGraph.model'
import cls from 'classnames'
import Spinner from 'components/Spinner'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { api, API } from 'services/api'

const ProfitGraph: React.FC<TProfitGraphProps> = ({
  isLoading,
  graphStatistic: rawData,
  user,
  getGraphStatisticThunk,
  exchangeType
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const urlSearchParams = new URLSearchParams(window.location.search)
  const { startDate, endDate } = Object.fromEntries(urlSearchParams.entries())
  const params = { exchangeType, startDate, endDate }
  const [botId, setBotId] = useState<string | null>(null)

  const profitAmount = useMemo(() => {
    if (!rawData) {
      return
    }

    return rawData
      .reduce((init, current) => {
        if (current.profit !== null) {
          return init + Number(current.profit)
        }
        return init
      }, 0)
      .toFixed(4)
  }, [rawData])

  const formattedData = useMemo(() => {
    if (!rawData) {
      return
    }

    return rawData.map(data => ({
      date: formattedDate(data.date),
      profit: data.profit
        ? Number(data.profit) > 0
          ? `+${Number(data.profit).toFixed(2)} USDT`
          : `${Number(data.profit).toFixed(2)} USDT`
        : '0 USDT',
      dailyProfitNET: data.dailyProfitNET
        ? `${Number(data.dailyProfitNET).toFixed(2)} USDT`
        : '0 USDT',
      maxMarginUsed: `${Number(data.maxMarginUsed).toFixed(2) || 0}`
    }))
  }, [rawData])

  const getBotId = useCallback(async () => {
    try {
      const { data } = await api.get(`${API.URL}/crypto-bot/settings/${user.id}`)

      setBotId(data.bot_id)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getBotId()
  }, [])

  useEffect(() => {
    if (exchangeType && startDate && endDate && botId) {
      getGraphStatisticThunk(params, botId)
    }
  }, [exchangeType, startDate, endDate, botId])

  const columnsMemoized = useMemo(() => Columns(words), [])

  const redOrGreen = Number(profitAmount) > 0 ? 'green' : 'red'

  return (
    <>
      {!isLoading ? (
        <>
          <div className={styles.filterBlock}>
            <p className={styles.profitDesc}>
              <strong>{`${words['crypto-bot.profit']}: `}</strong>

              <span className={cls(styles.profitAmount, styles[redOrGreen])}>
                {Number(profitAmount) > 0 ? `+${profitAmount} USDT` : `${profitAmount} USDT`}
              </span>
            </p>

            <Filter
              config={getProfitGraph(words)}
              defaultOpened={true}
              withButton={false}
              onlyField={false}
            />
          </div>

          {rawData && (
            <div className={styles.graphWrapper}>
              <GraphComponent rawData={rawData} />
            </div>
          )}

          {formattedData && (
            <div className={styles.tableWrapper}>
              <Table
                columns={columnsMemoized}
                data={formattedData}
                total={0}
                getData={() => ({})}
                tableHeight="200px"
              />
            </div>
          )}
        </>
      ) : (
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      )}
    </>
  )
}

export default ProfitGraph
