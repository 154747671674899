import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import { BounceLoader } from 'components/BounceLoader/model'
import { ReactComponent as AddImgIcon } from 'assets/images/add_img_icon.svg'
import ReactCrop, { Crop } from 'react-image-crop'
import styles from './ImageUpload.module.scss'
import cn from 'classnames'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useSelector } from 'react-redux'
import { TState } from 'store'

const ImageUpload = ({ validation, avatar, setAvatar, projectSrc, isEdit }: any) => {
  const words = useSelector((state: TState) => state.global.language.words)

  const [isImageLoaded, setIsImageLoaded] = useState(true)
  const [crop, setCrop] = useState<Crop>()
  const [croppedImage, setCroppedImage] = useState<File[] | undefined | null>()
  const [src, setSrc] = useState<string | null | undefined | ArrayBuffer>()
  const [userFileProps, setUserFileProps] = useState({ name: 'name', type: 'image/jpg' })

  const loadImageAsBlob = async (url: string) => {
    const response = await fetch(url)
    const blob = await response.blob()
    return blob
  }

  const onSelectImage = ({ onChange }: any) => (e: any) => {
    const f = e.target.files[0]
    const newFiles = [...avatar]
    const blob = new Blob([f], { type: f.type })
    const url = URL.createObjectURL(blob)
    newFiles.push({ name: f.name, link: url, file: f })
    setAvatar(newFiles)

    if (f.type.startsWith('image/')) {
      const reader = new FileReader()
      reader.addEventListener('load', () => setSrc(reader.result))
      reader.readAsDataURL(f)
    } else {
      setSrc(null)
    }

    setUserFileProps({ name: f.name, type: f.type })
    onChange(e)
  }

  const getSrc = () => {
    return src || projectSrc
  }

  useEffect(() => {
    if (typeof src === 'string' && src.startsWith('http')) {
      setIsImageLoaded(false)
      loadImageAsBlob(src)
        .then(blob => {
          setIsImageLoaded(true)
          setSrc(URL.createObjectURL(blob))
        })
        .catch(error => {
          setIsImageLoaded(true)
          console.error(error)
        })
    }
  }, [src])

  const getCroppedImg = (image: any, imgCrop: any, fileName: any) => {
    const canvas: any = document.createElement('canvas')
    const scaleX: any = image.naturalWidth / image.width
    const scaleY: any = image.naturalHeight / image.height
    canvas.width = imgCrop.width
    canvas.height = imgCrop.height
    const ctx: any = canvas.getContext('2d')

    ctx.drawImage(
      image,
      imgCrop.x * scaleX,
      imgCrop.y * scaleY,
      imgCrop.width * scaleX,
      imgCrop.height * scaleY,
      0,
      0,
      imgCrop.width,
      imgCrop.height
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob: any) => {
          if (!blob) {
            reject(new Error('Canvas is empty'))
            return
          }
          blob.name = fileName
          resolve(blob)
        },
        userFileProps.type,
        1
      )
    })
  }

  const makeClientCrop = async (imgCrop: any) => {
    const img: any = document.querySelector('.ReactCrop__child-wrapper img')
    if (src && imgCrop.width && imgCrop.height) {
      const cropedImage: any = await getCroppedImg(img, imgCrop, userFileProps.name)
      setCroppedImage([new File([cropedImage], userFileProps.name, { type: cropedImage.type })])
    }
  }

  const onCropComplete = (imgCrop: any) => {
    makeClientCrop(imgCrop)
  }

  useEffect(() => {
    if (croppedImage && croppedImage.length > 0) {
      const f = croppedImage[0]
      const blob = new Blob([f], { type: f.type })
      const url = URL.createObjectURL(blob)
      setAvatar([{ name: f.name, link: url, file: f }])
    }
  }, [croppedImage])

  return (
    <Field
      name={'photo'}
      validate={
        !isEdit && validation.composeValidators(validation.required(words['user.requiredMessage']))
      }
      // validate={
      //   !isEdit &&
      //   validation.composeValidators(() =>
      //     validation.required(words['user.requiredMessage'])(croppedImage)
      //   )
      // }
      render={({ input, meta }) =>
        !isImageLoaded ? (
          <div
            className={cn({
              [styles.emptyPicture]: true,
              [styles.invalid]: meta.error && meta.touched
            })}
          >
            <BounceLoader />
          </div>
        ) : (
          <div
            className={cn({
              [styles.picture]: true,
              [styles.invalid]: meta.error && meta.touched && !meta.dirty
            })}
            data-tip=""
            data-for="photo"
            id="photoError"
          >
            <div className={styles.crop} />
            <div>
              <label htmlFor="photo" className={isImageLoaded ? styles.right : styles.center}>
                <AddImgIcon />
                <input {...input} id="photo" type="file" onChange={onSelectImage(input)} />
              </label>

              {meta.error && meta.submitFailed && !meta.dirty && (
                <ReactTooltip
                  id="photoError"
                  anchorSelect="#photoError"
                  place="top"
                  variant="error"
                  content={meta.error}
                />
              )}
            </div>
            <div className={styles.info}>
              <div className={styles.pictureInfo}>{`${
                words['admin.shop.new.imageFormat']
              }: JPEG, JPG, PNG`}</div>
              <div className={styles.pictureInfo}>{`${
                words['admin.shop.new.imageSize']
              }: 550 x 335 px`}</div>
              <div className={styles.pictureInfo}>{`${words['admin.shop.new.imageWeight']}: ${
                words['user.pointsSystem.market.table.to']
              } 10 MB`}</div>
            </div>
            <ReactCrop
              aspect={1 / 1.022641509}
              crop={crop}
              onChange={(newCrop: any) => {
                setCrop(newCrop)
              }}
              onComplete={onCropComplete}
            >
              <img src={getSrc()} />
            </ReactCrop>
          </div>
        )
      }
    />
  )
}
export default ImageUpload
