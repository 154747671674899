/* eslint-disable */
import { Column } from 'react-table'
import { TState } from '../../../../../store'
import { useSelector } from 'react-redux'
import DownloadInvoice from './DownloadInvoice/DownloadInvoice'
import React from 'react'
import styles from './InvoiceTable.module.scss'
import moment from 'moment'

export type TVacanciesData = {
  id: any
  period: any
  dateOfPayment: any
  task: any
  dateOfClosing: string
  location: string
  recruiter: string
  status: string
  englishLevel: any
  countOfHours: string
}

type TColumnsManagerOptions = {
  role: number
  isOnlyManager: boolean
}
type TColumnsManager = (options: TColumnsManagerOptions) => Array<Column<TVacanciesData>>

export const Columns: TColumnsManager = ({}) => {
  // const { userData } = useUser()
  const words = useSelector((state: TState) => state.global.language.words)
  console.log(words)
  const columnsManager: Array<Column<TVacanciesData>> = [
    {
      Header: '№ инвойса',
      id: 'id',
      accessor: d => String(d.id).padStart(6, '0'),
      minWidth: 210
    },
    {
      Header: 'Период',
      id: 'period',
      Cell: (d: any) => {
        if (d.row.original.task.deadline) {
          return (
            <span>
              {moment(d.row.original.task.startProjectDate).format('DD.MM.YY')}
              {' - '}
              {moment(d.row.original.task.deadline).format('DD.MM.YY')}
            </span>
          )
        } else {
          return <span>no terms</span>
        }
      },
      minWidth: 210
    },
    {
      Header: 'Дата оплаты',
      id: 'dateOfPayment',
      accessor: d => moment(d.dateOfPayment).format('DD.MM.YY'),
      minWidth: 210
    },
    {
      Header: 'Итоговая сумма',
      id: 'finalPrice',
      accessor: d => <span className={styles.invoiceTablePrice}>{d.task.price} $</span>,
      minWidth: 210
    },
    {
      Header: '',
      id: 'countOfHours',
      accessor: d => <DownloadInvoice d={d} />,
      minWidth: 210
    }
  ]

  return columnsManager
}
