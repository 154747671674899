import React, { FC, useEffect, useRef, useState } from 'react'
import cls from 'classnames'
import styles from './style.module.scss'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { Icons } from 'components/Icons'
import { useAuth } from 'hooks/auth.hook'

type TModalProps = {
  toggle: boolean
  action: any
  setAcception: any
  acception: any
  data?: any
  loading?: boolean
  policy: any
  setScroll?: any
  scrollDocument?: any
  findAcception: any
  comparePolicy: any
  setAcceptPolicyThunk: any
}

const PolicyModal: FC<TModalProps> = ({
  toggle,
  action,
  setAcception,
  acception,
  policy,
  findAcception,
  comparePolicy,
  setAcceptPolicyThunk
}) => {
  const [scrollDocument, setScroll] = useState(false)
  const { logoutWithRedirect } = useAuth()
  const frameRef: any = useRef()

  useEffect(() => {
    let scrollCheckings: any = {}
    scrollCheckings = setInterval(() => {
      if (frameRef.current) {
        const yl: any = document.getElementById('frameClient')
        // console.log(
        //   yl.clientHeight,
        //   frameRef.current.contentWindow.document.documentElement.scrollTop,
        //   frameRef.current.contentWindow.document.documentElement.scrollHeight,
        //   frameRef.current.contentWindow.document.documentElement.scrollHeight - yl.clientHeight,
        //   frameRef.current.contentWindow.document.documentElement.scrollTop >=
        //     frameRef.current.contentWindow.document.documentElement.scrollHeight - yl.clientHeight
        // )
        if (
          frameRef.current.contentWindow.document.documentElement.scrollTop >=
          frameRef.current.contentWindow.document.documentElement.scrollHeight - yl.clientHeight
        ) {
          setScroll(true)
          clearInterval(scrollCheckings)
        }
      }
    }, 1000)
    // }
  }, [frameRef, acception])

  const onCompared = () => {
    const awre = { ...acception, [findAcception()]: true }
    setScroll(false)
    if (Object.keys(acception).every(val => awre[val] === true)) {
      setAcceptPolicyThunk(true)
      action()
    }
  }

  return (
    <div>
      <div className={cls({ [styles.overlay]: toggle })} />
      <div className={cls({ [styles.container]: true, [styles.active]: toggle })}>
        <div className={styles.modal}>
          <Icons
            className={styles['close-cross']}
            icon={'close'}
            onClick={() => {
              localStorage.clear()
              logoutWithRedirect()
            }}
          />
          <div className={styles.text} id={'frameClient'}>
            <iframe
              srcDoc={policy.result && policy.result.length > 0 ? policy.result[0].link : null}
              ref={frameRef}
            />
          </div>
          <div className={styles['button-container']}>
            <BaseButton
              children="Back"
              size={'lgs'}
              onClick={e => {
                e.preventDefault()
                localStorage.clear()
                logoutWithRedirect()
              }}
            />
            <BaseButton
              children={!scrollDocument ? 'Scroll Down' : 'Accept'}
              outline={true}
              size={'lgs'}
              onClick={e => {
                e.preventDefault()
                if (!scrollDocument) {
                  frameRef.current.contentWindow.scrollTo({
                    top: frameRef.current.contentWindow.document.documentElement.scrollHeight,
                    behavior: 'smooth'
                  })
                } else {
                  setAcception({ ...acception, [findAcception()]: true })
                  comparePolicy(policy.result[0].link, onCompared)
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PolicyModal
