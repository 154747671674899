// import { AxiosError } from 'axios'
import { action } from 'store/store.utils'
import {
  GET_OPTIONS_REQUEST,
  GET_OPTIONS_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS
} from './CreateInvoice.constants'
import { TUsers, TOptions } from './CreateInvoice.model'

export const getUsersRequest = (): TUsers => action(GET_USER_REQUEST)
export const getUsersSuccess = (payload?: any): TUsers => action(GET_USER_SUCCESS, payload)
export const getOptionsRequest = (): TOptions => action(GET_OPTIONS_REQUEST)
export const getOptionsSuccess = (payload?: any): TOptions => action(GET_OPTIONS_SUCCESS, payload)
