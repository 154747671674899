import React, { useState } from 'react'
import DeleteIcon from '../../../../../src/assets/images/delete_icon.svg'
import styles from './DeleteMember.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from '../../../../store'
import { deleteMemberFromProject } from '../CurrentTeam.thunk'
import { useParams } from 'react-router'
const DeleteMember = ({ id }: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const words = useSelector((state: TState) => state.global.language.words)
  const dispatch = useDispatch()
  const params: any = useParams()
  const handleDeleteMember = () => {
    dispatch(deleteMemberFromProject(params.id, id))
  }
  return (
    <div onClick={(e: any) => e.stopPropagation()} className={styles.deleteButton}>
      <img onClick={() => setIsModalVisible(!isModalVisible)} src={DeleteIcon} alt="delete" />
      {isModalVisible && (
        <div className={styles.deleteModal}>
          <div className={styles.triangle} />
          <span className={styles.deleteModalTitle}>{words['entrepreneur.invoices.delete']}</span>
          <div className={styles.deleteModalButtons}>
            <span onClick={handleDeleteMember} className={styles.confirmButton}>
              Да
            </span>
            <span onClick={() => setIsModalVisible(false)} className={styles.rejectButton}>
              Нет
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default DeleteMember
