import React, { useEffect, useState } from 'react'
import styles from './ProjectHistory.module.scss'
import { TProjectHistory, TProjectHistoryProps } from './ProjectHistory.model'
import { Icons, TIcon } from 'components/Icons'
import { TState } from 'store'
import { useSelector } from 'react-redux'
import { Filter } from '../../../../../components/UiKit/Filter'
import cls from 'classnames'
import { getFilterConfig } from './ProjectHistory.filter.config'
import Collapsible from 'react-collapsible'
import { convertDate, transformFieldName } from 'helpers/helpers'
import {
  modelsData as models,
  stageData as stage,
  methodologyData as methodology,
  paymentMethodData as paymentMethod,
  unitData as unit
} from '../../../CreateProject/CreateProject'

const ProjectHistory = ({
  history,
  match,
  getProjectHistory,
  projectHistory,
  users,
  getUsers,
  managers,
  getManagers
}: TProjectHistoryProps) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const [openAccordionIndexes, setOpenAccordionIndexes] = useState<string[]>([])

  useEffect(() => {
    getProjectHistory(+match.params.id, location.search)
    getUsers()
    getManagers()
  }, [])

  useEffect(() => {
    getProjectHistory(+match.params.id, location.search)
  }, [location.search])

  const getCurrentUser = (author: number) => {
    const currentUser = users.find(user => user.id === author)
    return currentUser && currentUser.fullName
  }

  const handleAccordionClick = (index: string) => {
    if (openAccordionIndexes.includes(index)) {
      setOpenAccordionIndexes(openAccordionIndexes.filter(item => item !== index))
    } else {
      setOpenAccordionIndexes([...openAccordionIndexes, index])
    }
  }

  function highlightDifferences(before: string, after: string) {
    let diffEnd = 0

    while (
      diffEnd < after.length &&
      diffEnd < before.length &&
      after[diffEnd] === before[diffEnd]
    ) {
      diffEnd++
    }

    const highlightedBefore = before.substring(diffEnd, before.length)
    const highlightedAfter = after.substring(diffEnd, after.length)

    return { highlightedBefore, highlightedAfter }
  }

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>{words['project.history.title']}</h1>
      </section>
      <section>
        <div className={styles['block-go-back']}>
          <button
            type="button"
            className={styles['go-back']}
            onClick={event => {
              event.preventDefault()
              history.goBack()
            }}
          >
            <Icons icon="backArrowIcon" />
            <span className={styles['go-back-text']}>{words['back']}</span>
          </button>
        </div>
      </section>
      <section className={styles['filter']}>
        <Filter
          config={getFilterConfig({
            words
          })}
          isMultilineBtn={false}
          withButton={false}
          defaultOpened={true}
        />
      </section>
      <section className={styles['accordion-wrapper']}>
        <div className={styles['accordion-top-header']}>
          <span className={styles['accordion-top-header-item']}>
            {words['project.history.action']}
          </span>
          <span className={styles['accordion-top-header-item']}>
            {words['project.history.editor']}
          </span>
          <span className={styles['accordion-top-header-item']}>
            {words['project.history.changesDate']}
          </span>
        </div>
        <div className={styles['accordion-container']}>
          {projectHistory && projectHistory.length > 0 ? (
            projectHistory.map((item: TProjectHistory, i: number) => {
              return Object.entries(item.fields).map(([fieldName, fieldData]) => {
                const newObj = {
                  ...fieldData,
                  before: fieldData.before.value ? fieldData.before.label : fieldData.before,
                  after: fieldData.after.value ? fieldData.after.label : fieldData.after
                }
                switch (newObj.fieldName) {
                  case 'model':
                    newObj.before = models[fieldData.before - 1].label
                    break
                  case 'methodology':
                    newObj.before = methodology[fieldData.before - 1].label
                    break
                  case 'paymentMethod':
                    newObj.before = paymentMethod[fieldData.before - 1].label
                    break
                  case 'stage':
                    newObj.before = stage[fieldData.before - 1].label
                    break
                  case 'manager':
                    newObj.before =
                      managers.length &&
                      managers.find((manager: any) => +manager.value === fieldData.before).label
                    break
                  case 'customerAssistant':
                    newObj.before =
                      managers.length &&
                      managers.find((manager: any) => +manager.value === fieldData.before).label
                    break
                  case 'unit':
                    newObj.before = unit[fieldData.before - 1].label
                    break
                  default:
                    newObj.before = newObj.before
                    break
                }
                const accordionIndex = `${fieldName}_${i}`
                const isOpen = openAccordionIndexes.includes(accordionIndex)
                const iconArrow: TIcon = isOpen ? 'arrowUpBlue' : 'arrowDownBlue'
                const { highlightedBefore, highlightedAfter } = highlightDifferences(
                  newObj.before,
                  newObj.after
                )
                return (
                  <Collapsible
                    key={fieldName + i}
                    transitionTime={250}
                    trigger={
                      <div
                        className={cls({
                          [styles['accordion-header']]: true,
                          [styles['active']]: isOpen
                        })}
                        onClick={() => handleAccordionClick(accordionIndex)}
                      >
                        <span className={styles['accordion-header-item']}>
                          {item.changes === 'info'
                            ? words['project.history.info']
                            : words['project.history.team']}
                        </span>
                        <span className={styles['accordion-header-item']}>
                          {getCurrentUser(item.author)}
                        </span>
                        <span className={styles['accordion-header-item']}>
                          {convertDate(new Date(item.changesDate))}
                        </span>
                        <span className={styles['arrow']}>
                          <Icons icon={`${iconArrow}`} />
                        </span>
                      </div>
                    }
                  >
                    <div
                      className={cls({
                        [styles['accordion-content']]: true,
                        [styles['active']]: isOpen
                      })}
                    >
                      <div className={styles['accordion-field']}>
                        <span className={styles['accordion-mini-title']}>
                          {words['project.history.field']}:{' '}
                        </span>
                        {transformFieldName(fieldName, words)}
                      </div>
                      <div className={styles['accordion-changes']}>
                        <div className={styles['accordion-before']}>
                          <span className={styles['accordion-mini-title']}>
                            {words['project.history.before']}:{' '}
                          </span>
                          <span>
                            {newObj.before.substring(0, newObj.before.indexOf(highlightedBefore))}
                            <span className={styles['difference']}>
                              {fieldName === 'start' || fieldName === 'end'
                                ? convertDate(new Date(highlightedBefore))
                                : highlightedBefore}
                            </span>
                            {newObj.before.substring(
                              newObj.before.indexOf(highlightedBefore) + highlightedBefore.length
                            )}
                          </span>
                        </div>
                        <div className={styles['accordion-after']}>
                          <span className={styles['accordion-mini-title']}>
                            {words['project.history.after']}:{' '}
                          </span>
                          <span>
                            {newObj.after.substring(0, newObj.after.indexOf(highlightedAfter))}
                            <span className={styles['difference']}>
                              {fieldName === 'start' || fieldName === 'end'
                                ? convertDate(new Date(highlightedAfter))
                                : highlightedAfter}
                            </span>
                            {newObj.after.substring(
                              newObj.after.indexOf(highlightedAfter) + highlightedAfter.length
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Collapsible>
                )
              })
            })
          ) : (
            <div className={styles['empty']}>{words['noData']}</div>
          )}
        </div>
      </section>
    </div>
  )
}
export default ProjectHistory
