import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import ChannelsSettingsComponent from './ChannelsSettings'
import { getBotInfo, getBotSettings, saveSettings } from '../ControlPanel/ControlPanel.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    user: state.auth.data,
    botSettings: state.client.controlPanel.settings,
    botInfo: state.client.controlPanel.botInfo
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getBotSettings,
      saveSettings,
      getBotInfo
    },
    dispatch
  )

export const ChannelsSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelsSettingsComponent)
