/* eslint-disable no-console */
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Form, Field } from 'react-final-form'
import { Redirect } from 'react-router-dom'
import { TState } from 'store'
import { useSelector } from 'react-redux'
import 'react-image-crop/dist/ReactCrop.css'
import queryString from 'query-string'
import { EProfileHistory, ETransactionHistory, getToggleBarOptions } from 'globalConfigs'
import {
  InitialValues,
  ISocialNetworks,
  IUpdateUserPayload,
  IValues,
  TEditProfileProps
} from './EditProfile.model'
import styles from './EditProfile.module.scss'
import { Button } from 'components/UiKit/Button'
import { EditProfileModalAcceptChanges } from './components/EditProfileModalAcceptChanges'
import { ToggleBar, IToggleBarData } from 'components/ToggleBar'
import { EditCareer } from './components/EditCareer'
import { EditMainInfo } from './components/EditMainInfo'
import Checkbox from 'components/UiKit/Checkbox/Checkbox'
import moment from 'moment'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import { fullNameFieldsConfig, profileFieldsConfig } from './EditProfile.config'
import toastr from '../../../utils/toastr'
import cls from 'classnames'
import { ReactComponent as AddImgIcon } from 'assets/images/plus_grey.svg'
import { ReactComponent as EditAvatar } from 'assets/images/editBtn.svg'
import { EditPhotoModal } from './components/EditPhotoModal'
import Modal from 'components/Modal'
import { isAdmin as adminRole, isUser } from 'utils/user'
import { TimeOffBalance } from './components/TimeOffBalance'
import { formattingObjectValuesToNumber } from 'utils/formatting'
import { TBalance } from 'utils/balance'
import { getInitialValues } from './components/initialValues'
import { getProfileType, setDefaultMainToggleBarState } from 'utils/toggleBarsValue'
import { EditPointsSytem } from './components/EditPointsSystem'
import { TUpdatePointsBalanceBody } from 'screens/admin/Users/Users.model'
import { VerificationBlockPass } from '../../../components/Profile/VerificationBlockPass'

const EditProfile: FC<TEditProfileProps> = ({
  currentUser,
  role,
  loading,
  getUserProfileInfo,
  userInfo,
  updateUser,
  history,
  error,
  getSelectOptions,
  positions,
  rank,
  balance,
  updateCurrentTimeBalance,
  getBalance,
  updatePointsBalance,
  checkUserProfileSuccess,
  getProjectOptions
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const parsed = queryString.parse(history.location.search)
  const isAdmin = useMemo(() => adminRole(role), [role])
  const id: number = isUser(role) ? currentUser.id : Number(parsed.userId) || currentUser.id

  const [srcForView, setSrcForView] = useState<any>()
  const [croppedImage, setCroppedImage] = useState<any>()
  const [originalImage, setOriginalImage] = useState<any>(null)
  const [originalImageSrc, setOriginalImageSrc] = useState<any>(null)
  const [defaultImageLink, setDefaultImageLink] = useState<any>(null)
  const [photoFileName, setPhotoFileName] = useState<string>('')
  const [trackerStatus, setTrackerStatus] = useState(false)
  const [files, setFiles] = useState([])
  const [deletedFilesId, setDeletedFilesId] = useState([])

  const [openCropperModal, setOpenModal] = useState<boolean>(false)
  const [openLoadingModal, setOpenLoadingModal] = useState<boolean>(false)

  const toggleOpenModal = () => setOpenModal(!openCropperModal)
  const closeLoadingPopup = () => {
    checkUserProfileSuccess('completed')
    if (isUser(role)) {
      return history.push(
        `/dashboard/profile?profileType=${EProfileHistory.MAIN_INFO}&type=${
          ETransactionHistory.GOODS
        }&userId=${id}`
      )
    }
    return history.push(
      `/dashboard/foreign-profile?profileType=${EProfileHistory.MAIN_INFO}&type=${
        ETransactionHistory.GOODS
      }&userId=${id}`
    )
  }

  const setChangedImages = (options: any) => {
    setOriginalImage(options.originalFile)
    setOriginalImageSrc(options.originalImageSrc)
    setCroppedImage(options.croppedBlob)
    setSrcForView(URL.createObjectURL(options.croppedBlob))
    setPhotoFileName(options.fileName.replace(/ /g, '_'))
  }
  const isEdit = true
  const [selectedType, setSelectedType] = useState<IToggleBarData>(
    setDefaultMainToggleBarState(parsed.profileType, words, isEdit, isAdmin)
  )

  useEffect(() => {
    if (parsed.profileType) {
      getUserProfileInfo(id, String(parsed.profileType), isAdmin)
      getSelectOptions()
      getProjectOptions()
      if (isAdmin && selectedType.value === 'time-off-balance') {
        getBalance(String(id))
      }
    }
  }, [selectedType.value])

  const [openEditProfileAccessChangesModal, setOpenEditProfileAccessChangesModal] = useState<
    boolean
  >(false)
  const toggleEditProfileAccessChangesModal = () =>
    setOpenEditProfileAccessChangesModal(!openEditProfileAccessChangesModal)

  const userData: InitialValues = useMemo(() => {
    if (
      userInfo &&
      (selectedType.value === 'main-info' ||
        selectedType.value === 'career' ||
        selectedType.value === 'verification')
    ) {
      const newFile: any = []
      userInfo.userfiles &&
        userInfo.userfiles.length &&
        userInfo.userfiles.map((file: any) => {
          newFile.push({ id: file.id, name: file.name, link: file.link })
        })
      setFiles(newFile)
      return getInitialValues(userInfo, selectedType.value, isAdmin)
    }
    if (balance && selectedType.value === 'time-off-balance') {
      return getInitialValues(balance, selectedType.value, isAdmin, userInfo)
    }
  }, [userInfo])

  useEffect(() => {
    if (userData) {
      setSrcForView(userData.photo)
      setDefaultImageLink(userData.originalPhoto)
    }
  }, [userData])

  useEffect(() => {
    loadBlockpassWidget()
  }, [])

  const dontSave = () => {
    closeLoadingPopup()
  }

  const openModalHandler = () => {
    toggleOpenModal()
  }

  // eslint-disable-next-line no-console
  if (!parsed.profileType) {
    return (
      <Redirect
        to={{
          search: `profileType=${EProfileHistory.MAIN_INFO}&userId=${currentUser.id}`,
          pathname: history.location.pathname
        }}
      />
    )
  }

  const loadBlockpassWidget = () => {
    if (userInfo.verificationStatus === 'not_exist' && parsed.profileType === 'verification') {
      const blockpass = new window.BlockpassKYCConnect(
        'technorely_15e8a', // service client_id from the admin console
        {
          refId: currentUser.id // assign the local user_id of the connected user
        }
      )

      blockpass.startKYCConnect()

      blockpass.on('KYCConnectSuccess', () => {
        //add code that will trigger when data have been sent.
      })
    }
  }

  const deleteFile = (file: any, idx: number) => {
    const newFilesArr = [...files]
    newFilesArr.splice(idx, 1)
    setFiles(newFilesArr)

    if (file.id) {
      const newDeletedFilesId: any = [...deletedFilesId]
      newDeletedFilesId.push(file.id)
      setDeletedFilesId(newDeletedFilesId)
    }
  }

  const getUserInfo = () => {
    getUserProfileInfo(id, String(parsed.profileType), isAdmin)
  }

  return (
    <>
      <div className={styles['container']}>
        <section className={styles['header']}>
          <h1>{words['user.header.editProfile']}</h1>
        </section>

        <div className={styles['nav']}>
          <ToggleBar
            name="active-achievements-no-active"
            data={getToggleBarOptions(words, isEdit, isAdmin)}
            defaultChecked={selectedType}
            onChange={event => {
              history.push(
                `${history.location.pathname}?${queryString.stringify({
                  profileType: getProfileType(event.value),
                  userId: userInfo.id
                })}`
              )
              setSelectedType(event)
            }}
          />
        </div>

        <Form
          initialValues={userData}
          onSubmit={(values: any) => {
            console.log(values)
            if (id === null) {
              return
            }
            if (
              values &&
              values.amountOfChildren &&
              values.children &&
              Number(values.amountOfChildren) < values.children.length
            ) {
              values.children = values.children.slice(0, Number(values.amountOfChildren))
            }
            delete values.amountOfChildren
            const body: IUpdateUserPayload = {
              id,
              ...(values as IValues)
            }

            const getChildren = () => {
              if (body.children) {
                body.children = JSON.stringify(
                  body.children.map((item: any) => {
                    return moment(item).format('YYYY-MM-DD')
                  })
                )
              } else {
                body.children = ''
              }
              return body.children
            }
            const getSocialNetworks = (obj: ISocialNetworks) => {
              const keys = Object.keys(obj)
              keys.forEach(key => {
                obj[key] = obj[key].trim()
              })
              return JSON.stringify(obj)
            }
            const setRequestValue = (child: string | null) => {
              return child ? child.trim() : ''
            }
            setOpenLoadingModal(true)

            if (parsed.profileType === 'main-info') {
              const request = new FormData()
              if (body.fullName && body.fullName.trim().length > 0) {
                request.append('fullName', body.fullName.trim())
              }
              request.append('id', String(body.id))
              request.append(
                'position',
                body.position && body.position !== null ? String(body.position) : ''
              )
              request.append('rank', body.rank && body.rank !== null ? String(body.rank) : '')
              request.append('phoneNumber', body.phoneNumber ? body.phoneNumber : '')
              request.append(
                'dateOfBirth',
                body.dateOfBirth ? moment(body.dateOfBirth).format('YYYY-MM-DD') : ''
              )
              request.append('hometown', setRequestValue(body.hometown))
              request.append('city', setRequestValue(body.city))
              request.append(
                'socialNetworks',
                body.socialNetworks ? getSocialNetworks(body.socialNetworks) : ''
              )
              request.append('hobby', setRequestValue(body.hobby))
              request.append('lifeGoals', setRequestValue(body.lifeGoals))
              request.append('university', setRequestValue(body.university))
              request.append(
                'maritalStatus',
                body.maritalStatus !== '0' && body.maritalStatus !== null
                  ? String(body.maritalStatus)
                  : ''
              )
              request.append(
                'lifePriorities',
                body.lifePriorities === null ? '' : JSON.stringify(body.lifePriorities)
              )
              request.append('children', getChildren())
              request.append(
                'academicDegree',
                body.academicDegree !== '0' && body.academicDegree !== null
                  ? String(body.academicDegree)
                  : ''
              )
              request.append('bank', body.bank !== null ? String(body.bank) : '')
              request.append('hidden', JSON.stringify(body.hidden))
              request.append('anonymous', JSON.stringify(body.anonymous))
              if (croppedImage) {
                request.append(`photo`, croppedImage, `cropped_${photoFileName}`)
              }
              if (originalImage) {
                request.append(`originalPhoto`, originalImage, photoFileName)
              }
              if (files.length > 0) {
                files.map((item: any) => {
                  if (item.file) {
                    request.append(`userFiles`, item.file)
                  }
                })
              }
              if (deletedFilesId.length > 0) {
                request.append('deletedFilesList', JSON.stringify(deletedFilesId))
              }
              updateUser(request, parsed.profileType, !isUser(role))
            }
            if (parsed.profileType === 'career') {
              const request = new FormData()
              request.append('id', String(body.id))
              request.append('careerGoal', setRequestValue(body.careerGoal))
              request.append('technologies', setRequestValue(body.technologies))
              request.append('englishLevel', body.englishLevel ? String(body.englishLevel) : '')
              request.append('previousJob', setRequestValue(body.previousJob))
              if (isAdmin) {
                request.append('department', body.department ? String(body.department) : '')
                request.append('salary', body.salary ? String(body.salary) : '')
                request.append(
                  'typeOfEmployment',
                  body.typeOfEmployment ? String(body.typeOfEmployment) : ''
                )
                request.append(
                  'workingHoursPerDay',
                  body.workingHoursPerDay ? String(body.workingHoursPerDay) : ''
                )
                request.append(
                  'firstWorkingDay',
                  body.firstWorkingDay ? moment(body.firstWorkingDay).format('YYYY-MM-DD') : ''
                )
                request.append(
                  'probationEndDate',
                  body.probationEndDate ? moment(body.probationEndDate).format('YYYY-MM-DD') : ''
                )
                request.append(
                  'salaryChangeDate',
                  body.salaryChangeDate ? moment(body.salaryChangeDate).format('YYYY-MM-DD') : ''
                )
                request.append('projectsTeam', JSON.stringify(body.projectsTeam))
              }
              updateUser(request, parsed.profileType, !isUser(role))
            }
            if (selectedType.value === 'time-off-balance') {
              const timeOfBalanceBody = formattingObjectValuesToNumber<TBalance>(values as TBalance)
              updateCurrentTimeBalance(id, timeOfBalanceBody)
            }
            if (selectedType.value === 'points-system') {
              const bodyPS: TUpdatePointsBalanceBody = {
                transactions: [
                  {
                    receiverID: id,
                    amount: Number(values.points) || 0,
                    comment: values.comments || ''
                  }
                ]
              }
              updatePointsBalance(bodyPS)
            }
          }}
        >
          {({ form, handleSubmit }) => {
            const formState = form.getState()

            const checkRequired = () => {
              if (formState.errors && Object.keys(formState.errors).length !== 0) {
                handleSubmit()
                toastr('error', words['user.editProfile.fillRequiredFields'])
              } else {
                toggleEditProfileAccessChangesModal()
              }
            }
            return (
              <>
                <form onSubmit={handleSubmit} className={styles['main']} name="form">
                  {parsed.profileType === 'main-info' && (
                    <>
                      <section className={styles['avatar-block']}>
                        <img src={croppedImage && croppedImage.blob} />
                        <div
                          className={cls({
                            [styles.row]: true,
                            [styles.avatarRow]: true
                          })}
                        >
                          <div className={styles['avatarColumn']}>
                            <div
                              className={cls({
                                [styles.img]: true,
                                [styles.notImg]: !srcForView
                              })}
                              style={{
                                backgroundImage: `url(${srcForView})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                              }}
                            >
                              <div className={!srcForView ? styles.plus : styles.editBtn}>
                                {srcForView && (
                                  <EditAvatar
                                    style={{ cursor: 'pointer' }}
                                    onClick={openModalHandler}
                                  />
                                )}
                                {!srcForView && (
                                  <AddImgIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={openModalHandler}
                                  />
                                )}
                                {!srcForView && (
                                  <div className={styles.addFoto}>
                                    {words['user.editProfile.addPhoto']}
                                  </div>
                                )}
                              </div>
                            </div>
                            <FieldFactory
                              form={form}
                              config={fullNameFieldsConfig()}
                              words={words}
                            />
                          </div>
                          <div className={styles['visibilityColumn']}>
                            <div className={styles['anonymous']}>
                              <Field name={'anonymous'} type={'checkbox'}>
                                {({ input }) => {
                                  return (
                                    <Checkbox
                                      {...input}
                                      name={'anonymous'}
                                      label={words['user.editProfile.anonymous']}
                                      value={input.value}
                                    />
                                  )
                                }}
                              </Field>
                            </div>
                            <div className={styles['hidden']}>
                              <Field name={'hidden'} type={'checkbox'}>
                                {({ input }) => {
                                  return (
                                    <Checkbox
                                      {...input}
                                      name={'hidden'}
                                      label={words['user.editProfile.hidden']}
                                      value={input.value}
                                    />
                                  )
                                }}
                              </Field>
                            </div>
                          </div>
                        </div>
                        <FieldFactory
                          form={form}
                          config={profileFieldsConfig(words, {
                            positions,
                            rank
                          })}
                          words={words}
                        />
                      </section>
                      <EditMainInfo
                        form={form}
                        isAdmin={isAdmin}
                        files={files}
                        setFiles={setFiles}
                        deleteFile={deleteFile}
                      />
                    </>
                  )}
                  {parsed.profileType === 'career' && (
                    <EditCareer userInfo={userInfo} form={form} isAdmin={isAdmin} />
                  )}
                  {parsed.profileType === 'verification' && (
                    <div className={styles.verificationContainer}>
                      <VerificationBlockPass
                        status={userInfo.verificationStatus}
                        id={userInfo.id}
                        getUserInfo={getUserInfo}
                      />
                    </div>
                  )}
                  {parsed.profileType === 'time-off-balance' && (
                    <TimeOffBalance
                      form={form}
                      isAdmin={isAdmin}
                      trackerStatus={trackerStatus}
                      setTrackerStatus={setTrackerStatus}
                      user={userInfo}
                    />
                  )}
                  {parsed.profileType === 'points-system' && (
                    <EditPointsSytem
                      form={form}
                      isAdmin={isAdmin}
                      user={userInfo}
                      formState={formState}
                    />
                  )}

                  <div className={styles['btnContainerClass']}>
                    <Button
                      outline={true}
                      onClick={() => {
                        dontSave()
                      }}
                    >
                      <span>{words['user.profile.savePopup.cancel']}</span>
                    </Button>
                    <Button disabled={loading} onClick={checkRequired}>
                      <span>{words['user.profile.savePopup.saveBtn']}</span>
                    </Button>
                  </div>
                </form>
                <EditProfileModalAcceptChanges
                  open={openEditProfileAccessChangesModal}
                  toggleEditProfileAccessChangesModal={toggleEditProfileAccessChangesModal}
                  dontSave={dontSave}
                  save={handleSubmit}
                />
                <EditPhotoModal
                  open={openCropperModal}
                  photo={originalImageSrc || defaultImageLink}
                  closeCropperModal={() => {
                    setOpenModal(false)
                  }}
                  save={setChangedImages}
                />
                <Modal
                  isShow={openLoadingModal}
                  onClose={() => {
                    if (!loading) closeLoadingPopup()
                  }}
                  className={styles['loading-modal']}
                >
                  {loading && !error && <p>{words['user.profile.saveDataPopup.loading']}</p>}
                  {!loading && !error && (
                    <div className={styles['data-saved-container']}>
                      <p>{words['user.profile.saveDataPopup.success']}</p>
                      <div>
                        <Button onClick={closeLoadingPopup}>
                          <span>Ok</span>
                        </Button>
                      </div>
                    </div>
                  )}
                </Modal>
              </>
            )
          }}
        </Form>
      </div>
    </>
  )
}

export default EditProfile
