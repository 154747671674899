import { TSelectOption } from '../../../components/Select'
import { TBaseFilterConfigData } from '../../../components/UiKit/Filter/Filter'
import { TWords } from 'elements/SideBar/SideBar.config'

export const getFilterUsers = (
  words: TWords,
  {
    positions = [],
    rank = []
  }: {
    positions: TSelectOption[]
    rank: TSelectOption[]
  }
): TBaseFilterConfigData[] => {
  return [
    {
      name: 'fullName',
      type: 'input',
      label: words['admin.users.table.filters.userName'],
      placeholder: words['user.dayOffTracker.enterSurnameAndName'],
      width: '31%'
    },
    {
      name: 'position',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...positions],
      label: words['user.profile.career.position'],
      placeholder: '',
      width: '31%'
    },
    {
      name: 'rank',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...rank],
      label: words['user.profile.career.level'],
      placeholder: '',
      width: '19%'
    }
  ]
}
