import React from 'react'
import { TableSortHeader } from 'components/UiKit/Table'
import { Column } from 'react-table'
import { FormatCell } from 'components/UiKit/Table/components/FormatCell'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export type TTeamData = {
  name: string
  specialization: string
  level: string
  location: string
  period: string
  type: string
  hoursPerDay: number
  payment: number
  rate: number
  invoivePosition: string
  invoiveLevel: string
}

export const Columns = (formatCellClass: string, formatHeaderClass: string) => {
  const currentLang = localStorage.getItem('TechnorelyLanguage')
  const words = useSelector((state: TState) => state.global.language.words)
  const columns: Array<Column<TTeamData>> = [
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>{words['project.team.name']}</TableSortHeader>
      ),
      id: 'name',
      accessor: 'name',
      minWidth: 240
    },
    {
      Header: words['project.team.specialization'],
      id: 'specialization',
      accessor: 'specialization',
      minWidth: 245
    },
    {
      Header: words['project.team.level'],
      id: 'level',
      accessor: 'level',
      minWidth: 115
    },
    {
      Header: words['project.team.location'],
      id: 'location',
      accessor: 'location',
      minWidth: 120
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>{words['project.team.period']}</TableSortHeader>
      ),
      id: 'period',
      accessor: 'period',
      minWidth: 165
    },
    {
      Header: words['project.team.type'],
      id: 'type',
      accessor: 'type',
      minWidth: 155
    },
    {
      Header: ({ column }) => (
        <div className={formatHeaderClass}>
          <TableSortHeader id={column.id}>{words['project.team.hoursPerDay']}</TableSortHeader>
        </div>
      ),
      id: 'hoursPerDay',
      Cell: (cellInfo: any) => {
        return (
          <FormatCell
            content={`${cellInfo.row.original.hoursPerDay} ${currentLang === 'ru' ? 'ч.' : 'h.'}`}
            customClass={formatCellClass}
          />
        )
      },
      minWidth: 205
    },
    {
      Header: ({ column }) => (
        <div className={formatHeaderClass}>
          <TableSortHeader id={column.id}>{words['project.team.paymentLevel']}</TableSortHeader>
        </div>
      ),
      id: 'payment',
      Cell: (cellInfo: any) => (
        <FormatCell content={`${cellInfo.row.original.payment} $`} customClass={formatCellClass} />
      ),
      minWidth: 195
    },
    {
      Header: ({ column }) => (
        <div className={`${formatHeaderClass}  last_format_header`}>
          <TableSortHeader id={column.id}>{words['project.team.rate']}</TableSortHeader>
        </div>
      ),
      id: 'rate',
      Cell: (cellInfo: any) => (
        <FormatCell
          content={`${cellInfo.row.original.rate} $`}
          customClass={`${formatCellClass} last_format_cell`}
        />
      ),
      minWidth: 170
    },
    {
      Header: words['project.team.positionOnInvoice'],
      id: 'invoivePosition',
      accessor: 'invoivePosition',
      minWidth: 260
    },
    {
      Header: words['project.team.levelOnInvoice'],
      id: 'invoiveLevel',
      accessor: 'invoiveLevel',
      minWidth: 150
    }
  ]

  return columns
}
