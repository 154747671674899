import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { getAllVoting } from './VotingList.thunk'
import { VotingList as VotingListComponent } from './VotingList'
import { TState } from 'store'

export const mapStateToProps = (state: TState) => {
  return { votingList: state.client.voting.votingList, loading: state.client.voting.loading }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAllVoting
    },
    dispatch
  )

export const VotingList = connect(
  mapStateToProps,
  mapDispatchToProps
)(VotingListComponent)
