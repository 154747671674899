import React from 'react'
import styles from './InputInvoice.module.scss'
// import { Field } from 'react-final-form'
// import { Input } from 'components/UiKit/Inputs'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import { FormApi } from 'final-form'

interface IInputInvoice {
  name: string
  form: FormApi
  label: string
  type: string
  require: boolean
  placeholder?: string
  className?: string
}

const InputInvoice = ({ name, form, label, type, require, placeholder = '' }: IInputInvoice) => {
  const words = useSelector((state: TState) => state.global.language.words)
  return (
    <FieldFactory
      className={styles['input-factory']}
      form={form}
      config={[
        {
          items: [
            {
              name: name,
              label: label,
              required: require,
              placeholder: placeholder,
              inputWrapperClassName: styles['input-warp'],
              component: () => {
                return {
                  type: type,
                  props: {
                    variant: 'outlined'
                  }
                }
              }
            }
          ]
        }
      ]}
      words={words}
    />
  )
}
export default InputInvoice
