import { api } from 'services/api'
import { API } from '../../../services/api'
import {
  getRequestsError,
  getRequestsRequest,
  getRequestsSuccess,
  requestsActionError,
  requestsActionRequest,
  requestsActionSuccess
} from './Requests.actions'
import { Dispatch } from 'redux'
import toastr from '../../../utils/toastr'
import { TActionResponse, TRequectBodyItem } from './Requests.model'
import { TState } from 'store'
import { AxiosError } from 'axios'

export const getRequests = (page: number, search: string = '') => async (dispatch: Dispatch) => {
  dispatch(getRequestsRequest())
  try {
    // TODO -- will remove limit=1000 when fixed database bugs
    // const { data } = await api.get(`${API.REQUEST_USER}${search}&page=${page}`)
    const { data } = await api.get(`${API.REQUEST_USER}${search}&page=${page}&limit=1000`)
    dispatch(getRequestsSuccess(data.payload))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(getRequestsError(err))
  }
}

export const actionForRequests = (body: TRequectBodyItem, type: 'approve' | 'reject') => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(requestsActionRequest())
  const words = getData().global.language.words

  try {
    const response = await api.put(API.REQUEST_ADMIN, body)
    dispatch(requestsActionSuccess())
    const data: TActionResponse = response.data
    const responseElement = data.payload
    const errorElement =
      responseElement[0] &&
      responseElement[0].result &&
      responseElement[0].result.find(el => el.message !== 'ok')

    if (responseElement[0].message) {
      toastr('error', words['AVAILABLE_BALANCE_EXCEEDED'])
    } else if (errorElement) {
      if (errorElement.message.match(/not found request/)) {
        toastr('error', words['RESOURSE_IS_NOT_FOUND'])
      } else if (errorElement.message.match(/alredy handeled request/)) {
        toastr('error', words['REQUEST_ALREADY_PROCESSED_EARLIER'])
      } else {
        toastr('error', words['FILED_PROCESS_REQUEST'])
      }
    } else {
      toastr('success', type === 'approve' ? words['SUCCESS_APPROVED'] : words['SUCCESS_REJECTED'])
    }
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(requestsActionError(err))
    toastr('error', words['FILED_PROCESS_REQUEST'], err)
  }
}
