import React, { FC, useEffect, useState } from 'react'
import BackButton from 'components/BackButton/BackButton'
import cls from 'classnames'
import styles from './VotingDetails.module.scss'
import { VotingStatusBar } from './components/VotingStatusBar'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { TVotingDetailsProps } from './VotingDetails.model'
import { TYPE_OF_CURRENCY } from 'globalConfigs'
import moment from 'moment'
import { categoryOptions } from 'components/Subcontract/SubcontractTasksFilter/SubcontractTasksFilter'
import { countPercent } from './libs/helpers/countPercent'
import { VotingModal } from '../VotingModal/VotingModal'
import { TComment, TCommentType } from '../VotingModal/VotingModal.model'
import { UserAvatar } from 'components/UserAvatar'
import { useParams } from 'react-router'
import { TVoice } from '../../VotingList.model'
import { handleComment } from './libs/helpers/handleComment'

const VotingDetailsComponent: FC<TVotingDetailsProps> = ({ voting, getVoting, vote }) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const { id } = useParams<{ id: string }>()
  const [isShowModal, setIsShowModal] = useState(false)
  const [modalData, setModalData] = useState<TComment>()
  const customerPercent = voting ? countPercent(voting.customerVoices, voting.executorVoices) : 0
  const executorPercent = voting ? countPercent(voting.executorVoices, voting.customerVoices) : 0

  useEffect(() => {
    getVoting(+id)
    selectComment('customer')
  }, [id])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!voting) {
      return
    }
    vote(voting.id, event.target.value as TVoice)
  }

  const selectComment = (type: TCommentType) => {
    if (!voting) {
      return
    }

    setModalData({
      type: type,
      desc:
        type === 'customer'
          ? voting.task.taskCancellation.commentFromCustomer
          : voting.task.taskCancellation.commentFromContractor,
      name: type === 'customer' ? voting.task.customer.fullName : voting.task.executor.fullName,
      avatar: type === 'customer' ? voting.task.customer.photo : voting.task.executor.photo,
      percent:
        type === 'customer'
          ? voting.task.taskCancellation.refundCustomerPercent
          : voting.task.taskCancellation.refundContractorPercent
    })
  }

  const openModal = () => {
    setIsShowModal(true)
  }

  const closeModal = () => {
    setIsShowModal(false)
  }
  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <h1>{words['user.voting.jobCenter']}</h1>
      </div>
      {voting && voting.id === +id && (
        <>
          <div className={styles['content']}>
            <BackButton />
            <div className={cls(styles['desc'], styles['block'])}>
              <div className={styles['desc-body']}>
                <div>{voting.task.description}</div>
              </div>
              <div className={styles['desc-footer']}>
                <div className={styles['category-wrapper']}>
                  <div className={styles['category']}>
                    <div className={styles['category-label']}>
                      {words['user.subcontract.header.taskType']}:
                    </div>
                    <div className={styles['value']}>
                      {voting.task.type && categoryOptions[+voting.task.type].label}
                    </div>
                  </div>
                  <div className={styles['category']}>
                    <div className={styles['category-label']}>
                      {words['user.voting.endOfVoting']}:
                    </div>
                    <div className={styles['value']}>
                      {moment(voting.endOfVoting).format(`DD.MM.YYYY | HH:MM`)}
                    </div>
                  </div>
                  <div className={styles['category']}>
                    <div className={styles['category-label']}>
                      {words['user.subcontract.task.budget']}:
                    </div>
                    <div className={cls(styles['value'], styles['value-price'])}>
                      {voting.task.price} {TYPE_OF_CURRENCY[+voting.task.currency]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles['votingWrapper']}>
              <div className={styles['comments']}>
                {voting.task.taskCancellation.commentFromCustomer && (
                  <div
                    className={cls(styles['block'], styles['comment'])}
                    onClick={() => {
                      selectComment('customer')
                      openModal()
                    }}
                  >
                    <div className={styles['header']}>
                      <h2 className={styles['subtitle']}>{words['user.voting.customerComment']}</h2>
                      <div className={styles['avatar-wrapper']}>
                        <UserAvatar
                          className={styles['avatar']}
                          photo={voting.task.customer.photo}
                        />
                        <div className={styles['name']}>{voting.task.customer.fullName}</div>
                      </div>
                    </div>
                    <div className={styles['comment-desc']}>
                      {handleComment(voting.task.taskCancellation.commentFromCustomer)}
                    </div>
                    <div className={styles['budget']}>
                      <h3 className={styles['title']}>{words['user.voting.budgetAllocation']}:</h3>
                      <div className={styles['customer']}>
                        <div className={styles['category-label']}>
                          {words['user.subcontract.task.customer']}:
                        </div>
                        <div className={styles['value']}>
                          {voting.task.taskCancellation.refundCustomerPercent}%
                        </div>
                      </div>
                      <div className={styles['executor']}>
                        <div className={styles['category-label']}>
                          {words['user.taskPayment.performer']}:
                        </div>
                        <div className={styles['value']}>
                          {100 - voting.task.taskCancellation.refundCustomerPercent}%
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {voting.task.taskCancellation.commentFromContractor && (
                  <div
                    className={cls(styles['block'], styles['comment'])}
                    onClick={() => {
                      selectComment('executor')
                      openModal()
                    }}
                  >
                    <div className={styles['header']}>
                      <h2 className={styles['subtitle']}>
                        {words['user.voting.performerComment']}
                      </h2>
                      <div className={styles['avatar-wrapper']}>
                        <UserAvatar
                          className={styles['avatar']}
                          photo={voting.task.executor.photo}
                        />
                        <div className={styles['name']}>{voting.task.executor.fullName}</div>
                      </div>
                    </div>
                    <div className={styles['comment-desc']}>
                      {handleComment(voting.task.taskCancellation.commentFromContractor)}
                    </div>
                    <div className={styles['budget']}>
                      <h3 className={styles['title']}>{words['user.voting.budgetAllocation']}:</h3>
                      <div className={styles['customer']}>
                        <div className={styles['category-label']}>
                          {words['user.subcontract.task.customer']}:
                        </div>
                        <div className={styles['value']}>
                          {100 - voting.task.taskCancellation.refundContractorPercent}%
                        </div>
                      </div>
                      <div className={styles['executor']}>
                        <div className={styles['category-label']}>
                          {words['user.taskPayment.performer']}:
                        </div>
                        <div className={styles['value']}>
                          {voting.task.taskCancellation.refundContractorPercent}%
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={cls(styles['block'], styles['voting'])}>
                <div className={styles['header']}>
                  <h2 className={styles['subtitle']}>{words['user.voting.voting']}</h2>
                </div>
                <div className={styles['percents']}>
                  {voting.isVoted ? (
                    <>
                      <div className={styles['customer']}>
                        <div className={styles['avatar-wrapper']}>
                          <UserAvatar
                            className={styles['avatar']}
                            photo={voting.task.customer.photo}
                          />
                          <div className={styles['name']}>{voting.task.customer.fullName}</div>
                        </div>
                        <div className={styles['value']}>{customerPercent}%</div>
                        <VotingStatusBar color="red" percent={customerPercent} />
                      </div>
                      <div className={styles['executor']}>
                        <div className={styles['avatar-wrapper']}>
                          <UserAvatar
                            className={styles['avatar']}
                            photo={voting.task.executor.photo}
                          />
                          <div className={styles['name']}>{voting.task.executor.fullName}</div>
                        </div>
                        <div className={styles['value']}>{executorPercent}%</div>
                        <VotingStatusBar color="green" percent={executorPercent} />
                      </div>
                    </>
                  ) : (
                    <FormControl>
                      <RadioGroup
                        name="voting"
                        className={styles['radio-group']}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="customer"
                          className={styles['radio-label']}
                          control={<Radio />}
                          label={
                            <div className={styles['avatar-wrapper']}>
                              <UserAvatar
                                className={styles['avatar']}
                                photo={voting.task.customer.photo}
                              />
                              <div className={styles['name']}>{voting.task.customer.fullName}</div>
                            </div>
                          }
                        />
                        <FormControlLabel
                          value="executor"
                          className={styles['radio-label']}
                          control={<Radio />}
                          label={
                            <div className={styles['avatar-wrapper']}>
                              <UserAvatar
                                className={styles['avatar']}
                                photo={voting.task.executor.photo}
                              />
                              <div className={styles['name']}>{voting.task.executor.fullName}</div>
                            </div>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </div>
              </div>
            </div>
          </div>
          {modalData && (
            <VotingModal isShow={isShowModal} onClose={closeModal} comment={modalData} />
          )}
        </>
      )}
    </div>
  )
}

export { VotingDetailsComponent }
