/* eslint-disable no-console */
import { Dispatch } from 'redux'
import { API, api } from '../../../services/api'

// import toastr from '../../../utils/toastr'
import { AxiosError } from 'axios'
import {
  deleteInvoiceRequest,
  deleteInvoiceSuccess,
  getInvoicesRequest,
  getInvoicesSuccess,
  getProjectsRequest,
  getProjectsSuccess
} from './ProjectInvoices.actions'
// import { transformApiResToFabricFormat } from 'utils/transformApiResToFabricFormat'
// import { TState } from 'store'

export function getInvoices(search: string = '') {
  return async function(dispatch: Dispatch) {
    dispatch(getInvoicesRequest())
    try {
      const resp = await api.get(`${API.URL}/project-invoice${search}${search ? '&' : '?'}`)
      dispatch(getInvoicesSuccess(resp.data.payload.results))
    } catch (error) {
      const err = error as AxiosError<any>
      console.log(err)
    }

    return
  }
}

export function getProjects() {
  return async function(dispatch: Dispatch) {
    dispatch(getProjectsRequest())
    try {
      const resp = await api.get(`${API.URL}/projects`)
      // const result = resp.data.payload.results.map((project: any) => {
      //   return { name: project.name, id: project.id }
      // })
      dispatch(getProjectsSuccess(resp))
      // return resp.data.payload.results
    } catch (error) {
      const err = error as AxiosError<any>
      console.log(err)
    }

    return
  }
}

export const removeInvoice = (id: number) => {
  return async function(dispatch: Dispatch) {
    dispatch(deleteInvoiceRequest())
    try {
      await api.delete(`${API.URL}/project-invoice/${id}`)
      dispatch(deleteInvoiceSuccess(id))
    } catch (error) {
      console.log(error)
    }
  }
}
