import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form } from 'react-final-form'
import { TState } from 'store'
import moment from 'moment'
import toastr from 'utils/toastr'
import queryString from 'query-string'
import { TInterviewInvitationProps } from './InterviewInvitation.model'
import TimePick from './components/TimePick/TimePick'
import Calendar from './components/Calendar/Calendar'
import PurchaseModal from 'components/SubcontractModals/PurchaseModal'
import { Button } from 'components/UiKit/Button'
import { Icons } from '../../../components/Icons'
import { Textarea } from '../../../components/Textarea'
import { setData } from './InterviewInvitation.actions'
import styles from './InterviewInvitation.module.scss'
import { formatDateAndTime } from 'helpers/formatDateAndTime'

const InterviewInvitation: FC<TInterviewInvitationProps> = ({
  history,
  time,
  data,
  sendInterviewInvitation,
  userEmail,
  getTime,
  invitedUser,
  addEventToGoogleCalendar,
  getInvitedUser
}) => {
  const [value, setValue] = useState<Date | null | undefined | [Date | null, Date | null]>(
    new Date()
  )
  const [modalState, setModalState] = useState(false)
  const words = useSelector((state: TState) => state.global.language.words)
  const dispatch = useDispatch()

  const parsedQuery = queryString.parse(history.location.search)
  const type = parsedQuery.type
  const taskId = parsedQuery.taskId
  const userId = parsedQuery.userId
  const formatDate = moment(value as Date).format('DD.MM.YYYY')
  const currentTimeData = time[formatDate]

  const timeData = {
    id: formatDate,
    email: userEmail,
    date: moment(value as Date).format('MM/DD/YYYY'),
    from: '10:00',
    to: '20:00',
    timezone: moment.tz.guess(true)
  }

  useEffect(() => {
    if (!(formatDate in time)) {
      getTime(timeData)
    }
  }, [value])

  useEffect(() => {
    const parse = data.every((item: any) => {
      return item.date !== formatDate
    })
    if (parse) {
      dispatch(setData([...data, { date: formatDate, time: [], suggestedDate: value }]))
    }
  }, [value])

  useEffect(() => {
    getInvitedUser(Number(userId))
  }, [userId])

  function openModal() {
    setModalState(!modalState)
  }

  return (
    <>
      <div className={styles.container}>
        <header className={styles.header}>
          <h1>{words['user.header.interviewInvitation']}</h1>
        </header>
        {type === 'request' && (
          <section className={styles.requestNav}>
            <div className={styles.wrapper}>
              <button onClick={() => history.goBack()}>
                <Icons icon={'arrowLeftWithStick'} />
                {words['back']}
              </button>
              <div className={styles.info}>
                <span>{words['user.interviewInvitation.available']} </span>
                <span>{5} </span>
                <span>{words['user.interviewInvitation.invitations']}</span>
              </div>
            </div>
          </section>
        )}
        {type === 'response' && (
          <section className={styles.responseNav}>
            <div className={styles.wrapper}>
              <button onClick={() => history.goBack()}>
                <Icons icon={'arrowLeftWithStick'} />
                {words['back']}
              </button>
            </div>
          </section>
        )}
        <Form
          onSubmit={(values: any) => {
            const suggestedDates: any[] = []
            data.forEach((item: any) => {
              if (item.time.length !== 0)
                item.time.forEach((i: any) => {
                  const splitTime = i.value.split(':')
                  const formatSuggestedDate = moment()
                    .set({
                      year: moment(item.suggestedDate).year(),
                      month: moment(item.suggestedDate).month(),
                      date: moment(item.suggestedDate).date(),
                      hour: splitTime[0],
                      minute: splitTime[1],
                      second: 0
                    })
                    .format()
                  suggestedDates.push(formatSuggestedDate)
                })

              return false
            })

            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

            const googleEvent = {
              timeZone,
              title: 'Interview',
              date: formatDateAndTime(suggestedDates[0]).date,
              from: formatDateAndTime(suggestedDates[0]).currentTime,
              to: formatDateAndTime(suggestedDates[1]).currentTime,
              attendees: [userEmail, invitedUser]
            }

            addEventToGoogleCalendar(googleEvent)

            sendInterviewInvitation({
              suggestedDates,
              taskId: taskId ? +taskId : 0,
              description: values.comment ? values.comment : 'No comment',
              invitedId: userId ? +userId : 0
            })
          }}
        >
          {({ form, handleSubmit }) => {
            const formState = form.getState()
            const checkRequired = () => {
              if (formState.errors && Object.keys(formState.errors).length !== 0) {
                toastr('error', 'Fill required fields!')
              } else {
                handleSubmit()
              }
            }
            return (
              <main className={styles.main}>
                <form onSubmit={handleSubmit} name="form">
                  {type === 'request' && (
                    <section className={styles.requestForm}>
                      <div className={styles.columns}>
                        <div className={styles.column}>
                          <div className={styles.date}>
                            <h2>1. {words['user.interviewInvitation.selectDates']}</h2>
                            <Calendar value={value} onChange={setValue} />
                          </div>
                        </div>
                        <div className={styles.column}>
                          <div className={styles.time}>
                            <h2>2. {words['user.interviewInvitation.pickTime']}</h2>
                            <TimePick time={currentTimeData} data={data} date={formatDate} />
                          </div>
                        </div>
                        <div className={styles.column}>
                          <div className={styles.invite}>
                            <h2>3. {words['user.interviewInvitation.sendInvite']}</h2>
                            <div className={styles.board}>
                              {data &&
                                data.map((i: any) => {
                                  return (
                                    <>
                                      {i.time.length > 0 && (
                                        <div className={styles.row}>
                                          <div className={styles.line}>
                                            <div className={styles.horizontal} />
                                          </div>
                                          <div className={styles.date}>{i.date}</div>

                                          <div className={styles.time}>
                                            {i.time.map((item: any) => {
                                              return (
                                                <div key={item.key} className={styles.item}>
                                                  <span>{item.value}</span>
                                                </div>
                                              )
                                            })}
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )
                                })}
                            </div>
                          </div>
                          <div className={styles.message}>
                            <div className={styles.label}>
                              <label>{words['user.interviewInvitation.comment']}</label>
                            </div>
                            <Field name="comment">
                              {({ input, meta }) => (
                                <Textarea
                                  {...input}
                                  isInvalid={meta.error && meta.submitFailed}
                                  errorMessage={meta.error}
                                  size={'xs'}
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                  {type === 'response' && (
                    <section className={styles.responseForm}>
                      <div className={styles.columns}>
                        <div className={styles.column}>
                          <div className={styles.date}>
                            <h2>1. {words['user.interviewInvitation.selectDates']}</h2>
                            <Calendar value={value} onChange={setValue} />
                          </div>
                        </div>
                        <div className={styles.column}>
                          <div className={styles.time}>
                            <h2>2. {words['user.interviewInvitation.pickTime']}</h2>
                            <TimePick time={currentTimeData} data={data} date={formatDate} />
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                  {type === 'request' && (
                    <section className={styles.requestButtons}>
                      <div className={styles.interviewsButton}>
                        <div className={styles.interviewsAmount}>
                          <span>{words['user.interviewInvitation.availableInvitations']}: </span>
                          <span>{5}</span>
                        </div>
                        <Button size={'md'} color={'green'} onClick={openModal}>
                          <span>{words['user.subcontract.buy']}</span>
                        </Button>
                      </div>
                      <div className={styles.actionsButton}>
                        <Button outline={true} size={'md'} onClick={() => history.goBack()}>
                          <span>{words['user.subcontract.cancel']}</span>
                        </Button>
                        <Button onClick={checkRequired} size={'md'}>
                          <span>{words['user.interviewInvitation.invite']}</span>
                        </Button>
                      </div>
                    </section>
                  )}
                  {type === 'response' && (
                    <section className={styles.responseButtons}>
                      <div className={styles.suggestTime}>
                        <div
                          className={styles.btn}
                          onClick={() =>
                            history.push(
                              `/dashboard/subcontract/interview-invitation?type=request&taskId=${taskId}&userId=${userId}`
                            )
                          }
                        >
                          <span>{words['user.interviewInvitation.suggestTime']}</span>
                        </div>
                      </div>
                      <div className={styles.actionsButton}>
                        <Button outline={true} size={'md'} onClick={() => history.goBack()}>
                          <span>{words['user.subcontract.reject']}</span>
                        </Button>
                        <Button onClick={checkRequired} size={'md'}>
                          <span>{words['user.interviewInvitation.confirm']}</span>
                        </Button>
                      </div>
                    </section>
                  )}
                </form>
              </main>
            )
          }}
        </Form>
        <PurchaseModal toggle={modalState} action={openModal} type={'interview'} />
      </div>
    </>
  )
}

export default InterviewInvitation
