import { AxiosError } from 'axios'
import { action } from 'store/store.utils'
import {
  GET_TASK_REQUEST,
  GET_TASK_SUCCESS,
  GET_TASK_ERROR,
  CLEAR_TASK_LIST,
  CLEAR_ONE_TASK,
  GET_USERS,
  GET_ONE_TASK,
  UPDATE_SUCCES,
  CLEAR_USERS_LIST,
  CLEAR_ALL_TASKS,
  CHANGE_TASK_STATUS,
  GET_PROPOSAL,
  GET_PROPOSAL_REQUEST,
  GET_INVOICE_SUCCESS,
  CLEAR_INVOICE,
  GET_CANCELLED_TASKS_REQUEST,
  GET_CANCELLED_TASKS_SUCCESS
} from './Subcontract.constants'
import { ICancelledTasks, TAllTasksList } from './Subcontract.model'

export const getTaskRequest = (payload?: any): TAllTasksList => action(GET_TASK_REQUEST, payload)

export const getTaskSuccess = (payload: any): TAllTasksList => action(GET_TASK_SUCCESS, payload)

export const getTaskError = (payload: AxiosError): TAllTasksList => action(GET_TASK_ERROR, payload)

export const clearTaskList = (): TAllTasksList => action(CLEAR_TASK_LIST)

export const clearOneTask = (): TAllTasksList => action(CLEAR_ONE_TASK)

export const clearAllTasks = (): TAllTasksList => action(CLEAR_ALL_TASKS)

export const clearUsersList = (): TAllTasksList => action(CLEAR_USERS_LIST)

export const getUsersSuccess = (payload: any): TAllTasksList => action(GET_USERS, payload)

export const getOneTaskSuccess = (payload: any): TAllTasksList => action(GET_ONE_TASK, payload)

export const updateTaskSuccess = (payload: any): TAllTasksList => action(UPDATE_SUCCES, payload)

export const changeTaskStatus = (payload: any): TAllTasksList => action(CHANGE_TASK_STATUS, payload)

export const getProposal = (payload: any): TAllTasksList => action(GET_PROPOSAL, payload)

export const getProposalRequest = (): TAllTasksList => action(GET_PROPOSAL_REQUEST)
export const getInvoicesSuccess = (payload: any) => action(GET_INVOICE_SUCCESS, payload)

export const clearInvoiceSuccess = () => action(CLEAR_INVOICE)

export const getCancelledTasksRequest = () => action(GET_CANCELLED_TASKS_REQUEST)
export const getCancelledTasksSuccess = (payload: ICancelledTasks) =>
  action(GET_CANCELLED_TASKS_SUCCESS, payload)
