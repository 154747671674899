import React, { FC, HTMLAttributes, ReactNode } from 'react'
import { ReactComponent as Add } from 'assets/images/plus.svg'
import { ReactComponent as CryptoBot } from 'assets/images/crypto-bot.svg'
import { ReactComponent as Remove } from 'assets/images/minus.svg'
import { ReactComponent as ArrowDown } from 'assets/images/arrow_icon.svg'
import { ReactComponent as ArrowSort } from 'assets/images/arrow_sort_icon.svg'
import { ReactComponent as Basket } from 'assets/images/basket_icon.svg'
import { ReactComponent as Discharge } from 'assets/images/cancel_icon.svg'
import { ReactComponent as Purchases } from 'assets/images/purchases.svg'
import { ReactComponent as Close } from 'assets/images/close.svg'
import { ReactComponent as DischargeOutline } from 'assets/images/discharge_outline.svg'
import { ReactComponent as DischargeOutlineDisabled } from 'assets/images/discharge_outline_disabled.svg'
import { ReactComponent as Ellipsis } from 'assets/images/ellipsis_icon.svg'
import { ReactComponent as Exit } from 'assets/images/exit_icon.svg'
import { ReactComponent as Shop } from 'assets/images/goods_icon.svg'
import { ReactComponent as Logo } from 'assets/images/logo.svg'
import { ReactComponent as LogoSmall } from 'assets/images/logo-small.svg'
import { ReactComponent as Prev } from 'assets/images/prev.svg'
import { ReactComponent as Next } from 'assets/images/next.svg'
import { ReactComponent as NormalDischarge } from 'assets/images/normalDischarge.svg'
import { ReactComponent as PlusIcon } from 'assets/images/plus_icon.svg'
import { ReactComponent as Point } from 'assets/images/point.svg'
import { ReactComponent as PointIcon } from 'assets/images/point_icon.svg'
import { ReactComponent as ProfileIcon } from 'assets/images/profile_icon.svg'
import { ReactComponent as RequestsUserIcon } from 'assets/images/requests_user_icon.svg'
import { ReactComponent as RouletteIcon } from 'assets/images/roulette_icon.svg'
import { ReactComponent as TcentIcon } from 'assets/images/tcent_icon.svg'
import { ReactComponent as TimeOffRequests } from 'assets/images/time-off-requests.svg'
import { ReactComponent as TimeIcon } from 'assets/images/time_icon.svg'
import { ReactComponent as TimeIconDisable } from 'assets/images/time_icon_disable.svg'
import { ReactComponent as Settings } from 'assets/images/settings.svg'
import { ReactComponent as Users } from 'assets/images/users.svg'
import { ReactComponent as UpdateIcon } from 'assets/images/update_icon.svg'
import { ReactComponent as DoubleArrow } from 'assets/images/doubleArrow.svg'
import { ReactComponent as LogoCollapsed } from 'assets/images/logo_collapsed.svg'
import { ReactComponent as Profile } from 'assets/images/profile.svg'
import { ReactComponent as RestoreIcon } from 'assets/images/restore_icon.svg'
import { ReactComponent as UpdateDisabledIcon } from 'assets/images/update_disabled_icon.svg'
import { ReactComponent as ArrowLeftIcon } from 'assets/images/arrow_left_icon.svg'
import { ReactComponent as ArrowRightIcon } from 'assets/images/arrow_right_icon.svg'
import { ReactComponent as PointEditIcon } from 'assets/images/point_edit_icon.svg'
import { ReactComponent as BackArrowIcon } from 'assets/images/back_arrow_icon.svg'
import { ReactComponent as ButtonFilterIcon } from 'assets/images/icon_button_filter.svg'
import { ReactComponent as Filter } from 'assets/images/filter_normal_icon.svg'
import { ReactComponent as FilterDisabled } from 'assets/images/filter_disabled_icon.svg'
import { ReactComponent as Comment } from 'assets/images/icon_table_comment.svg'
import { ReactComponent as CommentDisabled } from 'assets/images/icon_table_comment_disabled.svg'
import { ReactComponent as CloseTable } from 'assets/images/icon_table_close.svg'
import { ReactComponent as FAQ } from 'assets/images/FAQ.svg'
import { ReactComponent as IconFaqClose } from 'assets/images/iconFaqClose.svg'
import { ReactComponent as IconFaqOpen } from 'assets/images/iconFaqOpen.svg'
import { ReactComponent as StarSmallActive } from 'assets/images/icon_star_small_active.svg'
import { ReactComponent as StarSmallPassive } from 'assets/images/icon_star_small_passive.svg'
import { ReactComponent as FaqLogo } from 'assets/images/FaqLogo.svg'
import { ReactComponent as UsersRating } from 'assets/images/users_rating.svg'
import { ReactComponent as FirstPlace } from 'assets/images/icon_1st.svg'
import { ReactComponent as SecondPlace } from 'assets/images/icon_2st.svg'
import { ReactComponent as ThirdPlace } from 'assets/images/icon_3st.svg'
import { ReactComponent as TimeUnselect } from 'assets/images/timeUnselect.svg'
import { ReactComponent as TeamIcon } from 'assets/images/team_icon.svg'
import { ReactComponent as ColumnsIcon } from 'assets/images/columns.svg'
import { ReactComponent as FilterOpen } from 'assets/images/filter_open.svg'
import { ReactComponent as FilterClosed } from 'assets/images/filter_closed.svg'
import { ReactComponent as Subcontract } from 'assets/images/subcontract.svg'
import { ReactComponent as DndArea } from 'assets/images/dnd_area.svg'
import { ReactComponent as CrossinCircle } from 'assets/images/crossin_circle.svg'
import { ReactComponent as Cross } from 'assets/images/cross.svg'
import { ReactComponent as PopupDialog } from 'assets/images/popup_dialog.svg'
import { ReactComponent as CalendarTask } from 'assets/images/calendar_task.svg'
import { ReactComponent as ArrowLeftWithStick } from 'assets/images/arrow_left_with_stick.svg'
import { ReactComponent as NotificationsBell } from 'assets/images/notifications_bell.svg'
import { ReactComponent as Shield } from 'assets/images/shield.svg'
import { ReactComponent as Clock } from 'assets/images/clock.svg'
import { ReactComponent as Star } from 'assets/images/star.svg'
import { ReactComponent as Lightning } from 'assets/images/lightning.svg'
import { ReactComponent as BackArrow } from 'assets/images/back-arrow.svg'
import { ReactComponent as AddBlue } from 'assets/images/add_blue.svg'
import { ReactComponent as Approved } from 'assets/images/approved.svg'
import { ReactComponent as Rejected } from 'assets/images/rejected.svg'
import { ReactComponent as Waiting } from 'assets/images/waiting.svg'
import { ReactComponent as InReview } from 'assets/images/inreview.svg'
import { ReactComponent as Entrepreneurs } from 'assets/images/icon_sidebar_flp.svg'
import { ReactComponent as Edit } from 'assets/images/edit-button.svg'
import { ReactComponent as CloseModal } from 'assets/images/close-button.svg'
import { ReactComponent as Delete } from 'assets/images/delete-button.svg'
import { ReactComponent as Invoces } from 'assets/images/invoces.svg'
import { ReactComponent as ActiveTable } from 'assets/images/activeTable.svg'
import { ReactComponent as DisableTable } from 'assets/images/disableTable.svg'
import { ReactComponent as Download } from 'assets/images/download.svg'
import { ReactComponent as ArrowDownBlue } from 'assets/images/arrowDown.svg'
import { ReactComponent as ArrowUpBlue } from 'assets/images/arrowUp.svg'
import { ReactComponent as RemoveFilter } from 'assets/images/remove.svg'
import { ReactComponent as BlockpassLogo } from 'assets/images/blockpassLogo.svg'
import { ReactComponent as PolygonidLogo } from 'assets/images/polygonidLogo.svg'
import { ReactComponent as Binance } from 'assets/images/binance.svg'
import { ReactComponent as Bybit } from 'assets/images/bybit.svg'
import { ReactComponent as Vacancies } from 'assets/images/vacancies_sidebar.svg'
import { ReactComponent as RedBucket } from 'assets/images/red_bucket.svg'
import { ReactComponent as AlertNotification } from 'assets/images/alertNotification.svg'
import { ReactComponent as ProjectPlug } from 'assets/images/projectPlug.svg'
import { ReactComponent as ProjectsIcon } from 'assets/images/project_sidebar.svg'
import { ReactComponent as WaitClock } from 'assets/images/wait-clock.svg'
import { ReactComponent as DoneCircle } from 'assets/images/done-circle.svg'
import { ReactComponent as PredictableCosts } from 'assets/images/predictable_costs.svg'
import { ReactComponent as TimelyDelivery } from 'assets/images/timely_delivery.svg'
import { ReactComponent as ExpertTeam } from 'assets/images/expert_team.svg'
import { ReactComponent as ScalableExecution } from 'assets/images/scalable_execution.svg'
import { ReactComponent as CollaborativeApproach } from 'assets/images/collaborative_approach.svg'
import { ReactComponent as TailoredSolutions } from 'assets/images/tailored_solutions.svg'
import { ReactComponent as ConsistentQuality } from 'assets/images/consistent_quality.svg'
import { ReactComponent as SeamlessIntegration } from 'assets/images/seamless_integration.svg'
import { ReactComponent as ExclusiveFocus } from 'assets/images/exclusive_focus.svg'
import { ReactComponent as TransparentProcess } from 'assets/images/transparent_process.svg'
import { ReactComponent as AdaptableSolutions } from 'assets/images/adaptable_solutions.svg'
import { ReactComponent as FlexibleBudget } from 'assets/images/flexible_budget.svg'
import { ReactComponent as FixedPriceLogin } from 'assets/images/fixed_price_login.svg'
import { ReactComponent as TimeAndMaterials } from 'assets/images/time_and_materials.svg'
import { ReactComponent as ProjectBase } from 'assets/images/project_base.svg'
import { ReactComponent as DedicatedTeam } from 'assets/images/dedicated_team.svg'
import { ReactComponent as HightQualityResults } from 'assets/images/hight_quality_results.svg'
import { ReactComponent as Flexibility } from 'assets/images/flexibility.svg'
import { ReactComponent as Transparency } from 'assets/images/transparency.svg'
import { ReactComponent as RealTimeTracking } from 'assets/images/real_time_tracking.svg'
import { ReactComponent as CostEfficiency } from 'assets/images/cost_efficiency.svg'
import { ReactComponent as CustomizedApproach } from 'assets/images/customized_approach.svg'
import { ReactComponent as SpecializedExpertise } from 'assets/images/specialized_expertise.svg'
import { ReactComponent as FullCommitment } from 'assets/images/full_commitment.svg'
import { ReactComponent as AlignedGoals } from 'assets/images/aligned_goals.svg'
import { ReactComponent as ClientFocus } from 'assets/images/client_focus.svg'
import { ReactComponent as ContinuousImprovements } from 'assets/images/continuous_improvement.svg'
import { ReactComponent as InnovativeSolutions } from 'assets/images/innov_solutions.svg'

export type TIcon =
  | 'add'
  | 'remove'
  | 'arrowDown'
  | 'arrowSort'
  | 'basket'
  | 'discharge'
  | 'purchase'
  | 'close'
  | 'dischargeOutline'
  | 'ellipsis'
  | 'exit'
  | 'shop'
  | 'logo'
  | 'logoSmall'
  | 'prev'
  | 'next'
  | 'normalDischarge'
  | 'plusIcon'
  | 'point'
  | 'pointIcon'
  | 'profileIcon'
  | 'requestsUserIcon'
  | 'rouletteIcon'
  | 'tcentIcon'
  | 'timeOffRequests'
  | 'timeIcon'
  | 'timeIconDisable'
  | 'settings'
  | 'users'
  | 'updateIcon'
  | 'doubleArrow'
  | 'logoCollapsed'
  | 'profile'
  | 'restoreIcon'
  | 'updateDisabledIcon'
  | 'dischargeOutlineDisabled'
  | 'arrowLeftIcon'
  | 'arrowRightIcon'
  | 'pointEditIcon'
  | 'backArrowIcon'
  | 'buttonFilter'
  | 'filterIcon'
  | 'filterDisabledIcon'
  | 'iconTableComment'
  | 'iconTableCommentDisabled'
  | 'iconTableClose'
  | 'FAQ'
  | 'FaqLogo'
  | 'iconFaqClose'
  | 'iconFaqOpen'
  | 'starSmallActive'
  | 'starSmallPassive'
  | 'usersRating'
  | 'firstPlace'
  | 'secondPlace'
  | 'thirdPlace'
  | 'TimeUnselect'
  | 'TeamIcon'
  | 'columns'
  | 'filterOpen'
  | 'filterClosed'
  | 'subcontract'
  | 'dndArea'
  | 'crossinCircle'
  | 'cross'
  | 'popupDialog'
  | 'calendarTask'
  | 'arrowLeftWithStick'
  | 'notificationsBell'
  | 'shield'
  | 'clock'
  | 'star'
  | 'lightning'
  | 'backArrow'
  | 'addBlue'
  | 'approved'
  | 'rejected'
  | 'waiting'
  | 'inreview'
  | 'entrepreneurs'
  | 'delete'
  | 'closeModal'
  | 'edit'
  | 'invoces'
  | 'activeTable'
  | 'disableTable'
  | 'download'
  | 'arrowDownBlue'
  | 'arrowUpBlue'
  | 'removeFilter'
  | 'blockpassLogo'
  | 'polygonidLogo'
  | 'binance'
  | 'bybit'
  | 'vacancies'
  | 'redBucket'
  | 'alertNotification'
  | 'projectPlug'
  | 'projects'
  | 'cryptoBot'
  | 'waitClock'
  | 'doneCircle'
  | 'predictableCosts'
  | 'timelyDelivery'
  | 'expertTeam'
  | 'scalableExecution'
  | 'collaborativeApproach'
  | 'tailoredSolutions'
  | 'consistentQuality'
  | 'seamlessIntegration'
  | 'exclusiveFocus'
  | 'transparentProcess'
  | 'adaptableSolutions'
  | 'flexibleBudget'
  | 'fixedPriceLogin'
  | 'timeAndMaterials'
  | 'projectBase'
  | 'dedicatedTeam'
  | 'hightQualityResults'
  | 'flexibility'
  | 'transparency'
  | 'realTimeTracking'
  | 'costEfficiency'
  | 'customizedApproach'
  | 'specializedExpertise'
  | 'fullCommitment'
  | 'alignedGoals'
  | 'clientFocus'
  | 'innovativeSolutions'
  | 'continuousImprovements'

export type TIcons = { [key: string]: ReactNode }
export type TIconsProps = {
  icon: TIcon
} & HTMLAttributes<HTMLElement>

const icons: TIcons = {
  add: <Add />,
  remove: <Remove />,
  arrowDown: <ArrowDown />,
  arrowSort: <ArrowSort />,
  basket: <Basket />,
  discharge: <Discharge />,
  purchase: <Purchases />,
  close: <Close />,
  dischargeOutline: <DischargeOutline />,
  ellipsis: <Ellipsis />,
  exit: <Exit />,
  faq: <FAQ />,
  shop: <Shop />,
  logo: <Logo />,
  logoSmall: <LogoSmall />,
  prev: <Prev />,
  next: <Next />,
  normalDischarge: <NormalDischarge />,
  plusIcon: <PlusIcon />,
  point: <Point />,
  pointIcon: <PointIcon />,
  profileIcon: <ProfileIcon />,
  requestsUserIcon: <RequestsUserIcon />,
  rouletteIcon: <RouletteIcon />,
  tcentIcon: <TcentIcon />,
  timeOffRequests: <TimeOffRequests />,
  timeIcon: <TimeIcon />,
  timeIconDisable: <TimeIconDisable />,
  settings: <Settings />,
  users: <Users />,
  updateIcon: <UpdateIcon />,
  doubleArrow: <DoubleArrow />,
  logoCollapsed: <LogoCollapsed />,
  profile: <Profile />,
  restoreIcon: <RestoreIcon />,
  updateDisabledIcon: <UpdateDisabledIcon />,
  dischargeOutlineDisabled: <DischargeOutlineDisabled />,
  arrowLeftIcon: <ArrowLeftIcon />,
  arrowRightIcon: <ArrowRightIcon />,
  pointEditIcon: <PointEditIcon />,
  backArrowIcon: <BackArrowIcon />,
  buttonFilter: <ButtonFilterIcon />,
  filterIcon: <Filter />,
  filterDisabledIcon: <FilterDisabled />,
  iconTableComment: <Comment />,
  iconTableCommentDisabled: <CommentDisabled />,
  iconTableClose: <CloseTable />,
  FAQ: <FAQ />,
  FaqLogo: <FaqLogo />,
  iconFaqClose: <IconFaqClose />,
  iconFaqOpen: <IconFaqOpen />,
  starSmallActive: <StarSmallActive />,
  starSmallPassive: <StarSmallPassive />,
  usersRating: <UsersRating />,
  firstPlace: <FirstPlace />,
  secondPlace: <SecondPlace />,
  thirdPlace: <ThirdPlace />,
  timeUnselect: <TimeUnselect />,
  TeamIcon: <TeamIcon />,
  columns: <ColumnsIcon />,
  filterOpen: <FilterOpen />,
  filterClosed: <FilterClosed />,
  subcontract: <Subcontract />,
  dndArea: <DndArea />,
  crossinCircle: <CrossinCircle />,
  cross: <Cross />,
  popupDialog: <PopupDialog />,
  calendarTask: <CalendarTask />,
  arrowLeftWithStick: <ArrowLeftWithStick />,
  notificationsBell: <NotificationsBell />,
  shield: <Shield />,
  clock: <Clock />,
  star: <Star />,
  lightning: <Lightning />,
  backArrow: <BackArrow />,
  addBlue: <AddBlue />,
  approved: <Approved />,
  rejected: <Rejected />,
  waiting: <Waiting />,
  inreview: <InReview />,
  entrepreneurs: <Entrepreneurs />,
  delete: <Delete />,
  closeModal: <CloseModal />,
  edit: <Edit />,
  invoces: <Invoces />,
  activeTable: <ActiveTable />,
  disableTable: <DisableTable />,
  download: <Download />,
  arrowDownBlue: <ArrowDownBlue />,
  arrowUpBlue: <ArrowUpBlue />,
  removeFilter: <RemoveFilter />,
  blockpassLogo: <BlockpassLogo />,
  polygonidLogo: <PolygonidLogo />,
  binance: <Binance />,
  bybit: <Bybit />,
  vacancies: <Vacancies />,
  redBucket: <RedBucket />,
  alertNotification: <AlertNotification />,
  projectPlug: <ProjectPlug />,
  projects: <ProjectsIcon />,
  cryptoBot: <CryptoBot />,
  waitClock: <WaitClock />,
  doneCircle: <DoneCircle />,
  predictableCosts: <PredictableCosts />,
  timelyDelivery: <TimelyDelivery />,
  expertTeam: <ExpertTeam />,
  scalableExecution: <ScalableExecution />,
  tailoredSolutions: <TailoredSolutions />,
  collaborativeApproach: <CollaborativeApproach />,
  exclusiveFocus: <ExclusiveFocus />,
  seamlessIntegration: <SeamlessIntegration />,
  consistentQuality: <ConsistentQuality />,
  flexibleBudget: <FlexibleBudget />,
  transparentProcess: <TransparentProcess />,
  adaptableSolutions: <AdaptableSolutions />,
  fixedPriceLogin: <FixedPriceLogin />,
  timeAndMaterials: <TimeAndMaterials />,
  projectBase: <ProjectBase />,
  dedicatedTeam: <DedicatedTeam />,
  hightQualityResults: <HightQualityResults />,
  flexibility: <Flexibility />,
  transparency: <Transparency />,
  realTimeTracking: <RealTimeTracking />,
  costEfficiency: <CostEfficiency />,
  customizedApproach: <CustomizedApproach />,
  specializedExpertise: <SpecializedExpertise />,
  fullCommitment: <FullCommitment />,
  alignedGoals: <AlignedGoals />,
  clientFocus: <ClientFocus />,
  continuousImprovements: <ContinuousImprovements />,
  innovativeSolutions: <InnovativeSolutions />
}

export const Icons: FC<TIconsProps> = ({ icon, ...rest }) => {
  return (
    <i aria-hidden="true" {...rest}>
      {icons[icon]}
    </i>
  )
}
