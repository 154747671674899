/* eslint-disable no-console */
import { Column } from 'react-table'
import { TableShowHeader } from '../../../../../components/UiKit/Table'
import React from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export type TTimeOffRequestsData = {
  name: string
  grade: string
  level: string
  rate: string
  period: any
}

type TColumnsManagerOptions = {
  role: number
  //   handleCancelRequest: (id: number | string) => void
  isOnlyManager: boolean
  isActiveLevel: boolean
  isActiveName: boolean
  setName: (isName: any) => void
  setLevel: (isName: any) => void
}
type TColumnsManager = (options: TColumnsManagerOptions) => Array<Column<TTimeOffRequestsData>>

export const Columns: TColumnsManager = ({ isActiveLevel, isActiveName, setName, setLevel }) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const columnsManager: Array<Column<TTimeOffRequestsData>> = [
    {
      Header: ({ column }) => (
        <TableShowHeader
          id={column.id}
          isActiveLevel={isActiveLevel}
          isActiveName={isActiveName}
          setName={setName}
          setLevel={setLevel}
        >
          {words['projects.invoices.employeeName']}
        </TableShowHeader>
      ),
      id: 'name',
      accessor: d => d.name,
      width: 200
    },
    {
      Header: words['projects.invoices.positionByInvoice'],
      id: 'grade',
      accessor: d => d.grade,
      width: 200
    },
    {
      Header: ({ column }) => (
        <TableShowHeader
          id={column.id}
          isActiveLevel={isActiveLevel}
          isActiveName={isActiveName}
          setName={setName}
          setLevel={setLevel}
        >
          {words['projects.invoices.levelByInvoice']}
        </TableShowHeader>
      ),
      id: 'level',
      accessor: d => d.level,
      width: 200
    },
    {
      Header: words['projects.invoices.rate'],
      id: 'rate',
      accessor: d => d.rate,
      width: 50
    },
    {
      Header: words['projects.invoices.duration'],
      id: 'period',
      accessor: d => d.period,
      width: 250
    }
  ]

  return columnsManager
}
