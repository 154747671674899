import React, { FC, useEffect, useState } from 'react'
import history from 'utils/history'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store'
import styles from './Project.module.scss'
import { useParams } from 'react-router'
import { Icons } from '../../../../../components/Icons'
import { TProjectProps } from './Project.model'
import { EditProjectBtn } from './../EditProjectBtn'
import { FileView } from 'components/Profile/PersonalInfo/FileView'
import moment from 'moment'
import cls from 'classnames'
import queryString from 'query-string'
import { IToggleBarData, ToggleBar } from 'components/ToggleBar'
import { getProjectType, setDefaultMainToggleBarStateForProject } from 'utils/toggleBarsValue'
import { EProjectHistory, getToggleBarOptionsForProject } from 'globalConfigs'
import {
  modelsData,
  stageData,
  methodologyData,
  paymentMethodData,
  unitData
} from '../../../CreateProject/CreateProject'
import Loading from '../../../../../components/Spinner'
import { TeamArchive } from 'components/Project/TeamArchive'
import { clearOneProjectSuccess } from '../../Projects.actions'
import { IFileItem } from 'screens/admin/CreateProject/CreateProject.model'
import { ProjectVacancies } from '../../../../../components/Project/ProjectVacancies'
import { BaseButton } from '../../../../../components/UiKit/Button/BaseButton'
import { getSelectOptions } from 'screens/client/EditProfile/EditProfile.thunx'
import { getSelectOptionsForTeamAdmin } from 'screens/admin/Team/TeamAdmin.thunx'
import { CurrentTeam } from '../../../../../components/Project/CurrentTeam'

import { Invoices } from 'components/Project/Invoices'
const Project: FC<TProjectProps> = ({
  getOneProject,
  deleteProject,
  restoreProject,
  loading,
  projectInfo,
  displayAllFields,
  managers,
  getManagers
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const params: any = useParams()
  const parsed = queryString.parse(history.location.search)
  const [selectedProjectType, setSelectedProjectType] = useState<IToggleBarData>(
    setDefaultMainToggleBarStateForProject(parsed.projectType, words)
  )
  const dispatch = useDispatch()

  const findLabelInData = (value: any, data: any) => {
    const found = data.find((o: any) => +o.value === +value)
    return found ? found.label : 'No data'
  }

  useEffect(() => {
    dispatch(getSelectOptionsForTeamAdmin())
    dispatch(getSelectOptions())
    getOneProject(params.id)
    getManagers()

    return () => {
      dispatch(clearOneProjectSuccess())
    }
  }, [])

  const deleted = projectInfo && projectInfo.deletedAt

  return (
    <div
      className={cls({
        [styles.container]: true,
        [styles['deleted']]: deleted
      })}
    >
      <section>
        <div className={styles.header}>
          <h1>{words['project.projects']}</h1>
        </div>
      </section>

      <section className={styles['buttons']}>
        <div className={styles['back-btn-wrapper']}>
          <button
            className={styles['back-btn']}
            onClick={() => {
              const back = `/dashboard/projects?deleted=${deleted ? 'true' : 'false'}`
              history.push(back)
            }}
          >
            <Icons icon={'arrowLeftWithStick'} />
            {words['back']}
          </button>
        </div>

        {!loading && (
          <EditProjectBtn
            projectId={params.id}
            deleted={deleted}
            deleteAction={deleteProject}
            restoreAction={restoreProject}
            history={history}
          />
        )}
      </section>

      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
          }}
        >
          <Loading />
        </div>
      ) : projectInfo ? (
        <>
          <section className={styles['project-header']}>
            <div className={styles['project-header-img']}>
              <img src={projectInfo.avatar} alt="Project avatar" />
            </div>
            <div className={styles['project-header-text']}>
              <h1>{projectInfo.name}</h1>
              <div>
                <div>
                  <span>{words['project.creatingDate']}: </span>
                  {moment(projectInfo.createdAt).format('DD.MM.YYYY')}
                </div>
                {deleted && (
                  <div style={{ marginLeft: 64 }}>
                    <span>{words['project.archivingDate']}: </span>
                    {moment(deleted).format('DD.MM.YYYY')}
                  </div>
                )}
              </div>
              <div>
                <div>
                  <span>{words['project.description']}: </span> {projectInfo.description}
                </div>
              </div>
            </div>
          </section>

          <section className={styles['project-main']}>
            <h2>{words['project.main']}</h2>
            <div className={styles['project-main-wrapper']}>
              <div className={styles['project-main-left']}>
                <div>
                  <span>{words['project.start']}: </span>{' '}
                  {moment(projectInfo.start).format('DD.MM.YYYY')}
                </div>
                <div>
                  <span>{words['project.end']}: </span>{' '}
                  {moment(projectInfo.end).format('DD.MM.YYYY')}
                </div>
                <div>
                  <span>{words['project.model']}: </span>{' '}
                  {findLabelInData(projectInfo.model, modelsData)}
                </div>
                <div>
                  <span>{words['project.stage']}: </span>{' '}
                  {findLabelInData(projectInfo.stage, stageData)}
                </div>
                <div>
                  <span>{words['project.methodology']}: </span>{' '}
                  {findLabelInData(projectInfo.methodology, methodologyData)}
                </div>
                <div>
                  <span>{words['project.payment']}: </span>{' '}
                  {findLabelInData(projectInfo.paymentMethod, paymentMethodData)}
                </div>
                <div>
                  <span>{words['project.unit']}: </span>{' '}
                  {findLabelInData(projectInfo.unit, unitData)}
                </div>
                <div>
                  <span>{words['project.manager']}: </span>{' '}
                  {findLabelInData(projectInfo.manager, managers)}
                </div>
                <div>
                  <span>{words['project.assistant']}: </span>{' '}
                  {findLabelInData(projectInfo.customerAssistant, managers)}
                </div>
              </div>
              <div className={styles['divider']} />
              <div className={styles['project-main-right']}>
                <div>
                  <span>{words['project.stack']}: </span> {projectInfo.stack}
                </div>
                <div>
                  <span>{words['project.structure']}: </span> {projectInfo.structure}
                </div>
              </div>
            </div>
          </section>

          <section className={styles['project-client']}>
            <h2>{words['project.customerData']}</h2>
            <div className={styles['project-client-wrapper']}>
              <div>
                <span>{words['project.fullName']}: </span> {projectInfo.companyName}
              </div>
              <div>
                <span>{words['project.registerNumber']}: </span> {projectInfo.registerNumber}
              </div>
              <div>
                <span>{words['project.fullAddress']}: </span> {projectInfo.fullAddress}
              </div>
              <div>
                <span>{words['project.email']}: </span> {projectInfo.email}
              </div>
            </div>
          </section>

          <section className={styles['project-files']}>
            <h2>{words['project.files']}</h2>
            <div className={styles['project-files-wrapper']}>
              {projectInfo.projectFiles.length > 0 &&
                projectInfo.projectFiles
                  .filter((item: IFileItem) => !item.deletedAt)
                  .map((item: IFileItem, idx: number) => (
                    <FileView
                      isEdit={false}
                      item={item}
                      idx={idx}
                      key={String(idx)}
                      deleteFile={() => null}
                    />
                  ))}
            </div>
          </section>

          {!deleted && (
            <>
              <section className={styles['project-team']}>
                <h3>{words['project.team']}</h3>
                <div
                  className={cls({
                    [styles['project-toggle']]: true,
                    [styles['project-toggle-vacancies']]:
                      parsed.projectType === EProjectHistory.VACANCIES
                  })}
                >
                  <ToggleBar
                    name="project-info"
                    data={getToggleBarOptionsForProject(words)}
                    defaultChecked={selectedProjectType}
                    style={2}
                    onChange={event => {
                      history.push(
                        `${history.location.pathname}?${queryString.stringify({
                          projectType: getProjectType(event.value)
                        })}`
                      )
                      setSelectedProjectType(event)
                    }}
                  />
                  {parsed.projectType === EProjectHistory.VACANCIES && (
                    <BaseButton
                      onClick={() => history.push('/dashboard/vacancies/create')}
                      size="sm"
                    >
                      {words['project.addVacancy']}
                    </BaseButton>
                  )}
                  {parsed.projectType === EProjectHistory.CURRENT_TEAM && (
                    <BaseButton
                      onClick={() => history.push(`../create-invoice?projectId=${params.id}`)}
                      size="sm"
                    >
                      {words['admin.projects.currentTeam.generateInvoice']}
                    </BaseButton>
                  )}
                </div>
              </section>

              <section className={styles['project-team-info']}>
                {parsed.projectType === EProjectHistory.CURRENT_TEAM ? (
                  <CurrentTeam />
                ) : parsed.projectType === EProjectHistory.VACANCIES ? (
                  <ProjectVacancies />
                ) : parsed.projectType === EProjectHistory.INVOICES ? (
                  <Invoices />
                ) : parsed.projectType === EProjectHistory.TEAM_ARCHIVE ? (
                  <TeamArchive displayAllFields={displayAllFields}> </TeamArchive>
                ) : (
                  <></>
                )}
              </section>
            </>
          )}
        </>
      ) : (
        words['noData']
      )}
    </div>
  )
}

export default Project
