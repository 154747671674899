import { Dispatch } from 'redux'
import {
  getForeignProjectsRequest,
  getForeignProjectsSuccess,
  getForeignProjectsError,
  getForeignManagersRequest,
  getForeignManagersSuccess,
  getForeignManagersError
} from './ForeignProfile.actions'
import { api } from 'services/api'
import { API } from '../../../services/api'
import { AxiosError } from 'axios'

export const getProjects: any = () => async (dispatch: Dispatch) => {
  dispatch(getForeignProjectsRequest())

  try {
    const result = await api.get(`${API.URL}/projects`)

    dispatch(getForeignProjectsSuccess(result.data.payload.results))
  } catch (error) {
    const err = error as AxiosError<any>
    await dispatch(getForeignProjectsError(err))
  }
}

export const getManagers: any = () => async (dispatch: Dispatch) => {
  dispatch(getForeignManagersRequest())
  try {
    const result: any = await api.get(`${API.URL}/managers`)
    dispatch(getForeignManagersSuccess(result.data.payload))
  } catch (error) {
    const err = error as AxiosError<any>
    await dispatch(getForeignManagersError(err))
  }
}
