/* eslint-disable react/no-children-prop */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import styles from './UserResponse.module.scss'
import { ReactComponent as DefaultAvatar } from 'assets/images/default_avatar.svg'
import { TState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import history from 'utils/history'
import moment from 'moment'
import { getProposal } from '../../Subcontract.actions'
import { EResponsesHistory } from 'globalConfigs'
import { TaskResponseStatus } from './libs/constants/constants'

const UserResponse = ({
  userId,
  accessToken,
  taskInfo,
  response,
  isAdmin,
  tasksResponses,
  status
}: any) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const avatar = useSelector((state: TState) => state.auth.avatar)
  const [userPhoto, setUserPhoto] = useState('')
  const taskId = taskInfo.id
  const dispatch = useDispatch()

  useEffect(() => {
    setUserPhoto(avatar)
  }, [])

  const handleSetProposal = () => {
    dispatch(getProposal(response))
    history.push(
      `/dashboard/subcontract/task-payment?proposal=${
        response.id
      }&taskId=${taskId}&userId=${userId}`
    )
  }

  return (
    <div className={styles.container}>
      <section className={styles['personal-info']}>
        <div className={styles['info-task']}>
          <div>
            <div>
              {userPhoto ? (
                <img className={styles.avatar} src={userPhoto} alt="avatar" />
              ) : (
                <DefaultAvatar className={styles.avatar} />
              )}
            </div>
            <div className={styles['user-info']}>
              <h2>
                {response.author.fullName}
                {userId}
              </h2>
              <p>
                {words['user.subcontract.reviews']}
                <span style={{ color: '#E12F75' }}> -{response.author.countNegativeReviews}</span>
                <span style={{ color: '#149B58' }}> +{response.author.countPositiveReviews}</span>
              </p>
            </div>
          </div>
          {status !== EResponsesHistory.MY_PERFORMER &&
            (response.status !== TaskResponseStatus.SELECTED ? (
              <div style={{ display: 'flex' }}>
                {status !== 'on-interview' && (
                  <>
                    <BaseButton
                      disabled={isAdmin}
                      children={
                        taskInfo.tokenStatus
                          ? words['user.subcontract.myTask.nav.intinv']
                          : 'Connect calendar'
                      }
                      type="button"
                      className={styles['light-btn']}
                      onClick={() =>
                        !taskInfo.tokenStatus
                          ? (window.location.href = `${
                              window.location.href.search(/localhost/i) === 0
                                ? 'https://green-points-system.technorely.com/backend/api'
                                : 'http://localhost:3001/api'
                            }/google-calendar/refresh-token?token=${accessToken}&callbackUrl=${encodeURIComponent(
                              window.location.href
                            )}`)
                          : history.push(
                              `/dashboard/subcontract/interview-invitation?type=request&taskId=${taskId}&userId=${userId}`
                            )
                      }
                    />
                    {tasksResponses && (
                      <div className={styles['pop-num']}>
                        <p>{tasksResponses}</p>
                      </div>
                    )}
                  </>
                )}
                <BaseButton
                  disabled={isAdmin}
                  children={words['user.subcontract.response.employ']}
                  size="mds"
                  type="submit"
                  className={styles['take-btn']}
                  onClick={handleSetProposal}
                />
              </div>
            ) : (
              <div className={styles['response-status-wrapper']}>
                <div className={styles['response-status']}>
                  {words['user.subcontract.responseTask.selected']}
                </div>
              </div>
            ))}
        </div>
        <div>
          <p>{response.comment}</p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={styles['responser-desc']}>
              <p>
                <span>{words['user.subcontract.taskRequest.budget']}:</span> {response.budget}
                {' TRL'}
              </p>
              {response.deadline ? (
                <p>
                  <span>{words['user.subcontract.taskRequest.term']}:</span>{' '}
                  {moment(response.deadline).format(`DD.MM.YYYY`)}
                </p>
              ) : null}
            </div>
            <p className={styles['response-date']}>{moment(response.createdAt).fromNow()}</p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default UserResponse
