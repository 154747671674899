import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import { NotificationsComponent } from './Notifications'
import { deleteNotifications, viewNotification } from './Notifications.thunx'
import { removeSelectedNotification } from './Notifications.actions'

export const mapStateToProps = (state: TState) => {
  return {
    notifications: state.client.notifications.data,
    selected: state.client.notifications.selected
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      deleteNotifications,
      viewNotification,
      deleteSelected: removeSelectedNotification
    },
    dispatch
  )

export const Notifications = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsComponent)
