import React, { useEffect, useState } from 'react'
import styles from './Feedbacks.module.scss'
import { IFeedback } from 'screens/client/Profile/Profile.model'
import { normalizeWordsWithNumbers } from 'helpers/helpers'
import { History } from 'history'
import queryString from 'query-string'
import { IToggleBarData, ToggleBar } from 'components/ToggleBar'
import { EFeedbackOptions } from 'globalConfigs'
import { getFeedbacksType } from 'utils/toggleBarsValue'
import SingleFeedback from './components/SingleFeedback'

interface IFeedbacksProps {
  feedbacks: IFeedback[]
  history: History
  feedbackToggleBarOptions: IToggleBarData[]
  id: string
  getTasks: (limit: number, page: any, url: string) => void
  tasks: any
  locale: string
}

export const Feedbacks = ({
  feedbacks,
  history,
  feedbackToggleBarOptions,
  id,
  getTasks,
  tasks,
  locale
}: IFeedbacksProps) => {
  const parsed = queryString.parse(history.location.search)
  const [selectedTransactionType, setSelectedType] = useState<IToggleBarData>(
    parsed.type === EFeedbackOptions.POSITIVE
      ? feedbackToggleBarOptions[1]
      : parsed.type === EFeedbackOptions.NEGATIVE
      ? feedbackToggleBarOptions[2]
      : feedbackToggleBarOptions[0]
  )

  const positiveFeedbacks =
    feedbacks && feedbacks.filter(feedback => feedback.review === EFeedbackOptions.POSITIVE)
  const negativeFeedbacks =
    feedbacks && feedbacks.filter(feedback => feedback.review === EFeedbackOptions.NEGATIVE)

  useEffect(() => {
    getTasks(0, 0, '/tasks')
  }, [])
  return (
    <>
      <div className={styles['total-feedbacks']}>
        {normalizeWordsWithNumbers(feedbacks && feedbacks.length, locale)}
      </div>
      <div className={styles.toggle}>
        <ToggleBar
          name="active-feedbacks-no-active"
          data={feedbackToggleBarOptions}
          defaultChecked={selectedTransactionType}
          style={2}
          onChange={event => {
            history.push(
              `${history.location.pathname}?${queryString.stringify({
                type: getFeedbacksType(event.value),
                profileType: parsed.profileType,
                userId: id
              })}`
            )
            setSelectedType(event)
          }}
        />
      </div>
      <section className={styles['feedback-wrapper']}>
        {feedbacks && parsed.type === EFeedbackOptions.POSITIVE
          ? positiveFeedbacks.map((feedback, i) => (
              <SingleFeedback key={i} feedback={feedback} tasks={tasks} />
            ))
          : parsed.type === EFeedbackOptions.NEGATIVE
          ? negativeFeedbacks.map((feedback, i) => (
              <SingleFeedback key={i} feedback={feedback} tasks={tasks} />
            ))
          : feedbacks.map((feedback, i) => (
              <SingleFeedback key={i} feedback={feedback} tasks={tasks} />
            ))}
      </section>
    </>
  )
}
