import { Icons } from 'components/Icons'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { Button as UiButton } from '../UiKit/Button'
import styles from './styles.module.scss'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { IToken } from 'screens/admin/Token/Token.model'
import { getOnlyUniqueItems } from 'helpers/getOnlyUniqueItems'
import { filteredTokensByValue } from 'helpers/filteredTokensByValue'

interface ITokenChoseToken {
  tokens: {
    adminActivatedTokens: IToken[]
    adminDeactivatedTokens: IToken[]
    userActivatedTokens: IToken[]
    userDeactivatedTokens: IToken[]
  }
  action: () => void
  handleOpenEditModal: () => void
  handleKeyPress: (event: KeyboardEvent) => Promise<void>
  getSelectedToken: (value: IToken) => void
  title: string
  button: string
}

export const TokenChoseModal: React.FC<ITokenChoseToken> = ({
  tokens,
  action,
  handleOpenEditModal,
  handleKeyPress,
  getSelectedToken,
  title,
  button
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const [valueChoseToken, setValueChoseToken] = useState('')
  const [currentAdminTokens, setCurrentAdminTokens] = useState<IToken[]>([])

  const { userActivatedTokens, adminActivatedTokens } = tokens

  useEffect(() => {
    setCurrentAdminTokens(adminActivatedTokens)
  }, [adminActivatedTokens])

  const filteredTokensFromServer = useMemo((): IToken[] => {
    const tokensFromServer = [...userActivatedTokens, ...currentAdminTokens]

    const filteredTokensByName = filteredTokensByValue(tokensFromServer, valueChoseToken, 'name')
    const filteredTokensByAddress = filteredTokensByValue(
      tokensFromServer,
      valueChoseToken,
      'address'
    )

    const visibleTokens = filteredTokensByName
      .concat(filteredTokensByAddress)
      .filter(getOnlyUniqueItems)

    return visibleTokens
  }, [userActivatedTokens, currentAdminTokens, valueChoseToken])

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setValueChoseToken(event.target.value)
  }

  const handleSelectToken = (token: IToken) => {
    getSelectedToken(token)
    action()
  }

  return (
    <>
      <div className={styles.closeModalButton} onClick={action}>
        <Icons icon="close" />
      </div>
      <div className={styles.modalTitle}>{title}</div>
      <FieldFactory
        config={[
          {
            items: [
              {
                name: `tokenAddress`,
                label: '',
                required: false,
                inputWrapperClassName: styles['currency'],
                component: () => {
                  return {
                    type: 'input',
                    props: {
                      placeholder: 'Search name or paste address',
                      options: '',
                      variant: 'outlined',
                      value: valueChoseToken,
                      onChange: handleChangeInput,
                      onKeyPress: handleKeyPress
                    }
                  }
                }
              }
            ]
          }
        ]}
        words={words}
      />
      <div className={styles.tokensBlock}>
        <div className={styles.popularTokenList}>
          {adminActivatedTokens.slice(0, 5).map(token => (
            <div key={token.address} className={styles.popularTokenItem}>
              <img src={token.logoURI} alt="Token image" className={styles.popularTokenImage} />
              <div className={styles.popularTokenName}>{token.symbol}</div>
            </div>
          ))}
        </div>

        <div className={styles.tokenList}>
          {filteredTokensFromServer.length ? (
            filteredTokensFromServer.map(token => (
              <div
                key={token.id}
                className={styles.tokenItem}
                onClick={() => {
                  handleSelectToken(token)
                }}
              >
                <img src={token.logoURI} alt="Token image" className={styles.tokenImage} />

                <div className={styles.tokenItemWrapper}>
                  <div className={styles.tokenItemTitle}>{token.symbol}</div>

                  <div className={styles.tokenItemDescription}>{token.name}</div>
                </div>
              </div>
            ))
          ) : (
            <div>There are no tokens</div>
          )}
        </div>
      </div>
      <div className={`${styles.buttons} ${styles['buttons-cancel']}`}>
        <UiButton
          outline={false}
          color={'default'}
          className={'requesth'}
          children={button}
          onClick={handleOpenEditModal}
        />
      </div>
    </>
  )
}
