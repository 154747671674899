import { TWords } from 'elements/SideBar/SideBar.config'
import React from 'react'
// import { useSelector } from 'react-redux'
import { Column } from 'react-table'
// import { TState } from 'store'

export type TExchangeData = {
  date: string
  profit: string
  dailyProfitNET: string
  maxMarginUsed: string
}

export const Columns = (words: TWords) => {
  const columnsManager: Array<Column<TExchangeData>> = [
    {
      Header: words['crypto-bot.date'],
      id: 'date',
      accessor: 'date',
      minWidth: 250
    },
    {
      Header: words['crypto-bot.dailyProfit'],
      id: 'profit',
      accessor: d => {
        return (
          <p
            style={{
              color: parseFloat(d.profit) > 0 ? '#149B58' : '#E12F75',
              fontSize: '16px'
            }}
          >
            {d.profit}
          </p>
        )
      },
      minWidth: 250
    },
    {
      Header: words['crypto-bot.totalProfitLoss'],
      id: 'dailyProfitNET',
      accessor: 'dailyProfitNET',
      minWidth: 250
    },
    {
      Header: words['crypto-bot.marginEngaged'],
      id: 'maxMarginUsed',
      accessor: 'maxMarginUsed',
      minWidth: 250
    }
  ]

  return columnsManager
}
