import React, { FC, useRef } from 'react'
import downloadIcon from 'assets/images/download_icon.svg'
import SubcontractInvoice from '../../SubcontractInvoice/SubcontractInvoice'
import { generatePDF } from '../../../../../../components/DownloadInvoice/getPDF'
interface DownloadInvoiceProps {
  d?: any
}
const DownloadInvoice: FC<DownloadInvoiceProps> = ({ d }) => {
  const downloadRef = useRef<any>(null)
  const handleDownloadClick = (e: any) => {
    e.stopPropagation()
    generatePDF(downloadRef, d.id)
  }
  return (
    <>
      <div style={{ cursor: 'pointer' }} onClick={(e: any) => handleDownloadClick(e)}>
        <img src={downloadIcon} alt="invoice" />
      </div>
      <SubcontractInvoice ref={downloadRef} invoice={d} />
    </>
  )
}

export default DownloadInvoice
