import React from 'react'
// import styles from './ProfitSchedule.module.scss'
// import { Table } from 'components/UiKit/Table'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'react-chartjs-2'

interface RawDataItem {
  id: number
  exchange: string
  maxMarginUsed: number
  date: string
  profit: string | null
  profitNET: string
  dailyProfitNET: string | null
  updatedAt: string
  createdAt: string
}

interface IGraphComponent {
  rawData: RawDataItem[]
}

const GraphComponent: React.FC<IGraphComponent> = ({ rawData }) => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

  const labels = rawData.map(item => {
    const date = new Date(item.date)
    const day = String(date.getDate())
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = String(date.getFullYear())
    return `${day}.${month}.${year}`
  })

  const dataFromServer = rawData.map(item => (item.profit ? parseFloat(item.profit).toFixed(2) : 0))

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        color: '#000'
      },
      tooltip: {
        bodyFont: {
          size: 18
        },
        titleAlign: 'center',
        padding: 15,
        bodySpacing: 10,
        bodyAlign: 'center',
        displayColors: false,
        backgroundColor: '#fff',
        borderWidth: 1,
        borderColor: '#aaa',
        yAlign: 'bottom',
        titleColor: '#9a9a9a',
        callbacks: {
          title: function(tooltipItem: any) {
            return tooltipItem[0].label
          },
          label: function(tooltipItem: any) {
            return tooltipItem.raw > 0
              ? `+${tooltipItem.formattedValue} USDT`
              : `${tooltipItem.formattedValue} USDT`
          },
          labelTextColor: function(tooltipItem: any) {
            return Number(tooltipItem.raw) < 0 ? '#E12F75' : '#149B58'
          }
        }
      }
    },
    scales: {
      y: {
        ticks: {
          callback: function(value: any) {
            return value.toFixed(2)
          }
        }
      }
    }
  }

  const data = {
    labels,
    datasets: [
      {
        borderColor: '#149B58',
        tension: 0.4,
        pointRadius: 5,
        pointBackgroundColor: '#b80c54',
        pointBorderColor: '#b80c54',
        borderWidth: 3,
        data: dataFromServer,
        fill: false
      }
    ]
  }

  return <Line options={options as any} data={data} />
}

export default GraphComponent
