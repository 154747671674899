import { TBaseFilterConfigData } from 'components/UiKit/Filter/Filter'
import { TSelectOption } from '../../../Select/Select.model'
import { TWords } from 'elements/SideBar/SideBar.config'

export const getFilterConfig = ({
  categories,
  minPrice,
  maxPrice,
  words
}: {
  categories: TSelectOption[]
  minPrice: number
  maxPrice: number
  words: TWords
}): TBaseFilterConfigData[] => {
  return [
    {
      name: 'name',
      type: 'input',
      label: words['user.pointsSystem.initiatives.table.name'],
      placeholder: `${words['user.pointsSystem.initiatives.table.name']}...`,
      width: '40%'
    },
    {
      name: 'categoryId',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...categories],
      label: words['user.pointsSystem.initiatives.table.category'],
      placeholder: '',
      width: '28%'
    },
    {
      name: 'priceFrom',
      secondName: 'priceTo',
      type: 'inputRange',
      fieldData: { minValue: minPrice, maxValue: maxPrice },
      label: words['user.profile.table.reward'],
      width: 80
    }
  ]
}
