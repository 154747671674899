import { config } from 'globalConfigs'
import io from 'socket.io-client'
import { SocketEvent } from './libs/constants/socket-event.constant'

class SocketService {
  private socket!: SocketIOClient.Socket

  private handleConnection() {
    this.socket.on(SocketEvent.CONNECT, () => {
      console.log('Connected to WS server')
    })
  }
  private handleError() {
    this.socket.on(SocketEvent.CONNECT_ERROR, () => {
      console.log('Failed connection to WS server')
    })
  }

  public connect(): void {
    if (!this.socket) {
      if (config.serverUrl.includes('backend')) {
        this.socket = io('https://stage-points-system.technorely.com', {
          path: '/backend/socket.io',
          transports: ['websocket']
        })
      } else {
        this.socket = io(config.serverUrl, {
          path: '/socket.io',
          transports: ['websocket']
        })
      }
      this.handleConnection()
      this.handleError()
    }
  }

  public reconnect(): void {
    if (config.serverUrl.includes('backend')) {
      this.socket = io('https://stage-points-system.technorely.com', {
        path: '/backend/socket.io',
        transports: ['websocket']
      })
    } else {
      this.socket = io(config.serverUrl, {
        path: '/socket.io',
        transports: ['websocket']
      })
    }
  }

  public getSocket(): SocketIOClient.Socket {
    return this.socket
  }
}

export { SocketService }
