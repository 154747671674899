/* eslint-disable no-console */
import { Dispatch } from 'redux'
import { API, api, apiVetifi } from '../../../../../services/api'
import {
  verifiUserRequest,
  verifiUserSuccess,
  verifiUserError,
  claimsListSuccess
} from './UserVerifiinfo.actions'
import toastr from 'utils/toastr'
import { TState } from 'store'
import { AxiosError } from 'axios'

export const verifiUser = (status: string, id: number, offerId: string) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(verifiUserRequest())
  const words = getData().global.language.words
  try {
    const { data } = await api.get(`${API.CLIENT}/${id}`)

    await api.put(`${API.CLIENT}/${id}`, { ...data, verification: status, offerId })
    dispatch(verifiUserSuccess({ ...data, verification: status }))
    toastr('info', words['SUCCESS_UPDATED'])
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(verifiUserError(err))
    toastr('error', words['FILED_UPDATE_USER'], err)
  }
}

export const getClaims = () => async (dispatch: Dispatch, getData: () => TState): Promise<void> => {
  dispatch(verifiUserRequest())
  const words = getData().global.language.words
  console.log(process.env.REACT_APP_ADMIN_DID)
  try {
    const { data } = await apiVetifi.get(`polygon/${process.env.REACT_APP_ADMIN_DID}/claims`)

    dispatch(claimsListSuccess({ claimsList: data }))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(verifiUserError(err))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}
