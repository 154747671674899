import { fetchAllTokensThunk } from './../../../screens/admin/Token/Token.thunx'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import OfferTasksComponent from './OfferTasks'
import { clearStore, getTasks } from '../../../screens/client/Subcontract/Subcontract.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    taskArray: state.client.subcontract.tasks,
    usersArray: state.client.subcontract.users,
    loading: state.client.subcontract.loading,
    itemsCount: state.client.subcontract.itemsCount,
    pageCount: state.client.subcontract.pageCount,
    page: state.client.subcontract.page,
    tokens: state.admin.token.allTokens
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getTasks,
      clearStore,
      fetchAllTokensThunk
    },
    dispatch
  )

export const OfferTasks = connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferTasksComponent)
