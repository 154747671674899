import { API, api } from '../../../services/api'
import { AxiosError } from 'axios'

export const setProject = async (
  body: any
): Promise<{ errorMessage: string | null; success: boolean }> => {
  try {
    await api.post(`${API.URL}/projects`, body)
    return { success: true, errorMessage: null }
  } catch (err) {
    const error = err as AxiosError<any>
    const { response } = error
    let errorMessage = 'Something went wrong'

    if (response && response.status === 400) {
      errorMessage = 'Bad request'
    } else if (response && response.status === 401) {
      errorMessage = 'Unauthorized. Please check your credentials.'
    } else if (response && response.status === 500) {
      errorMessage = 'Server error. Please try again later.'
    }
    return { errorMessage, success: false }
  }
}

export const editProject = async (
  body: any,
  id: number
): Promise<{ errorMessage: string | null; success: boolean }> => {
  try {
    await api.put(`${API.URL}/projects/${id}`, body)
    return { success: true, errorMessage: null }
  } catch (err) {
    const error = err as AxiosError<any>
    const { response } = error
    let errorMessage = 'Something went wrong'

    if (response && response.status === 400) {
      errorMessage = 'Bad request'
    } else if (response && response.status === 401) {
      errorMessage = 'Unauthorized. Please check your credentials.'
    } else if (response && response.status === 500) {
      errorMessage = 'Server error. Please try again later.'
    }

    return { errorMessage, success: false }
  }
}

export const setProjectHistory = async (
  body: any
): Promise<{ errorMessage: string | null; success: boolean }> => {
  try {
    await api.post(`${API.URL}/project-history`, body)
    return { success: true, errorMessage: null }
  } catch (err) {
    const error = err as AxiosError<any>
    const { response } = error
    let errorMessage = 'Something went wrong'

    if (response && response.status === 400) {
      errorMessage = 'Bad request'
    } else if (response && response.status === 401) {
      errorMessage = 'Unauthorized. Please check your credentials.'
    } else if (response && response.status === 422) {
      errorMessage = 'Unprocessable Entity'
    } else if (response && response.status === 500) {
      errorMessage = 'Server error. Please try again later.'
    }

    return { errorMessage, success: false }
  }
}
