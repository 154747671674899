import React from 'react'
import styles from '../../UserHeader.module.scss'
import { Skeleton } from 'components/Skeleton'

type TProperty = {
  info: string
  children: React.ReactNode
}

const InfoField: React.FC<TProperty> = ({ info, children }) => {
  return (
    <div className={styles['main-info-item']}>
      {info ? children : <Skeleton className={styles['text-placeholder']} />}
    </div>
  )
}

export { InfoField }
