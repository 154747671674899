import React, { useEffect, useMemo, useState } from 'react'
import { Table as UIKitTable } from '../../../../../components/UiKit/Table'
import cls from 'classnames'
import { Columns } from './Table.columns'
import styles from './Table.module.scss'
import Checkbox from 'components/UiKit/Checkbox/Checkbox'
import { TChecked, ICheckedList } from '../../Entrepreneurs.model'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { TUserData } from 'screens/admin/Users/Users.model'
import { getBankName } from '../../Helpers/getBankName'

type TTableProps = {
  name: string
  bank: string
  entrepreneurs: TUserData[]
  checksEntrepreneurs: TChecked[]
  setChecksEntrepreneurs: (value: TChecked[]) => void
}

const Table = ({
  name,
  bank,
  entrepreneurs,
  checksEntrepreneurs,
  setChecksEntrepreneurs
}: TTableProps) => {
  const words = useSelector((state: TState) => state.global.language.words)

  const [formValues, setFormValues] = useState<ICheckedList>({})
  const [isChecked, setIsChecked] = useState<boolean>(false)

  const filteredEntrepreneurs = useMemo(() => {
    const filteredUsers = entrepreneurs.filter(user => {
      const nameMatch =
        user.fullName && user.fullName.toLowerCase().includes(name && name.toLowerCase())
      const bankMatch =
        user.bank && user.bank.name.toLowerCase().includes(bank && bank.toLowerCase())
      return nameMatch && bankMatch
    })

    if (name === '' || !name) {
      const filteredByBank = entrepreneurs.filter(
        user => user.bank && user.bank.name.toLowerCase().includes(bank && bank.toLowerCase())
      )
      return filteredByBank
    } else {
      return filteredUsers
    }
  }, [name, bank, entrepreneurs])

  const columns = Columns({
    role: 2,
    filteredEntrepreneurs,
    formValues,
    checksEntrepreneurs,
    setFormValues,
    isChecked,
    setIsChecked
  })
  const columnsMemoized = useMemo(() => columns, [isChecked])

  useEffect(() => {
    if (checksEntrepreneurs.length < filteredEntrepreneurs.length) {
      setIsChecked(false)
    }
    if (checksEntrepreneurs.length === filteredEntrepreneurs.length) {
      setIsChecked(true)
    }
    if (filteredEntrepreneurs.length === 0) {
      setIsChecked(false)
    }
  }, [checksEntrepreneurs])

  useEffect(() => {
    setChecksEntrepreneurs(filteredEntrepreneurs
      .map((entrepreneur: TUserData) => {
        return formValues && formValues[entrepreneur.fullName + entrepreneur.id]
      })
      .filter(item => item !== undefined && item.checked) as TChecked[])
  }, [formValues])

  const handleCheckboxChange = (value: TUserData, e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues(old => {
      const newName = value.fullName + value.id
      const newObject = {
        ...value,
        checked: e.target.checked
      }
      return { ...old, [newName]: newObject } as ICheckedList
    })
  }

  return (
    <Form
      onSubmit={() => ({})}
      render={() => {
        return (
          <form className={styles['primary-form']}>
            <div className={styles['table-form']}>
              <Field name={'table'}>
                {() => (
                  <div data-tip="" data-for="model">
                    <UIKitTable
                      data={filteredEntrepreneurs.map((entrepreneur: TUserData) => {
                        return {
                          check: (
                            <>
                              <div />
                              <Checkbox
                                name={entrepreneur.fullName + entrepreneur.id}
                                checked={
                                  formValues &&
                                  formValues[entrepreneur.fullName + entrepreneur.id] &&
                                  formValues[entrepreneur.fullName + entrepreneur.id].checked
                                }
                                onChange={e => handleCheckboxChange(entrepreneur, e)}
                              />
                            </>
                          ),
                          name: entrepreneur.fullName,
                          bank: getBankName(entrepreneur)
                        }
                      })}
                      columns={columnsMemoized}
                      total={entrepreneurs.length}
                      getData={() => ({})}
                      classNameToRow={cls({
                        [styles.header]: true
                      })}
                      tableHeight={'444px'}
                      noData={words['admin.entrepreneurs.emptyList']}
                      onSelectedRows={() => ({})}
                      onRowClick={() => ({})}
                    />
                  </div>
                )}
              </Field>
            </div>
          </form>
        )
      }}
    />
  )
}
export default Table
