import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'
import TimeOffBalanceComponent from './TimeOffBalance'
import {
  addToken,
  changeUserTokenStatusThunk,
  fetchOneToken,
  fetchTokens
} from 'screens/admin/Token/Token.thunx'
import { getSelectedToken } from 'screens/admin/Token/Token.actions'

export const mapStateToProps = (state: TState) => {
  return {
    tokens: state.admin.token.data,
    tokensLoading: state.admin.token.loading,
    user: state.auth.data,
    selectedToken: state.admin.token.selectedToken
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchTokens,
      addToken,
      fetchOneToken,
      changeUserTokenStatusThunk,
      getSelectedToken
    },
    dispatch
  )

export const TimeOffBalance = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeOffBalanceComponent)
