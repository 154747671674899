import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { TState } from 'store'
import styles from './PerformerInfo.module.scss'
import { ReactComponent as DefaultAvatar } from 'assets/images/default_avatar.svg'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { useHistory } from 'react-router'
import { clearTaskList } from 'screens/client/Subcontract/Subcontract.actions'

export const PerformerInfo = ({ id, user }: any) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const history = useHistory()

  const [userPhoto, setUserPhoto] = useState('')
  const avatar = useSelector((state: TState) => state.auth.avatar)
  const dispatch = useDispatch()
  useEffect(() => {
    setUserPhoto(avatar)
  }, [])

  return (
    <>
      <NavLink
        exact={true}
        className={styles.onetask}
        to={`/dashboard/foreign-profile?userId=${id}&profileType=main-info&type=goods`}
      >
        <div className={styles['info-section']}>
          <div className={styles['user-info']}>
            <div className={styles['user']}>
              <div>
                {userPhoto ? (
                  <img className={styles.avatar} src={userPhoto} alt="avatar" />
                ) : (
                  <DefaultAvatar className={styles.avatar} />
                )}
              </div>
              <div className={styles['user-desc']}>
                <h2>{user.fullName}</h2>
                <p>{words[`${user.position.name}`]}</p>
                <p className={styles.score}>
                  {words['user.subcontract.rating']}:<span> 120</span>
                </p>
              </div>
            </div>
            <BaseButton
              children={words['user.subcontract.suggestTask']}
              size="percent"
              type="submit"
              onClick={e => {
                dispatch(clearTaskList())
                e.preventDefault()
                history.push(`/dashboard/subcontract/offer-task?page=1`)
              }}
            />
          </div>
          <div className={styles['description']}>
            <p>
              <span>{words['user.subcontract.task.technology']}: </span>
              <br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat at pretium imperdiet
              mauris nullam lorem pellentesque ullamcorper facilisis dolor sit amet, consectetur
              adipiscing...
            </p>
          </div>
        </div>
      </NavLink>
    </>
  )
}
