/* eslint-disable no-console */
import React, { FC } from 'react'
import styles from './AllTasksList.module.scss'
import { TaskInfo } from '../TaskInfo'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import InfiniteScroll from 'react-infinite-scroll-component'
import { TAllTasksListProps } from './AllTasksList.model'
import queryString from 'query-string'
import history from 'utils/history'
import Loading from '../../../components/Spinner'
import { finishPoint } from 'screens/client/Subcontract/components/CreateTask/initialvalues'

const AllTasksList: FC<TAllTasksListProps> = ({ getTasks, urlEndpoint }: any) => {
  const tasks = useSelector((state: TState) => state.client.subcontract.tasks)
  const parsed = queryString.parse(history.location.search)

  const pageCount = useSelector((state: TState) => state.client.subcontract.pageCount)
  const urlSearchParamses = new URLSearchParams(window.location.search)
  const paramses = Object.fromEntries(urlSearchParamses.entries())

  const fetchMore = () => {
    const url = new URL((window.location as unknown) as string)
    url.searchParams.set('page', String((paramses.page ? +paramses.page : 0) + 1))
    window.history.pushState({}, '', url.toString())

    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    getTasks(10, params.page, urlEndpoint, finishPoint(), parsed.clear === 'true')
  }

  return (
    <>
      <div className={styles['info-container']} style={{ width: '100%' }}>
        <InfiniteScroll
          dataLength={tasks.length}
          next={fetchMore}
          hasMore={(paramses.page ? +paramses.page : 0) + 1 <= pageCount}
          loader={<Loading />}
        >
          {tasks.map((task: any) => (
            <TaskInfo key={task.id + Math.random()} id={task.id} task={task} />
          ))}
        </InfiniteScroll>
      </div>
    </>
  )
}

export default AllTasksList
