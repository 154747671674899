import { Icons } from 'components/Icons'
import React from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import history from 'utils/history'
import styles from './BackButton.module.scss'

const BackButton = () => {
  const words = useSelector((state: TState) => state.global.language.words)

  return (
    <button
      className={styles['back-btn']}
      onClick={() => {
        history.goBack()
      }}
    >
      <Icons icon={'arrowLeftWithStick'} />
      {words['back']}
    </button>
  )
}
export default BackButton
