import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { Calendar } from 'react-calendar'
import { IProps } from './model'
import arrowLeft from '../../../../../assets/images/arrow-left.svg'
import arrowRight from '../../../../../assets/images/arrow-right.svg'
import './styles.scss'

const CustomCalendar: FC<IProps> = ({ value, onChange }) => {
  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)

  const formatMonthYear = (locale: any, date: any): any => {
    const month = new Intl.DateTimeFormat(locale, { month: 'long' }).format(date)
    const year = new Intl.DateTimeFormat(locale, { year: 'numeric' }).format(date)
    return [
      <span key={month} className="month">
        {month}
      </span>,
      <span key={year} className="year">
        {year}
      </span>
    ]
  }

  return (
    <Calendar
      onChange={onChange}
      value={value}
      locale={currentLanguage}
      formatMonthYear={formatMonthYear}
      showNeighboringMonth={false}
      minDetail={'year'}
      prevLabel={
        <span className="custom-arrow">
          <img src={arrowLeft} alt="arrow" />
        </span>
      }
      nextLabel={
        <span className="custom-arrow">
          <img src={arrowRight} alt="arrow" />
        </span>
      }
    />
  )
}

export default CustomCalendar
