import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import PolicyComponent from './Policy'
import { getPolicy, postPolicy } from './Policy.thunx'

export const mapStateToProps = (state: TState) => {
  return {
    data: state.client.policy.data,
    loading: state.client.policy.loading
  }
}

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      postPolicy,
      getPolicy
    },
    dispatch
  )

export const Policy = connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyComponent)
