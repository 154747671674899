import axios from 'axios'

export const getContractAbi = async (address: string) => {
  try {
    const url = `https://api-testnet.bscscan.com/api?module=contract&action=getabi&address=${address}`
    const result = await axios.get(url)

    return JSON.parse(result.data.result)
  } catch (err) {
    console.log(err)
  }
}
