import { RouteComponentProps } from 'react-router-dom'
import { mapDispatchToProps, mapStateToProps } from './CreateTask.container'

export type TCreateTaskProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps & {
    history: string
    form?: any
  }

export type TCreateTask = {
  type: string
  payload?: any
}

export const enum ETaskStatus {
  DRAFT = 1,
  CREATED = 2,
  COMPLETED = 3,
  AT_THE_INTERVIEW = 4,
  IN_WORK = 5,
  CANCELLED = 6,
  PLANNING = 7,
  WITH_VIOLATION = 8,
  CONTRACTOR_EXECUTED = 9,
  ACCEPTED = 10
}
