import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'
import TaskRequestComponent from './TaskRequest'
import { createTaskRequest } from './TaskRequest.thunk'
import { isTaskInTransaction } from '../Web3/web3.action'
import { getTask } from '../Subcontract/Subcontract.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    loading: state.client.taskRequest.loading,
    error: state.client.taskRequest.error,
    currentTask: state.client.subcontract.task,
    user: state.auth.data,
    isTransaction: state.client.web3.isTransaction
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ createTaskRequest, isTaskInTransaction, getTask }, dispatch)

export const TaskRequest = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskRequestComponent)
