import React from 'react'
// import styles from './MyTasksList.module.scss'
import { TaskInfo } from '../TaskInfo'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import InfiniteScroll from 'react-infinite-scroll-component'
import queryString from 'query-string'
import history from 'utils/history'
import { finishPoint } from 'screens/client/Subcontract/components/CreateTask/initialvalues'
import Spinner from 'components/Spinner'

export const MyTasksList = ({ btnQuery, getTasks, urlEndpoint, isTransaction }: any) => {
  const tasks = useSelector((state: TState) => state.client.subcontract.tasks)
  const parsed = queryString.parse(history.location.search)

  const pageCount = useSelector((state: TState) => state.client.subcontract.pageCount)

  const urlSearchParamses = new URLSearchParams(window.location.search)
  const paramses = Object.fromEntries(urlSearchParamses.entries())
  const fetchMore = () => {
    const url = new URL((window.location as unknown) as string)
    url.searchParams.set('page', String((paramses.page ? +paramses.page : 0) + 1))
    window.history.pushState({}, '', url.toString())

    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    getTasks(10, params.page, urlEndpoint, finishPoint(), parsed.clear === 'true')
  }

  return (
    <>
      {isTransaction && <Spinner isBackground={true} isTransaction={isTransaction} />}
      <div style={{ width: '100%' }}>
        <InfiniteScroll
          dataLength={tasks.length}
          next={fetchMore}
          hasMore={(paramses.page ? +paramses.page : 0) + 1 <= pageCount}
          loader={<h4>Loading...</h4>}
        >
          {tasks.map((task: any) => (
            <TaskInfo
              key={task.id}
              id={task.id}
              status={task.statusId}
              path={`/dashboard/subcontract/${
                task.id
              }?my_task=true&response=all-responses&from=${btnQuery}&taskId=${task.id}`}
              task={task}
            />
          ))}
        </InfiniteScroll>
      </div>
    </>
  )
}
