import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import GeneralInfoComponent from './GeneralInfo'
import { getExchangeInfoThunk } from '../ControlPanel/ControlPanel.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    user: state.auth.data,
    exchangeInfo: state.client.controlPanel.exchangeInfo,
    isLoading: state.client.controlPanel.isLoading,
    exchangeType: state.client.controlPanel.botInfo.exchangeType
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getExchangeInfoThunk
    },
    dispatch
  )

export const GeneralInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralInfoComponent)
