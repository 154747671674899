import { IInitialState } from './InterviewInvitation.model'
import { IAction } from '../../../models'
import {
  CHANGE_TIME_STATUS,
  SET_DATE,
  SEND_INTERVIEW_INVITATION_LOADING,
  SEND_INTERVIEW_INVITATION_SUCCESS,
  SEND_INTERVIEW_INVITATION_ERROR,
  GET_TIME_REQUEST,
  GET_TIME_SUCCESS,
  GET_TIME_ERROR,
  GET_INVITED_USER_REQUEST,
  GET_INVITED_USER_SUCCESS
} from './InterviewInvitation.constants'

export const initialState: IInitialState = {
  data: [],
  time: {},
  error: '',
  loading: false,
  invitedUser: null
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case CHANGE_TIME_STATUS:
      return {
        ...state,
        time: { ...state.time, ...action.payload }
      }
    case SET_DATE:
      return {
        ...state,
        data: action.payload
      }
    case SEND_INTERVIEW_INVITATION_LOADING:
    case GET_TIME_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case SEND_INTERVIEW_INVITATION_ERROR:
    case GET_TIME_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    }
    case SEND_INTERVIEW_INVITATION_SUCCESS: {
      return {
        ...state,
        loading: false
      }
    }
    case GET_TIME_SUCCESS: {
      return {
        ...state,
        time: { ...state.time, ...action.payload },
        loading: false
      }
    }
    case GET_INVITED_USER_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case GET_INVITED_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        invitedUser: action.payload
      }
    }
    default:
      return state
  }
}
