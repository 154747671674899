/* eslint-disable no-console */
import React, { FC, useEffect } from 'react'
import styles from '../Polygon/Polygon.module.scss'
import Spinner from 'components/Spinner'
import queryString from 'query-string'
import QRCode from 'react-qr-code'
import { TClaimsProps } from './Claims.model'
import { BaseButton } from 'components/UiKit/Button/BaseButton'

const Claims: FC<TClaimsProps> = ({ qrCode, qetClaimsQrCode, history, qrCodeLoading }) => {
  const parsed = queryString.parse(history.location.search)
  useEffect(() => {
    qetClaimsQrCode(parsed.offer)
  }, [])
  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>Verification</h1>
      </section>
      <div className={styles.claimsqr}>
        <h1>Claim</h1>
        {!qrCode.claims || qrCodeLoading ? (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        ) : (
          <QRCode level="Q" style={{ width: 256 }} value={JSON.stringify(qrCode.claims)} />
        )}
        <BaseButton children={'Back'} onClick={() => history.goBack()} />
      </div>
    </div>
  )
}

export default Claims
