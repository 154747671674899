export const CURRENCIES = [
  {
    value: 'USD',
    label: 'USD'
  },
  {
    value: 'UAH',
    label: 'UAH'
  }
]
