import { IAction } from 'models/index'
import { ActionCreator } from 'redux'
import { action } from 'store/store.utils'
import { ETokenActions } from './Token.constans'

export const doRequest: ActionCreator<IAction> = () => action(ETokenActions.GET_TOKEN_REQUEST)

export const requestSuccess: ActionCreator<IAction> = payload =>
  action(ETokenActions.GET_TOKEN_REQUEST_SUCCESS, payload)

export const changeUserTokenStatus: ActionCreator<IAction> = payload =>
  action(ETokenActions.CHANGE_USER_TOKEN_STATUS, payload)

export const changeAdminTokenStatus: ActionCreator<IAction> = payload =>
  action(ETokenActions.CHANGE_ADMIN_TOKEN_STATUS, payload)

export const addOneToken: ActionCreator<IAction> = payload =>
  action(ETokenActions.ADD_USER_TOKEN, payload)

export const fetchAllTokens: ActionCreator<IAction> = payload =>
  action(ETokenActions.GET_ALL_TOKENS, payload)

export const getSelectedToken: ActionCreator<IAction> = payload =>
  action(ETokenActions.SELECT_TOKEN, payload)
