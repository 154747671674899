import { action } from 'store/store.utils'
import { POLYGON_CLAIMS_LIST_SUCCESS } from '../VerificationAdmin.constants'
import {
  VERIFI_USER_REQUEST,
  VERIFI_USER_ERROR,
  VERIFI_USER_SUCCESS
} from './UserVerifiinfo.constants'
import { TAllTasksList } from './UserVerifiinfo.model'

export const verifiUserRequest = (): TAllTasksList => action(VERIFI_USER_REQUEST)

export const verifiUserError = (payload?: any): TAllTasksList => action(VERIFI_USER_ERROR, payload)

export const verifiUserSuccess = (payload?: any): TAllTasksList =>
  action(VERIFI_USER_SUCCESS, payload)

export const claimsListSuccess = (payload?: any): TAllTasksList =>
  action(POLYGON_CLAIMS_LIST_SUCCESS, payload)
