import { fetchAllTokensThunk } from 'screens/admin/Token/Token.thunx'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import SubcontractTasksComponent from './SubcontractTasks'
import {
  getTasks,
  getUsers,
  clearStore,
  clearTask
} from '../../client/Subcontract/Subcontract.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    taskArray: state.client.subcontract.tasks,
    usersArray: state.client.subcontract.users,
    loading: state.client.subcontract.loading,
    loadedPage: state.client.subcontract.page,
    pageCount: state.client.subcontract.pageCount,
    itemsCount: state.client.subcontract.itemsCount,
    paginLoading: state.client.subcontract.paginLoading,
    page: state.client.subcontract.page,
    tokens: state.admin.token.allTokens
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getTasks,
      getUsers,
      clearStore,
      clearTask,
      fetchAllTokensThunk
    },
    dispatch
  )

export const SubcontractTasks = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubcontractTasksComponent)
