import React, { FC, useEffect, useRef, useState, useCallback } from 'react'
import {
  default as ReactSelect,
  components as rComponents,
  OptionProps,
  SelectInstance,
  StylesConfig
} from 'react-select'
import AsyncSelect from 'react-select/async'
import cls from 'classnames'
import styles from './Select.module.scss'
import { TSelectProps } from './Select.model'
import { Icons } from '../components/Icons'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useSelector } from 'react-redux'
import { TState } from 'store'

const Menu = (props: any) => {
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  const handleAdd = useCallback(props.selectProps.addCategory, [])
  const getData = useCallback(props.selectProps.getData, [])
  const words = useSelector((state: TState) => state.global.language.words)

  return (
    <rComponents.Menu {...props}>
      {props.children}
      <div>
        <div className={styles['add-input-title']}>{words['admin.shop.new.createCategory']}</div>
        <div className={styles['add-input-container']}>
          <input
            tabIndex={0}
            className={styles['add-input']}
            type="text"
            ref={inputRef}
            onKeyDown={event => {
              if (event.keyCode === 13) {
                // 13 === Enter
                event.stopPropagation()
                if (inputValue === '') {
                  return
                }
                handleAdd(inputValue)
                getData()
              }
            }}
            onClick={() => {
              const elem = inputRef.current
              if (elem !== null) {
                elem.focus()
                props.selectRef.current.onInputFocus((event: any) => {
                  event.preventDefault()
                })
              }
            }}
            onChange={event => {
              setInputValue(event.target.value)
            }}
            value={inputValue}
          />

          <button
            className={cls({
              [styles['input-btn']]: true
            })}
            type="button"
            onClick={() => {
              if (inputValue === '') {
                return
              }
              handleAdd(inputValue)
            }}
          >
            <Icons icon="plusIcon" />
          </button>
        </div>
      </div>
    </rComponents.Menu>
  )
}

const Option = (props: OptionProps<any, any> & { isSelectedAll?: boolean }) => {
  const { children, isSelected, innerRef, innerProps, isSelectedAll } = props

  return (
    <div
      ref={innerRef}
      className={cls({
        [styles.option]: true,
        [styles['option-selected']]: isSelected || isSelectedAll
      })}
      {...innerProps}
    >
      {children}
    </div>
  )
}

const NoOptionsMessage = (props: any) => {
  return <rComponents.NoOptionsMessage {...props}>{props.children}</rComponents.NoOptionsMessage>
}

export const Select: FC<TSelectProps> = ({
  inRef = null,
  isAddNewItem,
  isCheckboxesStyle,
  isSelectedAll,
  styles: customStyles,
  isInvalid,
  errorMessage,
  isAsync,
  loadOptions,
  emptyMessage,
  defaultValue,
  ...props
}) => {
  const [selectRef, setSelectRef] = useState(inRef)
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
    if (selectRef && selectRef.current) {
      selectRef.current.blur()
    }
  }

  const newRef = useRef<SelectInstance | null>(null)
  useEffect(() => {
    if (!inRef) {
      setSelectRef(newRef)
    }
  }, [inRef])
  const colorBorder = props.isDisabled
    ? { borderColor: '#9A9A9A' }
    : isInvalid
    ? { borderColor: '#D12A6C' }
    : { borderColor: '#3737ED' }

  const baseStyles: StylesConfig = {
    container: style => ({
      ...style,
      minWidth: '100%',
      height: '32px',
      marginTop: '7px',
      color: '#333333',
      button: { height: '27px', width: '90px' },
      overflow: 'hidden'
    }),
    menuPortal: style => ({ ...style, zIndex: 10202 }),
    control: style => ({
      ...style,
      ...colorBorder,
      borderRadius: props.style ? props.style.borderRadius : '10px',
      borderLeft: props.style ? props.style.borderLeft : '',
      minHeight: '30px',
      height: '30px',
      boxShadow: 'none',
      backgroundColor: 'hsl(0,0%,100%)',
      ':hover': isInvalid ? { borderColor: '#D12A6C' } : { borderColor: '#3737ED' }
    }),
    singleValue: style => ({
      ...style,
      div: { span: { display: 'none' } },
      top: '45%',
      transform: 'translateY(-18%)'
    }),
    valueContainer: style => ({ ...style, fontSize: '12px', height: '27px', textAlign: 'left' }),
    multiValue: style => ({
      ...style,
      borderRadius: '10px',
      backgroundColor: '#EFEFFF',
      padding: '0',
      marginRight: '8px',
      height: '20px',
      ':last-child': {
        marginRight: '0'
      }
    }),
    multiValueLabel: style => ({
      ...style,
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      color: '#333333',
      padding: '1px 0px 3px 8px',
      margin: '0',
      height: '20px'
    }),
    multiValueRemove: style => ({
      ...style,
      ':hover': { backgroundColor: 'transparent' },
      cursor: 'pointer',
      height: '100%',
      color: '#9A9A9A'
    }),
    option: (style, state: any) => ({
      ...style,
      fontSize: '14px',
      lineHeight: '16px',
      paddingTop: '3px',
      paddingBottom: '4px',
      color: '#333333',
      ':hover': { backgroundColor: '#EFEFFF' },
      backgroundColor: state.isSelected && '#EFEFFF'
    }),
    indicatorsContainer: style => ({
      ...style,
      height: '30px',
      div: { padding: '4px' }
    }),
    indicatorSeparator: style => ({ ...style, display: 'none' }),
    placeholder: style => ({
      ...style,
      top: '12px',
      color: isInvalid ? '#D12A6C' : '#9A9A9A'
    })
  }

  const asyncStyles: StylesConfig = {
    indicatorsContainer: style => ({
      ...style,
      display: 'none'
    })
  }

  const renderSelect = () => {
    if (isAsync && loadOptions) {
      return (
        <AsyncSelect
          ref={selectRef as any}
          {...props}
          styles={{ ...baseStyles, ...asyncStyles, ...(customStyles as any) }}
          cacheOptions={true}
          loadOptions={loadOptions}
          components={{
            NoOptionsMessage: (action: any) => (
              <NoOptionsMessage {...action}>{emptyMessage}</NoOptionsMessage>
            )
          }}
        />
      )
    }

    if (isAddNewItem) {
      return (
        <ReactSelect
          ref={inRef as any}
          {...props}
          defaultValue={defaultValue}
          styles={{ ...baseStyles, ...(customStyles as any) }}
          menuPortalTarget={document.body}
          menuIsOpen={isOpen}
          onFocus={handleOpen}
          onInputChange={handleClose}
          components={{
            Menu: isAddNewItem
              ? (menuProps: any) => <Menu {...menuProps} selectRef={inRef} />
              : undefined,
            NoOptionsMessage: (action: any) => (
              <NoOptionsMessage {...action}>{emptyMessage}</NoOptionsMessage>
            )
          }}
          // isSearchable={!isAddNewItem} TODO --- test without search
          backspaceRemovesValue={!isAddNewItem}
          openMenuOnFocus={true}
        />
      )
    }

    if (isCheckboxesStyle) {
      return (
        <>
          <div data-tip="" data-for="select" id={props.label}>
            <ReactSelect
              ref={selectRef as any}
              {...props}
              components={{
                Option: (optionProps: OptionProps<any, any>) => (
                  <Option {...optionProps} isSelectedAll={isSelectedAll} />
                ),
                NoOptionsMessage: (action: any) => (
                  <NoOptionsMessage {...action}>{emptyMessage}</NoOptionsMessage>
                )
              }}
              menuPortalTarget={document.body}
              styles={{ ...baseStyles, ...(customStyles as any) }}
            />
          </div>
          {isInvalid && errorMessage && (
            <ReactTooltip
              id="select"
              anchorSelect={`#${props.label}`}
              place="bottom"
              variant="error"
              content={errorMessage}
              className={styles.tooltip}
            />
          )}
        </>
      )
    }

    return (
      <>
        <div
          className={styles['wrapper-for-tooltip']}
          data-tip=""
          data-for="select"
          id={props.label}
        >
          <ReactSelect
            ref={selectRef as any}
            {...props}
            styles={{ ...baseStyles, ...(customStyles as any) }}
            menuPortalTarget={document.body}
            // styles={{ menuPortal: base => ({ ...base, zIndex: 0, overflow: 'hidden' }) }}
            // onClick={onclick}
            components={{
              NoOptionsMessage: (action: any) => (
                <NoOptionsMessage {...action}>{emptyMessage}</NoOptionsMessage>
              ),
              ...props.components
            }}
          />
        </div>
        {isInvalid && errorMessage && (
          <ReactTooltip
            id="select"
            anchorSelect={`#${props.label}`}
            place="bottom"
            variant="error"
            content={errorMessage}
            className={styles.tooltip}
          />
        )}
      </>
    )
  }

  return renderSelect()
}
