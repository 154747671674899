import { Dispatch } from 'redux'
import { API, api } from 'services/api'
import {
  addOneToken,
  changeUserTokenStatus,
  doRequest,
  requestSuccess,
  changeAdminTokenStatus,
  fetchAllTokens
} from './Token.actions'
import { filteredTokensByValue } from 'helpers/filteredTokensByValue'
import { IToken } from './Token.model'
import axios from 'axios'
import { ETokenStatus } from './Token.constans'

export const fetchTokens = (userId: number) => async (dispatch: Dispatch) => {
  try {
    const { data } = await api.get(`${API.URL}/get-tokens/${userId}`)

    dispatch(doRequest())
    dispatch(requestSuccess(data))
  } catch (error) {
    console.log(error)
  }
}

export const fetchAllTokensThunk = () => async (dispatch: Dispatch) => {
  try {
    const response = await api.get(`${API.URL}/tokens`)
    dispatch(fetchAllTokens(response.data))
  } catch (err) {
    console.log(err)
  }
}

export const changeUserTokenStatusThunk = (
  userId: number,
  status: ETokenStatus,
  token: IToken
) => async (dispatch: Dispatch) => {
  try {
    await api.put(`${API.URL}/change-token-status`, { data: { userId, tokenId: token.id, status } })
    dispatch(changeUserTokenStatus({ token, status }))
  } catch (error) {
    console.log(error)
  }
}

export const changeAdminTokenStatusThunk = (token: IToken) => async (dispatch: Dispatch) => {
  try {
    await api.put(`${API.URL}/tokens/change-token-status`, token)
    dispatch(changeAdminTokenStatus(token))
  } catch (error) {
    console.log(error)
  }
}

export const fetchOneToken = async (value: string): Promise<any> => {
  try {
    const allTokens = await axios.get('https://tokens.pancakeswap.finance/pancakeswap-default.json')
    let token: IToken | undefined

    if (value.length === 42) {
      token = filteredTokensByValue(allTokens.data.tokens, value, 'address')[0]
    } else {
      token = filteredTokensByValue(allTokens.data.tokens, value, 'name')[0]
    }

    return token
  } catch (error) {
    console.log(error)
  }
}

export const addToken = (token: IToken) => async (dispatch: Dispatch) => {
  try {
    const response = await api.put(`${API.URL}/add-token`, token)
    dispatch(addOneToken(response.data))
  } catch (error) {
    console.log(error)
  }
}
