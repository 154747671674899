import { INotificationsItem } from 'screens/client/Notifications/Notifications.model'
import { SocketEvent } from '../constants/socket-event.constant'
import { Dispatch } from 'redux'
import { addNotification } from 'screens/client/Notifications/Notifications.actions'

class NotificationService {
  constructor(private readonly socket: SocketIOClient.Socket) {}
  notificationConnect(userId: number, dispatch: Dispatch) {
    this.socket.emit(SocketEvent.NOTIFICATION_CONNECT, { id: userId })
    this.socket.on(SocketEvent.NEW_NOTIFICATION, (data: INotificationsItem) => {
      dispatch(addNotification(data))
    })
  }
}

export { NotificationService }
