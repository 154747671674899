import { TBaseFilterConfigData } from 'components/UiKit/Filter/Filter'
import { TWords } from 'elements/SideBar/SideBar.config'

export const getProfitGraph = (words: TWords): TBaseFilterConfigData[] => {
  return [
    {
      name: 'startDate',
      secondName: 'endDate',
      type: 'dateRangeNew',
      label: words['crypto-bot.profit'],
      rangeContainerWidth: '19%',
      fieldData: {
        from: '',
        to: ''
      }
    }
  ]
}
