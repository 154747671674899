import { TBaseFilterConfigData } from 'components/UiKit/Filter/Filter'
import { TSelectOption } from '../../../components/Select'
import { TWords } from 'elements/SideBar/SideBar.config'
import { getStatusOptions } from 'screens/client/Subcontract/components/CreateTask/initialvalues'
import moment from 'moment/moment'
import { currency } from './initialValues'

export const getFilterConfig = ({
  role,
  fromDate,
  toDate,
  parsed,
  words
}: {
  role: number
  fromDate: any
  toDate: any
  usersData?: TSelectOption[]
  managersData?: TSelectOption[]
  parsed: any
  words: TWords
}): TBaseFilterConfigData[] => {
  // const currentFormatMinFromDate = new Date(fromDate).valueOf()
  // const currentFormatMaxToDate = new Date(toDate).valueOf()
  const statusOptions = getStatusOptions(words)
  switch (role) {
    case 4:
    default: {
      return [
        {
          name: 'fullName',
          secondName: '',
          type: 'input',
          label: words['entrepreneur.invoices.fullName'],
          width: '20%'
        },
        {
          name: 'dateFrom',
          secondName: 'dateTo',
          type: 'dateRangeNew',
          fieldData: {
            from: fromDate ? moment(+fromDate).valueOf() : '',
            to: toDate ? moment(+toDate).valueOf() : ''
          },
          label: words['entrepreneur.invoices.dateOfFormation'],
          width: '20%'
        },
        {
          name: 'currency',
          type: 'select',
          label: words['entrepreneur.invoices.currency'],
          placeholder: '',
          fieldData: currency,
          width: '7%'
        },
        {
          name: 'fromPrice',
          secondName: 'toPrice',
          type: 'inputRange',
          label: words['entrepreneur.invoices.amount'],
          fieldData: {
            from: parsed.fromPrice ? parsed.fromPrice : '',
            to: parsed.toPrice ? parsed.toPrice : ''
          },
          width: '100%',
          rangeContainerWidth: '20%',
          inputType: 'number'
        },
        {
          name: 'status',
          type: 'select',
          fieldData: statusOptions,
          label: words['entrepreneur.invoices.status'],
          placeholder: '',
          width: '13%'
        }
      ]
    }
  }
}
