import { IChannelSettings } from 'screens/client/CryptoBot/components/ControlPanel/ControlPanel.types'

export function updatedChannelsSettings(
  settings: IChannelSettings[],
  newSettings: IChannelSettings
) {
  const updatedSettings = settings.filter(
    setting => setting.channelName !== newSettings.channelName
  )

  return [...updatedSettings, newSettings]
}
