import { TBaseFilterConfigData } from 'components/UiKit/Filter/Filter'
import { TWords } from 'elements/SideBar/SideBar.config'

export const getFilterConfig = ({ words }: { words: TWords }): TBaseFilterConfigData[] => {
  const filter: TBaseFilterConfigData[] = [
    {
      name: 'keyword',
      type: 'input',
      label: words['project.history.search'],
      width: '22%'
    },
    {
      name: 'changesFromDate',
      secondName: 'changesToDate',
      type: 'dateRangeNew',
      label: words['project.history.changesDate'],
      fieldData: {
        from: '',
        to: ''
      },
      rangeContainerWidth: '20%'
    }
  ]
  return filter
}
