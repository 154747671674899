import { Dispatch } from 'redux'
import { API, api } from 'services/api'
import {
  getForeignProfileError,
  getForeignProfileRequest,
  getForeignProfileSuccess,
  getUsersError,
  getUsersRequest,
  getUsersSuccess
} from './Team.actions'
import { AxiosError } from 'axios'

export const getTeamUsers = (page: number = 1, search: string = '', limit: number = 20) => async (
  dispatch: Dispatch
) => {
  dispatch(getUsersRequest())
  try {
    const { data } = await api.get(
      `${API.TEAM}${search}${
        search ? '&' : '?'
      }page=${page}&limit=${limit}&column=fullName&sort=ASC&deleted=false`
    )
    dispatch(getUsersSuccess(data.payload))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(getUsersError(err))
  }
}

export const getForeignProfile = (id: number) => async (dispatch: Dispatch) => {
  dispatch(getForeignProfileRequest())
  try {
    const { data } = await api.get(`${API.FOREIGN_PROFILE}/${id}`)
    dispatch(getForeignProfileSuccess(data.payload))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(getForeignProfileError(err))
  }
}
