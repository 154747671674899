import { IToken } from 'screens/admin/Token/Token.model'

export function filteredTokensByValue(
  array: IToken[],
  value: string,
  key: keyof Pick<IToken, 'name' | 'address'>
): IToken[] {
  return array.filter(item =>
    value ? item[key].toLowerCase().includes(value.trimStart().toLowerCase()) : array
  )
}
