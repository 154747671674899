export const SET_NEW_ITEM_REQUEST = 'SET_NEW_ITEM_REQUEST'
export const SET_NEW_ITEM_SUCCESS = 'SET_NEW_ITEM_SUCCESS'
export const SET_NEW_ITEM_ERROR = 'SET_NEW_ITEM_ERROR'
export const GET_GOODS_CATEGORIES_REQUEST = 'GET_GOODS_CATEGORIES_REQUEST'
export const GET_GOODS_CATEGORIES_SUCCESS = 'GET_GOODS_CATEGORIES_SUCCESS'
export const GET_GOODS_CATEGORIES_ERROR = 'GET_GOODS_CATEGORIES_ERROR'

export const GET_CURRENT_ITEM_REQUEST = 'GET_CURRENT_ITEM_REQUEST'
export const GET_CURRENT_ITEM_SUCCESS = 'GET_CURRENT_ITEM_SUCCESS'
export const GET_CURRENT_ITEM_ERROR = 'GET_CURRENT_ITEM_ERROR'

export const REMOVE_REVIEW_REQUEST = 'REMOVE_REVIEW_REQUEST'
export const REMOVE_REVIEW_SUCCESS = 'REMOVE_REVIEW_SUCCESS'
export const REMOVE_REVIEW_ERROR = 'REMOVE_REVIEW_ERROR'

export const REMOVE_REVIEW_ADMIN_REQUEST = 'REMOVE_REVIEW_ADMIN_REQUEST'
export const REMOVE_REVIEW_ADMIN_SUCCESS = 'REMOVE_REVIEW_ADMIN_SUCCESS'
export const REMOVE_REVIEW_ADMIN_ERROR = 'REMOVE_REVIEW_ADMIN_ERROR'

export const SET_NEW_CATEGORY_REQUEST = 'SET_NEW_CATEGORY_REQUEST'
export const SET_NEW_CATEGORY_SUCCESS = 'SET_NEW_CATEGORY_SUCCESS'
export const SET_NEW_CATEGORY_ERROR = 'SET_NEW_CATEGORY_ERROR'

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST'
export const DELETE_GOODS_CATEGORY_SUCCESS = 'DELETE_GOODS_CATEGORY_SUCCESS'
export const DELETE_ACHIEVEMENT_CATEGORY_SUCCESS = 'DELETE_ACHIEVEMENT_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR'
