import React, { useEffect, useState } from 'react'
import styles from './CreateProject.module.scss'
import { Icons } from 'components/Icons'
import {
  IFileItem,
  IFormValues,
  ISelectData,
  TCreateProjectProps,
  TSetFileItem
} from './CreateProject.model'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store'
import { Form } from 'react-final-form'
import validation from 'utils/validation'
import { Button } from 'components/UiKit/Button'
import { TSelectOption } from 'components/Select'
import { FileView } from 'components/Profile/PersonalInfo/FileView'
import ImageUpload from './components/ImageUpload/ImageUpload'
import InputProject from './components/InputProject/InputProject'
import DateProject from './components/DateProject/DateProject'
import SelectProject from './components/SelectProject/SelectProject'
import Modal from 'components/Modal'
import { clearOneProjectSuccess } from '../Projects/Projects.actions'
import { FormState } from 'final-form'
import moment from 'moment'
import { FORMAT_MMDDYYYY } from 'screens/client/NewRequestTimeOff/NewRequestTimeOff'
import toastr from 'utils/toastr'
import { setProject, editProject, setProjectHistory } from './CreateProject.thunk'

export const modelsData: TSelectOption[] = [
  {
    value: '1',
    label: 'Dedicated Team'
  },
  {
    value: '2',
    label: 'Fixed Price'
  },
  {
    value: '3',
    label: 'Project Base'
  },
  {
    value: '4',
    label: 'Times and materials'
  }
]

export const stageData: TSelectOption[] = [
  {
    value: '1',
    label: 'Активная разработка и саппорт'
  }
]

export const methodologyData: TSelectOption[] = [
  {
    value: '1',
    label: 'Scrum'
  },
  {
    value: '2',
    label: 'Kanban'
  }
]

export const paymentMethodData: TSelectOption[] = [
  {
    value: '1',
    label: 'Постоплата'
  },
  {
    value: '2',
    label: 'Предоплата'
  }
]

export const managerData: TSelectOption[] = [
  {
    value: '1',
    label: 'Margaret Hamilton'
  }
]

export const customerAssistantData: TSelectOption[] = [
  {
    value: '1',
    label: 'Margaret Hamilton'
  }
]

export const unitData: TSelectOption[] = [
  {
    value: '1',
    label: 'Часы'
  }
]

const CreateProject = ({
  history,
  match,
  projectInfo,
  getOneProject,
  getManagers,
  userId,
  managers
}: TCreateProjectProps) => {
  const words = useSelector((state: TState) => state.global.language.words)

  const isEdit = !!match.params.id

  const dispatch = useDispatch()

  useEffect(() => {
    if (isEdit) {
      const { id } = match.params
      getOneProject(+id)
    }

    return () => {
      dispatch(clearOneProjectSuccess())
    }
  }, [])

  useEffect(() => {
    getManagers()
  }, [])

  const [deletedFilesId, setDeletedFilesId] = useState<number[]>([])
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false)
  const [files, setFiles] = useState<IFileItem[]>([])
  const [avatar, setAvatar] = useState([])
  const [model, setModel] = useState<ISelectData>({
    value: '1',
    label: 'Dedicated Team'
  })
  const [stage, setStage] = useState<ISelectData>({
    value: '1',
    label: 'Активная разработка и саппорт'
  })
  const [methodology, setMethodology] = useState<ISelectData>({
    value: '1',
    label: 'Scrum'
  })
  const [paymentMethod, setPaymentMethod] = useState<ISelectData>({
    value: '1',
    label: 'Scrum'
  })
  const [unit, setUnit] = useState<ISelectData>({
    value: '1',
    label: 'Scrum'
  })
  const [manager, setManager] = useState<any>(managers[0])
  const [customerAssistant, setCustomerAssistant] = useState<any>(managers[0])

  const getSelectedData = (value: string, data: ISelectData[]) => {
    const filteredData = data.filter(item => item.value === value)

    return filteredData[0]
  }

  useEffect(() => {
    projectInfo && setFiles(projectInfo.projectFiles)
  }, [projectInfo])

  // const managerData: TSelectOption[] = [
  //   {
  //     value: '1',
  //     label: 'Margaret Hamilton'
  //   }
  // ]
  // const customerAssistantData: TSelectOption[] = [
  //   {
  //     value: '1',
  //     label: 'Margaret Hamilton'
  //   }
  // ]

  const onSelectFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    choosen: IFileItem[],
    setChoosen: React.Dispatch<React.SetStateAction<IFileItem[]>>
  ): void => {
    if (e.target.files && e.target.files[0].size > 10485760) {
      alert('File is too big!')
      return
    }
    const f = e.target.files && e.target.files[0]
    if (f) {
      const newFiles = [...choosen]
      const blob = new Blob([f], { type: f.type })
      const url = URL.createObjectURL(blob)
      newFiles.push({ name: f.name, link: url, file: f })
      setChoosen(newFiles)
    }
  }

  const deleteFile = (
    file: IFileItem,
    idx: number,
    choosen: IFileItem[] = files,
    setChoosen: TSetFileItem = setFiles
  ) => {
    const newFilesArr = [...choosen]
    newFilesArr.splice(idx, 1)
    setChoosen(newFilesArr)

    if (file.id) {
      const newDeletedFilesId: number[] = [...deletedFilesId]
      newDeletedFilesId.push(+file.id)
      setDeletedFilesId(newDeletedFilesId)
    }
  }

  //TODO add types for values
  const handleCreateRequest = async (values: any, { modified }: FormState<any>): Promise<void> => {
    const request = new FormData()
    request.append('name', String(values.name))
    request.append('description', String(values.description))
    request.append('start', new Date(values.start).toISOString())
    request.append('end', new Date(values.end).toISOString())
    request.append('model', String(model.value))
    request.append('stage', String(stage.value))
    request.append('methodology', String(methodology.value))
    request.append('paymentMethod', String(paymentMethod.value))
    request.append('unit', String(unit.value))
    request.append('manager', String(manager.value))
    request.append('customerAssistant', String(customerAssistant.value))
    request.append('stack', String(values.stack))
    request.append('structure', String(values.structure))
    request.append('companyName', String(values.companyName))
    request.append('registerNumber', String(values.registerNumber))
    request.append('fullAddress', String(values.fullAddress))
    request.append('email', String(values.email))
    if (avatar.length > 0) {
      avatar.map((blob: IFileItem) => {
        if (blob.file) {
          request.append('avatar', blob.file)
        }
      })
    }
    if (files.length > 0) {
      files.map((item: IFileItem) => {
        if (item.file) {
          request.append(`projectFiles`, item.file)
        }
      })
    }

    if (deletedFilesId.length > 0) {
      request.append('deletedFilesList', JSON.stringify(deletedFilesId))
    }

    const projectHistory: any = {
      projectId: +match.params.id,
      changes: 'info',
      author: userId,
      changesDate: new Date().toISOString(),
      team: ''
    }

    if (!isEdit) {
      const result = await setProject(request)

      result.success
        ? setIsSuccessful(true)
        : result.errorMessage && toastr('error', result.errorMessage)
    } else {
      for (const field in modified) {
        if (modified[field]) {
          if (!projectHistory.fields) {
            projectHistory.fields = {}
          }

          projectHistory.fields[field] = {
            fieldName: field,
            before: projectInfo[field],
            after: values[field]
          }
        }
      }

      if (projectHistory.fields) {
        projectHistory.fields = JSON.stringify(projectHistory.fields)
      } else {
        projectHistory.fields = '{}'
      }
      const result = await editProject(request, +match.params.id)
      const historyResult = await setProjectHistory(projectHistory)

      historyResult.errorMessage && toastr('error', historyResult.errorMessage)

      result.success
        ? setIsSuccessful(true)
        : result.errorMessage && toastr('error', result.errorMessage)
    }
  }

  const cancelHandler = () => {
    history.goBack()
  }
  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>{isEdit ? words['project.updateProject'] : words['project.createProject']}</h1>
      </section>
      <section>
        <div className={styles['block-go-back']}>
          <button
            type="button"
            className={styles['go-back']}
            onClick={event => {
              event.preventDefault()
              history.goBack()
            }}
          >
            <Icons icon="backArrowIcon" />
            <span className={styles['go-back-text']}>{words['back']}</span>
          </button>
        </div>
      </section>
      {/* <section> */}
      <Form
        initialValues={{
          name: projectInfo ? projectInfo.name : '',
          description: projectInfo ? projectInfo.description : '',
          start: projectInfo ? moment(projectInfo.start).format(FORMAT_MMDDYYYY) : '',
          end: projectInfo ? moment(projectInfo.end).format(FORMAT_MMDDYYYY) : '',
          model: projectInfo ? getSelectedData(projectInfo.model.toString(), modelsData) : '',
          stage: projectInfo ? getSelectedData(projectInfo.stage.toString(), stageData) : '',
          methodology: projectInfo
            ? getSelectedData(projectInfo.methodology.toString(), methodologyData)
            : '',
          paymentMethod: projectInfo
            ? getSelectedData(projectInfo.paymentMethod.toString(), paymentMethodData)
            : '',
          unit: projectInfo ? getSelectedData(projectInfo.unit.toString(), unitData) : '',
          manager: projectInfo ? getSelectedData(projectInfo.manager.toString(), managers) : '',
          customerAssistant: projectInfo
            ? getSelectedData(projectInfo.customerAssistant.toString(), managers)
            : '',
          stack: projectInfo ? projectInfo.stack : '',
          structure: projectInfo ? projectInfo.structure : '',
          companyName: projectInfo ? projectInfo.companyName : '',
          registerNumber: projectInfo ? projectInfo.registerNumber : '',
          fullAddress: projectInfo ? projectInfo.fullAddress : '',
          email: projectInfo ? projectInfo.email : ''
        }}
        onSubmit={(values, form) => handleCreateRequest(values as IFormValues, form.getState())}
        render={({ form, handleSubmit }) => {
          const formState = form.getState()
          return (
            <form onSubmit={handleSubmit}>
              <div className={styles['content-container']}>
                <ImageUpload
                  validation={validation}
                  avatar={avatar}
                  setAvatar={setAvatar}
                  projectSrc={projectInfo ? projectInfo.avatar : ''}
                  isEdit={isEdit}
                />
                <div className={styles['form-column-container']}>
                  <div className={styles['form-row-flex-container']}>
                    <InputProject
                      name={'name'}
                      form={form}
                      label={words['project.name']}
                      type={'input'}
                    />
                    <div className={styles['info-description']}>
                      <InputProject
                        name={'description'}
                        form={form}
                        label={words['project.description']}
                        type={'textarea'}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles['main-information']} ${styles['infoes-block']}`}>
                <h2>{words['project.main']}</h2>
                <div className={styles['form-wrapper']}>
                  <div className={styles['left-column']}>
                    <div className={styles['left-column-block']}>
                      <div className={styles['two-columns']}>
                        <div className={styles['left-column']}>
                          <label className={styles['label-for-field']}>
                            {words['project.start']}
                            <span className={styles['red-note']}>*</span>
                          </label>
                          <DateProject
                            validation={validation}
                            name={'start'}
                            form={form}
                            formState={formState}
                            selected={projectInfo ? projectInfo.start : null}
                          />
                        </div>
                        <div className={styles['right-column']}>
                          <label className={styles['label-for-field']}>
                            {words['project.end']}
                            <span className={styles['red-note']}>*</span>
                          </label>
                          <DateProject
                            validation={validation}
                            name={'end'}
                            form={form}
                            formState={formState}
                            selected={projectInfo ? projectInfo.end : null}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles['left-column-block']}>
                      <label className={styles['label-for-field']}>
                        {words['project.model']}
                        <span className={styles['red-note']}>*</span>
                      </label>
                      <SelectProject
                        validation={validation}
                        name={'model'}
                        data={modelsData}
                        setData={setModel}
                        form={form}
                      />
                    </div>
                    <div className={styles['left-column-block']}>
                      <label className={styles['label-for-field']}>
                        {words['project.stage']}
                        <span className={styles['red-note']}>*</span>
                      </label>
                      <SelectProject
                        validation={validation}
                        name={'stage'}
                        data={stageData}
                        setData={setStage}
                        form={form}
                      />
                    </div>
                    <div className={styles['left-column-block']}>
                      <label className={styles['label-for-field']}>
                        {words['project.methodology']}
                        <span className={styles['red-note']}>*</span>
                      </label>
                      <SelectProject
                        validation={validation}
                        name={'methodology'}
                        data={methodologyData}
                        setData={setMethodology}
                        form={form}
                      />
                    </div>
                    <div className={styles['left-column-block']}>
                      <div className={styles['two-columns']}>
                        <div className={styles['left-column']}>
                          <label className={styles['label-for-field']}>
                            {words['project.payment']}
                            <span className={styles['red-note']}>*</span>
                          </label>
                          <SelectProject
                            validation={validation}
                            name={'paymentMethod'}
                            data={paymentMethodData}
                            setData={setPaymentMethod}
                            form={form}
                          />
                        </div>
                        <div className={styles['right-column']}>
                          <label className={styles['label-for-field']}>
                            {words['project.unit']}
                            <span className={styles['red-note']}>*</span>
                          </label>
                          <SelectProject
                            validation={validation}
                            name={'unit'}
                            data={unitData}
                            setData={setUnit}
                            form={form}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles['left-column-block']}>
                      <label className={styles['label-for-field']}>
                        {words['project.manager']}
                        <span className={styles['red-note']}>*</span>
                      </label>
                      <SelectProject
                        validation={validation}
                        name={'manager'}
                        data={managers}
                        setData={setManager}
                        form={form}
                      />
                    </div>
                    <div className={styles['left-column-block']}>
                      <label className={styles['label-for-field']}>
                        {words['project.assistant']}
                        <span className={styles['red-note']}>*</span>
                      </label>
                      <SelectProject
                        validation={validation}
                        name={'customerAssistant'}
                        data={managers}
                        setData={setCustomerAssistant}
                        form={form}
                      />
                    </div>
                  </div>
                  <div className={styles['right-column']}>
                    <div className={styles['right-column-block']}>
                      <InputProject
                        name={'stack'}
                        form={form}
                        label={words['project.stack']}
                        type={'textarea'}
                      />
                    </div>
                    <div className={styles['right-column-block']}>
                      <InputProject
                        name={'structure'}
                        form={form}
                        label={words['project.structure']}
                        type={'textarea'}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles['client-information']} ${styles['infoes-block']}`}>
                <h2>{words['project.customerData']}</h2>
                <div className={styles['form-wrapper']}>
                  <div className={styles['left-column']}>
                    <div className={styles['left-column-block']}>
                      <InputProject
                        name={'companyName'}
                        form={form}
                        label={words['project.fullName']}
                        type={'input'}
                      />
                    </div>
                    <div className={styles['left-column-block']}>
                      <InputProject
                        name={'registerNumber'}
                        form={form}
                        label={words['project.registerNumber']}
                        type={'input'}
                      />
                    </div>
                  </div>
                  <div className={styles['right-column']}>
                    <div className={styles['right-column-block']}>
                      <InputProject
                        name={'fullAddress'}
                        form={form}
                        label={words['project.fullAddress']}
                        type={'input'}
                      />
                    </div>
                    <div className={styles['right-column-block']}>
                      <InputProject
                        name={'email'}
                        form={form}
                        label={words['project.email']}
                        type={'input'}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <section className={`${styles['add-files-block']} ${styles['infoes-block']}`}>
                <h2>{words['project.files']}</h2>
                {files &&
                  files.length > 0 &&
                  files
                    .filter(item => !item.deletedAt)
                    .map((item: IFileItem, idx: number) => (
                      <FileView
                        isEdit
                        item={item}
                        idx={idx}
                        deleteFile={deleteFile}
                        key={String(idx)}
                      />
                    ))}
                <label htmlFor="addFile" className={styles['add-file-arria']}>
                  <label htmlFor="addFile">
                    <Icons icon="add" />
                    <input
                      id="addFile"
                      type="file"
                      // accept="image/*, .pdf"
                      onChange={(e: any) => onSelectFile(e, files, setFiles)}
                    />
                  </label>
                </label>
              </section>
              <div className={styles['footer-btn-container']}>
                <Button onClick={cancelHandler} disabled={false}>
                  {words['admin.productionCalendar.new.buttons.cancel']}
                </Button>

                <Button type="submit">
                  {isEdit ? words['project.save'] : words['user.dayOffTracker.create']}
                </Button>
              </div>
            </form>
          )
        }}
      />
      <Modal
        isShow={isSuccessful}
        onClose={() => history.push('/dashboard/projects')}
        title={isEdit ? words['project.updated'] : words['project.created']}
        className={styles['modal']}
      />
    </div>
  )
}
export default CreateProject
