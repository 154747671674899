/* eslint-disable no-console */
import { IAction } from 'models'
import {
  GET_TASK_REQUEST,
  GET_TASK_SUCCESS,
  GET_TASK_ERROR,
  CLEAR_TASK_LIST,
  CLEAR_ONE_TASK,
  GET_USERS,
  GET_ONE_TASK,
  UPDATE_SUCCES,
  CLEAR_USERS_LIST,
  CLEAR_ALL_TASKS,
  CHANGE_TASK_STATUS,
  GET_PROPOSAL,
  GET_PROPOSAL_REQUEST,
  GET_INVOICE_SUCCESS,
  CLEAR_INVOICE,
  GET_CANCELLED_TASKS_REQUEST,
  GET_CANCELLED_TASKS_SUCCESS
} from './Subcontract.constants'
import { ICancelledTasks } from './Subcontract.model'

export type IInitialState = {
  error: string
  loading: boolean
  tasks: any
  users: any
  task: any
  urlFor: string
  page: number
  pageCount: number
  itemsCount: number
  filter: string
  paginLoading: boolean
  proposal: any
  invoice: any
  invoices: any
  cancelledTasks: ICancelledTasks | {}
}

export const initialState: IInitialState = {
  error: '',
  loading: false,
  urlFor: '',
  page: 0,
  pageCount: 0,
  itemsCount: 0,
  tasks: [],
  users: [],
  task: {},
  filter: '',
  paginLoading: false,
  proposal: null,
  invoice: {},
  invoices: [],
  cancelledTasks: {}
}

export default (state = initialState, action: IAction): any => {
  switch (action.type) {
    case GET_TASK_REQUEST: {
      return {
        ...state,
        loading: true,
        paginLoading: action.payload
      }
    }
    case GET_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        tasks: state.tasks.concat(action.payload.tasks).filter((task: any) => task),
        urlFor: action.payload.url,
        page: action.payload.page,
        pageCount: action.payload.pageCount,
        filter: action.payload.filter,
        itemsCount: action.payload.itemsCount,
        paginLoading: action.payload.paginationLoading
      }
    case GET_TASK_ERROR:
      return {
        ...state,
        error: action.payload ? action.payload.error : action.payload,
        loading: false
      }
    case CHANGE_TASK_STATUS:
      const updatedTasks = state.tasks.map((item: any) => {
        if (item.id === action.payload.id) {
          item.status = {
            id: action.payload.statusId
          }
        }
        return item
      })
      return {
        ...state,
        tasks: updatedTasks
      }
    case CLEAR_TASK_LIST:
      return {
        ...state,
        tasks: [],
        users: [],
        page: 0,
        pageCount: 0,
        itemsCount: 0
      }
    case CLEAR_ONE_TASK:
      return {
        ...state,
        task: {}
      }
    case CLEAR_ALL_TASKS:
      return {
        ...state,
        tasks: []
      }
    case CLEAR_USERS_LIST:
      return {
        ...state,
        users: [],
        page: 0,
        itemsCount: 0,
        pageCount: 0
      }
    case GET_INVOICE_SUCCESS:
      if (action.payload.length) {
        return {
          ...state,
          invoices: action.payload
        }
      } else {
        return {
          ...state,
          invoice: action.payload
        }
      }
    case GET_USERS:
      return {
        ...state,
        loading: false,
        users: state.users.concat(action.payload.users),
        urlFor: action.payload.url,
        page: action.payload.page,
        pageCount: action.payload.pageCount,
        filter: action.payload.filter,
        itemsCount: action.payload.itemsCount,
        paginLoading: action.payload.paginationLoading
      }
    case GET_ONE_TASK:
      return {
        ...state,
        loading: false,
        task: action.payload
      }
    case UPDATE_SUCCES:
      return {
        ...state,
        tasks: [action.payload, ...state.tasks.filter((el: any) => el.id !== action.payload.id)],
        loading: false
      }
    case GET_PROPOSAL_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_PROPOSAL:
      return {
        ...state,
        proposal: action.payload,
        loading: false
      }
    case CLEAR_INVOICE:
      return {
        ...state,
        invoice: {}
      }
    case GET_CANCELLED_TASKS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_CANCELLED_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        cancelledTasks: action.payload
      }

    default:
      return state
  }
}
