import React, { FC } from 'react'
import styles from './style.module.scss'
import { withRouter } from 'react-router-dom'
import { IProduct } from '../Goods.model'
import { ReactComponent as Logo } from './point_icon.svg'
import { ReactComponent as PeopleIcon } from 'assets/images/profile_icon.svg'
import history from '../../../../utils/history'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import toastr from 'utils/toastr'

const Product: FC<IProduct> = ({ name, price, id, quantity, category, photo, addToCart }) => {
  const words = useSelector((state: TState) => state.global.language.words)

  const handleClick = (goods: object) => (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    addToCart(goods)
    toastr('success', 'Successfully added to cart')
  }

  const handleItemClick = (itemId: number) => {
    history.push(`${history.location.pathname}/${itemId}`)
  }

  const checkNameLength = (elem: any, defaultLength = 50) => {
    const newName = elem.substr(0, defaultLength)
    return elem.length > defaultLength ? `${newName}...` : elem
  }

  return (
    <div
      className={styles.container}
      style={{ backgroundImage: `url(${photo})`, backgroundSize: 'cover' }}
    >
      <div className={styles.people}>
        <span>{quantity}</span>
        <i className={styles.people_icon}>
          <PeopleIcon />
        </i>
      </div>
      <div className={styles.price}>
        <span>{price}</span>
        <i className={styles.logo}>
          <Logo />
        </i>
      </div>
      <div className={styles.descr} onClick={() => handleItemClick(id)}>
        <div className={styles.name}>{checkNameLength(name)}</div>
        <button
          className={styles.btn}
          onClick={handleClick({
            id,
            name,
            quantity: 1,
            category,
            price,
            photo
          })}
          data-id={id}
        >
          {words['user.pointsSystem.market.table.inBasket']}
        </button>
      </div>
    </div>
  )
}

export default withRouter(Product)
