/* eslint-disable no-console */
import React, { FC, useEffect, useState } from 'react'
import styles from './Polygon.module.scss'
import passport from '../../../../../../../assets/images/passport.jpg'
import queryString from 'query-string'
import Spinner from 'components/Spinner'
import QRCode from 'react-qr-code'
import { TPolygonProps } from './Polygon.model'
import PolygonForm from './steps/PolygonForm'
import { EPolygonStatus } from 'globalConfigs'
import { Icons } from 'components/Icons'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import BackButton from 'components/BackButton/BackButton'

const Polygon: FC<TPolygonProps> = ({
  user,
  getUserProfileInfo,
  sendVerificationData,
  id,
  userLoading,
  profileLoading,
  getQrCodeSignIn,
  qrCode,
  claimsList,
  signupQr,
  getQrCodeSignUp,
  did,
  history
}) => {
  const [verifyPhoto, setVerifyPhoto] = useState({ up: [], back: [] } as any)
  const [deletedFilesId, setDeletedFilesId] = useState([])
  const parsed = queryString.parse(history.location.search)

  const loadings: boolean = userLoading || profileLoading

  const claimsStep = user.verification && user.verification === EPolygonStatus.CLAIMS && !loadings

  const waitForClaims =
    user.verification && user.verification === EPolygonStatus.INPROGRESS && !loadings

  const verified = user.verification && user.verification === EPolygonStatus.TRUE && !loadings

  const polygonSignUp = user.verification && user.verification === EPolygonStatus.FALSE && !loadings

  const polygonForm = user.verification && user.verification === EPolygonStatus.DATA && !loadings

  const qrSignUp =
    user.verification &&
    user.verification === EPolygonStatus.FALSE &&
    parsed.type === 'polygon' &&
    !signupQr.sessionId &&
    !profileLoading

  const qrSignIn =
    user.verification === EPolygonStatus.CLAIMS &&
    parsed.type === 'polygon' &&
    !qrCode.signinQr &&
    !profileLoading

  useEffect(() => {
    getUserProfileInfo(id, 'main-info', false)
  }, [])

  useEffect(() => {
    if (qrSignUp) {
      getQrCodeSignUp()
    } else if (qrSignIn) {
      getQrCodeSignIn()
    }
  }, [profileLoading])

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>Verification</h1>
      </section>
      <BackButton />
      <div className={styles.verification}>
        <h1>Verification</h1>
        {polygonSignUp && (
          <div>
            {signupQr ? (
              <>
                <p className={styles.step}>
                  Download Polygon Id app
                  <br />
                  <div style={{ maxWidth: '200px', margin: 'auto' }}>
                    <a href="https://play.google.com/store/apps/details?id=com.polygonid.wallet&hl=en_US">
                      Play Market
                    </a>
                    <a href="https://apps.apple.com/ru/app/polygon-id/id1629870183">App Store</a>
                  </div>
                  then scan
                </p>
                <QRCode level="Q" style={{ width: 256 }} value={JSON.stringify(signupQr)} />
              </>
            ) : null}
          </div>
        )}

        {polygonForm && (
          <PolygonForm
            sendVerificationData={sendVerificationData}
            verifyPhoto={verifyPhoto}
            did={did}
            id={id}
            user={user}
            passport={passport}
            setVerifyPhoto={setVerifyPhoto}
            deletedFilesId={deletedFilesId}
            setDeletedFilesId={setDeletedFilesId}
            loadings={loadings}
          />
        )}

        {waitForClaims && (
          <div>
            <p className={styles.passedVerification}>
              If you have <span>passed</span> verification, please wait for a response.
            </p>
            <Icons icon={'waitClock'} />
          </div>
        )}

        {claimsStep && (
          <div className={styles.claims}>
            <p>
              Scan <span>credential</span> first
            </p>
            {claimsList
              ? claimsList.map((claim: any, i: number) => (
                  <BaseButton
                    key={i}
                    size={'lgs'}
                    onClick={() =>
                      history.push(
                        `${history.location.pathname}/claims?offer=${claim.id.slice(-36)}`
                      )
                    }
                  >
                    Open Credential
                  </BaseButton>
                ))
              : null}
            <p>
              <span>Then</span> scan for verify
            </p>
            {qrCode.signinQr ? (
              <QRCode level="Q" style={{ width: 256 }} value={JSON.stringify(qrCode.signinQr)} />
            ) : null}
          </div>
        )}

        {verified && (
          <div className={styles.verified}>
            <p>Verified Successfully</p>
            <Icons icon={'doneCircle'} />
          </div>
        )}

        {loadings && (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  )
}

export default Polygon
