export const UPDATE_USER_REQUEST_VERIFI = 'UPDATE_USER_REQUEST_VERIFI'
export const UPDATE_USER_ERROR_VERIFI = 'UPDATE_USER_ERROR_VERIFI'
export const UPDATE_USER_SUCCESS_VERIFI = 'UPDATE_USER_SUCCESS_VERIFI'
export const GET_USERS_REQUEST2 = 'GET_USERS_REQUEST2'
export const GET_USERS_ERROR2 = 'GET_USERS_ERROR2'
export const GET_USERS_SUCCESS2 = 'GET_USERS_SUCCESS2'
export const POLYGON_LOGIN_REQUEST = 'POLYGON_LOGIN_REQUEST'
export const POLYGON_LOGIN_ERROR = 'POLYGON_LOGIN_ERROR'
export const POLYGON_LOGIN_SUCCESS = 'POLYGON_LOGIN_SUCCESS'
export const POLYGON_OFFER_SUCCESS = 'POLYGON_OFFER_SUCCESS'
export const POLYGON_CLAIMS_LIST_SUCCESS = 'POLYGON_CLAIMS_LIST_SUCCESS'
