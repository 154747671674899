import moment from 'moment'
import { EXECUTOR_CALENDAR_DAYS } from '../PlaningCalendar.constants'

const dateFormat = 'MM/DD/YYYY'

const SearchParamsObject: { [key: string]: string } = {
  date: moment().format(dateFormat),
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC',
  fromTime: '00:00',
  toTime: '00:00',
  fromDate: moment()
    .subtract(EXECUTOR_CALENDAR_DAYS.DAYS_BACK, 'days')
    .format(dateFormat),
  toDate: moment()
    .add(EXECUTOR_CALENDAR_DAYS.DAYS_FORTH, 'days')
    .format(dateFormat)
}

export const addGoogleEventsSearchParams = (originalUrl: string, email?: string): string => {
  const url = new URL(originalUrl)

  Object.entries(SearchParamsObject).forEach(([key, value]) => {
    url.searchParams.set(key, value)
  })
  if (email) url.searchParams.set('foreignEmail', email)

  return url.toString()
}
