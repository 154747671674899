import moment from 'moment'
import queryString from 'query-string'
import history from 'utils/history'
import { TWords } from 'elements/SideBar/SideBar.config'

export const getInitialValues = (taskInfo: any): any => {
  const initialValues: any = {}

  initialValues.title = taskInfo.title
  initialValues.specialization = taskInfo.specializations
  initialValues.level = taskInfo.level ? taskInfo.level.id : 0
  initialValues.description = taskInfo.description
  initialValues.technologies = taskInfo.technologies
  initialValues.files = taskInfo.files
  initialValues.price = taskInfo.price
  initialValues.currency = taskInfo.currency
  initialValues.isCustomerPrice = taskInfo.isCustomerPrice
  initialValues.workingHours = taskInfo.workingHours
  initialValues.schedule = taskInfo.schedule
  initialValues.pricePerHours = taskInfo.price
  initialValues.planning = taskInfo.planning
  initialValues.fixedDeadline = taskInfo.fixedDeadline === true && taskInfo.deadline ? false : true
  initialValues.deadline = moment(taskInfo.deadline).valueOf()

  initialValues.companyAddress = taskInfo.companyAddress
  initialValues.companyEmail = taskInfo.companyEmail
  initialValues.companyName = taskInfo.companyName
  initialValues.companyNumber = taskInfo.companyNumber
  initialValues.teamStructure = taskInfo.teamStructure
  initialValues.devStage = taskInfo.devStage ? taskInfo.devStage.id : 0
  initialValues.startProjectDate = moment(taskInfo.startProjectDate).valueOf()
  initialValues.techStack = taskInfo.techStack

  return initialValues
}

export enum TaskType {
  DEDICATED_TEAM = '1',
  TIME_AND_MATERIALS = '2',
  FIXED_PRICE = '3',
  PROJECT_BASED = '4'
}

export const getDefaultWeek = (words: TWords) => {
  return {
    // eslint-disable-next-line prettier/prettier
    monday: {
      from: {},
      to: {},
      day: words['user.subcontract.task.monday'],
      active: false,
      order: '1'
    },
    // eslint-disable-next-line prettier/prettier
    tuesday: {
      from: {},
      to: {},
      day: words['user.subcontract.task.tuesday'],
      active: false,
      order: '2'
    },
    // eslint-disable-next-line prettier/prettier
    wednesday: {
      from: {},
      to: {},
      day: words['user.subcontract.task.wednesday'],
      active: false,
      order: '3'
    },
    // eslint-disable-next-line prettier/prettier
    thursday: {
      from: {},
      to: {},
      day: words['user.subcontract.task.thursday'],
      active: false,
      order: '4'
    },
    // eslint-disable-next-line prettier/prettier
    friday: {
      from: {},
      to: {},
      day: words['user.subcontract.task.friday'],
      active: false,
      order: '5'
    },
    // eslint-disable-next-line prettier/prettier
    saturday: {
      from: {},
      to: {},
      day: words['user.subcontract.task.saturday'],
      active: false,
      order: '6'
    },
    // eslint-disable-next-line prettier/prettier
    sunday: {
      from: {},
      to: {},
      day: words['user.subcontract.task.sunday'],
      active: false,
      order: '7'
    }
  }
}

export const finishPoint = () => {
  const parsed = queryString.parse(history.location.search)
  return `${parsed.category !== '@ALL@' && parsed.category ? `&category=${parsed.category}` : ''}${
    parsed.currency !== '@ALL@' && parsed.currency ? `&currency=${parsed.currency}` : ''
  }${parsed.level !== '@ALL@' && parsed.level ? `&level=${parsed.level}` : ''}${
    parsed.specialization !== '@ALL@' && parsed.specialization
      ? `&specialization=${parsed.specialization}`
      : ''
  }${parsed.fromPrice ? `&fromPrice=${parsed.fromPrice}` : ''}${
    parsed.toPrice ? `&toPrice=${parsed.toPrice}` : ''
  }${parsed.fromDate ? `&fromDate=${parsed.fromDate}` : ''}${
    parsed.toDate ? `&toDate=${parsed.toDate}` : ''
  }${parsed.status && parsed.status !== '@ALL@' ? `&status=${parsed.status}` : ''}
  ${parsed.position ? `&position=${parsed.position}` : ''}${
    parsed.rank ? `&rank=${parsed.rank}` : ''
  }${parsed.technologies ? `&technologies=${parsed.technologies}` : ''}`
}

export const finishPointVacancies = () => {
  const parsed: any = queryString.parse(history.location.search)
  return `${parsed.rank ? `&rank=${parsed.rank}` : ''}${
    parsed.title ? `&title=${parsed.title}` : ''
  }${parsed.typeOfEmployment ? `&typeOfEmployment=${parsed.typeOfEmployment}` : ''}${
    parsed.project ? `&project=${parsed.project}` : ''
  }${parsed.status ? `&status=${parsed.status}` : ''}${
    parsed.countOfHours ? `&countOfHours=${parsed.countOfHours}` : ''
  }${parsed.levelOfEnglish ? `&englishLevel=${parsed.levelOfEnglish}` : ''}${
    parsed.dateOfClosingFrom ? `&dateOfClosingFrom=${parsed.dateOfClosingFrom}` : ''
  }${parsed.dateOfClosingTo ? `&dateOfClosingTo=${parsed.dateOfClosingTo}` : ''}`
}

export const getCardArray = (words: TWords) => [
  {
    img: {
      src: '../../../assets/images/teamExtension.png',
      // 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png',
      alt: 'pc'
    },
    title: 'Dedicated Team',
    description: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae vestibulum amet elit amet. Viverra risus tellus lectus facilisis nisl justo. Scelerisque senectus sit pulvinar praesent tempus odio amet. Massa libero amet, nunc vulputate non. Placerat ac iaculis ultrices hendrerit ornare rutrum egestas.'
    ],
    query: TaskType.DEDICATED_TEAM,
    advantages: words['user.subcontract.task.advantages'],
    advantagesArray: [
      { icon: 'shield', text: 'Lorem ipsum dolor sit amet' },
      { icon: 'clock', text: 'Lorem ipsum dolor sit amet' },
      { icon: 'star', text: 'Lorem ipsum dolor sit amet' },
      { icon: 'lightning', text: 'Lorem ipsum dolor sit amet' }
    ],
    howItWorks: {
      title: words['user.subcontract.task.howItWorks'],
      step: [
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet'
      ]
    }
  },
  {
    img: {
      src: '../../../assets/images/teamExtension.png',
      // 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png',
      alt: 'pc'
    },
    title: 'Time and Materials',
    description: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae vestibulum amet elit amet. Viverra risus tellus lectus facilisis nisl justo. Scelerisque senectus sit pulvinar praesent tempus odio amet. Massa libero amet, nunc vulputate non. Placerat ac iaculis ultrices hendrerit ornare rutrum egestas.'
    ],
    query: TaskType.TIME_AND_MATERIALS,
    advantages: words['user.subcontract.task.advantages'],
    advantagesArray: [
      { icon: 'shield', text: 'Lorem ipsum dolor sit amet2' },
      { icon: 'clock', text: 'Lorem ipsum dolor sit amet2' },
      { icon: 'star', text: 'Lorem ipsum dolor sit amet2' },
      { icon: 'lightning', text: 'Lorem ipsum dolor sit amet2' }
    ],
    howItWorks: {
      title: words['user.subcontract.task.howItWorks'],
      step: [
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet'
      ]
    }
  },
  {
    img: {
      src: '../../../assets/images/teamExtension.png',
      // 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png',
      alt: 'pc'
    },
    title: 'Fixed Price',
    description: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae vestibulum amet elit amet. Viverra risus tellus lectus facilisis nisl justo. Scelerisque senectus sit pulvinar praesent tempus odio amet. Massa libero amet, nunc vulputate non. Placerat ac iaculis ultrices hendrerit ornare rutrum egestas.'
    ],
    query: TaskType.FIXED_PRICE,
    advantages: words['user.subcontract.task.advantages'],
    advantagesArray: [
      { icon: 'shield', text: 'Lorem ipsum dolor sit amet3' },
      { icon: 'clock', text: 'Lorem ipsum dolor sit amet3' },
      { icon: 'star', text: 'Lorem ipsum dolor sit amet3' },
      { icon: 'lightning', text: 'Lorem ipsum dolor sit amet3' }
    ],
    howItWorks: {
      title: words['user.subcontract.task.howItWorks'],
      step: [
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet'
      ]
    }
  },
  {
    img: {
      src: '../../../assets/images/teamExtension.png',
      // 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png',
      alt: 'pc'
    },
    title: 'Project-based',
    description: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae vestibulum amet elit amet. Viverra risus tellus lectus facilisis nisl justo. Scelerisque senectus sit pulvinar praesent tempus odio amet. Massa libero amet, nunc vulputate non. Placerat ac iaculis ultrices hendrerit ornare rutrum egestas.'
    ],
    query: TaskType.PROJECT_BASED,
    advantages: words['user.subcontract.task.advantages'],
    advantagesArray: [
      { icon: 'shield', text: 'Lorem ipsum dolor sit amet4' },
      { icon: 'clock', text: 'Lorem ipsum dolor sit amet4' },
      { icon: 'star', text: 'Lorem ipsum dolor sit amet4' },
      { icon: 'lightning', text: 'Lorem ipsum dolor sit amet4' }
    ],
    howItWorks: {
      title: words['user.subcontract.task.howItWorks'],
      step: [
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet'
      ]
    }
  }
]

export const currency = [
  { value: '1', label: 'UAH' },
  { value: '2', label: 'USD' },
  { value: '3', label: 'TRL' }
]

export const getStatusOptions = (words: TWords) => [
  { value: '1', label: words['TASK_STATUS_1'] },
  { value: '2', label: words['TASK_STATUS_2'] },
  { value: '3', label: words['TASK_STATUS_3'] },
  { value: '4', label: words['TASK_STATUS_4'] },
  { value: '5', label: words['TASK_STATUS_5'] },
  { value: '6', label: words['TASK_STATUS_6'] }
]

export const getStatusVerificationOptions = (words: TWords) => [
  { value: '1', label: words['admin.subcontract.verification.taskStatus1'] },
  { value: '2', label: words['admin.subcontract.verification.taskStatus2'] },
  { value: '3', label: words['admin.subcontract.verification.taskStatus3'] },
  { value: '4', label: words['admin.subcontract.verification.taskStatus4'] }
]

export const timesOption = [
  {
    value: '1',
    label: `1:00`
  },
  {
    value: '2',
    label: `2:00`
  },
  {
    value: '3',
    label: `3:00`
  },
  {
    value: '4',
    label: `4:00`
  },
  {
    value: '5',
    label: `5:00`
  },
  {
    value: '6',
    label: `6:00`
  },
  {
    value: '7',
    label: `7:00`
  },
  {
    value: '8',
    label: `8:00`
  },
  {
    value: '9',
    label: `9:00`
  },
  {
    value: '10',
    label: `10:00`
  },
  {
    value: '11',
    label: `11:00`
  },
  {
    value: '12',
    label: `12:00`
  },
  {
    value: '13',
    label: `13:00`
  },
  {
    value: '14',
    label: `14:00`
  },
  {
    value: '15',
    label: `15:00`
  },
  {
    value: '16',
    label: `16:00`
  },
  {
    value: '17',
    label: `17:00`
  },
  {
    value: '18',
    label: `18:00`
  },
  {
    value: '19',
    label: `19:00`
  },
  {
    value: '20',
    label: `20:00`
  },
  {
    value: '21',
    label: `21:00`
  }
]
