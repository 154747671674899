import { IAction } from 'models'
import { IWeb3InitialState } from './web3.model'
import { EWeb3Actions } from './web3.constans'

export const Web3InitialState: IWeb3InitialState = {
  userAddress: null,
  trlTokenPrice: null,
  isTransaction: false
}

export default (state = Web3InitialState, action: IAction) => {
  switch (action.type) {
    case EWeb3Actions.CONNECT_TO_META_MASK:
      return {
        ...state,
        userAddress: action.payload
      }

    case EWeb3Actions.CHECK_META_MASK_CONNECTION:
      return {
        ...state,
        userAddress: action.payload
      }

    case EWeb3Actions.GET_TRL_TOKEN_PRICE:
      return {
        ...state,
        trlTokenPrice: action.payload
      }

    case EWeb3Actions.IS_TASK_IN_TRANSACTION:
      return {
        ...state,
        isTransaction: action.payload
      }

    default:
      return state
  }
}
