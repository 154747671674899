import { Dispatch } from 'redux'

import {
  getBasketRequest,
  getBasketSuccess,
  getBasketError,
  successRequestPurchaseOfGoods,
  errorRequestPurchaseOfGoods
} from './Basket.actions'
import { API, api } from 'services/api'
import { TPurchaseOfGoods } from './Basket.model'
import toastr from '../../../utils/toastr'
import { TState } from 'store'
import { AxiosError } from 'axios'

export const fetchBasket: any = () => async (dispatch: Dispatch) => {
  dispatch(getBasketRequest())

  try {
    const response: any = await api.get(`${API.POSTS}`)
    dispatch(getBasketSuccess(response.data))
  } catch (error) {
    await dispatch(getBasketError(error))
  }
}

export const failFetchBasket: any = () => async (dispatch: Dispatch) => {
  dispatch(getBasketRequest())

  try {
    const response: any = await api.get(`/FAIL_FOR_TRACK_JS`)
    dispatch(getBasketSuccess(response.data))
  } catch (error) {
    await dispatch(getBasketError(error))
  }
}

export const createRequestPurchaseOfGoods = (body: TPurchaseOfGoods) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  const words = getData().global.language.words

  try {
    await api.post(API.REQUEST_USER, body)
    dispatch(successRequestPurchaseOfGoods())
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(errorRequestPurchaseOfGoods(err.data))
    toastr('error', words['NOT_ENOUGH_TCENTS'], err)
  }
}
