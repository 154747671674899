import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

export const generatePDF = async (
  ref: any,
  number: any,
  type?: any,
  isZip?: boolean
): Promise<any> => {
  const element = type === 'all' ? ref : ref.current
  const canvas = await html2canvas(element, { scale: 2 })
  const data = canvas.toDataURL('image/png')
  const imgWidth = 210
  const pageHeight = 295
  const imgHeight = (canvas.height * imgWidth) / canvas.width
  let heightLeft = imgHeight
  const pdf = new jsPDF()
  let position = 0
  pdf.addImage(data, 'PNG', 0, position, imgWidth, imgHeight)
  heightLeft -= pageHeight
  while (heightLeft >= 0) {
    position = heightLeft - imgHeight
    pdf.addPage()
    pdf.addImage(data, 'PNG', 0, position, imgWidth, imgHeight)
    heightLeft -= pageHeight
  }

  if (isZip) {
    const pdfData = pdf.output('blob')
    return { fileName: `Invoice ${number}.pdf`, data: pdfData }
  } else {
    pdf.save(`Invoice № ${number}.pdf`)
  }
}
