import React, { FC, InputHTMLAttributes /*, Ref*/, useRef } from 'react'
import cls from 'classnames'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styles from './Input.module.scss'

export type TBaseInputProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string
  disabled?: boolean
  errorMessage?: string
  isInvalid?: boolean
  name: string
  placeholder?: string
  rest?: any
  required?: boolean
  label?: string
  value?: string | number
  checked?: boolean
  variant?: 'default' | 'outlined'
  withoutError?: boolean
  style?: any
}

export const Input: FC<TBaseInputProps> = ({
  className = '',
  disabled,
  errorMessage,
  isInvalid,
  name,
  placeholder,
  label,
  type = 'text',
  variant = 'default',
  withoutError,
  required,
  value = '',
  checked,
  readOnly,
  rest,
  style,
  ...other
}) => {
  const isOutline = variant === 'outlined'
  const inputRef = useRef<HTMLInputElement>(null)
  const isTypeNumber = type === 'number' && '^()[-+,0-9.]+'
  const renderInput = (
    <>
      {isOutline && label && (
        <label
          htmlFor={name}
          className={cls({
            [styles['label-outlined']]: true,
            [styles['label-outlined-required']]: required,
            [styles['label-outlined-error']]: isInvalid && errorMessage
          })}
        >
          {label && label}
        </label>
      )}
      <div
        className={cls({
          [styles.wrapper]: true,
          [className]: true,
          [styles['wrapper-outlined']]: isOutline,
          [styles['wrapper-outlined-required']]: isOutline && required,
          [styles['wrapper-outlined-disabled']]: isOutline && disabled,
          [styles['wrapper-outlined-error']]: isOutline && isInvalid && errorMessage,
          [styles['wrapper-required']]: required,
          [styles['wrapper-error']]: isInvalid && errorMessage,
          [styles['wrapper-disabled']]: disabled
        })}
        style={style}
      >
        <input
          id={name}
          ref={inputRef}
          placeholder="&nbsp;"
          disabled={disabled}
          name={name}
          data-tip=""
          data-for={name}
          value={value}
          readOnly={readOnly}
          pattern={isTypeNumber || undefined}
          type={type}
          {...rest}
          {...other}
        />
        {typeof value === 'string' && !value.length && (
          <span
            onClick={() => {
              if (inputRef.current !== null) {
                inputRef.current.focus()
              }
            }}
            className={styles.label}
          >
            {placeholder}
          </span>
        )}
      </div>
      {!withoutError && isInvalid && errorMessage && (
        <ReactTooltip
          id={name}
          anchorSelect={`#${name}`}
          place="bottom"
          variant="error"
          content={errorMessage}
          className={styles.tooltip}
        />
      )}
    </>
  )

  const renderCheckbox = (
    <>
      <label
        className={cls({
          [styles['wrapper-checkbox']]: true,
          [className]: true,
          [styles['wrapper-checkbox-disabled']]: disabled
        })}
      >
        {label}
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          name={name}
          {...rest}
          {...other}
        />
        <span className={styles.checkmark} />
      </label>
    </>
  )
  return type === 'checkbox' ? renderCheckbox : renderInput
}
