import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import SubcontractComponent from './Subcontract'
import { getTasks, getUsers, clearStore, clearTask } from './Subcontract.thunk'
import { fetchAllTokensThunk } from 'screens/admin/Token/Token.thunx'

export const mapStateToProps = (state: TState) => {
  return {
    taskArray: state.client.subcontract.tasks,
    usersArray: state.client.subcontract.users,
    loading: state.client.subcontract.loading,
    loadedPage: state.client.subcontract.page,
    pageCount: state.client.subcontract.pageCount,
    itemsCount: state.client.subcontract.itemsCount,
    paginLoading: state.client.subcontract.paginLoading,
    page: state.client.subcontract.page,
    tokens: state.admin.token.allTokens,
    isTransaction: state.client.web3.isTransaction
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getTasks,
      getUsers,
      clearStore,
      clearTask,
      fetchAllTokensThunk
    },
    dispatch
  )

export const Subcontract = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubcontractComponent)
