import React, { FC, useEffect, useState } from 'react'
import { ToggleBar } from '../../../components/ToggleBar'
import { useSelector } from 'react-redux'
import { TState } from '../../../store'
import queryString from 'query-string'
import { TVacanciesProps } from './Vacancies.model'
import {
  getVacanciesAdminType,
  setDefaultMainToggleBarStateForVacancies
} from '../../../utils/toggleBarsValue'
import { EVacanciesAdminHistory, getToggleBarOptionsForVacancies } from '../../../globalConfigs'
import styles from './Vacancies.module.scss'
import { BaseButton } from '../../../components/UiKit/Button/BaseButton'
import { Filter } from '../../../components/UiKit/Filter'
import { getVacanciesFilterConfig } from './Vacancies.filter.config'
import { Columns } from './Vacancies.columns'
import { finishPointVacancies } from '../../client/Subcontract/components/CreateTask/initialvalues'
import { TeamTable } from 'components/UiKit/Table'
import { TSelectOption } from '../../../components/Select'
const Vacancies: FC<TVacanciesProps> = ({
  history,
  vacanciesArray,
  displayAllFields,
  managersData,
  englishLevel,
  statusOptions,
  getVacancies,
  getStatusOptions,
  getSelectOptions,
  getProjectOptions,
  getVacancyRequest
}) => {
  const [managersOptions, setManagersOptions] = useState<TSelectOption[]>([
    { value: '', label: '' }
  ])
  const parsed = queryString.parse(history.location.search)
  const words = useSelector((state: TState) => state.global.language.words)
  const rank = useSelector((state: TState) => state.client.editProfile.rank)
  const typeOfEmployment = useSelector((state: TState) => state.client.editProfile.typeOfEmployment)
  const positions = useSelector((state: TState) => state.client.editProfile.positions)
  const projectOptions = useSelector((state: TState) => state.admin.vacancies.projectOptions)
  const [selectedVacanciesType, setSelectedVacanciesType] = useState(
    setDefaultMainToggleBarStateForVacancies(parsed.vacancy, words)
  )

  useEffect(() => {
    getSelectOptions()
    getProjectOptions()
    getStatusOptions()
    const filter = finishPointVacancies()
    getVacancies(filter)
  }, [])

  useEffect(() => {
    const filteredData: TSelectOption[] = managersData.results
      .map(item => ({
        value: `${item.fullName}`,
        label: item.fullName
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    setManagersOptions(filteredData)
  }, [managersData])

  const columns = Columns({ role: 2, isOnlyManager: false })
  const handleRowClick = (e: any) => {
    getVacancyRequest()
    history.push('/dashboard/vacancies/' + e.original.id)
  }
  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>{words['vacancies']}</h1>
      </section>
      <div className={styles.wrapBar}>
        <div className={styles.wrapHeader}>
          <div>
            <ToggleBar
              data={getToggleBarOptionsForVacancies(words)}
              name="vacancies"
              defaultChecked={selectedVacanciesType}
              onChange={(event: any) => {
                history.push(
                  `${history.location.pathname}?${queryString.stringify({
                    vacancy: getVacanciesAdminType(event.value),
                    page: 1
                  })}`
                )
                setSelectedVacanciesType(event)
              }}
            />
          </div>
          <div className={styles.wrapActions}>
            <BaseButton onClick={() => history.push('/dashboard/vacancies/create')} size="lg">
              {words['admin.vacancies.new']}
            </BaseButton>
          </div>
        </div>
        {parsed.vacancy === EVacanciesAdminHistory.ALL_VACANCIES_ADMIN && (
          <div className={styles.wrapFilter}>
            <Filter
              config={getVacanciesFilterConfig({
                displayAllFields,
                positions,
                rank,
                typeOfEmployment,
                englishLevel,
                managersOptions,
                projectOptions,
                statusOptions,
                maxToDate: Date.now(),
                minFromDate: Date.now(),
                role: 2,
                words
              })}
              isMultilineBtn={true}
              withButton={false}
              defaultOpened={true}
            />
          </div>
        )}
        <div className={styles['vacancies-table']}>
          <TeamTable
            columns={columns}
            data={vacanciesArray}
            classNameToRow="TeamItemRow"
            getData={() => ({})}
            hiddenColumns={[]}
            onRowClick={handleRowClick}
            total={vacanciesArray.length}
          />
        </div>
      </div>
    </div>
  )
}

export default Vacancies
