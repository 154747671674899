import { getManagers, getOneProject } from './../Projects/Projects.thunx'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'
import { setProject } from './CreateProject.thunk'

import CreateProjectComponent from './CreateProject'

export const mapStateToProps = (state: TState) => {
  return {
    projectInfo: state.admin.projects.project,
    userId: state.auth.data.id,
    taskArray: state.client.subcontract.tasks,
    managers: state.admin.projects.managers
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getOneProject,
      setProject,
      getManagers
    },
    dispatch
  )

export const CreateProject = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProjectComponent)
