function getDateRange() {
  const today = new Date()
  const sevenDaysAgo = new Date(today)
  sevenDaysAgo.setDate(today.getDate() - 7)

  return {
    to: today.getTime() + '',
    from: sevenDaysAgo.getTime() + ''
  }
}

export { getDateRange }
