export enum EProject {
  GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST',
  GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS',
  GET_ONE_PROJECT_SUCCESS = 'GET_ONE_PROJECT_SUCCESS',
  GET_PROJECTS_ERROR = 'GET_PROJECTS_ERROR',
  CHANGE_PROJECT_REQUEST = 'CHANGE_PROJECT_REQUEST',
  CHANGE_PROJECT_SUCCESS = 'CHANGE_PROJECT_SUCCESS',
  CHANGE_PROJECT_ERROR = 'CHANGE_PROJECT_ERROR',
  CLEAR_ONE_PROJECT_SUCCESS = 'CLEAR_ONE_PROJECT_SUCCESS',
  GET_PROJECT_HISTORY_REQUEST = 'GET_PROJECT_HISTORY_REQUEST',
  GET_PROJECT_HISTORY_SUCCESS = 'GET_PROJECT_HISTORY_SUCCESS',
  GET_MANAGERS_REQUEST = 'GET_MANAGERS_REQUEST',
  GET_MANAGERS_SUCCESS = 'GET_MANAGERS_SUCCESS',
  GET_MANAGERS_ERROR = 'GET_MANAGERS_ERROR'
}
