import React from 'react'
import { Field } from 'react-final-form'
import styles from './CustomInput.module.scss'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  name: string
  type: string
  options?: Array<{ label: string; value: string }>
}

const CustomInputField: React.FC<InputProps> = ({ label, options, ...props }) => {
  const { name, type, placeholder, disabled } = props

  const renderInput = (inputProps: any, inputType: string, placeholderText?: string) => {
    switch (inputType) {
      case 'select':
        return (
          options && (
            <select {...inputProps} className={styles.input} disabled={disabled}>
              {options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          )
        )

      default:
        return (
          <input
            {...inputProps}
            type={inputType}
            placeholder={placeholderText}
            className={styles.input}
            disabled={disabled}
          />
        )
    }
  }

  return (
    <Field name={name}>
      {({ input, meta }) => (
        <div className={styles.inputWrapper}>
          <label className={styles.label}>{label}</label>

          {renderInput(input, type, placeholder)}

          {meta.error && meta.touched && <span className={styles.errorMessage}>{meta.error}</span>}
        </div>
      )}
    </Field>
  )
}

export default CustomInputField
