import React, { FC } from 'react'
import styles from './styles.module.scss'
import { Field } from 'react-final-form'
import { FormApi } from 'final-form'
import { DatePicker } from 'components/UiKit/DatePicker'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import moment from 'moment'
import { formatterDateForDatePicker } from 'components/UiKit/Filter/Filter'

type IProps = {
  label: string
  name: string
  secondName: string
  form: FormApi
  dateFormat?: string
  fieldData: {
    from: string
    to: string
  }
}

export const DatePickerRangeNew: FC<IProps> = ({
  label,
  name,
  form,
  dateFormat,
  secondName,
  fieldData
}) => {
  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)

  const format = dateFormat || 'dd.MM.yy'

  return (
    <div className={styles.wrapper}>
      <label>{label}</label>
      <div className={styles.container}>
        <div
          style={{
            width: '45%'
          }}
        >
          <Field name={name}>
            {({ input }) => {
              const firstDate = form.getState().values[name]
              const secondDate = form.getState().values[secondName]
              return (
                <DatePicker
                  locale={currentLanguage}
                  name={input.name}
                  autoComplete="off"
                  strictParsing
                  dateFormat={format}
                  value={input.value || null}
                  selected={input.value || null}
                  onChange={event => {
                    if (event) {
                      const timeReset = moment(event)
                        .set({
                          hour: 0,
                          minute: 0,
                          second: 0,
                          millisecond: 0
                        })
                        .valueOf()
                      form.change(input.name, timeReset)
                    }
                    if (event === null) {
                      form.change(input.name, '')
                    }
                  }}
                  selectsStart
                  startDate={(firstDate && new Date(firstDate)) || fieldData.from || ''}
                  endDate={(secondDate && new Date(secondDate)) || fieldData.to || ''}
                  minDate={(fieldData.from as any) || ''}
                  maxDate={(secondDate && new Date(secondDate)) || fieldData.to || ''}
                />
              )
            }}
          </Field>
        </div>
        <span className={styles['data-picker-dash']} />
        <div
          style={{
            width: '40%'
          }}
        >
          <Field name={secondName}>
            {({ input }) => {
              const firstDate = form.getState().values[name]
              const secondDate = form.getState().values[secondName]
              return (
                <DatePicker
                  locale={currentLanguage}
                  name={input.name}
                  autoComplete="off"
                  strictParsing
                  dateFormat={format}
                  value={input.value || null}
                  selected={input.value || null}
                  onChange={event => {
                    if (event) {
                      const timeReset = moment(event)
                        .set({
                          hour: 23,
                          minute: 59,
                          second: 59,
                          millisecond: 999
                        })
                        .valueOf()
                      form.change(input.name, formatterDateForDatePicker(timeReset))
                    }
                    if (event === null) {
                      form.change(input.name, '')
                    }
                  }}
                  selectsEnd
                  startDate={(firstDate && new Date(firstDate)) || fieldData.from || ''}
                  endDate={(secondDate && new Date(secondDate)) || fieldData.to || ''}
                  minDate={(firstDate && new Date(firstDate)) || fieldData.from || ''}
                  maxDate={(fieldData.to as any) || ''}
                />
              )
            }}
          </Field>
        </div>
        <div className={styles['datepicker-icon']} />
      </div>
    </div>
  )
}
