import React from 'react'
import styles from './SingleFeedback.module.scss'
import { IFeedback } from 'screens/client/Profile/Profile.model'
import cls from 'classnames'
import { useSelector } from 'react-redux'
import { TState } from 'store'

interface ISingleFeedbackProps {
  feedback: IFeedback
  tasks: any
}

const SingleFeedback = ({ feedback, tasks }: ISingleFeedbackProps) => {
  const convertDateTime = (date: Date) => {
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    const hours = date.getHours()
    const minutes = date.getMinutes()

    const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`
    const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`

    return { date: formattedDate, time: formattedTime }
  }

  const words = useSelector((state: TState) => state.global.language.words)

  const taskTitle = tasks && feedback && tasks.find((task: any) => task.id === feedback.taskId)

  return (
    <div className={styles['feedback-wrapper']}>
      <div className={styles['feedback-info']}>
        <div className={styles['feedback-left-column']}>
          <div className={styles['feedback-info-wrapper']}>
            <div className={styles['feedback-info-img']}>
              <img src={feedback.avatar} alt="Avatar" />
            </div>
            <div className={styles['feedback-info-text']}>
              <h5 className={styles['feedback-info-name']}>{feedback.name}</h5>
              <p className={styles['feedback-info-rating']}>
                {words['user.profile.feedbacks.rating']}: <span>{feedback.rating}</span>
              </p>
              <p className={styles['feedback-info-task']}>
                {words['user.profile.feedbacks.order']} <span>{taskTitle && taskTitle.title}</span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles['feedback-right-column']}>
          <p
            className={cls({
              [styles['feedback-review']]: true,
              [styles['feedback-positive']]: feedback.review === 'positive',
              [styles['feedback-negative']]: feedback.review === 'negative'
            })}
          >
            {feedback.review === 'positive'
              ? words['user.profile.feedbacks.positive']
              : words['user.profile.feedbacks.negative']}
          </p>
          <div className={styles['feedback-date']}>
            <span>
              {convertDateTime(feedback.date).date} {words[words['user.profile.feedbacks.in']]}{' '}
            </span>
            <span>{convertDateTime(feedback.date).time}</span>
          </div>
        </div>
      </div>
      <div className={styles['feedback-text']}>{feedback.feedbackText}</div>
    </div>
  )
}
export default SingleFeedback
