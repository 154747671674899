/* eslint-disable react/no-children-prop */
/* eslint-disable no-console */
import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './Task.module.scss'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import queryString from 'query-string'
import { TState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { ToggleBar } from 'components/ToggleBar'
import {
  EContractAddress,
  EResponsesHistory,
  getToggleBarOptionsForSubcontractResponse
} from 'globalConfigs'
import { getResponseType, setDefaultMainToggleBarStateForResponse } from 'utils/toggleBarsValue'
import UserResponse from '../Response/UserResponse'
import { Icons } from 'components/Icons'
import { useParams } from 'react-router'
import { TTaskProps } from './Task.model'
import moment from 'moment'
import CancelTaskModal from 'components/SubcontractModals/CancelTaskModal'
import Loading from '../../../../../components/Spinner'
import { FileView } from 'components/Profile/PersonalInfo/FileView'
import { sortDays } from 'helpers/helpers'
import cls from 'classnames'
import getWeb3 from 'getWeb3'
import { getHashSignature } from 'utils/getHashSignature'
import { getContractAbi } from 'utils/getContractAbi'
import { api, API } from 'services/api'
import { AbiItem } from 'web3-utils'
import { EDealStatus } from 'screens/client/Web3/web3.model'
import { ETaskStatus } from '../CreateTask/CreateTask.model'
import { clearInvoice } from '../../Subcontract.thunk'
import SubcontractInvoice from '../SubcontractInvoice/SubcontractInvoice'
import { generatePDF } from '../../../../../components/DownloadInvoice/getPDF'
import { findedProposal } from 'utils/findedProposal'
import { Description } from './components/Description/Description'
import { Maininfo } from './components/MainInfo/Maininfo'
import { ButtonCondition } from './components/ButtonCondition/ButtonCondition'
import { ETransactionMessage } from 'components/Spinner/types'
import toastr, { EToastrTypeMessage } from 'utils/toastr'
import { isEmpty } from 'lodash'

const Task: FC<TTaskProps> = ({
  getTask,
  taskInfo,
  loading,
  history,
  updateTaskInfo,
  accessToken,
  error,
  currentUser,
  signTaskThunk,
  acceptTask,
  isTransaction,
  createVoting,
  createTaskCancellation,
  updateTaskCancellation,
  isTaskInTransaction
}) => {
  const params: any = useParams()
  const parsed = queryString.parse(history.location.search)
  const role = useSelector((state: TState) => state.auth.data.role)
  const downloadInvoiceRef: any = useRef()
  const dispatch = useDispatch()
  const words = useSelector((state: TState) => state.global.language.words)
  const avatar = useSelector((state: TState) => state.auth.avatar)
  const verifiedStatus = useSelector((state: TState) => state.auth.data.verification)
  const invoice = useSelector((state: TState) => state.client.subcontract.invoice)
  const [executor, setExecutor] = useState(null)
  const [taskRespTab, setTaskRespTab] = useState('all-responses')
  const [modalState, setModalState] = useState(false)
  const [userPhoto, setUserPhoto] = useState('')
  const [selectedResponseType, setSelectedResponseType] = useState(
    setDefaultMainToggleBarStateForResponse(parsed.response, words)
  )
  const [proposal, setProposal] = useState<any>(null)

  window.scrollTo(0, 0)

  useEffect(() => {
    setUserPhoto(avatar)
    getTask(params.id, !parsed.task || parsed.from === 'my-answers')
    dispatch(clearInvoice())
  }, [])

  useEffect(() => {
    if (!isEmpty(taskInfo)) {
      const selectedResponse = taskInfo.taskResponses.find(
        (response: any) => response.status === 'selected'
      )
      setExecutor(selectedResponse)
    }
  }, [taskInfo.executor])
  console.log('🚀 ~ taskInfo:', taskInfo)

  function openModal() {
    setModalState(!modalState)
  }

  useEffect(() => {
    if (taskInfo && taskInfo.executor) {
      setProposal(findedProposal(taskInfo))
    }
  }, [taskInfo])

  const weekSchedule = (schedule: any): any => {
    const arra = [] as any
    for (const key in schedule) {
      arra.push(
        <div className={styles.day} key={key}>
          <h4 className={cls({ [styles.unactive]: !schedule[key].active })}>{`${key
            .charAt(0)
            .toUpperCase() + key.slice(1)}`}</h4>
          {schedule[key].active ? (
            <p>{`${schedule[key].from.label} - ${schedule[key].to.label}`}</p>
          ) : (
            <p>{words['admin.productionCalendar.new.type.holiday']}</p>
          )}
          {/* <p>{task.date}</p> */}
        </div>
      )
    }
    return arra
  }
  const handleDownloadInvoice = () => {
    generatePDF(downloadInvoiceRef, taskInfo.id)
  }

  const markExecuted = async () => {
    const web3 = await getWeb3()
    const { taskHash } = taskInfo
    // const myTask = taskInfo.taskResponses.find((item: any) => item.authorId === currentUser.id)
    // delete myTask.author

    try {
      const addresses = await web3.eth.getAccounts()
      const markExecutedSignature = await getHashSignature(addresses[0], taskHash)
      const contractAbi: AbiItem[] = await getContractAbi(EContractAddress.FIX_DEAL_ESCROW)
      const contractInstance = new web3.eth.Contract(contractAbi, EContractAddress.FIX_DEAL_ESCROW)
      const gasPrice = await web3.eth.getGasPrice()
      const gasLimit = await contractInstance.methods
        .markExecuted(taskHash, markExecutedSignature)
        .estimateGas({ from: addresses[0] })

      const updatedTask = {
        ...taskInfo,
        status: {
          id: ETaskStatus.CONTRACTOR_EXECUTED
        },
        dealStatus: EDealStatus.EXECUTED
      }

      await contractInstance.methods
        .markExecuted(taskHash, markExecutedSignature)
        .send({
          from: addresses[0],
          gasPrice,
          gas: gasLimit
        })
        .on('transactionHash', () => {
          dispatch(isTaskInTransaction(true))
          toastr(EToastrTypeMessage.INFO, words[`${ETransactionMessage.TRANSACTION_MESSAGE}`])
        })
        .on('receipt', async () => {
          await api.put(`${API.URL}/tasks/${taskInfo.id}`, updatedTask)
          toastr(EToastrTypeMessage.SUCCESS, words[`${ETransactionMessage.TRANSACTION_SUCCESS}`])
          history.push(`/dashboard/subcontract?task=all-tasks&page=1&fromPrice=`)
        })
        .on('error', () => {
          toastr(EToastrTypeMessage.ERROR, words[`${ETransactionMessage.TRANSACTION_ERROR}`])
          dispatch(isTaskInTransaction(false))
        })
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(isTaskInTransaction(false))
    }
  }

  const handleAcceptTask = async () => {
    try {
      await acceptTask(taskInfo)
    } catch (err) {
      console.log(err)
    }
  }

  const handleSignTask = async (): Promise<void> => {
    try {
      await signTaskThunk(taskInfo, history)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      {isTransaction && <Loading isBackground={true} isTransaction={isTransaction} />}
      <div className={styles.container}>
        <section className={styles.header}>
          <h1>{words['user.header.subcontract']}</h1>
        </section>
        <div className={styles['wrap-bar']}>
          <button
            className={styles['back-btn']}
            onClick={() => {
              history.goBack()
            }}
          >
            <Icons icon={'arrowLeftWithStick'} />
            {words['back']}
          </button>
          <ButtonCondition
            currentUser={currentUser}
            taskInfo={taskInfo}
            parsed={parsed}
            history={history}
            openModal={openModal}
            verifiedStatus={verifiedStatus}
            role={role}
            invoice={invoice}
            handleDownloadInvoice={handleDownloadInvoice}
            handleAcceptTask={handleAcceptTask}
            markExecuted={markExecuted}
            handleSignTask={handleSignTask}
          />
        </div>

        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '545px'
            }}
          >
            <Loading />
          </div>
        ) : taskInfo.type !== '4' ? (
          <>
            <Maininfo taskInfo={taskInfo} parsed={parsed} userPhoto={userPhoto} />
            <Description taskInfo={taskInfo} parsed={parsed} userPhoto={userPhoto} />
          </>
        ) : (
          <>
            <Maininfo taskInfo={taskInfo} parsed={parsed} userPhoto={userPhoto} />

            {taskInfo.price || taskInfo.startProjectDate || taskInfo.deadline ? (
              <div
                className={cls(styles['info-task'], {
                  [styles['border-bottom']]: true
                })}
              >
                <div>
                  <p className={styles['title-user']}>{words['user.subcontract.task.budget']}:</p>
                  <h2 style={{ color: '#149B58' }}>
                    {taskInfo.price} {taskInfo.token && taskInfo.token.symbol}
                  </h2>
                </div>
                <div>
                  {taskInfo.fixedDeadline && taskInfo.startProjectDate ? (
                    <>
                      <p className={styles['title-user']}>
                        {words['user.subcontract.task.projectStart']}:
                      </p>
                      <h2>
                        {taskInfo.fixedDeadline
                          ? moment(taskInfo.startProjectDate).format(`DD.MM.YYYY`)
                          : ' without startProjectDate'}
                      </h2>
                    </>
                  ) : null}
                </div>
                <div>
                  {taskInfo.fixedDeadline && taskInfo.deadline ? (
                    <>
                      <p className={styles['title-user']}>
                        {words['user.subcontract.task.projectDelivery']}:
                      </p>
                      <h2>
                        {taskInfo.fixedDeadline
                          ? moment(taskInfo.deadline).format(`DD.MM.YYYY`)
                          : ' without deadline'}
                      </h2>
                    </>
                  ) : null}
                </div>
                <div>
                  {parsed.from === 'my-tasks' && taskInfo.type === '4' && (
                    <BaseButton
                      size="percent"
                      type="button"
                      outline={true}
                      className={styles.btn}
                      style={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                      onClick={() =>
                        history.push(`/dashboard/subcontract/invoices/${parsed.taskId}`)
                      }
                    >
                      <Icons icon="invoces" className={styles['btn__svg']} />
                      {words['user.subcontract.task.viewInvoices']}
                    </BaseButton>
                  )}
                </div>
              </div>
            ) : null}
            <Description taskInfo={taskInfo} parsed={parsed} userPhoto={userPhoto} />
            <section className={styles['personal-info']}>
              <h2>{words['user.createType.projectWorkSchedule']}</h2>
              <div className={styles.week}>
                {taskInfo.schedule ? weekSchedule(sortDays(taskInfo.schedule)) : null}
              </div>
            </section>
            <div className={styles['filesSection']}>
              <h2>{words['user.profile.mainInfo.files']}</h2>
              {taskInfo.taskFiles.map((item: any, idx: number) => (
                <FileView
                  isEdit={false}
                  item={item}
                  idx={idx}
                  key={String(idx)}
                  deleteFile={() => null}
                />
              ))}
            </div>
          </>
        )}
        {parsed.from === 'my-tasks' && taskInfo.type !== '4' && invoice && (
          <SubcontractInvoice fromTask={true} ref={downloadInvoiceRef} invoice={invoice} />
        )}
        {parsed.response ? (
          <>
            <div className={styles.toggle}>
              <ToggleBar
                name="tasks-responses"
                data={getToggleBarOptionsForSubcontractResponse(words)}
                defaultChecked={selectedResponseType}
                style={2}
                onChange={(event: any) => {
                  setTaskRespTab(getResponseType(event.value))
                  setSelectedResponseType(event)
                }}
              />
            </div>
            {taskRespTab === EResponsesHistory.ALL_RESPONSES && !loading ? (
              taskInfo.taskResponses &&
              taskInfo.taskResponses.map((response: any) => (
                <UserResponse
                  userId={response.authorId}
                  key={response.authorId}
                  accessToken={accessToken}
                  taskInfo={taskInfo}
                  response={response}
                  isAdmin={role === 2}
                  tasksResponses={taskInfo.taskResponses.length}
                />
              ))
            ) : taskRespTab === EResponsesHistory.ON_INTERVIEW && !loading ? (
              taskInfo.onInterviewUsers &&
              taskInfo.onInterviewUsers.map((response: any) => (
                <UserResponse
                  userId={response.authorId}
                  key={response.authorId}
                  accessToken={accessToken}
                  taskInfo={taskInfo}
                  response={response}
                  isAdmin={role === 2}
                  tasksResponses={taskInfo.onInterviewUsers.length}
                  status={EResponsesHistory.ON_INTERVIEW}
                />
              ))
            ) : taskRespTab === EResponsesHistory.AGREED && !loading ? (
              taskInfo.agreedUsers &&
              taskInfo.agreedUsers.map((response: any) => (
                <UserResponse
                  userId={response.authorId}
                  key={response.authorId}
                  accessToken={accessToken}
                  taskInfo={taskInfo}
                  response={response}
                  isAdmin={role === 2}
                  tasksResponses={taskInfo.agreedUsers.length}
                  status={EResponsesHistory.AGREED}
                />
              ))
            ) : taskRespTab === EResponsesHistory.MY_PERFORMER && !loading ? (
              executor && (
                <UserResponse
                  userId={taskInfo.executor.authorId}
                  accessToken={accessToken}
                  taskInfo={taskInfo}
                  response={executor}
                  isAdmin={role === 2}
                  tasksResponses={taskInfo.taskResponses.length}
                  status={EResponsesHistory.MY_PERFORMER}
                />
              )
            ) : (
              <></>
            )}
          </>
        ) : null}
        <CancelTaskModal
          proposal={proposal && proposal}
          size={'md'}
          toggle={modalState}
          action={openModal}
          task={taskInfo}
          updateTask={updateTaskInfo}
          loading={loading}
          error={error}
          history={history}
          createTaskCancellation={createTaskCancellation}
          currentUser={currentUser}
          updateTaskCancellation={updateTaskCancellation}
          isTaskInTransaction={isTaskInTransaction}
          createVoting={createVoting}
        />
      </div>
    </>
  )
}

export default Task
