import React, { useState } from 'react'
import DeleteIcon from '../../assets/images/delete_icon.svg'
import styles from './DeleteInvoice.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { deleteProjectInvoice } from '../Project/Invoices/Invoices.thunk'
import { TState } from 'store'
const DeleteInvoice = ({ invoice }: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const words = useSelector((state: TState) => state.global.language.words)
  const dispatch = useDispatch()
  const handleDeleteClick = () => {
    dispatch(deleteProjectInvoice(invoice.id))
  }
  return (
    <div onClick={(e: any) => e.stopPropagation()} className={styles.deleteButton}>
      <img onClick={() => setIsModalVisible(!isModalVisible)} src={DeleteIcon} alt="delete" />
      {isModalVisible && (
        <div className={styles.deleteModal}>
          <div className={styles.triangle} />
          <span className={styles.deleteModalTitle}>
            {words['component.deleteInvoice.delete']}?
          </span>
          <div className={styles.deleteModalButtons}>
            <span onClick={handleDeleteClick} className={styles.confirmButton}>
              {words['component.deleteInvoice.yes']}
            </span>
            <span onClick={() => setIsModalVisible(false)} className={styles.rejectButton}>
              {words['component.deleteInvoice.no']}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default DeleteInvoice
