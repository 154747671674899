import { API, api } from 'services/api'
import toastr from 'utils/toastr'
import { TInvoice } from './CreateInvoices.model'
import { Dispatch } from 'redux'
import { setDownloadInvoices } from '../Entrepreneurs.actions'

export const setInvoices = (body: TInvoice) => async (dispatch: Dispatch) => {
  try {
    const res = await api.post(`${API.URL}/entrepreneur`, body)
    dispatch(setDownloadInvoices(res.data.result))
  } catch (error) {
    // @ts-ignore
    toastr('error', error)
  }
}
