import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import TaskComponent from './Task'
import {
  clearTask,
  createTaskCancellation,
  getTask,
  updateTaskInfo,
  updateTaskCancellation
} from '../../Subcontract.thunk'
import { createVoting } from '../../../VotingList/VotingList.thunk'
import { acceptTask, signTaskThunk } from 'screens/client/Web3/web3.thunk'
import { isTaskInTransaction } from 'screens/client/Web3/web3.action'

export const mapStateToProps = (state: TState) => {
  return {
    taskInfo: state.client.subcontract.task,
    loading: state.client.subcontract.loading,
    accessToken: state.auth.data ? state.auth.data.accessToken : null,
    error: state.client.subcontract.error,
    currentUser: state.auth.data,
    isTransaction: state.client.web3.isTransaction
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getTask,
      updateTaskInfo,
      clearTask,
      signTaskThunk,
      createTaskCancellation,
      updateTaskCancellation,
      isTaskInTransaction,
      createVoting,
      acceptTask
    },
    dispatch
  )

export const Task = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskComponent)
