import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import CryptoApiComponent from './CryptoApi'
import { getInstanceThunk } from '../ControlPanel/ControlPanel.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    exchangeType: state.client.controlPanel.botInfo.exchangeType
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getInstanceThunk }, dispatch)

export const CryptoApi = connect(
  mapStateToProps,
  mapDispatchToProps
)(CryptoApiComponent)
