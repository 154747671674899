import { TBaseFilterConfigData } from 'components/UiKit/Filter/Filter'
import { TYPES_TRANSACTIONS } from 'globalConfigs'
import { TWords } from 'elements/SideBar/SideBar.config'
import moment from 'moment'

export const getFilterConfig = ({
  fromDate,
  toDate,
  words
}: {
  fromDate: any
  toDate: any
  words: TWords
}): TBaseFilterConfigData[] => {
  // const currentFormatMinFromDate = new Date(minFromDate).valueOf()
  // const currentFormatMaxToDate = new Date(maxToDate).valueOf()
  return [
    {
      name: 'type',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...TYPES_TRANSACTIONS(words)],
      label: words['user.subcontract.transactions.type'],
      width: 170
    },
    {
      name: 'fromDate',
      secondName: 'toDate',
      type: 'dateRange',
      label: words['user.subcontract.transactions.date'],
      fieldData: {
        from: fromDate ? moment(+fromDate).valueOf() : '',
        to: toDate ? moment(+toDate).valueOf() : ''
        // from: '',
        // to: ''
      },
      width: 110
    },
    {
      name: 'token',
      type: 'select',
      fieldData: [
        {
          value: 'TRL',
          label: 'TRL',
          icon: 'http://localhost:3000/static/media/logo-small.3e80600f8d325fe2d4eec86c2f891a8f.svg'
        },
        {
          value: 'USDT',
          label: 'USDT',
          icon:
            'https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/512/Tether-USDT-icon.png'
        }
      ],
      label: words['user.subcontract.transactions.token'],
      width: 100,
      hasIcons: true
    }
  ]
}
