import React from 'react'
// import styles from './MyTaskAnswerList.module.scss'
import { TaskInfo } from '../TaskInfo'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import queryString from 'query-string'
import history from 'utils/history'
import { finishPoint } from 'screens/client/Subcontract/components/CreateTask/initialvalues'

export const MyTaskAnswerList = ({ btnQuery, urlEndpoint, getTasks }: any) => {
  const stores = useSelector((state: TState) => state.client.subcontract.tasks)
  const parsed = queryString.parse(history.location.search)

  const pageCount = useSelector((state: TState) => state.client.subcontract.pageCount)

  const urlSearchParamses = new URLSearchParams(window.location.search)
  const paramses = Object.fromEntries(urlSearchParamses.entries())
  const fetchMore = () => {
    const url = new URL((window.location as unknown) as string)
    url.searchParams.set('page', String((paramses.page ? +paramses.page : 0) + 1))
    window.history.pushState({}, '', url.toString())

    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    getTasks(10, params.page, urlEndpoint, finishPoint(), parsed.clear === 'true')
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <InfiniteScroll
          dataLength={stores.length}
          next={fetchMore}
          hasMore={(paramses.page ? +paramses.page : 0) + 1 <= pageCount}
          loader={<h4>Loading...</h4>}
        >
          {stores.map((task: any) => (
            <TaskInfo
              key={task.id}
              id={task.id}
              status={task.status.id}
              deadline={true}
              path={`/dashboard/subcontract/${task.id}?from=${btnQuery}`}
              task={task}
            />
          ))}
        </InfiniteScroll>
      </div>
    </>
  )
}
