import React, { FC } from 'react'
import { ICheckout } from './Checkout.model'
// import CheckoutStyles from '../../Basket.module.scss'
import { ReactComponent as PurchasesIcon } from 'assets/images/purchases.svg'
import styles from '../../Basket.module.scss'
import { Textarea } from '../../../../../components/Textarea'
import { BaseButton } from '../../../../../components/UiKit/Button/BaseButton'
import { Icons } from 'components/Icons'
import { TPurchaseOfGoods } from '../../Basket.model'
import { ThankForPurchaseModal } from '../ThankForPurchaseModal/ThankForPurchaseModal'
import { Field, Form } from 'react-final-form'
import validation from '../../../../../utils/validation'
import { RedNote } from '../../../../../components/RedNote'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import toastr from '../../../../../utils/toastr'

export const Checkout: FC<ICheckout> = ({
  totalQuantity,
  totalPrice,
  goodsData,
  onPurchaseOfGoods,
  emptyTrash,
  balance,
  openThankForPurchase,
  setOpenThankForPurchase
}) => {
  const toggleThankForPurchase = () => setOpenThankForPurchase(!openThankForPurchase)
  const words = useSelector((state: TState) => state.global.language.words)

  return (
    <>
      <Form
        onSubmit={(values: any) => {
          if (balance < totalPrice) {
            toastr('info', words['NOT_ENOUGH_TCENTS'])
          } else {
            const body: TPurchaseOfGoods = {
              requestUserComment: values.comment,
              details: [...goodsData]
            }
            onPurchaseOfGoods(body)
          }
        }}
      >
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className={styles['container-checkout']}>
              <PurchasesIcon />
              <div className={styles['quantity-container']}>
                <div className={styles.text}>{words['user.basket.amountOfProducts']}</div>
                <div className={styles.number}>{totalQuantity}</div>
              </div>
              <div className={styles['price-container']}>
                <div className={styles.text}>{words['user.basket.toPay']}</div>
                <div className={styles['total-price-container']}>
                  <div className={styles.number}>{totalPrice}</div>
                  <Icons icon="pointIcon" />
                </div>
              </div>
              <div className={styles['checkout-comment-title']}>
                {`${words['user.profile.table.comment']}:`} <RedNote />
              </div>
              <Field
                name="comment"
                validate={validation.composeValidators(
                  validation.required(words['user.requiredMessage']),
                  validation.maxValue(1000)
                )}
                render={({ input, meta }) => (
                  <div>
                    <Textarea
                      {...input}
                      className={styles['checkout-comment']}
                      placeholder={`${words['user.basket.placeholderComment']}...`}
                      isInvalid={meta.error && meta.touched}
                      errorMessage={meta.error}
                    />
                  </div>
                )}
              />
              <div className={styles['btn-container']}>
                <BaseButton children={words['user.basket.send']} size="percent" type="submit" />
              </div>
            </form>
          )
        }}
      </Form>
      <ThankForPurchaseModal
        open={openThankForPurchase}
        toggleThankForPurchase={toggleThankForPurchase}
        emptyTrash={emptyTrash}
      />
    </>
  )
}
