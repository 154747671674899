import { History } from 'history'
import { ESettingsActions } from './ControlPanel.constants'
import { mapDispatchToProps, mapStateToProps } from './ControlPanel.container'

export interface IChannelSettings {
  active: boolean
  autoAveraging: boolean
  autoAveragingTP: number
  averageToPositionSize: null | boolean
  averaging: boolean
  channelName: string
  closeAndReopenWhenOpposite: boolean
  closeOrderType: string
  closeWhenLessEnable: boolean
  closeWhenLessPercent: number
  defaultTP: number
  enableMultiplierTPSL: boolean
  enableOnlyMoveTPStep: boolean
  entryPriceMode: string
  exchange: string
  firstStepAveraging: number
  ignoreCloseSignals: boolean
  maxAutoAveraging: number
  maxChannelAveraging: number
  numberOfTakes: null | number
  onlyMoveTPStep: number
  onlyOneSide: string
  openOrderType: string
  otherStepsAveraging: number
  percentagePosToAver: null | number
  periodAfterOMTPStep: number
  restAtBreakevenMode: string
  secondStepAveraging: number
  stopLoss: number
  stopLossMode: string
  tSInsteadSL: boolean
  tSInsteadTP: boolean
  taFilter: boolean
  takeProfit: number
  takeProfitMode: string
  whiteList: string[]
  manualTsRollbackPercentage: boolean
}

interface TelegramBotCustomAssets {
  active: boolean
  channelId: string | null
}

interface TelegramBotCustomDaily {
  active: boolean
  channelId: string | null
}

interface TelegramBotGeneralDaily {
  active: boolean
  channelId: string
}

interface TelegramBot {
  active: boolean
  customAssets: TelegramBotCustomAssets
  customDaily: TelegramBotCustomDaily
  generalDaily: TelegramBotGeneralDaily
  telegramBotToken: string
}

export interface IExchangeSettings {
  active: boolean
  allocationPercent: string
  autoHold: boolean
  currentRealizedPnl: string
  enableProfitFixation: boolean
  exchange: string
  enableFundingRateLimit: boolean
  fundingRateLimit: string
  holdList: string[]
  initialBalance: number
  enableMaxNumPositions: boolean
  maxNumPositions: number
  maxOrderPrice: string
  minSumForHoldOrder: string
  orderPrice: string
  orderPriceMode: string
  percentForHold: string
  enableLimitTokenAlive: boolean
  limitTokenAlive: null | number
  enablePercentageForStability: boolean
  percentageForStability: number
  pnlRecTime: Date
  profitFixationMode: string
  profitFixationTime: string
  resetPositions: boolean
  stopLoss: number
  takeProfit: number
  telegramBot: TelegramBot
  timeZone: string
  trailing: string
  trailingTrigger: string
  transferToSpot: boolean
  unProfTrigger: string
}

export interface GlobalConfig {
  instanceId: string
  ownerName: string
}

export type TObjectRecord = Record<string, string | number | boolean | string[] | null>

export type TChannels = TObjectRecord | IChannelSettings

export interface IStandardSettings {
  channels: IChannelSettings[]
  exchanges: IExchangeSettings
  global: GlobalConfig
}

export interface IStandardExchange {
  [key: string]: IStandardSettings
}

export interface IDefaultSettings {
  [key: string]: IStandardExchange
}
export interface ISettings extends Omit<IStandardSettings, 'channels'> {
  channels: TChannels | TChannels[]
}

interface DataRow {
  [key: string]: string | number
}

export enum EProfitFixationMode {
  TOTAL_BALANCE = 'TOTAL_BALANCE',
  ORDER = 'ORDER',
  TOTAL_BALANCE_TRAILING = 'TOTAL_BALANCE_TRAILING'
}

export const enum ETakeProfitMode {
  WITHOUT = 'WITHOUT',
  SIGNAL = 'SIGNAL',
  MANUAL = 'MANUAL',
  MANUAL_MULTIPLE = 'MANUAL_MULTIPLE'
}

export const enum EStopLossMode {
  WITHOUT = 'WITHOUT',
  SIGNAL = 'SIGNAL',
  MANUAL = 'MANUAL'
}

export enum EBotStatus {
  RUNNING = 'RUNNING',
  STOPPED = 'STOPPED',
  NEVER_STARTED = 'NEVER_STARTED'
}

export const enum EOrderPrice {
  FIXED = 'FIXED',
  ALLOCATION = 'ALLOCATION'
}

export const enum ERestAtBreakevenMode {
  WITHOUT = 'WITHOUT',
  ALWAYS = 'ALWAYS'
}

export const enum EOrderType {
  LIMIT = 'LIMIT',
  MARKET = 'MARKET'
}

export type ValidateExchangesSettings = (
  values: IExchangeSettings
) => { [key: string]: string } | undefined

export type ValidateChannelsSettings = (
  values: IChannelSettings
) => { [key: string]: string } | undefined

export const enum EValidationSchema {
  MAX_VALUE = 'Максимальное значение',
  MIN_VALUE = 'Минимальное значение'
}

export interface ExchangeInfo {
  exchange: string
  totalCrossWalletBalance: number
  totalUnrealizedProfit: number
  cumRealizedPnl: number
  realizedPnl: number
  equity: number
  availableBalance: number
  totalPositionInitialMargin: number
}

interface PositionInfo {
  symbol: string
  positionAmt: string
  entryPrice: string
  markPrice: string
  unRealizedProfit: string
  liquidationPrice: string
  leverage: string
  maxNotionalValue: null
  marginType: null
  isolatedMargin: null
  isAutoAddMargin: string
  positionSide: null
  notional: null
  isolatedWallet: null
  updateTime: null
}

interface Position {
  info: PositionInfo
  symbol: null
  contracts: null
  unrealizedPnl: number
  leverage: number
  collateral: null
  notional: null
  markPrice: number
  entryPrice: number
  timestamp: null
  initialMargin: number
  initialMarginPercentage: null
  maintenanceMargin: null
  maintenanceMarginPercentage: null
  datetime: null
  marginMode: null
  side: string
  hedged: boolean
  percentage: number
}

interface ExchangeData {
  exchange: string
  positions: Position[]
}

export type TPositionsInfo = ExchangeData[]

export type TPositionResult = DataRow

export interface ExchangeCredentials {
  exchange: string
  key: string
  secret: string
}

export type TExchangeType = 'binance' | 'bybit'

export type TStrategyType = keyof IDefaultSettings

export type TBotInfo = {
  botId: string | null
  exchangeType: TExchangeType | null
  strategyType: TStrategyType | null
}

export type TBotInfoResponse = {
  bot_id: string
  instance_id: string
  selectedExchange: TExchangeType | null
  selectedStrategy: TStrategyType
}

export interface IGraphStatistic {
  id: number
  exchange: string
  maxMarginUsed: number
  date: string
  profit: string | null
  profitNET: string
  dailyProfitNET: string | null
  updatedAt: string
  createdAt: string
}

export type IInitialState = {
  botInfo: TBotInfo
  error: string | null
  isLoading: boolean
  settings: null | IStandardSettings
  exchangeInfo: ExchangeInfo[] | null
  positionsInfo: TPositionResult[] | null
  graphStatistic: IGraphStatistic[] | null
}

interface IGraphStatisticAction {
  type: typeof ESettingsActions.GET_GRAPH_STATISTIC
  payload: IGraphStatistic[]
}

interface IRequestStartedAction {
  type: typeof ESettingsActions.REQUEST_STARTED
}

interface IBotInfoAction {
  type: typeof ESettingsActions.GET_BOT_INFO
  payload: TBotInfoResponse
}

interface IExchangeInfoAction {
  type: typeof ESettingsActions.GET_EXCHANGE_INFO
  payload: ExchangeInfo[]
}

interface IPositionsInfoAction {
  type: typeof ESettingsActions.GET_POSITIONS_INFO
  payload: TPositionsInfo
}

interface IGetBotSettingsAction {
  type: typeof ESettingsActions.GET_BOT_SETTINGS
  payload: IStandardSettings
}

interface IGetBotExchangeTypeAction {
  type: typeof ESettingsActions.GET_BOT_EXCHANGE_TYPE
  payload: TExchangeType
}

export type TControlPanelProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & {
    history: History<unknown> & string
  }

export type TSettingsAction =
  | IRequestStartedAction
  | IExchangeInfoAction
  | IPositionsInfoAction
  | IGraphStatisticAction
  | IGetBotSettingsAction
  | IBotInfoAction
  | IGetBotExchangeTypeAction
