/* eslint-disable */
import { Dispatch } from 'redux'
import { API, api } from '../../../../../services/api'
import {
  createTaskRequest,
  createTaskSuccess,
  createTaskError,
  getTaskSuccess
} from './CreateTask.actions'
import toastr from '../../../../../utils/toastr'
import { TState } from 'store'
import { AxiosError } from 'axios'
import { getTaskRequest, updateTaskSuccess } from '../../Subcontract.actions'

export function taskCreatorReq(body: any, history: any, path?: string) {
  return async (dispatch: Dispatch, getData: () => TState) => {
    dispatch(createTaskRequest())
    dispatch(getTaskRequest())
    const words = getData().global.language.words

    try {
      const { data } = await api.post(`${API.URL}/tasks`, body)
      dispatch(updateTaskSuccess(data.payload.result))
      dispatch(createTaskSuccess())
      history.push(path ? '/' + path : `/dashboard/subcontract?task=all-tasks&page=1`)

      return data
    } catch (error) {
      const err = error as AxiosError<any>
      //@ts-ignore
      dispatch(createTaskError(err.response))
      toastr('error', words['CREATE_TASK_ERROR'], err)
    }
  }
}

export const getTaskInfo = (id: any) => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  const words = getData().global.language.words
  dispatch(createTaskRequest())
  try {
    let data = {}
    const res = await api.get(`${API.URL}/tasks/${id}`)
    data = { ...res.data.payload.result }
    dispatch(getTaskSuccess(data))
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(createTaskError(err.response))
    toastr('error', words['REQUEST_NOT_FOUND'], err)
  }
}

export const updateTaskInfo = (id: any, body: any, history: any, path: string) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  const words = getData().global.language.words
  dispatch(createTaskRequest())
  try {
    const { data } = await api.put(`${API.URL}/tasks/${id}`, body)
    dispatch(updateTaskSuccess(data.payload.result))
    history.push(path ? path : `/dashboard/subcontract?task=all-tasks&page=1`)
    return data
  } catch (error) {
    const err = error as AxiosError<any>
    console.log(err)
    //@ts-ignore
    dispatch(createTaskError(err.response))
    toastr('error', words['REQUEST_NOT_FOUND'], err)
  }
}
