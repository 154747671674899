import { TBaseFilterConfigData } from 'components/UiKit/Filter/Filter'
import { TWords } from 'elements/SideBar/SideBar.config'
import {
  modelsData,
  stageData,
  methodologyData,
  paymentMethodData
} from '../CreateProject/CreateProject'
import { TManager } from './Projects.model'

export const getFilterConfig = ({
  displayAllFields,
  managers,
  words
}: {
  displayAllFields: boolean
  words: TWords
  managers: TManager[]
}): TBaseFilterConfigData[] => {
  const filter: TBaseFilterConfigData[] = [
    {
      name: 'name',
      type: 'input',
      label: words['project.name'],
      width: '22%'
    },
    {
      name: 'startFromDate',
      secondName: 'startToDate',
      type: 'dateRangeNew',
      label: words['project.start'],
      fieldData: {
        from: '',
        to: ''
      },
      rangeContainerWidth: '20%'
    },
    {
      name: 'endFromDate',
      secondName: 'endToDate',
      type: 'dateRangeNew',
      label: words['project.end'],
      fieldData: {
        from: '',
        to: ''
      },
      rangeContainerWidth: '20%'
    },
    {
      name: 'model',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...modelsData],
      label: words['project.model'],
      placeholder: '',
      width: '22%'
    }
  ]
  if (displayAllFields) {
    filter.push(
      {
        name: 'stage',
        type: 'select',
        fieldData: [{ value: '@ALL@', label: words['all'] }, ...stageData],
        label: words['project.stage'],
        placeholder: words['project.stage'],
        width: '22%'
      },
      {
        name: 'methodology',
        type: 'select',
        fieldData: [{ value: '@ALL@', label: words['all'] }, ...methodologyData],
        label: words['project.methodology'],
        placeholder: words['project.methodology'],
        width: '20%'
      },
      {
        name: 'paymentMethod',
        type: 'select',
        fieldData: [{ value: '@ALL@', label: words['all'] }, ...paymentMethodData],
        label: words['project.payment'],
        placeholder: words['project.payment'],
        width: '20%'
      },
      {
        name: 'manager',
        type: 'select',
        fieldData: [{ value: '@ALL@', label: words['all'] }, ...managers],
        label: words['project.manager'],
        placeholder: words['project.manager'],
        width: '22%'
      },
      {
        name: 'customerAssistant',
        type: 'select',
        fieldData: [{ value: '@ALL@', label: words['all'] }, ...managers],
        label: words['project.assistant'],
        placeholder: words['project.assistant'],
        width: '22%'
      }
    )
  }
  return filter
}
