import { action } from 'store/store.utils'
import { EProjectInvoices } from './ProjectInvoices.constants'
import { TInvoice, TInvoiceAction } from './ProjectInvoices.model'

export const getInvoicesRequest = () => action(EProjectInvoices.GET_INVOICES_REQUEST)
export const getInvoicesSuccess = (payload?: TInvoice[]): TInvoiceAction =>
  action(EProjectInvoices.GET_INVOICES_SUCCESS, payload)
export const getProjectsRequest = () => action(EProjectInvoices.GET_PROJECTS_REQUEST)
export const getProjectsSuccess = (payload?: any): TInvoiceAction =>
  action(EProjectInvoices.GET_PROJECTS_SUCCESS, payload)
export const deleteInvoiceRequest = () => action(EProjectInvoices.DELETE_INVOICE_REQUEST)
export const deleteInvoiceSuccess = (payload?: number): TInvoiceAction =>
  action(EProjectInvoices.DELETE_INVOICE_SUCCESS, payload)
