import React from 'react'
import styles from '../../CreateInvoices.module.scss'
import { useSelector } from 'react-redux'
import { TState } from 'store'

import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import { FormApi } from 'final-form'
import { TSelectData } from '../../CreateInvoices.model'

type TSelectInoviceProps = {
  name: string
  label: string
  data: TSelectData[]
  form: FormApi
}

const SelectInvoice = ({ name, label, form, data }: TSelectInoviceProps) => {
  const words = useSelector((state: TState) => state.global.language.words)
  return (
    <FieldFactory
      form={form}
      config={[
        {
          items: [
            {
              name: name,
              label: label,
              required: true,
              inputWrapperClassName: styles['form-value'],
              component: () => {
                return {
                  type: 'select',
                  props: {
                    placeholder: '',
                    options: data,
                    defaultValue: data[0]
                  }
                }
              }
            }
          ]
        }
      ]}
      words={words}
    />
  )
}
export default SelectInvoice
