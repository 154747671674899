import React, { FC, useEffect, useMemo } from 'react'
import styles from './VerificationAdmin.module.scss'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { TVerificationAdminProps } from './VerificationAdmin.model'
import Pagination from 'react-js-pagination'
import { Table as UIKitTable } from '../../../../components/UiKit/Table'
import Loading from '../../../../components/Spinner'
import { ReactComponent as Prev } from 'assets/images/prev.svg'
import { ReactComponent as Next } from 'assets/images/next.svg'
import { Filter } from '../../../../components/UiKit/Filter'
import { getFilterConfig } from './Verifications.filter.config'
import { Columns } from './Verifications.columns'
import { Column } from 'react-table'
import BackButton from 'components/BackButton/BackButton'

const VerificationAdmin: FC<TVerificationAdminProps> = ({
  userList,
  getUsers,
  history,
  loading,
  itemsCount
}) => {
  const ranks = useSelector((state: TState) => state.admin.verification.rank)
  const positions = useSelector((state: TState) => state.admin.verification.position)
  const parsed = queryString.parse(history.location.search)
  const words = useSelector((state: TState) => state.global.language.words)

  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())

  const columns = Columns({ role: 2, isOnlyManager: false })
  const columnsMemoized = useMemo(() => columns, [])

  useEffect(() => {
    getUsers(10, '', '&verification=inprogress')
  }, [])

  const handlePageChange = (pagePag: number) => {
    const url = new URL((window.location as unknown) as string)
    url.searchParams.set('page', String(pagePag))
    window.history.pushState({}, '', url.toString())
    window.scrollTo(0, 0)
  }

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>{words['admin.subcontract.verification']}</h1>
      </section>
      <BackButton />
      <div className={styles.verification}>
        <section className={styles['list-filter']}>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '80vh'
              }}
            >
              <Loading />
            </div>
          ) : parsed.task === null ? (
            <p>ACCEPTED_VERIFY</p>
          ) : (
            <>
              <Filter
                config={getFilterConfig({
                  positions,
                  role: 4,
                  fromDate: parsed.fromDate,
                  toDate: parsed.toDate,
                  ranks,
                  parsed,
                  usersData: userList,
                  words
                })}
                withButton={false}
                defaultOpened={true}
              />
              <UIKitTable
                data={userList}
                columns={columnsMemoized as Column<any>[]}
                total={userList.length}
                getData={() => ({})}
                classNameToRow={styles.cursor}
                tableHeight={'40vh'}
                noData={words['admin.subcontract.verification.userListEmpty']}
                onRowClick={d => {
                  history.push(`${history.location.pathname}/${d.original.id}`)
                }}
              />
            </>
          )}
          <div className={styles.filter}>
            <div className={styles['pagination-container']}>
              <Pagination
                itemsCountPerPage={10}
                totalItemsCount={itemsCount}
                activePage={params.page ? +params.page : 0}
                onChange={handlePageChange}
                hideFirstLastPages={true}
                prevPageText={<Prev />}
                nextPageText={<Next />}
                linkClass={styles['pagination-item']}
                activeLinkClass={styles['pagination-active']}
                disabledClass={styles['pagination-disabled']}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default VerificationAdmin
