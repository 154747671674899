import { EProjectInvoices } from './ProjectInvoices.constants'
/* eslint-disable no-console */
import { IAction } from 'models'
import { TInvoice, TProject } from './ProjectInvoices.model'

export type IInitialState = {
  error: string
  loading: boolean
  invoices: TInvoice[]
  projects: TProject[]
}

export const initialState: IInitialState = {
  error: '',
  loading: false,
  invoices: [],
  projects: []
}

export default (state = initialState, action: IAction): any => {
  switch (action.type) {
    case EProjectInvoices.GET_INVOICES_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case EProjectInvoices.GET_INVOICES_SUCCESS: {
      return {
        ...state,
        loading: false,
        invoices: action.payload
      }
    }
    case EProjectInvoices.GET_PROJECTS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case EProjectInvoices.GET_PROJECTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        projects: action.payload
      }
    }
    case EProjectInvoices.DELETE_INVOICE_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case EProjectInvoices.DELETE_INVOICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        invoices: state.invoices.filter(i => i.id !== action.payload)
      }
    }

    default:
      return state
  }
}
