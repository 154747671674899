/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import React, { useState } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './styles.scss'
import styles from './BigCalendar.module.scss'
import cls from 'classnames'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import format from 'date-fns/format'
import { Form } from 'react-final-form'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import { useSelector } from 'react-redux'
import { Icons } from 'components/Icons'
import moment from 'moment'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { formatDateAndTime } from 'helpers/formatDateAndTime'

const BigCalendars = ({
  setEvent,
  eventsArray,
  changeEvent,
  getEvents,
  deleteEvent,
  isExecutor,
  changedDay,
  taskId,
  setUsedTime,
  currentTask,
  addEventToGoogleCalendar,
  updateEventToGoogleCalendar,
  deleteEventFromGoogleCalendar
}) => {
  const [events, setEvents] = useState([])
  const [modalState, setModalState] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(undefined)
  const [taskData, setTaskData] = useState([])
  const [currentId, setCurrentId] = useState()
  const [isNewEvent, setIsNewEvent] = useState(true)
  const [isFieldEmpty, setIsFieldEmpty] = useState(false)

  const words = useSelector(state => state.global.language.words)

  const userId = useSelector(state => state.auth.data.id)

  const executorEmail = currentTask.executor !== null ? currentTask.executor.email : ''

  //FILTER EVENTS BY TASK AND USERS
  let newArr = eventsArray
    .filter(
      item =>
        item.taskId === +taskId &&
        (item.pretenderId === (currentTask.executor && currentTask.executor.id) ||
          item.pretenderId === currentTask.customer.id)
    )
    .map(item => {
      if (item.pretenderId === userId) {
        item.isExecutor = false
      } else {
        item.isExecutor = true
      }

      return item
    })

  React.useEffect(() => {
    setEvents(newArr)
  }, [])

  React.useEffect(() => {
    setEvents(e => {
      if (isExecutor) {
        return e
      } else {
        const newEvents = e.filter(item => item.isExecutor === false)
        return newEvents
      }
    })
  }, [isExecutor])

  //CALCULATE USED HOURSED

  const currentUserEvents = React.useMemo(() => {
    return events.filter(item => item.pretenderId && item.pretenderId !== userId)
  }, [newArr])

  const hours = React.useMemo(() => {
    return events.reduce((acc, curr) => {
      return acc + (curr.end - curr.start)
    }, 0)
  }, [events])

  const otherHours = React.useMemo(() => {
    return currentUserEvents.reduce((acc, curr) => {
      return acc + (curr.end - curr.start)
    }, 0)
  }, [currentUserEvents])

  const totalOtherHours = React.useMemo(() => {
    return Math.floor(otherHours / 1000 / 60 / 60)
  }, [currentUserEvents])

  const totalHours = React.useMemo(() => {
    return Math.floor(hours / 1000 / 60 / 60)
  }, [events])

  React.useEffect(() => {
    setUsedTime(totalHours - totalOtherHours)
  }, [events])

  //SET EVENT TO ADD TO DB

  const setNewEvent = values => {
    const idx = events.findIndex(item => item.eventId === selectedEvent.eventId)
    const filtered = events.find(item => item.eventId === selectedEvent.eventId)
    const newEvent = {
      ...filtered,
      title: values.title,
      description: values.description
    }
    const nextEvents = [...events]
    nextEvents.splice(idx, 1, newEvent)
    setEvents(nextEvents)
    setModalState(false)
    return newEvent
  }

  const onEventResize = ({ event, start, end }) => {
    if (event.pretenderId && event.pretenderId !== userId) {
      return
    }
    setEvents(() => {
      const nextEvents = events.map(existingEvent => {
        if (existingEvent.eventId == event.eventId) {
          const newEvent = { ...existingEvent, start, end }

          changeEvent({
            ...newEvent,
            taskId: +taskId
          })

          updateEventToGoogleCalendar(
            getEventToGoogleCalendar(newEvent),
            newEvent.eventId,
            executorEmail
          )
          return { ...existingEvent, start, end }
        } else {
          return existingEvent
        }
      })
      return nextEvents
    })
  }

  const onEventDrop = ({ event, start, end }) => {
    if (event.pretenderId && event.pretenderId !== userId) {
      return
    }
    const idx = events.indexOf(event)

    const firstEvent = {
      start: new Date(start),
      end: new Date(end),
      title: event.title,
      description: event.description,
      isExecutor: false,
      eventId: event.eventId
    }

    changeEvent({
      ...firstEvent,
      taskId: +taskId
    })

    updateEventToGoogleCalendar(
      getEventToGoogleCalendar(firstEvent),
      firstEvent.eventId,
      executorEmail
    )

    setEvents(currentEvents => {
      const nextEvents = [...currentEvents]
      nextEvents.splice(idx, 1, firstEvent)
      return nextEvents
    })
  }

  const onSelectEvent = data => {
    if (data.pretenderId && data.pretenderId !== userId) {
      return
    }
    setSelectedEvent(data)
    setIsNewEvent(false)
    setTaskData({
      title: data.title,
      description: data.description
    })

    setEvents(currentEvents => {
      const idx = currentEvents.findIndex(item => item.eventId === data.eventId)
      const filtered = currentEvents.find(item => item.eventId === data.eventId)
      const newEvent = {
        ...filtered,
        title: data.title,
        description: data.description
      }
      const nextEvents = [...currentEvents]
      nextEvents.splice(idx, 1, newEvent)
      return nextEvents
    })
    setEditModal(true)
    setModalState(true)
  }

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const onSelectSlot = slotInfo => {
    setSelectedEvent(slotInfo)
    setIsNewEvent(true)
    setTaskData({
      title: slotInfo.title ? slotInfo.title : '',
      description: slotInfo.description ? slotInfo.description : ''
    })
    setEditModal(false)
    setModalState(true)
    setEvents(currentEvents => {
      const firstEvent = {
        start: new Date(slotInfo.start),
        end: new Date(slotInfo.end),
        title: slotInfo.title ? slotInfo.title : '',
        description: slotInfo.description ? slotInfo.description : '',
        isExecutor: false,
        isOverlap: false,
        eventId: currentId
      }

      setSelectedEvent(firstEvent)

      currentEvents.push(firstEvent)

      return currentEvents
    })
  }

  // FUNCTIONS FOR CHANGE EVENT IN CALENDAR AND DB

  const handleDeleteEvent = () => {
    const confirm = window.confirm('Are you sure you want to delete?')
    if (confirm) {
      const idx = events.findIndex(item => item.eventId === selectedEvent.eventId)
      const nextEvents = [...events]
      nextEvents.splice(idx, 1)
      setEvents(nextEvents)
      deleteEvent({ ...selectedEvent, taskId: +taskId })
      deleteEventFromGoogleCalendar(selectedEvent.eventId)
      setModalState(false)
    } else {
      return
    }
  }

  const handleCancelCreateEvent = () => {
    if (isNewEvent) {
      const idx = events.findIndex(item => item.eventId === selectedEvent.eventId)
      const nextEvents = [...events]
      nextEvents.splice(idx, 1)
      setEvents(nextEvents)
      setModalState(false)
    } else {
      setModalState(false)
    }
  }

  const getEventToGoogleCalendar = values => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const dateToGoogleCalendar = formatDateAndTime(values.start).date
    const startDateToGoogleCalendar = formatDateAndTime(values.start).currentTime
    const endDateToGoogleCalendar = formatDateAndTime(values.end).currentTime

    const googleEvent = {
      timeZone,
      title: values.title,
      date: dateToGoogleCalendar,
      from: startDateToGoogleCalendar,
      to: endDateToGoogleCalendar
    }

    return googleEvent
  }

  // CHANGE EVENT TITLE AND TIME IN EVENT

  const MySlot = ({ event }) => {
    return (
      <div>
        <div className={styles.slotTitle}>{event.title}</div>
        <div className={styles.slotTime}>
          {`${moment(event.start)
            .locale('en')
            .format('LT')}`}
          {' - '}
          {moment(event.end)
            .locale('en')
            .format('LT')}
        </div>
      </div>
    )
  }

  // CHANGE CALENDAR HEADER AND TIME

  const calendarComponents = {
    week: {
      header: ({ date }) => {
        const dayOfWeek = format(date, 'EE')
        const dayOfMonth = format(date, 'd')
        return (
          <div className={styles.days}>
            <span className={styles['day-week']}>{dayOfWeek}</span>
            <div className={cls({ [styles.today]: new Date().getDate() === +dayOfMonth })}>
              <span className={styles['day-month']}>{dayOfMonth}</span>
            </div>
          </div>
        )
      }
    },
    timeGutter: ({ components }) => {
      const TimeGutterComponent = components.timeGutter || TimeGutter
      return (
        <TimeGutterComponent
          {...TimeGutterComponent.props}
          date={start}
          ref={TimeGutterComponent.gutterRef}
          className="rbc-time-gutter"
        />
      )
    },
    event: MySlot
  }

  const calendarFormats = {
    timeGutterFormat: (date, culture) => format(date, 'h a', culture),
    eventTimeRangeFormat: () => null
  }

  return (
    <div className={styles.container}>
      <DnDCalendar
        formats={calendarFormats}
        components={calendarComponents}
        date={changedDay}
        startAccessor={event => event.start}
        endAccessor={event => event.end}
        onNavigate={() => start}
        eventPropGetter={event => {
          const newStyle = {
            backgroundColor: '#3737ED',
            border: 'none',
            left: '50%',
            minWidth: '100% !important'
          }

          let newClassName = ''

          if (event.isExecutor) {
            newStyle.backgroundColor = '#FF5F1B'
            newClassName += 'executor '
          }

          if (event.isOverlap) {
            newClassName += 'overlap '
          }

          return {
            className: newClassName,
            style: newStyle
          }
        }}
        toolbar={false}
        defaultView="week"
        events={events}
        localizer={localizer}
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
        resizable
        selectable
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        step={15}
        timeslots={4}
        style={{ height: 'calc(100vh - 80px)', width: '100%' }}
      />
      {modalState && (
        <>
          <div className={styles.modal} style={{ height: editModal ? '300px' : '450px' }}>
            {editModal ? (
              <>
                <div className={styles.modalIcons}>
                  <button
                    className={`${styles.modalButton} ${styles.modalEdit}`}
                    onClick={() => setEditModal(false)}
                  >
                    <Icons icon="edit" />
                  </button>
                  <button className={`${styles.modalButton}`} onClick={handleDeleteEvent}>
                    <Icons icon="delete" />
                  </button>
                  <button
                    className={`${styles.modalButton} ${styles.modalClose}`}
                    onClick={() => setModalState(false)}
                  >
                    <Icons icon="closeModal" />
                  </button>
                </div>
                <h2>Задача</h2>
                <p className={`${styles.modalText} ${styles.modalTitle}`}>
                  <span>Название:</span> {selectedEvent.title}
                </p>
                <p className={`${styles.modalText} ${styles.modalDescription}`}>
                  <span>Описание:</span> {selectedEvent.description}
                </p>
                <hr className={styles.modalLine} />
                <div className={styles.timeSlot}>
                  <div className={styles.timeText}>
                    <Icons icon="timeIcon" />
                    <p style={{ marginRight: '8px' }}>
                      {capitalizeFirstLetter(moment(selectedEvent.start).format('dddd, D MMMM'))}
                    </p>
                    <p>
                      {`${moment(selectedEvent.start).format('LT')}`}
                      {' - '}
                      {moment(selectedEvent.end).format('LT')}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <h2>Задача</h2>
                <Form
                  initialValues={taskData}
                  onSubmit={async values => {
                    if (!values.title || !values.description) {
                      setIsFieldEmpty(true)
                      return
                    } else {
                      setIsFieldEmpty(false)
                    }
                    setTaskData({
                      title: values.title ? values.title : 'no title',
                      description: values.description ? values.description : 'no title'
                    })

                    if (isNewEvent) {
                      const googleCurrentEvent = await addEventToGoogleCalendar(
                        getEventToGoogleCalendar(setNewEvent(values)),
                        executorEmail
                      )
                      setCurrentId(googleCurrentEvent.id)
                      await setEvent({
                        ...setNewEvent(values),
                        eventId: googleCurrentEvent.id,
                        taskId: +taskId
                      })
                      getEvents(+taskId)
                    } else {
                      const editEvent = await changeEvent({
                        ...setNewEvent(values),
                        taskId: +taskId,
                        eventId: selectedEvent.eventId
                      })
                      if (editEvent) {
                        updateEventToGoogleCalendar(
                          getEventToGoogleCalendar(setNewEvent(values)),
                          editEvent.data.changedEvent.eventId,
                          executorEmail
                        )
                      }
                    }
                  }}
                >
                  {({ form, handleSubmit }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <FieldFactory
                          form={form}
                          config={[
                            {
                              items: [
                                {
                                  name: 'title',
                                  label: 'Название',
                                  required: false,
                                  inputWrapperClassName: styles['title'],
                                  component: () => {
                                    return {
                                      type: 'input',
                                      props: {
                                        variant: 'outlined'
                                      }
                                    }
                                  }
                                }
                              ]
                            }
                          ]}
                          words={words}
                        />
                        <FieldFactory
                          form={form}
                          config={[
                            {
                              style: {},
                              items: [
                                {
                                  name: 'description',
                                  label: words['user.subcontract.task.description'],
                                  required: false,
                                  inputWrapperClassName: styles['description'],
                                  component: () => {
                                    return {
                                      type: 'textarea',
                                      props: { size: 'sm' }
                                    }
                                  }
                                }
                              ]
                            }
                          ]}
                          words={words}
                        />
                        <hr className={styles.modalLine} />
                        <div className={styles.timeSlot}>
                          <div className={styles.timeText}>
                            <Icons icon="timeIcon" />
                            <p style={{ marginRight: '8px' }}>
                              {capitalizeFirstLetter(
                                moment(selectedEvent.start).format('dddd, D MMMM')
                              )}
                            </p>
                            <p>
                              {`${moment(selectedEvent.start).format('LT')}`}
                              {' - '}
                              {moment(selectedEvent.end).format('LT')}
                            </p>
                          </div>
                          <div className={styles.buttons}>
                            <BaseButton
                              children={words['user.subcontract.cancel']}
                              type="button"
                              outline={true}
                              className={styles['btn-outline']}
                              onClick={handleCancelCreateEvent}
                            />
                            <BaseButton
                              className={styles.btn}
                              children={'Сохранить'}
                              size="lgs"
                              type="submit"
                            />
                          </div>
                          {isFieldEmpty && (
                            <span className={styles.validationError}>
                              All fields are required! Please fill them all
                            </span>
                          )}
                        </div>
                      </form>
                    )
                  }}
                </Form>
              </>
            )}
          </div>
        </>
      )}
      {modalState && <div className={styles.mask} onClick={handleCancelCreateEvent} />}
    </div>
  )
}

const localizer = momentLocalizer(moment)

//@ts-ignore
const DnDCalendar = withDragAndDrop(Calendar)

export default BigCalendars
