import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { TInvoiceValues, TCreateInvoiceProps, IUser, IInvoiceSelect } from './CreateInvoice.model'
import { Icons } from 'components/Icons'
import styles from './CreateInvoice.module.scss'
import { Form } from 'react-final-form'
import InputInvoice from './components/InputInvoice/InputInvoice'
import DateInvoice from './components/DateInvoice/DateInvoice'
import SelectInvoice from './components/SelectInvoice/SelectInvoice'
import validation from 'utils/validation'
import TableInvoice from './components/TableInvoice/TableInvoice'
import Checkbox from 'components/UiKit/Checkbox/Checkbox'
import { Button } from 'components/UiKit/Button'
import Spinner from 'components/Spinner'
import toastr from 'utils/toastr'
import { setProjectInvoice } from './CreateInvoice.thunk'
import queryString from 'query-string'

export const discountData: IInvoiceSelect[] = [
  {
    value: 'SALE',
    label: 'SALE'
  },
  {
    value: 'More SALE',
    label: 'More SALE'
  }
]

const CreateInvoice = ({
  history,
  users,
  getUsers,
  getRankPosition,
  rank,
  position,
  loading
}: TCreateInvoiceProps) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const [formValues, setFormValues] = useState({} as TInvoiceValues)
  const [isRememberComment, setIsRememberComment] = useState<boolean>(false)
  const [rememberedComments, setRememberedComment] = useState<string>('')
  const [daysToAmount, setDaysToAmount] = useState<number>(0)
  const [isActiveName, setIsActiveName] = useState<boolean>(true)
  const [isActiveLevel, setIsActiveLevel] = useState<boolean>(true)
  const [usersDate, setUsersDate] = useState([] as any)
  const parsed: any = queryString.parse(history.location.search)
  const rate: number = 10
  const projectId: number = +parsed.projectId
  const setValues = (values: TInvoiceValues) => {
    setTimeout(() => {
      setFormValues(values)
    }, 0)
  }

  React.useEffect(() => {
    const collectedValues: any = []
    users.forEach((user: IUser) => {
      const fromKey = `from${user.id}`
      const toKey = `to${user.id}`
      const fromValue = formValues[fromKey]
      const toValue = formValues[toKey]

      collectedValues.push(fromValue, toValue)

      setUsersDate(collectedValues)
    })
  }, [formValues])

  React.useEffect(() => {
    getUsers()
    getRankPosition()
  }, [])

  React.useEffect(() => {
    const invoiceComment = localStorage.getItem('invoiceComment')
    if (typeof invoiceComment === 'string') {
      const parsedComment = JSON.parse(invoiceComment)
      setRememberedComment(parsedComment.comment)
      setIsRememberComment(parsedComment.isRememberComment)
    }
  }, [])

  const currentDates = React.useMemo(() => {
    return (
      usersDate.reduce((acc: number, curr: number): number => {
        if (curr === undefined) {
          return acc
        }
        return new Date(curr).getTime() - new Date(acc).getTime()
      }, 0) / 86400000
    )
  }, [usersDate])

  React.useEffect(() => {
    setDaysToAmount(currentDates)
  }, [usersDate])

  const calculateAmount = () => {
    let finalResult = 0
    users.map((user: any) => {
      const userRate = parseInt(user.projectsTeam[0].rateByInvoice)
      if (daysToAmount % 1 !== 0 || daysToAmount * rate < 0) {
        return words['projects.invoices.incorrect']
      }

      if ((daysToAmount % 1 === 0 || daysToAmount * rate >= 0) && !formValues.discount) {
        finalResult = daysToAmount * userRate
      }

      if (
        (daysToAmount % 1 === 0 || daysToAmount * rate >= 0) &&
        formValues.discount &&
        formValues.discountUnit === 'USD'
      ) {
        finalResult = daysToAmount * userRate - +formValues.discountAmount
      } else if (formValues.discountUnit === '%') {
        finalResult = Math.ceil(
          daysToAmount * userRate - ((daysToAmount * rate) / 100) * +formValues.discountAmount
        )
      }
    })
    return finalResult
  }

  const bankDetailsData: IInvoiceSelect[] = [
    {
      value: '123456789987654321',
      label: '123456789987654321'
    }
  ]
  const unitData: IInvoiceSelect[] = [
    {
      value: 'USD',
      label: 'USD'
    },
    {
      value: '%',
      label: '%'
    }
  ]

  // const cancelHandler = () => {
  //   console.log('cancelled')
  // }

  const onHandleSubmit = async () => {
    if (isRememberComment) {
      localStorage.setItem(
        'invoiceComment',
        JSON.stringify({ comment: formValues.comment, isRememberComment })
      )
    } else {
      localStorage.removeItem('invoiceComment')
    }

    const result = await setProjectInvoice({
      ...formValues,
      isActiveName: isActiveName,
      isActiveLevel: isActiveLevel,
      totalAmount: calculateAmount() ? calculateAmount() : 0,
      project: projectId
    })

    result.success
      ? history.push('/dashboard/projects/invoices')
      : result.errorMessage && toastr('error', result.errorMessage)
  }

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>{words['projects.invoices.newInvoice']}</h1>
      </section>
      <section>
        <div className={styles['block-go-back']}>
          <button
            type="button"
            className={styles['go-back']}
            onClick={event => {
              event.preventDefault()
              history.goBack()
            }}
          >
            <Icons icon="backArrowIcon" />
            <span className={styles['go-back-text']}>{words['back']}</span>
          </button>
        </div>
      </section>
      <section className={styles['primary']}>
        <div className={styles['primary-wrapper']}>
          <Form
            initialValues={{
              number: '',
              start: null,
              end: null,
              bankDetails: '',
              formationDate: null,
              paymentDate: null,
              discount: '',
              discountAmount: '',
              discountUnit: '',
              comment: rememberedComments
            }}
            onSubmit={onHandleSubmit}
            render={({ form, handleSubmit }: any) => {
              const formState = form.getState()
              setValues(formState.values)
              return (
                <form onSubmit={handleSubmit} className={styles['primary-form']}>
                  <div className={styles['main-info']}>
                    <h2 className={styles['title']}>
                      {words['projects.invoices.mainInformation']}
                    </h2>
                    <InputInvoice
                      require={true}
                      form={form}
                      name="number"
                      label={words['projects.invoices.invoiceNumber']}
                      type="input"
                      placeholder="#VOICAP-1"
                    />
                    <div className={styles['two-columns']}>
                      <label className={styles['label-for-field']}>
                        {words['projects.invoices.duration']}
                      </label>
                      <div className={styles['two-columns-wrapper']}>
                        <DateInvoice name="start" form={form} formState={formState} /> –{' '}
                        <DateInvoice name="end" form={form} formState={formState} />
                      </div>
                    </div>
                    <SelectInvoice
                      name="bankDetails"
                      label={words['projects.invoices.bankDetails']}
                      data={bankDetailsData}
                      required={true}
                      form={form}
                    />
                    <div className={styles['two-columns-inner']}>
                      <div className={styles['left-column']}>
                        <label className={styles['label-for-field']}>
                          {words['projects.invoices.formationDate']}
                        </label>
                        <span className={styles['red-note']}>*</span>
                        <DateInvoice
                          name="formationDate"
                          form={form}
                          formState={formState}
                          validation={validation}
                        />
                      </div>
                      <div className={styles['right-column']}>
                        <label className={styles['label-for-field']}>
                          {words['projects.invoices.paymentDate']}
                        </label>
                        <span className={styles['red-note']}>*</span>
                        <DateInvoice
                          name="paymentDate"
                          form={form}
                          formState={formState}
                          validation={validation}
                        />
                      </div>
                    </div>
                    <div className={styles['divider']} />
                    <SelectInvoice
                      name="discount"
                      label={words['projects.invoices.discount']}
                      data={discountData}
                      form={form}
                      required={false}
                    />
                    <div className={styles['two-columns-inner']}>
                      <div className={styles['left-column']}>
                        <InputInvoice
                          className={styles['input-invoice']}
                          require={false}
                          form={form}
                          name="discountAmount"
                          label={words['projects.invoices.discountAmount']}
                          type="input"
                        />
                      </div>
                      <div className={styles['right-column']}>
                        <SelectInvoice
                          name="discountUnit"
                          label={words['projects.invoices.discountUnit']}
                          data={unitData}
                          form={form}
                          required={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles['table']}>
                    {loading ? (
                      <div className={styles['spinner']}>
                        <Spinner />
                      </div>
                    ) : (
                      <TableInvoice
                        rate={rate}
                        rank={rank}
                        position={position}
                        users={users}
                        form={form}
                        formState={formState}
                        isActiveName={isActiveName}
                        isActiveLevel={isActiveLevel}
                        setIsActiveName={setIsActiveName}
                        setIsActiveLevel={setIsActiveLevel}
                      />
                    )}
                  </div>
                  <div className={styles['comment']}>
                    <div className={styles['comment-wrapper']}>
                      <h2 className={styles['title']}>{words['projects.invoices.comment']}</h2>
                      <InputInvoice
                        require={false}
                        form={form}
                        name="comment"
                        label={words['projects.invoices.enterComment']}
                        type="textarea"
                      />
                      <Checkbox
                        name={'isRemember'}
                        label={words['projects.invoices.remember']}
                        onChange={() => setIsRememberComment(!isRememberComment)}
                        checked={isRememberComment}
                      />
                    </div>
                  </div>
                  <div className={styles['summary-wrapper']}>
                    <div className={styles['summary']}>
                      {words['projects.invoices.totalAmount']}:{' '}
                      <span>
                        {typeof calculateAmount() === 'number'
                          ? `${calculateAmount()} $`
                          : typeof calculateAmount() === 'string'
                          ? calculateAmount()
                          : '0 $'}
                      </span>
                    </div>
                    <div className={styles['buttons']}>
                      <Button
                        // onClick={cancelHandler}
                        disabled={false}
                        outline={true}
                        className={styles['button']}
                      >
                        {words['admin.productionCalendar.new.buttons.cancel']}
                      </Button>

                      <Button type="submit" className={styles['button']}>
                        {words['user.dayOffTracker.create']}
                      </Button>
                    </div>
                  </div>
                </form>
              )
            }}
          />
        </div>
      </section>
    </div>
  )
}
export default CreateInvoice
