import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import { getUsers, clearStore } from '../../Subcontract.thunk'
import { fetchAllTokensThunk } from 'screens/admin/Token/Token.thunx'
import { PerformersComponent } from './Performers'

export const mapStateToProps = (state: TState) => {
  return {
    usersArray: state.client.subcontract.users,
    loading: state.client.subcontract.loading,
    itemsCount: state.client.subcontract.itemsCount,
    tokens: state.admin.token.allTokens
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getUsers,
      clearStore,
      fetchAllTokensThunk
    },
    dispatch
  )

export const Performers = connect(
  mapStateToProps,
  mapDispatchToProps
)(PerformersComponent)
