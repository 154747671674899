import {
  COMPARE_POLICY_ERROR,
  GET_POLICY_REQUEST,
  GET_POLICY_ERROR,
  GET_POLICY_SUCCESS,
  POST_POLICY_SUCCESS
} from './Policy.constants'
import { IAction } from 'models'

export const initialState = {
  data: [],
  error: '',
  loading: false
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_POLICY_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_POLICY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }

    case POST_POLICY_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case COMPARE_POLICY_ERROR:
    case GET_POLICY_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    default:
      return state
  }
}
