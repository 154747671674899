import { IAction } from 'models/index'
import { ActionCreator } from 'redux'
import { action } from 'store/store.utils'
import { EWeb3Actions } from './web3.constans'
import { ITransactionReceipt } from './web3.model'

export const connectToMetaMask: ActionCreator<IAction> = (payload: string) =>
  action(EWeb3Actions.CONNECT_TO_META_MASK, payload)

export const getTrlTokenPrice: ActionCreator<IAction> = (payload: string) =>
  action(EWeb3Actions.GET_TRL_TOKEN_PRICE, payload)

export const checkMetaMaskConnection: ActionCreator<IAction> = (payload: string) =>
  action(EWeb3Actions.CHECK_META_MASK_CONNECTION, payload)

export const getReceipt: ActionCreator<IAction> = (payload: ITransactionReceipt) =>
  action(EWeb3Actions.GET_RECEIPT, payload)

export const isTaskInTransaction: ActionCreator<IAction> = payload =>
  action(EWeb3Actions.IS_TASK_IN_TRANSACTION, payload)
