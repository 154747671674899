import React, { FC, useEffect } from 'react'
import styles from './OfferTasks.module.scss'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { TaskInfo } from '../TaskInfo'
import {
  SubcontractTasksFilter,
  TSubcontractFilterValues
} from '../SubcontractTasksFilter/SubcontractTasksFilter'
import { ESubcontractTabs } from '../subcontract.enum'
import { Icons } from 'components/Icons'
import { TOfferTasksProps } from './OfferTasks.model'
import queryString from 'query-string'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ReactComponent as Prev } from 'assets/images/prev.svg'
import { ReactComponent as Next } from 'assets/images/next.svg'
import Pagination from 'react-js-pagination'
import Loading from '../../../components/Spinner'
import tasks_tablet from '../../../assets/images/tasks_tablet.svg'
import { finishPoint } from 'screens/client/Subcontract/components/CreateTask/initialvalues'

const OfferTasks: FC<TOfferTasksProps> = ({
  history,
  getTasks,
  taskArray,
  itemsCount,
  pageCount,
  page,
  loading,
  clearStore,
  tokens,
  fetchAllTokensThunk
}: any) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const parsed = queryString.parse(history.location.search)
  const urlSearchParamses = new URLSearchParams(window.location.search)
  const paramses = Object.fromEntries(urlSearchParamses.entries())
  const uses = (paramses.page === '1' ? !loading : true) && taskArray.length > 0
  useEffect(() => {
    if (pageCount !== (paramses.page ? +paramses.page : 0) && paramses.page !== page) {
      getTasks(10, parsed.page, '/tasks/own', finishPoint(), parsed.clear === 'true')
    }
    fetchAllTokensThunk()
  }, [])

  const fetchMore = () => {
    const url = new URL((window.location as unknown) as string)
    url.searchParams.set('page', String((paramses.page ? +paramses.page : 0) + 1))
    window.history.pushState({}, '', url.toString())

    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    getTasks(10, params.page, '/tasks/own', finishPoint(), parsed.clear === 'true')
  }

  const handlePageChange = (pagePag: number) => {
    const url = new URL((window.location as unknown) as string)
    url.searchParams.set('page', String(pagePag))
    window.history.pushState({}, '', url.toString())
    getTasks(10, pagePag, '/tasks/own', finishPoint(), true)
    window.scrollTo(0, 0)
  }

  return (
    <>
      <div className={styles.container} style={{ width: '100%' }}>
        <section className={styles.header}>
          <h1>{words['user.header.subcontract']}</h1>
        </section>
        <div className={styles['wrap-bar']}>
          <button
            className={styles['back-btn']}
            onClick={() =>
              //  history.goBack()}
              history.push('/dashboard/subcontract?page=1&task=performers')
            }
          >
            <Icons icon={'arrowLeftWithStick'} />
            {words['back']}
          </button>
        </div>
        <div className={styles['list-filter']}>
          {taskArray.length === 0 && uses ? (
            <div className={styles['empty-list']}>
              <img src={tasks_tablet} alt="logo" />
              <h2>{words['user.subcontract.taskListIsEmpty']}</h2>
            </div>
          ) : uses ? (
            <div className={styles['info-container']} style={{ width: '100%' }}>
              <InfiniteScroll
                dataLength={taskArray.length}
                next={fetchMore}
                hasMore={(paramses.page ? +paramses.page : 0) + 1 <= pageCount}
                loader={<h4>Loading...</h4>}
              >
                {taskArray.map((task: any) => (
                  <TaskInfo
                    key={task.id}
                    id={task.id}
                    status={'offer'}
                    path={`/dashboard/subcontract/${
                      task.id
                    }?my_task=true&response=all-responses&offer=true`}
                    task={task}
                  />
                ))}
              </InfiniteScroll>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '500px'
              }}
            >
              <Loading />
            </div>
          )}
          <div className={styles.filter}>
            <SubcontractTasksFilter
              tokens={tokens}
              activeTab={ESubcontractTabs.ALL_TASKS}
              onSubmit={function(values: TSubcontractFilterValues) {
                clearStore()
                history.push(
                  `${history.location.pathname}?${queryString.stringify({
                    ...parsed,
                    page: 1,
                    category:
                      values.category && values.category.value !== '@ALL@'
                        ? values.category.value
                        : '',
                    currency: values.currency.value !== '@ALL@' ? values.currency.value : '',
                    level: values.level.value !== '@ALL@' ? values.level.value : '',
                    specialization:
                      values.specialization.value !== '@ALL@' ? values.specialization.value : '',
                    fromPrice: values.budgetFrom,
                    toPrice: values.budgetTo,
                    fromDate: values.publicationDateFrom,
                    toDate: values.publicationDateTo,
                    status:
                      values.status && values.status.value !== '@ALL@' ? values.status.value : '',
                    technologies: values.technology,
                    isClear: true
                  })}`
                )
              }}
            />
            <div className={styles['pagination-container']}>
              <Pagination
                itemsCountPerPage={10}
                totalItemsCount={itemsCount}
                activePage={paramses.page ? +paramses.page : 0}
                onChange={handlePageChange}
                hideFirstLastPages={true}
                prevPageText={<Prev />}
                nextPageText={<Next />}
                linkClass={styles['pagination-item']}
                activeLinkClass={styles['pagination-active']}
                disabledClass={styles['pagination-disabled']}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OfferTasks
