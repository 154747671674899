import React from 'react'
import styles from './TypeCard.module.scss'
import teamExtension from '../../../assets/images/teamExtension.png'
import timeAndMaterials from '../../../assets/images/timeAndMaterials.png'
import dedicatedTeam from '../../../assets/images/dedicatedTeam.png'
import fixedPrice from '../../../assets/images/fixedPrice.png'
import cls from 'classnames'

export const TypeCard = ({ index, img, title, active, onCli }: any) => {
  return (
    <div
      className={cls({ [styles['card-container']]: true, [styles['active']]: active })}
      onClick={() => {
        onCli()
      }}
    >
      {active ? <div className={styles.choosen} /> : null}
      <div style={{ display: 'flex' }}>
        <div className={cls({ [styles['opacity-active']]: active })} />
        <img
          src={
            index === 0
              ? fixedPrice
              : index === 1
              ? timeAndMaterials
              : index === 2
              ? dedicatedTeam
              : teamExtension
          }
          alt={img.alt}
          className={styles['card-img']}
        />
        <div className={styles.text}>
          <h3>{title}</h3>
        </div>
      </div>
    </div>
  )
}
