import {
  GET_FOREIGN_PROJECTS_REQUEST,
  GET_FOREIGN_PROJECTS_SUCCESS,
  GET_FOREIGN_PROJECTS_ERROR,
  GET_FOREIGN_MANAGERS_REQUEST,
  GET_FOREIGN_MANAGERS_SUCCESS,
  GET_FOREIGN_MANAGERS_ERROR
} from './ForeignProfile.constants'
import { IInitialState, TManager } from './ForeignProfile.model'
import { IAction } from 'models'

export const initialState: IInitialState = {
  projects: [],
  managers: [],
  error: '',
  loading: false
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_FOREIGN_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_FOREIGN_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
        loading: false
      }
    case GET_FOREIGN_PROJECTS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    case GET_FOREIGN_MANAGERS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_FOREIGN_MANAGERS_SUCCESS:
      return {
        ...state,
        managers: action.payload.map((item: TManager) => {
          return { value: String(item.id), label: item.fullName }
        }),
        loading: false
      }
    case GET_FOREIGN_MANAGERS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }

    default:
      return state
  }
}
