import React, { useEffect, useRef, useState } from 'react'
import styles from '../Entrepreneurs.module.scss'
import { Filter } from '../../../../components/UiKit/Filter'
import { getFilterConfig } from '../Entrepreneurs.filter.config'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from '../../../../store'
import { Table as UIKitTable } from '../../../../components/UiKit/Table'
import { Columns } from '../Entrepreneurs.columns'
import { filterInvoices, setInvoice } from '../Entrepreneurs.actions'
import Offer from './Offer/Offer'

const FormulatedInvoices = ({ invoicesArray, parsed }: any) => {
  const [isOfferOpened, setOfferOpened] = useState(false)
  const downloadRef = useRef<HTMLInputElement>(null)
  const words = useSelector((state: TState) => state.global.language.words)
  const invoice = useSelector((state: TState) => state.admin.entrepreneurs.invoice)
  const columns = Columns({ role: 2, isOnlyManager: true, downloadRef })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(filterInvoices(parsed))
  }, [parsed])
  const handleRowClick = (offer: any) => {
    setOfferOpened(true)
    dispatch(setInvoice(offer))
  }
  // FILTRATION WILL BE ON BACKEND
  return (
    <section className={styles['entrepreneurInvoices__container']}>
      <Filter
        config={getFilterConfig({
          role: 4,
          fromDate: parsed.fromDate,
          toDate: parsed.toDate,
          parsed,
          words
        })}
      />
      {isOfferOpened && (
        <Offer
          toggle={isOfferOpened}
          ref={downloadRef}
          action={() => setOfferOpened(false)}
          invoice={invoice}
        />
      )}
      <div>
        <UIKitTable
          classNameToBody={'test'}
          rowSize={70}
          columns={columns}
          data={invoicesArray}
          getData={() => null}
          total={10}
          onRowClick={(d: any) => handleRowClick(d.original)}
        />
      </div>
    </section>
  )
}

export default FormulatedInvoices
