import { TWords } from 'elements/SideBar/SideBar.config'
import React from 'react'
import { Column } from 'react-table'

export type TExchangeData = {
  contracts: string
  quantity: string
  margin: string
  entryPrice: string
  markingPrice: string
  liquidationPrice: string
  unrealizedPL: string
}

export const Columns = (words: TWords) => {
  const columnsManager: Array<Column<TExchangeData>> = [
    {
      Header: words['crypto-bot.contracts'],
      id: 'contracts',
      accessor: item => {
        const symbol = item.contracts.split(' ')[0]
        const leverage = item.contracts.split(' ')[1]
        const side = item.markingPrice.split(' ')[1]

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                backgroundColor: side === 'long' ? '#149B58' : '#E12F75',
                width: '3px',
                height: '25px',
                marginRight: '5px',
                borderRadius: '30px'
              }}
            />

            <div style={{ color: side === 'long' ? '#149B58' : '#E12F75', fontWeight: 600 }}>
              {symbol}
              <br />
              {leverage}
            </div>
          </div>
        )
      },
      minWidth: 250
    },
    {
      Header: words['crypto-bot.quantity'],
      id: 'quantity',
      accessor: 'quantity',
      minWidth: 200
    },
    {
      Header: words['crypto-bot.margin'],
      id: 'margin',
      accessor: 'margin',
      minWidth: 200
    },
    {
      Header: words['crypto-bot.entryPrice'],
      id: 'entryPrice',
      accessor: 'entryPrice',
      minWidth: 250
    },
    {
      Header: words['crypto-bot.markingPrice'],
      id: 'markingPrice',
      accessor: item => {
        const markingPrice = item.markingPrice.split(' ')[0]
        const side = item.markingPrice.split(' ')[1]
        return (
          <div style={{ color: side === 'long' ? '#149B58' : '#E12F75', fontWeight: 600 }}>
            {markingPrice}
          </div>
        )
      },
      minWidth: 250
    },
    {
      Header: words['crypto-bot.liquidationPrice'],
      id: 'liquidationPrice',
      accessor: 'liquidationPrice',
      minWidth: 250
    },
    {
      Header: words['crypto-bot.unrealizedPL'],
      id: 'unrealizedPL',
      accessor: item => {
        const profit = item.unrealizedPL.split(' ')[0]
        const percentage = item.unrealizedPL.split(' ')[1]

        return (
          <div
            style={{
              color: +profit > 0 ? '#149B58' : '#E12F75'
            }}
          >
            {profit} USDT
            <br />
            {percentage} %
          </div>
        )
      },
      minWidth: 250
    }
  ]

  return columnsManager
}
