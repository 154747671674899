import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import PositionsInfoComponent from './PositionsInfo'
import { getPositionsInfoThunk } from '../ControlPanel/ControlPanel.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    user: state.auth.data,
    positionsInfo: state.client.controlPanel.positionsInfo,
    isLoading: state.client.controlPanel.isLoading
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getPositionsInfoThunk
    },
    dispatch
  )

export const PositionsInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(PositionsInfoComponent)
