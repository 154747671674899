import React, { FC, useEffect, useState, CSSProperties } from 'react'
import Modal from 'components/Modal'
import styles from './RequectDetailsModal.module.scss'
import { TRequestDetailsModal } from './RequectDetailsModal.model'
import { TRequestsDetailsItem } from '../../Requests.model'
import { RegectCommentModal } from '../RegectCommentModal'
// import { STATUS_OF_REQUESTS_TIME_OFF } from '../../../../../globalConfigs'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { Select } from 'Select'
import { TSelectOption } from 'components/Select'
import { REQUEST_STATUS_COLORS, STATUS_OF_REQUESTS_SELECT } from '../../../../../globalConfigs'

export const RequestDetailsModal: FC<TRequestDetailsModal> = ({
  data,
  userName,
  isShow,
  onClose,
  handlerReject,
  handlerApprove
}) => {
  const [openModalRegectComments, setOpenModalRegectComments] = useState(false)
  const toggleModalRegectComments = () => setOpenModalRegectComments(!openModalRegectComments)
  const [currentItemId, setCurrentItemId] = useState<any>()
  const words = useSelector((state: TState) => state.global.language.words)
  const [selectValueState, setSelectValueState] = useState<any>([])
  const [selectColorState, setSelectColorState] = useState<any>([])

  const rejectItem = (comment: string) => {
    handlerReject(currentItemId, comment)
    toggleModalRegectComments()
  }

  useEffect(() => {
    const curentState = data.map(item => {
      return STATUS_OF_REQUESTS_SELECT(words).find((element: TSelectOption) => {
        return Number(element.value) === Number(item.status)
      })
    })
    const curentColorState = data.map(item => {
      return REQUEST_STATUS_COLORS[item.status]
    })
    setSelectValueState(curentState)
    setSelectColorState(curentColorState)
  }, [])

  const renderRequestItem = (item: TRequestsDetailsItem, index: number) => {
    let borderColor = ''
    switch (selectColorState[index]) {
      case 'green':
        borderColor = '#149B58'
        break
      case 'red':
        borderColor = '#E12F75'
        break
      case 'orange':
        borderColor = '#FF5F1B'
        break
    }
    const customStyles: any = {
      container: (style: CSSProperties) => ({
        ...style,
        width: 120,
        height: 25
      }),
      control: () => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '20px',
        border: `1px solid ${borderColor}`,
        fontSize: '8px',
        width: '120px',
        minHeight: '20px',
        height: '25px'
      }),
      input: (style: CSSProperties) => ({
        ...style,
        width: 110,
        height: 25
      }),
      indicatorsContainer: (style: CSSProperties) => ({
        ...style,
        height: 16,
        alignSelf: 'center',
        color: borderColor
      }),
      singleValue: (style: CSSProperties) => ({
        ...style,
        width: 110,
        height: 16,
        color: borderColor,
        alignSelf: 'center'
      }),
      option: (style: any) => {
        return {
          ...style,
          fontSize: 12,
          color: borderColor,
          textAlign: 'center',
          lineHeight: '4px',
          ':active': {
            ...style[':active'],
            backgroundColor: 'white'
          }
        }
      },
      menu: (style: CSSProperties) => ({
        ...style,
        borderRadius: 20,
        border: `1px solid ${borderColor}`
      }),
      menuList: (style: CSSProperties) => ({
        ...style,
        borderRadius: 20
      }),
      dropdownIndicator: (style: CSSProperties) => ({ ...style, color: borderColor })
    }

    const handlerChange = async (value: any) => {
      const changeCurrentValueState = selectValueState
      changeCurrentValueState[index] = value
      const changeSelectColorState = selectColorState
      changeSelectColorState[index] =
        REQUEST_STATUS_COLORS[value.value] === 'orange'
          ? changeSelectColorState[index]
          : REQUEST_STATUS_COLORS[value.value]

      setSelectValueState(changeCurrentValueState)
      setSelectColorState(changeSelectColorState)

      switch (value.value) {
        case '1':
          break
        case '2':
          await handlerApprove(item.id)
          break
        case '3':
          toggleModalRegectComments()
          await setCurrentItemId(item.id)
          break
      }
    }

    return (
      <div className={styles['item-container']}>
        <div className={styles['item-row']}>
          <div>
            <div className={styles['item-name']}>{item.goodsId && item.goodsId.name}</div>
          </div>
          <div className={styles['status-container']}>
            <Select
              name="selectStatus"
              styles={customStyles}
              options={STATUS_OF_REQUESTS_SELECT(words)}
              value={selectValueState[index]}
              onChange={handlerChange}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: 'hsl(0, 0%,95%)',
                  primary: '#DEEBFF'
                }
              })}
            />
          </div>
        </div>
        <div className={styles['item-row']}>
          <div>
            <span className={styles['item-title']}>
              {words['admin.requestDetailsModal.amount']}:
            </span>
            {item && item.amount}
          </div>
          <div>
            <span className={styles['item-title']}>
              {words['admin.requestDetailsModal.people']}:
            </span>
            {item.goodsId && item.goodsId.numberOfPeople}
          </div>
          <div>
            <span className={styles['item-title']}>
              {words['admin.requestDetailsModal.price']}:
            </span>
            {item && item.price}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Modal isShow={isShow} onClose={onClose} className={styles.modal}>
        <header>
          {words['admin.requestDetailsModal.requestFrom']} {userName}
        </header>
        <div className={styles['requests-container']}>
          {data && data.map((el, i) => renderRequestItem(el, i))}
        </div>
      </Modal>

      {openModalRegectComments && (
        <RegectCommentModal
          isShow={openModalRegectComments}
          onClose={toggleModalRegectComments}
          onSubmit={rejectItem}
        />
      )}
    </>
  )
}
