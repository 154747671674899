import { action } from 'store/store.utils'
import { ESettingsActions } from './ControlPanel.constants'
import {
  ExchangeInfo,
  IGraphStatistic,
  IStandardSettings,
  TBotInfoResponse,
  TExchangeType,
  TPositionsInfo
} from './ControlPanel.types'

export const fetchBotSettings = () => action(ESettingsActions.REQUEST_STARTED)

export const getGraphStatisticAction = (payload: IGraphStatistic[]) =>
  action(ESettingsActions.GET_GRAPH_STATISTIC, payload)

export const getBotSettingsAction = (payload: IStandardSettings) =>
  action(ESettingsActions.GET_BOT_SETTINGS, payload)

export const getExchangeInfoAction = (payload: ExchangeInfo[]) =>
  action(ESettingsActions.GET_EXCHANGE_INFO, payload)

export const getPositionsInfoAction = (payload: TPositionsInfo) =>
  action(ESettingsActions.GET_POSITIONS_INFO, payload)

export const getBotInfoAction = (payload: TBotInfoResponse) =>
  action(ESettingsActions.GET_BOT_INFO, payload)
export const getBotExchangeTypeAction = (payload: TExchangeType) =>
  action(ESettingsActions.GET_BOT_EXCHANGE_TYPE, payload)

export const getInstanceAction = (payload: any) => action(ESettingsActions.GET_INSTANCE, payload)
