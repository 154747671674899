import React, { FC, useEffect } from 'react'
import styles from './VotingList.module.scss'
import { VotingListItem } from './components/VotingListItem'
import { TVotingListProps } from './VotingList.model'
import tasks_tablet from '../../../assets/images/tasks_tablet.svg'
import { useSelector } from 'react-redux'
import { TState } from 'store'

const VotingList: FC<TVotingListProps> = ({ history, getAllVoting, votingList, loading }) => {
  const words = useSelector((state: TState) => state.global.language.words)
  useEffect(() => {
    getAllVoting()
  }, [])

  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <h1>{words['user.voting.voting']}</h1>
        {votingList.length === 0 ? (
          !loading && (
            <div className={styles['empty-list']}>
              <img src={tasks_tablet} />
              <div className={styles['text']}>{words['user.voting.emptyList']}</div>
            </div>
          )
        ) : (
          <div className={styles['content']}>
            <div className={styles['list']}>
              {votingList.map(element => (
                <VotingListItem history={history} key={element.id} votingElement={element} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export { VotingList }
