import React, { FC, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import Sidebar from '../../../SideBar'
import { TDashboard } from './Dashboard.model'
import styles from './Dashboard.module.scss'
import { NotFound } from '../../../../components/NotFound'
import cls from 'classnames'
import storage from '../../../../utils/storage'
import { Header } from 'elements/Header'
import { adminRoutes, clientRoutes } from 'elements/App/App.routes'
import { isAdmin, isSuperAdmin } from 'utils/user'

const sideBarKey = 'side_bar'

export const Dashboard: FC<TDashboard> = ({ successPath, role, languageObject }) => {
  const [routes] = useState(isSuperAdmin(role) || isAdmin(role) ? adminRoutes : clientRoutes)
  const [collapsed, setCollapsed] = useState(!!Number(storage.get(sideBarKey)))

  return (
    <div className={cls({ [styles.container]: true, [styles.collapsed]: collapsed })}>
      <Sidebar
        successPath={successPath}
        languageObject={languageObject}
        onCollapsed={setCollapsed}
      />
      <main className={styles.main}>
        {<Header isSideBarCollapsed={collapsed} />}
        <Switch>
          {routes.map((route: any, index: number) => (
            <Route key={index} exact={route.exact} path={route.path} component={route.component} />
          ))}

          {routes.length > 0 && <Route component={NotFound} />}
        </Switch>
      </main>
    </div>
  )
}
