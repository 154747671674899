import React, { FC, useEffect } from 'react'
import styles from './Idenfy.module.scss'
import queryString from 'query-string'
import Spinner from 'components/Spinner'
import QRCode from 'react-qr-code'
import { TIdenfyProps } from './Idenfy.model'
import { BaseButton } from 'components/UiKit/Button/BaseButton'

const Idenfy: FC<TIdenfyProps> = ({
  user,
  getUserProfileInfo,
  id,
  userLoading,
  profileLoading,
  getQrCodeSignIn,
  qrCode,
  signupQr,
  getQrCodeSignUp,
  history,
  createVerificationSession,
  userIdenfy,
  claimsList
}) => {
  const parsed = queryString.parse(history.location.search)
  const isIdenfy = user.idenfyResult

  useEffect(() => {
    getUserProfileInfo(id, 'main-info', false)
  }, [])

  useEffect(() => {
    if (user.verificationIdenfy && user.verificationIdenfy === 'inprogress')
      createVerificationSession()
    if (
      user.verificationIdenfy &&
      user.verificationIdenfy === 'false' &&
      parsed.type === 'idenfy' &&
      !signupQr.sessionId &&
      !profileLoading
    ) {
      console.log('signup')
      getQrCodeSignUp()
    } else if (
      (user.verificationIdenfy === 'claims' || user.verificationIdenfy === 'inprogress') &&
      (isIdenfy && user.idenfyResult.final) &&
      parsed.type === 'idenfy' &&
      !qrCode.signinQr &&
      !profileLoading
    ) {
      console.log('signIN')
      getQrCodeSignIn()
    }
  }, [profileLoading])

  return (
    <div className={styles.verification}>
      <>
        <h1>Verification</h1>
        <p>Please verify your identity</p>
        {userLoading || profileLoading ? (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        ) : user.verificationIdenfy && user.verificationIdenfy === 'false' ? (
          <div>
            {signupQr ? (
              <QRCode level="Q" style={{ width: 256 }} value={JSON.stringify(signupQr)} />
            ) : null}
          </div>
        ) : user.verificationIdenfy &&
          user.verificationIdenfy === 'inprogress' &&
          (!isIdenfy || (isIdenfy && !user.idenfyResult.final)) ? (
          <>
            {userIdenfy ? (
              <BaseButton
                // className={styles.btn}
                children={'Start Verification'}
                // disabled={userLoading}
                size="lg"
                type="button"
                onClick={() => {
                  window.location.replace(
                    `https://ivs.idenfy.com/api/v2/redirect?authToken=${
                      userIdenfy.authToken
                    }&successUrl=${window.location.href}&errorUrl=${
                      window.location.href
                    }&unverifiedUrl=${
                      window.location.href
                    }&callbackUrl=https://green-points-system.technorely.com/backend/api/idenfy/user`
                  )
                }}
              />
            ) : null}
            {isIdenfy && !user.idenfyResult.final ? (
              <p className={styles.denied}>Denied</p>
            ) : (
              <p>If you have passed verification, please wait for a response.</p>
            )}
          </>
        ) : user.verificationIdenfy &&
          user.verificationIdenfy === 'claims' &&
          isIdenfy &&
          user.idenfyResult.final ? (
          <div>
            <p>get claims first</p>
            {claimsList
              ? claimsList.map((claim: any, i: number) => (
                  <button
                    key={i}
                    onClick={() =>
                      history.push(
                        `${history.location.pathname}/claims?offer=${claim.id.slice(-36)}`
                      )
                    }
                  >
                    Get Age Credential
                  </button>
                ))
              : null}
            <p>then verifie</p>
            {qrCode.signinQr ? (
              <QRCode level="Q" style={{ width: 256 }} value={JSON.stringify(qrCode.signinQr)} />
            ) : null}
          </div>
        ) : user.verificationIdenfy &&
          isIdenfy &&
          user.idenfyResult.final &&
          user.verificationIdenfy === 'true' ? (
          <div>
            <p>Verified Successfully</p>
          </div>
        ) : (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        )}
      </>
    </div>
  )
}

export default Idenfy
