import { EProject } from './Projects.constants'
import { IInitialState, TManager } from './Projects.model'
import { IAction } from 'models'
import {
  GET_PROJECT_INVOICES_REQUEST,
  GET_PROJECT_INVOICES_SUCCESS,
  GET_ONE_PROJECT_INVOICE_SUCCESS,
  SELECT_PROJECT_INVOICE,
  DELETE_SELECTED_PROJECT_INVOICE,
  DELETE_PROJECT_INVOICE,
  SELECT_ALL_PROJECT_INVOICES
} from '../../../components/Project/Invoices/Invoices.constants'

export const initialState: IInitialState = {
  data: [],
  dataProjects: [],
  project: null,
  projectHistory: [],
  managers: [],
  error: '',
  projectInvoices: [],
  invoice: null,
  loading: false,
  selectedProjectInvoices: []
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case EProject.GET_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case EProject.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        dataProjects: [...state.dataProjects, ...action.payload.payload.results],
        total: action.payload.payload.total,
        loading: false
      }
    case EProject.GET_PROJECTS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    case EProject.GET_ONE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        project: action.payload
      }
    case EProject.CHANGE_PROJECT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case EProject.CHANGE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case EProject.CHANGE_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case EProject.CLEAR_ONE_PROJECT_SUCCESS: {
      return {
        ...state,
        loading: false,
        project: null
      }
    }
    case EProject.GET_PROJECT_HISTORY_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case EProject.GET_PROJECT_HISTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        projectHistory: action.payload
      }
    }

    case EProject.GET_MANAGERS_SUCCESS:
      return {
        ...state,
        managers: action.payload.map((item: TManager) => {
          return { value: String(item.id), label: item.fullName }
        }),
        loading: false
      }
    case EProject.GET_MANAGERS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }

    case GET_PROJECT_INVOICES_REQUEST:
      return {
        ...state
      }
    case GET_PROJECT_INVOICES_SUCCESS:
      return {
        ...state,
        projectInvoices: action.payload,
        loading: false
      }
    case GET_ONE_PROJECT_INVOICE_SUCCESS:
      return {
        ...state,
        invoice: action.payload
      }
    case SELECT_PROJECT_INVOICE:
      return {
        ...state,
        selectedProjectInvoices: [...state.selectedProjectInvoices, action.payload]
      }
    case SELECT_ALL_PROJECT_INVOICES:
      return {
        ...state,
        selectedProjectInvoices: action.payload
      }
    case DELETE_SELECTED_PROJECT_INVOICE: {
      return {
        ...state,
        selectedProjectInvoices: state.selectedProjectInvoices.filter(
          (invoice: any) => invoice.id !== action.payload.id
        )
      }
    }
    case DELETE_PROJECT_INVOICE: {
      return {
        ...state,
        projectInvoices: state.projectInvoices.filter(
          (invoice: any) => invoice.id !== action.payload
        )
      }
    }
    default:
      return state
  }
}
