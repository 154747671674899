import React, { FC, useEffect, useState } from 'react'
import styles from './SettingsPointsSystem.module.scss'
import numeral from 'numeral'
import { mapStateToProps, mapDispatchToProps } from './SettingsPointsSystem.container'
import validation from '../../../../../utils/validation'
import { Field, Form } from 'react-final-form'
import { EPointsSettings } from '../../../../../globalConfigs'
import { Input } from 'components/UiKit/Inputs'
import { Slider as UIKitSlider } from 'components/UiKit/Slider/Slider'
import { Icons } from '../../../../../components/Icons'
import { Button } from 'components/UiKit/Button'
import { TUpdatePointsSettingsBody } from './SettingsPointsSystem.thunx'
import { SettingsModalPointsEditForm } from './components/SettingsModalPoinsEditForm'
import { useSelector } from 'react-redux'
import { TState } from 'store'

type TSettingsPointsSystemProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

export const SettingsPointsSystem: FC<TSettingsPointsSystemProps> = ({
  settingsPointsSystem,
  getPointsSettings,
  updatePointsSettings,
  usersList,
  getUsersList,
  updateSettingsPointsBalance
}) => {
  useEffect(() => {
    getPointsSettings()
  }, [])

  const words = useSelector((state: TState) => state.global.language.words)
  const [openPointsEditModal, setOpenPointsEditModal] = useState<boolean>(false)
  const togglePointsEditModal = () => setOpenPointsEditModal(!openPointsEditModal)

  const [currentAchievementsAmountValue, setCurrentAchievementsAmountValue] = useState<number>(
    Number(settingsPointsSystem.pointsFund)
  )
  const handlecurrentAchievementsAmountChange = (_event: Event, newValue: number | number[]) => {
    setCurrentAchievementsAmountValue(newValue as number)
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles['row-btn']}>
          <Button
            className={styles['btn-wrapper']}
            outline={true}
            onClick={() => togglePointsEditModal()}
          >
            {words['admin.PointsSystem.buttons.balanceEdit']}
          </Button>
          {/*TODO -- will add when backend do functional*/}
          {/*<Button className={styles['btn-wrapper']} disabled={true}>*/}
          {/*Создать уведомление*/}
          {/*</Button>*/}
        </div>
        <Form
          onSubmit={(values: { [key: string]: any }) => {
            const body: TUpdatePointsSettingsBody = {
              pointsFundPerMonth: Number(values.pointsFundPerMonth),
              achievementsAmount: Number(values.achievementsAmount),
              feeCharger: numeral(values.feeCharger)
                .add(100)
                .divide(100)
                .value()
            }
            updatePointsSettings(body)
          }}
          initialValues={{
            pointsFundPerMonth: settingsPointsSystem.pointsFundPerMonth,
            achievementsAmount: settingsPointsSystem.achievementsAmount,
            feeCharger: numeral(settingsPointsSystem.feeCharger)
              .multiply(100)
              .subtract(100)
              .value()
          }}
        >
          {({ form, handleSubmit }) => {
            const formState = form.getState()
            return (
              <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles['form-row']}>
                  <div className={styles['pointsFund-container']}>
                    <div>
                      <div className={styles['input-label']}>
                        {words['admin.settings.pointsSystem.PointsFund']}
                      </div>

                      <div className={styles['achievements-amount']}>
                        <div className={styles['input-label']}>{`${
                          words['admin.settings.pointsSystem.teamBalance.requiredAmount']
                        }:`}</div>
                        <Field
                          name="pointsFundPerMonth"
                          validate={validation.composeValidators(validation.isNumber())}
                        >
                          {({ input, meta }) => {
                            return (
                              <div className={styles['div-wrapper']}>
                                <button
                                  onClick={() => {
                                    if (
                                      !formState.values.pointsFundPerMonth ||
                                      typeof formState.values.pointsFundPerMonth !== 'number'
                                    ) {
                                      form.change(
                                        'pointsFundPerMonth',
                                        -EPointsSettings.STEP_POINTS_FUND
                                      )
                                    } else {
                                      form.change(
                                        'pointsFundPerMonth',
                                        Number(formState.values.pointsFundPerMonth) -
                                          EPointsSettings.STEP_POINTS_FUND
                                      )
                                    }
                                  }}
                                  type="button"
                                >
                                  <Icons icon="remove" />
                                </button>
                                <div className={styles['input-wrapper']}>
                                  <Input
                                    variant="outlined"
                                    type="number"
                                    isInvalid={meta.error && meta.submitFailed}
                                    errorMessage={meta.error}
                                    {...input}
                                  />
                                </div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    if (
                                      !formState.values.pointsFundPerMonth ||
                                      typeof formState.values.pointsFundPerMonth !== 'number'
                                    ) {
                                      form.change(
                                        'pointsFundPerMonth',
                                        EPointsSettings.STEP_POINTS_FUND
                                      )
                                    } else {
                                      form.change(
                                        'pointsFundPerMonth',
                                        Number(formState.values.pointsFundPerMonth) +
                                          EPointsSettings.STEP_POINTS_FUND
                                      )
                                    }
                                  }}
                                >
                                  <Icons icon="add" />
                                </button>
                              </div>
                            )
                          }}
                        </Field>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className={styles['input-label']}>
                      {words['admin.settings.pointsSystem.priceEdit']}
                    </div>
                    <Field
                      name="feeCharger"
                      validate={validation.composeValidators(validation.isNumber())}
                    >
                      {({ input, meta }) => {
                        return (
                          <div className={styles['div-feecharger-wrapper']}>
                            <button
                              onClick={() => {
                                if (
                                  !formState.values.feeCharger ||
                                  typeof formState.values.feeCharger !== 'number'
                                ) {
                                  form.change('feeCharger', -EPointsSettings.STEP_FEE_CHARGER)
                                } else {
                                  form.change(
                                    'feeCharger',
                                    Number(formState.values.feeCharger) -
                                      EPointsSettings.STEP_FEE_CHARGER
                                  )
                                }
                              }}
                              type="button"
                            >
                              <Icons icon="remove" />
                            </button>
                            <div className={styles['feecharger']}>
                              {formState.values.feeCharger} %
                            </div>
                            <div className={styles['input-feecharger-wrapper']}>
                              <Input
                                variant="outlined"
                                type="number"
                                isInvalid={meta.error && meta.submitFailed}
                                errorMessage={meta.error}
                                {...input}
                              />
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                if (
                                  !formState.values.feeCharger ||
                                  typeof formState.values.feeCharger !== 'number'
                                ) {
                                  form.change('feeCharger', EPointsSettings.STEP_FEE_CHARGER)
                                } else {
                                  form.change(
                                    'feeCharger',
                                    Number(formState.values.feeCharger) +
                                      EPointsSettings.STEP_FEE_CHARGER
                                  )
                                }
                              }}
                            >
                              <Icons icon="add" />
                            </button>
                          </div>
                        )
                      }}
                    </Field>
                  </div>

                  <div className={styles['achievements-container']}>
                    <div className={styles['input-label']}>
                      {words['admin.settings.pointsSystem.teamBalance']}
                    </div>
                    <UIKitSlider
                      sliderVariant="gradient"
                      value={currentAchievementsAmountValue}
                      onChange={handlecurrentAchievementsAmountChange}
                      min={0}
                      max={Number(settingsPointsSystem.achievementsAmount)}
                      isValuesShows
                    />

                    <div className={styles['achievements-amount']}>
                      <div className={styles['input-label']}>{`${
                        words['admin.settings.pointsSystem.teamBalance.requiredAmount']
                      }:`}</div>
                      <Field
                        name="achievementsAmount"
                        validate={validation.composeValidators(validation.isNumber())}
                      >
                        {({ input, meta }) => {
                          return (
                            <div className={styles['div-wrapper']}>
                              <button
                                onClick={() => {
                                  if (
                                    !formState.values.achievementsAmount ||
                                    typeof formState.values.achievementsAmount !== 'number'
                                  ) {
                                    form.change(
                                      'achievementsAmount',
                                      -EPointsSettings.STEP_ACHIEVEMENTS
                                    )
                                  } else {
                                    form.change(
                                      'achievementsAmount',
                                      Number(formState.values.achievementsAmount) -
                                        EPointsSettings.STEP_ACHIEVEMENTS
                                    )
                                  }
                                }}
                                type="button"
                              >
                                <Icons icon="remove" />
                              </button>
                              <div className={styles['input-wrapper']}>
                                <Input
                                  variant="outlined"
                                  type="number"
                                  isInvalid={meta.error && meta.submitFailed}
                                  errorMessage={meta.error}
                                  {...input}
                                />
                              </div>
                              <button
                                type="button"
                                onClick={() => {
                                  if (
                                    !formState.values.achievementsAmount ||
                                    typeof formState.values.achievementsAmount !== 'number'
                                  ) {
                                    form.change(
                                      'achievementsAmount',
                                      EPointsSettings.STEP_ACHIEVEMENTS
                                    )
                                  } else {
                                    form.change(
                                      'achievementsAmount',
                                      Number(formState.values.achievementsAmount) +
                                        EPointsSettings.STEP_ACHIEVEMENTS
                                    )
                                  }
                                }}
                              >
                                <Icons icon="add" />
                              </button>
                            </div>
                          )
                        }}
                      </Field>
                    </div>
                  </div>
                </div>

                <div className={styles['form-row-btn']}>
                  <Button type="submit" className={styles['div-btn-wrapper']} variant="secondary">
                    {words['admin.productionCalendar.new.buttons.save']}
                  </Button>
                </div>
              </form>
            )
          }}
        </Form>
      </div>

      <SettingsModalPointsEditForm
        open={openPointsEditModal}
        togglePointsEdit={togglePointsEditModal}
        dataUsers={usersList}
        getUsers={getUsersList}
        updateSettingsPointsBalance={updateSettingsPointsBalance}
      />
    </>
  )
}
