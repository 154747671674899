import React, { FC, useEffect, useState } from 'react'
import { withRouter, Route } from 'react-router-dom'
import history from 'utils/history'
import { TAuthRoute } from './AuthRoute.model'
import { useAuth } from 'hooks/auth.hook'
import { isEmpty } from 'lodash'
import toastr from 'utils/toastr'
import { isAdmin, isSuperAdmin } from 'utils/user'
import { ADMIN_PATH, USER_PATH } from 'elements/App/App.constants'
import { AuthModal } from './components/AuthModal/AuthModal'
import { AuthException } from './AuhtRoute.constants'

const AuthRoute: FC<TAuthRoute> = ({
  user,
  role,
  component: Component,
  exact,
  path,
  login,
  signup,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { isAuthenticated, isLoading, getAccessTokenSilently, logoutWithRedirect } = useAuth()

  const handleAuth = async () => {
    if (isAuthenticated && !isLoading) {
      if (!isEmpty(user)) {
        const successPath = isSuperAdmin(role) || isAdmin(role) ? ADMIN_PATH : USER_PATH
        history.push(successPath)
        return
      }

      try {
        const token = await getAccessTokenSilently()
        if (!token) return

        await login(role)
      } catch (err) {
        const errorResponse = (err as any).response

        if (!errorResponse) {
          console.log(err)
          return
        }

        if (errorResponse.data.message === AuthException.EMAIL_REQUIRE) {
          setIsOpen(true)
        }

        if (errorResponse.data.message === AuthException.FORBIDDEN) {
          toastr('error', 'You have no admin access')
          await logoutWithRedirect()
        }
      }
    }
  }

  useEffect(() => {
    handleAuth()
  }, [isAuthenticated, isLoading, user])

  return (
    <Route
      {...rest}
      exact={exact}
      path={path}
      component={(props: any) => (
        <>
          <Component {...props} />
          <AuthModal isOpen={isOpen} signup={signup} onClose={() => setIsOpen(false)} />
        </>
      )}
    />
  )
}

export default withRouter(AuthRoute)
