import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { ReactComponent as DefaultAvatar } from 'assets/images/default_avatar.svg'
import styles from '../../Task.module.scss'
import { Icons } from 'components/Icons'

export const Maininfo: any = ({ taskInfo, parsed, userPhoto }: any) => {
  const words = useSelector((state: TState) => state.global.language.words)

  return (
    <>
      {taskInfo.type !== '4' ? (
        <section className={styles['personal-info']}>
          <h1 className={styles['title-name']}>{taskInfo.title}</h1>
          <div className={styles['info-task']}>
            {parsed['my_task'] ? null : (
              <div>
                <p className={styles['title-user']}>{words['user.subcontract.task.customer']}:</p>
                <div>
                  {userPhoto ? (
                    <img className={styles.avatar} src={userPhoto} alt="avatar" />
                  ) : (
                    <DefaultAvatar className={styles.avatar} />
                  )}
                </div>
                <div className={styles['user-info']}>
                  <h2>{taskInfo.customer ? taskInfo.customer.fullName : taskInfo.customer}</h2>
                  <p>
                    {words['user.subcontract.reviews']}:{' '}
                    <span style={{ color: '#E12F75' }}>-0</span>
                    <span style={{ color: '#149B58' }}> +5</span>
                  </p>
                </div>
              </div>
            )}
            <div>
              <p className={styles['title-user']}>{words['user.subcontract.task.budget']}:</p>
              <h2 style={{ color: '#149B58' }}>
                {taskInfo.price} {taskInfo.token && taskInfo.token.symbol}
                {taskInfo.type === '4' || taskInfo.type === '4'
                  ? `/ ${words['user.subcontract.perMonth']}`
                  : ''}
                {taskInfo.type === '2' ? `/ ${words['user.subcontract.perHour']}` : ''}
                {taskInfo.workingHours
                  ? ' | ' + taskInfo.workingHours + ` ${words['user.subcontract.task.hours']}`
                  : ''}
              </h2>
            </div>
            <div>
              {taskInfo.fixedDeadline ? (
                <>
                  <p className={styles['title-user']}>{words['user.subcontract.task.term']}:</p>
                  <h2>
                    {words['user.subcontract.until'] +
                      ' ' +
                      moment(taskInfo.deadline).format(`DD.MM.YYYY`)}
                  </h2>
                </>
              ) : null}
            </div>
          </div>
        </section>
      ) : (
        <div className={styles['task-main']}>
          {taskInfo.avatar ? (
            <img src={taskInfo.avatar} className={styles['task-avatar']} />
          ) : (
            <Icons icon="projectPlug" className={styles['task-avatar']} />
          )}
          <div>
            <h1>{taskInfo.title}</h1>
            <p className={styles['about-task']}>
              <span>{words['user.subcontract.task.description']}:</span>
              <br />
              {taskInfo.description}
            </p>
          </div>
        </div>
      )}
    </>
  )
}
