/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import { Dispatch } from 'redux'
import { API, api } from '../../../../../services/api'
import { getEventsRequest, getEventsSuccess } from './PlaningCalendar.actions'
// import toastr from '../../../../../utils/toastr'
// import { TState } from 'store'
import { AxiosError } from 'axios'
import { TEvent } from './PlaningCalendar.model'
import { IGoogleEvent } from 'screens/client/InterviewInvitation/InterviewInvitation.model'
import {
  addGoogleEventsSearchParams,
  parseGoogleEvents,
  getOnlyUniqueEvents
} from './helpers/helpers'

export function setEvent(body: TEvent) {
  return async function(): Promise<void> {
    try {
      await api.post(`${API.URL}/planning`, body)
    } catch (error) {
      const err = error as AxiosError<any>
      console.log(err)
    }
  }
}

export function changeEvent(body: TEvent) {
  return async function(): Promise<any> {
    try {
      const allTasks = await api.get(`${API.URL}/planning`)
      const currentTasks = allTasks.data.payload.result
      const found = currentTasks.filter((item: any) => item.eventId === body.eventId)

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { sourceResource, ...filteredBody } = body
      const resp = api.put(`${API.URL}/planning/${found[0].id}`, filteredBody)

      return resp
    } catch (error) {
      const err = error as AxiosError<any>
      console.log(err)
    }
  }
}

export function getEvents(id: string | null | (string | null)[]) {
  return async function(dispatch: Dispatch) {
    try {
      dispatch(getEventsRequest())
      const resp = await api.get(`${API.URL}/planning`)
      let events = resp.data.payload.result

      const task = await api.get(`${API.URL}/tasks/${id}`)

      const customer = task.data.payload.result.customer
      const executor = task.data.payload.result.executor

      if (customer !== null) {
        const customerGoogleEventsURL = addGoogleEventsSearchParams(
          `${API.URL}${API.GOOGLE_CALENDAR_REQUEST_OWN_EVENTS}`
        )
        const customerGoogleEvents = await api.get(customerGoogleEventsURL)
        const parsedCustomerGoogleEvents = parseGoogleEvents(customerGoogleEvents.data, {
          taskId: Number(id),
          pretenderId: customer.id
        })

        events = [...events, ...parsedCustomerGoogleEvents]
      }

      if (executor !== null) {
        const getExecutorEventsURL = addGoogleEventsSearchParams(
          `${API.URL}${API.GOOGLE_CALENDAR_FOREIGN_EVENTS_REQUEST}`,
          executor.email
        )
        const executorGoogleEvents = await api.get(getExecutorEventsURL)
        const parsedExecutorGoogleEvents = parseGoogleEvents(executorGoogleEvents.data, {
          taskId: Number(id),
          pretenderId: executor.id
        })

        events = [...events, ...parsedExecutorGoogleEvents]
      }

      const currentArray: TEvent[] = getOnlyUniqueEvents(events).map((item: TEvent) => {
        item.start = new Date(item.start)
        item.end = new Date(item.end)

        return item
      })

      dispatch(
        getEventsSuccess({ eventsArray: currentArray, currentTask: task.data.payload.result })
      )
      return resp.data.payload.result
    } catch (error) {
      const err = error as AxiosError<any>
      console.log(err)
    }

    return
  }
}

export function deleteEvent(body: TEvent) {
  return async function(): Promise<any> {
    try {
      const allTasks = await api.get(`${API.URL}/planning`)
      const currentTasks = allTasks.data.payload.result
      const found = currentTasks.filter((item: TEvent) => item.eventId === body.eventId)
      const resp = api.delete(`${API.URL}/planning/${found[0].id}`)

      return resp
    } catch (error) {
      const err = error as AxiosError<any>
      console.log(err)
    }
  }
}

export const addEventToGoogleCalendar = (
  body: IGoogleEvent,
  executorEmail: string
) => async (): Promise<any> => {
  try {
    const newBody = executorEmail
      ? {
          ...body,
          attendees: [executorEmail]
        }
      : body

    const result = await api.post(
      `${API.URL}${API.GOOGLE_CALENDAR_REQUEST}/planning-events`,
      newBody
    )

    return result.data.payload
  } catch (err) {
    console.log(err)
  }
}

export const updateEventToGoogleCalendar = (
  body: IGoogleEvent,
  id: string,
  executorEmail: string
) => async (): Promise<any> => {
  try {
    const newBody = executorEmail
      ? {
          ...body,
          attendees: [executorEmail]
        }
      : body

    const result = await api.put(
      `${API.URL}${API.GOOGLE_CALENDAR_REQUEST}/planning-events/${id}`,
      newBody
    )

    return result.data.payload
  } catch (err) {
    console.log(err)
  }
}

export const deleteEventFromGoogleCalendar = (id: string) => async (): Promise<void> => {
  try {
    await api.delete(`${API.URL}${API.GOOGLE_CALENDAR_REQUEST}/planning-events/${id}`)
  } catch (err) {
    console.log(err)
  }
}
