import React, { FC, useState } from 'react'
import styles from './CryptoBot.module.scss'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store'
import { TCryptoBotProps } from './CryptoBot.model'
import cls from 'classnames'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { Icons } from 'components/Icons'
import { CryptoApi } from './components/CryptoApi'
import { ControlPanel } from './components/ControlPanel'
import { TExchangeType } from './components/ControlPanel/ControlPanel.types'
import { getBotExchangeTypeAction } from './components/ControlPanel/ControlPanel.actions'
import { ExchangeType } from './components/ControlPanel/libs/constants/constants'

const CryptoBot: FC<TCryptoBotProps> = ({ history, user, updateBotInfo, getBotInfo }) => {
  const dispatch = useDispatch()
  const parsed = queryString.parse(history.location.search)
  const words = useSelector((state: TState) => state.global.language.words)
  const [exchangeType, setExchangeType] = useState<TExchangeType>()

  const [apiSetted] = useState(JSON.parse(parsed.apiSetted === 'true' ? 'true' : 'false'))

  async function handleRedirect() {
    try {
      await getBotInfo()
      const queryParams = {
        typeStep: '',
        type: 'general',
        stats: 'false',
        apiSetted: 'true'
      }
      await updateBotInfo({ selectedExchange: exchangeType })
      history.push(`${history.location.pathname}?${queryString.stringify(queryParams)}`)
    } catch (error) {
      const currentError = error as Error
      if (currentError.message === 'Request failed with status code 404' && exchangeType) {
        dispatch(getBotExchangeTypeAction(exchangeType))
        const queryParams = {
          ...parsed,
          typeStep: 'api'
        }
        history.push(`${history.location.pathname}?${queryString.stringify(queryParams)}`)
      } else {
        console.error('An error occurred:', currentError.message)
      }
    }
  }

  return (
    <>
      <div className={styles.container}>
        <section className={styles.header}>
          <h1>{words['crypto-bot.cryptoBot']}</h1>
        </section>
        {parsed.typeStep === '' && !apiSetted ? (
          <div className={styles['choose-exchange']}>
            <h1 className={styles.title}>{words['crypto-bot.selectExchange']}</h1>
            <p className={styles.description}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.
            </p>
            <div className={styles.methods}>
              <div
                className={cls(styles['methods-item'], {
                  [styles.shadow]: exchangeType === ExchangeType.BINANCE
                })}
                onClick={() => {
                  setExchangeType(ExchangeType.BINANCE)
                }}
              >
                <h2>
                  <Icons icon={ExchangeType.BINANCE} />
                </h2>
              </div>
              <div
                className={cls(styles['methods-item'], {
                  [styles.shadow]: exchangeType === ExchangeType.BY_BIT
                })}
                onClick={() => {
                  setExchangeType(ExchangeType.BY_BIT)
                }}
              >
                <h2>
                  <Icons icon={ExchangeType.BY_BIT} />
                </h2>
              </div>
            </div>
            <BaseButton
              children={words['crypto-bot.select']}
              disabled={!exchangeType}
              size="lgs"
              type="button"
              onClick={handleRedirect}
            />
          </div>
        ) : parsed.typeStep === 'api' && !apiSetted ? (
          <CryptoApi history={history} user={user} />
        ) : apiSetted ? (
          <ControlPanel history={history} />
        ) : null}
      </div>
    </>
  )
}

export default CryptoBot
