import { action } from 'store/store.utils'
import { GET_EVENTS_REQUEST, GET_EVENTS_SUCCESS } from './PlaningCalendar.constants'
import { TAllTasksList, TEvent } from './PlaningCalendar.model'
import { TTask } from '../Task/Task.model'

type TPayload = {
  eventsArray: TEvent[]
  currentTask: TTask
}

export const getEventsRequest = (): TAllTasksList => action(GET_EVENTS_REQUEST)
export const getEventsSuccess = (payload?: TPayload): TAllTasksList =>
  action(GET_EVENTS_SUCCESS, payload)
