import React from 'react'
import styles from './InputProject.module.scss'
// import { Field } from 'react-final-form'
// import { Input } from 'components/UiKit/Inputs'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'

const InputProject = ({ name, form, label, type }: any) => {
  const words = useSelector((state: TState) => state.global.language.words)
  return (
    <FieldFactory
      className={styles['input-factory']}
      form={form}
      config={[
        {
          items: [
            {
              name: name,
              label: label,
              required: true,
              inputWrapperClassName: styles['head-task'],
              component: () => {
                return {
                  type: type,
                  props: {
                    variant: 'outlined'
                  }
                }
              }
            }
          ]
        }
      ]}
      words={words}
    />
  )
}
export default InputProject
