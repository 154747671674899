import React, { FC, useState } from 'react'
import styles from './Verification.module.scss'
import { TVerificationProps } from './Verification.model'
import { Icons } from 'components/Icons'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import queryString from 'query-string'
import cls from 'classnames'
import { Polygon } from './components/Polygon'
import { VerificationBlockPass } from 'components/Profile/VerificationBlockPass'
import { Idenfy } from './components/Idenfy'
import { useSelector } from 'react-redux'
import { TState } from 'store'

const Verification: FC<TVerificationProps> = ({ user, getUserProfileInfo, history }) => {
  const [kycType, setKycType] = useState('')
  const words = useSelector((state: TState) => state.global.language.words)
  const parsed = queryString.parse(history.location.search)
  const getUserInfo = () => {
    if (parsed.type || parsed.profileType) {
      getUserProfileInfo(user.id, 'verification', false)
    }
  }

  return (
    <div className={styles.verification}>
      {!parsed.type && (
        <div className={styles['choose-verifi']}>
          <h1>{words['user.subcontract.verification.selectMethod']}</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.
          </p>
          <div className={styles.methods}>
            <div
              className={cls({
                [styles.shadow]: kycType === 'polygon'
              })}
              onClick={() => {
                setKycType('polygon')
              }}
            >
              <h2>
                <Icons icon="polygonidLogo" />
              </h2>
            </div>
            <div
              className={cls({
                [styles.shadow]: kycType === 'blockpass'
              })}
              onClick={() => {
                setKycType('blockpass')
              }}
            >
              <h2>
                <Icons icon="blockpassLogo" />
              </h2>
            </div>
            <div
              className={cls({
                [styles.shadow]: kycType === 'idenfy'
              })}
              onClick={() => {
                // setKycType('idenfy')
              }}
            >
              <h2>
                {/* <Icons icon="polygonidLogo" />
                 */}
                <img
                  src={require('../../../../../assets/images/Logo-iDenfy.png')}
                  className={styles.idenfyImg}
                />
              </h2>
            </div>
          </div>
          <BaseButton
            children={'Choose'}
            size="lgs"
            type="button"
            onClick={() => {
              if (kycType === 'blockpass') {
                history.push(
                  `${history.location.pathname}?${queryString.stringify({
                    ...parsed,
                    type: `${kycType}`
                  })}`
                )
              } else {
                history.push(
                  `${history.location.pathname}?${queryString.stringify({
                    ...parsed,
                    type: `${kycType}`
                  })}`
                )
              }
            }}
          />
        </div>
      )}

      {parsed.type === 'polygon' && <Polygon history={history} />}

      {parsed.type === 'blockpass' && (
        <VerificationBlockPass
          status={user.verificationStatus}
          id={user.id}
          getUserInfo={getUserInfo}
        />
      )}

      {parsed.type === 'idenfy' && <Idenfy history={history} />}
    </div>
  )
}

export default Verification
