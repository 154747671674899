import React, { FC } from 'react'
import styles from '../../AuthRoute.module.scss'
import Modal from 'components/Modal'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import { fieldsConfig } from './AuthModal.config'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { ReactComponent as Logo } from 'assets/images/logo-new.svg'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { Dispatch } from 'redux'

type TProperty = {
  isOpen: boolean
  onClose: () => void
  signup: (body: Record<string, any>) => (dispatch: Dispatch) => Promise<void>
}

const AuthModal: FC<TProperty> = ({ isOpen, onClose, signup }) => {
  const words = useSelector((state: TState) => state.global.language.words)
  return (
    <Modal className={styles['modal']} isShow={isOpen} onClose={onClose}>
      <div className={styles['modal-container']}>
        <Logo />
        <div className={styles['modal-desc']}>
          Please fill in these fields, to complete your registration
        </div>
        <Form
          initialValues={{
            fullName: '',
            email: ''
          }}
          onSubmit={values => {
            signup(values)
          }}
          render={({ form, handleSubmit }) => (
            <form onSubmit={handleSubmit} className={styles['modal-form']}>
              <div className={styles['input-wrapper']}>
                <FieldFactory
                  form={form}
                  config={fieldsConfig()}
                  words={words}
                  labelClassName={styles['label']}
                />
                <div className={styles['button-wrapper']}>
                  <BaseButton type="submit">Submit</BaseButton>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    </Modal>
  )
}

export { AuthModal }
