import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import VerificationComponent from './Idenfy'
import {
  getQrCodeSignIn,
  sendVerificationData,
  getQrCodeSignUp,
  createVerificationSession,
  createClaimsByIdenfy
} from './Idenfy.thunk'
import { getUserProfileInfo } from 'screens/client/Profile/Profile.thunx'
import { getSelectOptions } from 'screens/client/EditProfile/EditProfile.thunx'
import { createOffer } from 'screens/admin/SubcontractTasks/Verification/VerificationAdmin.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    user: state.client.profile.user,
    profileLoading: state.client.profile.loading,
    id: state.auth.data.id,
    userLoading: state.client.verificationClient.loading,
    offerId: state.admin.verification.offerId,
    qrCode: state.client.verificationClient.qrCode,
    signupQr: state.client.verificationClient.signupQr,
    did: state.client.verificationClient.did,
    claimsList: state.client.verificationClient.claimsList,
    userIdenfy: state.client.verificationClient.userIdenfy
  }
}

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      // updateUser,
      getUserProfileInfo,
      getSelectOptions,
      sendVerificationData,
      createOffer,
      getQrCodeSignIn,
      getQrCodeSignUp,
      createVerificationSession,
      createClaimsByIdenfy
    },
    dispatch
  )

export const Idenfy = connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationComponent)
