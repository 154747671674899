import React, { useEffect, useState } from 'react'
import styles from './SettingsTaskCancellationInfo.module.scss'
import { Icons } from 'components/Icons'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import history from 'utils/history'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { TaskInfoDetails } from 'components/TaskInfoDetails'
import { mapStateToProps, mapDispatchToProps } from './SettingsTaskCancellationInfo.container'
import { useParams } from 'react-router'
import Spinner from 'components/Spinner'
import { TaskInfoDescription } from 'components/TaskInfoDescription'
import { RefundComment } from 'components/RefundComment'
import CancelTaskModal from 'components/SubcontractModals/CancelTaskModal'
import { findedProposal } from 'utils/findedProposal'

type TSettingsTaskCancellationInfo = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

export const SettingsTaskCancellationInfo: React.FC<TSettingsTaskCancellationInfo> = ({
  taskInfo,
  userPhoto,
  loading,
  error,
  currentUser,
  isTransaction,
  getTask,
  updateTaskInfo,
  createTaskCancellation,
  updateTaskCancellation,
  isTaskInTransaction
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const params: any = useParams()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [proposal, setProposal] = useState<any>(null)

  useEffect(() => {
    getTask(params.id, true)
  }, [])

  useEffect(() => {
    if (taskInfo) {
      setProposal(findedProposal(taskInfo))
    }
  }, [loading])

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  return loading ? (
    <div className={styles.spinnerWrapper}>
      <Spinner />
    </div>
  ) : (
    <div className={styles.container}>
      {isTransaction && <Spinner isBackground={true} isTransaction={isTransaction} />}
      <section className={styles.header}>
        <h1>{words['admin.header.settings']}</h1>
      </section>
      <div className={styles.navWrapper}>
        <button
          className={styles['back-btn']}
          onClick={() => {
            history.goBack()
          }}
        >
          <Icons icon={'arrowLeftWithStick'} />
          {words['back']}
        </button>

        <BaseButton
          children={words['admin.settings.subcontract.confirmCancellation']}
          size="sm"
          className={styles.btn}
          onClick={toggleModal}
        />
      </div>

      <TaskInfoDetails taskInfo={taskInfo} userPhoto={userPhoto} />

      <TaskInfoDescription taskInfo={taskInfo} />

      <div className={styles.commentsWrapper}>
        <RefundComment taskInfo={taskInfo} isCustomer={true} />
        <RefundComment taskInfo={taskInfo} isCustomer={false} />
      </div>

      <CancelTaskModal
        size={'md'}
        toggle={isOpenModal}
        action={toggleModal}
        task={taskInfo}
        updateTask={updateTaskInfo}
        loading={loading}
        error={error}
        history={history}
        createTaskCancellation={createTaskCancellation}
        currentUser={currentUser}
        updateTaskCancellation={updateTaskCancellation}
        isTaskInTransaction={isTaskInTransaction}
        proposal={proposal && proposal}
        isAdmin={true}
      />
    </div>
  )
}
