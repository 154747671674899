import { API, api } from '../../../services/api'
import { Dispatch } from 'redux'
import { getPaymentTaskRequest, getPaymentTaskSuccess } from './TaskPayment.actions'

export function changeExecutor(id: number, userId: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getPaymentTaskRequest())
      const getTaskById = await api.get(`${API.URL}/tasks/${id}`)
      const currentTask = getTaskById.data.payload.result
      const { data } = await api.put(`${API.URL}/tasks/${id}`, {
        ...currentTask,
        executorId: userId
      })

      dispatch(getPaymentTaskSuccess(currentTask))
      return data.payload.result
    } catch (e) {
      console.log(e)
    }
    return
  }
}
