import { EProject } from './Projects.constants'
import { action } from '../../../store/store.utils'
import { TManager } from './Projects.model'
import { AxiosError } from 'axios'
import { TProjectHistory } from './components/ProjectHistory/ProjectHistory.model'

export const getProjectsRequest = () => action(EProject.GET_PROJECTS_REQUEST)
export const getProjectsSuccess = (payload: any) => action(EProject.GET_PROJECTS_SUCCESS, payload)
export const getOneProjectSuccess = (payload: any) =>
  action(EProject.GET_ONE_PROJECT_SUCCESS, payload)
export const getProjectsError = (payload: AxiosError) =>
  action(EProject.GET_PROJECTS_ERROR, payload)
export const changeProjectRequest = () => action(EProject.CHANGE_PROJECT_REQUEST)
export const changeProjectSuccess = (payload: any) =>
  action(EProject.CHANGE_PROJECT_SUCCESS, payload)
export const changeProjectError = (payload: AxiosError) =>
  action(EProject.CHANGE_PROJECT_ERROR, payload)
export const clearOneProjectSuccess = () => action(EProject.CLEAR_ONE_PROJECT_SUCCESS)
export const getProjectHistoryRequest = () => action(EProject.GET_PROJECT_HISTORY_REQUEST)
export const getProjectHistorySuccess = (payload: TProjectHistory[]) =>
  action(EProject.GET_PROJECT_HISTORY_SUCCESS, payload)

export const getManagersRequest = () => action(EProject.GET_MANAGERS_REQUEST)
export const getManagersSuccess: any = (payload: TManager[]) =>
  action(EProject.GET_MANAGERS_SUCCESS, payload)
export const getManagersError = (payload: AxiosError) =>
  action(EProject.GET_MANAGERS_ERROR, payload)
