import {
  GET_FOREIGN_PROJECTS_REQUEST,
  GET_FOREIGN_PROJECTS_SUCCESS,
  GET_FOREIGN_PROJECTS_ERROR,
  GET_FOREIGN_MANAGERS_REQUEST,
  GET_FOREIGN_MANAGERS_SUCCESS,
  GET_FOREIGN_MANAGERS_ERROR
} from './ForeignProfile.constants'
import { action } from '../../../store/store.utils'
import { AxiosError } from 'axios'
import { TManager, TProject } from './ForeignProfile.model'

export const getForeignProjectsRequest = () => action(GET_FOREIGN_PROJECTS_REQUEST)
export const getForeignProjectsSuccess = (payload: TProject[]) =>
  action(GET_FOREIGN_PROJECTS_SUCCESS, payload)
export const getForeignProjectsError = (payload: AxiosError) =>
  action(GET_FOREIGN_PROJECTS_ERROR, payload)

export const getForeignManagersRequest = () => action(GET_FOREIGN_MANAGERS_REQUEST)
export const getForeignManagersSuccess: any = (payload: TManager[]) =>
  action(GET_FOREIGN_MANAGERS_SUCCESS, payload)
export const getForeignManagersError = (payload: AxiosError) =>
  action(GET_FOREIGN_MANAGERS_ERROR, payload)
