import React, { FC } from 'react'
import { ReactComponent as DefaultAvatar } from 'assets/images/default_avatar.svg'

type TProperty = {
  photo?: string | null
  className?: string
}
const UserAvatar: FC<TProperty> = ({ photo, className }) => {
  return photo ? (
    <img className={className} src={photo} alt="avatar" />
  ) : (
    <DefaultAvatar className={className} />
  )
}

export { UserAvatar }
