import { Dispatch } from 'redux'

import {
  getAchievementsRequest,
  getAchievementsSuccess,
  getAchievementsError,
  createRequestAchievementSuccess,
  createRequestAchievementError,
  deleteAchievementSuccess,
  deleteAchievementError,
  restoreAchievementSuccess,
  restoreAchievementError,
  createOrEditAchievementRequest,
  createOrEditAchievementSuccess,
  createOrEditAchievementError,
  getCurrentAchievementRequest,
  getCurrentAchievementSuccess,
  getCurrentAchievementError
} from './Achievements.actions'
import { api, API } from 'services/api'
import { EPageLimits } from 'globalConfigs'
import toastr from 'utils/toastr'
import { TRequestAchievement } from './Achievements.model'
import history from 'utils/history'
import { TState } from 'store'
import { AxiosError } from 'axios'

export const getAchievements = (page: number, search: string = '') => async (
  dispatch: Dispatch
) => {
  dispatch(getAchievementsRequest())
  try {
    const response = await api.get(
      `${API.ACHIEVEMENTS}${search}${search ? '&' : '?'}limit=${
        EPageLimits.ACHIEVEMENTS
      }&page=${page}`
    )
    dispatch(getAchievementsSuccess(response.data))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(getAchievementsError(err))
  }
}

export const createRequestAchievement = (body: TRequestAchievement) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  const words = getData().global.language.words

  try {
    const response = await api.post(API.REQUEST_USER, body)
    dispatch(createRequestAchievementSuccess(response.status))
    toastr('success', words['SUCCESS_REQUESTED_ACHIEVEMENT'])
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(createRequestAchievementError(err))
    toastr('error', words['FILED_REQUESTED_ACHIEVEMENT'])
  }
}

export const deleteAchievement = (id: number) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  const words = getData().global.language.words

  try {
    await api.delete(`${API.ACHIEVEMENTS}/${id}`)
    dispatch(deleteAchievementSuccess(id))
    toastr('success', words['SUCCESS_DELETED'])
  } catch (error) {
    const err = error as AxiosError<any>
    toastr('error', words['FILED_DELETE_ACHIEVEMENT'], err)
    dispatch(deleteAchievementError(err))
  }
}

export const restoreAchievement = (id: number) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  const words = getData().global.language.words

  try {
    await api.put(`${API.ACHIEVEMENTS_RESTORE}/${id}`)
    dispatch(restoreAchievementSuccess(id))
    toastr('success', words['SUCCESS_RESTORED'])
  } catch (error) {
    const err = error as AxiosError<any>
    toastr('error', words['FILED_RESTORE_ACHIEVEMENT'], err)
    dispatch(restoreAchievementError(err))
  }
}

export const getCurrentAchievement = (id: string | number) => async (dispatch: Dispatch) => {
  dispatch(getCurrentAchievementRequest())
  try {
    const response = await api.get(`${API.ACHIEVEMENTS}/${id}`)
    dispatch(getCurrentAchievementSuccess(response.data.payload))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(getCurrentAchievementError(err))
  }
}

export const createOrEditAchievement = (body: any, id?: string) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  const words = getData().global.language.words

  const method = id ? 'put' : 'post'
  const url = id ? `${API.ACHIEVEMENTS}/${id}` : API.ACHIEVEMENTS

  dispatch(createOrEditAchievementRequest())
  try {
    const response = await api[method](url, body)

    dispatch(createOrEditAchievementSuccess(response))

    response.data.status === 'ok' &&
      toastr('success', id ? words['UPDATED_ACHIEVEMENT'] : words['CREATED_ACHIEVEMENT'])
    response.data.status === 'error' && toastr('error', response.data.errorMessage)
    history.push('/dashboard/point-system/achievements')
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(createOrEditAchievementError(err))
    //@ts-ignore
    toastr('error', err)
  }
}
