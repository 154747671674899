import { action } from 'store/store.utils'
import { AxiosError } from 'axios'
import {
  CHANGE_TIME_STATUS,
  SET_DATE,
  SEND_INTERVIEW_INVITATION_LOADING,
  SEND_INTERVIEW_INVITATION_SUCCESS,
  SEND_INTERVIEW_INVITATION_ERROR,
  GET_TIME_REQUEST,
  GET_TIME_SUCCESS,
  GET_TIME_ERROR,
  GET_INVITED_USER_REQUEST,
  GET_INVITED_USER_SUCCESS
} from './InterviewInvitation.constants'
import { TParam } from './InterviewInvitation.model'

export const changeTimeStatus = (payload: any) => action(CHANGE_TIME_STATUS, payload)

export const setData = (payload: any) => action(SET_DATE, payload)

export const sendInterviewInvitationLoading = (): TParam =>
  action(SEND_INTERVIEW_INVITATION_LOADING)
export const sendInterviewInvitationSuccess = (): TParam =>
  action(SEND_INTERVIEW_INVITATION_SUCCESS)
export const sendInterviewInvitationError = (payload: AxiosError): TParam =>
  action(SEND_INTERVIEW_INVITATION_ERROR, payload)

export const getTimeRequest = (): TParam => action(GET_TIME_REQUEST)
export const getTimeSuccess = (payload: any): TParam => action(GET_TIME_SUCCESS, payload)
export const getTimeError = (payload: AxiosError): TParam => action(GET_TIME_ERROR, payload)

export const getInvitedUserRequest = (): TParam => action(GET_INVITED_USER_REQUEST)
export const getInvitedUserSuccess = (payload: string): TParam =>
  action(GET_INVITED_USER_SUCCESS, payload)
