import { connect } from 'react-redux'
import { getDataSelector, getLoadingSelector } from './Login.reducer'
import { Login as LoginComponent } from './Login'
import { TState } from 'store'
import { bindActionCreators, Dispatch } from 'redux'
import { setUserRole } from './Login.actions'

export const mapStateToProps = (state: TState) => ({
  data: getDataSelector(state),
  loading: getLoadingSelector(state),
  role: state.auth.role
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setUserRole
    },
    dispatch
  )

export const Login = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent)
