import { validationSchemaForExchanges } from 'utils/validation'
import { ValidateExchangesSettings } from '../../ControlPanel.types'
import * as Yup from 'yup'

const validateControlPanel: ValidateExchangesSettings = values => {
  try {
    validationSchemaForExchanges.validateSync(values, { abortEarly: false })
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      return error.inner.reduce(
        (acc: { [key: string]: string }, currentError: Yup.ValidationError) => {
          if (currentError.path !== undefined) {
            acc[currentError.path] = currentError.message
          }
          return acc
        },
        {}
      )
    }
  }

  return undefined
}

export { validateControlPanel }
