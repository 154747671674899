import queryString from 'query-string'
import history from '../../../utils/history'

export const finishPointVacancies = () => {
  const parsed = queryString.parse(history.location.search)
  return `${parsed.fromPrice ? `&fromPrice=${parsed.fromPrice}` : ''}${
    parsed.toPrice ? `&toPrice=${parsed.toPrice}` : ''
  }${parsed.currency ? `&currency=${parsed.currency}` : ''}${
    parsed.project ? `&project=${parsed.project}` : ''
  }`
}

export const currency = [
  { value: 'UAH', label: 'UAH' },
  { value: 'USD', label: 'USD' },
  { value: 'TRL', label: 'TRL' }
]
