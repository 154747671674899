import React, { FC, MouseEvent } from 'react'
import styles from './NotificationPopUpElement.module.scss'
import { Icons } from 'components/Icons'
import { INotificationsItem } from '../../Notifications.model'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { TState } from 'store'

type Property = {
  notification: INotificationsItem
  onDelete: (id: number) => void
  onSelect: (notification: INotificationsItem) => void
}

const NotificationPopUpElement: FC<Property> = ({ notification, onDelete, onSelect }) => {
  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)
  const handleDelete = (event: MouseEvent) => {
    event.stopPropagation()
    onDelete(notification.id)
  }
  const handleSelect = () => {
    onSelect(notification)
  }
  return (
    <div className={styles['notification-container']} onClick={handleSelect}>
      <div className={styles['notification-wrapper']}>
        <div>
          <div>
            <div className={styles['notification-title']}>{notification.title}</div>
            <div className={styles['notification-message']}>{notification.message}</div>
          </div>
          <div className={styles['notification-date']}>
            {moment(notification.createdAt)
              .locale(currentLanguage)
              .fromNow()}
          </div>
        </div>
      </div>
      <button onClick={handleDelete}>
        <Icons icon="cross" />
      </button>
    </div>
  )
}

export { NotificationPopUpElement }
