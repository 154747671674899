import React, { FC } from 'react'
import styles from './NotFound.module.scss'
import notFoundImg from 'assets/images/404.svg'
import { useSelector } from 'react-redux'
import { TState } from 'store'

type TNotFoundProps = {
  title?: string
  children: any
}

export const NotFound: FC<TNotFoundProps> = ({ title, children }) => {
  const words = useSelector((state: TState) => state.global.language.words)
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{title || words['component.notFound.title']}</h2>
      <img src={notFoundImg} alt="notFound" />
      {children}
    </div>
  )
}
