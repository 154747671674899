import { Dispatch } from 'redux'
import { API, api } from '../../../services/api'
import { AxiosError } from 'axios'
import {
  deleteProjectMember,
  getProjectMemberError,
  getProjectMembersRequest,
  getProjectMemberSuccess
} from './CurrentTeam.actions'

export const getProjectTeam: any = (id: string, search: string = '') => async (
  dispatch: Dispatch
) => {
  dispatch(getProjectMembersRequest())
  try {
    const response: any = await api.get(`${API.URL}/projects/team/${id}${search}`)
    dispatch(getProjectMemberSuccess(response.data))
  } catch (error) {
    const err = error as AxiosError<any>
    await dispatch(getProjectMemberError(err))
  }
}
export const deleteMemberFromProject: any = (id: string, userId: string) => async (
  dispatch: Dispatch
) => {
  try {
    await api.delete(`${API.URL}/projects/team/${id}?userId=${userId}`)
    dispatch(deleteProjectMember(userId))
  } catch (error) {
    console.log(error)
  }
}
