import { Dispatch } from 'redux'
import { request, getAllVotingSuccess, getVotingSuccess, updateVoting } from './VotingList.actions'
import { API, api } from 'services/api'
import { TVoice } from './VotingList.model'
import toastr from '../../../utils/toastr'

export const getAllVoting = () => async (dispatch: Dispatch) => {
  dispatch(request())
  const response = await api.get(`${API.URL}/task-voting`)
  dispatch(getAllVotingSuccess(response.data))
}

export const getVoting = (id: number) => async (dispatch: Dispatch) => {
  dispatch(request())
  const response = await api.get(`${API.URL}/task-voting/${id}`)
  dispatch(getVotingSuccess(response.data))
}

export const vote = (id: number, voice: TVoice) => async (dispatch: Dispatch) => {
  dispatch(request())
  const response = await api.patch(`${API.URL}/task-voting/${id}`, { voice })
  dispatch(updateVoting(response.data))
}

export const createVoting = (endOfVoting: Date, taskId: number) => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    dispatch(request())
    const response = await api.post(`${API.URL}/task-voting`, { endOfVoting, taskId })
    dispatch(getVotingSuccess(response.data))
    toastr('success', 'Voting was successfully created')
  } catch (error) {
    console.log(error)
  }
}
