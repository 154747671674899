import React, { FC, CSSProperties, useState } from 'react'
import { useSelector } from 'react-redux'
import { Field, Form } from 'react-final-form'
import { TState } from 'store'
import cls from 'classnames'
import { Input } from 'components/UiKit/Inputs'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import styles from './styles.module.scss'
import { config } from 'globalConfigs'
import { TWords } from 'elements/SideBar/SideBar.config'
import { Icons } from '../../components/Icons'
import Select, { StylesConfig } from 'react-select'
import { Button } from 'components/UiKit/Button'

type TModalProps = {
  toggle?: boolean
  action?: any
  size?: 'default' | 'md'
}

const getIntervalOptions = (words: TWords) => [
  { value: '1', label: words['user.subcontract.bonuses.day'] },
  { value: '2', label: words['user.subcontract.bonuses.week'] },
  { value: '3', label: words['user.subcontract.bonuses.month'] },
  { value: '4', label: words['user.subcontract.bonuses.year'] }
]

const WithdrawalConfigModal: FC<TModalProps> = ({ toggle, action, size = 'default' }) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)
  const intervalOptions = getIntervalOptions(words)
  const [selectedInterval, setSelectedInterval] = useState(intervalOptions[0].label)
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const withdrawalStylesOption: StylesConfig = {
    menuPortal: base => ({
      ...base,
      top: '0',
      right: '0'
    }),
    menu: base => ({
      ...base,
      top: '0'
    }),
    control: base => ({
      ...base,
      opacity: '0',
      pointerEvents: 'none'
    }),
    container: base => ({
      ...base,
      marginLeft: '8px',
      width: '74px',
      minHeight: '120px'
    }),
    option: (base, state: any) => ({
      ...base,
      paddingTop: '4px',
      paddingBottom: '8px',
      fontSize: '12px',
      fontWeight: '400',
      ':hover': { backgroundColor: '#EFEFFF' },
      backgroundColor: state.isSelected && '#EFEFFF',
      color: state.isSelected && '#333'
    })
  }

  return (
    <div>
      <div onClick={action} className={cls({ [styles.overlay]: toggle })} />
      <div className={cls({ [styles.container]: true, [styles.active]: toggle })}>
        <div
          className={cls({
            [styles.modal]: true,
            [styles.withdrawalModal]: true,
            [styles[`modal-size-${size}`]]: true
          })}
        >
          <button onClick={action} className={styles['close-btn']}>
            <Icons icon={'closeModal'} />
          </button>
          <h2>{words['user.subcontract.bonuses.withdrawalBonuses']}</h2>
          <Form
            onSubmit={() => {
              action()
            }}
          >
            {({ form, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} name="form">
                  <div className={styles.field}>
                    <div className={styles.label}>
                      <label>{words['user.subcontract.bonuses.outputInterval']} </label>
                    </div>
                    <Field name="intervalValue" className={styles.input}>
                      {({ input }) => (
                        <div className={styles.intervalValue}>
                          <Input variant="outlined" type="number" {...input} />
                        </div>
                      )}
                    </Field>
                    <Field name="intervalRange" className={styles.input}>
                      {() => (
                        <div className={styles.intervalRange}>
                          <span
                            className={styles['interval-selected']}
                            onClick={() => setMenuIsOpen(true)}
                          >
                            {selectedInterval}
                          </span>
                          <Select
                            name="intervalRange"
                            options={intervalOptions}
                            menuIsOpen={menuIsOpen}
                            styles={withdrawalStylesOption}
                            className={styles['withdaw-select']}
                            onChange={(selectedOptions: any) => {
                              form.change('intervalRange', selectedOptions.value)
                              setSelectedInterval(selectedOptions.label)
                              setMenuIsOpen(false)
                            }}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className={styles.field}>
                    <div className={styles.label}>
                      <label>{words['user.subcontract.bonuses.outputDate']} </label>
                    </div>
                    <FieldFactory
                      currentLanguage={currentLanguage}
                      form={form}
                      config={[
                        {
                          items: [
                            {
                              name: 'date',
                              required: true,
                              inputWrapperClassName: styles.output,
                              component: ({ input }: any) => {
                                return {
                                  type: 'datePicker',
                                  props: {
                                    autoComplete: 'off',
                                    dateFormat: config.dateFormat,
                                    selected: input.value ? new Date(input.value) : null
                                  }
                                }
                              }
                            }
                          ]
                        }
                      ]}
                      words={words}
                      yearStyles={{
                        menuPortal: (style: CSSProperties) => ({
                          ...style,
                          zIndex: 9999
                        }),
                        menu: (style: CSSProperties) => ({
                          ...style,
                          color: 'red'
                        })
                      }}
                    />
                  </div>
                  <Button onClick={() => form.submit()} className={styles.saveBtn} size="lg">
                    <span>{words['user.subcontract.bonuses.save']}</span>
                  </Button>
                </form>
              )
            }}
          </Form>
        </div>
      </div>
    </div>
  )
}

export default WithdrawalConfigModal
