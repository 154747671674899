import { TSelectOption } from 'components/Select'
import React, { useEffect, useState } from 'react'
import { IProject } from './ProfileProjects.model'
import styles from './ProfileProjects.module.scss'
import Spinner from 'components/Spinner'
import { useSelector } from 'react-redux'
import { TState } from 'store'

type TProfileProjectsProps = {
  history: any
  projects: IProject[]
  managers: TSelectOption[]
  loading: boolean
}

const ProfileProjects = ({ history, projects, managers, loading }: TProfileProjectsProps) => {
  const [newProjects, setNewProjects] = useState<IProject[]>([])
  const words = useSelector((state: TState) => state.global.language.words)

  useEffect(() => {
    if (projects) {
      let updatedProjects: IProject[] = []
      updatedProjects = projects.map((project: IProject) => {
        const manager = managers.find((option: any) => +option.value === project.manager)
        if (manager) {
          return { ...project, manager: manager.label }
        }
        return project
      })
      setNewProjects(updatedProjects)
    }
  }, [projects])
  return (
    <div className={styles['wrapper']}>
      {loading ? (
        <div className={styles['spinner']}>
          <Spinner />
        </div>
      ) : newProjects.length ? (
        <ul className={styles['project-list']}>
          {newProjects.map((project: IProject) => {
            return (
              <li key={project.id} onClick={() => history.push('/')}>
                <div className={styles['project-wrapper']}>
                  <div className={styles['project-image']}>
                    <img src={project.avatar} alt={project.name} />
                  </div>
                  <div className={styles['project-name']}>{project.name}</div>
                </div>
                <div className={styles['project-manager']}>
                  <span>{words['projects.manager']}: </span>
                  {project.manager}
                </div>
              </li>
            )
          })}
        </ul>
      ) : (
        <div className={styles['empty-projects']}>{words['project.emptyList']}</div>
      )}
    </div>
  )
}
export default ProfileProjects
