import React from 'react'
import { Column } from 'react-table'
import { TableSortHeader } from 'components/UiKit/Table/components/TableSortHeader'
import { ParsedQuery } from 'query-string'
import { ETransactionHistory, STATUS_OF_REQUESTS_TIME_OFF, TYPE_TRANSACTION } from 'globalConfigs'
import styles from './HistoryTransaction.module.scss'
import moment from 'moment'
import cls from 'classnames'
import { CommentCell } from 'components/UiKit/Table/components/CommentsCell'
import { TWords } from 'elements/SideBar/SideBar.config'
import { THistory, TTransactions } from '../../Profile.model'

type TGetColumnsOptions = {
  parsed: ParsedQuery<string>
  words: TWords
}
type TGetColumns = (options: TGetColumnsOptions) => Array<Column<THistory & TTransactions>>

//@ts-ignore
export const getColumns: TGetColumns = ({ parsed, words }) => {
  const columnsGoods: Array<Column<THistory>> = [
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>{words['user.profile.table.goodsService']}</TableSortHeader>
      ),
      id: 'goodsId',
      accessor: ({ goodsId }) => <span className={styles.general}>{goodsId && goodsId.name} </span>,
      width: 230
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>{words['user.profile.table.amount']}</TableSortHeader>
      ),
      id: 'amount',
      accessor: ({ amount }) => <span className={styles.center}>{amount}</span>,
      width: 80
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>{words['user.profile.table.requestDate']}</TableSortHeader>
      ),
      id: 'creationDate',
      accessor: ({ creationDate }) => (
        <span className={styles.general}>{moment(creationDate).format('DD.MM.YYYY')}</span>
      ),
      width: 135
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>
          {words['user.profile.table.numberOfPeople']}
        </TableSortHeader>
      ),
      id: 'numberOfPeople',
      accessor: ({ goodsId }) => (
        <span className={styles.center}>{goodsId && goodsId.numberOfPeople}</span>
      ),
      width: 110
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>{words['user.profile.table.price']}</TableSortHeader>
      ),
      id: 'price',
      accessor: ({ price }) => (
        <span
          className={cls({
            [styles['color-price']]: true,
            [styles['color-price-red']]: true
          })}
        >
          {price}
        </span>
      ),
      width: 160
    },
    {
      Header: words['user.profile.table.status'],
      id: 'status',
      accessor: ({ status }) => (
        <span
          className={cls({
            [styles.status]: true,
            [styles.orange]: status === 1,
            [styles.red]: status === 3,
            [styles.green]: status === 2
          })}
        >
          {STATUS_OF_REQUESTS_TIME_OFF(words)[status]}
        </span>
      ),
      width: 160
    },
    {
      Header: words['user.profile.table.comment'],
      id: 'adminComment',
      accessor: ({ adminComment }) => <CommentCell comment={adminComment} />,
      width: 120
    }
  ]

  const columnsAchievements: Array<Column<THistory>> = [
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>{words['user.profile.table.initiative']}</TableSortHeader>
      ),
      id: 'initiativeId',
      accessor: ({ initiativeId }) => (
        <div data-title={initiativeId ? initiativeId.name : ''} className={styles.customTitle}>
          <span className={styles.general}>{initiativeId && initiativeId.name} </span>
        </div>
      ),
      width: 230
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>{words['user.profile.table.requestDate']}</TableSortHeader>
      ),
      id: 'creationDate',
      accessor: ({ creationDate }) => (
        <span className={styles.general}>{moment(creationDate).format('DD.MM.YYYY')}</span>
      ),
      width: 130
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>{words['user.profile.table.reward']}</TableSortHeader>
      ),
      id: 'price',
      accessor: ({ price }) => (
        <span
          className={cls({
            [styles['color-price']]: true,
            [styles['color-price-green']]: true
          })}
        >
          {price}
        </span>
      ),
      width: 110
    },
    {
      Header: words['user.profile.table.status'],
      id: 'status',
      accessor: ({ status }) => (
        <span
          className={cls({
            [styles.status]: true,
            [styles.orange]: status === 1,
            [styles.red]: status === 3,
            [styles.green]: status === 2
          })}
        >
          {STATUS_OF_REQUESTS_TIME_OFF(words)[status]}
        </span>
      ),
      width: 160
    },
    {
      Header: words['user.profile.table.comment'],
      id: 'adminComment',
      accessor: ({ adminComment }) => <CommentCell comment={adminComment} />,
      width: 120
    }
  ]

  const columnsTransactions: Array<Column<TTransactions>> = [
    {
      Header: words['admin.productionCalendar.new.type'],
      id: 'kind',
      accessor: ({ kind }) => (
        <span className={styles.general}>{TYPE_TRANSACTION(words)[kind]} </span>
      ),
      width: 100
    },
    {
      Header: words['user.profile.table.sender'],
      id: 'payerID',
      accessor: ({ payerID }) => <span className={styles.general}>{payerID.fullName} </span>,
      width: 200
    },
    {
      Header: words['user.profile.table.receiver'],
      id: 'receiverID',
      accessor: ({ receiverID }) => <span className={styles.general}>{receiverID.fullName} </span>,
      width: 200
    },
    {
      Header: words['user.profile.table.transactionDate'],
      id: 'transactionDate',
      accessor: ({ transactionDate }) => (
        <span className={styles.general}>{moment(transactionDate).format('DD.MM.YYYY')}</span>
      ),
      width: 200
    },
    {
      Header: `${words['user.profile.table.sum']}`,
      id: 'amount',
      accessor: ({ amount }) => (
        <span
          className={cls({
            [styles['color-price']]: true
          })}
        >
          {amount}
        </span>
      ),
      width: 110
    },
    {
      Header: words['user.profile.table.comment'],
      id: 'comment',
      accessor: ({ comment }) => <CommentCell comment={comment} />,
      width: 120
    }
  ]

  return parsed.type === ETransactionHistory.GOODS
    ? columnsGoods
    : parsed.type === ETransactionHistory.ACHIEVEMENTS
    ? columnsAchievements
    : columnsTransactions
}
