import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import ControlPanelComponent from './ControlPanel'
import {
  getBotSettings,
  getBotInfo,
  resetSettings,
  updateBotInfo,
  saveSettings
} from './ControlPanel.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    user: state.auth.data,
    botSettings: state.client.controlPanel.settings,
    botInfo: state.client.controlPanel.botInfo
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getBotSettings,
      resetSettings,
      getBotInfo,
      updateBotInfo,
      saveSettings
    },
    dispatch
  )

export const ControlPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlPanelComponent)
