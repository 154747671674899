import React, { FC, useState } from 'react'
import { Icons } from 'components/Icons'
import { TOptions } from './Options.model'
import styles from './Options.module.scss'
import { ConfirmAction } from 'components/ConfirmAction'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export const Options: FC<TOptions> = ({
  dotted,
  onPointsEdit,
  onEdit,
  onRemove,
  onTimeEdit,
  onRestore
}) => {
  const [openOptions, setOpenOptions] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const words = useSelector((state: TState) => state.global.language.words)

  return (
    <>
      <div className={styles.container}>
        {openConfirm && onRemove && (
          <ConfirmAction
            children={`${words['admin.users.table.buttons.delete']} ?`}
            successLabel={words['user.deleting.successLabel']}
            cancelLabel={words['user.deleting.cancelLabel']}
            onCancel={() => {
              setOpenConfirm(false)
            }}
            onSuccess={() => {
              setOpenOptions(false)
              setOpenConfirm(false)
              onRemove()
            }}
          />
        )}

        {openOptions || !dotted ? (
          <>
            {dotted && (
              <div
                className={styles.overlay}
                onClick={() => {
                  setOpenOptions(false)
                  setOpenConfirm(false)
                }}
              />
            )}
            <div className={styles.openOptionsWrapper}>
              <div className={cn(styles.openOptions, { [styles['open-options-inline']]: !dotted })}>
                {onPointsEdit && (
                  <>
                    <span data-tip="true" data-for="pointsIcon">
                      <Icons icon="pointEditIcon" onClick={onPointsEdit} />
                    </span>

                    <ReactTooltip
                      className={cn(styles.tooltip, styles['tooltip-points'])}
                      id="pointsIcon"
                      place="bottom"
                    >
                      {words['admin.users.table.buttons.balanceEdit']}
                    </ReactTooltip>
                  </>
                )}
                {onTimeEdit && (
                  <>
                    <span data-tip="true" data-for="timeIcon">
                      <Icons icon="timeIcon" onClick={onTimeEdit} />
                    </span>

                    <ReactTooltip
                      className={cn(styles.tooltip, styles['tooltip-time'])}
                      id="timeIcon"
                      place="bottom"
                    >
                      {words['admin.users.table.buttons.timeEdit']}
                    </ReactTooltip>
                  </>
                )}
                {onEdit && (
                  <>
                    <span data-tip="true" data-for="updateIcon">
                      <Icons icon="updateIcon" onClick={onEdit} />
                    </span>

                    <ReactTooltip
                      className={cn(styles.tooltip, styles['tooltip-update'])}
                      id="updateIcon"
                      place="bottom"
                    >
                      {words['admin.users.table.buttons.Edit']}
                    </ReactTooltip>
                  </>
                )}
                {onRemove && (
                  <>
                    <span data-tip="true" data-for="discharge">
                      <Icons icon="dischargeOutline" onClick={() => setOpenConfirm(true)} />
                    </span>

                    <ReactTooltip
                      className={cn(styles.tooltip, styles['tooltip-discharge'])}
                      id="discharge"
                      place="bottom"
                    >
                      {words['admin.users.table.buttons.delete']}
                    </ReactTooltip>
                  </>
                )}
                {onRestore && (
                  <>
                    <span data-tip="true" data-for="restore">
                      <Icons icon="restoreIcon" onClick={onRestore} />
                    </span>

                    <ReactTooltip
                      className={cn(styles.tooltip, styles['tooltip-restore'])}
                      id="restore"
                      place="bottom"
                    >
                      {words['admin.users.table.buttons.deactivate']}
                    </ReactTooltip>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          dotted && (
            <div onClick={() => setOpenOptions(true)} className={styles.options}>
              <div className={styles.circle} />
              <div className={styles.circle} />
              <div className={styles.circle} />
            </div>
          )
        )}
      </div>
    </>
  )
}
