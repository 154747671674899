import React from 'react'
import { Field } from 'react-final-form'
import { DatePicker } from 'components/UiKit/DatePicker'
import { useSelector } from 'react-redux'
import cls from 'classnames'
import { config } from 'globalConfigs'
import { TState } from 'store'
import styles from './DateInvoice.module.scss'
import { FormApi, InternalFormState } from 'final-form'

interface IDateInvoice {
  name: string
  form: FormApi
  formState: InternalFormState | any
  validation?: any
}

const DateInvoice = ({ name, form, formState, validation }: IDateInvoice) => {
  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)
  return (
    <Field
      name={name}
      validate={validation && validation.composeValidators(validation.required('error'))}
    >
      {({ input, meta }) => {
        return (
          <div data-for={name} id={name}>
            <DatePicker
              locale={currentLanguage}
              isInvalid={meta.error && (meta.error && meta.submitFailed)}
              isNextYear={true}
              className={cls({
                [styles.dateInvalid]: meta.error && (meta.error && meta.submitFailed)
              })}
              dateFormat={config.dateFormat}
              selected={input.value && new Date(input.value)}
              onChange={(date: Date) => {
                form.change(input.name, new Date(date).toISOString())
              }}
              endDate={new Date(formState.values.lastDay)}
              startDate={new Date(input.value)}
            />
          </div>
        )
      }}
    </Field>
  )
}
export default DateInvoice
