import { generatePDF } from './getPDF'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { TInvoiceToDownload } from '../CreteInvoices/CreateInvoices.model'

export const generateZip = async (ref: any, invoices?: TInvoiceToDownload[]) => {
  const pdfPromises = ref.current.map((item: React.RefObject<HTMLDivElement>, i: number) => {
    const invoiceNumber = invoices ? invoices[i].invoiceNumber : i
    return generatePDF(item, invoiceNumber, 'all', true)
  })

  const pdfFiles = await Promise.all(pdfPromises)

  const zip = new JSZip()

  pdfFiles.filter(Boolean).forEach(pdfFile => {
    zip.file(pdfFile.fileName, pdfFile.data)
  })

  const zipData = await zip.generateAsync({ type: 'blob' })

  saveAs(zipData, 'Invoices.zip')
}
