import { action } from '../../../store/store.utils'
import { TCreateEntrepreneurs, TFormulatedInvoices } from './Entrepreneurs.model'
import {
  DELETE_ENTREPRENEUR_INVOICE,
  DELETE_FROM_SELECTED_ENTREPRENEUR_INVOICE,
  FILTER_INVOICES,
  SET_ENTREPRENEURS,
  SET_SELECTED_ALL_ENTREPRENEUR_INVOICES,
  SET_SELECTED_ENTREPRENEUR_INVOICE,
  GET_ENTREPRENEURS_INVOICES_REQUEST,
  GET_ENTREPRENEURS_INVOICES_SUCCESS,
  SET_INVOICE,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_REQUEST,
  GET_INVOICES_ERROR,
  SET_DOWNLOAD_INVOICES,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR
} from './Entrepreneurs.constants'
import { TUserData } from '../Users/Users.model'
import { AxiosError } from 'axios'

export const getUsersRequest = () => action(GET_USERS_REQUEST)
export const getUsersSuccess = (payload: { results: TUserData[] }) =>
  action(GET_USERS_SUCCESS, payload)
export const getUsersError = (payload: AxiosError) => action(GET_USERS_ERROR, payload)

export const setEntrepreneurs = (payload?: any): TCreateEntrepreneurs =>
  action(SET_ENTREPRENEURS, payload)

export const getInvoicesRequest = () => action(GET_INVOICES_REQUEST)
export const getInvoicesSuccess = (payload: any) => action(GET_INVOICES_SUCCESS, payload)
export const getInvoicesError = (payload: any) => action(GET_INVOICES_ERROR, payload)
export const deleteEntrepreneurInvoice = (id: string): TCreateEntrepreneurs =>
  action(DELETE_ENTREPRENEUR_INVOICE, id)

export const setSelectedEntrepreneurInvoice = (payload: any): TCreateEntrepreneurs =>
  action(SET_SELECTED_ENTREPRENEUR_INVOICE, payload)

export const setInvoice = (payload: any): TCreateEntrepreneurs => action(SET_INVOICE, payload)

export const deleteFromSelectedEntrepreneurInvoice = (payload: any): TCreateEntrepreneurs =>
  action(DELETE_FROM_SELECTED_ENTREPRENEUR_INVOICE, payload)

export const filterInvoices = (payload: any): TCreateEntrepreneurs =>
  action(FILTER_INVOICES, payload)

export const setSelectedAllEntrepreneurInvoices = (payload: any): TCreateEntrepreneurs =>
  action(SET_SELECTED_ALL_ENTREPRENEUR_INVOICES, payload)

export const getEntrepreneurInvoicesRequest = () => action(GET_ENTREPRENEURS_INVOICES_REQUEST)

export const getEntrepreneurInvoicesSuccess = (
  payload: TFormulatedInvoices[]
): TCreateEntrepreneurs => action(GET_ENTREPRENEURS_INVOICES_SUCCESS, payload)

export const setDownloadInvoices = (payload: any) => action(SET_DOWNLOAD_INVOICES, payload)
