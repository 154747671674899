import { ETransactionMessage } from 'components/Spinner/types'
import { TWords } from 'elements/SideBar/SideBar.config'
import { signERC2612Permit } from 'eth-permit'
import { Dispatch } from 'redux'
import { isTaskInTransaction } from 'screens/client/Web3/web3.action'
import { Contract } from 'web3-eth-contract'
import toastr, { EToastrTypeMessage } from 'utils/toastr'

export async function permit(
  contractInstance: Contract,
  owner: string,
  spender: string,
  deadline: number,
  nonce: number,
  gasPrice: string,
  MAX_INT: string,
  dispatch: Dispatch,
  words: TWords
) {
  try {
    const {
      name,
      version,
      verifyingContract,
      chainId
    } = await contractInstance.methods.eip712Domain().call()

    const domain = {
      name,
      version,
      verifyingContract,
      chainId
    }

    const { v, r, s } = await signERC2612Permit(
      window.ethereum,
      domain,
      owner,
      spender,
      MAX_INT,
      deadline,
      nonce
    )

    const gasLimitPermit = await contractInstance.methods
      .permit(owner, spender, MAX_INT, deadline, v, r, s)
      .estimateGas({ from: owner })

    await contractInstance.methods
      .permit(owner, spender, MAX_INT, deadline, v, r, s)
      .send({ from: owner, gasPrice, gas: gasLimitPermit })
      .on('transactionHash', () => dispatch(isTaskInTransaction(true)))
      .on('receipt', async () => {
        toastr(EToastrTypeMessage.SUCCESS, words[`${ETransactionMessage.TRANSACTION_SUCCESS}`])
      })
      .on('error', () => {
        toastr(EToastrTypeMessage.ERROR, words[`${ETransactionMessage.TRANSACTION_ERROR}`])
        dispatch(isTaskInTransaction(false))
      })
  } catch (error) {
    console.log(error)
  } finally {
    dispatch(isTaskInTransaction(false))
  }
}
