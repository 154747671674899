import React, { FC } from 'react'
import { IProps } from './model'
import styles from './style.module.scss'
import cls from 'classnames'
import { Tooltip as ReactTooltip } from 'react-tooltip'

export const Textarea: FC<IProps> = ({
  name,
  className = '',
  title,
  placeholder,
  onChange,
  isInvalid,
  errorMessage,
  value,
  readonly,
  required,
  size = 'default'
}) => {
  const inputClass = cls({
    [styles['comments']]: true,
    [className]: true,
    [styles.invalid]: isInvalid
  })

  return (
    <>
      {/* <div data-tip="" html-for={name}> */}
      <div data-tip="">
        <label className={cls(inputClass, { [styles['label-required']]: required })}>
          <span>{title}</span>
          <div data-tip="" data-for="comments">
            <textarea
              id={name}
              className={cls({ [styles[`textarea-size-${size}`]]: true })}
              name={name}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              readOnly={readonly}
            />
          </div>
        </label>
      </div>
      {isInvalid && errorMessage && (
        <ReactTooltip
          anchorSelect={`#${name}`}
          id={name}
          place="bottom"
          variant="error"
          content={errorMessage}
          className={styles.tooltip}
        />
      )}
    </>
  )
}
