import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './PositionsInfo.module.scss'
import { TPositionsInfoProps } from './PositionsInfo.model'
import Spinner from 'components/Spinner'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { TWords } from 'elements/SideBar/SideBar.config'
import { Columns } from './PositionInfo.columns'
import { Table } from 'components/UiKit/Table'
import { api, API } from 'services/api'

const Positions: React.FC<TPositionsInfoProps> = ({
  positionsInfo,
  isLoading,
  user,
  getPositionsInfoThunk
}) => {
  const words: TWords = useSelector((state: TState) => state.global.language.words)
  const [botId, setBotId] = useState<string | null>(null)

  const getBotId = useCallback(async () => {
    try {
      const { data } = await api.get(`${API.URL}/crypto-bot/settings/${user.id}`)

      setBotId(data.bot_id)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getBotId()
  }, [])

  useEffect(() => {
    if (!positionsInfo && botId) {
      getPositionsInfoThunk(botId)
    }
  }, [botId, positionsInfo])

  const columnsMemoized = useMemo(() => Columns(words), [])

  if (!positionsInfo) {
    return null
  }

  return (
    <>
      {!isLoading ? (
        <section className={styles.positionsInfoSection}>
          <Table
            columns={columnsMemoized}
            data={positionsInfo}
            total={0}
            getData={() => ({})}
            tableHeight="500"
          />
        </section>
      ) : (
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      )}
    </>
  )
}
export default Positions
