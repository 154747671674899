import React, { FC } from 'react'
import cls from 'classnames'
import styles from './style.module.scss'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { Icons } from 'components/Icons'
import { useSelector } from 'react-redux'
import { TState } from 'store'

type TModalProps = {
  toggle?: boolean
  action?: any
  handleAction: () => void
  isResetSettings: boolean
}

const CryptoBotModal: FC<TModalProps> = ({ toggle, action, handleAction, isResetSettings }) => {
  const words = useSelector((state: TState) => state.global.language.words)

  const title = isResetSettings ? words['crypto-bot.reset'] : words['crypto-bot.start']
  const content = isResetSettings
    ? words['crypto-bot.confirmReset']
    : words['crypto-bot.confirmStart']

  const button = isResetSettings
    ? words['user.subcontract.taskFilter.reset']
    : words['user.subcontract.bonuses.save']

  return (
    <div>
      <div onClick={action} className={cls({ [styles.overlay]: toggle })} />
      <div className={cls({ [styles.container]: true, [styles.active]: toggle })}>
        <div className={styles.modal}>
          <Icons
            className={styles['close-cross']}
            icon={'close'}
            onClick={() => {
              action()
            }}
          />
          <h2>{title}</h2>

          <p>{content}</p>

          <div className={styles['button-container']}>
            <BaseButton
              children={words['user.subcontract.cancelTaskModal.cancel']}
              outline={true}
              size={'lgs'}
              onClick={e => {
                e.preventDefault()
                action()
              }}
            />

            <BaseButton children={button} size={'lgs'} onClick={handleAction} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CryptoBotModal
