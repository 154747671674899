/* eslint-disable no-console */
import { IAction } from 'models'
import {
  UPDATE_USER_REQUEST_VERIFI,
  UPDATE_USER_ERROR_VERIFI,
  UPDATE_USER_SUCCESS_VERIFI,
  GET_USERS_REQUEST2,
  GET_USERS_ERROR2,
  GET_USERS_SUCCESS2,
  POLYGON_LOGIN_REQUEST,
  POLYGON_LOGIN_ERROR,
  POLYGON_LOGIN_SUCCESS,
  POLYGON_OFFER_SUCCESS,
  POLYGON_CLAIMS_LIST_SUCCESS
} from './VerificationAdmin.constants'

export type IInitialState = {
  error: string
  loading: boolean
  user: object
  userList: object[]
  itemsCount: number
  position: object[]
  rank: any
  polygonToken: string
  offerId: string
  qrcode: any
  claimsList: []
}

export const initialState: IInitialState = {
  error: '',
  loading: false,
  user: {},
  userList: [],
  itemsCount: 0,
  position: [{ value: '1', label: 'Researcher' }],
  rank: [],
  polygonToken: '',
  offerId: '',
  qrcode: {},
  claimsList: []
}

export default (state = initialState, action: IAction): any => {
  switch (action.type) {
    case UPDATE_USER_REQUEST_VERIFI: {
      return {
        ...state,
        loading: true,
        paginLoading: action.payload
      }
    }
    case UPDATE_USER_SUCCESS_VERIFI:
      return {
        ...state,
        loading: false,
        user: action.payload.user
      }
    case UPDATE_USER_ERROR_VERIFI:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    case GET_USERS_REQUEST2: {
      return {
        ...state,
        loading: true,
        paginLoading: action.payload
      }
    }
    case GET_USERS_SUCCESS2:
      return {
        ...state,
        loading: false,
        userList: action.payload.users,
        itemsCount: action.payload.total,
        position: action.payload.positions,
        rank: action.payload.rank
      }
    case GET_USERS_ERROR2:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case POLYGON_LOGIN_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case POLYGON_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        polygonToken: action.payload
      }
    case POLYGON_LOGIN_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case POLYGON_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        offerId: action.payload.data
        // qrcode: action.payload.qrcode
      }
    case POLYGON_CLAIMS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        claimsList: action.payload.claimsList
      }
    default:
      return state
  }
}
