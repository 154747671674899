import { action } from 'store/store.utils'
import {
  UPDATE_USER_REQUEST_VERIFI,
  UPDATE_USER_ERROR_VERIFI,
  UPDATE_USER_SUCCESS_VERIFI,
  GET_USERS_REQUEST2,
  GET_USERS_ERROR2,
  GET_USERS_SUCCESS2,
  POLYGON_LOGIN_REQUEST,
  POLYGON_LOGIN_ERROR,
  POLYGON_LOGIN_SUCCESS,
  POLYGON_OFFER_SUCCESS
} from './VerificationAdmin.constants'
import { TAllTasksList } from './VerificationAdmin.model'

export const updateUserRequest = (): TAllTasksList => action(UPDATE_USER_REQUEST_VERIFI)

export const updateUserError = (payload?: any): TAllTasksList =>
  action(UPDATE_USER_ERROR_VERIFI, payload)

export const updateUserSuccess = (payload?: any): TAllTasksList =>
  action(UPDATE_USER_SUCCESS_VERIFI, payload)

export const getUsersRequest = (): TAllTasksList => action(GET_USERS_REQUEST2)

export const getUsersSuccess = (payload: any): TAllTasksList => {
  return action(GET_USERS_SUCCESS2, payload)
}

export const getUsersError = (payload?: any): TAllTasksList => action(GET_USERS_ERROR2, payload)

export const polygonLoginRequest = (): TAllTasksList => action(POLYGON_LOGIN_REQUEST)

export const polygonLoginSuccess = (payload: any): TAllTasksList => {
  return action(POLYGON_LOGIN_SUCCESS, payload)
}

export const polygonLoginError = (payload?: any): TAllTasksList =>
  action(POLYGON_LOGIN_ERROR, payload)

export const polygonOfferSuccess = (payload: any): TAllTasksList => {
  return action(POLYGON_OFFER_SUCCESS, payload)
}
