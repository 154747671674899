import { getInvoices, getUsersWithBanks } from './Entrepreneurs.thunk'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import EntrepreneursComponent from './Entrepreneurs'

export const mapStateToProps = (state: TState) => {
  return {
    invoicesArray: state.admin.entrepreneurs.invoices,
    loading: state.admin.entrepreneurs.loading,
    users: state.admin.entrepreneurs.users
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInvoices,
      getUsersWithBanks
    },
    dispatch
  )

export const Entrepreneurs = connect(
  mapStateToProps,
  mapDispatchToProps
)(EntrepreneursComponent)
