/* eslint-disable no-console */
import { Dispatch } from 'redux'
import { API, api } from '../../../services/api'
import {
  getOptionsRequest,
  getOptionsSuccess,
  getUsersRequest,
  getUsersSuccess
} from './CreateInvoice.actions'
import { AxiosError } from 'axios'
import { transformApiResToFabricFormat } from 'utils/transformApiResToFabricFormat'
import { TState } from 'store'

export function getUsers() {
  return async function(dispatch: Dispatch) {
    dispatch(getUsersRequest())
    try {
      const resp = await api.get(`${API.URL}/users`)
      dispatch(getUsersSuccess(resp.data.payload.results))
      return resp.data.payload.results
    } catch (error) {
      const err = error as AxiosError<any>
      console.log(err)
    }

    return
  }
}

export function getRankPosition() {
  return async function(dispatch: Dispatch, getData: () => TState) {
    const words = getData().global.language.words
    dispatch(getOptionsRequest())
    try {
      const positions = (await api.get(`${API.URL}${API.POSITIONS}`)).data.payload.result.map(
        (item: any) => transformApiResToFabricFormat(words, item)
      )

      const rank = (await api.get(`${API.URL}${API.RANK}`)).data.payload.result.map((item: any) =>
        transformApiResToFabricFormat(words, item)
      )

      dispatch(getOptionsSuccess({ positions, rank }))
    } catch (err) {
      console.log(err)
    }
  }
}

export const setProjectInvoice = async (
  body: any
): Promise<{ errorMessage: string | null; success: boolean }> => {
  try {
    await api.post(`${API.URL}/project-invoice`, body)
    return { errorMessage: null, success: true }
  } catch (err) {
    const error = err as AxiosError<any>
    const { response } = error
    let errorMessage = 'Something went wrong'

    if (response && response.status === 400) {
      errorMessage = 'Bad request'
    } else if (response && response.status === 401) {
      errorMessage = 'Unauthorized. Please check your credentials.'
    } else if (response && response.status === 500) {
      errorMessage = 'Server error. Please try again later.'
    }

    return { errorMessage, success: false }
  }
}
