import { TBaseFilterConfigData } from 'components/UiKit/Filter/Filter'
import {
  FILTER_SELECT_ACCAUNT_STATUS,
  FILTER_SELECT_STATUS,
  FILTER_SELECT_TYPE
} from 'globalConfigs'
import { TSelectOption } from '../../../components/Select'
import { TWords } from 'elements/SideBar/SideBar.config'

export const getFilterConfig = ({
  role,
  usersData = [],
  managersData = [],
  minFromDate,
  maxToDate,
  words
}: {
  role: number
  usersData?: TSelectOption[]
  managersData?: TSelectOption[]
  minFromDate: string | number
  maxToDate: string | number
  words: TWords
}): TBaseFilterConfigData[] => {
  const currentFormatMinFromDate = new Date(minFromDate).valueOf()
  const currentFormatMaxToDate = new Date(maxToDate).valueOf()
  switch (role) {
    case 1:
    case 2: {
      return [
        {
          name: 'authorId',
          type: 'select',
          fieldData: [{ value: '@ALL@', label: words['all'] }, ...usersData],
          label: words['roles.user'],
          placeholder: '',
          width: '19.3%'
        },
        {
          name: 'reporterId',
          type: 'select',
          fieldData: [{ value: '@ALL@', label: words['all'] }, ...managersData],
          label: words['roles.manager'],
          placeholder: '',
          width: '19.3%'
        },
        {
          name: 'requestType',
          type: 'select',
          fieldData: FILTER_SELECT_TYPE(words),
          label: words['manager.dayOffTracker.details.requestType'],
          placeholder: '',
          width: '11.3%'
        },
        {
          name: 'fromDate',
          secondName: 'toDate',
          type: 'dateRange',
          label: words['manager.dayOffTracker.details.requestDate'],
          fieldData: {
            from: currentFormatMinFromDate,
            to: currentFormatMaxToDate
          },
          width: 90
        },
        {
          name: 'status',
          type: 'select',
          fieldData: FILTER_SELECT_STATUS(words),
          label: words['user.profile.table.status'],
          placeholder: '',
          width: '12%'
        },
        {
          name: 'isActive',
          type: 'select',
          fieldData: FILTER_SELECT_ACCAUNT_STATUS(words),
          label: words['admin.table.accauntStatus'],
          placeholder: '',
          width: '12%'
        }
      ]
    }
    case 3: {
      return [
        {
          name: 'authorId',
          type: 'select',
          fieldData: [{ value: '@ALL@', label: words['all'] }, ...usersData],
          label: words['roles.user'],
          placeholder: words['admin.users.table.Edit.name'],
          width: '25%'
        },
        {
          name: 'requestType',
          type: 'select',
          fieldData: FILTER_SELECT_TYPE(words),
          label: words['manager.dayOffTracker.details.requestType'],
          placeholder: words['manager.dayOffTracker.details.requestType'],
          width: '15%'
        },
        {
          name: 'fromDate',
          secondName: 'toDate',
          type: 'dateRange',
          label: words['manager.dayOffTracker.details.requestDate'],
          fieldData: {
            from: currentFormatMinFromDate,
            to: currentFormatMaxToDate
          },
          width: 100
        },
        {
          name: 'status',
          type: 'select',
          fieldData: FILTER_SELECT_STATUS(words),
          label: words['user.profile.table.status'],
          placeholder: words['user.profile.table.status'],
          width: '15%'
        }
      ]
    }
    case 4:
    default: {
      return [
        {
          name: 'requestType',
          type: 'select',
          fieldData: FILTER_SELECT_TYPE(words),
          label: words['manager.dayOffTracker.details.requestType'],
          placeholder: words['manager.dayOffTracker.details.requestType'],
          width: '28.4%'
        },
        {
          name: 'fromDate',
          secondName: 'toDate',
          type: 'dateRange',
          label: words['manager.dayOffTracker.details.requestDate'],
          fieldData: {
            from: currentFormatMinFromDate,
            to: currentFormatMaxToDate
          },
          width: '50%'
        },
        {
          name: 'status',
          type: 'select',
          fieldData: FILTER_SELECT_STATUS(words),
          label: words['user.profile.table.status'],
          placeholder: words['user.profile.table.status'],
          width: '20%'
        }
      ]
    }
  }
}
