import { TBaseFilterConfigData } from 'components/UiKit/Filter/Filter'
import {
  ETransactionHistory,
  FILTER_SELECT_STATUS_FOR_USER,
  TYPES_TRANSACTIONS
} from 'globalConfigs'
import { TSelectOption } from '../../../../../components/Select'
import { TWords } from 'elements/SideBar/SideBar.config'

export const getFilterConfig = ({
  type,
  priceFrom,
  priceTo,
  dateFrom,
  dateTo,

  payers,
  receivers,
  words
}: {
  type: string | (string | null)[] | null | undefined
  priceFrom: number
  priceTo: number
  dateFrom: Date | string | number
  dateTo: Date | string | number
  payers: TSelectOption[]
  receivers: TSelectOption[]
  words: TWords
}): TBaseFilterConfigData[] => {
  const currentFormatMinFromDate = new Date(dateFrom).valueOf()
  const currentFormatMaxToDate = new Date(dateTo).valueOf()
  const TYPES = TYPES_TRANSACTIONS(words)

  switch (type) {
    case ETransactionHistory.ACHIEVEMENTS: {
      return [
        {
          name: 'name',
          type: 'input',
          label: `${words['user.profile.table.initiative']}:`,
          placeholder: `${words['user.pointsSystem.market.table.name']}...`,
          width: '28.5%'
        },
        {
          name: 'status',
          type: 'select',
          fieldData: FILTER_SELECT_STATUS_FOR_USER(words),
          // fieldData: FILTER_SELECT_STATUS_FOR_USER,
          label: words['user.profile.table.status'],
          placeholder: words['user.profile.table.status'],
          width: '11.3%'
        },
        {
          name: 'dateFrom',
          secondName: 'dateTo',
          type: 'dateRange',
          label: `${words['manager.dayOffTracker.details.requestDate']}`,
          fieldData: {
            from: currentFormatMinFromDate,
            to: currentFormatMaxToDate
          },
          width: 110
        },
        {
          name: 'priceFrom',
          secondName: 'priceTo',
          type: 'inputRange',
          fieldData: { minValue: priceFrom, maxValue: priceTo },
          label: `${words['user.profile.table.reward']}:`,
          width: 80
        }
      ]
    }
    case ETransactionHistory.TRANSACTIONS: {
      return [
        {
          name: 'payerID',
          type: 'select',
          fieldData: [
            { value: '@ALL@', label: words['all'] },
            { value: '0', label: words['user.profile.table.sender.system'] },
            ...payers
          ],
          label: `${words['user.profile.table.sender']}`,
          placeholder: '',
          width: '17%'
        },
        {
          name: 'receiverID',
          type: 'select',
          fieldData: [{ value: '@ALL@', label: words['all'] }, ...receivers],
          label: `${words['user.profile.table.receiver']}`,
          placeholder: '',
          width: '17%'
        },
        {
          name: 'kind',
          type: 'select',
          fieldData: [{ value: '@ALL@', label: words['all'] }, ...TYPES],
          label: `${words['admin.productionCalendar.new.type']}`,
          placeholder: '',
          width: '11%'
        },
        {
          name: 'dateFrom',
          secondName: 'dateTo',
          type: 'dateRange',
          label: `${words['user.profile.table.requestDate']}`,
          fieldData: {
            from: currentFormatMinFromDate,
            to: currentFormatMaxToDate
          },
          width: 90
        },
        {
          name: 'priceFrom',
          secondName: 'priceTo',
          type: 'inputRange',
          fieldData: { minValue: priceFrom, maxValue: priceTo },
          label: `${words['user.profile.table.sum']}`,
          width: 70
        }
      ]
    }
    case ETransactionHistory.GOODS:
    default: {
      return [
        {
          name: 'name',
          type: 'input',
          label: `${words['user.profile.table.goodsService']}:`,
          placeholder: `${words['user.pointsSystem.market.table.name']}...`,
          width: '22.7%'
        },
        {
          name: 'status',
          type: 'select',
          fieldData: FILTER_SELECT_STATUS_FOR_USER(words),
          label: `${words['user.profile.table.status']}`,
          placeholder: `${words['user.profile.table.status']}`,
          width: '17%'
        },
        {
          name: 'dateFrom',
          secondName: 'dateTo',
          type: 'dateRange',
          label: `${words['manager.dayOffTracker.details.requestDate']}`,
          fieldData: {
            from: currentFormatMinFromDate,
            to: currentFormatMaxToDate
          },
          width: 110
        },
        {
          name: 'priceFrom',
          secondName: 'priceTo',
          type: 'inputRange',
          fieldData: { minValue: priceFrom, maxValue: priceTo },
          label: `${words['user.profile.table.price']}:`,
          width: 80
        }
      ]
    }
  }
}
