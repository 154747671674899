export const CHANGE_TIME_STATUS = 'CHANGE_TIME_STATUS'
export const SET_DATE = 'SET_DATE'
export const SEND_INTERVIEW_INVITATION_LOADING = 'SEND_INTERVIEW_REQUEST_LOADING'
export const SEND_INTERVIEW_INVITATION_SUCCESS = 'SEND_INTERVIEW_REQUEST_SUCCESS'
export const SEND_INTERVIEW_INVITATION_ERROR = 'SEND_INTERVIEW_INVITATION_ERROR'
export const GET_TIME_REQUEST = 'GET_TIME_REQUEST'
export const GET_TIME_SUCCESS = 'GET_TIME_SUCCESS'
export const GET_TIME_ERROR = 'GET_TIME_ERROR'

export const SET_FIX_DATA = 'SET_FIX_DATA'
export const GET_DATA_REQUEST = 'SET_DATA_REQUEST'
export const GET_DATA_SUCCESS = 'SET_DATA_SUCCESS'
export const GET_DATA_ERROR = 'SET_DATA_ERROR'

export const GET_INVITED_USER_REQUEST = 'GET_INVITED_USER_REQUEST'
export const GET_INVITED_USER_SUCCESS = 'GET_INVITED_USER_SUCCESS'
