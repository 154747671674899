export const GET_TASK_REQUEST = 'GET_TASK_REQUEST'
export const GET_TASK_SUCCESS = 'GET_TASK_SUCCESS'
export const GET_TASK_ERROR = 'GET_TASK_ERROR'
export const CLEAR_TASK_LIST = 'CLEAR_TASK_LIST'
export const CLEAR_ONE_TASK = 'CLEAR_ONE_TASK'
export const CLEAR_ALL_TASKS = 'CLEAR_ALL_TASKS'
export const GET_USERS = 'GET_USERS'
export const GET_ONE_TASK = 'GET_ONE_TASK'
export const UPDATE_SUCCES = 'UPDATE_SUCCES'
export const CLEAR_USERS_LIST = 'CLEAR_USERS_LIST'
export const CHANGE_TASK_STATUS = 'CHANGE_TASK_STATUS'
export const GET_PROPOSAL = 'GET_PROPOSAL'
export const GET_PROPOSAL_REQUEST = 'GET_PROPOSAL_REQUEST'

export const CREATE_INVOICE = 'CREATE_INVOICE'
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS'
export const CLEAR_INVOICE = 'CLEAR_INVOICE'

export const GET_CANCELLED_TASKS_REQUEST = 'GET_CANCELLED_TASKS_REQUEST'
export const GET_CANCELLED_TASKS_SUCCESS = 'GET_CANCELLED_TASKS_SUCCESS'
