import { Dispatch } from 'redux'
import { API, api } from '../../../services/api'
import { AxiosError } from 'axios'
import {
  deleteEntrepreneurInvoice,
  getInvoicesError,
  getInvoicesRequest,
  getInvoicesSuccess,
  getUsersRequest,
  getUsersSuccess,
  getUsersError
} from './Entrepreneurs.actions'

export const getUsersWithBanks = () => async (dispatch: Dispatch) => {
  dispatch(getUsersRequest())
  try {
    const {
      data: { payload: usersPayload }
    } = await api.get(`${API.CLIENT}?bank=notNull&limit=2000&page=1`)
    const {
      data: { payload: banksPayload }
    } = await api.get(`${API.URL}${API.BANKS}`)
    usersPayload.banks = banksPayload
    dispatch(getUsersSuccess(usersPayload))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(getUsersError(err))
  }
}

export const getInvoices = (search: string = '') => async (dispatch: Dispatch) => {
  dispatch(getInvoicesRequest())
  try {
    const { data } = await api.get(`${API.ENTREPRENEUR_INVOICES}?${search}`)
    dispatch(getInvoicesSuccess(data))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(getInvoicesError(err))
  }
}

export const deleteInvoice = (id: string) => async (dispatch: Dispatch) => {
  try {
    await api.delete(`${API.ENTREPRENEUR_INVOICES}/${id}`)
    dispatch(deleteEntrepreneurInvoice(id))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(getInvoicesError(err))
  }
}
