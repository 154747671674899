import React, { useState } from 'react'
import DeleteIcon from '../../../../../assets/images/delete_icon.svg'
import styles from './DeleteInvoice.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from '../../../../../store'
import { deleteInvoice } from '../../Entrepreneurs.thunk'
const DeleteInvoice = ({ invoice }: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const words = useSelector((state: TState) => state.global.language.words)
  const dispatch = useDispatch()
  return (
    <div onClick={(e: any) => e.stopPropagation()} className={styles.deleteButton}>
      <img onClick={() => setIsModalVisible(!isModalVisible)} src={DeleteIcon} alt="delete" />
      {isModalVisible && (
        <div className={styles.deleteModal}>
          <div className={styles.triangle} />
          <span className={styles.deleteModalTitle}>{words['entrepreneur.invoices.delete']}</span>
          <div className={styles.deleteModalButtons}>
            <span
              onClick={() => dispatch(deleteInvoice(invoice.id))}
              className={styles.confirmButton}
            >
              Да
            </span>
            <span onClick={() => setIsModalVisible(false)} className={styles.rejectButton}>
              Нет
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default DeleteInvoice
