import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import UserVerifiinfoComponent from './UserVerifiinfo'
import { getUserProfileInfo } from 'screens/client/Profile/Profile.thunx'
import { getClaims, verifiUser } from './UserVerifiinfo.thunk'
import { createOffer } from '../VerificationAdmin.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    user: state.client.profile.user,
    loading: state.client.profile.loading,
    loadingOffer: state.admin.verification.loading,
    error: state.client.profile.error,
    offerId: state.admin.verification.offerId,
    qrcode: state.admin.verification.qrcode,
    claimsList: state.admin.verification.claimsList
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      verifiUser,
      getUserProfileInfo,
      createOffer,
      getClaims
    },
    dispatch
  )

export const UserVerifiInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserVerifiinfoComponent)
