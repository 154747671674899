import React, { FC } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import styles from './style.module.scss'

export type TButton = {
  name?: string
  type?: any
  onClick?: (event: object) => void
  disabled?: boolean
  className?: any
  content?: any
  children: React.ReactNode
}

const Button: FC<TButton & RouteComponentProps> = ({
  name,
  type,
  className,
  children,
  disabled,
  onClick,
  ...rest
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { staticContext: _, ...props } = rest

  return (
    <button
      name={name}
      type={type}
      className={styles[className]}
      disabled={disabled}
      onClick={onClick && onClick}
      {...props}
    >
      {children}
    </button>
  )
}
export default withRouter(Button)
