import { toggleTokenActive } from 'helpers/toggleTokenActive'
import { ETokenActions, ETokenStatus } from './Token.constans'
import { ITokenInitialState } from './Token.model'
import { IAction } from 'models'

export const TokenInitialState: ITokenInitialState = {
  data: {
    adminActivatedTokens: [],
    adminDeactivatedTokens: [],
    userActivatedTokens: [],
    userDeactivatedTokens: []
  },
  selectedToken: null,
  allTokens: [],
  onlyAdminTokens: [],
  errorMessage: '',
  loading: false
}

export default (state = TokenInitialState, action: IAction) => {
  switch (action.type) {
    case ETokenActions.GET_ALL_TOKENS:
      return {
        ...state,
        allTokens: action.payload
      }

    case ETokenActions.GET_TOKEN_REQUEST:
      return {
        ...state,
        loading: true
      }

    case ETokenActions.SELECT_TOKEN: {
      return {
        ...state,
        selectedToken: action.payload
      }
    }

    case ETokenActions.GET_TOKEN_REQUEST_SUCCESS:
      const onlyAdminTokens = [
        ...action.payload.adminActivatedTokens,
        ...action.payload.adminDeactivatedTokens
      ]
      return {
        ...state,
        data: action.payload,
        onlyAdminTokens
      }

    case ETokenActions.CHANGE_ADMIN_TOKEN_STATUS:
      const updatedTokens = toggleTokenActive(state.onlyAdminTokens, action.payload.id)

      return {
        ...state,
        onlyAdminTokens: updatedTokens
      }

    case ETokenActions.CHANGE_USER_TOKEN_STATUS:
      const { token, status } = action.payload
      const deactivatedTokens = state.data.userDeactivatedTokens
      const activatedTokens = state.data.userActivatedTokens
      let updatedDeactivatedTokens = deactivatedTokens
      let updatedActivatedTokens = activatedTokens

      if (status === ETokenStatus.ACTIVATE) {
        updatedDeactivatedTokens = deactivatedTokens.filter(t => t !== token)
        updatedActivatedTokens = [...activatedTokens, token]
      } else {
        updatedActivatedTokens = activatedTokens.filter(t => t !== token)
        updatedDeactivatedTokens = [...deactivatedTokens, token]
      }

      const updatedData = {
        ...state.data,
        userActivatedTokens: updatedActivatedTokens,
        userDeactivatedTokens: updatedDeactivatedTokens
      }

      return { ...state, data: updatedData }

    case ETokenActions.ADD_USER_TOKEN:
      const newToken = action.payload

      if (!newToken.isAdminToken) {
        const newUserTokens = [...state.data.userActivatedTokens, newToken]
        const newData = { ...state.data, userActivatedTokens: newUserTokens }
        return { ...state, data: newData }
      }

      const newAdminTokens = [...state.onlyAdminTokens, newToken]
      const newAllTokens = [...state.allTokens, newToken]
      return { ...state, onlyAdminTokens: newAdminTokens, allTokens: newAllTokens }

    default:
      return state
  }
}
