import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import styles from '../CreateTask.module.scss'
import queryString from 'query-string'
import { Input } from '../../../../../../components/UiKit/Inputs'
import { Field } from 'react-final-form'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store'
import { config } from 'globalConfigs'
import { ToggleButton } from 'components/UiKit/Button/ToggleButton'
import { timesOption, getDefaultWeek } from '../initialvalues'
import { getSelectOptions } from 'screens/client/EditProfile/EditProfile.thunx'
import { Select as NewUIKitSelect } from 'Select' // TODO -- rename alias
import { components } from 'react-select'
import { ReactComponent as TimeIcon } from 'assets/images/time_icon.svg'
import { ReactComponent as TimeUnselect } from 'assets/images/timeUnselect.svg'
import history from 'utils/history'
import { Icons } from 'components/Icons'
import { FileView } from 'components/Profile/PersonalInfo/FileView'
import TokenChangeModal from 'components/SubcontractModals/TokenChangeModal'
import { TokenField } from 'components/TokenField'

export type TInputArg = {
  name: string
  onBlur: (event?: React.FocusEvent<any> | undefined) => void
  onChange: (event: React.ChangeEvent<any>) => void
  onFocus: (event?: React.FocusEvent<any> | undefined) => void
  value: any
  checked?: boolean | undefined
}

const CreateType: FC<any> = ({
  form,
  dataForTableDefault,
  setDataForTableDefault,
  formState,
  files,
  setFiles,
  avatar,
  setAvatar,
  specialization,
  level,
  onSelectFile,
  deleteFile,
  fetchTokens,
  tokens,
  user,
  tokensLoading,
  changeUserTokenStatusThunk,
  addToken,
  fetchOneToken,
  getSelectedToken,
  selectedToken
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const dispatch = useDispatch()
  const parsed = queryString.parse(history.location.search)
  const DefaultWeek = getDefaultWeek(words)
  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)
  const inpOnChange = (input: TInputArg, event: React.ChangeEvent<HTMLInputElement>) => {
    input.onChange(event)
  }

  useEffect(() => {
    fetchTokens(user.id)
  }, [])

  useEffect(() => {
    dispatch(getSelectOptions())
    setFixedPrice(
      formState.initialValues.isCustomerPrice === true
        ? true
        : formState.initialValues.isCustomerPrice
    )
    setDataForTableDefault(
      parsed.taskId
        ? formState.initialValues.schedule
          ? formState.initialValues.schedule
          : {} || DefaultWeek
        : DefaultWeek
    )
  }, [formState.initialValues.schedule])

  const [fixedPrice, setFixedPrice] = useState(
    formState.initialValues.isCustomerPrice === undefined
      ? false
      : !formState.initialValues.isCustomerPrice
  )

  const [fixedDate, setFixedDate] = useState(
    formState.initialValues.fixedDeadline === undefined
      ? false
      : formState.initialValues.fixedDeadline
  )

  const [vacancy, setVacancy] = useState([{ id: 1 }])

  const [modalState, setModalState] = useState(false)

  const selectTimeStyle = {
    container: (style: any) => ({
      ...style,
      width: '90px'
    }),
    singleValue: (style: any) => ({
      ...style,
      top: '43%'
    }),
    indicatorsContainer: (style: any) => ({ ...style, div: { paddingBottom: '14px' } })
  }

  const DropownIndicator = (props: PropsWithChildren<any>) => {
    return (
      <components.DropdownIndicator {...props}>
        <TimeIcon />
      </components.DropdownIndicator>
    )
  }

  const DisabledIndicator = (props: PropsWithChildren<any>) => {
    return (
      <components.DropdownIndicator {...props}>
        <TimeUnselect />
      </components.DropdownIndicator>
    )
  }

  const deleteVacancy = (idx: number, choosen: any = files) => {
    const newVacancyArr = [...choosen]
    newVacancyArr.splice(idx - 1, 1)
    setVacancy(newVacancyArr)
  }

  const openModal = () => {
    setModalState(!modalState)
  }

  return (
    <>
      {parsed.taskType === '1' ? (
        <>
          <section className={styles['personal-info']}>
            <h2>{words['user.subcontract.task.payment']}</h2>
            <div className={styles.budget}>
              <div style={{ display: 'flex' }}>
                <FieldFactory
                  disabled={fixedPrice}
                  form={form}
                  config={[
                    {
                      style: {
                        width: '148px'
                      },
                      items: [
                        {
                          name: `price${!fixedPrice ? '' : 'Dis'}`,
                          label: words['user.subcontract.task.budget'],
                          required: !fixedPrice,
                          isNumber: true,
                          inputWrapperClassName: styles['budget-task'],
                          component: () => {
                            return {
                              type: 'input',
                              props: {
                                variant: 'outlined'
                              }
                            }
                          }
                        }
                      ]
                    }
                  ]}
                  words={words}
                />
                <TokenField disabled={fixedPrice} token={selectedToken} openModal={openModal} />
              </div>
              <div className={styles.check}>
                <p className={styles.tax}>
                  {words['user.subcontract.task.total']}: <span>3300 USD</span>
                </p>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className={styles.checkboxes}>
                <Field name={'isCustomerPrice'} type="checkbox">
                  {({ input }) => (
                    <Input
                      step="1"
                      variant="outlined"
                      type="checkbox"
                      placeholder="hel"
                      {...input}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFixedPrice(!fixedPrice)
                        inpOnChange(input, event)
                      }}
                    />
                  )}
                </Field>
                <label>{words['user.subcontract.task.noPricePerformers']}</label>
              </div>

              <div className={styles.checkboxes}>
                <Field name={'isRepeatable'} type="checkbox">
                  {({ input }) => (
                    <Input
                      step="1"
                      variant="outlined"
                      type="checkbox"
                      placeholder="hel"
                      {...input}
                    />
                  )}
                </Field>
                <label>{words['user.createType.repeatedTask']}</label>
              </div>
            </div>
          </section>
          <section className={styles['personal-info']}>
            <h2>{words['user.subcontract.task.schedule']}</h2>
            {Object.entries(dataForTableDefault).map((item: any, index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                  key={index}
                >
                  <h3
                    style={{
                      width: '150px',
                      fontWeight: '700px',
                      fontSize: '16px',
                      lineHeight: '26px',
                      color: '#3737ED'
                    }}
                  >
                    {item[1].day}
                  </h3>
                  <ToggleButton
                    disabled={item[1].active}
                    index={index}
                    checked={item[1].active}
                    onCheng={() => {
                      setDataForTableDefault({
                        ...dataForTableDefault,
                        [item[0]]: { ...item[1], active: !item[1].active }
                      })
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    key={item[0]}
                  >
                    <div style={{ width: '90px' }}>
                      <Field name={item[0]}>
                        {({ input }) => (
                          <NewUIKitSelect
                            name={input.name}
                            options={timesOption}
                            value={item[1].from}
                            styles={selectTimeStyle}
                            components={{
                              DropdownIndicator: !item[1].active
                                ? DisabledIndicator
                                : DropownIndicator
                            }}
                            isDisabled={!item[1].active}
                            emptyMessage={words['noOption']}
                            onChange={(event: any) => {
                              const newDates = {
                                ...dataForTableDefault[item[0]]
                              }
                              const setted = { ...newDates, from: event }
                              setDataForTableDefault({
                                ...dataForTableDefault,
                                [item[0]]: setted
                              })
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    <div
                      style={{
                        width: '8px',
                        height: '1px',
                        backgroundColor: '#333333',
                        margin: '0px 8px'
                      }}
                    />
                    <div style={{ width: '90px' }} className={item[0]}>
                      <Field name={item[0]}>
                        {({ input }) => (
                          <NewUIKitSelect
                            name={input.name}
                            options={timesOption}
                            value={item[1].to}
                            styles={selectTimeStyle}
                            components={{
                              DropdownIndicator: !item[1].active
                                ? DisabledIndicator
                                : DropownIndicator
                            }}
                            // isDisabled={!isAbsent && !isRemote}
                            isDisabled={!item[1].active}
                            emptyMessage={words['noOption']}
                            onChange={(event: any) => {
                              const newDates = {
                                ...dataForTableDefault[item[0]]
                              }
                              const setted = { ...newDates, to: event }
                              setDataForTableDefault({
                                ...dataForTableDefault,
                                [item[0]]: setted
                              })
                              // console.log(event)
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
              )
            })}
            <TokenChangeModal
              toggle={modalState}
              action={openModal}
              tokens={tokens}
              user={user}
              changeUserTokenStatusThunk={changeUserTokenStatusThunk}
              tokensLoading={tokensLoading}
              addToken={addToken}
              fetchOneToken={fetchOneToken}
              getSelectedToken={getSelectedToken}
            />
          </section>
        </>
      ) : parsed.taskType === '2' ? (
        <section className={styles['personal-info']}>
          <h2>{words['user.subcontract.task.budgetAndDeadline']}</h2>
          <div className={styles.budget}>
            <div style={{ display: 'flex' }}>
              <FieldFactory
                disabled={fixedPrice}
                form={form}
                config={[
                  {
                    style: {
                      width: '90px',
                      marginRight: '20px'
                    },
                    items: [
                      {
                        name: `workingHours${!fixedPrice ? '' : 'Dis'}`,
                        label: words['user.subcontract.task.hoursCount'],
                        required: !fixedPrice,
                        inputWrapperClassName: styles['budget-task-hour'],
                        component: () => {
                          return {
                            type: 'input',
                            props: {
                              variant: 'outlined',
                              type: 'number'
                            }
                          }
                        }
                      }
                    ]
                  }
                ]}
                words={words}
              />
              <FieldFactory
                disabled={fixedPrice}
                form={form}
                config={[
                  {
                    style: {
                      width: '152px',
                      marginRight: '-2px'
                    },
                    items: [
                      {
                        name: `pricePerHours${!fixedPrice ? '' : 'Dis'}`,
                        label: words['user.subcontract.task.rateHour'],
                        required: !fixedPrice,
                        inputWrapperClassName: styles['budget-task'],
                        component: () => {
                          return {
                            type: 'input',
                            props: {
                              variant: 'outlined',
                              type: 'number'
                            }
                          }
                        }
                      }
                    ]
                  }
                ]}
                words={words}
              />
              <TokenField disabled={fixedPrice} token={selectedToken} openModal={openModal} />
            </div>
            <div className={styles.check}>
              <p className={styles.tax}>
                {words['user.subcontract.task.total']}: <span>1220 USD</span>
              </p>
            </div>
          </div>
          <div className={styles.checkboxes}>
            <Field name={'isCustomerPrice'} type="checkbox">
              {({ input }) => (
                <Input
                  step="1"
                  variant="outlined"
                  type="checkbox"
                  placeholder="hel"
                  {...input}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFixedPrice(!fixedPrice)
                    inpOnChange(input, event)
                  }}
                />
              )}
            </Field>
            <label>{words['user.subcontract.task.noPrice']}</label>
          </div>

          <div className={styles.checkboxes}>
            <Field name={'isRepeatable'} type="checkbox">
              {({ input }) => (
                <Input step="1" variant="outlined" type="checkbox" placeholder="hel" {...input} />
              )}
            </Field>
            <label>{words['user.createType.repeatedTask']}</label>
          </div>

          <div style={{ display: 'flex' }}>
            <Field name={'planning'} type="checkbox">
              {({ input }) => (
                <Input
                  step="1"
                  variant="outlined"
                  type="checkbox"
                  placeholder="hel"
                  {...input}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    inpOnChange(input, event)
                  }}
                />
              )}
            </Field>
            <label>{words['user.subcontract.task.planning']}</label>
          </div>

          <TokenChangeModal
            toggle={modalState}
            action={openModal}
            tokens={tokens}
            user={user}
            changeUserTokenStatusThunk={changeUserTokenStatusThunk}
            tokensLoading={tokensLoading}
            addToken={addToken}
            fetchOneToken={fetchOneToken}
            getSelectedToken={getSelectedToken}
          />
        </section>
      ) : parsed.taskType === '3' ? (
        //Fixed Price
        <section className={styles['personal-info']}>
          <h2>{words['user.subcontract.task.budgetAndDeadline']}</h2>
          <div className={styles.budget}>
            <div style={{ display: 'flex' }}>
              <FieldFactory
                // currentLanguage={currentLanguage}
                disabled={fixedPrice}
                form={form}
                config={[
                  {
                    style: {
                      width: '148px'
                    },
                    items: [
                      {
                        name: `price${!fixedPrice ? '' : 'Dis'}`,
                        label: words['user.subcontract.task.budget'],
                        required: !fixedPrice,
                        inputWrapperClassName: styles['budget-task'],
                        component: () => {
                          return {
                            type: 'input',
                            props: {
                              variant: 'outlined',
                              type: 'number'
                              // style: { borderRadius: '10px 0px 0px 10px' }
                            }
                          }
                        }
                      }
                    ]
                  }
                ]}
                words={words}
              />
              <TokenField disabled={fixedPrice} token={selectedToken} openModal={openModal} />
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={styles.checkboxes}>
              <Field name={'isCustomerPrice'} type="checkbox">
                {({ input }) => (
                  <Input
                    step="1"
                    variant="outlined"
                    type="checkbox"
                    placeholder="hel"
                    {...input}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFixedPrice(!fixedPrice)
                      inpOnChange(input, event)
                    }}
                  />
                )}
              </Field>
              <label>{words['user.subcontract.task.noPrice']}</label>
            </div>

            <div className={styles.checkboxes}>
              <Field name={'isRepeatable'} type="checkbox">
                {({ input }) => (
                  <Input step="1" variant="outlined" type="checkbox" placeholder="hel" {...input} />
                )}
              </Field>
              <label>{words['user.createType.repeatedTask']}</label>
            </div>
          </div>

          <FieldFactory
            currentLanguage={currentLanguage}
            disabled={fixedDate}
            form={form}
            config={[
              {
                style: {
                  marginBottom: '0px'
                },
                items: [
                  {
                    name: 'deadline',
                    label: words['user.subcontract.task.orderDelivery'],
                    required: false,
                    inputWrapperClassName: styles['deadline'],
                    component: ({ input }: any) => {
                      return {
                        type: 'datePicker',
                        props: {
                          autoComplete: 'off',
                          dateFormat: config.dateFormat,
                          selected: input.value ? new Date(input.value) : null
                        }
                      }
                    }
                  }
                ]
              }
            ]}
            words={words}
          />
          <div style={{ display: 'flex' }}>
            <Field name={'fixedDeadline'} type="checkbox">
              {({ input }) => (
                <Input
                  step="1"
                  variant="outlined"
                  type="checkbox"
                  placeholder="hel"
                  {...input}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFixedDate(!fixedDate)
                    inpOnChange(input, event)
                  }}
                />
              )}
            </Field>
            <label>{words['user.subcontract.task.openEndDate']}</label>
          </div>
          <TokenChangeModal
            toggle={modalState}
            action={openModal}
            tokens={tokens}
            user={user}
            changeUserTokenStatusThunk={changeUserTokenStatusThunk}
            tokensLoading={tokensLoading}
            addToken={addToken}
            fetchOneToken={fetchOneToken}
            getSelectedToken={getSelectedToken}
          />
        </section>
      ) : (
        // Project-based
        <>
          <section>
            <div className={styles['main-info-project']}>
              <section className={styles['add-files-block']}>
                {avatar && avatar.length > 0 ? (
                  <>
                    <img src={avatar[0].link} className={styles['add-image-arria']} />
                    <button
                      onClick={() => {
                        deleteFile(avatar[0], 0, avatar, setAvatar)
                      }}
                    >
                      delete
                    </button>
                  </>
                ) : (
                  <label htmlFor="addAvatar" className={styles['add-image-arria']}>
                    <label htmlFor="addAvatar">
                      <Icons icon="addBlue" />
                      <p>
                        {words['user.subcontract.task.avatarFormat']}
                        <br />
                        {words['user.subcontract.task.avatarScale']}
                        <br />
                        {words['user.subcontract.task.avatarWeight']}
                      </p>
                      <input
                        id="addAvatar"
                        type="file"
                        // accept="image/*, .pdf"
                        onChange={(e: any) => {
                          onSelectFile(e, avatar, setAvatar)
                        }}
                      />
                    </label>
                  </label>
                )}
              </section>
              <div className={styles['info-field']}>
                <FieldFactory
                  form={form}
                  config={[
                    {
                      items: [
                        {
                          name: 'title',
                          label: words['user.subcontract.task.name'],
                          required: true,
                          inputWrapperClassName: styles['head-task'],
                          component: () => {
                            return {
                              type: 'input',
                              props: {
                                variant: 'outlined'
                              }
                            }
                          }
                        }
                      ]
                    }
                  ]}
                  words={words}
                />
                <FieldFactory
                  form={form}
                  config={[
                    {
                      items: [
                        {
                          name: 'description',
                          label: words['user.subcontract.task.description'],
                          required: true,
                          inputWrapperClassName: styles['description-task'],
                          component: () => {
                            return {
                              type: 'textarea',
                              props: {}
                            }
                          }
                        }
                      ]
                    }
                  ]}
                  words={words}
                />
              </div>
            </div>
          </section>
          <section className={styles['personal-info']}>
            <h2>{words['user.subcontract.task.basicInfo']}</h2>
            <div className={styles['basic-info']}>
              <div>
                <div className={styles.dates}>
                  <FieldFactory
                    currentLanguage={currentLanguage}
                    disabled={fixedDate}
                    form={form}
                    config={[
                      {
                        style: {
                          marginBottom: '0px'
                        },
                        items: [
                          {
                            name: 'startProjectDate',
                            label: words['user.subcontract.task.projectStart'],
                            required: false,
                            inputWrapperClassName: styles['deadline'],
                            component: ({ input }: any) => {
                              return {
                                type: 'datePicker',
                                props: {
                                  autoComplete: 'off',
                                  dateFormat: config.dateFormat,
                                  selected: input.value ? new Date(input.value) : null
                                }
                              }
                            }
                          }
                        ]
                      }
                    ]}
                    words={words}
                  />
                  <FieldFactory
                    currentLanguage={currentLanguage}
                    disabled={fixedDate}
                    form={form}
                    config={[
                      {
                        style: {
                          marginBottom: '0px'
                        },
                        items: [
                          {
                            name: 'deadline',
                            label: words['user.subcontract.task.orderDelivery'],
                            required: false,
                            inputWrapperClassName: styles['deadline'],
                            component: ({ input }: any) => {
                              return {
                                type: 'datePicker',
                                props: {
                                  autoComplete: 'off',
                                  dateFormat: config.dateFormat,
                                  selected: input.value ? new Date(input.value) : null
                                }
                              }
                            }
                          }
                        ]
                      }
                    ]}
                    words={words}
                  />
                </div>
                <FieldFactory
                  form={form}
                  config={[
                    {
                      style: {
                        marginBottom: '0px'
                      },
                      items: [
                        {
                          name: 'devStage',
                          label: words['user.subcontract.task.devStage'],
                          required: false,
                          inputWrapperClassName: styles['stage'],
                          component: () => {
                            return {
                              type: 'select',
                              props: {
                                placeholder: '',
                                options: true
                                  ? [{ value: '1', label: 'Пример' }]
                                  : [{ value: '1', label: 'Loading...' }]
                              }
                            }
                          }
                        }
                      ]
                    }
                  ]}
                  words={words}
                />
              </div>
              <div className={styles['dates-area']}>
                <FieldFactory
                  form={form}
                  config={[
                    {
                      items: [
                        {
                          name: 'teamStructure',
                          label: words['user.subcontract.task.teamStructure'],
                          required: true,
                          inputWrapperClassName: styles['description-task'],
                          component: () => {
                            return {
                              type: 'textarea',
                              props: {}
                            }
                          }
                        }
                      ]
                    }
                  ]}
                  words={words}
                />
              </div>
            </div>
            <FieldFactory
              // currentLanguage={currentLanguage}
              form={form}
              config={[
                {
                  items: [
                    {
                      name: 'techStack',
                      label: words['user.subcontract.task.techStack'],
                      required: true,
                      inputWrapperClassName: styles['description-task'],
                      component: () => {
                        return {
                          type: 'textarea',
                          props: {}
                        }
                      }
                    }
                  ]
                }
              ]}
              words={words}
            />
          </section>
          <section className={styles['personal-info']}>
            <h2>{words['user.subcontract.task.payment']}</h2>
            <div className={styles.budget}>
              <div style={{ display: 'flex' }}>
                <FieldFactory
                  disabled={fixedPrice}
                  form={form}
                  config={[
                    {
                      style: {
                        width: '152px'
                      },
                      items: [
                        {
                          name: `price${!fixedPrice ? '' : 'Dis'}`,
                          label: words['user.subcontract.task.paymentPerMonth'],
                          required: !fixedPrice,
                          isNumber: true,
                          inputWrapperClassName: styles['budget-task'],
                          component: () => {
                            return {
                              type: 'input',
                              props: {
                                variant: 'outlined'
                              }
                            }
                          }
                        }
                      ]
                    }
                  ]}
                  words={words}
                />
                <TokenField disabled={fixedPrice} token={selectedToken} openModal={openModal} />
              </div>
              <div className={styles.check}>
                <p className={styles.tax}>
                  {words['user.subcontract.task.total']}: <span>3300 USD</span>
                </p>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className={styles.checkboxes}>
                <Field name={'isCustomerPrice'} type="checkbox">
                  {({ input }) => (
                    <Input
                      step="1"
                      variant="outlined"
                      type="checkbox"
                      placeholder="hel"
                      {...input}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFixedPrice(!fixedPrice)
                        inpOnChange(input, event)
                      }}
                    />
                  )}
                </Field>
                <label>{words['user.subcontract.task.noPricePerformers']}</label>
              </div>

              <div className={styles.checkboxes}>
                <Field name={'isRepeatable'} type="checkbox">
                  {({ input }) => (
                    <Input
                      step="1"
                      variant="outlined"
                      type="checkbox"
                      placeholder="hel"
                      {...input}
                    />
                  )}
                </Field>
                <label>{words['user.createType.repeatedTask']}</label>
              </div>
            </div>
          </section>
          <section className={styles['personal-info']}>
            <h2>{words['user.subcontract.task.vacancies']}</h2>
            {/* <div className={styles['vacancy']}> */}
            {vacancy.map((item, i) => {
              return (
                <div key={item.id}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FieldFactory
                      // currentLanguage={currentLanguage}
                      // disabled={fixedPrice}
                      form={form}
                      config={[
                        {
                          style: {
                            width: '423px',
                            marginRight: '32px'
                          },
                          items: [
                            {
                              name: `jobTitle${item.id}`,
                              label: words['user.subcontract.task.position'],
                              required: true,
                              component: () => {
                                return {
                                  type: 'select',
                                  props: {
                                    placeholder: '',
                                    options: specialization
                                  }
                                }
                              }
                            }
                          ]
                        }
                      ]}
                      words={words}
                    />
                    <FieldFactory
                      // currentLanguage={currentLanguage}
                      // disabled={fixedPrice}
                      form={form}
                      config={[
                        {
                          style: {
                            width: '272px',
                            marginRight: '32px'
                          },
                          items: [
                            {
                              name: `jobLevel${item.id}`,
                              label: words['user.subcontract.task.level'],
                              required: true,
                              component: () => {
                                return {
                                  type: 'select',
                                  props: {
                                    placeholder: '',
                                    options: level
                                  }
                                }
                              }
                            }
                          ]
                        }
                      ]}
                      words={words}
                    />
                    <FieldFactory
                      // currentLanguage={currentLanguage}
                      // disabled={fixedPrice}
                      form={form}
                      config={[
                        {
                          style: {
                            width: '135px',
                            height: '88.39px'
                          },
                          items: [
                            {
                              name: `procentVacancy${item.id}`,
                              label: words['user.subcontract.task.percentOfTotal'],
                              required: !fixedPrice,
                              isNumber: true,
                              inputWrapperClassName: styles['budget-task'],
                              component: () => {
                                return {
                                  type: 'input',
                                  props: {
                                    variant: 'outlined'
                                  }
                                }
                              }
                            }
                          ]
                        }
                      ]}
                      words={words}
                    />
                    <Icons
                      className={styles['delete_vacancy']}
                      icon={'cross'}
                      onClick={() => {
                        deleteVacancy(i + 1, vacancy)
                      }}
                    />
                  </div>
                  <FieldFactory
                    // currentLanguage={currentLanguage}
                    form={form}
                    config={[
                      {
                        style: {
                          width: '894px'
                        },
                        items: [
                          {
                            name: `descriptionVacancy${item.id}`,
                            label: words['user.subcontract.task.vacancyDescription'],
                            required: true,
                            inputWrapperClassName: styles['description-task'],
                            component: () => {
                              return {
                                type: 'textarea',
                                props: {}
                              }
                            }
                          }
                        ]
                      }
                    ]}
                    words={words}
                  />
                </div>
              )
            })}
            <Icons
              className={styles['add_vacancy']}
              icon={'addBlue'}
              onClick={() => {
                setVacancy([
                  ...vacancy,
                  vacancy.length === 0 ? { id: 1 } : { id: vacancy[vacancy.length - 1].id + 1 }
                ])
              }}
            />
            {/* </div> */}
          </section>
          <section className={styles['personal-info']}>
            <h2>{words['user.createType.projectWorkSchedule']}</h2>
            {Object.entries(dataForTableDefault).map((item: any, index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                  key={item[0]}
                >
                  <h3
                    style={{
                      width: '150px',
                      fontWeight: '700px',
                      fontSize: '16px',
                      lineHeight: '26px',
                      color: '#3737ED'
                    }}
                  >
                    {item[1].day}
                  </h3>
                  <ToggleButton
                    disabled={item[1].active}
                    index={index}
                    checked={item[1].active}
                    onCheng={() => {
                      setDataForTableDefault({
                        ...dataForTableDefault,
                        [item[0]]: { ...item[1], active: !item[1].active }
                      })
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <div style={{ width: '90px' }}>
                      <Field name={item[0]}>
                        {({ input }) => (
                          <NewUIKitSelect
                            name={input.name}
                            options={timesOption}
                            value={item[1].from}
                            styles={selectTimeStyle}
                            components={{
                              DropdownIndicator: !item[1].active
                                ? DisabledIndicator
                                : DropownIndicator
                            }}
                            isDisabled={!item[1].active}
                            emptyMessage={words['noOption']}
                            onChange={(event: any) => {
                              const newDates = {
                                ...dataForTableDefault[item[0]]
                              }
                              const setted = { ...newDates, from: event }
                              setDataForTableDefault({
                                ...dataForTableDefault,
                                [item[0]]: setted
                              })
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    <div
                      style={{
                        width: '8px',
                        height: '1px',
                        backgroundColor: '#333333',
                        margin: '0px 8px'
                      }}
                    />
                    <div style={{ width: '90px' }} key={index} className={item[0]}>
                      <Field name={item[0]}>
                        {({ input }) => (
                          <NewUIKitSelect
                            name={input.name}
                            options={timesOption}
                            value={item[1].to}
                            styles={selectTimeStyle}
                            components={{
                              DropdownIndicator: !item[1].active
                                ? DisabledIndicator
                                : DropownIndicator
                            }}
                            // isDisabled={!isAbsent && !isRemote}
                            isDisabled={!item[1].active}
                            emptyMessage={words['noOption']}
                            onChange={(event: any) => {
                              const newDates = {
                                ...dataForTableDefault[item[0]]
                              }
                              const setted = { ...newDates, to: event }
                              setDataForTableDefault({
                                ...dataForTableDefault,
                                [item[0]]: setted
                              })
                              // console.log(event)
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
              )
            })}
          </section>
          <section className={styles['personal-info']}>
            <h2>{words['user.subcontract.task.personalInfo']}</h2>
            <div className={styles.column}>
              <FieldFactory
                // currentLanguage={currentLanguage}
                form={form}
                config={[
                  {
                    items: [
                      {
                        name: 'companyName',
                        label: words['user.subcontract.task.fullCompanyName'],
                        required: true,
                        inputWrapperClassName: styles['head-task'],
                        component: () => {
                          return {
                            type: 'input',
                            props: {
                              variant: 'outlined'
                            }
                          }
                        }
                      }
                    ]
                  }
                ]}
                words={words}
              />
              <FieldFactory
                // currentLanguage={currentLanguage}
                form={form}
                config={[
                  {
                    items: [
                      {
                        name: 'companyNumber',
                        label: words['user.subcontract.task.companyNumber'],
                        required: true,
                        inputWrapperClassName: styles['head-task'],
                        component: () => {
                          return {
                            type: 'input',
                            props: {
                              variant: 'outlined'
                            }
                          }
                        }
                      }
                    ]
                  }
                ]}
                words={words}
              />
            </div>
            <div className={styles.column}>
              <FieldFactory
                // currentLanguage={currentLanguage}
                form={form}
                config={[
                  {
                    items: [
                      {
                        name: 'companyAddress',
                        label: words['user.subcontract.task.companyAddress'],
                        required: true,
                        inputWrapperClassName: styles['head-task'],
                        component: () => {
                          return {
                            type: 'input',
                            props: {
                              variant: 'outlined'
                            }
                          }
                        }
                      }
                    ]
                  }
                ]}
                words={words}
              />
              <FieldFactory
                // currentLanguage={currentLanguage}
                form={form}
                config={[
                  {
                    items: [
                      {
                        name: 'companyEmail',
                        label: 'Email',
                        required: true,
                        inputWrapperClassName: styles['head-task'],
                        component: () => {
                          return {
                            type: 'input',
                            props: {
                              variant: 'outlined'
                            }
                          }
                        }
                      }
                    ]
                  }
                ]}
                words={words}
              />
            </div>
          </section>
          <section className={styles['personal-info']}>
            <section className={styles['add-files-block']} style={{ marginTop: '0px' }}>
              <h3 style={{ marginTop: '0px' }}>{words['user.subcontract.task.files']}</h3>
              {files &&
                files.length > 0 &&
                files.map((item: any, idx: number) => (
                  <FileView
                    isEdit
                    item={item}
                    idx={idx}
                    deleteFile={deleteFile}
                    choosen={files}
                    setChoosen={setFiles}
                    key={String(idx)}
                  />
                ))}
              <label htmlFor="addFile" className={styles['add-file-arria']}>
                <label htmlFor="addFile">
                  <Icons icon="add" />
                  <input
                    id="addFile"
                    type="file"
                    // accept="image/*, .pdf"
                    onChange={(e: any) => onSelectFile(e, files, setFiles)}
                  />
                </label>
              </label>
            </section>
            <TokenChangeModal
              toggle={modalState}
              action={openModal}
              tokens={tokens}
              user={user}
              changeUserTokenStatusThunk={changeUserTokenStatusThunk}
              tokensLoading={tokensLoading}
              addToken={addToken}
              fetchOneToken={fetchOneToken}
              getSelectedToken={getSelectedToken}
            />
          </section>
          {/* </section> */}
        </>
      )}
    </>
  )
}

export default CreateType
