import React, { FC } from 'react'
import { withRouter, Route, Redirect } from 'react-router-dom'
import { TPrivateRouter } from './PrivatRoute.model'
import { isAdmin, isSuperAdmin } from 'utils/user'
import { ADMIN_PATH, USER_PATH } from 'elements/App/App.constants'

const PrivateRoute: FC<TPrivateRouter> = ({
  isAuthenticated,
  component: Component,
  path,
  role,

  languageObject,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      path={path}
      component={(props: any) =>
        isAuthenticated ? (
          <Component
            {...props}
            role={role}
            successPath={isSuperAdmin(role) || isAdmin(role) ? ADMIN_PATH : USER_PATH}
            languageObject={languageObject}
          />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  )
}
export default withRouter(PrivateRoute)
