import { action } from 'store/store.utils'
import { TAllVacanciesList } from './Vacancies.model'
import {
  ACTION_VACANCIES_REQUEST,
  GET_VACANCIES_SUCCESS,
  SET_PROJECT_OPTIONS,
  SET_VACANCY_SUCCESS,
  SET_STATUS_OPTIONS,
  UPDATE_VACANCY_SUCCESS
} from './Vacancies.constants'

export const getVacancyRequest = () => action(ACTION_VACANCIES_REQUEST)
export const getVacanciesSuccess = (payload: any): TAllVacanciesList =>
  action(GET_VACANCIES_SUCCESS, payload)

export const getVacancySuccess = (payload: any) => action(SET_VACANCY_SUCCESS, payload)
export const setProjectOptions = (projects: any) => action(SET_PROJECT_OPTIONS, projects)
export const setStatusOptions = (statuses: any) => action(SET_STATUS_OPTIONS, statuses)

export const updateVacancySuccess = (vacancy: any) => action(UPDATE_VACANCY_SUCCESS, vacancy)
