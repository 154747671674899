import React, { FC } from 'react'
import MUISlider from '@mui/material/Slider'
import { TSliderProps, TSliderVariants } from './Slider.model'
import gradientStyle from './gradient.module.scss'
import defaultStyle from './default.module.scss'
import cls from 'classnames'
import { formatBigNumbers } from 'helpers/formatBigNumbers'

export const Slider: FC<TSliderProps> = ({
  className,
  sliderVariant = 'default',
  isValuesShows = false,
  valuesCurrrency = null,
  ...props
}) => {
  const { value, max } = props
  const currencyCode = isValuesShows && valuesCurrrency ? valuesCurrrency : null

  let sliderStyle
  switch (sliderVariant) {
    case 'default':
      sliderStyle = defaultStyle
      break

    case 'gradient':
      sliderStyle = gradientStyle
      break

    default:
      sliderStyle = defaultStyle
      break
  }

  return (
    <div className={cls(sliderStyle.sliderContainer, className)}>
      <MUISlider classes={sliderStyle} {...props} />
      {isValuesShows && (
        <div className={sliderStyle.sliderContainerValues}>
          <span className={sliderStyle.sliderValueCurrent}>
            {formatBigNumbers(value as number)}
            {currencyCode && ` ${currencyCode}`}
          </span>
          {sliderVariant === TSliderVariants.gradient && (
            <span>
              {'\u00A0'}/{'\u00A0'}
            </span>
          )}
          <span className={sliderStyle.sliderValueMax}>
            {formatBigNumbers(max as number)}
            {currencyCode && ` ${currencyCode}`}
          </span>
        </div>
      )}
    </div>
  )
}
