import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'
import { getTask } from '../../Subcontract.thunk'
import CreateTaskComponent from './CreateTask'
import { taskCreatorReq, updateTaskInfo } from './CreateTask.thunk'
import { signTaskThunk } from 'screens/client/Web3/web3.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    taskInfo: state.client.subcontract.task,
    level: state.client.editProfile.rank,
    error: state.client.createTask.error,
    loading: state.client.createTask.loading,
    taskLoading: state.client.subcontract.loading,
    selectedToken: state.admin.token.selectedToken
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      taskCreatorReq,
      // getTaskInfo,
      getTask,
      updateTaskInfo,
      signTaskThunk
    },
    dispatch
  )

export const CreateTask = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTaskComponent)
