import React, { FC, useState } from 'react'
import { TState } from 'store'
import { useSelector } from 'react-redux'
import styles from './EditProjectBtn.module.scss'
import { ReactComponent as DotsBtn } from 'assets/images/icon_dots_vertical.svg'
import { RestoreOrDeleteProjectModal } from '../ProjectModals/RestoreOrDeleteProjectModal'

type TEditMenuProps = {
  projectId: number
  deleted: Date | null
  deleteAction: () => any
  restoreAction: () => any
  history: any
}

export const EditProjectBtn: FC<TEditMenuProps> = ({
  projectId,
  deleted,
  deleteAction,
  restoreAction,
  history
}) => {
  const [open, setOpen] = useState(false)
  const [modalIsOpen, setModalOpen] = useState(false)
  const words = useSelector((state: TState) => state.global.language.words)

  const toggleOpen = () => setOpen(!open)
  const toggleModal = () => setModalOpen(!modalIsOpen)
  return (
    <>
      <div className={styles.container}>
        {deleted && <div className={styles['archived-sign']}>{words['project.archived']}</div>}
        {open && <div className={styles.overlay} onClick={toggleOpen} />}
        <button className={styles['edit-btn']} onClick={toggleOpen} type="button">
          <DotsBtn />
        </button>
        {open && (
          <ul className={styles.menu}>
            <li onClick={() => history.push(`/dashboard/projects/${projectId}/edit`)}>
              {words['project.edit']}
            </li>
            <li onClick={toggleModal}>
              {deleted ? words['project.restore'] : words['project.toArchive']}
            </li>
            <li onClick={() => history.push(`/dashboard/projects/${projectId}/history`)}>
              {words['project.historyOfChanges']}
            </li>
          </ul>
        )}
      </div>

      {/* <DeactivateUserModal toggleOpenDeactivateModal={toggleOpenDeactivateModal} userId={projectId} /> */}
      <RestoreOrDeleteProjectModal
        projectId={projectId}
        isOpen={modalIsOpen}
        deleted={deleted}
        toggleModal={toggleModal}
        action={deleted ? restoreAction : deleteAction}
      />
    </>
  )
}
