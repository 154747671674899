import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { VotingDetailsComponent } from './VotingDetails'
import { getVoting, vote } from '../../VotingList.thunk'
import { TState } from 'store'

export const mapStateToProps = (state: TState) => {
  return { voting: state.client.voting.selectVoting }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getVoting,
      vote
    },
    dispatch
  )

export const VotingDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(VotingDetailsComponent)
