import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Icons, TIcon } from 'components/Icons'

import { ISliderProps } from './Slider.model'

import './Slider.scss'
import 'swiper/css'
import 'swiper/css/navigation'

// prettier-ignore
const Slider = <T,>({
  className = '',
  options,
  nextClass = 'next',
  prevClass = 'prev',
  prevIcon = prevClass,
  nextIcon = nextClass,
  slide: Slide,
  slideInfo,
}: ISliderProps<T>) => {
  const customPrevButton = (
    <button className={prevClass}>
      <Icons icon={prevIcon as TIcon} />
    </button>
  )

  const customNextButton = (
    <button className={nextClass}>
      <Icons icon={nextIcon as TIcon} />
    </button>
  )

  return (
    <>
      <Swiper
        navigation={{ prevEl: `.${prevClass}`, nextEl: `.${nextClass}` }}
        {...options}
        className={className}
      >
        {slideInfo.map((slide: any) => {
          return (
            <SwiperSlide key={slide.id}>
              <Slide {...slide} className={'swiper-slide'}/>
            </SwiperSlide>
          )
        })}
      </Swiper>

      <div className={'swiper-nav-bts'}>
        {customPrevButton}
        {customNextButton}
      </div>
    </>
  )
}
export { Slider }
