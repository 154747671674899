import React, { useEffect } from 'react'
import { TProjectInvoicesProps } from './ProjectInvoices.model'
import styles from './ProjectInvoices.module.scss'
import { Icons } from 'components/Icons'
import { Filter } from '../../../components/UiKit/Filter'
import { getFilterConfig } from './ProjectInvoices.filter.config'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import Table from './Table'

const ProjectInvoices = ({
  getInvoices,
  removeInvoice,
  displayAllFields,
  invoices,
  getProjectOptions,
  projectOptions
}: TProjectInvoicesProps) => {
  const words = useSelector((state: TState) => state.global.language.words)
  useEffect(() => {
    getInvoices(`${location.search}`)
    getProjectOptions()
  }, [])
  useEffect(() => {
    getInvoices(`${location.search}`)
  }, [location.search])
  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>{words['projectInvoices.invoices']}</h1>
      </section>
      <div className={styles['history-changes']}>
        <button className={styles['history-button']}>
          {' '}
          <Icons icon="timeIcon" /> {words['project.history']}
        </button>
      </div>
      <section className={styles['filter']}>
        <Filter
          config={getFilterConfig({
            displayAllFields,
            projects: projectOptions,
            words
          })}
          isMultilineBtn={true}
          withButton={false}
          defaultOpened={true}
        />
      </section>
      <section className={styles['table']}>
        <Table invoices={invoices} getInvoices={getInvoices} removeInvoice={removeInvoice} />
      </section>
    </div>
  )
}
export default ProjectInvoices
