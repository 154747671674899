export enum ESettingsActions {
  REQUEST_STARTED = 'REQUEST_STARTED',
  GET_BOT_SETTINGS = 'GET_BOT_SETTINGS',
  GET_EXCHANGE_INFO = 'GET_EXCHANGE_INFO',
  GET_POSITIONS_INFO = 'GET_POSITIONS_INFO',
  GET_GRAPH_STATISTIC = 'GET_GRAPH_STATISTIC',
  GET_BOT_EXCHANGE_TYPE = 'GET_BOT_EXCHANGE_TYPE',
  GET_BOT_INFO = 'GET_BOT_INFO',
  GET_INSTANCE = 'GET_INSTANCE'
}
