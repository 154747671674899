import {
  IChannelSettings,
  IDefaultSettings,
  TExchangeType,
  TStrategyType
} from 'screens/client/CryptoBot/components/ControlPanel/ControlPanel.types'
import { applyChannelDefaultValues, applyExchangeDefaultValues } from './applyDefaultValues'

export const handleDefaultChannelsValues = (channels: IChannelSettings[]): IChannelSettings[] => {
  return channels.map(channel => applyChannelDefaultValues(channel))
}

export const handleDefaultBotSettings = ({
  defaultSettings,
  exchangeType,
  strategyType
}: {
  defaultSettings: IDefaultSettings
  exchangeType: TExchangeType | null
  strategyType: TStrategyType | null
}) => {
  const updatedSettings: IDefaultSettings = structuredClone(defaultSettings)
  const strategyKey = strategyType ? strategyType : Object.keys(updatedSettings)[0]
  const exchangeKey = exchangeType ? exchangeType : Object.keys(updatedSettings[strategyKey])[0]
  const setting = updatedSettings[strategyKey][exchangeKey]
  setting.exchanges = applyExchangeDefaultValues(setting.exchanges)
  setting.channels = handleDefaultChannelsValues(setting.channels)
  return setting
}
