import React, { FC } from 'react'
import { ReactComponent as DefaultAvatar } from 'assets/images/default_avatar.svg'
import styles from './NotificationDetails.module.scss'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { INotificationsItem } from '../../Notifications.model'
import { useSelector } from 'react-redux'
import { TState } from 'store'

type Property = {
  notification: INotificationsItem | null
  onDelete: (id: number) => void
  onClose: () => void
}

const NotificationDetails: FC<Property> = ({ notification, onDelete, onClose }) => {
  const words = useSelector((state: TState) => state.global.language.words)

  return (
    <div className={styles['container']}>
      {notification ? (
        <>
          <div className={styles['header']}>
            <DefaultAvatar className={styles['header-avatar']} />
            <div className={styles['header-author']}>{notification.sender.fullName}</div>
          </div>
          <div className={styles['body']}>
            <div className={styles['details-title']}>{notification.title}</div>
            <div className={styles['details-text']}>{notification.message}</div>
          </div>
          <div className={styles['footer']}>
            <BaseButton onClick={onClose}>{words['component.notifications.close']}</BaseButton>
            <BaseButton
              color="red"
              onClick={() => {
                onDelete(notification.id)
              }}
            >
              {words['component.deleteInvoice.delete']}
            </BaseButton>
          </div>
        </>
      ) : (
        <div className={styles['empty-block']}>
          <div className={styles['text']}>{words['component.notifications.emptyDetails']}</div>
        </div>
      )}
    </div>
  )
}

export { NotificationDetails }
