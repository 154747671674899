import { Column } from 'react-table'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import React from 'react'
import DownloadInvoice from '../../DownloadInvoice/DownloadInvoice'
import DeleteInvoice from '../../DeleteInvoice/DeleteInvoice'
import moment from 'moment'
import styles from '../../../screens/client/Profile/components/HistoryTransaction/HistoryTransaction.module.scss'
import SelectInvoice from './SelectInvoice/SelectInvoice'

export type TTeamData = {
  id: string
  period: string
  start: string
  end: string
  totalAmount: string
  paymentDate: string
  formationDate: string
  discount: string
  usersDates: any
}

export const Columns = () => {
  const words = useSelector((state: TState) => state.global.language.words)
  const columns: Array<Column<TTeamData>> = [
    {
      Header: d => <SelectInvoice invoice={d} name={'selectAll'} />,
      id: 'check',
      accessor: d => <SelectInvoice name={d.id} invoice={d} />,
      maxWidth: 40
    },
    {
      Header: words['projects.invoices.duration'],
      id: 'period',
      accessor: ({ start, end }) => (
        <span className={styles.general}>
          {moment(start).format('DD.MM.YYYY') + ' - '} {moment(end).format('DD.MM.YYYY')}
        </span>
      ),
      minWidth: 300
    },
    {
      Header: words['projects.invoices.countOfPeople'],
      id: 'userDates',
      accessor: ({ usersDates }) => <span className={styles.general}>{usersDates.length}</span>,
      minWidth: 300
    },
    {
      Header: words['projects.invoices.totalAmount'],
      id: 'totalAmount',
      accessor: 'totalAmount',
      minWidth: 200
    },
    {
      Header: words['projects.invoices.paymentDate'],
      id: 'paymentDate',
      accessor: ({ paymentDate }) => (
        <span className={styles.general}>{moment(paymentDate).format('DD.MM.YYYY')}</span>
      )
    },
    {
      Header: words['projects.invoices.formationDate'],
      id: 'formationDate',
      accessor: ({ formationDate }) => (
        <span className={styles.general}>{moment(formationDate).format('DD.MM.YYYY')}</span>
      ),
      minWidth: 200
    },
    {
      Header: words['projects.invoices.discount'],
      id: 'discount',
      accessor: ({ discount }) => (
        <span className={styles.general}>{discount ? discount : 'Без скидки'}</span>
      ),
      minWidth: 200
    },
    {
      Header: '',
      id: 'file',
      accessor: d => {
        // eslint-disable-next-line react/react-in-jsx-scope
        return <DownloadInvoice d={d} />
      },
      maxWidth: 50
    },
    {
      Header: '',
      id: 'delete',
      accessor: d => {
        // eslint-disable-next-line react/react-in-jsx-scope
        return <DeleteInvoice invoice={d} />
      },
      maxWidth: 50
    }
  ]

  return columns
}
