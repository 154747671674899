import moment from "moment";

export const getInitialValues = (vacancy: any): any => {
  const initialValues: any = {}

  // eslint-disable-next-line prettier/prettier
  initialValues.title = String(vacancy?.title?.id)
  initialValues.levelOfEnglish = vacancy?.englishLevel?.id
  initialValues.typeOfEmployment = String(vacancy?.typeOfEmployment?.id)
  initialValues.technologies = vacancy.technologies
  initialValues.project = String(vacancy?.project?.id)
  initialValues.status = vacancy.status
  initialValues.rank = String(vacancy?.rank?.id)
  initialValues.interviewer = vacancy.interviewer
  initialValues.additionalRequirements = vacancy.additionalRequirements
  initialValues.isTestExist = vacancy.isTestExist
  initialValues.participationPeriod = vacancy.participationPeriod
  initialValues.countOfHours = vacancy.countOfHours
  initialValues.recruiter = vacancy.recruiter
  initialValues.location = vacancy.location
  initialValues.dateOfClosing = moment(vacancy.dateOfClosing).format('MM/DD/YYYY')
  return initialValues
}
