import { connect } from 'react-redux'
import AuthRoute from './AuthRoute'
import { Dispatch, bindActionCreators } from 'redux'
import { login, signup } from '../../screens/auth/Login/Login.thunx'
import { TState } from 'store'

export const mapStateToProps = (state: TState) => ({
  user: state.auth.data,
  role: state.auth.role
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      login,
      signup
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthRoute)
