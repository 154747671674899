import React, { FC, useEffect } from 'react'
// import { RouteComponentProps } from 'react-router-dom'
import styles from './Filter.module.scss'
import { Field, Form } from 'react-final-form'
import { Button, DischargeButton } from 'components/UiKit/Button'
import { Input } from '../Inputs'
import { FormApi } from 'final-form'
import { Select as NewUIKitSelect } from 'Select' // TODO -- rename alias
import { DatePicker } from 'components/UiKit/DatePicker'
import cls from 'classnames'
import { config } from 'globalConfigs'
import history from 'utils/history'
import queryString from 'query-string'
import { TSelectOption } from '../../Select'
import { useHistory } from 'react-router'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { ButtonFilter } from '../../ButtonFilter'
import { mapDispatchToProps, mapStateToProps } from './Filter.container'
import { useSelector } from 'react-redux'
import { TWords } from 'elements/SideBar/SideBar.config'
import { TState } from 'store'
import { debounce } from 'lodash'
import { newValueForQuery } from './Helpers/newValueForQuery'
import { components } from 'react-select'
import { Icons } from 'components/Icons'

// const location = window.location.toString()

export type TOwnProps = {
  config: TBaseFilterConfigData[]
  withButton?: boolean
  defaultOpened?: boolean
  withSubmitButton?: boolean
  page?: string
  currentNameComponent?: string
  isMultilineBtn?: boolean
  onlyField?: boolean
}

export type TBaseFilterConfigData = {
  name: string // for DateRange name must start with "date"
  secondName?: string // for double fields exm: 'dateRange' || 'inputRange'
  type:
    | 'input'
    | 'select'
    | 'asyncSelect'
    | 'dateRange'
    | 'inputRange'
    | 'selectRange'
    | 'date'
    | 'dateRangeNew'
    | 'multiSelect'
  label?: string
  fieldData?: any
  placeholder?: string
  inputType?: 'text' | 'number'
  width?: string | number
  rangeContainerWidth?: string | number
  defaultOpened?: boolean
  withSubmitButton?: boolean
  loadOptions?: (value?: string | undefined) => Promise<TSelectOption[]>
  words?: TWords
  emptyMessage?: string
  isTimeIndicator?: boolean
  isDisabled?: boolean
  inputContainerClass?: string
  selectRangeBoundsIndex?: any
  hasIcons?: boolean
}
export type TBaseFilterProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  TOwnProps

export type TInputArg = {
  name: string
  onBlur: (event?: React.FocusEvent<any> | undefined) => void
  onChange: (event: React.ChangeEvent<any>) => void
  onFocus: (event?: React.FocusEvent<any> | undefined) => void
  value: any
  checked?: boolean | undefined
}

export type TInputData = { elem?: queryString.ParsedQuery<string> | undefined }

// TODO --- move to utils
export const formatterDateForDatePicker = (date: Date | number | null): Date | number | null => {
  return date ? date.valueOf() : null
}
export const formatterDateForDatePickerToString = (date: Date | number | null): string | null => {
  return date ? moment(date).format('YYYY-MM-DD') : null
}
export const addCurrentTime = (date: Date | number | null): Date | number | null =>
  date !== null
    ? moment(
        moment(date).set({
          hour: moment().get('h'),
          minute: moment().get('m'),
          second: moment().get('s')
        })
      )
        // .utc()
        .valueOf()
    : null

const getValidNames = (arr: TBaseFilterConfigData[]): string[] =>
  arr.reduce<string[]>((result, item) => {
    if (item.secondName) {
      result.push(item.secondName)
    }
    result.push(item.name)
    return result
  }, [])

const debounceSent = debounce(func => {
  func()
}, 1000)

const inpOnChange = (input: TInputArg, event: React.ChangeEvent<HTMLInputElement>) => {
  input.onChange(event)
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icons icon="timeIcon" />
    </components.DropdownIndicator>
  )
}

const Control = (props: any) => {
  return (
    <components.Control {...props}>
      {props.getValue()[0] && (
        <div className={styles['icon']}>
          <img src={props.getValue()[0].icon} />
        </div>
      )}{' '}
      {props.children}
    </components.Control>
  )
}

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <div className={styles['option-with-icon']}>
        <div className={styles['icon']}>
          <img src={props.data.icon} />
        </div>{' '}
        {props.children}
      </div>
    </components.Option>
  )
}

const renderFilters = (
  form: FormApi,
  refresh: () => void,
  defaultOpened: boolean | undefined,
  words: TWords,
  withSubmitButton: boolean | undefined
) => ({
  name,
  secondName,
  type,
  fieldData,
  label,
  placeholder,
  width,
  rangeContainerWidth,
  inputType = 'text',
  loadOptions,
  isTimeIndicator,
  isDisabled = false,
  inputContainerClass = '',
  selectRangeBoundsIndex,
  hasIcons
}: TBaseFilterConfigData) => {
  switch (type) {
    case 'select':
      return (
        <div key={name} style={{ width }} className={styles['filter-fields']}>
          <label className={hasIcons ? styles['select-with-icons'] : ''}>
            {label}
            <Field name={name}>
              {({ input }) => {
                return (
                  <NewUIKitSelect
                    placeholder={placeholder}
                    options={fieldData && fieldData}
                    {...input}
                    defaultValue={fieldData && fieldData[0]}
                    emptyMessage={words['noOption']}
                    value={
                      fieldData &&
                      fieldData.find((element: TSelectOption) => element.value === input.value)
                    }
                    onChange={(newValue: any) => {
                      form.change(name, newValue.value)
                      if (defaultOpened && !withSubmitButton) {
                        refresh()
                      }
                    }}
                    components={
                      hasIcons
                        ? isTimeIndicator
                          ? { DropdownIndicator, Control, Option }
                          : { Control, Option }
                        : {}
                    }
                    isDisabled={isDisabled}
                  />
                )
              }}
            </Field>
          </label>
        </div>
      )
    case 'asyncSelect':
      return (
        <div key={name} style={{ width }} className={styles['filter-fields']}>
          <label>
            {label}:
            <Field name={name}>
              {({ input }) => {
                return (
                  <NewUIKitSelect
                    {...input}
                    isAsync={true}
                    placeholder={placeholder}
                    loadOptions={loadOptions}
                    emptyMessage={words['noOption']}
                    value={input.value ? { value: input.value, label: input.value } : null}
                    onChange={(event: any) => {
                      form.change(name, event.value)
                      if (defaultOpened && !withSubmitButton) {
                        refresh()
                      }
                    }}
                  />
                )
              }}
            </Field>
          </label>
        </div>
      )
    case 'inputRange':
      if (!secondName) {
        throw new Error(`For this  field type "${type}" Need props "secondName"`)
      }
      return (
        <div
          style={{ width: rangeContainerWidth }}
          key={name}
          className={cls({
            [styles['filter-fields']]: true,
            [styles['filter-range']]: true,
            [inputContainerClass]: inputContainerClass
          })}
        >
          <label>{label}</label>
          <div>
            <div style={{ width }}>
              <Field name={name}>
                {({ input }) => (
                  <Input
                    step="1"
                    variant="outlined"
                    type={inputType}
                    placeholder={fieldData.minValue}
                    {...input}
                    onChange={event => {
                      inpOnChange(input, event)
                      if (!withSubmitButton) {
                        debounceSent(refresh)
                      }
                    }}
                  />
                )}
              </Field>
            </div>
            <span className={styles['range-span']}> - </span>
            <div style={{ width }}>
              <Field name={secondName}>
                {({ input }) => (
                  <Input
                    step="1"
                    variant="outlined"
                    type={inputType}
                    placeholder={fieldData.maxValue}
                    {...input}
                    onChange={event => {
                      inpOnChange(input, event)
                      if (!withSubmitButton) {
                        debounceSent(refresh)
                      }
                    }}
                  />
                )}
              </Field>
            </div>
          </div>
        </div>
      )
    case 'dateRange':
      if (!secondName) {
        throw new Error(`For this  field type "${type}" Need props "secondName"`)
      }
      return (
        <div
          key={name + 'DataRange'}
          className={cls({
            [styles['filter-fields']]: true,
            [styles['filter-data-range']]: true
          })}
        >
          <label>{label}:</label>
          <div className={styles['data-picker-row']}>
            <span className={styles['data-picker-text']}>
              {words['manager.dayOffTracker.details.date.from']}
            </span>
            <div style={{ width }}>
              <Field name={name}>
                {({ input }) => (
                  <DatePicker
                    locale={words.language}
                    name={input.name}
                    autoComplete="off"
                    dateFormat={config.dateFormat}
                    value={input.value || fieldData.from || new Date()}
                    selected={input.value || fieldData.from || new Date()}
                    onChange={event => {
                      if (event) {
                        form.change(input.name, formatterDateForDatePicker(addCurrentTime(event)))

                        if (
                          new Date(event).valueOf() >
                          new Date(form.getState().values[secondName]).valueOf()
                        ) {
                          form.change(secondName, formatterDateForDatePicker(addCurrentTime(event)))
                        }
                      }
                      if (defaultOpened && !withSubmitButton) {
                        refresh()
                      }
                    }}
                    startDate={form.getState().values[name] || fieldData.from}
                    endDate={form.getState().values[secondName] || fieldData.to}
                    minDate={fieldData.from || new Date()}
                    maxDate={fieldData.to || new Date()}
                  />
                )}
              </Field>
            </div>
            <span
              className={cls({
                [styles['data-picker-text']]: true,
                [styles['data-picker-text-to']]: true
              })}
            >
              {words['manager.dayOffTracker.details.date.to']}
            </span>
            <div style={{ width }}>
              <Field name={secondName}>
                {({ input }) => (
                  <DatePicker
                    locale={words.language}
                    name={input.name}
                    autoComplete="off"
                    dateFormat={config.dateFormat}
                    value={input.value || fieldData.to || new Date()}
                    selected={input.value || fieldData.to || new Date()}
                    onChange={event => {
                      if (event) {
                        form.change(input.name, formatterDateForDatePicker(addCurrentTime(event)))
                      }
                      if (defaultOpened && !withSubmitButton) {
                        refresh()
                      }
                    }}
                    startDate={form.getState().values[name] || fieldData.from}
                    endDate={form.getState().values[secondName] || fieldData.to}
                    minDate={form.getState().values[name] || new Date()}
                    maxDate={fieldData.to || new Date()}
                  />
                )}
              </Field>
            </div>
          </div>
        </div>
      )
    case 'selectRange':
      if (!secondName) {
        throw new Error(`For this  field type "${type}" Need props "secondName"`)
      }
      return (
        <div
          style={{ width: rangeContainerWidth }}
          key={name}
          className={cls({
            [styles['filter-fields']]: true,
            [styles['filter-range-select']]: true
          })}
        >
          <label>{label}</label>
          <div>
            <div style={{ width }} className={styles['range-select-field']}>
              <Field name={name}>
                {({ input }) => {
                  return (
                    <NewUIKitSelect
                      placeholder={placeholder}
                      options={
                        fieldData && selectRangeBoundsIndex.max
                          ? fieldData.slice(0, selectRangeBoundsIndex.max + 2)
                          : fieldData
                      }
                      {...input}
                      defaultValue={fieldData && fieldData[0]}
                      emptyMessage={words['noOption']}
                      value={
                        fieldData &&
                        fieldData.find((element: TSelectOption) => element.value === input.value)
                      }
                      onChange={(event: any) => {
                        form.change(name, event.value)
                        if (defaultOpened && !withSubmitButton) {
                          refresh()
                        }
                      }}
                      components={isTimeIndicator ? { DropdownIndicator } : {}}
                      isDisabled={isDisabled}
                    />
                  )
                }}
              </Field>
            </div>
            <span className={styles['range-span']}> - </span>
            <div style={{ width }} className={styles['range-select-field']}>
              <Field name={secondName}>
                {({ input }) => {
                  return (
                    <NewUIKitSelect
                      placeholder={placeholder}
                      options={
                        fieldData && selectRangeBoundsIndex.min
                          ? [
                              fieldData[0],
                              ...fieldData.slice(selectRangeBoundsIndex.min + 1, fieldData.length)
                            ]
                          : fieldData
                      }
                      {...input}
                      defaultValue={fieldData && fieldData[0]}
                      emptyMessage={words['noOption']}
                      value={
                        fieldData &&
                        fieldData.find((element: TSelectOption) => element.value === input.value)
                      }
                      onChange={(event: any) => {
                        form.change(secondName, event.value)
                        if (defaultOpened && !withSubmitButton) {
                          refresh()
                        }
                      }}
                      components={isTimeIndicator ? { DropdownIndicator } : {}}
                      isDisabled={isDisabled}
                    />
                  )
                }}
              </Field>
            </div>
          </div>
        </div>
      )
    case 'input':
    default:
      return (
        <div key={name} style={{ width }} className={styles['filter-fields']}>
          <Field name={name}>
            {({ input }) => (
              <Input
                type={inputType}
                variant="outlined"
                label={label}
                placeholder={placeholder}
                {...input}
                onChange={event => {
                  inpOnChange(input, event)
                  if (!withSubmitButton) {
                    debounceSent(refresh)
                  }
                }}
              />
            )}
          </Field>
        </div>
      )
    case 'date':
      return (
        <div
          key={name}
          style={{ width }}
          className={cls({
            [styles['filter-fields']]: true
          })}
        >
          <label>{label}</label>
          <div className={styles['picker-container']}>
            <Field name={name}>
              {({ input }) => (
                <DatePicker
                  locale={words.language}
                  name={input.name}
                  autoComplete="off"
                  dateFormat={config.dateFormat}
                  value={(input.value && moment(input.value).format('DD.MM.YYYY')) || '-'}
                  onChange={event => {
                    if (event) {
                      form.change(input.name, formatterDateForDatePickerToString(event))
                    }
                    if (defaultOpened && !withSubmitButton) {
                      refresh()
                    }
                  }}
                />
              )}
            </Field>
          </div>
        </div>
      )
    case 'dateRangeNew':
      if (!secondName) {
        throw new Error(`For this  field type "${type}" Need props "secondName"`)
      }

      return (
        <div
          style={{ width: rangeContainerWidth }}
          key={name + 'DataRange'}
          className={cls({
            [styles['filter-fields']]: true,
            [styles['filter-data-range']]: true
          })}
        >
          <label>{label}:</label>
          <div className={styles['newrange-data-picker-row']}>
            <div style={{ width: '45%' }}>
              <Field name={name}>
                {({ input }) => {
                  const firstDate = form.getState().values[name]
                  const secondDate = form.getState().values[secondName]

                  console.log('secondDate', secondDate, 'newDAte', new Date(secondDate))
                  console.log(fieldData.from, fieldData.to)
                  return (
                    <DatePicker
                      locale={words.language}
                      name={input.name}
                      autoComplete="off"
                      strictParsing
                      dateFormat={config.dateFormat}
                      // dateFormat={'dd MMMM'} // todo filter for birthday
                      value={input.value || null}
                      selected={input.value || null}
                      onChange={event => {
                        if (event) {
                          const timeReset = moment(event)
                            .set({
                              hour: 0,
                              minute: 0,
                              second: 0,
                              millisecond: 0
                            })
                            .valueOf()
                          form.change(input.name, timeReset)
                        }
                        if (event === null) {
                          form.change(input.name, '')
                        }
                        if (defaultOpened && !withSubmitButton) {
                          refresh()
                        }
                      }}
                      selectsStart
                      startDate={(firstDate && new Date(firstDate)) || fieldData.from || ''}
                      endDate={(secondDate && new Date(secondDate)) || fieldData.to || ''}
                      minDate={fieldData.from || ''}
                      maxDate={(secondDate && new Date(secondDate)) || fieldData.to || ''}
                    />
                  )
                }}
              </Field>
            </div>
            <span className={styles['data-picker-dash']} />
            <div style={{ width: '40%' }}>
              <Field name={secondName}>
                {({ input }) => {
                  const firstDate = form.getState().values[name]
                  const secondDate = form.getState().values[secondName]
                  return (
                    <DatePicker
                      locale={words.language}
                      name={input.name}
                      autoComplete="off"
                      strictParsing
                      dateFormat={config.dateFormat}
                      value={input.value || null}
                      selected={input.value || null}
                      onChange={event => {
                        if (event) {
                          const timeReset = moment(event)
                            .set({
                              hour: 23,
                              minute: 59,
                              second: 59,
                              millisecond: 999
                            })
                            .valueOf()
                          form.change(input.name, formatterDateForDatePicker(timeReset))
                        }
                        if (event === null) {
                          form.change(input.name, '')
                        }
                        if (defaultOpened && !withSubmitButton) {
                          refresh()
                        }
                      }}
                      selectsEnd
                      startDate={(firstDate && new Date(firstDate)) || fieldData.from || ''}
                      endDate={(secondDate && new Date(secondDate)) || fieldData.to || ''}
                      minDate={(firstDate && new Date(firstDate)) || fieldData.from || ''}
                      maxDate={fieldData.to || ''}
                    />
                  )
                }}
              </Field>
            </div>
            <div className={styles['datepicker-icon']} />
          </div>
        </div>
      )
    case 'multiSelect':
      return (
        <div key={name} style={{ width }} className={styles['filter-fields']}>
          <label className={styles['multi-select']}>
            {label}
            <Field name={name}>
              {({ input }) => {
                return (
                  <NewUIKitSelect
                    // @ts-ignore
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder={placeholder}
                    options={fieldData && fieldData}
                    {...input}
                    defaultValue={fieldData && fieldData[0]}
                    emptyMessage={words['noOption']}
                    value={fieldData && input.value}
                    onChange={(event: any) => {
                      if (event) {
                        form.change(name, event)
                      }
                      if (!event) {
                        form.change(name, [])
                      }
                      if (defaultOpened && !withSubmitButton) {
                        refresh()
                      }
                    }}
                    components={isTimeIndicator ? { DropdownIndicator } : {}}
                    isDisabled={isDisabled}
                    styles={{
                      valueContainer: provided => ({
                        ...provided,
                        verticalAlign: 'middle',
                        minHeight: '30px'
                      }),
                      container: provided => ({
                        ...provided,
                        marginTop: '7px',
                        verticalAlign: 'middle',
                        height: 'unset'
                      }),
                      input: provided => ({
                        ...provided,
                        position: 'absolute',
                        bottom: '0px',
                        right: '0'
                      })
                    }}
                  />
                )
              }}
            </Field>
          </label>
        </div>
      )
  }
}

export const Filter: FC<TBaseFilterProps> = ({
  config: filterConfig,
  withButton,
  defaultOpened,
  openFilter,
  isOpenFilter,
  withSubmitButton,
  multilineFilterFields,
  displayAllFilterFields,
  currentNameComponent,
  isMultilineBtn,
  onlyField = true
}) => {
  const { location } = useHistory()
  const parsed: any = queryString.parse(location.search)
  const words = useSelector((state: TState) => state.global.language.words)
  let insertInitialValues = parsed

  useEffect(() => {
    if (isMultilineBtn && currentNameComponent !== multilineFilterFields.componentName) {
      displayAllFilterFields({
        componentName: currentNameComponent,
        status: false
      })
    }
  }, [currentNameComponent])

  if (isEmpty(parsed)) {
    const validKeys = filterConfig.reduce((res: TInputData, elem) => {
      if (elem.name.toLowerCase().includes('date') && elem.secondName) {
        return {
          ...res,
          [elem.name]:
            (elem.fieldData &&
              elem.fieldData.from &&
              formatterDateForDatePicker(addCurrentTime(elem.fieldData.from))) ||
            null,
          [elem.secondName]:
            (elem.fieldData &&
              elem.fieldData.to &&
              formatterDateForDatePicker(addCurrentTime(elem.fieldData.to))) ||
            null
        }
      }
      return { ...res, [elem.name]: null }
    }, {})
    insertInitialValues = { ...validKeys }
  }

  const validNames = getValidNames(filterConfig)

  for (const key in insertInitialValues) {
    if (!isEmpty(parsed) && key.toLowerCase().includes('date')) {
      insertInitialValues = {
        ...insertInitialValues,
        [key]: parsed && parsed[key] && +parsed[key]
      }
    }
    if (!isEmpty(parsed) && key === 'englishLevel') {
      insertInitialValues = {
        ...insertInitialValues,
        [key]: parsed && parsed[key] && parsed[key].length && JSON.parse(parsed[key])
      }
    }
  }
  const validInitialValues = validNames.reduce<any>((result, item) => {
    result = {
      ...result,
      [item]: insertInitialValues[item]
    }
    return result
  }, {})

  return (
    <>
      <Form
        initialValues={validInitialValues}
        onSubmit={(values: any) => {
          const oldQuery = queryString.parse(history.location.search)

          const hasOwnPropertyReverce = (key: string) => {
            return !values.hasOwnProperty(key)
          }

          const newQueryValue = Object.keys(values).reduce((res, item) => {
            return newValueForQuery(
              filterConfig,
              hasOwnPropertyReverce,
              oldQuery,
              res,
              values,
              item
            )
          }, {})
          const query = queryString.stringify(newQueryValue)
          history.push(`${history.location.pathname}?${query}`)
        }}
      >
        {({ form, handleSubmit }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className={cls({
                [styles['filter-wrapper']]: true,
                [styles['onlyField']]: !onlyField,
                [styles.hidden]: withButton && openFilter,
                [styles.showed]: withButton && openFilter
              })}
            >
              <div
                className={cls({
                  [styles['fields-container']]: true,
                  [styles['onlyField-container']]: !onlyField,
                  [styles['fields-container-for-multline']]: isMultilineBtn
                })}
              >
                {filterConfig.map(
                  renderFilters(form, handleSubmit, defaultOpened, words, withSubmitButton)
                )}
              </div>

              {onlyField && (
                <div className={styles['btns-container']}>
                  {isMultilineBtn && (
                    <div
                      className={styles['icon']}
                      onClick={() =>
                        displayAllFilterFields({
                          componentName: currentNameComponent,
                          status: !multilineFilterFields.status
                        })
                      }
                    >
                      {multilineFilterFields.status ? (
                        <Icons icon="filterOpen" />
                      ) : (
                        <Icons icon="filterClosed" />
                      )}
                    </div>
                  )}
                  {onlyField && (
                    <div>
                      <DischargeButton
                        onClick={() => {
                          const oldQuery = queryString.parse(history.location.search)
                          form.reset()

                          getValidNames(filterConfig).forEach((key: string) => {
                            delete oldQuery[key]
                          })
                          history.push(
                            `${history.location.pathname}?${queryString.stringify(oldQuery)}`
                          )
                        }}
                        className={styles['btn-reset']}
                      >
                        {words['reset']}
                      </DischargeButton>
                      {withSubmitButton ? (
                        <Button
                          type="submit"
                          variant="secondary"
                          className={styles['btn-complete']}
                        >
                          {words['apply']}
                        </Button>
                      ) : null}
                    </div>
                  )}
                </div>
              )}
            </form>
          )
        }}
      </Form>
      <section /*className={styles['btn-filter']}*/
        className={cls({
          [styles['btn-filter']]: true,
          [styles.hidden]: !withButton,
          [styles.showed]: withButton
        })}
      >
        {defaultOpened ? null : (
          <ButtonFilter isOpen={openFilter} clickOpen={() => isOpenFilter(!openFilter)} />
        )}
      </section>
    </>
  )
}
