import React, { FC, useCallback } from 'react'
import { INotificationsItem, TNotificationsProps } from './Notifications.model'
import styles from './Notifications.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store'
import { Icons } from 'components/Icons'
import { NotificationListElement } from './components/NotificationListElement/NotificationListElement'
import { NotificationDetails } from './components/NotificationDetails/NotificationDetails'
import tasks_tablet from '../../../assets/images/tasks_tablet.svg'

const NotificationsComponent: FC<TNotificationsProps> = ({
  history,
  selected,
  notifications,
  deleteNotifications,
  deleteSelected,
  viewNotification
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const dispatch = useDispatch()

  const handleDelete = useCallback((id: number) => {
    deleteNotifications(id)
    dispatch(deleteSelected())
  }, [])

  const handleClose = useCallback(() => {
    dispatch(deleteSelected())
  }, [])

  const checkIsSelected = (notification: INotificationsItem) => {
    return selected ? notification.id === selected.id : false
  }

  return (
    <div className={styles.container}>
      <section>
        <div className={styles.header}>
          <h1>{words['component.notifications.title']}</h1>
        </div>
      </section>
      <section>
        <div className={styles['block-go-back']}>
          <button
            type="button"
            className={styles['go-back']}
            onClick={event => {
              event.preventDefault()
              history.goBack()
            }}
          >
            <Icons icon="backArrowIcon" />
            <span className={styles['go-back-text']}>{words['back']}</span>
          </button>
        </div>
      </section>
      <div className={styles['container-flex']}>
        <ul className={styles['notification-list']}>
          {notifications.length === 0 ? (
            <div className={styles['empty-list']}>
              <img src={tasks_tablet} />
              <div className={styles['text']}>{words['component.notifications.emptyList']}</div>
            </div>
          ) : (
            notifications.map(el => (
              <NotificationListElement
                key={el.id}
                notification={el}
                onSelect={viewNotification}
                isSelected={checkIsSelected(el)}
              />
            ))
          )}
        </ul>
        <NotificationDetails
          notification={selected}
          onDelete={handleDelete}
          onClose={handleClose}
        />
      </div>
    </div>
  )
}

export { NotificationsComponent }
