import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import App from './elements/App'
import { store, persistor } from './store'
import history from './utils/history'
import './assets/style/_global.scss'
import 'toastr/build/toastr.min.css'
import './utils/locale/i18n'
import Spinner from './components/Spinner'
import uk from 'date-fns/locale/uk'
import ru from 'date-fns/locale/ru'
import { registerLocale } from 'react-datepicker'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { config } from 'globalConfigs'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { WagmiProvider } from 'wagmi'
import { bscTestnet } from 'wagmi/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

registerLocale('ua', uk) // Need for datepicker
registerLocale('ru', ru) // Need for datepicker
// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'))

const onRedirectCallback = (appState?: AppState) => {
  history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname)
}

// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId from https://cloud.walletconnect.com
const projectId = config.walletConnectProjectId

// 2. Create wagmiConfig
const metadata = {
  name: 'Platform',
  description: 'Platform',
  url: 'https://web3modal.com', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [bscTestnet] as const
export const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata
})

// 3. Create modal
createWeb3Modal({
  wagmiConfig,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true // Optional - false as default
})

root.render(
  // @ts-ignore
  <WagmiProvider config={wagmiConfig}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Auth0Provider
          domain={config.authDomain}
          clientId={config.authClientId}
          onRedirectCallback={onRedirectCallback}
          cacheLocation="localstorage"
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: config.authAudience,
            scope: config.authScope
          }}
        >
          <PersistGate
            loading={
              <div style={{ margin: '75px auto' }}>
                <Spinner />
              </div>
            }
            persistor={persistor}
          >
            <Router history={history}>
              <App />
            </Router>
          </PersistGate>
        </Auth0Provider>
      </Provider>
    </QueryClientProvider>
  </WagmiProvider>
)
