import { Dispatch } from 'redux'
import { API, api } from 'services/api'
import {
  setProductCalendarError,
  setProductCalendarRequest,
  setProductCalendarSuccess,
  getProductCalendarError,
  getProductCalendarRequest,
  getProductCalendarSuccess,
  deleteProductCalendarError,
  deleteProductCalendarRequest,
  deleteProductCalendarSuccess
} from './SettingsTimeOfRequest.actions'
import toastr from 'utils/toastr'
import history from 'utils/history'
import { TState } from 'store'
import { AxiosError } from 'axios'

export type TSetProductCalendarBody = {
  date: string
  description: string
  type: 1 | 2
}

export const getProductCalendar = (page: number = 1, search: string = '') => async (
  dispatch: Dispatch
) => {
  dispatch(getProductCalendarRequest())
  try {
    const { data } = await api.get(
      `${API.PRODUCT_CALENDAR}${search}${search ? '&' : '?'}page=${page}&limit=${3000}`
    )
    dispatch(getProductCalendarSuccess(data.payload))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(getProductCalendarError(err))
  }
}

export const setProductCalendar = (body: TSetProductCalendarBody[]) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(setProductCalendarRequest())
  const words = getData().global.language.words

  try {
    const { data } = await api.post(API.PRODUCT_CALENDAR, body)
    dispatch(setProductCalendarSuccess(data.payload))
    toastr('info', words['SUCCESS'])
    history.push({
      pathname: history.location.pathname,
      search: `calendar=true`
    }) // TODO -- remove. need for close modal
  } catch (error) {
    const err = error as AxiosError<any>
    toastr('error', words['FILED_UPDATE_SETTINGS'], err)
    dispatch(setProductCalendarError(err))
  }
}

export const deleteProductCalendar = (id: number) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(deleteProductCalendarRequest())
  const words = getData().global.language.words

  try {
    await api.delete(`${API.PRODUCT_CALENDAR}/${id}`)

    dispatch(deleteProductCalendarSuccess(id))
    toastr('info', words['SUCCESS_DELETED'])
  } catch (error) {
    const err = error as AxiosError<any>
    toastr('error', words['FILED_DELETE_ENTRY'], err)
    dispatch(deleteProductCalendarError(err))
  }
}
