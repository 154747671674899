/* eslint-disable no-shadow */
export enum ESubcontractTabs {
  ALL_TASKS = 'ALL_TASKS',
  MY_TASKS = 'MY_TASKS',
  MY_RESPONDS = 'MY_RESPONDS',
  EXECUTORS = 'EXECUTORS',
  // PLANNING = 'PLANNING',
  DRAFTS = 'DRAFTS'
}

export enum ESubcontractFilterFields {
  CATEGORY = 'category',
  SPECIALIZATION = 'specialization',
  RANK = 'level',
  PUBLICATION_DATE_FROM = 'publicationDateFrom',
  PUBLICATION_DATE_TO = 'publicationDateTo',
  BUDGET_FROM = 'budgetFrom',
  BUDGET_TO = 'budgetTo',
  CURRENCY = 'currency',
  SUBMITTING_DATE_FROM = 'submittingDateFrom',
  SUBMITTING_DATE_TO = 'submittingDateTo',
  STATUS = 'status',
  TECHNOLOGY = 'technology',
  USER_RANK = 'rank',
  POSITION = 'position'
}
