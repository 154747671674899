import { API, api } from 'services/api'
import { headers } from '../constants/constants'

export async function deleteExchangeSettings(endpoint: string, exchangeType: string) {
  try {
    await api.delete(`${API.URL}${endpoint}`, {
      headers,
      data: { exchange: exchangeType }
    })
  } catch (error) {
    console.log(error)
  }
}
