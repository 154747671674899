import React from 'react'
import { ReactComponent as DefaultAvatar } from 'assets/images/default_avatar.svg'
import styles from './RefundComment.module.scss'

interface IRefundComment {
  taskInfo: any
  isCustomer: boolean
}

export const RefundComment: React.FC<IRefundComment> = ({ taskInfo, isCustomer }) => {
  if (!taskInfo.taskCancellation) {
    return null
  }

  const customerPhoto = taskInfo.customer.photo ? (
    <img className={styles.avatar} src={taskInfo.customer.photo} alt="avatar" />
  ) : (
    <DefaultAvatar className={styles.avatar} />
  )

  const contractorPhoto = taskInfo.executor.photo ? (
    <img className={styles.avatar} src={taskInfo.executor.photo} alt="avatar" />
  ) : (
    <DefaultAvatar className={styles.avatar} />
  )

  const refundCustomerPercent =
    (taskInfo.price / 100) * taskInfo.taskCancellation.refundCustomerPercent

  const refundContractorPercent =
    (taskInfo.price / 100) * taskInfo.taskCancellation.refundContractorPercent

  return (
    <div className={styles.comment}>
      <div>
        <p className={styles.title}>{isCustomer ? 'Запрос заказчика' : 'Запрос исполнителя'}</p>

        <div className={styles.commentDescription}>
          <div>
            <div className={styles.userInfoWrapper}>
              <div className={styles.userPhotoWrapper}>
                {isCustomer ? customerPhoto : contractorPhoto}
              </div>

              <p className={styles.userName}>
                {isCustomer ? taskInfo.customer.fullName : taskInfo.executor.fullName}
              </p>
            </div>
          </div>

          <p className={styles.userComment}>
            {isCustomer
              ? taskInfo.taskCancellation.commentFromCustomer
              : taskInfo.taskCancellation.commentFromContractor}
          </p>
        </div>
      </div>

      <div>
        <p className={styles.priceTitle}>Раздел суммы</p>

        <p>Запрашиваемая сумма {isCustomer ? 'заказчика' : 'исполнителя'}</p>

        <div className={styles.price}>
          {isCustomer ? refundCustomerPercent : refundContractorPercent} TRL
        </div>
      </div>
    </div>
  )
}
