import { getManagers, getProjectHistory } from './../../Projects.thunx'
// import { clearOneProject } from './../../../CreateProject/CreateProject.thunk'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import ProjectHistoryComponent from './ProjectHistory'
import { getUsers } from 'screens/admin/Users/Users.thunx'

export const mapStateToProps = (state: TState) => {
  return {
    loading: state.admin.projects.loading,
    projectHistory: state.admin.projects.projectHistory,
    users: state.admin.users.results,
    managers: state.admin.projects.managers
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getProjectHistory, getUsers, getManagers }, dispatch)

export const ProjectHistory = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectHistoryComponent)
