import { TBaseFilterConfigData } from '../../../components/UiKit/Filter/Filter'
import { TWords } from 'elements/SideBar/SideBar.config'
import { IFilterTeamAdminDeactivateProps, IFilterTeamAdminProps } from './TeamAdmin.model'
import { blackList } from './components/helper'

export const getFilterTeamAdmin = (
  words: TWords,
  {
    positions = [],
    rank = [],
    department = [],
    typeOfEmployment = [],
    englishLevel = [],
    displayAllFields
  }: IFilterTeamAdminProps
): TBaseFilterConfigData[] => {
  const filter: TBaseFilterConfigData[] = [
    {
      name: 'fullName',
      type: 'input',
      label: words['admin.users.table.filters.userName'],
      width: '19%'
    },
    {
      name: 'position',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...positions],
      label: words['user.profile.career.position'],
      placeholder: '',
      width: '18%'
    },
    {
      name: 'rank',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...rank],
      label: words['user.profile.career.level'],
      placeholder: '',
      width: '14%'
    },
    {
      name: 'city',
      type: 'input',
      label: words['user.profile.mainInfo.location'],
      width: '18%'
    },
    {
      name: 'department',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...department],
      label: words['user.profile.career.department'],
      placeholder: '',
      width: '18%'
    }
  ]
  if (displayAllFields) {
    filter.push(
      {
        name: 'project',
        type: 'select',
        fieldData: [{ value: '@ALL@', label: words['all'] }],
        label: words['user.profile.career.projects'],
        isDisabled: true,
        width: '19%'
      },
      {
        name: 'typeOfEmployment',
        type: 'select',
        fieldData: [{ value: '@ALL@', label: words['all'] }, ...typeOfEmployment],
        label: words['user.profile.career.occupation'],
        width: '18%'
      },
      {
        name: 'childrenFrom',
        secondName: 'childrenTo',
        type: 'inputRange',
        fieldData: { minValue: 1, maxValue: 10 },
        label: `${words['user.profile.mainInfo.numberOfChildren']}`,
        rangeContainerWidth: '14%'
      },
      {
        name: 'firstWorkingDateFrom',
        secondName: 'firstWorkingDateTo',
        type: 'dateRangeNew',
        label: words['user.profile.career.hiringDate'],
        rangeContainerWidth: '18%',
        fieldData: {
          from: '',
          to: ''
        }
      },
      {
        name: 'probationEndDatePeriodFrom',
        secondName: 'probationEndDatePeriodTo',
        type: 'dateRangeNew',
        label: words['user.profile.career.probationEndDate'],
        rangeContainerWidth: '18%',
        fieldData: {
          from: '',
          to: ''
        }
      },
      {
        name: 'salaryFrom',
        secondName: 'salaryTo',
        type: 'inputRange',
        fieldData: { minValue: '$', maxValue: '$' },
        label: words['user.profile.career.salary'],
        rangeContainerWidth: '19%'
      },
      {
        name: 'dateOfBirthFrom',
        secondName: 'dateOfBirthTo',
        type: 'dateRangeNew',
        label: words['user.profile.mainInfo.dob'],
        rangeContainerWidth: '18%',
        fieldData: {
          from: '',
          to: ''
        }
      },
      {
        name: 'englishLevel',
        placeholder: '',
        type: 'multiSelect',
        fieldData: englishLevel,
        label: words['user.profile.career.englishLevel'],
        width: '55%'
      }
    )
  }
  return filter
}

export const getFilterTeamAdminDeactivate = (
  words: TWords,
  {
    positions = [],
    rank = [],
    department = [],
    typeOfEmployment = [],
    englishLevel = [],
    terminationInitiator = [],
    displayAllFields
  }: IFilterTeamAdminDeactivateProps
): TBaseFilterConfigData[] => {
  const filter: TBaseFilterConfigData[] = [
    {
      name: 'fullName',
      type: 'input',
      label: words['admin.users.table.filters.userName'],
      width: '16%'
    },
    {
      name: 'position',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...positions],
      label: words['user.profile.career.position'],
      placeholder: '',
      width: '15%'
    },
    {
      name: 'rank',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...rank],
      label: words['user.profile.career.level'],
      placeholder: '',
      width: '12%'
    },
    {
      name: 'city',
      type: 'input',
      label: words['user.profile.mainInfo.location'],
      width: '12%'
    },
    {
      name: 'terminationDateFrom',
      secondName: 'terminationDateTo',
      type: 'dateRangeNew',
      label: words['admin.team.terminationDate'],
      rangeContainerWidth: '18%',
      fieldData: {
        from: '',
        to: ''
      }
    },
    {
      name: 'terminationInitiator',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...terminationInitiator],
      label: words['admin.team.termination_initiator'],
      placeholder: '',
      width: '12%'
    }
  ]
  if (displayAllFields) {
    filter.push(
      {
        name: 'blackList',
        type: 'select',
        fieldData: blackList(words),
        label: words['admin.team.blackList'],
        width: '9%'
      },
      {
        name: 'department',
        type: 'select',
        fieldData: [{ value: '@ALL@', label: words['all'] }, ...department],
        label: words['user.profile.career.department'],
        width: '16%'
      },
      {
        name: 'project',
        type: 'select',
        fieldData: [{ value: '@ALL@', label: words['all'] }],
        label: words['user.profile.career.projects'],
        isDisabled: true,
        width: '16.2%'
      },
      {
        name: 'typeOfEmployment',
        type: 'select',
        fieldData: [{ value: '@ALL@', label: words['all'] }, ...typeOfEmployment],
        label: words['user.profile.career.occupation'],
        width: '12.7%'
      },
      {
        name: 'childrenFrom',
        secondName: 'childrenTo',
        type: 'inputRange',
        fieldData: { minValue: 1, maxValue: 10 },
        label: `${words['user.profile.mainInfo.numberOfChildren']}`,
        rangeContainerWidth: '12.7%'
      },
      {
        name: 'firstWorkingDateFrom',
        secondName: 'firstWorkingDateTo',
        type: 'dateRangeNew',
        label: words['user.profile.career.hiringDate'],
        rangeContainerWidth: '18%',
        fieldData: {
          from: '',
          to: ''
        }
      },
      {
        name: 'probationEndDatePeriodFrom',
        secondName: 'probationEndDatePeriodTo',
        type: 'dateRangeNew',
        label: words['user.profile.career.probationEndDate'],
        rangeContainerWidth: '18%',
        fieldData: {
          from: '',
          to: ''
        }
      },
      {
        name: 'salaryFrom',
        secondName: 'salaryTo',
        type: 'inputRange',
        fieldData: { minValue: '$', maxValue: '$' },
        label: words['user.profile.career.salary'],
        rangeContainerWidth: '14%'
      },
      {
        name: 'dateOfBirthFrom',
        secondName: 'dateOfBirthTo',
        type: 'dateRangeNew',
        label: words['user.profile.mainInfo.dob'],
        rangeContainerWidth: '18%',
        fieldData: {
          from: '',
          to: ''
        }
      },
      {
        name: 'englishLevel',
        type: 'multiSelect',
        placeholder: '',
        fieldData: englishLevel,
        label: words['user.profile.career.englishLevel'],
        width: '40%'
      }
    )
  }
  return filter
}
