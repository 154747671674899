import {
  SIGN_IN_CLEAR,
  LOG_OUT_REQUEST,
  LOG_OUT_ERROR,
  LOG_OUT_SUCCESS,
  REFRESH_TOKEN_ERROR,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_REQUEST,
  AUTH_TOKEN_SUCCESS,
  AUTH_TOKEN_FAILED,
  SET_TOKEN,
  PULLING_SUCCESS,
  PULLING_ERROR,
  SET_USER_AVATAR,
  SET_USER_VERIFICATION,
  SET_USER_ROLE
} from './Login.constants'
import { IAction } from 'models'
import { ActionCreator } from 'redux'

export const signInTokenSuccess: ActionCreator<IAction> = payload => ({
  type: AUTH_TOKEN_SUCCESS,
  payload
})

export const pollingSuccess: ActionCreator<IAction> = payload => ({
  type: PULLING_SUCCESS,
  payload
})

export const pollingError: ActionCreator<IAction> = payload => ({
  type: PULLING_ERROR,
  payload
})

export const signInTokenFailed: ActionCreator<IAction> = payload => ({
  type: AUTH_TOKEN_FAILED,
  payload
})

export const setToken: ActionCreator<IAction> = payload => ({
  type: SET_TOKEN,
  payload
})
export const logOutRequest: ActionCreator<IAction> = payload => ({
  type: LOG_OUT_REQUEST,
  payload
})

export const logOutSuccess: ActionCreator<IAction> = payload => ({
  type: LOG_OUT_SUCCESS,
  payload
})
export const logOutError: ActionCreator<IAction> = payload => ({
  type: LOG_OUT_ERROR,
  payload
})

// -------------------------------------------------------------
export const signInClear: ActionCreator<IAction> = () => ({
  type: SIGN_IN_CLEAR
})

export const refreshTokenRequest: ActionCreator<IAction> = payload => ({
  type: REFRESH_TOKEN_REQUEST,
  payload
})

export const refreshTokenSuccess: ActionCreator<IAction> = payload => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload
})

export const refreshTokenError: ActionCreator<IAction> = payload => ({
  type: REFRESH_TOKEN_ERROR,
  payload
})

export const setUserAvatar: ActionCreator<IAction> = payload => ({
  type: SET_USER_AVATAR,
  payload
})

export const setUserVerification: ActionCreator<IAction> = payload => ({
  type: SET_USER_VERIFICATION,
  payload
})

export const setUserRole: ActionCreator<IAction> = payload => ({
  type: SET_USER_ROLE,
  payload
})
