import { action } from 'store/store.utils'
import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
  REMOVE_NOTIFICATION,
  ADD_NOTIFICATION,
  SELECT_NOTIFICATION,
  REMOVE_SELECTED_NOTIFICATION
} from './Notifications.constants'
import { INotificationsItem } from './Notifications.model'

export const getNotificationsRequest = (payload?: any) => action(GET_NOTIFICATIONS_REQUEST, payload)

export const getNotificationsSuccess = (payload: INotificationsItem[]) =>
  action(GET_NOTIFICATIONS_SUCCESS, payload)

export const addNotification = (payload: any) => action(ADD_NOTIFICATION, payload)

export const selectNotification = (payload: any) => action(SELECT_NOTIFICATION, payload)

export const removeSelectedNotification = () => action(REMOVE_SELECTED_NOTIFICATION)

export const removeNotificationElement = (payload: any) => action(REMOVE_NOTIFICATION, payload)

export const getNotificationsError = (payload: any) => action(GET_NOTIFICATIONS_ERROR, payload)
