import React, { useState } from 'react'
import styles from './ScrollToBtn.module.scss'
// import { BaseButton } from 'components/UiKit/Button/BaseButton'

const ScrollToBtn = ({ refsik }: any) => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    refsik.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  if (refsik) {
    refsik.addEventListener('scroll', toggleVisible)
  }

  return (
    <button
      className={styles.btn}
      onClick={scrollToTop}
      style={{ display: visible ? 'inline' : 'none', transition: 'display 3s ease-out' }}
    >
      UP
    </button>
  )
}

export default ScrollToBtn
