import React, { FC } from 'react'
import cls from 'classnames'
import { usePagination, DOTS } from './usePagination'
import styles from './Pagination.module.scss'

interface IProps {
  onPageChange: (current: number) => void
  totalCount: number
  siblingCount?: number
  currentPage: number
  pageSize: number
  className?: string
  disabledClassName?: string
  selectedClassName?: string
  itemClassName?: string
  arrowLeft?: any
  arrowRight?: any
}

const Pagination: FC<IProps> = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className = '',
    disabledClassName = '',
    selectedClassName = '',
    itemClassName = '',
    arrowLeft,
    arrowRight
  } = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  })

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage !== 0 || (paginationRange && paginationRange.length > 2)) {
    if (paginationRange !== null) {
      const onNext = () => {
        onPageChange(currentPage + 1)
      }

      const onPrevious = () => {
        onPageChange(currentPage - 1)
      }

      const lastPage = paginationRange[paginationRange.length - 1]
      return (
        <ul className={cls([styles['pagination-container']], { [className]: className })}>
          {/* Left navigation arrow */}
          <li
            className={cls(
              [styles['pagination-item']],
              { [itemClassName]: itemClassName },
              { [styles.disabled]: currentPage === 1 },
              { [disabledClassName]: disabledClassName && currentPage === 1 }
            )}
            onClick={onPrevious}
          >
            <div className={cls([styles['arrow']], [styles['left']])}>
              {arrowLeft ? arrowLeft : '<'}
            </div>
          </li>
          {paginationRange.map((pageNumber: any, i: number) => {
            // If the pageItem is a DOT, render the DOTS unicode character
            if (pageNumber === DOTS) {
              return (
                <li
                  key={i}
                  className={cls(
                    [styles['pagination-item']],
                    { [itemClassName]: itemClassName },
                    'dots'
                  )}
                >
                  &#8230;
                </li>
              )
            }

            // Render our Page Pills
            return (
              <li
                key={i}
                className={cls(
                  [styles['pagination-item']],
                  { [itemClassName]: itemClassName },
                  { [styles.selected]: pageNumber === currentPage },
                  { [selectedClassName]: selectedClassName && pageNumber === currentPage }
                )}
                onClick={() => onPageChange(pageNumber)}
              >
                {pageNumber}
              </li>
            )
          })}
          {/*  Right Navigation arrow */}
          <li
            className={cls(
              [styles['pagination-item']],
              { [itemClassName]: itemClassName },
              { [styles.disabled]: currentPage === lastPage },
              { [disabledClassName]: disabledClassName && currentPage === lastPage }
            )}
            onClick={onNext}
          >
            <div className={cls([styles['arrow']], [styles['right']])}>
              {arrowRight ? arrowRight : '>'}
            </div>
          </li>
        </ul>
      )
    }
  }
  return null
}

export default Pagination
