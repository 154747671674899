import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import CurrentTeamComponent from './CurrentTeam'
import { deleteMemberFromProject, getProjectTeam } from './CurrentTeam.thunk'
import { getSelectOptions } from '../../../screens/client/EditProfile/EditProfile.thunx'

export const mapStateToProps = (state: TState) => {
  return {
    members: state.admin.currentTeam.members,
    displayAllFields: state.client.filter.multilineFilterFields.status,
    projectInfo: state.admin.projects.project,
    positions: state.client.editProfile.positions,
    typeOfEmployment: state.admin.teamAdmin.options.typeOfEmployment,
    rank: state.client.editProfile.rank
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getProjectTeam,
      getSelectOptions,
      deleteMemberFromProject
    },
    dispatch
  )

export const CurrentTeam = connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentTeamComponent)
