/* eslint-disable no-console */
import { Column } from 'react-table'
import { TableSortHeader } from '../../../components/UiKit/Table'
import React from 'react'
import styles from './SubcontractTasks.module.scss'
import { STATUS_OF_REQUESTS_TIME_OFF, TYPE_OF_REQUESTS_TIME_OFF } from '../../../globalConfigs'
import { isUser } from '../../../utils/user'
import moment from 'moment'
import { RequestTypeCell } from '../../../components/UiKit/Table/components/RequestTypeCell'
import { useUser } from '../../../hooks/useUser'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { ActionsTableCell } from 'screens/client/TimeOffRequests/components/ActionsTableCell'
import { IToken } from '../Token/Token.model'

export type TTimeOffRequestsData = {
  title: string
  customer: { fullName: string }
  specializations: any[]
  level: {
    name: string
  }
  fromDate: string
  createdAt: string
  currency: number
  status: {
    id: number
    name: string
  }
  price: number
  hours: number
  id: number
  reporterId: { id: number }
  requestType: number
  statuss: number
  timeZone: string
  toDate: string
  token: IToken
}

type TColumnsManagerOptions = {
  role: number
  isOnlyManager: boolean
}
type TColumnsManager = (options: TColumnsManagerOptions) => Array<Column<TTimeOffRequestsData>>

export const Columns: TColumnsManager = ({ role, isOnlyManager }) => {
  console.log(isOnlyManager)
  const { userData } = useUser()
  const words = useSelector((state: TState) => state.global.language.words)
  const statusik = (id: number) => {
    return id === 1
      ? 'draft'
      : id === 2
      ? 'created'
      : id === 3
      ? 'ready'
      : id === 4
      ? 'interwiev'
      : id === 5
      ? 'work'
      : id === 6
      ? 'canceled'
      : 'waiting'
  }
  const columnsManager: Array<Column<TTimeOffRequestsData>> = [
    {
      Header: words['user.subcontract.task'],
      id: 'title',
      accessor: d => d.title,
      minWidth: 210
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>{words['user.subcontract.task.customer']}</TableSortHeader>
      ),
      id: 'requestType',
      accessor: d => d.customer.fullName,
      minWidth: 100
    },
    {
      Header: words['user.subcontract.taskFilter.specialization'],
      id: 'fromDate',
      accessor: d => d.specializations[0].label,
      minWidth: 200
    },
    {
      Header: words['user.subcontract.task.level'],
      id: 'toDate',
      accessor: d => (d.level ? words[`${d.level.name}`] : '---'),
      minWidth: 70
    },
    {
      Header: words['user.subcontract.taskFilter.publicationDate'],
      id: 'hours',
      accessor: d =>
        d.timeZone
          ? moment(d.fromDate)
              .tz(d.timeZone)
              .format('DD.MM.YYYY')
          : moment(d.createdAt).format('DD.MM.YYYY'),
      width: 130
    },
    {
      Header: words['user.subcontract.task.currency'],
      id: 'toDates',
      accessor: d => d.token && d.token.symbol,
      minWidth: 70
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>{words['user.subcontract.task.budget']}</TableSortHeader>
      ),
      id: 'toDateawd',
      accessor: d => `${d.price} ${d.token && d.token.symbol}`,
      minWidth: 70
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>
          {words['user.subcontract.taskFilter.status']}
        </TableSortHeader>
      ),
      id: 'toDatedawd',
      accessor: d => (
        <div className={styles[statusik(d.status.id)]}>{words[`${d.status.name}`]}</div>
      ),
      minWidth: 70
    }
  ]

  const columnsUser: Array<Column<TTimeOffRequestsData>> = [
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>
          {words['user.dayOffTracker.createRequest.requestType']}
        </TableSortHeader>
      ),
      id: 'requestType',
      accessor: d => TYPE_OF_REQUESTS_TIME_OFF[d.requestType]
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>
          {words['user.dayOffTracker.table.requestStatus']}
        </TableSortHeader>
      ),
      id: 'status',
      Cell: ({ row }: any) => {
        return <RequestTypeCell content={row.original.status} />
      }
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>
          {words['user.dayOffTracker.table.firstDay']}
        </TableSortHeader>
      ),
      id: 'fromDate',
      accessor: d =>
        d.timeZone
          ? moment(d.fromDate)
              .tz(d.timeZone)
              .format('DD.MM.YYYY')
          : moment(d.fromDate).format('DD.MM.YYYY')
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>
          {words['user.dayOffTracker.table.lastDay']}
        </TableSortHeader>
      ),
      id: 'toDate',
      accessor: d =>
        d.timeZone
          ? moment(d.toDate)
              .tz(d.timeZone)
              .format('DD.MM.YYYY')
          : moment(d.toDate).format('DD.MM.YYYY')
    },
    {
      Header: words['user.dayOffTracker.table.hours'],
      width: 80,
      id: 'hours',
      accessor: d => `${d.hours}:00`
    },
    {
      width: 80,
      id: 'actions',
      accessor: props => {
        return (
          <ActionsTableCell
            id={props.id}
            status={STATUS_OF_REQUESTS_TIME_OFF(words)[props.statuss]}
            userId={userData.data ? userData.data.id : 0}
            onClick={() => null}
            role={role}
            rowData={props}
          />
        )
      }
    }
  ]

  return isUser(role) ? columnsUser : columnsManager
}
