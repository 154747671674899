import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { SettingsTaskCancellationInfo as SettingsTaskCancellationComponent } from './SettingsTaskCancellationInfo'
import { TState } from 'store'
import {
  createTaskCancellation,
  getTask,
  updateTaskCancellation
} from 'screens/client/Subcontract/Subcontract.thunk'
import { isTaskInTransaction } from 'screens/client/Web3/web3.action'
import { updateTaskInfo } from 'screens/client/Subcontract/components/CreateTask/CreateTask.thunk'

export const mapStateToProps = (state: TState) => ({
  taskInfo: state.client.subcontract.task,
  userPhoto: state.auth.avatar,
  loading: state.client.subcontract.loading,
  error: state.client.subcontract.error,
  currentUser: state.auth.data,
  isTransaction: state.client.web3.isTransaction
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getTask,
      createTaskCancellation,
      updateTaskCancellation,
      isTaskInTransaction,
      updateTaskInfo
    },
    dispatch
  )

export const SettingsTaskCancellationInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsTaskCancellationComponent)
