import React, { useEffect, useState } from 'react'
import styles from './Bonuses.module.scss'
import { Icons } from 'components/Icons'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import { Form } from 'react-final-form'
import { Button } from 'components/UiKit/Button'
import TokenChangeModal from 'components/SubcontractModals/TokenChangeModal'
import WithdrawalConfigModal from 'components/SubcontractModals/WithdrawalConfigModal'

// interface IBonusesProps {
//   words: TWords
//   history: History
// }

const Bonuses = ({
  words,
  history,
  fetchTokens,
  tokens,
  user,
  tokensLoading,
  addToken,
  fetchOneToken,
  changeUserTokenStatusThunk,
  getSelectedToken
}: any) => {
  const bonusesData = [
    { value: '1', label: words['user.profile.bonuses.accumulate'] },
    { value: '2', label: words['user.profile.bonuses.withdraw.week'] },
    { value: '3', label: words['user.profile.bonuses.withdraw.month'] },
    { value: '4', label: words['user.profile.bonuses.withdraw.year'] },
    { value: '5', label: words['user.profile.bonuses.withdraw.other'] }
  ]

  const [isOpenToken, setIsOpenToken] = useState<boolean>(false)
  const [isOpenWithdraw, setIsOpenWithdraw] = useState<boolean>(false)
  const [currentBonusesData, setCurrentBonusesData] = useState(bonusesData[0].value)
  const mockTokens = JSON.parse(JSON.stringify(tokens))

  mockTokens.userActivatedTokens = [
    ...mockTokens.userActivatedTokens,
    {
      address: '0xa258c4606ca8206d8aa700ce2143d7db854d168c',
      chainId: 1,
      decimals: 18,
      id: 2,
      isActive: true,
      isAdminToken: false,
      isCustom: false,
      logoURI:
        'https://assets.coingecko.com/coins/images/28793/thumb/yvWETH-128-0xa258C4606Ca8206D8aA700cE2143D7db854D168c.png?1674225513',
      name: 'WETH yVault',
      symbol: 'YVWETH'
    },
    {
      address: '0x786e995a975c2ab7c1dd252a9019fb0c91c9ca83',
      chainId: 1,
      decimals: 18,
      id: 3,
      isActive: true,
      isAdminToken: false,
      isCustom: false,
      logoURI:
        'https://assets.coingecko.com/coins/images/25642/thumb/v-MhJXeSQuKSjz5gJRpptF5E5v95862DXJhkSeVabQcVsbBxzryaaQQ-eqwp0hvjrXQQmQRUKYW3gFfzUi3zVjhWoZKg79rmFF5dL6igKT0iWWpDtfNRcr6XA1mL9WiLgg6EOqwfmuLNu1-qan38OQJve32FKgJ7FL_fAyZRHvSuuIwO4qmcLPVqWo08oYELCC5ParMmS7FfNsHtu38u6j0gxw.jpg?1652949037',
      name: 'Landworld',
      symbol: 'LWD'
    }
  ]

  useEffect(() => {
    fetchTokens(user.id)
  }, [])

  useEffect(() => {
    if (currentBonusesData === '5') {
      setIsOpenWithdraw(!isOpenWithdraw)
    }
  }, [currentBonusesData])

  const openTokenModal = () => {
    setIsOpenToken(!isOpenToken)
  }
  const openWithdrawModal = () => {
    setIsOpenWithdraw(!isOpenWithdraw)
  }

  return (
    <>
      <div className={styles['bonuses-title-wrapper']}>
        <h2>{words['user.profile.bonuses.title']}</h2>
        <button
          className={styles['history']}
          onClick={() => history.push('/dashboard/subcontract/profile/history-transaction')}
        >
          {' '}
          <Icons icon="timeIconDisable" /> {words['user.subcontract.bonuses.transactionHistory']}
        </button>
      </div>
      <div className={styles['bonuses-main-wrapper']}>
        <div className={styles['bonuses-trl']}>
          77.20 <span>TRL</span>
        </div>
        <div className={styles['bonuses-activities']}>
          <div className={styles['bonuses-select']}>
            <Form
              initialValues={{ bonuses: bonusesData[0].value }}
              onSubmit={() => {
                null
              }}
              render={({ form, handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit} className={styles['bonuses-form']}>
                    <FieldFactory
                      form={form}
                      config={[
                        {
                          items: [
                            {
                              name: 'bonuses',
                              required: false,
                              inputWrapperClassName: styles['form-value'],
                              component: () => {
                                return {
                                  type: 'select',
                                  props: {
                                    onChange: (event: any) => {
                                      form.change('bonuses', event.value)
                                      setCurrentBonusesData(event.value)
                                    },
                                    options: bonusesData,
                                    defaultValue: bonusesData[0]
                                  }
                                }
                              }
                            }
                          ]
                        }
                      ]}
                      words={words}
                    />
                    <Button
                      type="submit"
                      className={styles['bonuses-withdraw']}
                      onClick={openTokenModal}
                    >
                      {words['user.profile.bonuses.withdraw']}
                    </Button>
                    <TokenChangeModal
                      toggle={isOpenToken}
                      action={openTokenModal}
                      tokens={mockTokens}
                      user={user}
                      changeUserTokenStatusThunk={changeUserTokenStatusThunk}
                      tokensLoading={tokensLoading}
                      addToken={addToken}
                      fetchOneToken={fetchOneToken}
                      getSelectedToken={getSelectedToken}
                      title={words['entrepreneur.invoices.currency']}
                      button={words['user.profile.bonuses.withdraw']}
                    />
                  </form>
                )
              }}
            />
          </div>
        </div>
      </div>
      <WithdrawalConfigModal toggle={isOpenWithdraw} action={openWithdrawModal} />
    </>
  )
}
export default Bonuses
