import React, { useState } from 'react'
import styles from './Filters.module.scss'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { Form } from 'react-final-form'
import { Icons } from 'components/Icons'
import { IForm, ISelect } from '../../Entrepreneurs.model'
import { banksToSelectOptions } from '../../Helpers/banksToSelectOptions'

type TFiltersProps = {
  setName: (name: string) => void
  setBank: (bank: string) => void
}

type TFormValues = {
  name?: string
  bank?: string
}

const Filters = ({ setName, setBank }: TFiltersProps) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const banks = useSelector((state: TState) => state.admin.entrepreneurs.banks)

  const [selectedOption, setSelectedOption] = useState({
    value: '',
    label: ''
  })

  const handleReset = (values: TFormValues) => {
    values.name = ''
    values.bank = ''
    setName('')
    setBank('')
    setSelectedOption({
      value: '',
      label: ''
    })
  }
  return (
    <div className={styles['filters-wrapper']}>
      <Form
        initialValues={{
          name: '',
          bank: ''
        }}
        onSubmit={() => ({})}
        render={({ form, handleSubmit }: IForm) => {
          const formState = form.getState()
          return (
            <form onSubmit={handleSubmit} className={styles['primary-form']}>
              <div className={styles['inputs']}>
                <FieldFactory
                  className={styles['input-factory']}
                  form={form}
                  config={[
                    {
                      items: [
                        {
                          name: 'name',
                          label: words['admin.entrepreneurs.fullName'],
                          required: false,
                          inputWrapperClassName: styles['input-warp'],
                          component: () => {
                            return {
                              type: 'input',
                              props: {
                                variant: 'outlined',
                                onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                                  setName(e.target.value)
                                }
                              }
                            }
                          }
                        }
                      ]
                    }
                  ]}
                  words={words}
                />
                <FieldFactory
                  className={styles['input-factory']}
                  form={form}
                  config={[
                    {
                      items: [
                        {
                          name: 'bank',
                          label: words['admin.entrepreneurs.bank'],
                          required: false,
                          inputWrapperClassName: styles['input-warp'],
                          component: () => {
                            return {
                              type: 'select',
                              props: {
                                variant: 'outlined',
                                options: banksToSelectOptions(banks),
                                placeholder: '',
                                value: selectedOption,
                                onChange: ({ value, label }: ISelect) => {
                                  form.change('bank', value)
                                  setBank(label)
                                  setSelectedOption({ value, label })
                                }
                              }
                            }
                          }
                        }
                      ]
                    }
                  ]}
                  words={words}
                />
              </div>
              <div className={styles['reset']}>
                <button
                  className={styles['remove-filter']}
                  onClick={() => handleReset(formState.values)}
                >
                  {words['admin.entrepreneurs.reset']}
                  <Icons icon="removeFilter" />
                </button>
              </div>
            </form>
          )
        }}
      />
    </div>
  )
}
export default Filters
