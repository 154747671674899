import { EventForCalendar } from '../PlaningCalendar.model'

export const getOnlyUniqueEvents = (events: EventForCalendar[]): EventForCalendar[] => {
  const uniqueIdsSet = new Set<string>()
  const uniqueObjects = events.filter(event => {
    if (!uniqueIdsSet.has(event.eventId)) {
      uniqueIdsSet.add(event.eventId)
      return true
    }
    return false
  })

  return uniqueObjects
}
