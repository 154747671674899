import { Dispatch } from 'redux'
import { API, api } from 'services/api'
import {
  getNotificationsError,
  getNotificationsRequest,
  getNotificationsSuccess,
  removeNotificationElement,
  selectNotification
} from './Notifications.actions'
import { INotificationsItem } from './Notifications.model'

export const fetchNotifications = () => async (dispatch: Dispatch) => {
  dispatch(getNotificationsRequest())

  try {
    const response = await api.get(`${API.URL}/notifications`)
    dispatch(getNotificationsSuccess(response.data.payload.result))
  } catch (error) {
    dispatch(getNotificationsError(error))
  }
}

export const deleteNotifications = (id: number) => async (dispatch: Dispatch) => {
  try {
    await api.delete(`${API.URL}/notifications/${id}`)
    dispatch(removeNotificationElement(id))
  } catch (error) {
    dispatch(getNotificationsError(error))
  }
}

export const viewNotification = (notification: INotificationsItem) => async (
  dispatch: Dispatch
) => {
  const updatedNotification = {
    ...notification,
    isViewed: true
  }
  try {
    await api.put(`${API.URL}/notifications/${notification.id}`, updatedNotification)
    dispatch(selectNotification(updatedNotification))
  } catch (error) {
    dispatch(getNotificationsError(error))
  }
}
