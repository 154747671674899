import React, { FC, useEffect } from 'react'
import styles from './SplitSumRangeInput.module.scss'
import { InputsState } from 'components/SubcontractModals/CancelTaskModal'

interface ISplitSumRangeInput {
  budget: number
  inputsState: InputsState
  setInputsState: (value: React.SetStateAction<InputsState>) => void
  task: any
}

export const SplitSumRangeInput: FC<ISplitSumRangeInput> = ({
  budget,
  inputsState,
  setInputsState,
  task
}) => {
  const getBackgroundSize = () => {
    const percentage = (inputsState.field1 / budget) * 100
    return {
      backgroundSize: `${percentage}% 100%`
    }
  }

  useEffect(() => {
    setInputsState(prevState => ({
      ...prevState,
      field1: (inputsState.numInRange / 100) * budget,
      field2: ((100 - inputsState.numInRange) / 100) * budget
    }))
  }, [inputsState.numInRange, budget])

  const handleChangePart1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPart1 = Number(event.currentTarget.value)
    if (newPart1 >= 0 && newPart1 <= 100) {
      setInputsState(prevState => ({
        ...prevState,
        field1: (newPart1 / 100) * budget,
        field2: ((100 - newPart1) / 100) * budget,
        numInRange: newPart1
      }))
    }
  }

  const handleChangePart2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPart2 = Number(event.currentTarget.value)
    if (newPart2 >= 0 && newPart2 <= 100) {
      setInputsState(prevState => ({
        ...prevState,
        field2: (newPart2 / 100) * budget,
        field1: ((100 - newPart2) / 100) * budget,
        numInRange: 100 - newPart2
      }))
    }
  }

  const handleChangeRangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newProgress = Number(event.currentTarget.value)
    setInputsState(prevState => ({
      ...prevState,
      numInRange: newProgress,
      field1: (newProgress / 100) * budget,
      field2: ((100 - newProgress) / 100) * budget
    }))
  }

  return (
    <div>
      <div className={styles.subTitle}>Раздел суммы</div>

      <div className={styles.fieldWrapper}>
        <div className={styles.inputContainer}>
          <input
            name="field"
            type="number"
            className={styles.field}
            value={inputsState.numInRange.toString()}
            onChange={handleChangePart1}
          />
        </div>

        <div className={styles.inputContainer}>
          <input
            name="field"
            className={styles.field}
            type="number"
            value={(100 - inputsState.numInRange).toString()}
            onChange={handleChangePart2}
          />
        </div>
      </div>

      <div className={styles.rangeWrapper}>
        <input
          type="range"
          max={100}
          value={inputsState.numInRange}
          onChange={handleChangeRangeInput}
          className={styles.rangeInput}
          style={getBackgroundSize()}
        />

        <div className={styles.rangeSum1}>
          {Math.round(inputsState.field1)}
          {task.token && task.token.symbol}
        </div>

        <div className={styles.rangeSum2}>
          {Math.round(inputsState.field2)}
          {task.token && task.token.symbol}
        </div>
      </div>
    </div>
  )
}
