import React, { FC, useMemo } from 'react'
import styles from './TeamArchive.module.scss'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { Filter } from '../../UiKit/Filter'
import { getFilterConfig } from './TeamArchive.filter.config'
import { TTeamArchiveProps } from './TeamArchive.model'
import queryString from 'query-string'
import history from 'utils/history'
import { TeamTable } from 'components/UiKit/Table'
import { Columns } from './TeamArchive.columns'

export const TeamArchive: FC<TTeamArchiveProps | any> = ({ displayAllFields }) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const parsed = queryString.parse(history.location.search)
  const columns = Columns(styles['formatCell'], styles['formatHeader'])
  const columnsMemoized = useMemo(() => columns, [])

  const placeholderData: any[] = [
    {
      name: 'placeholder',
      specialization: 'placeholder',
      level: 'placeholder',
      location: 'placeholder',
      period: 'placeholder',
      type: 'placeholder',
      hoursPerDay: 'placeholder',
      payment: 'placeholder',
      rate: 'placeholder',
      invoivePosition: 'placeholder',
      invoiveLevel: 'placeholder'
    },
    {
      name: 'Ada Lovelace',
      specialization: 'UI/UX Designer',
      level: 'Junior',
      location: 'Харьков',
      period: '5 месяцев',
      type: 'Full-time',
      hoursPerDay: '8',
      payment: '1000',
      rate: '10',
      invoivePosition: 'UI/UX Designer',
      invoiveLevel: 'Junior'
    },
    {
      name: 'Grace Hopper',
      specialization: 'JavaScript Developer',
      level: 'Middle',
      location: 'Киев',
      period: '1 год',
      type: 'Part-time',
      hoursPerDay: '4',
      payment: '500',
      rate: '8',
      invoivePosition: 'JavaScript Developer',
      invoiveLevel: 'Middle'
    }
  ]

  return (
    <>
      <div className={styles['team-archive']}>
        <Filter
          config={getFilterConfig({
            displayAllFields,
            words,
            inputCurrencyClass: styles['currency-input'],
            inputTimeClass: styles['time-input'],
            currentPeriodFrom: parsed.periodFrom,
            currentPeriodTo: parsed.periodTo
          })}
          isMultilineBtn={true}
          withButton={false}
          defaultOpened={true}
        />
      </div>
      <div className={styles['team-archive-table']}>
        <TeamTable
          total={0}
          data={placeholderData}
          noData={words['noData']}
          getData={() => ({})}
          columns={columnsMemoized}
          classNameToRow="TeamItemRow"
          hiddenColumns={[]}
        />
        <button className={styles['team-archive-xlxs']}>.xlxs</button>
      </div>
    </>
  )
}
