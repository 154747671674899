import { Column } from 'react-table'
import { TableSortHeader } from '../../../components/UiKit/Table'
import React from 'react'
import { TTimeOffRequestsData } from './TimeOffRequests.reducer'
import { STATUS_OF_REQUESTS_TIME_OFF, TYPE_OF_REQUESTS_TIME_OFF } from '../../../globalConfigs'
import { isUser } from '../../../utils/user'
import { IndeterminateCheckbox } from '../../../components/UiKit/Table/components/IndeterminateCheckbox'
import moment from 'moment'
import { RequestTypeCell } from '../../../components/UiKit/Table/components/RequestTypeCell'
import { ActionsTableCell } from './components/ActionsTableCell'
import { useUser } from '../../../hooks/useUser'
import { useSelector } from 'react-redux'
import { TState } from 'store'

type TColumnsManagerOptions = {
  role: number
  handleCancelRequest: (id: number | string) => void
  isOnlyManager: boolean
}
type TColumnsManager = (options: TColumnsManagerOptions) => Array<Column<TTimeOffRequestsData>>

export const Columns: TColumnsManager = ({ role, handleCancelRequest, isOnlyManager }) => {
  const { userData } = useUser()
  const words = useSelector((state: TState) => state.global.language.words)
  const columnsManager: Array<Column<TTimeOffRequestsData>> = [
    !isOnlyManager
      ? {
          id: 'selection',
          // @ts-ignore
          Header: ({ getToggleAllRowsSelectedProps, data }) => {
            const toggleAllRowsSelectedProps = getToggleAllRowsSelectedProps()
            return (
              <>
                {data.length && !isUser(role) ? (
                  <IndeterminateCheckbox isHeader={true} {...toggleAllRowsSelectedProps} />
                ) : null}
              </>
            )
          },
          Cell: ({ row }: any) => {
            return (
              <IndeterminateCheckbox
                // @ts-ignore
                {...row.getToggleRowSelectedProps()}
                disabled={row.original.status !== 1}
              />
            )
          },
          width: 20
        }
      : { id: 'selection', width: 0 },
    {
      Header: words['user.dayOffTracker.table.creatorOfRequest'],
      id: 'fullName',
      accessor: d => d.authorId.fullName,
      minWidth: 200
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>
          {words['manager.dayOffTracker.details.requestType']}
        </TableSortHeader>
      ),
      id: 'requestType',
      accessor: d => TYPE_OF_REQUESTS_TIME_OFF[d.requestType],
      minWidth: 150
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>
          {words['user.dayOffTracker.table.firstDay']}
        </TableSortHeader>
      ),
      id: 'fromDate',
      accessor: d =>
        d.timeZone
          ? moment(d.fromDate)
              .tz(d.timeZone)
              .format('DD.MM.YYYY')
          : moment(d.fromDate).format('DD.MM.YYYY'),
      minWidth: 150
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>
          {words['user.dayOffTracker.table.lastDay']}
        </TableSortHeader>
      ),
      id: 'toDate',
      accessor: d =>
        d.timeZone
          ? moment(d.toDate)
              .tz(d.timeZone)
              .format('DD.MM.YYYY')
          : moment(d.toDate).format('DD.MM.YYYY'),
      minWidth: 150
    },
    {
      Header: words['user.dayOffTracker.table.hours'],
      id: 'hours',
      accessor: d => `${d.hours}:00`,
      width: 80
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>
          {words['user.dayOffTracker.table.requestStatus']}
        </TableSortHeader>
      ),
      id: 'status',
      Cell: ({ row }: any) => {
        return <RequestTypeCell content={row.original.status} />
      },
      minWidth: 150
    },
    {
      width: 80,
      id: 'actions',
      accessor: props => {
        return (
          <ActionsTableCell
            id={props.id}
            status={STATUS_OF_REQUESTS_TIME_OFF(words)[props.status]}
            userId={userData.data ? userData.data.id : 0}
            onClick={handleCancelRequest}
            role={role}
            rowData={props}
          />
        )
      }
    }
  ]

  const columnsUser: Array<Column<TTimeOffRequestsData>> = [
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>
          {words['user.dayOffTracker.createRequest.requestType']}
        </TableSortHeader>
      ),
      id: 'requestType',
      accessor: d => TYPE_OF_REQUESTS_TIME_OFF[d.requestType]
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>
          {words['user.dayOffTracker.table.requestStatus']}
        </TableSortHeader>
      ),
      id: 'status',
      Cell: ({ row }: any) => {
        return <RequestTypeCell content={row.original.status} />
      }
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>
          {words['user.dayOffTracker.table.firstDay']}
        </TableSortHeader>
      ),
      id: 'fromDate',
      accessor: d =>
        d.timeZone
          ? moment(d.fromDate)
              .tz(d.timeZone)
              .format('DD.MM.YYYY')
          : moment(d.fromDate).format('DD.MM.YYYY')
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>
          {words['user.dayOffTracker.table.lastDay']}
        </TableSortHeader>
      ),
      id: 'toDate',
      accessor: d =>
        d.timeZone
          ? moment(d.toDate)
              .tz(d.timeZone)
              .format('DD.MM.YYYY')
          : moment(d.toDate).format('DD.MM.YYYY')
    },
    {
      Header: words['user.dayOffTracker.table.hours'],
      width: 80,
      id: 'hours',
      accessor: d => `${d.hours}:00`
    },
    {
      width: 80,
      id: 'actions',
      accessor: props => {
        return (
          <ActionsTableCell
            id={props.id}
            status={STATUS_OF_REQUESTS_TIME_OFF(words)[props.status]}
            userId={userData.data ? userData.data.id : 0}
            onClick={handleCancelRequest}
            role={role}
            rowData={props}
          />
        )
      }
    }
  ]

  return isUser(role) ? columnsUser : columnsManager
}
