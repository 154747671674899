import axios from 'axios'
import { config } from 'globalConfigs'

export const API = {
  URL: `${config.apiUrl}`,
  ADMIN: '/admins',
  ADMIN_UPDATE: '/admin',
  CLIENT: '/users',
  CLIENT_UPDATE: '/user',
  CLIENT_RESTORE: '/users/restore',
  POSTS: '/posts',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  REFRESH: '/refresh-token',
  POLLING: '/balance',
  CALENDAR: '/calendar',
  BALANCE_UPDATE: '/balance-update',
  TIME_BALANCE: '/time-balance',
  CALENDAR_USER: '/calendar/user',
  MAIL: '/mail',
  RESTORE: '/restore-password',
  GOODS: '/goods',
  GOODS_RESTORE: '/goods/restore',
  GOODS_CATEGORIES: '/categories',
  GOODS_NAMES: '/goods/name',
  MANAGERS: '/managers',
  MANAGEMENT: '/calendar/management',
  MANAGEMENT_MAIL: '/calendar/management-mail',
  PRODUCT_CALENDAR: '/production-calendar',
  POSITIONS: '/position',
  TRANSACTION: '/transaction',
  TRANSACTION_USER: '/transaction/user',
  TRANSACTION_ADMIN: '/transaction/admin',
  POINTS_CONFIG: '/points-config',
  REQUEST_USER: '/request',
  REQUEST_ADMIN: '/request/management',
  ACHIEVEMENTS: '/initiative',
  ACHIEVEMENTS_RESTORE: '/initiative/restore',
  HISTORY: '/history',
  REVIEW: '/review',
  CATEGORIES: '/categories',
  FAQ: '/faq',
  LOCALIZATION: '/localization',
  RANK: '/rank',
  MARITIAL_STATUS: '/maritalstatus',
  ENGLISH_LEVEL: '/englishlevel',
  DEPARTMENT: '/department',
  ACADEMIC_DEGREE: '/academicdegree',
  TYPE_OF_EMPLOYMENT: '/typeofemployment',
  TEAM: '/team',
  TEAM_ADMIN: '/team-admin',
  TERMINATION_INITIATOR: '/terminationinitiator',
  USER_PHOTO: '/user-photo',
  USER_PROFILE_INFO_FOR_ADMIN: '/admin/user-profile',
  USER_PROFILE_INFO: '/user-profile',
  ALL_USERS: '/users-list',
  FOREIGN_PROFILE: '/foreign-profile',
  CHECK_USER_PROFILE: '/check-profile',
  TASK_REQUEST: '/task-responses',
  INTERVIEW_REQUEST: '/interview-requests',
  GOOGLE_CALENDAR_REQUEST: '/google-calendar',
  GOOGLE_CALENDAR_REQUEST_OWN_EVENTS: '/google-calendar/own-events',
  GOOGLE_CALENDAR_FOREIGN_EVENTS_REQUEST: '/google-calendar/foreign-events',
  GOOGLE_CALENDAR_FOREIGN_PLANNING_EVENTS: '/google-calendar/foreigner-planning-events',
  ENTREPRENEUR_INVOICES: '/entrepreneur',
  BANKS: '/banks'
}

// Set config defaults when creating the instance
export const api = axios.create({
  baseURL: API.URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json'
  }
})

export const apiVetifi = axios.create({
  baseURL: process.env.REACT_APP_VERIFI_API_POLYGON || 'http://localhost:3030',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json'
  }
})

export const apiIdenfy = axios.create({
  baseURL: process.env.REACT_APP_IDENFY_API || 'http://localhost:3030',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json'
  }
})

apiIdenfy.interceptors.request.use(
  request => {
    request.headers.Authorization =
      'Basic ' + btoa(unescape(encodeURIComponent('aA5Ygk7Wc8N' + ':' + 'sSQI2gTgF5seFk1sRqAz')))

    return request
  },
  error => {
    return Promise.reject(error)
  }
)

// api.interceptors.request.use(
//   request => {
//     const accessToken = getAccessTokenFromStorage()

//     if (accessToken) {
//       request.headers.Authorization = `Bearer ${accessToken}`
//     }

//     return request
//   },
//   error => {
//     return Promise.reject(error)
//   }
// )

api.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401) {
      localStorage.clear()
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

export const addAccessTokenInterceptor = (getAccessTokenSilently: any) => {
  api.interceptors.request.use(async request => {
    try {
      const token = await getAccessTokenSilently()
      request.headers.Authorization = `Bearer ${token}`
    } catch (err) {}
    return request
  })
}
