/* eslint-disable no-console */
import React from 'react'
import { Column } from 'react-table'
import DownloadInvoice from './FormulatedInvoices/DownloadInvoice/DownloadInvoice'
import DeleteInvoice from './FormulatedInvoices/DeleteInvoice/DeleteInvoice'
import SelectInvoice from './FormulatedInvoices/SelectInvoice/SelectInvoice'
import { useSelector } from 'react-redux'
import { TState } from '../../../store'
import moment from 'moment'

export type TEntrepreneursData = {
  id: string
  name: string
  invoiceNumber: string
  dateOfFormation: any
  currency: any
  amount: number
  delete: any
  check: any
}

type TColumnsManagerOptions = {
  role: number
  isOnlyManager: boolean
  downloadRef: any
}
type TColumnsManager = (options: TColumnsManagerOptions) => Array<Column<TEntrepreneursData>>

export const Columns: TColumnsManager = ({ downloadRef }) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const columnsManager: Array<Column<TEntrepreneursData>> = [
    {
      Header: d => <SelectInvoice invoice={d} name="selectAll" />,
      id: 'check',
      accessor: d => <SelectInvoice invoice={d} name={d.id} />,
      width: 68
    },
    {
      Header: words['entrepreneur.invoices.fullName'],
      id: 'name',
      accessor: d => d.name,
      minWidth: 313
    },
    {
      Header: words['entrepreneur.invoices.number'],
      id: 'invoiceNumber',
      accessor: d => d.invoiceNumber,
      minWidth: 313
    },
    {
      Header: words['entrepreneur.invoices.dateOfFormation'],
      id: 'dateOfFormation',
      accessor: d => moment(d.dateOfFormation).format('L'),
      minWidth: 350
    },
    {
      Header: words['entrepreneur.invoices.currency'],
      id: 'currency',
      accessor: d => d.currency,
      minWidth: 70
    },
    {
      Header: words['entrepreneur.invoices.amount'],
      id: 'amount',
      accessor: d => d.amount,
      minWidth: 300
    },
    {
      Header: '',
      id: 'file',
      accessor: d => {
        // eslint-disable-next-line react/react-in-jsx-scope
        return <DownloadInvoice componentRef={downloadRef} d={d} />
      },
      maxWidth: 70
    },
    {
      Header: '',
      id: 'delete',
      accessor: d => {
        // eslint-disable-next-line react/react-in-jsx-scope
        return <DeleteInvoice invoice={d} />
      },
      maxWidth: 70
    }
  ]

  return columnsManager
}
