import { headers } from 'screens/client/CryptoBot/components/ControlPanel/libs/constants/constants'
import { api, API } from 'services/api'

export async function fetchSettingsFromCryptoBot(endpoint: string) {
  try {
    const { data } = await api.get(`${API.URL}${endpoint}`, { headers })
    return data
  } catch (error) {
    console.log(error)
  }
}
