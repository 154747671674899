import { IInitialState } from './Vacancies.model'
import { IAction } from 'models'
import {
  GET_VACANCIES_ERROR,
  GET_VACANCIES_REQUEST,
  GET_VACANCIES_SUCCESS,
  ACTION_VACANCIES_REQUEST,
  ACTION_VACANCIES_SUCCESS,
  ACTION_VACANCIES_ERROR,
  CLEAR_VACANCIES_DATA,
  SET_PROJECT_OPTIONS,
  SET_VACANCY_SUCCESS,
  SET_STATUS_OPTIONS,
  UPDATE_VACANCY_SUCCESS
} from './Vacancies.constants'

export const initialState: IInitialState = {
  vacanciesList: [],
  vacancy: null,
  projectOptions: [],
  total: 0,
  statusOptions: [],
  error: '',
  loading: true,
  minFromDate: '',
  maxToDate: '',
  minPrice: 0,
  maxPrice: 0
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_VACANCIES_REQUEST:
    case ACTION_VACANCIES_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_VACANCIES_SUCCESS:
      console.log(action.payload.data)
      return {
        ...state,
        vacanciesList: [...action.payload.data],
        loading: false
      }

    case ACTION_VACANCIES_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case SET_PROJECT_OPTIONS:
      return {
        ...state,
        projectOptions: action.payload
      }
    case SET_VACANCY_SUCCESS:
      return {
        ...state,
        vacancy: action.payload.data,
        loading: false
      }
    case SET_STATUS_OPTIONS:
      return {
        ...state,
        statusOptions: action.payload
      }
    case UPDATE_VACANCY_SUCCESS:
      return {
        ...state,
        vacanciesList: state.vacanciesList.map(vacancy =>
          vacancy.id === action.payload.id ? { ...vacancy, status: action.payload.status } : vacancy
        ),
        loading: false
      }
    case CLEAR_VACANCIES_DATA:
      return {
        ...initialState
      }

    case GET_VACANCIES_ERROR:
    case ACTION_VACANCIES_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    default:
      return state
  }
}
