import React, { FC, useRef, useState } from 'react'
import styles from './EntrepreneursList.module.scss'
import Filters from './Filters'

import Table from './Table'
import { TChecked } from '../Entrepreneurs.model'
import { TEntrepreneursListProps } from './EntrepreneursList.model'

const EntrepreneursList: FC<TEntrepreneursListProps> = ({ entrepreneurs }) => {
  const [name, setName] = useState('')
  const [bank, setBank] = useState('')
  const [checksEntrepreneurs, setChecksEntrepreneurs] = useState<TChecked[]>([])
  const tableRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <section className={styles['filters']}>
        <Filters setName={setName} setBank={setBank} />
      </section>
      <section className={`${styles['table']} ${styles['table-wrapper']}`} ref={tableRef}>
        <Table
          name={name}
          bank={bank}
          entrepreneurs={entrepreneurs}
          checksEntrepreneurs={checksEntrepreneurs}
          setChecksEntrepreneurs={setChecksEntrepreneurs}
        />
      </section>
    </>
  )
}
export default EntrepreneursList
