/* eslint-disable no-console */
import { Column } from 'react-table'
import { TableSortHeader } from '../../../components/UiKit/Table'
import React from 'react'
// import { TTimeOffRequestsData } from 'screens/client/TimeOffRequests/TimeOffRequests.reducer'
// import { IndeterminateCheckbox } from '../../../components/UiKit/Table/components/IndeterminateCheckbox'
import moment from 'moment'
import { TState } from '../../../store'
import { useSelector } from 'react-redux'
import SelectVacancyStatus from './SelectVacancyStatus/SelectVacancyStatus'

export type TVacanciesData = {
  title: any
  rank: any
  project: any
  typeOfEmployment: any
  dateOfClosing: string
  location: string
  recruiter: string
  status: string
  englishLevel: any
  countOfHours: string
}

type TColumnsManagerOptions = {
  role: number
  //   handleCancelRequest: (id: number | string) => void
  isOnlyManager: boolean
}
type TColumnsManager = (options: TColumnsManagerOptions) => Array<Column<TVacanciesData>>

// ADD LOCALIZATION*
export const Columns: TColumnsManager = ({}) => {
  // const { userData } = useUser()
  const words = useSelector((state: TState) => state.global.language.words)
  const statusOptions = useSelector((state: TState) => state.admin.vacancies.statusOptions)
  const columnsManager: Array<Column<TVacanciesData>> = [
    {
      Header: words['user.profile.career.position'],
      id: 'title',
      accessor: d => words[d.title.name],
      minWidth: 210
    },
    {
      Header: words['user.profile.career.level'],
      id: 'rank',
      accessor: d => words[d.rank.name],
      minWidth: 100
    },
    {
      Header: words['admin.vacancies.project'],
      id: 'project',
      accessor: d => d.project.name,
      minWidth: 200
    },
    {
      Header: words['user.profile.career.occupation'],
      id: 'typeOfEmployment',
      accessor: d => words[d.typeOfEmployment.name],
      minWidth: 70
    },
    {
      Header: words['user.profile.mainInfo.location'],
      id: 'location',
      accessor: d => d.location,
      minWidth: 70
    },
    {
      Header: words['admin.vacancies.dateOfClosing'],
      id: 'dateOfClosing',
      //   accessor: d => `${d.hours}:00`,
      accessor: d =>
        d.dateOfClosing
          ? moment(d.dateOfClosing).format('DD.MM.YYYY')
          : moment(d.dateOfClosing).format('DD.MM.YYYY'),
      width: 130
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>
          {words['admin.vacancies.responsibleRecruiter']}
        </TableSortHeader>
      ),
      id: 'recruiter',
      accessor: d => d.recruiter,
      minWidth: 100
    },
    {
      Header: words['user.profile.table.status'],
      id: 'status',
      accessor: d => {
        return <SelectVacancyStatus vacancy={d} defaultOption={d.status} options={statusOptions} />
      },
      minWidth: 100
    }
  ]

  return columnsManager
}
