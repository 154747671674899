import React, { useEffect, useState } from 'react'
import styles from './Settings.module.scss'
import { SettingsTimeOfRequest } from './components/SettingsTimeOfRequest'
import queryString from 'query-string'
import { IToggleBarData, ToggleBar } from '../../../components/ToggleBar'
import { SettingsPointsSystem } from './components/SettingsPointsSystem'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { EAdminSettings } from 'globalConfigs'
import { SettingsSubcontract } from './components/SettingSubcontract'
import SettingsTaskCancellation from './components/SettingsTaskCancellation/SettingsTaskCancellation'
import { TSettingsProps } from './Settings.model'

export const Settings: React.FC<TSettingsProps> = ({
  allTokens,
  history,
  tokens,
  user,
  fetchTokens,
  changeAdminTokenStatusThunk,
  addToken,
  fetchAllTokensThunk,
  loading,
  cancelledTasks,
  getCancelledTasks
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const parsedType = queryString.parse(history.location.search).type

  useEffect(() => {
    fetchTokens(user.id)
    fetchAllTokensThunk()
  }, [])

  const toggleBarOptions = [
    {
      value: EAdminSettings.TASK_CANCELLATION,
      title: words['admin.settings.subcontract.cancelledTasks']
    },
    {
      value: EAdminSettings.CALENDAR,
      title: words['sidebar.dayOffTracker']
    },
    {
      value: EAdminSettings.POINTS_SYSTEM,
      title: words['sidebar.pointsSystem']
    },
    {
      value: EAdminSettings.SUBCONTRACT,
      title: words['sidebar.subcontract']
    }
  ]

  const getSelectedType = (type: string | (string | null)[] | null): IToggleBarData => {
    switch (type) {
      case EAdminSettings.TASK_CANCELLATION:
        return toggleBarOptions[0]
      case EAdminSettings.CALENDAR:
        return toggleBarOptions[1]
      case EAdminSettings.POINTS_SYSTEM:
        return toggleBarOptions[2]
      case EAdminSettings.SUBCONTRACT:
        return toggleBarOptions[3]
      default:
        return toggleBarOptions[0]
    }
  }

  const [selectedType, setSelectedType] = useState<IToggleBarData>(getSelectedType(parsedType))
  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>{words['admin.header.settings']}</h1>
      </section>
      <div className={styles.toggle}>
        <ToggleBar
          name="settings"
          data={toggleBarOptions}
          defaultChecked={selectedType}
          onChange={event => {
            history.push(
              `${history.location.pathname}?${queryString.stringify({
                type: event.value
              })}`
            )
            setSelectedType(event)
          }}
        />
      </div>
      {selectedType.value === EAdminSettings.TASK_CANCELLATION && (
        <SettingsTaskCancellation
          cancelledTasks={cancelledTasks}
          loading={loading}
          getCancelledTasks={getCancelledTasks}
        />
      )}
      {selectedType.value === EAdminSettings.POINTS_SYSTEM && <SettingsPointsSystem />}
      {selectedType.value === EAdminSettings.CALENDAR && <SettingsTimeOfRequest />}
      {selectedType.value === EAdminSettings.SUBCONTRACT && (
        <SettingsSubcontract
          tokens={tokens}
          changeAdminTokenStatusThunk={changeAdminTokenStatusThunk}
          addToken={addToken}
          allTokens={allTokens}
        />
      )}
    </div>
  )
}
