import React, { FC, InputHTMLAttributes } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import cls from 'classnames'
import styles from '../Inputs/Input.module.scss'
import { Tooltip as ReactTooltip } from 'react-tooltip'

export type TBaseSearchPlacesProps = InputHTMLAttributes<HTMLInputElement> & {
  disabled?: boolean
  errorMessage?: string
  isInvalid?: boolean
  name: string
  required?: boolean
  label?: string
  variant?: 'default' | 'outlined'
  withoutError?: boolean
  input?: any
}

const apiKey = process.env.REACT_APP_GOOGLE_PLACES_API

export const SearchPlaces: FC<TBaseSearchPlacesProps> = ({
  variant,
  required,
  isInvalid,
  errorMessage,
  withoutError,
  disabled,
  name,
  label,
  ...input
}) => {
  const isOutline = variant === 'outlined'
  return (
    <>
      {isOutline && label && (
        <label
          htmlFor={name}
          className={cls({
            [styles['label-outlined']]: true,
            [styles['label-outlined-required']]: required,
            [styles['label-outlined-error']]: isInvalid && errorMessage
          })}
        >
          {label && label}
        </label>
      )}
      <div
        className={cls({
          [styles.wrapper]: true,
          [styles['wrapper-outlined']]: isOutline,
          [styles['wrapper-outlined-required']]: isOutline && required,
          [styles['wrapper-outlined-error']]: isOutline && isInvalid && errorMessage,
          [styles['wrapper-required']]: required,
          [styles['wrapper-error']]: isInvalid && errorMessage,
          [styles['wrapper-disabled']]: disabled
        })}
        id={name}
      >
        <GooglePlacesAutocomplete
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /*
          // @ts-ignore */
          selectProps={{
            placeholder: input.value,
            onChange: event => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              input.onChange(event.label)
            },
            styles: {
              container: provided => ({
                ...provided,
                width: 'inherit',
                position: 'absolute',
                bottom: 0
              }),
              control: provided => ({
                ...provided,
                border: 'none',
                minHeight: 'initial',
                borderRadius: '10px',
                boxShadow: 'none'
              }),
              indicatorsContainer: () => ({
                display: 'none'
              }),
              placeholder: provided => ({
                ...provided,
                maxWidth: 'calc(100% - 8px)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: 'rgb(17, 1, 1)'
              })
            }
          }}
          apiKey={apiKey}
          autocompletionRequest={{
            types: ['(cities)']
          }}
        />
      </div>
      {!withoutError && isInvalid && errorMessage && (
        <ReactTooltip
          id={name}
          anchorSelect={`#${name}`}
          place="bottom"
          variant="error"
          content={errorMessage}
          className={styles.tooltip}
        />
      )}
    </>
  )
}
