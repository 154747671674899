import React, { FC, useEffect } from 'react'
import { TInvoiceProps } from '../SubcontractInvoice/Invoice.model'
import styles from './InvoiceTable.module.scss'
import backIcon from 'assets/images/back_arrow_icon.svg'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from '../../../../../store'
import { clearInvoice, getProjectInvoices, getUserProjectInvoices } from '../../Subcontract.thunk'

import { Table as UIKitTable } from '../../../../../components/UiKit/Table'
import { Columns } from './ProjectInvoicesTable.columns'
import { ERowSize } from '../../../../../globalConfigs'

const ProjectInvoicesTable: FC<TInvoiceProps> = () => {
  const history = useHistory()
  const invoices = useSelector((state: TState) => state.client.subcontract.invoices)
  const dispatch = useDispatch()
  const { id }: { id?: string } = useParams()
  const words = useSelector((state: TState) => state.global.language.words)
  const columns = Columns({ role: 1, isOnlyManager: false })
  useEffect(() => {
    if (id) {
      const getInvoices = () => {
        dispatch(getProjectInvoices(+id))
      }
      getInvoices()
      return () => {
        dispatch(clearInvoice())
      }
    } else {
      const getInvoices = () => {
        dispatch(getUserProjectInvoices())
      }
      getInvoices()
      return () => {
        dispatch(clearInvoice())
      }
    }
  }, [])
  return (
    <>
      <div className={styles.container}>
        <header className={styles.header}>
          <h1>{words['user.projectInvoicesTable.invoices']}</h1>
        </header>
        <div onClick={() => history.goBack()} className={styles.backButton}>
          <img src={backIcon} alt="" />
          <span>{words['user.projectInvoicesTable.back']}</span>
        </div>
        <div className={styles.invoiceTable}>
          <UIKitTable
            // key={some}
            data={invoices}
            columns={columns}
            total={5}
            getData={() => ({})}
            rowSize={ERowSize.MIDDLE}
            classNameToRow={styles.cursor}
            tableHeight={'40vh'}
            noData={words['user.subcontract.taskListIsEmpty']}
          />
        </div>
      </div>
    </>
  )
}

export default ProjectInvoicesTable
