import React, { FC, useEffect } from 'react'
import logo from '../../assets/images/logo-small.svg'
import styles from './style.module.scss'
import cls from 'classnames'

import { TSpanProps } from './types'

const Spinner: FC<TSpanProps> = ({ size = 'default', isBackground, isTransaction }) => {
  useEffect(() => {
    const originalOverflow = document.body.style.overflow

    if (isTransaction) {
      document.body.style.overflow = 'hidden'
      document.body.style.height = '100vh'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = originalOverflow
      document.body.style.height = 'auto'
    }
  }, [])

  return (
    <div className={cls({ [styles.spinnerWrap]: isBackground })}>
      <div className={cls({ [styles.spinner]: true, [styles['spinner-btn']]: size === 'button' })}>
        <img src={logo} alt="logo" />
      </div>
    </div>
  )
}

export default Spinner
