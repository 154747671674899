import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'
import { Invoices as InvoicesComponent } from './Invoices'
import { getOneProjectInvoice } from './Invoices.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    invoice: state.admin.projects.invoice,
    projectInvoices: state.admin.projects.projectInvoices,
    loading: state.admin.projects.loading,
    selectedProjectInvoices: state.admin.projects.selectedProjectInvoices
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getOneProjectInvoice
    },
    dispatch
  )

export const Invoices = connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicesComponent)
