import { CSSProperties } from 'react'
import { TWords } from 'elements/SideBar/SideBar.config'
import styles from '../../EditProfile.module.scss'
import { config, LIFE_PRIORITIES, AMOUNT_OF_CHILDREN } from 'globalConfigs'
import { TFieldConfigRow } from 'components/UiKit/FieldFactory/FieldFactory.model'
import { TFinancialInfoOptions } from './EditMainInfo.model'

export const contactInfoFieldsConfig = (words: TWords): TFieldConfigRow[] => {
  const mainInfoRow: TFieldConfigRow = {
    style: {
      justifyContent: 'space-between',
      marginBottom: '32px'
    },
    items: [
      {
        name: 'email',
        label: 'E-mail',
        inputWrapperClassName: styles['description-email'],
        component: () => {
          return {
            type: 'input',
            props: {
              className: styles['disabled-input'],
              disabled: true,
              variant: 'outlined',
              placeholder: 'E-mail'
            }
          }
        }
      },
      {
        name: 'phoneNumber',
        label: words['user.profile.mainInfo.phone'],
        required: true,
        isPhoneNumber: true,
        inputWrapperClassName: styles['description-phone'],
        component: () => {
          return {
            type: 'input',
            props: {
              variant: 'outlined',
              placeholder: words['user.profile.mainInfo.phone']
            }
          }
        }
      },
      {
        name: 'city',
        label: words['user.profile.mainInfo.location'],
        required: true,
        inputWrapperClassName: styles['description-location'],
        component: () => {
          return {
            type: 'input',
            props: {
              variant: 'outlined'
            }
          }
        }
      }
    ]
  }

  const socialsRow = {
    style: {
      justifyContent: 'space-between'
    },
    items: [
      {
        name: 'socialNetworks.linkedin',
        label: 'LinkedIn',
        inputWrapperClassName: styles['description-sociale'],
        component: () => {
          return {
            type: 'input',
            props: {
              variant: 'outlined'
            }
          }
        }
      },
      {
        name: 'socialNetworks.facebook',
        label: 'Facebook',
        inputWrapperClassName: styles['description-sociale'],
        component: () => {
          return {
            type: 'input',
            props: {
              variant: 'outlined'
            }
          }
        }
      },
      {
        name: 'socialNetworks.instagram',
        label: 'Instagram',
        inputWrapperClassName: styles['description-sociale'],
        component: () => {
          return {
            type: 'input',
            props: {
              variant: 'outlined'
            }
          }
        }
      }
    ]
  }

  return [mainInfoRow, socialsRow]
}

export const personalInfoFieldsConfig = (words: TWords, options: any): TFieldConfigRow[] => {
  const { amountOfChildren, maritalStatus, academicDegree } = options
  const getChildrenFields = (children: string) => {
    const childrenRow: any = [
      {
        name: 'amountOfChildren',
        label: words['user.profile.mainInfo.numberOfChildren'],
        inputWrapperClassName: styles['description-amount-of-children'],
        component: () => {
          return {
            type: 'select',
            props: {
              options: AMOUNT_OF_CHILDREN(words)
            }
          }
        }
      }
    ]
    for (let x = 0; x < +children; x++) {
      childrenRow.push({
        name: `children[${x}]`,
        label: words['user.profile.mainInfo.dob'],
        inputWrapperClassName: styles['description-DOBchildren'],
        required: true,
        component: ({ input }: any) => {
          return {
            type: 'datePicker',
            props: {
              autoComplete: 'off',
              dateFormat: config.dateFormat,
              selected: input.value ? new Date(input.value) : null
            }
          }
        }
      })
    }
    return childrenRow
  }

  const row1: TFieldConfigRow = {
    style: {
      marginBottom: '32px'
    },
    items: [
      {
        name: 'dateOfBirth',
        label: words['user.profile.mainInfo.dob'],
        required: true,
        inputWrapperClassName: styles['description-DOB'],
        component: ({ input }: any) => {
          return {
            type: 'datePicker',
            props: {
              autoComplete: 'off',
              dateFormat: config.dateFormat,
              selected: input.value ? new Date(input.value) : null
            }
          }
        }
      },
      {
        name: 'hometown',
        label: words['user.profile.mainInfo.nativeCity'],
        required: true,
        inputWrapperClassName: styles['description-hometown'],
        component: () => {
          return {
            type: 'input',
            props: {
              variant: 'outlined'
            }
          }
        }
      }
    ]
  }

  const row2: TFieldConfigRow = {
    items: [
      {
        name: 'hobby',
        label: words['user.profile.mainInfo.hobbies'],
        inputWrapperClassName: styles['description-hobby'],
        component: () => {
          return {
            type: 'textarea',
            props: {}
          }
        }
      }
    ]
  }

  const row3: TFieldConfigRow = {
    items: [
      {
        name: 'lifeGoals',
        label: words['user.profile.mainInfo.lifeGoal'],
        inputWrapperClassName: styles['description-life-goal'],
        component: () => {
          return {
            type: 'textarea',
            props: {}
          }
        }
      }
    ]
  }

  const row4: TFieldConfigRow = {
    style: {
      marginBottom: '32px'
    },
    items: [
      {
        name: 'university',
        label: words['user.profile.mainInfo.university'],
        inputWrapperClassName: styles['description-university'],
        component: () => {
          return {
            type: 'input',
            props: {
              variant: 'outlined'
            }
          }
        }
      }
    ]
  }

  if (academicDegree)
    row4.items.push({
      name: 'academicDegree',
      label: words['user.profile.mainInfo.degree'],
      inputWrapperClassName: styles['description-academic-degree'],
      component: () => {
        return {
          type: 'select',
          props: {
            placeholder: words['user.editProfile.notSelected'],
            options: academicDegree
          }
        }
      }
    })

  const row5: TFieldConfigRow = {
    style: {
      marginBottom: '32px'
    },
    items: [
      {
        name: 'lifePriorities',
        label: words['user.profile.mainInfo.lifePriorities'],
        inputWrapperClassName: styles['description-lifePriorities'],
        component: ({ meta }) => {
          return {
            type: 'multiSelect',
            props: {
              options: LIFE_PRIORITIES(words),
              isCheckboxesStyle: true,
              isMulti: true,
              closeMenuOnSelect: false,
              hideSelectedOptions: false,
              errorMessage: meta.error,
              placeholder: words['user.editProfile.notSelected'],
              styles: {
                placeholder: (provided: CSSProperties) => ({
                  ...provided,
                  color: '#333333',
                  fontSize: '12px',
                  lineHeight: '14px'
                }),
                valueContainer: (provided: CSSProperties) => ({
                  ...provided,
                  verticalAlign: 'middle',
                  minHeight: '27px'
                }),
                container: (provided: CSSProperties) => ({
                  ...provided,
                  verticalAlign: 'middle',
                  height: 'unset'
                }),
                input: (provided: CSSProperties) => ({
                  ...provided,
                  position: 'absolute',
                  bottom: '0px',
                  right: 0
                })
              }
            }
          }
        }
      }
    ]
  }

  if (maritalStatus)
    row5.items.push({
      name: 'maritalStatus',
      label: words['user.profile.mainInfo.martialStatus'],
      inputWrapperClassName: styles['description-marital-status'],
      component: () => {
        return {
          type: 'select',
          props: {
            placeholder: '',
            options: maritalStatus
          }
        }
      }
    })
  const row6: TFieldConfigRow = {
    style: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    items: getChildrenFields(amountOfChildren)
  }

  return [row1, row2, row3, row4, row5, row6]
}

export const financialInfoFieldsConfig = (
  words: TWords,
  options: TFinancialInfoOptions
): TFieldConfigRow[] => {
  const { banks } = options
  const row: TFieldConfigRow = {
    items: []
  }

  if (banks)
    row.items.push({
      name: 'bank',
      label: words['user.profile.mainInfo.financialInfo.bank'],
      required: false,
      inputWrapperClassName: styles['description-bank'],
      component: () => {
        return {
          type: 'select',
          props: {
            placeholder: '',
            options: banks || []
          }
        }
      }
    })

  return [row]
}
