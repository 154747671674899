import { BaseButton } from 'components/UiKit/Button/BaseButton'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import React, { FC, useState } from 'react'
import { Form } from 'react-final-form'
import styles from './CryptoApi.module.scss'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import binanceScreen from '../.,/../../../../../assets/images/binanceKeyAboutScreen.svg'
import queryString from 'query-string'
import cls from 'classnames'

const CryptoApi: FC<any> = ({ history, user, getInstanceThunk, botStatus, exchangeType }: any) => {
  console.log('🚀 ~ file: CryptoApi.tsx:17 ~ botStatus:', botStatus)
  const words = useSelector((state: TState) => state.global.language.words)
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  const [exchangeValues] = useState({
    exchange: exchangeType,
    key: 'Hdbrls25gjsQKCYd9E',
    secret: 'VNwQon1PeWWNyKEchIqtEy4w3rOUTmNsbmor'
  })

  async function onSubmit(values: any) {
    if (!exchangeType) {
      history.goBack()
      return
    }
    console.log(values)
    try {
      await getInstanceThunk(exchangeValues, user)

      history.push(
        `${history.location.pathname}?${queryString.stringify({
          ...params,
          typeStep: '',
          type: 'general',
          stats: 'false',
          apiSetted: 'true'
        })}`
      )
    } catch (error) {
      console.log(error)
    }
  }

  const [actualStep, setActualStep] = useState(1)

  return (
    <>
      {params.how !== 'api' ? (
        <div className={styles['api']}>
          <h2 className={styles['main-title']}>{words['crypto-bot.enterKeys']}</h2>
          <p className={styles.description}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.
          </p>
          <p
            className={styles['how-get']}
            onClick={() => {
              history.push(
                `${history.location.pathname}?${queryString.stringify({
                  ...params,
                  how: 'api'
                })}`
              )
            }}
          >
            {words['crypto-bot.howToGetKeys']}
          </p>
          <div className={styles.inputs}>
            <Form onSubmit={onSubmit}>
              {({ form, handleSubmit }) => {
                // const formState = form.getState()
                return (
                  <form onSubmit={handleSubmit} name="form">
                    <FieldFactory
                      // currentLanguage={currentLanguage}
                      form={form}
                      config={[
                        {
                          style: {
                            width: '360px',
                            marginBottom: '-8px'
                          },
                          items: [
                            {
                              name: `key`,
                              label: 'API KEY',
                              required: false,
                              inputWrapperClassName: styles['key'],
                              component: () => {
                                return {
                                  type: 'input',
                                  props: {
                                    placeholder: '',
                                    options: '',
                                    variant: 'outlined',
                                    value: 'Hdbrls25gjsQKCYd9E'
                                  }
                                }
                              }
                            }
                          ]
                        }
                      ]}
                      words={words}
                    />
                    <FieldFactory
                      // currentLanguage={currentLanguage}
                      form={form}
                      config={[
                        {
                          style: {
                            width: '360px',
                            marginBottom: '16px'
                          },
                          items: [
                            {
                              name: `secret`,
                              label: 'API SECRET KEY',
                              required: false,
                              inputWrapperClassName: styles['key'],
                              component: () => {
                                return {
                                  type: 'input',
                                  props: {
                                    placeholder: '',
                                    options: '',
                                    variant: 'outlined',
                                    value: 'VNwQon1PeWWNyKEchIqtEy4w3rOUTmNsbmor'
                                  }
                                }
                              }
                            }
                          ]
                        }
                      ]}
                      words={words}
                    />
                    <BaseButton
                      outline={false}
                      color={'default'}
                      className={'requesth'}
                      children={words['crypto-bot.next']}
                      type="submit"
                    />
                  </form>
                )
              }}
            </Form>
          </div>
        </div>
      ) : (
        <div className={styles['how-get-key-div']}>
          <h2 className={styles['main-title']}>{words['crypto-bot.howToGetKeys']}</h2>
          <div className={styles.steps}>
            <div className={cls({ [styles.step]: true, [styles.action]: actualStep >= 1 })}>
              <span>1</span>
            </div>
            <div className={styles.line} />
            <div className={cls({ [styles.step]: true, [styles.action]: actualStep >= 2 })}>
              <span>2</span>
            </div>
            <div className={styles.line} />
            <div className={cls({ [styles.step]: true, [styles.action]: actualStep >= 3 })}>
              <span>3</span>
            </div>
          </div>
          <div className={styles.about}>
            <img src={binanceScreen} />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.
            </p>
          </div>
          <div className={styles.btn}>
            <BaseButton
              size="lgs"
              outline={true}
              children="Back"
              onClick={() => history.goBack()}
            />
            <BaseButton
              size="lgs"
              children="Next"
              onClick={() => {
                setActualStep(actualStep === 3 ? 1 : actualStep + 1)
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default CryptoApi
