import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import styles from './style.module.scss'
import { Button } from 'components/UiKit/Button'
import cls from 'classnames'

interface RestoreUserModalProps {
  projectId: number
  isOpen: any
  deleted: any
  toggleModal: () => any
  action: (id: any) => any
}

export const RestoreOrDeleteProjectModal: FC<RestoreUserModalProps> = ({
  projectId,
  isOpen,
  deleted,
  toggleModal,
  action
}) => {
  const words = useSelector((state: TState) => state.global.language.words)

  const onActionClick = () => {
    toggleModal()
    action(projectId)
  }

  return (
    <div>
      <div onClick={toggleModal} className={cls({ [styles.overlay]: isOpen })} />
      <div className={cls({ [styles.container]: true, [styles.active]: isOpen })}>
        <div className={styles['modal']}>
          <h2>{deleted ? words['project.restore'] : words['project.toArchive']}</h2>
          <div className={styles['buttons']}>
            <Button size="lgs" outline onClick={toggleModal}>
              {words['project.cancel']}
            </Button>
            <Button size="lgs" onClick={onActionClick}>
              {deleted ? words['project.restore'] : words['project.toArchive']}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
