import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './GeneralInfo.module.scss'
import Spinner from 'components/Spinner'
import { TGeneralInfoProps } from './GeneralInfo.model'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { api, API } from 'services/api'

const GeneralInfo: React.FC<TGeneralInfoProps> = ({
  exchangeInfo,
  isLoading,
  user,
  getExchangeInfoThunk,
  exchangeType
}) => {
  const words = useSelector((state: TState) => state.global.language.words)

  const [botId, setBotId] = useState<string | null>(null)

  const getBotId = useCallback(async () => {
    try {
      const { data } = await api.get(`${API.URL}/crypto-bot/settings/${user.id}`)

      setBotId(data.bot_id)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getBotId()
  }, [])

  const getCurrentExchangeInfo = useMemo(() => {
    if (!exchangeInfo || !botId) {
      return null
    }

    return exchangeInfo.find(({ exchange }) => exchange === exchangeType) || null
  }, [exchangeInfo, botId])

  useEffect(() => {
    if (!exchangeInfo && botId) {
      getExchangeInfoThunk(botId)
    }
  }, [botId, exchangeInfo])

  console.log(getCurrentExchangeInfo)

  return (
    <>
      {!isLoading && getCurrentExchangeInfo ? (
        <section className={styles.generalInfoSection}>
          <p className={styles.generalInfoItem}>
            <strong className={styles.generalInfoText}>
              {words['crypto-bot.walletBalance']} (USDT)
            </strong>

            <span className={styles.generalInfoValue}>
              {getCurrentExchangeInfo.totalCrossWalletBalance}
            </span>
          </p>

          <p className={styles.generalInfoItem}>
            <strong className={styles.generalInfoText}>
              {words['crypto-bot.unrealizedProfit']} (USDT)
            </strong>

            <span
              className={styles.generalInfoValue}
              style={{
                color: getCurrentExchangeInfo.totalUnrealizedProfit > 0 ? '#149B58' : '#E12F75'
              }}
            >
              {getCurrentExchangeInfo.totalUnrealizedProfit}
            </span>
          </p>

          <p className={styles.generalInfoItem}>
            <strong className={styles.generalInfoText}>
              {words['crypto-bot.netCapitalSize']} (USDT)
            </strong>

            <span className={styles.generalInfoValue}>{getCurrentExchangeInfo.equity}</span>
          </p>

          <p className={styles.generalInfoItem}>
            <strong className={styles.generalInfoText}>
              {words['crypto-bot.availableBalance']} (USDT)
            </strong>

            <span className={styles.generalInfoValue}>
              {getCurrentExchangeInfo.availableBalance}
            </span>
          </p>

          <p className={styles.generalInfoItem}>
            <strong className={styles.generalInfoText}>
              {words['crypto-bot.marginPosition']} (USDT)
            </strong>

            <span className={styles.generalInfoValue}>
              {getCurrentExchangeInfo.totalPositionInitialMargin}
            </span>
          </p>
        </section>
      ) : (
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      )}
    </>
  )
}

export default GeneralInfo
