import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import ProfitGraphComponent from './ProfitGraph'
import { getGraphStatisticThunk } from '../ControlPanel/ControlPanel.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    user: state.auth.data,
    isLoading: state.client.controlPanel.isLoading,
    graphStatistic: state.client.controlPanel.graphStatistic,
    exchangeType: state.client.controlPanel.botInfo.exchangeType
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getGraphStatisticThunk }, dispatch)

export const ProfitGraph = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfitGraphComponent)
