import React from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import styles from '../../CreateInvoices.module.scss'
import { FormApi } from 'final-form'

type TInputInvoiceProps = {
  form: FormApi
  name: string
  placeholder: string
}

const InputInvoice = ({ form, name, placeholder }: TInputInvoiceProps) => {
  const words = useSelector((state: TState) => state.global.language.words)
  return (
    <FieldFactory
      // className={styles['input-factory']}
      form={form}
      config={[
        {
          items: [
            {
              name: name,
              inputWrapperClassName: styles['input-warp'],
              component: () => {
                return {
                  type: 'input',
                  props: {
                    placeholder: placeholder === 'USD' ? '$' : '₴',
                    variant: 'outlined'
                  }
                }
              }
            }
          ]
        }
      ]}
      words={words}
    />
  )
}
export default InputInvoice
