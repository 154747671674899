import React, { FC } from 'react'
import styles from './ToggleButton.module.scss'
import { TBaseProps } from './BaseButton'
import cls from 'classnames'
import { Field } from 'react-final-form'

export type ToggleButton = TBaseProps

export const ToggleButton: FC<ToggleButton> = ({
  label = '',
  disabled = false,
  reverse = false,
  checked = false,
  index,
  onCheng,
  name
}) => (
  <div className={cls({ [styles.container]: true, [styles.reverse]: reverse })}>
    <label
      htmlFor={`toggle-button${index}`}
      className={cls({ [styles.text]: true, [styles.inactive]: disabled })}
      onChange={event => {
        event.stopPropagation()
        onCheng()
      }}
    >
      {label === '' ? (disabled ? 'Active' : 'Inactive') : label}
    </label>

    {!name ? (
      <input
        type="checkbox"
        id={`toggle-button${index}`}
        className={styles['toggle-button']}
        checked={checked}
        onChange={event => {
          event.stopPropagation()
          onCheng()
        }}
      />
    ) : (
      <Field
        name={name}
        component="input"
        type="checkbox"
        className={styles['toggle-button']}
        disabled={disabled}
      />
    )}
  </div>
)
