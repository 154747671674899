import { Column } from 'react-table'
import React, { useEffect, useMemo } from 'react'
import Checkbox from 'components/UiKit/Checkbox/Checkbox'
import { TChecked, ICheckedList } from '../../Entrepreneurs.model'
import { setEntrepreneurs } from '../../Entrepreneurs.actions'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store'
import { TUserData } from 'screens/admin/Users/Users.model'

export type TEntrepreneursColumns = {
  name: string
  bank: string
  check: boolean
}

type TColumnsManagerOptions = {
  role: number
  filteredEntrepreneurs: TUserData[]
  checksEntrepreneurs: TChecked[]
  formValues: ICheckedList
  setFormValues: (value: ICheckedList) => void
  isChecked: boolean
  setIsChecked: (value: boolean) => void
}
type TColumnsManager = (options: TColumnsManagerOptions) => Array<Column<TEntrepreneursColumns>>

export const Columns: TColumnsManager = ({
  filteredEntrepreneurs,
  checksEntrepreneurs,
  formValues,
  setFormValues,
  isChecked,
  setIsChecked
}) => {
  const dispatch = useDispatch()
  const words = useSelector((state: TState) => state.global.language.words)

  const checkedMemo = useMemo(() => {
    if (isChecked) {
      return filteredEntrepreneurs.reduce((accumulator: ICheckedList, currentItem: TUserData) => {
        return {
          ...accumulator,
          [currentItem.fullName + currentItem.id]: {
            ...currentItem,
            checked: true
          }
        }
      }, {})
    } else if (checksEntrepreneurs.length < filteredEntrepreneurs.length) {
      const checkedUsers = Object.values(formValues).filter(user => user.checked === true)

      return checkedUsers.reduce((accumulator: ICheckedList, currentItem: TChecked) => {
        return {
          ...accumulator,
          [currentItem.fullName + currentItem.id]: {
            ...currentItem
          }
        }
      }, {})
    } else {
      return {}
    }
  }, [isChecked, filteredEntrepreneurs, formValues])

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  useEffect(() => {
    setFormValues(checkedMemo)
  }, [isChecked, filteredEntrepreneurs])

  useEffect(() => {
    dispatch(setEntrepreneurs(checksEntrepreneurs))
  }, [filteredEntrepreneurs, checksEntrepreneurs])

  const columnsManager: Array<Column<TEntrepreneursColumns>> = [
    {
      Header: () => {
        return <Checkbox name="check" checked={isChecked} onChange={handleCheckboxChange} />
      },
      id: 'check',
      accessor: d => d.check,
      width: 150
    },
    {
      Header: words['admin.entrepreneurs.fullName'],
      id: 'name',
      accessor: d => d.name,
      width: 350
    },
    {
      Header: words['admin.entrepreneurs.bank'],
      id: 'bank',
      accessor: d => d.bank,
      width: 600
    }
  ]

  return columnsManager
}
