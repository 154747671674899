/* eslint-disable no-console */
import React from 'react'
import styles from './DraftsList.module.scss'
import { TaskInfo } from '../TaskInfo'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import InfiniteScroll from 'react-infinite-scroll-component'
import queryString from 'query-string'
import history from 'utils/history'
import { finishPoint } from 'screens/client/Subcontract/components/CreateTask/initialvalues'
import Spinner from 'components/Spinner'

export const DraftsList = ({
  btnQuery,
  rerender,
  setRerender,
  urlEndpoint,
  getTasks,
  isTransaction
}: any) => {
  const stores = useSelector((state: TState) => state.client.subcontract.tasks)
  const parsed = queryString.parse(history.location.search)

  const pageCount = useSelector((state: TState) => state.client.subcontract.pageCount)

  const urlSearchParamses = new URLSearchParams(window.location.search)
  const paramses = Object.fromEntries(urlSearchParamses.entries())
  const fetchMore = () => {
    const url = new URL((window.location as unknown) as string)
    url.searchParams.set('page', String((paramses.page ? +paramses.page : 0) + 1))
    window.history.pushState({}, '', url.toString())

    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    getTasks(10, params.page, urlEndpoint, finishPoint(), parsed.clear === 'true')
  }

  return (
    <>
      {isTransaction && <Spinner isBackground={true} isTransaction={isTransaction} />}
      <div className={styles['info-container']} style={{ width: '100%' }}>
        <InfiniteScroll
          dataLength={stores.length}
          next={fetchMore}
          hasMore={(paramses.page ? +paramses.page : 0) + 1 <= pageCount}
          loader={<h4>Loading...</h4>}
        >
          {stores.map((task: any) => (
            <TaskInfo
              getTasks={getTasks}
              key={task.id}
              id={task.id}
              status={task.status.id}
              path={`/dashboard/subcontract/${task.id}?my_task=true&from=${btnQuery}`}
              task={task}
              setRerender={setRerender}
              rerender={rerender}
            />
          ))}
        </InfiniteScroll>
      </div>
    </>
  )
}
