import React from 'react'
import Checkbox from '../../../UiKit/Checkbox/Checkbox'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteSelectedProjectInvoice,
  selectAllProjectInvoices,
  selectProjectInvoice
} from '../Invoices.actions'
import { TState } from '../../../../store'

const SelectInvoice = ({ invoice, name }: any) => {
  const dispatch = useDispatch()
  const projectInvoices = useSelector((state: TState) => state.admin.projects.projectInvoices)
  const selectedProjectInvoices = useSelector(
    (state: TState) => state.admin.projects.selectedProjectInvoices
  )
  const handleSelect = () => {
    const invoices = selectedProjectInvoices.filter((invoicik: any) => invoice.id === invoicik.id)
    if (name === 'selectAll' && selectedProjectInvoices.length === projectInvoices.length)
      return true
    if (name === 'selectAll') return false
    return Boolean(invoices.length)
  }

  handleSelect()
  const handleCheck = (e: any) => {
    if (e.target.checked) {
      if (name === 'selectAll') {
        dispatch(selectAllProjectInvoices(projectInvoices))
      } else {
        dispatch(selectProjectInvoice(invoice))
      }
    } else {
      dispatch(deleteSelectedProjectInvoice(invoice))
    }
  }
  return (
    <div onClick={e => e.stopPropagation()}>
      <Checkbox checked={handleSelect()} onChange={(e: any) => handleCheck(e)} name={name} />
    </div>
  )
}

export default SelectInvoice
