import React, { FC } from 'react'
import Modal from 'components/Modal'
import { Field, Form } from 'react-final-form'
import styles from './RegectCommentModal.module.scss'
import { Button } from 'components/UiKit/Button'
import validation from 'utils/validation'
import { Textarea } from 'components/Textarea'
import { RedNote } from '../../../../../components/RedNote'
import { TRegectCommentModal } from './RegectCommentModal.model'
import commentPopup from 'assets/images/comment_popup.svg'
import { useSelector } from 'react-redux'
import { TState } from 'store'

type TComments = {
  comments?: string
}
export const RegectCommentModal: FC<TRegectCommentModal> = ({ isShow, onClose, onSubmit }) => {
  const words = useSelector((state: TState) => state.global.language.words)

  return (
    <Modal isShow={isShow} onClose={onClose} className={styles.modal}>
      <Form
        onSubmit={(values: TComments) => {
          if (values.comments) {
            onSubmit(values.comments)
          }
        }}
      >
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className={styles.form}>
              <header>{words['manager.dayOffTracker.createRequest.commentPlaceholder']}</header>
              <div className={styles.image}>
                <img src={commentPopup} alt="comment-popup-image" />
              </div>
              <div className={styles['comments-wrapper']}>
                <Field
                  name="comments"
                  validate={validation.composeValidators(
                    validation.required(words['user.requiredMessage']),
                    validation.minValue(2)
                  )}
                >
                  {({ input, meta }) => {
                    return (
                      <div>
                        <Textarea
                          {...input}
                          className={styles['comments']}
                          title={
                            <>
                              {words['user.profile.table.comment']}
                              <RedNote />
                            </>
                          }
                          placeholder={
                            words['admin.settings.pointsSystem.balanceEdit.comment.placeHolder']
                          }
                          isInvalid={meta.error && meta.submitFailed}
                          errorMessage={meta.error}
                        />
                      </div>
                    )
                  }}
                </Field>
              </div>

              <div className={styles['form-row-btn']}>
                <Button type="submit" className={styles['div-btn-wrapper']}>
                  {words['admin.regectComment.submit']}
                </Button>
              </div>
            </form>
          )
        }}
      </Form>
    </Modal>
  )
}
