import React, { forwardRef } from 'react'
import styles from './IndeterminateCheckbox.module.scss'
import cls from 'classnames'

interface IProps {
  indeterminate?: boolean
  name: string
  disabled: boolean
  isHeader?: boolean
}

const useCombinedRefs = (...refs: any[]): React.MutableRefObject<any> => {
  const targetRef = React.useRef()

  React.useEffect(() => {
    refs.forEach(ref => {
      if (!ref) {
        return
      }

      if (typeof ref === 'function') {
        ref(targetRef.current)
      } else {
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}

export const IndeterminateCheckbox = forwardRef<HTMLInputElement, IProps>(
  ({ indeterminate, disabled, isHeader = false, ...rest }, ref) => {
    const defaultRef = React.useRef(null)
    const combinedRef = useCombinedRefs(ref, defaultRef)
    React.useEffect(() => {
      combinedRef.current.indeterminate = indeterminate
    }, [combinedRef, indeterminate])

    return (
      <label
        className={cls({
          [styles['wrapper-checkbox']]: true,
          [styles['wrapper-checkbox-disabled']]: disabled
        })}
        onClick={event => {
          event.stopPropagation()
        }}
      >
        {disabled ? (
          <input type="checkbox" disabled={disabled} ref={combinedRef} />
        ) : (
          <input type="checkbox" disabled={disabled} ref={combinedRef} {...rest} />
        )}
        <span
          className={cls({ [styles.checkmark]: true, [styles['checkmark-header']]: isHeader })}
        />
      </label>
    )
  }
)
