import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import CreateInvoicesComponent from './CreateInvoices'
import { setInvoices } from './CreateInvoices.thunk'
import { getInvoices } from '../Entrepreneurs.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    entrepreneurs: state.admin.entrepreneurs.entrepreneurs,
    invoicesArray: state.admin.entrepreneurs.invoices,
    downloadInvoices: state.admin.entrepreneurs.downloadInvoices,
    banks: state.admin.entrepreneurs.banks
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ setInvoices, getInvoices }, dispatch)

export const CreateInvoices = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateInvoicesComponent)
