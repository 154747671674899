import { TBaseFilterConfigData } from 'components/UiKit/Filter/Filter'
import { TSelectOption } from '../../../components/Select'
import { TWords } from 'elements/SideBar/SideBar.config'
import moment from 'moment'
import { getStatusOptions } from 'screens/client/Subcontract/components/CreateTask/initialvalues'
import { IToken } from '../Token/Token.model'
// import { useSelector } from 'react-redux'
// import { TState } from 'store'
// const positions = useSelector((state: TState) => state.client.editProfile.positions)

const getCurrencyList = (tokens: IToken[]) => {
  return tokens.map((token: IToken) => {
    return { value: String(token.id), label: token.symbol }
  })
}

export const getFilterConfig = ({
  role,
  positions,
  fromDate,
  toDate,
  rank,
  parsed,
  //   usersData = [],
  //   managersData = [],
  //   minFromDate,
  //   maxToDate,
  words,
  tokens
}: {
  rank: any
  positions: any
  role: number
  usersData?: TSelectOption[]
  managersData?: TSelectOption[]
  fromDate: any
  toDate: any
  parsed: any
  //   minFromDate: string | number
  //   maxToDate: string | number
  words: TWords
  tokens: IToken[]
}): TBaseFilterConfigData[] => {
  const statusOptions = getStatusOptions(words)
  //   const currentFormatMinFromDate = new Date(minFromDate).valueOf()
  //   const currentFormatMaxToDate = new Date(maxToDate).valueOf()
  switch (role) {
    // case 1:
    // case 2: {
    //   return [
    //     {
    //       name: 'authorId',
    //       type: 'select',
    //       fieldData: [{ value: '@ALL@', label: words['all'] }, ...usersData],
    //       label: words['roles.user'],
    //       placeholder: words['admin.users.table.Edit.name'],
    //       width: '19.3%'
    //     },
    //     {
    //       name: 'reporterId',
    //       type: 'select',
    //       fieldData: [{ value: '@ALL@', label: words['all'] }, ...managersData],
    //       label: words['roles.manager'],
    //       placeholder: words['admin.users.table.Edit.name'],
    //       width: '19.3%'
    //     },
    //     {
    //       name: 'requestType',
    //       type: 'select',
    //       fieldData: FILTER_SELECT_TYPE(words),
    //       label: words['manager.dayOffTracker.details.requestType'],
    //       placeholder: words['manager.dayOffTracker.details.requestType'],
    //       width: '11.3%'
    //     },
    //     {
    //       name: 'fromDate',
    //       secondName: 'toDate',
    //       type: 'dateRange',
    //       label: words['manager.dayOffTracker.details.requestDate'],
    //       fieldData: {
    //         from: currentFormatMinFromDate,
    //         to: currentFormatMaxToDate
    //       },
    //       width: 90
    //     },
    //     {
    //       name: 'status',
    //       type: 'select',
    //       fieldData: FILTER_SELECT_STATUS(words),
    //       label: words['user.profile.table.status'],
    //       placeholder: words['user.profile.table.status'],
    //       width: '12%'
    //     },
    //     {
    //       name: 'isActive',
    //       type: 'select',
    //       fieldData: FILTER_SELECT_ACCAUNT_STATUS(words),
    //       label: words['admin.table.accauntStatus'],
    //       placeholder: words['admin.table.accauntStatus'],
    //       width: '12%'
    //     }
    //   ]
    // }
    // case 3: {
    //   return [
    //     {
    //       name: 'authorId',
    //       type: 'select',
    //       fieldData: [{ value: '@ALL@', label: words['all'] }, ...usersData],
    //       label: words['roles.user'],
    //       placeholder: words['admin.users.table.Edit.name'],
    //       width: '25%'
    //     },
    //     {
    //       name: 'requestType',
    //       type: 'select',
    //       fieldData: FILTER_SELECT_TYPE(words),
    //       label: words['manager.dayOffTracker.details.requestType'],
    //       placeholder: words['manager.dayOffTracker.details.requestType'],
    //       width: '15%'
    //     },
    //     {
    //       name: 'fromDate',
    //       secondName: 'toDate',
    //       type: 'dateRange',
    //       label: words['manager.dayOffTracker.details.requestDate'],
    //       fieldData: {
    //         from: currentFormatMinFromDate,
    //         to: currentFormatMaxToDate
    //       },
    //       width: 100
    //     },
    //     {
    //       name: 'status',
    //       type: 'select',
    //       fieldData: FILTER_SELECT_STATUS(words),
    //       label: words['user.profile.table.status'],
    //       placeholder: words['user.profile.table.status'],
    //       width: '15%'
    //     }
    //   ]
    // }
    case 4:
    default: {
      return [
        {
          name: 'fullName',
          secondName: '',
          type: 'input',
          label: words['user.subcontract.task'],
          width: '30%'
        },
        {
          name: 'userName',
          secondName: '',
          type: 'input',
          label: words['user.subcontract.task.customer'],
          width: '20%'
        },
        {
          name: 'specialization',
          type: 'select',
          fieldData: positions,
          label: words['user.subcontract.taskFilter.specialization'],
          placeholder: '',
          width: '20%'
        },
        {
          name: 'level',
          type: 'select',
          fieldData: rank,
          label: words['user.subcontract.taskFilter.level'],
          placeholder: '',
          width: '20%'
        },
        {
          name: 'fromDate',
          secondName: 'toDate',
          type: 'dateRangeNew',
          label: words['user.subcontract.taskFilter.publicationDate'],
          fieldData: {
            from: fromDate ? moment(+fromDate).valueOf() : '',
            to: toDate ? moment(+toDate).valueOf() : ''
          },
          width: '30%'
        },
        {
          name: 'currency',
          type: 'select',
          label: words['user.subcontract.task.currency'],
          fieldData: getCurrencyList(tokens),
          placeholder: '',
          width: '7%'
        },
        {
          name: 'fromPrice',
          secondName: 'toPrice',
          type: 'inputRange',
          label: words['user.subcontract.task.budget'],
          fieldData: {
            from: parsed.fromPrice ? parsed.fromPrice : '',
            to: parsed.toPrice ? parsed.toPrice : ''
          },
          width: '100%',
          rangeContainerWidth: '20%',
          inputType: 'number'
        },
        {
          name: 'status',
          type: 'select',
          fieldData: statusOptions,
          label: words['user.profile.table.status'],
          placeholder: '',
          width: '13%'
        }
      ]
    }
  }
}
