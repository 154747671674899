import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { components } from 'react-select'
import * as Moment from 'moment'
import xorBy from 'lodash/xorBy'
import { extendMoment } from 'moment-range'
import 'moment-timezone'
import 'moment/locale/en-ca'
import 'moment/locale/ru'
import { DatePicker } from 'components/UiKit/DatePicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Button } from 'components/UiKit/Button'
import styles from './NewRequestTimeOff.module.scss'
import { /*Select, */ TSelectOption } from 'components/Select'
import { Select as NewUIKitSelect } from 'Select' // TODO -- rename alias
import { config, EPageLimits, TYPE_OF_REQUESTS_TIME_OFF } from 'globalConfigs'
import { Textarea } from 'components/Textarea'
import { ReactComponent as TimeIcon } from 'assets/images/time_icon.svg'
import { ReactComponent as TimeUnselect } from 'assets/images/timeUnselect.svg'
import { Field, Form } from 'react-final-form'
import { FormApi } from 'final-form'
import { isUser } from 'utils/user'
import {
  TBodyForTimeOffRequest,
  TDataForTable,
  TNewRequestTimeOffProps,
  TValuesForTimeOffRequest
} from './NewRequestTimeOff.model'
import validation from '../../../utils/validation'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import toastr from 'utils/toastr'
import cls from 'classnames'
import { currentBalance, TBalance } from '../../../utils/balance'
import { Icons } from '../../../components/Icons'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { useUser } from '../../../hooks/useUser'

const moment = extendMoment(Moment)

const TYPE_SELECT_MOCKS: TSelectOption[] = [
  // { value: '1', label: 'PENDING' }, TODO -- what this?
  { value: '2', label: 'Day-off' },
  { value: '3', label: 'Absent' },
  { value: '6', label: 'Remote' },
  { value: '5', label: 'Sick' },
  { value: '4', label: 'Self-paid' }
]

export const FORMAT_TIME = 'dddd, D MMMM, YYYY'
export const FORMAT_MMDDYYYY = 'MM/DD/YYYY'
export const getWorkday = (arr: string[] = []) => arr.filter(m => !m.toString().match(/Sat|Sun/))

const DropownIndicator = (props: PropsWithChildren<any>) => {
  return (
    <components.DropdownIndicator {...props}>
      <TimeIcon />
    </components.DropdownIndicator>
  )
}

const DisabledIndicator = (props: PropsWithChildren<any>) => {
  return (
    <components.DropdownIndicator {...props}>
      <TimeUnselect />
    </components.DropdownIndicator>
  )
}

const maxDate = moment().month(26)

export const NewRequestTimeOffComponent: FC<TNewRequestTimeOffProps> = ({
  data,
  user,
  setTimeOffPeriod,
  getManagers,
  getUsersList,
  usersList,
  dataManagers,
  history,
  match,
  getCurrentRequest,
  loadingSetNewRequest,
  role,
  getBalance,
  balance,
  userId,
  getProductCalendarDays,
  productCalendar
}) => {
  const isEdit = !!match.params.id
  const isAdmin = useUser().userData.admin

  const initialManager = () => {
    if (!user.reviewerId) {
      return
    }
    if (user.reviewerId === null) {
      return
    }
    if (!isUser(role)) {
      return {
        value: `${user.id}`,
        label: user.fullName
      }
    }
    return {
      value: `${user.reviewerId.id}`,
      label: user.reviewerId.fullName
    }
  }
  const [managersData, setManagersData] = useState<TSelectOption[]>([{ value: '', label: '' }])
  const [usersData, setUsersData] = useState<TSelectOption[]>([{ value: '', label: '' }])
  const [responsibleData, setResponsibleData] = useState<TSelectOption[]>([
    { value: '', label: '' }
  ])
  const [dataForTableDefault, setDataForTableDefault] = useState<TDataForTable[]>([
    { date: moment().format(FORMAT_MMDDYYYY), time: '0' }
  ])
  useEffect(() => {
    getProductCalendarDays()
  }, [])
  const [author, setAuthor] = useState()
  const [manager, setManager] = useState(initialManager())
  const [assignee, setAssignee] = useState()
  const words = useSelector((state: TState) => state.global.language.words)
  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)

  // const [firstDayState, setFirstDayState] = useState(moment().format(FORMAT_MMDDYYYY))
  // const [lastDayState, setLastDayState] = useState(moment().format(FORMAT_MMDDYYYY))
  useEffect(() => {
    getManagers({ limit: EPageLimits.MANAGERS })
    getUsersList()

    if (isEdit && isNaN(Number(match.params.id))) {
      history.push('/dashboard/requests')
    }

    if (isEdit) {
      const { id } = match.params
      getCurrentRequest(id)
    }
  }, [])

  useEffect(() => {
    if (!isEdit) {
      getBalance()
    }
  }, [])

  useEffect(() => {
    if (data && data.authorId && data.authorId.id) {
      getBalance(data.authorId.id)
    }
  }, [data])

  useEffect(() => {
    const filteredData: TSelectOption[] = dataManagers
      .map(item => ({
        value: `${item.id}`,
        label: item.fullName
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    setManagersData(filteredData)
  }, [dataManagers])

  useEffect(() => {
    const filteredData: TSelectOption[] = usersList
      .map((item: { id: number; fullName: string }) => ({
        value: `${item.id}`,
        label: item.fullName
      }))
      .sort((a: any, b: any) => a.label.localeCompare(b.label))
    setUsersData(filteredData)

    const filteredNewDate: TSelectOption[] = filteredData.filter(
      predicate => Number(predicate.value) !== user.id
    )
    const filteredResponsible: TSelectOption[] = [...filteredNewDate]
    setResponsibleData(filteredResponsible)
  }, [usersList])

  const isWorkingDay = (date: Moment.Moment) => {
    return (
      (!date.toString().match(/Sat|Sun/) ||
        (productCalendar.workingDays &&
          productCalendar.workingDays.filter(
            (item: any) =>
              moment(item.date, 'MM-DD-YYYY').format(FORMAT_MMDDYYYY) ===
              date.format(FORMAT_MMDDYYYY)
          ).length)) &&
      (productCalendar.nonWorkingDays &&
        !productCalendar.nonWorkingDays.filter(
          (item: any) =>
            moment(item.date, 'MM-DD-YYYY').format(FORMAT_MMDDYYYY) === date.format(FORMAT_MMDDYYYY)
        ).length)
    )
  }

  useEffect(() => {
    setDataForTableDefault(
      isWorkingDay(moment())
        ? [
            {
              date: moment().format(FORMAT_MMDDYYYY),
              time: balance.workingHoursPerDay && balance.workingHoursPerDay
            }
          ]
        : []
    )
  }, [balance.workingHoursPerDay, productCalendar])

  const language = useSelector((state: TState) => state.global.language.currentLanguage)

  useEffect(() => {
    moment.locale(language)
  }, [language])

  const excludeDates = (productCalendar.nonWorkingDays as Array<{
    date: string
    description: string
    id: number
  }>).map(({ date }) => new Date(moment(date, 'MM-DD-YYYY').toISOString()))
  const includeDates = (productCalendar.workingDays as Array<{
    date: string
    description: string
    id: number
  }>).map(({ date }) => moment(date, 'MM-DD-YYYY').format('DD-MM-YYYY'))

  const isWeekday = (date: Date) => {
    if (includeDates.includes(moment(date, 'MM-DD-YYYY').format('DD-MM-YYYY'))) {
      return true
    }
    const day = moment(date, 'MM-DD-YYYY').get('day')
    return day !== 0 && day !== 6
  }

  const selectTimeStyle = {
    container: (style: any) => ({
      ...style,
      width: '90px'
    }),
    singleValue: (style: any) => ({
      ...style,
      top: '43%'
    }),
    indicatorsContainer: (style: any) => ({ ...style, div: { paddingBottom: '14px' } })
  }

  const getTimeForDataTable = (arr: TDataForTable[], type: string) =>
    arr.map((item: TDataForTable) => ({
      ...item,
      time:
        TYPE_OF_REQUESTS_TIME_OFF[Number(type)] === 'Absent' ||
        TYPE_OF_REQUESTS_TIME_OFF[Number(type)] === 'Remote'
          ? '1'
          : balance && balance.workingHoursPerDay
          ? balance.workingHoursPerDay
          : '0'
    }))

  const changeDataForTable = (form: FormApi, start: string, end: string) => {
    const prevDataForTable = form.getState().values.dataForTable
    const type = form.getState().values.type.value
    const range = moment.range([moment(start), moment(end).endOf('day')])
    const insertData = getTimeForDataTable(
      Array.from(range.by('days'))
        .filter(m => isWorkingDay(m))
        .filter((m: Moment.Moment) => {
          for (const element of productCalendar.nonWorkingDays) {
            if (
              m.format(FORMAT_MMDDYYYY) ===
              moment(element.date, 'MM-DD-YYYY').format(FORMAT_MMDDYYYY)
            ) {
              return false
            }
          }
          return true
        })
        .map((m: Moment.Moment) => ({
          date: m.format(FORMAT_MMDDYYYY),
          time: balance.workingHoursPerDay
        })),
      type
    )

    if (form.getState().values.type.value !== '3' && form.getState().values.type.value !== '6') {
      return form.change('dataForTable', insertData)
    }

    const diffDataForTable = xorBy(prevDataForTable, insertData, 'date')
    // TODO -- <its code must be update - too match ifs> START
    if (end > form.getState().values.lastDay) {
      const newInsertData = [...prevDataForTable, ...diffDataForTable]
      form.change('dataForTable', newInsertData)
    } else if (end < form.getState().values.lastDay) {
      form.change(
        'dataForTable',
        prevDataForTable.slice(0, prevDataForTable.length - diffDataForTable.length)
      )
    } else if (
      prevDataForTable.length &&
      start === prevDataForTable[prevDataForTable.length - 1].date
    ) {
      form.change('dataForTable', prevDataForTable.slice(prevDataForTable.length - 1))
    } else if (start === form.getState().values.lastDay) {
      form.change('dataForTable', insertData)
    } else if (start > form.getState().values.firstDay) {
      form.change('dataForTable', prevDataForTable.slice(diffDataForTable.length))
    } else if (start < form.getState().values.firstDay) {
      const newInsertData = prevDataForTable.slice()
      newInsertData.unshift(...diffDataForTable)
      form.change('dataForTable', newInsertData)
    }
    // TODO -- <its code must be update - too match ifs> END
  }

  const handleOnSubmit = (values: TValuesForTimeOffRequest) => {
    const body: TBodyForTimeOffRequest = {
      fromDate: moment(
        moment(values.firstDay).set({
          hour: moment().get('h'),
          minute: moment().get('m'),
          second: moment().get('s')
        })
      ).valueOf(),
      toDate: moment(
        moment(values.lastDay).set({
          hour: moment().get('h'),
          minute: moment().get('m'),
          second: moment().get('s')
        })
      ).valueOf(),
      comment: values.comments,
      details: values.dataForTable.map((item: TDataForTable) => ({
        day: moment(item.date).valueOf(),
        from: moment(item.date).valueOf(),
        to: moment(item.date)
          .add('hours', item.time)
          .valueOf(),
        hours: Number(item.time)
      })),
      requestType: values.type.value,
      reporterId: values.manager.value,
      assigneeId:
        values.assignee && values.assignee.value !== '@NULL@' ? values.assignee.value : null,
      timeZone: moment.tz.guess()
    }

    if (!isUser(role)) {
      body.authorId = values.author.value
    }

    const hours = calculateTime(values.dataForTable, balance).totalHours
    switch (values.type.value) {
      case '2':
        if (
          (balance.standing === 0 &&
            balance.dayOffLimit - balance.dayOffsTaken >= 0 &&
            balance.dayOffLimit - balance.dayOffsTaken < hours) ||
          (balance.standing > 0 &&
            balance.dayOffLimit - balance.dayOffsTaken > 0 &&
            balance.dayOffLimit - balance.dayOffsTaken + (balance.dayOffBalance + balance.debt) <
              hours) ||
          (balance.standing > 0 &&
            balance.dayOffLimit - balance.dayOffsTaken <= 0 &&
            balance.dayOffLimit * 2 - balance.workingHoursPerDay - balance.dayOffsTaken < hours)
        ) {
          toastr('info', words['NOT_ENOUGH_BALANCE'])
        } else {
          setTimeOffPeriod(body, match.params.id)
        }
        break
      case '3':
      case '4':
        setTimeOffPeriod(body, match.params.id)
        break
      case '5':
        if (balance.totalSick === 0 || balance.totalSick < hours) {
          toastr('info', words['NOT_ENOUGH_BALANCE'])
        } else {
          setTimeOffPeriod(body, match.params.id)
        }
        break
      case '6':
        if (balance.totalRemote === 0 || balance.totalRemote < hours) {
          toastr('info', words['NOT_ENOUGH_BALANCE'])
        } else {
          setTimeOffPeriod(body, match.params.id)
        }
        break
      default:
        toastr('info', words['NOT_ENOUGH_BALANCE'])
    }
  }

  const calculateTime = (arr: TDataForTable[], balanceValue: TBalance) => {
    const value: { [key: string]: number } = {}
    value.totalHours = arr.reduce<number>((init: number, item) => +item.time + init, 0)
    value.days = Math.floor(value.totalHours / balanceValue.workingHoursPerDay) || 0
    value.hours = Math.floor(value.totalHours % balanceValue.workingHoursPerDay) || 0
    return value
  }

  const getInitialValues = () => {
    return isEdit
      ? {
          author:
            (data.authorId && {
              value: data.authorId.id,
              label: data.authorId.fullName
            }) ||
            {},
          manager:
            (data.reporterId && {
              value: data.reporterId.id,
              label: data.reporterId.fullName
            }) ||
            {},
          assignee:
            (data.assigneeId && {
              value: data.assigneeId.id,
              label: data.assigneeId.fullName
            }) ||
            {},
          firstDay: data.timeZone
            ? moment(data.fromDate)
                .tz(data.timeZone)
                .format(FORMAT_MMDDYYYY)
            : moment(data.fromDate).format(FORMAT_MMDDYYYY),
          lastDay: data.timeZone
            ? moment(data.toDate)
                .tz(data.timeZone)
                .format(FORMAT_MMDDYYYY)
            : moment(data.toDate).format(FORMAT_MMDDYYYY),
          type: { value: data.requestType, label: TYPE_OF_REQUESTS_TIME_OFF[data.requestType] },
          comments: data.comment,
          dataForTable:
            (data.details &&
              data.details.map((item: any) => ({
                date: data.timeZone
                  ? moment(item.day)
                      .tz(data.timeZone)
                      .format(FORMAT_MMDDYYYY)
                  : moment(item.day).format(FORMAT_MMDDYYYY),
                time: item.hours
              }))) ||
            dataForTableDefault
        }
      : {
          firstDay: moment().format(FORMAT_MMDDYYYY),
          lastDay: moment().format(FORMAT_MMDDYYYY),
          type: { value: '2', label: 'Day-off' },
          dataForTable: dataForTableDefault,
          // manager: initialManager(),
          manager,
          author,
          assignee
          // firstDayState,
          // lastDayState
        }
  }

  const setOtionsForTimeSelect = (workingHours: number, index: number, date: string) => {
    const options = []
    for (let i = 1; i <= workingHours; i++) {
      options.push({ value: String(i), label: `${i}:00`, index, date })
    }
    return options
  }

  return (
    <Form
      initialValues={useMemo(() => getInitialValues(), [data, dataForTableDefault])}
      onSubmit={values => handleOnSubmit(values as TValuesForTimeOffRequest)}
    >
      {({ handleSubmit, form }) => {
        const formState = form.getState()
        const isAbsent = Number(formState.values.type.value) === 3
        const isRemote = Number(formState.values.type.value) === 6
        return (
          <form onSubmit={handleSubmit} className={styles.container}>
            <section className={styles.header}>
              <h1>
                {isEdit
                  ? words['manager.dayOffTracker.editRequests']
                  : words['manager.dayOffTracker.createRequest']}
              </h1>
            </section>
            <section>
              <div className={styles['block-go-back']}>
                <button
                  type="button"
                  className={styles['go-back']}
                  onClick={event => {
                    event.preventDefault()
                    history.goBack()
                  }}
                >
                  <Icons icon="backArrowIcon" />
                  <span className={styles['go-back-text']}>{words['back']}</span>
                </button>
              </div>
            </section>

            <div className={styles['content-container']}>
              <div className={styles['form-container']}>
                <div className={styles['form-row-flex-container']}>
                  <div className={styles['form-item-name']}>
                    <label>
                      {`${words['user.dayOffTracker.createRequest.requestType']}:`}
                      <span className={styles['red-note']}>*</span>
                      <Field name="type">
                        {({ input }) => (
                          <NewUIKitSelect
                            placeholder={words['manager.dayOffTracker.details.requestType']}
                            options={TYPE_SELECT_MOCKS}
                            {...input}
                            onChange={(event: any) => {
                              form.change(input.name, event)
                              form.change(
                                'dataForTable',
                                getTimeForDataTable(
                                  formState.values.dataForTable,
                                  (event as TSelectOption).value
                                )
                              )
                            }}
                            emptyMessage={words['noOption']}
                          />
                        )}
                      </Field>
                    </label>
                  </div>
                  {!isUser(role) && (
                    <div className={styles['form-item-name']}>
                      <label>
                        {`${words['manager.dayOffTracker.createRequest.requestFrom']}:`}
                        <span className={styles['red-note']}>*</span>
                        <Field
                          name="author"
                          validate={validation.composeValidators(
                            validation.required(words['user.requiredMessage'])
                          )}
                        >
                          {({ input, meta }) => (
                            <div data-tip="" data-for="author" id="author">
                              <NewUIKitSelect
                                placeholder={words['user.dayOffTracker.enterSurnameAndName']}
                                options={usersData}
                                {...input}
                                isInvalid={meta.error && meta.submitFailed}
                                emptyMessage={words['noOption']}
                                onChange={(event: any) => {
                                  setAuthor({
                                    //@ts-ignore
                                    value: (event as TSelectOption).value,
                                    label: (event as TSelectOption).label
                                  })
                                  getBalance(event.value)
                                  form.change('author', event)
                                  form.change(
                                    'dataForTable',
                                    getTimeForDataTable(
                                      formState.values.dataForTable,
                                      formState.values.type
                                    )
                                  )
                                }}
                              />
                              {meta.error && meta.submitFailed && (
                                <ReactTooltip
                                  id="author"
                                  anchorSelect="#author"
                                  place="bottom"
                                  variant="error"
                                  content={meta.error}
                                />
                              )}
                            </div>
                          )}
                        </Field>
                      </label>
                    </div>
                  )}
                </div>

                <div className={styles['form-row-flex-container']}>
                  <div className={styles['form-item-date-picker']} data-tip="" data-for="firstDay">
                    <span>{`${words['manager.dayOffTracker.createRequest.firstDay']}:`}</span>
                    <Field
                      name="firstDay"
                      validate={(_, allValues) => {
                        if (
                          (allValues as TValuesForTimeOffRequest).dataForTable &&
                          !(allValues as TValuesForTimeOffRequest).dataForTable.length
                        ) {
                          return words['selectWorkDays']
                        }
                        return null
                      }}
                    >
                      {({ input, meta }) => {
                        return (
                          <>
                            <DatePicker
                              id="firstDay"
                              locale={currentLanguage}
                              excludeDates={excludeDates}
                              isNextYear={true}
                              filterDate={isWeekday}
                              className={cls({
                                [styles.dateInvalid]: meta.error && meta.submitFailed
                              })}
                              dateFormat={config.dateFormat}
                              selected={input.value ? new Date(input.value) : new Date()}
                              onChange={(date: Date) => {
                                const lastDay = formState.values.lastDay
                                const formatDate = date
                                  ? moment(date).format(FORMAT_MMDDYYYY)
                                  : moment().format(FORMAT_MMDDYYYY)

                                if (moment(lastDay).valueOf() <= moment(date).valueOf()) {
                                  form.change('lastDay', formatDate)
                                  changeDataForTable(form, formatDate, formatDate)
                                } else {
                                  changeDataForTable(form, formatDate, lastDay)
                                }
                                // setFirstDayState(formatDate)
                                form.change(input.name, formatDate)
                              }}
                              endDate={new Date(formState.values.lastDay)}
                              startDate={new Date(input.value)}
                              // minDate={isAdmin ? null : new Date()}
                              maxDate={new Date(maxDate.valueOf())}
                            />
                            {meta.error && meta.submitFailed && (
                              <ReactTooltip
                                id="firstDay"
                                anchorSelect="#firstDay"
                                place="bottom"
                                variant="error"
                                content={meta.error}
                              />
                            )}
                          </>
                        )
                      }}
                    </Field>
                  </div>

                  <div className={styles['form-item-date-picker']} data-tip="" data-for="firstDay">
                    <span>{`${words['manager.dayOffTracker.createRequest.lastDay']}:`}</span>
                    <Field
                      name="lastDay"
                      validate={(_, allValues) => {
                        if (
                          (allValues as TValuesForTimeOffRequest).dataForTable &&
                          !(allValues as TValuesForTimeOffRequest).dataForTable.length
                        ) {
                          return words['selectWorkDay']
                        }
                        return null
                      }}
                    >
                      {({ input, meta }) => {
                        const firstDay = formState.values.firstDay
                        let selectedLastDayDate = new Date(input.value)

                        if (moment(input.value) <= moment(firstDay)) {
                          selectedLastDayDate = new Date(firstDay)
                        }

                        return (
                          <>
                            <DatePicker
                              id="firstDay"
                              locale={currentLanguage}
                              excludeDates={excludeDates}
                              isNextYear={true}
                              className={cls({
                                [styles.dateInvalid]: meta.error && meta.submitFailed
                              })}
                              filterDate={isWeekday}
                              dateFormat={config.dateFormat}
                              selected={selectedLastDayDate || new Date()}
                              onChange={(date: Date) => {
                                changeDataForTable(
                                  form,
                                  firstDay,
                                  moment(date).format(FORMAT_MMDDYYYY)
                                )
                                form.change(
                                  input.name,
                                  date
                                    ? moment(date).format(FORMAT_MMDDYYYY)
                                    : moment().format(FORMAT_MMDDYYYY)
                                )
                              }}
                              startDate={new Date(formState.values.firstDay)}
                              endDate={new Date(selectedLastDayDate)}
                              minDate={isAdmin ? null : new Date(firstDay)}
                              maxDate={new Date(maxDate.valueOf())}
                            />
                            {meta.error && meta.submitFailed && (
                              <ReactTooltip
                                id="firstDay"
                                anchorSelect="#firstDay"
                                place="bottom"
                                variant="error"
                                content={meta.error}
                              />
                            )}
                          </>
                        )
                      }}
                    </Field>
                  </div>
                </div>

                <div className={styles['form-row-flex-container']}>
                  <div className={styles['form-item-name']}>
                    <label>
                      {`${words['manager.dayOffTracker.createRequest.manager']}:`}
                      <span className={styles['red-note']}>*</span>
                      <Field
                        name="manager"
                        validate={validation.composeValidators(
                          validation.required(words['user.requiredMessage'])
                        )}
                      >
                        {({ input, meta }) => (
                          <div data-tip="" data-for="manager">
                            <NewUIKitSelect
                              placeholder={words['user.dayOffTracker.enterSurnameAndName']}
                              options={managersData}
                              {...input}
                              isInvalid={meta.error && meta.submitFailed}
                              errorMessage={meta.error}
                              emptyMessage={words['noOption']}
                              onChange={(event: any) => {
                                setManager({
                                  value: (event as TSelectOption).value,
                                  label: (event as TSelectOption).label
                                })
                                form.change('manager', event)
                              }}
                            />
                          </div>
                        )}
                      </Field>
                    </label>
                  </div>

                  <div className={styles['form-item-name']}>
                    <label>
                      {`${words['manager.dayOffTracker.createRequest.responsibleForTheTasks']}:`}
                      <span className={styles['red-note']}>*</span>
                      <Field
                        name="assignee"
                        validate={validation.composeValidators(
                          validation.required(words['user.requiredMessage'])
                        )}
                      >
                        {({ input, meta }) => (
                          <NewUIKitSelect
                            placeholder={words['user.dayOffTracker.enterSurnameAndName']}
                            {...input}
                            options={responsibleData}
                            isInvalid={meta.error && meta.submitFailed}
                            errorMessage={meta.error}
                            emptyMessage={words['noOption']}
                            onChange={(event: any) => {
                              setAssignee({
                                //@ts-ignore
                                value: (event as TSelectOption).value,
                                label: (event as TSelectOption).label
                              })
                              form.change('assignee', event)
                            }}
                          />
                        )}
                      </Field>
                    </label>
                  </div>
                </div>

                <Field
                  name="comments"
                  validate={validation.composeValidators(
                    validation.required(words['user.requiredMessage']),
                    validation.minValue(3),
                    validation.maxValue(250)
                  )}
                >
                  {({ input, meta }) => {
                    const isReadOnly = isEdit && (data.reporterId && data.authorId.id) !== userId
                    return (
                      <div>
                        <Textarea
                          {...input}
                          title={
                            <span>
                              {`${words['manager.dayOffTracker.details.comment']}:`}
                              <span className={styles['red-note']}>*</span>
                            </span>
                          }
                          placeholder={`${
                            words['manager.dayOffTracker.createRequest.commentPlaceholder']
                          }...`}
                          onChange={input.onChange}
                          readonly={isReadOnly}
                          isInvalid={meta.error && meta.submitFailed}
                          errorMessage={meta.error}
                        />
                      </div>
                    )
                  }}
                </Field>
              </div>

              <div className={styles.details}>
                {Number(formState.values.type.value) === 5 ||
                Number(formState.values.type.value) === 2 ? (
                  <div className={styles['details-title']}>
                    <div>
                      <span className={styles['balance-title']}>{`${
                        words['manager.dayOffTracker.createRequest.yearBalance']
                      }:`}</span>
                      <span
                        className={cls({
                          [styles.balance]: true,
                          [styles['balance-red']]: balance.dayOffBalance < 0
                        })}
                      >
                        {currentBalance(Number(formState.values.type.value), balance).days}
                      </span>
                      {Number(formState.values.type.value) === 2 && (
                        <span className={styles.balance}>
                          /{currentBalance(Number(formState.values.type.value), balance).limitDays}
                        </span>
                      )}
                      <span className={styles.balance}>
                        {words['user.dayOffTracker.shortDays']}
                      </span>
                    </div>
                  </div>
                ) : Number(formState.values.type.value) === 6 ? (
                  <div className={styles['details-title']}>
                    <div>
                      <span className={styles['balance-title']}>{`${
                        words['user.header.balance']
                      }:`}</span>
                      <span className={styles.balance}>
                        {currentBalance(Number(formState.values.type.value), balance).days}{' '}
                        {words['user.dayOffTracker.shortDays']}
                        {currentBalance(Number(formState.values.type.value), balance).hours !==
                          0 && (
                          <span>
                            {currentBalance(Number(formState.values.type.value), balance).hours}
                            {` ${words['user.dayOffTracker.shortHours']}`}
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className={styles['details-title']}>{`${
                    words['manager.dayOffTracker.details.requestDetails']
                  }:`}</div>
                )}
                <div className={styles['details-list-container']}>
                  <div className={styles['details-list']}>
                    <ul>
                      {(formState.values.dataForTable as TDataForTable[]).map((item, index) => {
                        const currentDay = moment(item.date).format('dddd')
                        const flagMonday = currentDay === 'понедельник'
                        const flagFriday = currentDay === 'пятница'
                        const classes = cls({
                          [styles['list-days']]: true,
                          [styles['start-week']]: flagMonday,
                          [styles['week']]: !flagMonday && !flagFriday,
                          [styles['firstDay']]: formState.values.dataForTable.length === 1,
                          [styles['end-week']]: flagFriday
                        })
                        return (
                          <li key={index} className={classes}>
                            <span>{moment(item.date).format(FORMAT_TIME)}</span>
                            <div style={{ width: '90px' }}>
                              <Field name={`timeSelect${index}`}>
                                {({ input }) => (
                                  <NewUIKitSelect
                                    name={input.name}
                                    options={setOtionsForTimeSelect(
                                      balance.workingHoursPerDay,
                                      index,
                                      item.date
                                    )}
                                    value={{
                                      value: formState.values.dataForTable[index].time,
                                      label: `${formState.values.dataForTable[index].time}:00`
                                    }}
                                    styles={selectTimeStyle}
                                    components={{
                                      DropdownIndicator:
                                        !isAbsent && !isRemote
                                          ? DisabledIndicator
                                          : DropownIndicator
                                    }}
                                    isDisabled={!isAbsent && !isRemote}
                                    emptyMessage={words['noOption']}
                                    onChange={(event: any) => {
                                      if (event.index < formState.values.dataForTable.length) {
                                        const newArr = formState.values.dataForTable.slice()
                                        newArr[event.index] = {
                                          ...newArr[event.index],
                                          time: event.value
                                        }
                                        form.change('dataForTable', newArr)
                                      }
                                    }}
                                  />
                                )}
                              </Field>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>

                  <div className={styles['details-total']}>
                    <span>{words['user.dayOffTracker.totalRequests']}</span>
                    <div className={styles['details-total-count']}>
                      <span>{calculateTime(formState.values.dataForTable, balance).days} </span>
                      {words['user.dayOffTracker.days']}
                      <span>{calculateTime(formState.values.dataForTable, balance).hours}</span>
                      {words['user.dayOffTracker.hours']}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles['footer-btn-container']}>
              <Button
                type="submit"
                disabled={loadingSetNewRequest}
                loading={loadingSetNewRequest}
                style={{ minWidth: 210 }}
              >
                {isEdit ? words['user.dayOffTracker.save'] : words['user.dayOffTracker.create']}
              </Button>
            </div>
          </form>
        )
      }}
    </Form>
  )
}
