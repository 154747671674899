export const enum ETokenStatus {
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE'
}

export const enum ETokenActions {
  GET_TOKEN_REQUEST = 'GET_TOKEN_REQUEST',
  GET_TOKEN_REQUEST_SUCCESS = 'GET_TOKEN_REQUEST_SUCCESS',
  CHANGE_USER_TOKEN_STATUS = 'CHANGE_USER_TOKEN_STATUS',
  CHANGE_ADMIN_TOKEN_STATUS = 'CHANGE_ADMIN_TOKEN_STATUS',
  ADD_USER_TOKEN = 'ADD_USER_TOKEN',
  GET_ALL_TOKENS = 'GET_ALL_TOKENS',
  SELECT_TOKEN = 'SELECT_TOKEN'
}
