import { IAction } from 'models'
import { TVoting } from './VotingList.model'
import { REQUEST, GET_ALL_VOTING_SUCCESS, GET_VOTING_SUCCESS, VOTE } from './VotingList.constant'

type TInitialState = {
  error: string
  loading: boolean
  votingList: TVoting[]
  selectVoting: TVoting | null
}

const initialState: TInitialState = {
  error: '',
  loading: false,
  votingList: [],
  selectVoting: null
}

export default (state = initialState, action: IAction): TInitialState => {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_ALL_VOTING_SUCCESS:
      return {
        ...state,
        loading: false,
        votingList: action.payload
      }
    case GET_VOTING_SUCCESS:
      return {
        ...state,
        loading: false,
        selectVoting: action.payload
      }
    case VOTE:
      return {
        ...state,
        loading: false,
        selectVoting: { ...state.selectVoting, ...action.payload }
      }
    default:
      return state
  }
}
