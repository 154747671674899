import { formatNumber } from 'utils/numberToFixed'
import { ESettingsActions } from './ControlPanel.constants'
import { TPositionResult, TSettingsAction, IInitialState } from './ControlPanel.types'

export const initialState: IInitialState = {
  botInfo: {
    botId: null,
    strategyType: null,
    exchangeType: null
  },
  error: null,
  settings: null,
  isLoading: false,
  exchangeInfo: null,
  positionsInfo: null,
  graphStatistic: null
}

export default (state = initialState, action: TSettingsAction) => {
  switch (action.type) {
    case ESettingsActions.REQUEST_STARTED: {
      return {
        ...state,
        isLoading: true
      }
    }

    case ESettingsActions.GET_BOT_SETTINGS: {
      return {
        ...state,
        isLoading: false,
        settings: action.payload
      }
    }

    case ESettingsActions.GET_EXCHANGE_INFO: {
      return {
        ...state,
        isLoading: false,
        exchangeInfo: action.payload
      }
    }

    case ESettingsActions.GET_POSITIONS_INFO: {
      const positionInfoResult: TPositionResult[] = action.payload[0].positions.map(position => ({
        contracts: `${position.info.symbol} ${position.leverage}x`,
        quantity: Math.abs(Number(position.info.positionAmt)),
        margin: formatNumber(position.initialMargin, 2),
        entryPrice: position.entryPrice,
        markingPrice: `${position.markPrice} ${position.side}`,
        liquidationPrice: formatNumber(Number(position.info.liquidationPrice), 9),
        unrealizedPL: `${position.info.unRealizedProfit} ${formatNumber(position.percentage, 2)}`
      }))

      return {
        ...state,
        isLoading: false,
        positionsInfo: positionInfoResult
      }
    }

    case ESettingsActions.GET_GRAPH_STATISTIC: {
      return {
        ...state,
        isLoading: false,
        graphStatistic: action.payload
      }
    }

    case ESettingsActions.GET_BOT_INFO: {
      return {
        ...state,
        botInfo: {
          botId: action.payload.bot_id,
          exchangeType: action.payload.selectedExchange,
          strategyType: action.payload.selectedStrategy
        }
      }
    }
    case ESettingsActions.GET_BOT_EXCHANGE_TYPE: {
      return {
        ...state,
        botInfo: {
          ...state.botInfo,
          exchangeType: action.payload
        }
      }
    }

    default:
      return state
  }
}
