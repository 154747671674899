import { ethers } from 'ethers'
import { EContractAddress } from 'globalConfigs'

export const getMessageHash = (
  values: [number, string, string, number, EContractAddress]
): string => {
  const timestamp = new Date().toISOString()
  const messageHash = ethers.utils.solidityKeccak256(
    ['uint8', 'string', 'string', 'uint256', 'address', 'string'],
    [...values, timestamp]
  )
  return messageHash
}
