import React, { FC, memo } from 'react'
import cls from 'classnames'
import styles from './TableSortHeader.module.scss'
import { Icons } from 'components/Icons'
import history from 'utils/history'
import queryString from 'query-string'

export const TableSortHeader: FC<{
  sort?: boolean
  id?: string
  children: any
}> = memo(({ children, sort = true, id }) => {
  const parsed = queryString.parse(history.location.search)
  const ask = parsed.column === id && parsed.sort === 'ASC'

  const onClick = () => {
    const query = {
      ...parsed,
      column: id,
      sort: !ask ? 'ASC' : 'DESC'
    }

    history.push(`${history.location.pathname}?${queryString.stringify(query)}`)
  }

  return (
    <div className={styles.sort} onClick={onClick}>
      {children}
      {sort && (
        <>
          {' '}
          <Icons
            icon="arrowSort"
            className={cls({
              [styles.up]: ask
            })}
          />
        </>
      )}
    </div>
  )
})
