import { Table as UIKitTable } from '../../../../../components/UiKit/Table'
import React, { useCallback, useMemo } from 'react'
import styles from './TableInvoice.module.scss'
import { Columns } from './TableInvoice.columns'
import DateInvoice from '../DateInvoice/DateInvoice'
import cls from 'classnames'
import { FormApi, InternalFormState } from 'final-form'
import { useSelector } from 'react-redux'
import { TState } from 'store'

// import { getUsers } from '../../CreateInvoice.thunk'

interface ITableInvoice {
  users: any[]
  form: FormApi
  formState: InternalFormState
  rank: { value: string; label: string }[]
  position: { value: string; label: string }[]
  rate: number
  isActiveName: boolean
  isActiveLevel: boolean
  setIsActiveName: (isName: boolean) => void
  setIsActiveLevel: (isLevel: boolean) => void
}

const TableInvoice = ({
  users,
  form,
  formState,
  rank,
  position,
  isActiveName,
  isActiveLevel,
  setIsActiveName,
  setIsActiveLevel
}: ITableInvoice) => {
  const setName = useCallback((isName: any) => {
    setIsActiveName(isName)
  }, [])

  const setLevel = useCallback((isLevel: any) => {
    setIsActiveLevel(isLevel)
  }, [])

  const words = useSelector((state: TState) => state.global.language.words)

  const columns = Columns({
    role: 2,
    isOnlyManager: false,
    isActiveLevel,
    isActiveName,
    setName,
    setLevel
  })
  const columnsMemoized = useMemo(() => columns, [])

  const getCurrentRank = (user: any) => {
    return rank && rank.filter((r: any) => r.value === String(user.rank.id))[0].label
  }
  const getCurrentPosition = (user: any) => {
    return position && position.filter((p: any) => p.value === String(user.position.id))[0].label
  }
  //TODO need to finilize table with real user data from user profile after complete user profile logic in admin panel

  return (
    <UIKitTable
      // key={some}
      data={users.map((user: any) => {
        return {
          name: user.fullName,
          grade: position && getCurrentPosition(user),
          level: rank && getCurrentRank(user),
          rate: `${parseInt(user.projectsTeam[0].rateByInvoice)} $ `,
          period: [
            <DateInvoice
              key={user.id + Math.random()}
              name={`from${user.id}`}
              form={form}
              formState={formState}
            />,
            '–',
            <DateInvoice
              key={user.id + Math.random()}
              name={`to${user.id}`}
              form={form}
              formState={formState}
            />
          ]
        }
      })}
      columns={columnsMemoized}
      total={users.length}
      getData={() => ({})}
      classNameToRow={cls({
        [styles.sort]: true,
        [styles['active-name']]: !isActiveName,
        [styles['active-level']]: !isActiveLevel
      })}
      tableHeight={'444px'}
      noData={words['admin.tableInvoices.noData']}
      onSelectedRows={() => ({})}
      onRowClick={() => ({})}
    />
  )
}
export default TableInvoice
