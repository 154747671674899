/* eslint-disable no-console */
import { IAction } from 'models'
import { GET_IDENFY_TOKEN_SUCCESS } from './components/Idenfy/Idenfy.constants'
import {
  UPDATE_USER_SUCCESS,
  GET_QRCODE_SUCCESS,
  GET_SIGNUP_SUCCESS,
  GET_DID_SUCCESS,
  VERIFICATION_REQUEST,
  VERIFICATION_ERROR,
  VERIFICATION_SUCCESS
} from './components/Polygon/Polygon.constants'

export type IInitialState = {
  error: string
  loading: boolean
  user: object
  qrCode: object
  signupQr: any
  did: any
  claimsList: any
  userIdenfy: string
}

export const initialState: IInitialState = {
  error: '',
  loading: false,
  user: {},
  qrCode: {},
  signupQr: {},
  did: '',
  claimsList: [],
  userIdenfy: ''
}

export default (state = initialState, action: IAction): any => {
  switch (action.type) {
    case VERIFICATION_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case VERIFICATION_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case VERIFICATION_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case GET_QRCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        qrCode: action.payload,
        claimsList: action.payload.claimsList
      }
    case GET_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        signupQr: action.payload.signupQr
      }
    case GET_DID_SUCCESS:
      return {
        ...state,
        // loading: false,
        did: action.payload.did
      }
    case GET_IDENFY_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        userIdenfy: action.payload
      }
    default:
      return state
  }
}
