import { validationSchemaForChannels } from 'utils/validation'
import { ValidateChannelsSettings } from '../../../ControlPanel/ControlPanel.types'
import * as Yup from 'yup'

const validateChannelsSettings: ValidateChannelsSettings = values => {
  try {
    validationSchemaForChannels.validateSync(values, { abortEarly: false })
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      return error.inner.reduce(
        (acc: { [key: string]: string }, currentError: Yup.ValidationError) => {
          if (currentError.path !== undefined) {
            acc[currentError.path] = currentError.message
          }
          return acc
        },
        {}
      )
    }
  }

  return undefined
}

export { validateChannelsSettings }
