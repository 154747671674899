/* eslint-disable no-console */
import React, { FC, useEffect, useMemo, useState } from 'react'
import styles from './TimeOffRequests.module.scss'
import { IToggleBarData, ToggleBar } from '../../../components/ToggleBar'
import history from '../../../utils/history'
import queryString from 'query-string'
import { useUser } from '../../../hooks/useUser'
import { TTimeOffRequestsProps } from './TimeOffRequests.model'
import {
  DATE_FORMAT_FOR_DETAILS,
  EPageLimits,
  TYPE_OF_REQUESTS_TIME_OFF
} from '../../../globalConfigs'
import { Table as UIKitTable } from '../../../components/UiKit/Table'
import { Button } from 'components/UiKit/Button'
import moment from 'moment'
import Modal from '../../../components/Modal'
import cls from 'classnames'
import { isUser } from '../../../utils/user'
import cn from 'classnames/bind'
import { getFilterConfig } from './TimeOffRequests.filter.config'
import { TSelectOption } from '../../../components/Select'
import { Filter } from '../../../components/UiKit/Filter'
import { Columns } from './TimeOffRequests.columns'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { IUsersList } from 'global.model'

const ONLY_MANAGER = 'onlyManager'

export type TRequestsParsed = {
  onlyMy?: 'true'
  id?: number
  token?: string
  actionType?: 'approve' | 'reject' | 'cancel'
}

export const TimeOffRequests: FC<TTimeOffRequestsProps> = ({
  role,
  data,
  usersList,
  dataManagers,
  total,
  maxToDate,
  minFromDate,
  // loadingData,
  loadingReject,
  loadingApprove,
  currentRequest,
  getTimeOffRequests,
  actionForTimeOff,
  getCurrentRowRequest,
  getUsersList,
  getManagers,
  location
}) => {
  const { manager } = useUser().userData
  const parsed: TRequestsParsed = queryString.parse(history.location.search)
  const words = useSelector((state: TState) => state.global.language.words)

  const switchTableToggleData: IToggleBarData[] = [
    {
      value: 'all',
      title: words['user.dayOffTracker.allRequests']
    },
    {
      value: ONLY_MANAGER,
      title: words['user.dayOffTracker.onlyMyRequests']
    }
  ]

  const usersData: TSelectOption[] = useMemo(
    () =>
      usersList.map((item: IUsersList) => ({
        value: `${item.id}`,
        label: item.fullName
      })),
    [usersList]
  )

  const managersData: TSelectOption[] = useMemo(
    () =>
      dataManagers.map(item => ({
        value: `${item.id}`,
        label: item.fullName
      })),
    [dataManagers]
  )

  useEffect(() => {
    if (parsed.id && parsed.token && parsed.actionType) {
      actionForTimeOff({ search: location.search, type: parsed.actionType })
    }
  }, [])

  useEffect(() => {
    if (parsed.onlyMy) {
      getTimeOffRequests(location.search)
    }
  }, [location.search])

  const [selectedTableToggle, setSelectedTableToggle] = useState<IToggleBarData>(
    parsed.onlyMy ? switchTableToggleData[1] : switchTableToggleData[0]
  )

  const [detailsId, setDetailsId] = useState<number | null>(null)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [openModalDetails, setOpenModalDetails] = useState(false)
  const toggleModalDetails = () => setOpenModalDetails(!openModalDetails)
  const isOnlyManager = manager && selectedTableToggle.value === ONLY_MANAGER
  useEffect(() => {
    if (detailsId != null) {
      getCurrentRowRequest(detailsId)
    }
  }, [detailsId])

  useEffect(() => {
    if (!isOnlyManager) {
      getTimeOffRequests(location.search)
      !isUser(role) && getUsersList()
      !isUser(role) && getManagers({ limit: EPageLimits.MANAGERS })
    }
  }, [isOnlyManager])

  const cx = cn.bind(styles)
  const hideClass = cx('btn-managers', { 'hide-if-user': isUser(role) || isOnlyManager })
  const btnContainerClass = cx('footer-btn-container', {
    'btn-container-if-user': isUser(role) || isOnlyManager
  })

  const handleCreateRequestBtn = (event: any) => {
    event.preventDefault()
    history.push('/dashboard/requests/create')
  }
  const handleCancelRequest = (id: number | string) => {
    if (id) {
      actionForTimeOff({ ids: [+id], type: 'cancel' })
    }
  }

  const renderModalContent = () => {
    return (
      currentRequest &&
      currentRequest.authorId &&
      currentRequest.reporterId && (
        <div className={styles['details-container']}>
          <header>{words['manager.dayOffTracker.details.requestDetails']}</header>
          <section className={styles['details-info']}>
            <div className={styles['details-info-row']}>
              <span className={styles['details-info-title']}>{`${
                words['manager.dayOffTracker.details.author']
              }:`}</span>
              <span className={styles['details-info-context']}>
                {currentRequest.authorId.fullName}
              </span>
            </div>
            <div className={styles['details-info-row']}>
              <span className={styles['details-info-title']}>{`${
                words['manager.dayOffTracker.details.requestType']
              }:`}</span>
              <span className={styles['details-info-context']}>
                {TYPE_OF_REQUESTS_TIME_OFF[+currentRequest.requestType]}
              </span>
            </div>
            <div className={styles['details-info-row']}>
              <span className={styles['details-info-title']}>{`${
                words['manager.dayOffTracker.details.date']
              }:`}</span>
              <span className={styles['details-info-context']}>
                {`${words['manager.dayOffTracker.details.date.from']} `}
                <span className={styles['details-info-context-green']}>
                  {moment(currentRequest.fromDate)
                    .tz(currentRequest.timeZone)
                    .format(DATE_FORMAT_FOR_DETAILS)}
                </span>{' '}
                {`${words['manager.dayOffTracker.details.date.to']} `}
                <span className={styles['details-info-context-green']}>
                  {moment(currentRequest.toDate)
                    .tz(currentRequest.timeZone)
                    .format(DATE_FORMAT_FOR_DETAILS)}
                </span>
              </span>
            </div>
            <div className={styles['details-info-row']}>
              <span className={styles['details-info-title']}>{`${
                words['manager.dayOffTracker.details.time']
              }:`}</span>
              <span
                className={cls({
                  [styles['details-info-context']]: true,
                  [styles['details-info-context-green']]: true
                })}
              >
                {currentRequest.hours} {words['manager.dayOffTracker.createRequest.specialHours']}
              </span>
            </div>
            <div className={styles['details-info-row']}>
              <span className={styles['details-info-title']}>{`${
                words['admin.users.table.Edit.manager']
              }:`}</span>
              <span className={styles['details-info-context']}>
                {currentRequest.reporterId.fullName}
              </span>
            </div>
            <div className={styles['details-info-row']}>
              <span className={styles['details-info-title']}>{`${
                words['manager.dayOffTracker.details.responsibleForTheTasks']
              }:`}</span>
              <span className={styles['details-info-context']}>
                {currentRequest.assigneeId ? currentRequest.assigneeId.fullName : '-'}
              </span>
            </div>
            <div className={styles['details-info-row']}>
              <span className={styles['details-info-title']}>
                {`${words['manager.dayOffTracker.details.requestDate']}:`}
              </span>
              <span className={styles['details-info-context']}>
                {moment(currentRequest.createdAt)
                  .tz(currentRequest.timeZone)
                  .format(DATE_FORMAT_FOR_DETAILS)}
              </span>
            </div>
            <div className={styles['details-info-row']}>
              <span className={styles['details-info-title']}>
                {`${words['manager.dayOffTracker.details.comment']}:`}
              </span>
              <span className={styles['details-info-context']}>{currentRequest.comment}</span>
            </div>
          </section>
        </div>
      )
    )
  }

  const columns = Columns({ role, handleCancelRequest, isOnlyManager })
  const columnsMemoized = useMemo(() => columns, [])

  return (
    <>
      <div className={styles.container}>
        <section className={styles.header}>
          <h1>{words['sidebar.dayOffTracker']}</h1>
        </section>
        <div className={styles['toggle-bar']}>
          {manager && (
            <ToggleBar
              data={switchTableToggleData}
              name="time-off-requests"
              defaultChecked={selectedTableToggle}
              onChange={event => {
                if (event.value === ONLY_MANAGER) {
                  history.push(
                    `${history.location.pathname}?${queryString.stringify({
                      onlyMy: 'true'
                    })}%3Fstatus%3D12&status=1`
                  )
                }
                if (event.value !== ONLY_MANAGER) {
                  history.push(`${history.location.pathname}?status=1`)
                }
                setSelectedTableToggle(event)
              }}
            />
          )}
        </div>
        <div className={styles.content}>
          <Filter
            config={getFilterConfig({
              role: isOnlyManager ? 4 : role,
              usersData,
              managersData,
              minFromDate,
              maxToDate,
              words
            })}
            withButton={false}
            defaultOpened={true}
          />
          <UIKitTable
            data={data}
            columns={columnsMemoized}
            total={total}
            getData={(/*{ page }*/) => ({})}
            classNameToRow={styles.cursor}
            noData={words['noData']}
            onSelectedRows={rows => {
              if (rows) {
                setSelectedIds(
                  rows.reduce((accumulator: number[], { original }) => {
                    if (original.status === 1) {
                      accumulator.push(original.id)
                    }
                    return accumulator
                  }, [])
                )
              }
            }}
            onRowClick={d => {
              toggleModalDetails()
              setDetailsId(d.original.id as number)
            }}
          />
        </div>
        <div className={btnContainerClass}>
          <Button onClick={handleCreateRequestBtn}>
            <span>{words['adminManager.dayOffTracker.buttons.create']}</span>
          </Button>
          <div className={hideClass}>
            <Button
              outline={true}
              disabled={!data.length || loadingReject}
              loading={loadingReject}
              onClick={() => {
                if (selectedIds && selectedIds.length > 0) {
                  actionForTimeOff({ ids: selectedIds, type: 'reject' })
                  setSelectedIds([])
                }
              }}
            >
              <span>{words['adminManager.dayOffTracker.buttons.reject']}</span>
            </Button>

            <Button
              disabled={!data.length || loadingApprove}
              loading={loadingApprove}
              onClick={() => {
                if (selectedIds && selectedIds.length > 0) {
                  actionForTimeOff({ ids: selectedIds, type: 'approve' })
                  setSelectedIds([])
                }
              }}
            >
              <span>{words['adminManager.dayOffTracker.buttons.approve']}</span>
            </Button>
          </div>
        </div>
      </div>
      {openModalDetails && (
        <Modal
          isShow={openModalDetails}
          onClose={toggleModalDetails}
          className={styles['modal-details']}
        >
          {renderModalContent()}
        </Modal>
      )}
    </>
  )
}
