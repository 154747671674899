/* eslint-disable no-console */
import { Dispatch } from 'redux'
import { api, apiVetifi } from '../../../../../../../services/api'
import {
  verificationSuccess,
  getQrCodeSuccess,
  getSignupSuccess,
  getUserDidSuccess,
  verificationRequest,
  verificationError
} from './Polygon.actions'
import toastr from 'utils/toastr'
import { TState } from 'store'
import { AxiosError } from 'axios'
import { getUserProfileInfo } from 'screens/client/Profile/Profile.thunx'
import { pulling } from 'screens/auth/Login/Login.thunx'
import history from '../../../../../../../utils/history'
import { setUserVerification } from 'screens/auth/Login/Login.actions'
import { EPolygonStatus } from 'globalConfigs'

export const sendVerificationData = (id: any, files: any, _user: any, did: any, status?: any) => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  dispatch(verificationRequest())
  const words = getData().global.language.words
  try {
    const formData = new FormData()
    if (did) {
      formData.append('did', did)
    }
    status && formData.append('verification', status)

    if(files) {
      files.forEach((file: string | Blob) => {
        if (file) {
          formData.append('userFiles', file)
        }
      })
    }
    await api.put(`user/verification`, formData).then(async () => await getUserProfileInfo(id, 'main-info', false)(dispatch, getData))

    dispatch(
      verificationSuccess()
    )
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(verificationError(err.response))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}

export const getQrCodeSignUp = () => async (dispatch: Dispatch, getData: () => TState): Promise<void> => {
  dispatch(verificationRequest())
  const words = getData().global.language.words
  try {
    const resp: any = await apiVetifi.get('/polygon/sign-up')
    dispatch(
      getSignupSuccess({
        signupQr: resp.data
      })
    )
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const getWalletDids = setInterval(async () => {
      if (history.location.pathname !== '/dashboard/subcontract/verification') {
        dispatch(
          getSignupSuccess({
            signupQr: {}
          })
        )
        clearInterval(getWalletDids)
      }
      const status: any = await apiVetifi.get(`/polygon/status?sessionId=${resp.data.sessionId}`)
      console.log('statusDDD', status);
      // eslint-disable-next-line prettier/prettier
      if (status?.status === 200) {
        dispatch(
          getUserDidSuccess({
            did: status.data.did
          })
        )
        sendVerificationData(getData().auth.data.id, [], {}, status.data.did, EPolygonStatus.DATA)(dispatch, getData)
        clearInterval(getWalletDids)
        toastr('success', 'Did was received')
      }
    }, 1000)
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(verificationError(err.response))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}

export const getQrCodeSignIn = () => async (dispatch: Dispatch, getData: () => TState): Promise<void> => {
  dispatch(verificationRequest())
  const words = getData().global.language.words
  console.log(process.env.REACT_APP_ADMIN_DID)
  try {
    const qrclaim = { data: {}}
    const qrAllClaims: any = await apiVetifi.get(
      `polygon/${process.env.REACT_APP_ADMIN_DID}/claims`
    )

    const resp = await apiVetifi.get('/polygon/sign-in')

    const getVerificationStatus= setInterval(async () => {
      if (history.location.pathname !== '/dashboard/subcontract/verification' && history.location.pathname !== '/dashboard/subcontract/verification/claims') {
        dispatch(
          getQrCodeSuccess({
            signinQr : { sessionId: ''}
          })
        )
        clearInterval(getVerificationStatus)
      }
    const status: any = await apiVetifi.get(`/polygon/status?sessionId=${resp.data.sessionId}`)
      // eslint-disable-next-line prettier/prettier
      if (status?.status === 200) {
        sendVerificationData(getData().auth.data.id, [], {}, false, 'true')(dispatch, getData)
        dispatch(setUserVerification('true'))
        pulling()(dispatch)
        clearInterval(getVerificationStatus)
      }
    }, 1000)

    dispatch(
      getQrCodeSuccess({
        claims: qrclaim.data , signinQr : resp.data , claimsList: qrAllClaims.data.filter((claim: any) =>
        claim.credentialSubject.id === getData().auth.data.did
      )
      })
    )
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    console.log(err)
    dispatch(verificationError(err))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
    }
  }

export const qetClaimsQrCode = (offer: any) => async (dispatch: Dispatch, getData: () => TState): Promise<void> => {
  dispatch(verificationRequest())
  const words = getData().global.language.words

  try {
    const qrclaim = await apiVetifi.get(
      `polygon/${process.env.REACT_APP_ADMIN_DID}/claims/${offer}/qrcode`
    )
    dispatch(
      getQrCodeSuccess({
        claims: qrclaim.data , signinQr : getData().client.verificationClient.qrCode.signinQr, claimsList: getData().client.verificationClient.claimsList
      })
    )
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(verificationError(err.response))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}
