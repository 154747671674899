import { action } from '../../../store/store.utils'
import { AxiosError } from 'axios'
import {
  DELETE_PROJECT_INVOICE,
  DELETE_SELECTED_PROJECT_INVOICE,
  GET_ONE_PROJECT_INVOICE_SUCCESS,
  GET_PROJECT_INVOICES_ERROR,
  GET_PROJECT_INVOICES_REQUEST,
  GET_PROJECT_INVOICES_SUCCESS,
  SELECT_ALL_PROJECT_INVOICES,
  SELECT_PROJECT_INVOICE
} from './Invoices.constants'

export const getProjectInvoicesRequest = () => action(GET_PROJECT_INVOICES_REQUEST)
export const getProjectInvoicesSuccess = (payload: any) =>
  action(GET_PROJECT_INVOICES_SUCCESS, payload)

export const getProjectInvoicesError = (payload: AxiosError) =>
  action(GET_PROJECT_INVOICES_ERROR, payload)

export const getOneProjectInvoiceSuccess = (payload: any) =>
  action(GET_ONE_PROJECT_INVOICE_SUCCESS, payload)
export const selectProjectInvoice = (payload: any) => action(SELECT_PROJECT_INVOICE, payload)

export const selectAllProjectInvoices = (payload: any) =>
  action(SELECT_ALL_PROJECT_INVOICES, payload)

export const deleteSelectedProjectInvoice = (payload: any) =>
  action(DELETE_SELECTED_PROJECT_INVOICE, payload)

export const deleteProjectInvoiceSuccess = (id: string) => action(DELETE_PROJECT_INVOICE, id)
