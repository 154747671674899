import { IAction } from 'models/index'

import {
  SET_NEW_ITEM_REQUEST,
  SET_NEW_ITEM_SUCCESS,
  SET_NEW_ITEM_ERROR,
  GET_GOODS_CATEGORIES_REQUEST,
  GET_GOODS_CATEGORIES_SUCCESS,
  GET_GOODS_CATEGORIES_ERROR,
  GET_CURRENT_ITEM_REQUEST,
  GET_CURRENT_ITEM_SUCCESS,
  GET_CURRENT_ITEM_ERROR,
  REMOVE_REVIEW_ERROR,
  REMOVE_REVIEW_REQUEST,
  REMOVE_REVIEW_ADMIN_SUCCESS,
  SET_NEW_CATEGORY_SUCCESS,
  DELETE_GOODS_CATEGORY_SUCCESS,
  SET_NEW_CATEGORY_REQUEST,
  SET_NEW_CATEGORY_ERROR,
  DELETE_ACHIEVEMENT_CATEGORY_SUCCESS
} from './GoodsItem.constants'
import { AxiosError } from 'axios'
import { TCategories, TGoodData } from '../../client/GoodsItemDetails/GoodsItemDetails.model'
import { CLEAR_GOOD_DATA } from '../../client/GoodsItemDetails/GoodsItemDetails.constants'
import { TReviewsItem } from '../Shop/Shop.model'

export type TCreteItemState = {
  data: TGoodData | { reviews: [] }
  loading: boolean
  error: string | AxiosError
  goodsCategories: TCategories[]
  achievementCategories: TCategories[]
}

export const initialState: TCreteItemState = {
  data: { reviews: [] },
  error: '',
  loading: false,
  goodsCategories: [],
  achievementCategories: []
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_NEW_ITEM_REQUEST:
    case GET_GOODS_CATEGORIES_REQUEST:
    case GET_CURRENT_ITEM_REQUEST:
    case SET_NEW_CATEGORY_REQUEST:
    case REMOVE_REVIEW_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case SET_NEW_ITEM_ERROR:
    case GET_GOODS_CATEGORIES_ERROR:
    case GET_CURRENT_ITEM_ERROR:
    case SET_NEW_CATEGORY_ERROR:
    case REMOVE_REVIEW_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    }

    case SET_NEW_ITEM_SUCCESS: {
      return {
        ...state,
        data: [],
        loading: false
      }
    }

    case SET_NEW_CATEGORY_SUCCESS: {
      return {
        ...state,
        goodsCategories: [...state.goodsCategories, action.payload],
        achievementCategories: [...state.achievementCategories, action.payload],
        loading: false
      }
    }

    case GET_GOODS_CATEGORIES_SUCCESS: {
      return {
        ...state,
        goodsCategories: action.payload.filter((item: TCategories) => item.type === 1),
        achievementCategories: action.payload.filter((item: TCategories) => item.type === 2),
        loading: false
      }
    }

    case GET_CURRENT_ITEM_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    }

    case CLEAR_GOOD_DATA: {
      return {
        ...initialState
      }
    }

    case REMOVE_REVIEW_ADMIN_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          reviews: state.data.reviews.filter(
            (element: TReviewsItem) => Number(action.payload) !== Number(element.id)
          )
        },
        loading: false
      }
    }

    case DELETE_GOODS_CATEGORY_SUCCESS: {
      return {
        ...state,
        goodsCategories: state.goodsCategories.filter(value => value.id !== action.payload.id)
      }
    }

    case DELETE_ACHIEVEMENT_CATEGORY_SUCCESS: {
      return {
        ...state,
        achievementCategories: state.achievementCategories.filter(
          value => value.id !== action.payload.id
        )
      }
    }

    default:
      return state
  }
}
