import { IAction } from 'models'
import {
  TASK_REQUEST_LOADING,
  TASK_REQUEST_SUCCESS,
  TASK_REQUEST_ERROR
} from './TaskRequest.costants'

export type IInitialState = {
  error: string
  loading: boolean
}

const initialState = {
  error: '',
  loading: false
}
export default (state = initialState, action: IAction): any => {
  switch (action.type) {
    case TASK_REQUEST_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case TASK_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false
      }
    }
    case TASK_REQUEST_ERROR: {
      return {
        ...state,
        error: action.payload.data.message,
        loading: false
      }
    }
    default:
      return state
  }
}
