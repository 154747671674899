import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import AllTasksListComponent from './AllTasksList'
import { getTasks } from '../../../screens/client/Subcontract/Subcontract.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    taskArray: state.client.subcontract.tasks,
    usersArray: state.client.subcontract.users,
    loading: state.client.subcontract.loading
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getTasks
    },
    dispatch
  )

export const AllTasksList = connect(
  mapStateToProps,
  mapDispatchToProps
)(AllTasksListComponent)
