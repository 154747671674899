import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { IToken } from 'screens/admin/Token/Token.model'
import { TState } from 'store'
import styles from './TokenField.module.scss'
import { Icons } from 'components/Icons'
import cls from 'classnames'

interface ITokenField {
  token: IToken
  openModal: () => void
  disabled: boolean
}

export const TokenField: FC<ITokenField> = ({ token, openModal, disabled }) => {
  const words = useSelector((state: TState) => state.global.language.words)

  console.log('🚀 ~ file: TokenField.tsx:19 ~ value:', token)

  return (
    <div
      onClick={() => {
        disabled ? null : openModal()
      }}
    >
      <div className={styles.fieldDescription}>
        {words['user.subcontract.task.currency']}
        <span className={cls({ [styles['red-note']]: true, [styles['disabled']]: disabled })}>
          *
        </span>
      </div>

      <div className={cls({ [styles.field]: true, [styles['opacity']]: disabled })}>
        {token && (
          <>
            <img src={`${token.logoURI}`} className={styles.tokenImg} />
            <span className={styles.tokenName}>{token.symbol}</span>
            <Icons icon={'arrowDown'} style={{ marginLeft: 'auto' }} />
          </>
        )}
      </div>
    </div>
  )
}
