import { SliderProps } from '@mui/material/Slider'

export const TSliderVariants = {
  default: 'default',
  gradient: 'gradient'
} as const

export type TSliderProps = SliderProps & {
  sliderVariant?: typeof TSliderVariants[keyof typeof TSliderVariants]
  isValuesShows?: boolean
  valuesCurrrency?: string
}
