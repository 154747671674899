import React, { useEffect } from 'react'
import { Select as NewUIKitSelect } from 'Select'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store'
import { getVacancy, updateVacancy } from '../Vacancies.thunk'

const SelectVacancyStatus = ({ options, vacancy, fromPage }: any) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const dispatch = useDispatch()
  const getStatusColors = () => {
    switch (vacancy.status.id || vacancy.status) {
      case 1:
        return { backgroundColor: '#FFD5E5', color: '#E12F75' }
      case 2:
        return { backgroundColor: '#B2FDD8', color: '#149B58' }
      case 3:
        return { backgroundColor: '#FFDFC8', color: '#FF5F1B' }
      case 4:
        return { backgroundColor: '#EFEFFF', color: '#3737ED' }
      case 5:
        return { backgroundColor: '#FFD5E5', color: '#E12F75' }
      default:
        return { backgroundColor: '#fff', color: '#fff' }
    }
  }
  const color = getStatusColors()
  const selectStyles = {
    container: (style: any) => ({
      ...style,
      width: '132px'
    }),
    control: (style: any) => ({
      ...style,
      backgroundColor: color.backgroundColor,
      fontWeight: '700',
      borderRadius: '20px',
      minHeight: '30px',
      input: { color: color.color },
      height: '21px',
      border: 'none'
    }),
    valueContainer: (style: any) => ({
      ...style,
      fontSize: '10px',
      div: { color: color.color },
      input: { fontWeight: '700' }
    }),
    indicatorsContainer: (style: any) => ({
      ...style,
      div: { color: color.color, paddingTop: '4px' }
    })
  }

  useEffect(() => {
    getStatusColors()
  }, [vacancy])
  const handleVacancySelect = (e: any) => {
    const statusValue = e.value
    const statusLabel = e.label
    if (!fromPage) {
      dispatch(
        updateVacancy(
          vacancy.id,
          { ...vacancy, status: +statusValue },
          { ...vacancy, status: { id: +statusValue, label: statusLabel } }
        )
      )
    } else {
      dispatch(
        updateVacancy(
          vacancy.id,
          { ...vacancy, status: +statusValue },
          { ...vacancy, status: { id: +statusValue, label: statusLabel } }
        )
      )
      dispatch(getVacancy(vacancy.id))
    }
  }
  return (
    <div onClick={e => e.stopPropagation()} style={{ width: '100%' }} data-tip="" data-for="model">
      <NewUIKitSelect
        options={options}
        styles={selectStyles}
        placeholder={vacancy.status.label || words[vacancy.status.name]}
        errorMessage={'error'}
        emptyMessage={words['noOption']}
        onChange={handleVacancySelect}
      />
    </div>
  )
}
export default SelectVacancyStatus
