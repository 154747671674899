import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form } from 'react-final-form'
import { TState } from 'store'
import toastr, { EToastrTypeMessage } from '../../utils/toastr'
import cls from 'classnames'
import validation from '../../utils/validation'
import { Button } from '../UiKit/Button'
import { RedNote } from '../RedNote'
import { Textarea } from '../Textarea'
import Modal from '../Modal'
import styles from './styles.module.scss'
import getWeb3 from 'getWeb3'
import { getHashSignature } from 'utils/getHashSignature'
import { getContractAbi } from 'utils/getContractAbi'
import { EContractAddress } from 'globalConfigs'
import { EDealStatus } from 'screens/client/Web3/web3.model'
import { SplitSumRangeInput } from 'components/SplitSumRangeInput'
import Checkbox from 'components/UiKit/Checkbox/Checkbox'
import { AbiItem } from 'web3-utils'
import { ITaskCancellation } from 'screens/client/Subcontract/Subcontract.model'
import { Dispatch } from 'redux'
import { ETaskStatus } from 'screens/client/Subcontract/components/CreateTask/CreateTask.model'
import { ETransactionMessage } from 'components/Spinner/types'

type TModalProps = {
  toggle?: boolean
  action?: any
  size?: 'default' | 'md'
  task: any
  updateTask: any
  loading: boolean
  error: string
  history: any
  createTaskCancellation: (
    payload: Partial<ITaskCancellation>,
    taskId: number
  ) => (dispatch: Dispatch) => Promise<void>
  updateTaskCancellation: (
    payload: Partial<ITaskCancellation>,
    taskId: number
  ) => (dispatch: Dispatch) => Promise<void>
  currentUser: any
  isTaskInTransaction: (value: boolean) => void
  proposal: any
  isAdmin?: boolean
  createVoting?: (endOfVoting: Date, taskId: number) => (dispatch: Dispatch) => Promise<void>
}

export interface InputsState {
  field1: number
  field2: number
  numInRange: number
}

const CancelTaskModal: FC<TModalProps> = ({
  toggle,
  action,
  size = 'default',
  task,
  loading,
  error,
  history,
  currentUser,
  createTaskCancellation,
  createVoting,
  updateTaskCancellation,
  isTaskInTransaction,
  proposal,
  isAdmin
}) => {
  const [openLoadingModal, setOpenLoadingModal] = useState<boolean>(false)
  const [isChecked, setIsChecked] = useState<any>(false)
  const [isOnVoting, setIsOnVoting] = useState<any>(false)
  const words = useSelector((state: TState) => state.global.language.words)
  const dispatch = useDispatch()
  const [inputsState, setInputsState] = useState<InputsState>({
    field1: 0,
    field2: 0,
    numInRange: 50
  })

  const onSubmit = async (values: any) => {
    if (isAdmin) {
      handleStopTaskWithProblem(values)
    } else if (!isChecked && !isAdmin) {
      await cancelTask(values)
    } else {
      handleStopTask(values)
    }
  }

  const closeLoadingModal = () => {
    return history.goBack()
  }

  const checkHandler = () => {
    setIsChecked(!isChecked)
  }

  const onChangeVoting = () => {
    setIsOnVoting(!isOnVoting)
  }

  const cancelTask = async (values: any) => {
    const web3 = await getWeb3()
    const { taskHash } = task

    try {
      const addresses = await web3.eth.getAccounts()
      const contractAbi: AbiItem | AbiItem[] = await getContractAbi(
        EContractAddress.FIX_DEAL_ESCROW
      )
      const contractInstance = new web3.eth.Contract(contractAbi, EContractAddress.FIX_DEAL_ESCROW)
      const customerSignature = await getHashSignature(addresses[0], taskHash)
      const gasPrice = await web3.eth.getGasPrice()
      const gasLimit = await contractInstance.methods
        .cancelTask(taskHash, customerSignature)
        .estimateGas({ from: addresses[0] })

      await contractInstance.methods
        .cancelTask(taskHash, customerSignature)
        .send({
          from: addresses[0],
          gasPrice,
          gas: gasLimit
        })
        .on('transactionHash', () => {
          dispatch(isTaskInTransaction(true))
          toastr(EToastrTypeMessage.INFO, words[`${ETransactionMessage.TRANSACTION_MESSAGE}`])
        })
        .on('receipt', () => {
          createTaskCancellation(
            {
              cancelledAt: new Date().toISOString(),
              task: task.id,
              statusId: ETaskStatus.CANCELLED,
              dealStatus: EDealStatus.CANCELLED,
              commentFromCustomer: String(values.cancelDescription),
              refundCustomerPercent: 100,
              customerSignature
            },
            task.id
          )
          toastr(EToastrTypeMessage.SUCCESS, words[`${ETransactionMessage.TRANSACTION_SUCCESS}`])
        })
        .on('error', () => {
          toastr(EToastrTypeMessage.ERROR, words[`${ETransactionMessage.TRANSACTION_ERROR}`])
          dispatch(isTaskInTransaction(false))
        })

      setOpenLoadingModal(true)
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(isTaskInTransaction(false))
    }
  }

  const handleStopTask = async (values: any) => {
    console.log(task)
    const web3 = await getWeb3()
    const { taskHash } = task
    let taskCancellation: Partial<ITaskCancellation> = {
      task: task.id,
      statusId: ETaskStatus.WITH_VIOLATION,
      publicVoting: isOnVoting,
      dealStatus:
        currentUser.id !== task.executor.id
          ? EDealStatus.CANCELLED_BY_SUPPORT_COMMENT_FROM_CONTRACTOR
          : EDealStatus.CANCELLED_BY_SUPPORT_COMMENT_FROM_CUSTOMER
    }

    try {
      const addresses = await web3.eth.getAccounts()
      const contractAbi = await getContractAbi(EContractAddress.FIX_DEAL_ESCROW)
      const contractInstance = new web3.eth.Contract(contractAbi, EContractAddress.FIX_DEAL_ESCROW)
      const signature = await getHashSignature(addresses[0], taskHash)

      if (task.customer.id === currentUser.id) {
        taskCancellation = {
          ...taskCancellation,
          commentFromCustomer: values.cancelDescription,
          refundCustomerPercent: inputsState.numInRange,
          customerSignature: signature
        }
      } else {
        taskCancellation = {
          ...taskCancellation,
          commentFromContractor: values.cancelDescription,
          refundContractorPercent: inputsState.numInRange,
          contractorSignature: signature
        }
      }

      const gasPrice = await web3.eth.getGasPrice()
      const gasLimit = await contractInstance.methods
        .stopTask(taskHash, signature, inputsState.numInRange)
        .estimateGas({ from: addresses[0] })

      await contractInstance.methods
        .stopTask(taskHash, signature, inputsState.numInRange)
        .send({
          from: addresses[0],
          gasPrice,
          gas: gasLimit
        })
        .on('transactionHash', () => {
          dispatch(isTaskInTransaction(true))
          toastr(EToastrTypeMessage.INFO, words[`${ETransactionMessage.TRANSACTION_MESSAGE}`])
        })
        .on('receipt', async () => {
          if (task.taskCancellation === null) {
            if (isOnVoting && createVoting) {
              await createVoting(new Date(), task.id)
            }
            await createTaskCancellation(taskCancellation, task.id)
          } else {
            await updateTaskCancellation(taskCancellation, task.taskCancellation.id)
            toastr('success', 'Comment successfully added')
          }

          toastr(EToastrTypeMessage.SUCCESS, words[`${ETransactionMessage.TRANSACTION_SUCCESS}`])
        })
        .on('error', () => {
          toastr(EToastrTypeMessage.ERROR, words[`${ETransactionMessage.TRANSACTION_ERROR}`])
          dispatch(isTaskInTransaction(false))
        })
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(isTaskInTransaction(false))
    }
  }

  const handleStopTaskWithProblem = async (values: any) => {
    const web3 = await getWeb3()
    const { taskHash } = task

    try {
      const addresses = await web3.eth.getAccounts()
      const contractAbi = await getContractAbi(EContractAddress.FIX_DEAL_ESCROW)
      const contractInstance = new web3.eth.Contract(contractAbi, EContractAddress.FIX_DEAL_ESCROW)

      const refundCustomerPercent = inputsState.numInRange
      const refundContractorPercent = 100 - inputsState.numInRange

      const updatedTaskCancellation = {
        task: task.id,
        commentFromSupport: values.cancelDescription,
        refundCustomerPercent,
        refundContractorPercent,
        statusId: ETaskStatus.CANCELLED,
        dealStatus: EDealStatus.CANCELLED_BY_SUPPORT_COMMENT_FROM_BOTH,
        cancelledAt: new Date().toISOString()
      }

      const gasPrice = await web3.eth.getGasPrice()
      const gasLimit = await contractInstance.methods
        .processStoppedTask(taskHash, refundCustomerPercent, refundContractorPercent)
        .estimateGas({ from: addresses[0] })

      contractInstance.methods
        .processStoppedTask(taskHash, refundCustomerPercent, refundContractorPercent)
        .send({
          from: addresses[0],
          gasPrice,
          gas: gasLimit
        })
        .on('transactionHash', () => {
          dispatch(isTaskInTransaction(true))
          toastr(EToastrTypeMessage.INFO, words[`${ETransactionMessage.TRANSACTION_MESSAGE}`])
        })
        .on('receipt', async () => {
          await updateTaskCancellation(updatedTaskCancellation, task.taskCancellation.id)
          history.push(
            '/dashboard/settings?page=1&taskCancellationType=cancelled&type=taskCancellation'
          )
          toastr(EToastrTypeMessage.SUCCESS, words[`${ETransactionMessage.TRANSACTION_SUCCESS}`])
        })
        .on('error', () => {
          toastr(EToastrTypeMessage.ERROR, words[`${ETransactionMessage.TRANSACTION_ERROR}`])
          dispatch(isTaskInTransaction(false))
        })
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(isTaskInTransaction(false))
    }
  }

  const isShowSplitSum = (isChecked && !isAdmin) || isAdmin

  return (
    <div>
      <div onClick={action} className={cls({ [styles.overlay]: toggle })} />
      <div className={cls({ [styles.container]: true, [styles.active]: toggle })}>
        <div className={cls({ [styles.modal]: true, [styles[`modal-size-${size}`]]: true })}>
          <h2>{words['user.subcontract.cancelTaskModal.header']}</h2>
          <h3 className={styles.subTitle}>
            {!isChecked ? 'Отменить с возвратом' : 'Отменить с разделом суммы'}
          </h3>
          <Form
            onSubmit={(values: any) => {
              onSubmit(values)
            }}
          >
            {({ form, handleSubmit }) => {
              const formState = form.getState()
              const checkRequired = async () => {
                if (formState.errors && Object.keys(formState.errors).length !== 0) {
                  handleSubmit()
                  toastr('error', words['user.editProfile.fillRequiredFields'])
                } else {
                  handleSubmit()
                  action()
                }
              }

              return (
                <form onSubmit={handleSubmit} name="form">
                  <section
                    className={cls({
                      [styles.form]: true,
                      [styles['cancel-task-form']]: true
                    })}
                  >
                    <div className={styles.message}>
                      <div className={styles.label}>
                        <label>
                          {words['user.taskSelection.comment']} {<RedNote />}
                        </label>
                      </div>
                      <Field
                        name="cancelDescription"
                        validate={validation.required(words['user.requiredMessage'])}
                      >
                        {({ input, meta }) => (
                          <Textarea
                            {...input}
                            isInvalid={meta.error && meta.submitFailed}
                            errorMessage={meta.error}
                            size={'sm'}
                            placeholder="Причина отмены"
                          />
                        )}
                      </Field>
                    </div>

                    {!isAdmin && (
                      <div className={styles.check}>
                        <Checkbox
                          name={'refund'}
                          value={isChecked}
                          label="Отменить с разделом суммы"
                          onChange={checkHandler}
                        />

                        {!task.taskCancellation && (
                          <Checkbox
                            name={'voting'}
                            value={isOnVoting}
                            label="Выставить на общее голосование"
                            onChange={onChangeVoting}
                          />
                        )}
                      </div>
                    )}
                  </section>

                  {isShowSplitSum && (
                    <>
                      <div className={styles.splitSumContainer}>
                        <div className={styles.splitSumWrapper}>
                          <SplitSumRangeInput
                            budget={proposal && proposal.budget}
                            inputsState={inputsState}
                            setInputsState={setInputsState}
                            task={task}
                          />
                        </div>

                        <div className={styles.controllerDescription}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae vestibulum
                          amet elit amet.
                        </div>
                      </div>
                    </>
                  )}

                  <section className={`${styles.buttons} ${styles['buttons-cancel']}`}>
                    <Button outline={true} size={'lg'} onClick={action}>
                      <span>{words['user.subcontract.cancelTaskModal.cancel']}</span>
                    </Button>
                    <Button onClick={checkRequired} size={'lg'}>
                      <span>{words['user.subcontract.cancelTaskModal.cancelTask']}</span>
                    </Button>
                  </section>
                </form>
              )
            }}
          </Form>
          <Modal
            isShow={openLoadingModal}
            onClose={() => {
              if (!loading) closeLoadingModal()
            }}
            className={styles.LoadingModal}
          >
            {loading && !error && <p>{words['user.subcontract.taskRequest.loading']}</p>}
            {!loading && !error && (
              <div className={styles.success}>
                <p>{words['admin.subcontract.taskCancel.success']}</p>
                <div className={styles.btn}>
                  <Button onClick={closeLoadingModal}>
                    <span>Ok</span>
                  </Button>
                </div>
              </div>
            )}
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default CancelTaskModal
