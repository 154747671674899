import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import styles from '../../EditProfile.module.scss'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import { editCareerInfoConfig } from './EditCareer.config'
import { Icons } from '../../../../../components/Icons'

export const EditCareer: any = ({ form, isAdmin, userInfo }: any) => {
  const [userProjects, setUserProjects] = useState<any>([])
  const words = useSelector((state: TState) => state.global.language.words)
  const options = useSelector((state: TState) => state.client.editProfile)
  const projectOptions = useSelector((state: TState) => state.admin.vacancies.projectOptions)

  const deleteProject = (idx: number) => {
    setUserProjects(form.getState().values.projectsTeam)
    const deletedArray = userProjects.filter((_item: any, i: any) => i !== idx)
    form.change('projectsTeam', deletedArray)
    setUserProjects(deletedArray)
  }

  useEffect(() => {
    setUserProjects(userInfo.projectsTeam)
  }, [userInfo])
  return (
    <>
      <section className={styles.section}>
        <h3>{words['user.profile.career.info']}</h3>
        <FieldFactory
          form={form}
          config={editCareerInfoConfig(words, { options, isAdmin, isProject: false })}
          words={words}
        />
      </section>
      {isAdmin && (
        <section style={{ marginTop: '24px' }} className={styles.section}>
          <h3>{words['project.projects']}</h3>
          {userProjects &&
            userProjects.map((item: any, index: number) => {
              return (
                <div className={styles['project__wrapper']} key={item.rank}>
                  <FieldFactory
                    form={form}
                    config={editCareerInfoConfig(words, {
                      options: { ...options, projectOptions },
                      isAdmin,
                      index: index,
                      isProject: true
                    })}
                    words={words}
                  />
                  <Icons
                    className={styles['delete_vacancy']}
                    icon={'cross'}
                    onClick={() => {
                      deleteProject(index)
                    }}
                  />
                </div>
              )
            })}
          <div
            onClick={() =>
              setUserProjects([
                ...userProjects,
                userProjects.length === 0 ? { id: 1 } : { id: userProjects.length - 1 }
              ])
            }
            className={styles.addProjectBtn}
          >
            <Icons className={styles['add_project']} icon={'addBlue'} />
          </div>
        </section>
      )}
    </>
  )
}
