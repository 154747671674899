import React, { FC, useEffect, useState } from 'react'
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom'
import PrivateRoute from '../PrivatRoute'
import { Dashboard } from './components/Dashboard'
import { Login } from 'screens/auth/Login'
import { mapStateToProps, mapDispatchToProps } from './App.container'
import styles from './App.module.scss'
import {
  defaultContextValue,
  isManager,
  isUser,
  isAdmin,
  isSuperAdmin,
  TContextValue,
  UserContext
} from 'utils/user'
import { Elanguages } from 'store/global'
import UncontrolledLottie from '../Lotties/UncontrolledLottie'
import { useDispatch, useSelector } from 'react-redux'
import { Web3Provider } from '@ethersproject/providers'
import { Web3ReactProvider } from '@web3-react/core'
import { NotificationService } from 'socket/libs/services/notifications.socket.service'
import { socketService } from 'socket/socket'
import AuthRoute from 'elements/AuthRoute/AuthRoute.container'
import { TState } from 'store'
import { isEmpty } from 'lodash'
import { useAuth0 } from '@auth0/auth0-react'
import { addAccessTokenInterceptor } from 'services/api'

type Props = RouteComponentProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

export type TLanguageObject = {
  fetchInfo: (language: string) => void
  languages: string[]
  currentLanguage: string
}

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

export const App: FC<Props> = props => {
  const { currentLanguage, loading, error, fetchInformation, role, userId } = props
  const [isAdminSide, setAdminSide] = useState<boolean | null>(null)
  const [contextValue, setContextValue] = useState<TContextValue>(defaultContextValue)
  const words = useSelector((state: TState) => state.global.language.words)
  const dispatch = useDispatch()
  const technorelyLanguage = 'TechnorelyLanguage'
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently)
  }, [getAccessTokenSilently])

  const fetchInfo = (language: string) => {
    localStorage.setItem(technorelyLanguage, language)
    fetchInformation(language)
  }

  const fetchInformationWithSave = (language: string) => {
    const currentLang = localStorage.getItem(technorelyLanguage)
    if (currentLang) {
      fetchInformation(currentLang)
    } else {
      localStorage.setItem(technorelyLanguage, language)
      fetchInformation(language)
    }
  }

  const languageObject = {
    fetchInfo,
    languages: Object.values(Elanguages),
    currentLanguage
  }

  useEffect(() => {
    fetchInformationWithSave(currentLanguage)
  }, [currentLanguage])

  useEffect(() => {
    let userData
    //Set up SSL on Backend side for socket.io on hosting
    socketService.connect()

    const currentRole = isSuperAdmin(role) || isAdmin(role)
    if (isAdminSide !== currentRole) {
      setAdminSide(currentRole)
    }

    if (role && userId) {
      const notificationService = new NotificationService(socketService.getSocket())
      notificationService.notificationConnect(userId, dispatch)

      setContextValue({
        data: userData,
        superAdmin: isSuperAdmin(role),
        admin: isAdmin(role),
        manager: isManager(role),
        user: isUser(role)
      } as TContextValue)
    }
  }, [role, userId])

  const isSignin = window.location.href.includes('signin')

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        document.location.reload()
      }, 2000)
    }
  }, [error])

  const content = () => {
    if ((loading || error || isEmpty(words)) && !isSignin) {
      return (
        <div className={styles.loadingDiv}>
          <div className={styles.loading}>
            <UncontrolledLottie />
          </div>
        </div>
      )
    }
    return (
      <Web3ReactProvider getLibrary={getLibrary}>
        <UserContext.Provider value={contextValue}>
          <Switch>
            <PrivateRoute path="/dashboard" component={Dashboard} languageObject={languageObject} />
            <AuthRoute path="/signin" component={Login} />

            <Route render={() => <Redirect to="/signin" />} />
          </Switch>
        </UserContext.Provider>
      </Web3ReactProvider>
    )
  }

  return content()
}
