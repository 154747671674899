import {
  GET_POLICY_REQUEST,
  GET_POLICY_SUCCESS,
  GET_POLICY_ERROR,
  POST_POLICY_SUCCESS,
  COMPARE_POLICY_ERROR
} from './Policy.constants'
import { IAction } from 'models'
import { ActionCreator } from 'redux'
import { SET_ACCEPT_POLICY } from 'screens/auth/Login/Login.constants'

export const policyRequest: ActionCreator<IAction> = payload => ({
  type: GET_POLICY_REQUEST,
  payload
})

export const getPolicySuccess: ActionCreator<IAction> = payload => ({
  type: GET_POLICY_SUCCESS,
  payload
})

export const postPolicySuccess: ActionCreator<IAction> = payload => ({
  type: POST_POLICY_SUCCESS,
  payload
})

export const getPolicyError: ActionCreator<IAction> = payload => ({
  type: GET_POLICY_ERROR,
  payload
})

export const comparePolicyError: ActionCreator<IAction> = payload => ({
  type: COMPARE_POLICY_ERROR,
  payload
})

export const setAcceptPolicy: ActionCreator<IAction> = payload => ({
  type: SET_ACCEPT_POLICY,
  payload
})
