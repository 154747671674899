import { Dispatch } from 'redux'
import { TState } from 'store'
import {
  getProjectsRequest,
  getProjectsSuccess,
  getOneProjectSuccess,
  getProjectsError,
  changeProjectRequest,
  changeProjectSuccess,
  changeProjectError,
  getProjectHistoryRequest,
  getProjectHistorySuccess,
  getManagersRequest,
  getManagersSuccess,
  getManagersError
} from './Projects.actions'
import { api } from 'services/api'
import { API } from '../../../services/api'
import { AxiosError } from 'axios'
import toastr from '../../../utils/toastr'
import history from 'utils/history'

export const getProjects: any = (page: number, search: string = '', limit: number = 16) => async (
  dispatch: Dispatch
) => {
  dispatch(getProjectsRequest())

  try {
    const response: any = await api.get(
      `${API.URL}/projects${search}${search ? '&' : '?'}limit=${limit}&page=${page}`
    )

    dispatch(getProjectsSuccess(response.data))
  } catch (error) {
    const err = error as AxiosError<any>
    await dispatch(getProjectsError(err))
  }
}

export const getOneProject: any = (id: number) => async (dispatch: Dispatch) => {
  dispatch(getProjectsRequest())

  try {
    const response = await api.get(`${API.URL}/projects/${id}`)

    dispatch(getOneProjectSuccess(response.data.result))
  } catch (error) {
    const err = error as AxiosError<any>
    await dispatch(getProjectsError(err))
  }
}

export const deleteProject: any = (id: number) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(changeProjectRequest())

  const words = getData().global.language.words
  try {
    const response: any = await api.delete(`${API.URL}/projects/${id}`)

    dispatch(changeProjectSuccess(response.data))
    toastr('success', words['project.toArchiveSuccess'])
    history.push('/dashboard/projects?deleted=true')
  } catch (error) {
    const err = error as AxiosError<any>
    await dispatch(changeProjectError(err))
    toastr('error', words['project.toArchiveError'])
  }
}

export const restoreProject: any = (id: number) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(changeProjectRequest())

  const words = getData().global.language.words
  try {
    const response: any = await api.put(`${API.URL}/projects/restore/${id}`)

    dispatch(changeProjectSuccess(response.data))
    toastr('success', words['project.restoreSuccess'])
    history.push('/dashboard/projects?deleted=false')
  } catch (error) {
    const err = error as AxiosError<any>
    await dispatch(changeProjectError(err))
    toastr('error', words['project.restoreError'])
  }
}

export const getProjectHistory = (id: number, search: string = '') => async (
  dispatch: Dispatch
) => {
  dispatch(getProjectHistoryRequest())
  try {
    const result = await api.get(`${API.URL}/project-history/${id}${search}`)

    dispatch(getProjectHistorySuccess(result.data.result))
  } catch (error) {
    // const err = error as AxiosError<any>
    toastr('error', 'error')
  }
}

export const getManagers: any = () => async (dispatch: Dispatch) => {
  dispatch(getManagersRequest())
  try {
    const result: any = await api.get(`${API.URL}/managers`)
    dispatch(getManagersSuccess(result.data.payload))
  } catch (error) {
    const err = error as AxiosError<any>
    await dispatch(getManagersError(err))
  }
}
