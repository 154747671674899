import { TBaseFilterConfigData } from 'components/UiKit/Filter/Filter'
import { TWords } from 'elements/SideBar/SideBar.config'
import moment from 'moment'

export const getFilterConfig = ({
  words,
  fromDate,
  toDate
}: {
  words: TWords
  fromDate: any
  toDate: any
}): TBaseFilterConfigData[] => {
  const filter: TBaseFilterConfigData[] = [
    {
      name: 'durationFromDate',
      secondName: 'durationToDate',
      type: 'dateRangeNew',
      fieldData: {
        from: fromDate ? moment(+fromDate).valueOf() : '',
        to: toDate ? moment(+toDate).valueOf() : ''
      },
      label: words['projects.invoices.duration'],
      width: '20%',
      rangeContainerWidth: 320
    },
    {
      name: 'fromPeople',
      secondName: 'toPeople',
      type: 'inputRange',
      fieldData: {
        from: '',
        to: ''
      },
      label: words['projects.invoices.countOfPeople'],
      width: '50%',
      rangeContainerWidth: 250
    },
    {
      name: 'priceFrom',
      secondName: 'priceTo',
      type: 'inputRange',
      fieldData: {
        from: '',
        to: ''
      },
      label: words['projects.invoices.totalAmount'],
      width: '50%',
      rangeContainerWidth: 250
    },
    {
      name: 'paymentFromDate',
      secondName: 'paymentToDate',
      type: 'dateRangeNew',
      fieldData: {
        from: fromDate ? moment(+fromDate).valueOf() : '',
        to: toDate ? moment(+toDate).valueOf() : ''
      },
      label: words['projects.invoices.paymentDate'],
      width: '15%'
    }
  ]
  return filter
}
