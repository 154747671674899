import React from 'react'
import { TableSortHeader } from 'components/UiKit/Table'
import { Column } from 'react-table'
import { FormatCell } from 'components/UiKit/Table/components/FormatCell'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import SelectMember from './SelectMember/SelectMember'
import { Input } from '../../UiKit/Inputs'
import DeleteMember from './DeleteMember/DeleteMember'

export type TTeamData = {
  id: string
  fullName: string
  position: any
  typeOfEmployment: any
  rank: any
  city: string
  participationPeriod: string
  type: string
  workingHoursPerDay: number
  salary: number
  rateByInvoice: number
  invoicePosition: string
  invoiceLevel: string
  loadByInvoice: string
  loadForReal: string
  delete: any
  test: string
  projectsTeam: any[]
}

export const Columns = (
  formatCellClass: string,
  formatHeaderClass: string,
  positions: any,
  rank: any
) => {
  const currentLang = localStorage.getItem('TechnorelyLanguage')
  const words = useSelector((state: TState) => state.global.language.words)
  const getValue = (id: string, array: any) => {
    let result: string = ''
    if (!array) return ' '
    array.map((position: any) => {
      if (position.value === id) result = position.label
    })
    return result
  }
  const columns: Array<Column<TTeamData>> = [
    {
      Header: d => (
        <div className={formatHeaderClass}>
          <SelectMember member={d} name="selectAll" />
        </div>
      ),
      id: 'test',
      accessor: d => <SelectMember member={d} name={d.id} />,
      maxWidth: 40
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>{words['project.team.name']}</TableSortHeader>
      ),
      id: 'name',
      accessor: 'fullName',
      minWidth: 240
    },
    {
      Header: words['project.team.specialization'],
      id: 'specialization',
      accessor: d => words[d.position.name] || '',
      minWidth: 245
    },
    {
      Header: words['project.team.level'],
      id: 'rank',
      accessor: d => words[d.rank.name],
      minWidth: 115
    },
    {
      Header: words['project.team.location'],
      id: 'location',
      accessor: d => d.city.split(',')[0],
      minWidth: 120
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>{words['project.team.period']}</TableSortHeader>
      ),
      id: 'participationPeriod',
      accessor: d => d.projectsTeam[0].participationPeriod,
      minWidth: 165
    },
    {
      Header: words['project.team.type'],
      id: 'typeOfEmployment',
      accessor: d => words[d.typeOfEmployment.name],
      minWidth: 155
    },
    {
      Header: ({ column }) => (
        <div className={formatHeaderClass}>
          <TableSortHeader id={column.id}>{words['project.team.hoursPerDay']}</TableSortHeader>
        </div>
      ),
      id: 'workingHoursPerDay',
      Cell: (cellInfo: any) => {
        return (
          <FormatCell
            content={`${cellInfo.row.original.workingHoursPerDay} ${
              currentLang === 'ru' ? 'ч.' : 'h.'
            }`}
            customClass={formatCellClass}
          />
        )
      },
      minWidth: 205
    },
    {
      Header: ({ column }) => (
        <div className={formatHeaderClass}>
          <TableSortHeader id={column.id}>{words['project.team.paymentLevel']}</TableSortHeader>
        </div>
      ),
      id: 'salary',
      Cell: (cellInfo: any) => (
        <FormatCell content={`${cellInfo.row.original.salary} $`} customClass={formatCellClass} />
      ),
      minWidth: 195
    },
    {
      Header: () => (
        <div className={`${formatHeaderClass}  last_format_header`}>
          {words['admin.projects.currentTeam.realLoad']}
        </div>
      ),
      id: 'loadForReal',
      Cell: (cellInfo: any) => (
        <div style={{ width: '90px' }}>
          <Input
            placeholder="%"
            variant={'outlined'}
            name={'3'}
            value={`${cellInfo.row.original.projectsTeam[0].loadForReal}`}
          />
        </div>
      ),
      minWidth: 150
    },
    {
      Header: () => (
        <div className={`${formatHeaderClass}  last_format_header`}>
          {words['admin.projects.currentTeam.loadByInvoice']}
        </div>
      ),
      id: 'loadByInvoice',
      Cell: (cellInfo: any) => (
        <div style={{ width: '90px' }}>
          <Input
            placeholder="ч."
            variant={'outlined'}
            name={'2'}
            value={`${cellInfo.row.original.projectsTeam[0].loadByInvoice}`}
          />
        </div>
      ),
      minWidth: 150
    },
    {
      Header: () => (
        <div className={`${formatHeaderClass}  last_format_header`}>
          {words['admin.projects.currentTeam.rateByInvoice']}
        </div>
      ),
      id: 'rateByInvoice',
      Cell: (cellInfo: any) => (
        <div style={{ width: '90px' }}>
          <Input
            onChange={(e: any) => (cellInfo.row.original.rateByInvoice = e.target.value)}
            placeholder="$"
            variant={'outlined'}
            name={'1'}
            value={`${cellInfo.row.original.projectsTeam[0].rateByInvoice}`}
          />
        </div>
      ),
      minWidth: 150
    },
    {
      Header: words['project.team.positionOnInvoice'],
      id: 'invoicePosition',
      accessor: d => getValue(d.projectsTeam[0].position, positions) || '  ',
      minWidth: 260
    },
    {
      Header: words['project.team.levelOnInvoice'],
      id: 'invoiceLevel',
      accessor: d => getValue(d.projectsTeam[0].rank, rank) || '  ',
      minWidth: 150
    },
    {
      Header: '',
      id: 'delete',
      accessor: d => {
        return <DeleteMember id={d.id} />
      },
      maxWidth: 70
    }
  ]

  return columns
}
