import { getCancelledTasks } from './../../client/Subcontract/Subcontract.thunk'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'
import { Settings as SettingsComponent } from './Settings'
import {
  addToken,
  fetchTokens,
  changeAdminTokenStatusThunk,
  fetchAllTokensThunk
} from 'screens/admin/Token/Token.thunx'

export const mapStateToProps = (state: TState) => {
  return {
    tokens: state.admin.token.onlyAdminTokens,
    user: state.auth.data,
    allTokens: state.admin.token.allTokens,
    tasks: state.client.subcontract.tasks,
    loading: state.client.subcontract.loading,
    cancelledTasks: state.client.subcontract.cancelledTasks
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchTokens,
      addToken,
      changeAdminTokenStatusThunk,
      fetchAllTokensThunk,
      getCancelledTasks
    },
    dispatch
  )

export const Settings = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsComponent)
