import { IAction } from 'models'

import {
  SET_ORDER_IN_COLUMN_REQUEST,
  SET_ORDER_IN_COLUMN_SUCCESS,
  SET_ORDER_IN_COLUMN_ERROR,
  SET_PAGE_REQUEST,
  SET_PAGE_SUCCESS,
  SET_PAGE_ERROR,
  GET_HISTORY_TRANSACTIONS_SUCCESS,
  GET_HISTORY_TRANSACTIONS_ERROR,
  CLEAR_HISTORY_DATA,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_ERROR,
  CLEAR_TRANSACTIONS_DATA,
  GET_USER_PROFILE_INFO_REQUEST,
  GET_TUSER_PROFILE_INFO_ERROR,
  GET_USER_PROFILE_INFO_SUCCESS,
  GET_ALL_USERS_LIST,
  CHECK_USER_PROFILE_SUCCESS,
  CHECK_USER_PROFILE_REQUEST
} from './Profile.constants'
import { IInitialState } from './Profile.model'

export const initialState: IInitialState = {
  data: [],
  history: {
    results: [],
    transactions: [],
    payers: [],
    receivers: [],
    total: 1000,
    minPrice: 0,
    maxPrice: 0,
    minDate: new Date(),
    maxDate: new Date()
  },
  error: '',
  loading: false,
  user: {},
  usersList: [],
  profile: {
    status: '',
    loading: false
  },
  feedbacks: [
    {
      avatar:
        'https://points-system-dev.s3.amazonaws.com/photos/1684843418357_cropped_1613140022736-fintech.png',
      name: 'Pavel Yevtushenko',
      rating: 120,
      taskId: 1,
      review: 'positive',
      date: new Date(),
      feedbackText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat at pretium imperdiet mauris nullam lorem pellentesque ullamcorper facilisis. Sagittis, risus sit egestas tempus nunc arcu. Ut ornare lorem sit diam ut quis dui eget.'
    },
    {
      avatar:
        'https://points-system-dev.s3.amazonaws.com/photos/1684843418357_cropped_1613140022736-fintech.png',
      name: 'Pavel Yevtushenko1',
      rating: 150,
      taskId: 2,
      review: 'negative',
      date: new Date(),
      feedbackText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat at pretium imperdiet mauris nullam lorem pellentesque ullamcorper facilisis. Sagittis, risus sit egestas tempus nunc arcu. Ut ornare lorem sit diam ut quis dui eget.'
    },
    {
      avatar:
        'https://points-system-dev.s3.amazonaws.com/photos/1684843418357_cropped_1613140022736-fintech.png',
      name: 'Pavel Yevtushenko3',
      rating: 220,
      taskId: 3,
      review: 'positive',
      date: new Date(),
      feedbackText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat at pretium imperdiet mauris nullam lorem pellentesque ullamcorper facilisis. Sagittis, risus sit egestas tempus nunc arcu. Ut ornare lorem sit diam ut quis dui eget.'
    }
  ]
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_PAGE_REQUEST:
    case SET_ORDER_IN_COLUMN_REQUEST:
    // case GET_HISTORY_TRANSACTIONS_REQUEST:
    // case GET_TRANSACTIONS_REQUEST:
    case GET_USER_PROFILE_INFO_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case SET_ORDER_IN_COLUMN_ERROR:
    case SET_PAGE_ERROR:
    case GET_HISTORY_TRANSACTIONS_ERROR:
    case GET_TRANSACTIONS_ERROR:
    case GET_TUSER_PROFILE_INFO_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    case SET_ORDER_IN_COLUMN_SUCCESS:
      return {
        ...state,
        loading: false,
        // data: [...action.payload.data],
        limit: action.payload.total
      }
    case SET_PAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: [...action.payload]
      }
    }
    case GET_HISTORY_TRANSACTIONS_SUCCESS: {
      const { payload } = action.payload
      return {
        ...state,
        history: {
          ...state.history,
          results: [...state.history.results, ...payload.results],
          total: payload.total,
          minPrice: payload.minPrice,
          maxPrice: payload.maxPrice,
          minDate: payload.minDate,
          maxDate: payload.maxDate
        }
      }
    }
    case GET_TRANSACTIONS_SUCCESS: {
      const { payload } = action.payload
      return {
        ...state,
        history: {
          ...state.history,
          payers: payload.payers,
          receivers: payload.receivers,
          transactions: [...state.history.transactions, ...payload.results],
          total: payload.total,
          minPrice: payload.minAmount,
          maxPrice: payload.maxAmount,
          minDate: payload.minDate,
          maxDate: payload.maxDate
        }
      }
    }

    case CLEAR_TRANSACTIONS_DATA:
    case CLEAR_HISTORY_DATA: {
      return {
        ...initialState,
        user: state.user
      }
    }
    case GET_USER_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        user: action.payload
      }
    case GET_ALL_USERS_LIST:
      return {
        ...state,
        usersList: action.payload
      }
    case CHECK_USER_PROFILE_REQUEST:
      return {
        ...state,
        profile: {
          status: state.profile.status,
          loading: true
        }
      }
    case CHECK_USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          status: action.payload,
          loading: false
        }
      }
    default:
      return state
  }
}
