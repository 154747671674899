import React from 'react'
import Checkbox from '../../../../../components/UiKit/Checkbox/Checkbox'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteFromSelectedEntrepreneurInvoice,
  setSelectedAllEntrepreneurInvoices,
  setSelectedEntrepreneurInvoice
} from '../../Entrepreneurs.actions'
import { TState } from '../../../../../store'

const SelectInvoice = ({ name, invoice }: any) => {
  const dispatch = useDispatch()
  const selectedInvoices = useSelector(
    (state: TState) => state.admin.entrepreneurs.selectedInvoices
  )
  const allInvoices = useSelector((state: TState) => state.admin.entrepreneurs.invoices)
  const handleSelect = () => {
    const invoices = selectedInvoices.filter((invoicik: any) => invoice.id === invoicik.id)
    if (name === 'selectAll' && selectedInvoices.length === allInvoices.length) return true
    if (name === 'selectAll') return false
    return Boolean(invoices.length)
  }

  handleSelect()
  const handleCheck = (e: any) => {
    if (e.target.checked) {
      if (name === 'selectAll') {
        dispatch(setSelectedAllEntrepreneurInvoices(allInvoices))
        e.target.checked = true
      } else {
        dispatch(setSelectedEntrepreneurInvoice(invoice))
      }
    } else {
      dispatch(deleteFromSelectedEntrepreneurInvoice(invoice))
    }
  }

  return (
    <div>
      <Checkbox checked={handleSelect()} onChange={(e: any) => handleCheck(e)} name={name} />
    </div>
  )
}

export default SelectInvoice
