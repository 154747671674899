import { Dispatch } from 'redux'

import {
  getUserRatingRequest,
  getUserRatingSuccess,
  getUserRatingError
} from './UserRating.actions'
import { API, api } from 'services/api'
import { AxiosError } from 'axios'

export const getUsersRating: any = (page: number = 1, search: string = '') => async (
  dispatch: Dispatch
) => {
  dispatch(getUserRatingRequest())
  try {
    const { data } = await api.get(
      `${API.CLIENT}${search}${search ? '&' : '?'}limit=${10}&page=${page}&deleted=false`
    )
    dispatch(getUserRatingSuccess(data.payload))
  } catch (error) {
    const err = error as AxiosError<any>
    await dispatch(getUserRatingError(err))
  }
}
