/* eslint-disable no-console */
import { AxiosError } from 'axios'

const errorMessages: { [key: string]: string } = {
  'Not found': 'Ресурс не найден',
  'not found': 'Ресурс не найден',
  'Not enough hours on balance': 'Недостаточно баланса',
  "you can't assign request to this user":
    'Ответственный за задачи не может быть назначен в выбранный период времени',
  "invalid date, date musn't be before current date": 'Дата не должна быть меньше, чем сегодня',
  'You have no permission to handle this request': 'Недостаточно прав',
  'user not found': 'Ресурс не найден',
  'You are trying to change roles of deleted user':
    'Невозможно изменить роль удаленному пользвателю',
  'DOB must be greater than 1-01-1950': 'Дата рождения должна быть больше, чем 1.01.1950',
  'you are trying to delete yourself': 'Невозможно удалить самого себя',
  "invalid balance, current balance shoudn't be bigger than limit":
    'Баланс не должен превышать лимит',
  'admin not found!': 'Админ не найден',
  'you have exceeded the fund balance': 'Превышен доступный баланс',
  'city need to be set up city': 'Установите город в Вашем профиле',
  'not enough balance': 'Недостаточно баланса',
  'if day-off or self-paid request is more than 2 days it should be created month before':
    'Подача запросов на Day-off / Self-paid в количестве больше чем 2 дня, подается минимум за месяц до наступления запрашиваемых дат'
}

export const errorMessagesParsing = (error: AxiosError<any>) => {
  let res
  if (
    error.response &&
    error.response.data.message &&
    !Array.isArray(error.response.data.message)
  ) {
    res = Object.keys(errorMessages).find(
      (item: string) => error.response && error.response.data.message.match(item)
    )
    if (res) {
      return errorMessages[res]
    }
    return error.response && error.response.data.message
  } else if (error.response && error.response.data.errorMessage) {
    res = Object.keys(errorMessages).find(
      (item: string) => error.response && error.response.data.errorMessage.match(item)
    )
    if (res) {
      return errorMessages[res]
    }
    return error.response && error.response.data.errorMessage
  }
  return error
}

export const TOASTR_DEFAULT_MESSAGES = {
  SUCCESS: 'Успешно',
  SUCCESS_UPDATED: 'Успешно обновлено',
  SUCCESS_APPROVED: 'Успешно утверждено',
  SUCCESS_REJECTED: 'Успешно отклонено',
  SUCCESS_CREATED: 'Успешно создано',
  SUCCESS_DELETED: 'Успешно удалено',
  SUCCESS_RESTORED: 'Успешно востановлен',
  SUCCESS_TRANSFERRED: 'Успешно отправлено',
  SUCCESS_PURCHASED: 'Успешно куплено',
  SUCCESS_REQUESTED_ACHIEVEMENT: 'Заявка отправлена на рассмотрение',
  CREATED_ITEM: 'Товар создан',
  UPDATED_ITEM: 'Товар обновлен',
  CREATED_ACHIEVEMENT: 'Достижение создано',
  UPDATED_ACHIEVEMENT: 'Достижение обновлено',
  UPDATED_CITY: 'Город обновлен!',
  FILED_UPDATE_BALANCE: 'Не удалось обновить баланс',
  FILED_UPDATE_SETTINGS: 'Не удалось обновить настройки',
  FILED_TRANSFER_POINTS: 'Не удалось отправить поинты',
  FILED_TRANSFER_REVIEW: 'Не удалось отправить отзыв',
  REQUEST_NOT_FOUND: 'Запрос не найден',
  LINK_NOT_VALID: 'Ссылка недействительна',
  FILED_PROCESS_REQUEST: 'Не удалось обработать запрос',
  FILED_CREATE_OR_UPDATE_REQUEST: 'Не удалось создать (изменить) запрос',
  NOT_FOUND: 'Ресурс не найден',
  FILED_UPDATE_USER: 'Не удалось обновить пользователя',
  FILED_DELETE_USER: 'Не удалось удалить пользователя',
  FILED_DELETE_ACHIEVEMENT: 'Не удалось удалить достижение',
  FILED_RESTORE_USER: 'Не удалось восстановить пользователя',
  FILED_RESTORE_ACHIEVEMENT: 'Не удалось восстановить достижение',
  FILED_RESTORE_ITEM: 'Не удалось восстановить товар',
  FILED_UPDATE_TIME: 'Не удалось обновить время',
  FILED_UPDATE_CALENDAR: 'Не удалось обновить календарь',
  FILED_DELETE_ENTRY: 'Не удалось удалить запись',
  FILED_DELETE_ITEM: 'Не удалось удалить товар',
  FILED_DELETE_REVIEW: 'Не удалось удалить отзыв',
  REQUIREMENT_LOGIN: 'Перелогиньтесь пожалуйста!',
  NO_PERMISSIONS: 'No access rights',
  NOT_ENOUGH_BALANCE: 'Недостаточно баланса',
  NOT_ENOUGH_TCENTS: 'У вас недостаточно средств для покупки, проверьте ваш баланс, пожалуйста',
  FILED_REQUESTED_ACHIEVEMENT: 'Не удалось отправить заявку',
  FILED_CREATE_CATEGORY: 'Не удалось создать категорию',
  UNSUCCESSFUL_WORDS_LOADING: 'Unsuccessful words loading'
}
