import { formatterDateForDatePicker } from 'components/UiKit/Filter/Filter'
import moment from 'moment'

export const getInitialValues = (
  userInfo: any,
  selectedType: string,
  isAdmin: boolean,
  user?: any
): any => {
  const initialValues: any = {}
  // eslint-disable-next-line no-console

  if (userInfo && selectedType === 'main-info') {
    if (userInfo && userInfo.children) {
      userInfo.children = userInfo.children.map((item: any) => {
        return moment(item).valueOf()
      })
    }
    initialValues.amountOfChildren =
      userInfo.children && userInfo.children.length > 0 ? String(userInfo.children.length) : '0'
    initialValues.children = userInfo.children
    initialValues.city = userInfo.city
    initialValues.dateOfBirth = moment(userInfo.dateOfBirth).valueOf()
    initialValues.email = userInfo.email
    initialValues.fullName = userInfo.fullName
    initialValues.hobby = userInfo.hobby
    initialValues.hometown = userInfo.hometown
    initialValues.lifeGoals = userInfo.lifeGoals
    initialValues.phoneNumber = userInfo.phoneNumber
    initialValues.photo = userInfo.photo
    initialValues.originalPhoto = userInfo.originalPhoto
    initialValues.university = userInfo.university
    initialValues.socialNetworks = userInfo.socialNetworks
    initialValues.position = userInfo.position && userInfo.position.id
    initialValues.rank = userInfo.rank && userInfo.rank.id
    initialValues.academicDegree = userInfo.academicDegree && userInfo.academicDegree.id
    initialValues.lifePriorities = userInfo.lifePriorities
    initialValues.maritalStatus = userInfo.maritalStatus && userInfo.maritalStatus.id
    initialValues.hidden = userInfo.hidden
    initialValues.anonymous = userInfo.anonymous
    initialValues.bank = userInfo.bank && userInfo.bank.id
  }

  if (userInfo && selectedType === 'career') {
    initialValues.technologies = userInfo.technologies
    initialValues.careerGoal = userInfo.careerGoal
    initialValues.previousJob = userInfo.previousJob
    initialValues.englishLevel = userInfo.englishLevel && userInfo.englishLevel.id
    if (isAdmin) {
      initialValues.department = userInfo.department && userInfo.department.id
      initialValues.salary = userInfo.salary
      initialValues.typeOfEmployment = userInfo.typeOfEmployment && userInfo.typeOfEmployment.id
      initialValues.workingHoursPerDay = userInfo.workingHoursPerDay
      initialValues.firstWorkingDay = userInfo.firstWorkingDay && moment(userInfo.firstWorkingDay)
      initialValues.probationEndDate =
        userInfo.probationEndDate && moment(userInfo.probationEndDate)
      initialValues.salaryChangeDate =
        userInfo.salaryChangeDate && moment(userInfo.salaryChangeDate)
    }
    initialValues.projectsTeam = userInfo.projectsTeam
  }
  if (selectedType === 'time-off-balance') {
    initialValues.dayOffBalance = userInfo.dayOffBalance
      ? parseFloat(userInfo.dayOffBalance.toFixed(3))
      : 0
    initialValues.dayOffLimit = userInfo.dayOffLimit
    initialValues.totalRemote = userInfo.totalRemote
    initialValues.remoteLimit = userInfo.remoteLimit
    initialValues.totalSick = userInfo.totalSick
    initialValues.sickLimit = userInfo.sickLimit
    initialValues.isTerminating = user && user.firstTerminatingDay ? true : false
    initialValues.firstTerminatingDay =
      user && user.firstTerminatingDay
        ? formatterDateForDatePicker(moment(user.firstTerminatingDay).valueOf())
        : null
  }
  return initialValues
}
