export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_ERROR = 'GET_USERS_ERROR'

export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST'

export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS'

export const GET_INVOICES_ERROR = 'GET_INVOICES_ERROR'

export const DELETE_ENTREPRENEUR_INVOICE = 'DELETE_ENTREPRENEUR_INVOICE'
export const SET_SELECTED_ENTREPRENEUR_INVOICE = 'SET_SELECTED_ENTREPRENEUR_INVOICE'

export const SET_INVOICE = 'SET_INVOICE'
export const DELETE_FROM_SELECTED_ENTREPRENEUR_INVOICE = 'DELETE_FROM_SELECTED_ENTREPRENEUR_INVOICE'

export const SET_SELECTED_ALL_ENTREPRENEUR_INVOICES = 'SET_SELECTED_ALL_ENTREPRENEUR_INVOICES'
export const FILTER_INVOICES = 'FILTER_INVOICES'

export const GET_ENTREPRENEURS_INVOICES_REQUEST = 'GET_ENTREPRENEURS_INVOICES_REQUEST'
export const GET_ENTREPRENEURS_INVOICES_SUCCESS = 'GET_ENTREPRENEURS_INVOICES_SUCCESS'

export const SET_ENTREPRENEURS = 'SET_ENTREPRENEURS'

export const SET_DOWNLOAD_INVOICES = 'SET_DOWNLOAD_INVOICES'
