export const GET_VACANCIES_REQUEST = 'GET_VACANCIES_REQUEST'
export const GET_VACANCIES_SUCCESS = 'GET_VACANCIES_SUCCESS'
export const GET_VACANCIES_ERROR = 'GET_VACANCIES_ERROR'

export const GET_VACANCY_REQUEST = 'GET_VACANCY_REQUEST'
export const ACTION_VACANCIES_REQUEST = 'ACTION_VACANCIES_REQUEST'
export const ACTION_VACANCIES_SUCCESS = 'ACTION_VACANCIES_SUCCESS'
export const ACTION_VACANCIES_ERROR = 'ACTION_VACANCIES_ERROR'

export const CLEAR_VACANCIES_DATA = 'CLEAR_VACANCIES_DATA'
export const SET_PROJECT_OPTIONS = 'SET_PROJECT_OPTIONS'
export const SET_VACANCY_SUCCESS = 'SET_VACANCY_SUCCESS'

export const SET_STATUS_OPTIONS = 'SET_STATUS_OPTIONS'

export const UPDATE_VACANCY_SUCCESS = 'UPDATE_VACANCY_SUCCESS'
