import React, { FC, useMemo } from 'react'
import history from 'utils/history'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { Icons } from '../../../../../components/Icons'
import styles from './SubcontractHistoryTransaction.module.scss'
import { getFilterConfig } from './SubcontractHistoryTransaction.filter.config'
import { Filter } from '../../../../../components/UiKit/Filter'
import { Table } from '../../../../../components/UiKit/Table'
import { getColumns } from './SubcontractHistoryTransaction.columns'
import queryString from 'query-string'

const testData = [
  {
    type: 1,
    sum: '50.00',
    token: 'TRL',
    task: 'Сверстать страницу в html из макета figma',
    date: 1626901200000
  },
  {
    type: 2,
    sum: '50.00',
    token: 'USDT',
    task: 'Сверстать страницу в html из макета figma',
    date: 1626901200000
  }
]

export const SubcontractHistoryTransaction: FC<{}> = () => {
  const words = useSelector((state: TState) => state.global.language.words)
  const columns = getColumns(words)
  const columnsMemoized = useMemo(() => columns, [])
  const parsed = queryString.parse(history.location.search)

  return (
    <div className={styles['container']}>
      <section>
        <div className={styles.header}>
          <h1>{words['user.subcontract.bonuses.transactionHistory']}</h1>
        </div>
      </section>

      <section>
        <div className={styles['wrap-bar']}>
          <button
            className={styles['back-btn']}
            onClick={() => {
              history.push('/dashboard/profile')
            }}
          >
            <Icons icon={'arrowLeftWithStick'} />
            {words['back']}
          </button>
        </div>
      </section>

      <Filter
        config={getFilterConfig({
          fromDate: parsed.fromDate,
          toDate: parsed.toDate,
          words
        })}
        withButton={false}
        defaultOpened={true}
      />

      <div className={styles.table}>
        <Table
          columns={columnsMemoized}
          data={testData}
          noData={words['noData']}
          total={0}
          getData={() => ({})}
        />
      </div>
    </div>
  )
}
