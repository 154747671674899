import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import TaskPaymentComponent from './TaskPayment'
import { acceptProposalThunk, transferTokensToContract } from '../Web3/web3.thunk'
import { changeExecutor } from './TaskPayment.thunk'
import { getTask } from '../Subcontract/Subcontract.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    task: state.client.subcontract.task,
    currentUser: state.auth.data,
    proposalInfo: state.client.subcontract.proposal,
    loading: state.client.subcontract.loading,
    isTransaction: state.client.web3.isTransaction
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { acceptProposalThunk, changeExecutor, getTask, transferTokensToContract },
    dispatch
  )

export const TaskPayment = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskPaymentComponent)
