import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import CreateVacancyComponent from './CreateVacancy'
import { getVacancy, updateVacancy } from '../Vacancies.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    vacanciesArray: state.admin.vacancies.vacanciesList,
    positions: state.client.editProfile.positions,
    rank: state.client.editProfile.rank,
    englishLevel: state.client.editProfile.englishLevel,
    typeOfEmployment: state.client.editProfile.typeOfEmployment,
    projectOptions: state.admin.vacancies.projectOptions,
    managersData: state.client.newRequestTimeOff.managers,
    vacancy: state.admin.vacancies.vacancy,
    test: '123'
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getVacancy,
      updateVacancy
    },
    dispatch
  )

export const CreateVacancy = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVacancyComponent)
