/* eslint-disable no-console */
import { Dispatch } from 'redux'
import {
  pollingSuccess,
  setUserAvatar,
  signInTokenSuccess
  // pollingError
} from './Login.actions'
import { API, api } from 'services/api'
import { PERIOD_OF_POLLING } from 'globalConfigs'
import toastr from 'utils/toastr'

let timer: any
export const getTimer = () => timer

export const pulling: any = () => async (dispatch: Dispatch) => {
  clearTimeout(timer)
  try {
    const { data } = await api.get(`${API.CLIENT}${API.POLLING}`)
    dispatch(pollingSuccess(data.payload))

    timer = setTimeout(() => {
      pulling()(dispatch)
    }, PERIOD_OF_POLLING)
  } catch (err) {
    // dispatch(pollingError(err))
    console.error(err)
  }
}

export const getUserPhoto = (id: number) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const { data } = await api.get(`${API.USER_PHOTO}/${id}`)
    dispatch(setUserAvatar(data.payload))
  } catch (error) {
    console.error(error)
  }
}

export const login = (role: number) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const { data } = await api.post(API.LOGIN, { role })
    dispatch(signInTokenSuccess(data.payload.result))
  } catch (err) {
    const errorResponse = (err as any).response
    if (!errorResponse) {
      console.log(err)
    } else {
      toastr('error', errorResponse.data.message)
    }
  }
}

export const signup = (body: Record<string, any>) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const { data } = await api.post(API.SIGNUP, body)
    dispatch(signInTokenSuccess(data.payload.result))
  } catch (err) {
    const errorResponse = (err as any).response
    if (!errorResponse) {
      console.log(err)
    } else {
      toastr('error', errorResponse.data.message)
    }
  }
}
