import React, { useEffect } from 'react'
import styles from './Vacancy.module.scss'
import { ReactComponent as ArrowLeftWithStick } from 'assets/images/arrow_left_with_stick.svg'
import { useHistory, useParams } from 'react-router'
import vacancyImage from 'assets/images/vacancies_image.svg'
import { Button } from '../../../../components/UiKit/Button'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { TState } from '../../../../store'
import Loading from '../../../../components/Spinner'
import SelectVacancyStatus from '../SelectVacancyStatus/SelectVacancyStatus'
import { EditVacancyBtn } from '../EditVacancyBtn/EditVacancyBtn'

const Vacancy = ({ loading, getVacancy, vacancyItem, statusOptions }: any) => {
  const history = useHistory()
  const words = useSelector((state: TState) => state.global.language.words)
  const { id }: any = useParams()

  useEffect(() => {
    getVacancy(id)
  }, [])
  useEffect(() => {
    debugger
    getVacancy(id)
  }, [loading])
  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>Страница вакансии</h1>
      </section>
      <div className={styles.buttons}>
        <div className={styles.backButton} onClick={() => history.goBack()}>
          <ArrowLeftWithStick />
          <span>Назад</span>
        </div>
        <EditVacancyBtn deleted={null} />
      </div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '545px'
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <div className={styles.mainInfo}>
            <div className={styles.vacancyInfo}>
              <div className={styles.vacancyTitle}>
                <h2>{words[vacancyItem.title.name]}</h2>
                <SelectVacancyStatus
                  fromPage={true}
                  vacancy={vacancyItem}
                  defaultOption={vacancyItem.status}
                  options={statusOptions}
                />
              </div>
              <div className={styles['vacancy__info-wrap']}>
                <ul className={styles['vacancy__info-list']}>
                  <li>
                    <span>{words['user.profile.career.level']}:</span>{' '}
                    {words[vacancyItem.rank.name]}
                  </li>
                  <li>
                    <span>{words['user.profile.career.occupation']}:</span>
                    {words[vacancyItem.typeOfEmployment.name]}
                  </li>
                  <li>
                    <span>{words['admin.vacancies.dateOfClosing']}:</span>
                    {moment(vacancyItem.dateOfClosing).format('L')}
                  </li>
                </ul>
                <ul className={styles['vacancy__info-list']}>
                  <li>
                    <span>{words['admin.vacancies.project']}:</span> {vacancyItem.project.name}
                  </li>
                  <li>
                    <span>{words['user.profile.mainInfo.location']}:</span> {vacancyItem.location}
                  </li>
                  <li>
                    <span>{words['admin.vacancies.responsibleRecruiter']}:</span>
                    {vacancyItem.recruiter}
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <img src={vacancyImage} alt="vacancy" />
            </div>
          </div>
          <div className={styles.mainInfo}>
            <div style={{ width: '100%' }}>
              <div className={styles.mainInfoTop}>
                <h2>Требования</h2>
                <Button color="green" size={'lgs'}>
                  Список пулов
                </Button>
              </div>
              <div className={styles.vacancy__wrapper}>
                <ul className={styles['vacancy__list-wrapper']}>
                  <li className={styles['vacancy__info-item']}>
                    <span>{words['admin.vacancies.participationPeriod']}:</span>{' '}
                    {vacancyItem.participationPeriod}
                  </li>
                  <li className={styles['vacancy__info-item']}>
                    <span>{words['admin.vacancies.countOfHoursPerDay']}:</span>{' '}
                    {vacancyItem.countOfHours}
                  </li>
                  <li className={styles['vacancy__info-item']}>
                    <span>{words['user.profile.career.englishLevel']}:</span>{' '}
                    {words[vacancyItem.englishLevel.name]}
                  </li>
                  <li className={styles['vacancy__info-item']}>
                    <span>{words['admin.vacancies.interviewers']}:</span> {vacancyItem.interviewer}
                  </li>
                  <li className={styles['vacancy__info-item']}>
                    <span>{words['admin.vacancies.testTaskFromClient']}: </span>{' '}
                    {vacancyItem.isTestExist ? 'Да' : 'Нет'}
                  </li>
                </ul>
                <ul className={styles['vacancy__list-wrapper']}>
                  <li className={styles['vacancy__info-item']}>
                    <span>{words['admin.vacancies.techStackJob']}:</span> <br />
                    {vacancyItem.technologies}
                  </li>
                  <li className={styles['vacancy__info-item']}>
                    <span>{words['admin.vacancies.additionalRequierements']}:</span> <br />
                    {vacancyItem.additionalRequirements}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Vacancy
