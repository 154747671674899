import React, { ChangeEvent, FC, useEffect, useMemo, useState, MouseEvent } from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import cls from 'classnames'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import styles from './styles.module.scss'
import { Icons } from '../Icons/'
import { IToken } from 'screens/admin/Token/Token.model'
import { convertTokenWord } from 'helpers/convertTokenWord'
import Button from 'components/Button'
import Spinner from 'components/Spinner'
import { fetchOneToken } from 'screens/admin/Token/Token.thunx'
import { TUserData } from 'screens/admin/Users/Users.model'
import { ETokenStatus } from 'screens/admin/Token/Token.constans'
import { AlertNotification } from 'components/AlertNotification'
import toastr from 'utils/toastr'
import { TokenChoseModal } from './TokenChoseModal'

type TModalProps = {
  toggle?: boolean
  action: () => void
  size?: 'default' | 'md' | 'small'
  tokens: {
    adminActivatedTokens: IToken[]
    adminDeactivatedTokens: IToken[]
    userActivatedTokens: IToken[]
    userDeactivatedTokens: IToken[]
  }
  user: TUserData
  tokensLoading?: boolean
  addToken: (token: IToken) => void
  fetchOneToken: (value: string) => IToken
  changeUserTokenStatusThunk: (userId: number, status: ETokenStatus, token: IToken) => Promise<void>
  getSelectedToken: (value: IToken) => void
  title?: string
  button?: string
}

const TokenChangeModal: FC<TModalProps> = ({
  toggle,
  size = 'small',
  tokens,
  user,
  action,
  changeUserTokenStatusThunk,
  addToken,
  getSelectedToken,
  title = 'Выберите токен',
  button = 'Управлять токенами'
}) => {
  const [openTokenEditModal, setOpenTokenEditModal] = useState<boolean>(false)
  const [valueSearchToken, setValueSearchToken] = useState('')
  const [currentUserTokens, setCurrentUserTokens] = useState<IToken[]>([])
  const [currentToken, setCurrentToken] = useState<IToken | null>(null)
  const [isLoadingToken, setIsLoadingToken] = useState(false)

  const words = useSelector((state: TState) => state.global.language.words)
  const {
    userActivatedTokens,
    userDeactivatedTokens,
    adminActivatedTokens,
    adminDeactivatedTokens
  } = tokens

  useEffect(() => {
    setCurrentUserTokens([...userActivatedTokens, ...userDeactivatedTokens])
  }, [userActivatedTokens, userDeactivatedTokens])

  const handleOpenEditModal = (): void => {
    if (button === 'Управлять токенами') {
      setOpenTokenEditModal(true)
    }
  }

  const handleChangeTokenStatus = (status: ETokenStatus, token: IToken): void => {
    try {
      changeUserTokenStatusThunk(user.id, status, token)
    } catch (err) {
      console.log(err)
    }
  }

  const isActiveUserToken = useMemo(() => {
    const activeUserTokens = userActivatedTokens.map(token => token.id)

    return (tokenId: number): boolean => {
      return activeUserTokens.includes(tokenId)
    }
  }, [userActivatedTokens])

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setValueSearchToken(event.target.value)
  }

  const handleKeyPress = async (event: KeyboardEvent): Promise<void> => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (!valueSearchToken.trim()) return

      setIsLoadingToken(true)
      setCurrentToken(null)

      try {
        const token = await fetchOneToken(valueSearchToken)
        const findedUserToken = currentUserTokens.find(item => item.address === token.address)
        const findedAdminToken = [...adminActivatedTokens, ...adminDeactivatedTokens].find(
          item => item.address === token.address
        )

        if (findedUserToken) {
          toastr('error', 'Такой токен уже есть!')
        } else if (findedAdminToken && !findedAdminToken.isActive) {
          toastr('error', `Токен ${findedAdminToken.name} деактивирован администратором`)
        } else {
          setCurrentToken(token)
        }

        setIsLoadingToken(false)
      } catch (error) {
        toastr('error', 'Произошла ошибка при поиске токена.')
      }
    }
  }

  const handleAddToken = (event: object): void => {
    const mouseEvent = event as MouseEvent
    mouseEvent.preventDefault()
    setIsLoadingToken(true)

    try {
      if (currentToken) {
        addToken({ ...currentToken, userId: user.id })
        setCurrentToken(null)
        toastr('success', `Токен ${currentToken.name} успешно импортирован!`)
      }
    } catch (err) {
      toastr('error', 'Произошла ошибка при импортировании токена.')
    } finally {
      setIsLoadingToken(false)
    }
  }

  useEffect(() => {
    if (!valueSearchToken) {
      setCurrentToken(null)
    }
  }, [valueSearchToken])

  return (
    <div>
      <div onClick={action} className={cls({ [styles.overlay]: toggle })} />
      <div className={cls({ [styles.container]: true, [styles.active]: toggle })}>
        <div className={cls({ [styles.modal]: true, [styles[`modal-size-${size}`]]: true })}>
          {!openTokenEditModal ? (
            <TokenChoseModal
              handleKeyPress={handleKeyPress}
              tokens={tokens}
              handleOpenEditModal={handleOpenEditModal}
              action={action}
              getSelectedToken={getSelectedToken}
              title={title}
              button={button}
            />
          ) : (
            <div className={styles.modalWrapper}>
              <div>
                <div className={styles.modalTitle}>Управление токенами</div>

                <div className={styles.closeModalButton} onClick={action}>
                  <Icons icon="close" />
                </div>

                <div className={styles.returnButton} onClick={() => setOpenTokenEditModal(false)}>
                  <Icons icon="backArrowIcon" />
                </div>
                <div
                  className={cls({
                    [styles.form]: true,
                    [styles['cancel-task-form']]: true
                  })}
                >
                  <div className={styles.message}>
                    <FieldFactory
                      config={[
                        {
                          items: [
                            {
                              name: `tokenSearch`,
                              label: '',
                              required: false,
                              inputWrapperClassName: styles['currency'],
                              component: () => {
                                return {
                                  type: 'input',
                                  props: {
                                    placeholder: 'Search name or paste address',
                                    options: '',
                                    variant: 'outlined',
                                    value: valueSearchToken,
                                    onKeyPress: handleKeyPress,
                                    onChange: handleSearchInput
                                  }
                                }
                              }
                            }
                          ]
                        }
                      ]}
                      words={words}
                    />
                  </div>
                </div>
                <div className={styles.tokenManageList}>
                  {isLoadingToken && (
                    <div className={styles.spinnerWrapper}>
                      <Spinner size="button" />
                    </div>
                  )}
                  {currentToken && (
                    <>
                      <div className={styles.tokenItemContent}>
                        <div className={styles.tokenItem}>
                          <img
                            src={currentToken.logoURI}
                            alt="Token image"
                            className={styles.tokenImage}
                          />

                          <div className={styles.tokenItemWrapper}>
                            <div className={styles.tokenItemTitle}>{currentToken.symbol}</div>
                            <div className={styles.tokenItemDescription}>{currentToken.name}</div>
                          </div>
                        </div>

                        <Button
                          className={'btn-import'}
                          children={'Импорт'}
                          onClick={handleAddToken}
                        />
                      </div>
                    </>
                  )}
                  {!currentToken &&
                    !isLoadingToken &&
                    currentUserTokens.map(token => (
                      <div key={token.address} className={styles.tokenItemContent}>
                        <div className={styles.tokenItem}>
                          <img
                            src={token.logoURI}
                            alt="Token image"
                            className={cls({
                              [styles.tokenImage]: true,
                              [styles['gray-img']]: !isActiveUserToken(token.id)
                            })}
                          />

                          <div
                            className={cls({
                              [styles.tokenItemWrapper]: true,
                              [styles['is-activated']]: !isActiveUserToken(token.id)
                            })}
                          >
                            <div className={styles.tokenItemTitle}>{token.symbol}</div>
                            <div className={styles.tokenItemDescription}>{token.name}</div>
                          </div>
                        </div>

                        {isActiveUserToken(token.id) ? (
                          <div
                            className={styles.deactivatedButton}
                            onClick={() => handleChangeTokenStatus(ETokenStatus.DEACTIVATE, token)}
                          >
                            <Icons icon="redBucket" />
                          </div>
                        ) : (
                          <div
                            className={styles.activatedButton}
                            onClick={() => handleChangeTokenStatus(ETokenStatus.ACTIVATE, token)}
                          >
                            <Icons icon="restoreIcon" />
                          </div>
                        )}
                      </div>
                    ))}
                  {currentToken && (
                    <div className={styles.alertWrapper}>
                      <AlertNotification />
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.totalTokens}>{convertTokenWord(currentUserTokens.length)}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TokenChangeModal
