import { Icons } from 'components/Icons'
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import styles from './ControlPanel.module.scss'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { Form } from 'react-final-form'
import { ToggleButton } from 'components/UiKit/Button/ToggleButton'
import { ToggleBar } from 'components/ToggleBar'
import {
  ECryptoBotHistory,
  getToggleBarOptionsForCrypto,
  orderPriceModeSelectOptions
} from 'globalConfigs'
import { getCryptoBotType, setDefaultMainToggleBarStateForCryptoBot } from 'utils/toggleBarsValue'
import queryString from 'query-string'
import CryptoBotModal from 'components/CryptoBotModal/CryptoBotModal'
import { api, API } from 'services/api'
import {
  EBotStatus,
  EOrderPrice,
  IDefaultSettings,
  IExchangeSettings,
  TControlPanelProps,
  TStrategyType
} from './ControlPanel.types'
import CustomInputField from '../../../../../components/UiKit/CustomInput/CustomInputField'
import toastr from 'utils/toastr'
import { FormApi } from 'final-form'
import { GeneralInfo } from '../GeneralInfo'
import { PositionsInfo } from '../PositionsInfo'
import { ProfitGraph } from '../ProfitGraph'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { fetchSettingsFromCryptoBot } from 'utils/fetchSettingsFromCryptoBot'
import { validateControlPanel } from './libs/validation/validation'
import { headers } from './libs/constants/constants'
import { isNotEqualToOrder } from './libs/helpers/isNotEqualToOrder.helper'
import { getDateRange } from './libs/helpers/getDateRange.helper'

const ControlPanel: FC<TControlPanelProps> = ({
  history,
  user,
  getBotSettings,
  botSettings,
  resetSettings,
  getBotInfo,
  updateBotInfo,
  botInfo,
  saveSettings
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  const [isAdditionalSettings, setIsAdditionalSettings] = useState(false)
  const [modalState, setModalState] = useState(false)
  const [currentBotSettings, setCurrentBotSettings] = useState<any>(null)
  const [selectedType, setSelectedType] = useState(
    setDefaultMainToggleBarStateForCryptoBot(params.type, words)
  )
  const [botStatus, setBotStatus] = useState('')
  const [isBotStarted, setIsBotStarted] = useState(false)
  const [isResetSettings, setIsResetSettings] = useState(false)
  const [defaultSettings, setDefaultSettings] = useState<IDefaultSettings>()
  const [defaultStrategyType, setDefaultStrategyType] = useState('')
  const { botId, exchangeType, strategyType } = botInfo

  useEffect(() => {
    if (!botId) {
      getBotInfo()
    }
    handleDefaultSettings()
  }, [])

  useEffect(() => {
    if (botId && exchangeType) {
      isBotRunning()
      isBotStartedUp()
      getBotSettings(botId, exchangeType, strategyType)
    }
  }, [botId, exchangeType, strategyType])

  const handleDefaultSettings = async () => {
    const data = await fetchSettingsFromCryptoBot('/bot/default-settings')
    setDefaultSettings(data)
    setDefaultStrategyType(Object.keys(data)[0])
  }

  const isBotRunning = async () => {
    try {
      const { status } = await fetchSettingsFromCryptoBot(`/bot/${botId}/trading/status`)

      setBotStatus(status)
    } catch (error) {
      console.log(error)
    }
  }

  function handleOpenResetModal() {
    setIsResetSettings(!isResetSettings)
    openModal()
  }

  function openModal() {
    setModalState(!modalState)
  }

  const isBotStartedUp = useCallback(async () => {
    try {
      const result = await fetchSettingsFromCryptoBot(`/bot/${botId}/exchanges-settings`)

      setIsBotStarted(result.length > 0)
    } catch (error) {
      console.log(error)
    }
  }, [isBotStarted, botId])

  async function startBot() {
    try {
      const { data } = await api.get(`${API.URL}/bot/${botId}/trading/start`, { headers })

      if (data.status === EBotStatus.RUNNING) {
        setIsBotStarted(true)
        toastr('success', words['crypto-bot.botStarted'])
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function handleStartBot(settings: IExchangeSettings) {
    setCurrentBotSettings(settings)

    try {
      const exchangeSettingsFromBot = await fetchSettingsFromCryptoBot(
        `/bot/${botId}/exchanges-settings`
      )

      if (!exchangeSettingsFromBot.length) {
        openModal()
        setBotStatus(EBotStatus.RUNNING)
        return
      }

      await startBot()
      setBotStatus(EBotStatus.RUNNING)
    } catch (error) {
      console.log(error)
    }
  }

  async function handleStopBot() {
    try {
      const { data } = await api.get(`${API.URL}/bot/${botId}/trading/stop`, { headers })

      if (data.status === EBotStatus.STOPPED) {
        toastr('success', words['crypto-bot.botStopped'])
        setBotStatus(EBotStatus.STOPPED)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function handleReset() {
    if (exchangeType && botId && strategyType) {
      resetSettings({ user, exchange: exchangeType, botId, strategyType })
      openModal()
    }
  }

  async function handleSaveSettings() {
    if (botSettings) {
      saveSettings({ exchanges: botSettings.exchanges, listener: setIsBotStarted })
      setIsBotStarted(true)
      startBot()
      openModal()
    }
  }

  async function onSubmit(
    values: IExchangeSettings,
    form: FormApi<IExchangeSettings, IExchangeSettings>
  ) {
    try {
      saveSettings({ exchanges: values, listener: setIsBotStarted })
      form.initialize(values)
    } catch (error) {
      console.log(error)
    }
  }

  function handleResetFormSettings(form: FormApi<IExchangeSettings, Partial<IExchangeSettings>>) {
    form.reset()
  }

  const handleChangeStrategy = (event: ChangeEvent<HTMLSelectElement>) => {
    updateBotInfo({ selectedStrategy: event.target.value as TStrategyType })
  }

  const { from, to } = getDateRange()

  return (
    <div className={styles.panel}>
      <div className={styles.methods}>
        {exchangeType && (
          <div className={styles.shadow}>
            <Icons icon={exchangeType} />
          </div>
        )}
      </div>

      <div className={styles.control}>
        {params.stats === 'false' ? (
          <>
            <div className={styles['control-head']}>
              <h2>{words['crypto-bot.controlPanel']}</h2>
            </div>

            {botSettings && (
              <Form
                initialValues={botSettings.exchanges}
                onSubmit={onSubmit}
                validate={validateControlPanel}
              >
                {({ handleSubmit, form, pristine }) => {
                  const currentValues = form.getState().values
                  return (
                    <form onSubmit={handleSubmit} name="form" className={styles.form}>
                      <section className={styles['control-section']}>
                        <div className={styles.actionBtnsWrapper}>
                          <BaseButton
                            outline={true}
                            children={words['crypto-bot.statistics']}
                            disabled={!isBotStarted}
                            size={'smb'}
                            onClick={() =>
                              history.push(
                                `${history.location.pathname}?${queryString.stringify({
                                  ...params,
                                  type: params.type,
                                  stats: 'true',
                                  startDate: from,
                                  endDate: to
                                })}`
                              )
                            }
                          />

                          <BaseButton
                            outline={true}
                            children={words['crypto-bot.resetSettings']}
                            disabled={!isBotStarted}
                            size={'smb'}
                            onClick={handleOpenResetModal}
                          />

                          {(botStatus === EBotStatus.STOPPED ||
                            botStatus === EBotStatus.NEVER_STARTED) && (
                            <BaseButton
                              children={words['crypto-bot.start']}
                              size={'smb'}
                              onClick={() => handleStartBot(currentBotSettings)}
                              disabled={!pristine}
                            />
                          )}

                          {botStatus === EBotStatus.RUNNING && (
                            <BaseButton
                              children={words['crypto-bot.stop']}
                              size={'smb'}
                              onClick={handleStopBot}
                            />
                          )}
                        </div>
                      </section>
                      <div className={styles['control-section-wrapper']}>
                        <section className={styles['control-section']}>
                          <p>Strategy</p>

                          <select
                            className={styles.select}
                            onChange={handleChangeStrategy}
                            value={strategyType ? strategyType : defaultStrategyType}
                          >
                            {defaultSettings &&
                              Object.keys(defaultSettings).map(strategy => (
                                <option key={strategy} value={strategy}>
                                  {strategy}
                                </option>
                              ))}
                          </select>
                        </section>
                        <section className={styles['control-section']}>
                          <p>{words['crypto-bot.bet']}</p>

                          <CustomInputField
                            type="select"
                            options={orderPriceModeSelectOptions(words)}
                            name="orderPriceMode"
                          />

                          <div className={styles.inputWrapper}>
                            {currentValues.orderPriceMode === EOrderPrice.FIXED && (
                              <CustomInputField
                                type="number"
                                name="orderPrice"
                                label={`${words['crypto-bot.betSize']}` + ' USDT'}
                                placeholder={'USDT'}
                              />
                            )}

                            {currentValues.orderPriceMode === EOrderPrice.ALLOCATION && (
                              <CustomInputField
                                type="number"
                                name="allocationPercent"
                                label={`${words['crypto-bot.betSize']}` + ' %'}
                                placeholder={'%'}
                              />
                            )}
                          </div>
                        </section>
                        {!isNotEqualToOrder(currentValues) && (
                          <section className={styles['control-section']}>
                            <div className={styles['profit-head']}>
                              <p>{words['crypto-bot.profit']}</p>
                            </div>

                            <div className={styles.toggleWrapper}>
                              <ToggleButton
                                label={words['crypto-bot.withdrawProfitToSpot']}
                                name="transferToSpot"
                              />
                            </div>

                            <div className={styles.inputWrapper}>
                              <CustomInputField
                                type="number"
                                name="takeProfit"
                                label={words['crypto-bot.percentageOfProfits']}
                                disabled={!currentValues.transferToSpot}
                                placeholder="%"
                              />
                            </div>
                          </section>
                        )}
                        <section className={styles['control-section']}>
                          <p>{words['crypto-bot.positions']}</p>

                          <div className={styles.toggleWrapper}>
                            <ToggleButton
                              label={words['crypto-bot.positionLimit']}
                              name="enableMaxNumPositions"
                            />
                          </div>

                          <div className={styles.inputWrapper}>
                            <CustomInputField
                              type="number"
                              name="maxNumPositions"
                              label={words['crypto-bot.positionLimit']}
                              disabled={!currentValues.enableMaxNumPositions}
                            />
                          </div>
                        </section>
                      </div>
                      <button
                        className={styles.additionalSettingsBtn}
                        onClick={() => setIsAdditionalSettings(!isAdditionalSettings)}
                        disabled={strategyType !== 'custom'}
                        type="button"
                      >
                        {words['crypto-bot.additionalSettings']}
                        <Icons icon="filterClosed" />
                      </button>

                      {isAdditionalSettings && strategyType === 'custom' && (
                        <div>
                          <div className={styles.channelsSettingsWrapper}>
                            <BaseButton
                              outline={true}
                              children={words['crypto-bot.channelSettings']}
                              size={'smb'}
                              disabled={!pristine}
                              onClick={() =>
                                history.push(
                                  `/dashboard/channels-settings?exchangeType=${exchangeType}`
                                )
                              }
                            />
                          </div>

                          <div className={styles.additionalInputsWrapper}>
                            <div className={styles.additionalSettingsWrapperField}>
                              <ToggleButton
                                label={words['crypto-bot.activate']}
                                name="enablePercentageForStability"
                              />

                              <CustomInputField
                                type="number"
                                name="percentageForStability"
                                label={words['crypto-bot.stabilityPercentage']}
                                placeholder="%"
                                disabled={!currentValues.enablePercentageForStability}
                              />
                            </div>

                            <div className={styles.additionalSettingsWrapperField}>
                              <ToggleButton
                                label={words['crypto-bot.activate']}
                                name="enableFundingRateLimit"
                              />

                              <CustomInputField
                                type="number"
                                name="fundingRateLimit"
                                label={words['crypto-bot.betLockLimit']}
                                placeholder="%"
                                disabled={!currentValues.enableFundingRateLimit}
                              />
                            </div>

                            <div className={styles.additionalSettingsWrapperField}>
                              <ToggleButton
                                label={words['crypto-bot.activate']}
                                name="enableLimitTokenAlive"
                              />

                              <CustomInputField
                                type="number"
                                name="limitTokenAlive"
                                label={words['crypto-bot.tokenAgeLimit']}
                                placeholder={words['crypto-bot.enterTheNumber']}
                                disabled={!currentValues.enableLimitTokenAlive}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className={styles.buttonWrapper}>
                        <BaseButton
                          outline={true}
                          color={'default'}
                          className={'requesth'}
                          children={words['crypto-bot.modifyAPIKeys']}
                          onClick={() => {
                            history.push(
                              'cryptoBot?apiSetted=false&exchangeType=bybit&typeStep=api'
                            )
                          }}
                        />

                        <BaseButton
                          outline={false}
                          color={'default'}
                          className={'requesth'}
                          disabled={pristine}
                          onClick={() => handleResetFormSettings(form)}
                          children={words['crypto-bot.cancelSettings']}
                        />

                        <BaseButton
                          outline={false}
                          color={'default'}
                          className={'requesth'}
                          type="submit"
                          disabled={pristine}
                          children={words['crypto-bot.save']}
                        />
                      </div>
                    </form>
                  )
                }}
              </Form>
            )}
          </>
        ) : (
          <div>
            <ToggleBar
              name="crypto"
              data={getToggleBarOptionsForCrypto(words)}
              defaultChecked={selectedType}
              onChange={(event: any) => {
                history.push(
                  `${history.location.pathname}?${queryString.stringify({
                    ...params,
                    type: getCryptoBotType(event.value)
                  })}`
                )
                setSelectedType(event)
              }}
            />

            {ECryptoBotHistory.GENERAL === params.type && <GeneralInfo />}

            {ECryptoBotHistory.CHART === params.type && <ProfitGraph />}

            {ECryptoBotHistory.POSITIONS === params.type && <PositionsInfo />}
          </div>
        )}
      </div>

      <CryptoBotModal
        toggle={modalState}
        action={openModal}
        handleAction={isResetSettings ? handleReset : handleSaveSettings}
        isResetSettings={isResetSettings}
      />
    </div>
  )
}

export default ControlPanel
