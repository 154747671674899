import React, { useEffect, useMemo, useRef, useState, FC } from 'react'
import styles from './Invoices.module.scss'
import { Filter } from '../../UiKit/Filter'
import { getFilterConfig } from './Invoices.filter.config'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from '../../../store'
import { Columns } from './Invoices.columns'
import history from 'utils/history'
import queryString from 'query-string'
import { useParams } from 'react-router'
import { getOneProjectInvoice, getProjectInvoices } from './Invoices.thunk'
import InvoiceModal from '../../ProjectModals/InvoiceModal'
import Spinner from '../../Spinner'
import { generateZip } from '../helpers/getZip'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { Table } from '../../UiKit/Table'

export const Invoices: FC<any> = ({
  invoice,
  projectInvoices,
  loading,
  selectedProjectInvoices
}) => {
  const [isInvoiceOpened, setInvoiceOpened] = useState<boolean>()
  const downloadListRef = useRef<any>([])
  const words = useSelector((state: TState) => state.global.language.words)
  const parsed = queryString.parse(history.location.search)
  const dispatch = useDispatch()
  const params: any = useParams()
  const columns = Columns()
  const columnsMemoized = useMemo(() => columns, [])

  useEffect(() => {
    dispatch(getProjectInvoices(location.search, params.id))
  }, [])

  const handleRowClick = (currentInvoice: any) => {
    setInvoiceOpened(true)
    dispatch(getOneProjectInvoice(currentInvoice.id))
  }

  const handleDownloadClick = () => {
    generateZip(downloadListRef, selectedProjectInvoices)
  }
  const updateToDownload = useMemo(() => {
    return selectedProjectInvoices.map((projectInvoice: any, i: number) => (
      <InvoiceModal
        invoice={projectInvoice}
        key={projectInvoice.id}
        isHidden={true}
        ref={(el: React.RefCallback<HTMLDivElement>) => (downloadListRef.current[i] = el)}
        toggle={true}
      />
    ))
  }, [selectedProjectInvoices])

  return (
    <div>
      <div className={styles['project-invoices']}>
        <BaseButton onClick={handleDownloadClick} className={styles['invoices-download']} size="sm">
          {words['projects.invoices.downloadSelected']}
        </BaseButton>
        <Filter
          config={getFilterConfig({
            words,
            fromDate: parsed.fromDate,
            toDate: parsed.toDate
          })}
          defaultOpened={true}
        />
      </div>
      {isInvoiceOpened && (
        <InvoiceModal
          action={() => setInvoiceOpened(false)}
          toggle={isInvoiceOpened}
          invoice={invoice}
        />
      )}
      {loading ? (
        <Spinner />
      ) : (
        <div className={styles['project-invoices-table']}>
          <Table
            total={10}
            data={projectInvoices}
            noData={'no data'}
            getData={() => ({})}
            columns={columnsMemoized}
            hiddenColumns={[]}
            onRowClick={d => handleRowClick(d.original)}
          />
          <button className={styles['project-invoices-xlxs']}>.xlxs</button>
        </div>
      )}
      {updateToDownload}
    </div>
  )
}
