import { Icons } from 'components/Icons'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { EPolygonStatus } from 'globalConfigs'
import React, { FC } from 'react'
import { Form } from 'react-final-form'
import styles from './../Polygon.module.scss'

const PolygonForm: FC<any> = ({
  sendVerificationData,
  verifyPhoto,
  id,
  user,
  passport,
  setVerifyPhoto,
  deletedFilesId,
  setDeletedFilesId,
  loadings
}) => {
  const onSelectFile = (e: any, choosen: any, setChoosen: any, side: string) => {
    if (e.target.files[0].size > 10485760) {
      alert('File is too big!')
      return
    }
    const f = e.target.files[0]
    if (f) {
      const newFiles = [...choosen[side]]
      const blob = new Blob([f], { type: f.type })
      const url = URL.createObjectURL(blob)
      newFiles.push({ name: f.name, link: url, file: f })
      setChoosen({ ...choosen, [side]: newFiles })
      // e.target.value = null
    }
  }

  const deleteFile = (
    file: any,
    idx: number,
    choosen: any = verifyPhoto,
    setChoosen: any = setVerifyPhoto,
    side: string
  ) => {
    const newFilesArr = [...choosen[side]]
    newFilesArr.splice(idx, 1)
    setChoosen({ ...choosen, [side]: newFilesArr })

    if (file.id) {
      const newDeletedFilesId: any = [...deletedFilesId]
      newDeletedFilesId.push(file.id)
      setDeletedFilesId(newDeletedFilesId)
    }
  }

  return (
    <div>
      <Form
        onSubmit={() => {
          sendVerificationData(
            id,
            [
              verifyPhoto.up[0] ? verifyPhoto.up[0].file : null,
              verifyPhoto.back[0] ? verifyPhoto.back[0].file : null
            ],
            user,
            false,
            EPolygonStatus.INPROGRESS
          )
        }}
      >
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={styles.passport}>
                {verifyPhoto.up && verifyPhoto.up.length > 0 ? (
                  <>
                    <label htmlFor="addAvatar" className={styles['add-image-arria']}>
                      <img src={verifyPhoto.up[0].link} className={styles['add-image-arria']} />
                      <Icons
                        className={styles['delete']}
                        icon={'cross'}
                        onClick={() => {
                          deleteFile(verifyPhoto.up[0], 0, verifyPhoto, setVerifyPhoto, 'up')
                        }}
                      />
                    </label>
                  </>
                ) : (
                  <label htmlFor="addAvatar" className={styles['add-image-arria']}>
                    <h2 className={styles.named}>Up</h2>
                    <img src={passport} className={styles['passport_up']} />
                    <label htmlFor="addAvatar">
                      <Icons icon="addBlue" />
                      <p>
                        Format: JPEG, JPG, PNG
                        <br />
                        Scale: from 450 x 275 px
                        <br />
                        Size: Up to 10 MB
                      </p>
                      <input
                        id="addAvatar"
                        type="file"
                        // accept="image/*, .pdf"
                        onChange={(e: any) => {
                          onSelectFile(e, verifyPhoto, setVerifyPhoto, 'up')
                        }}
                      />
                    </label>
                  </label>
                )}
                {verifyPhoto.back && verifyPhoto.back.length > 0 ? (
                  <>
                    <label htmlFor="addAvatar" className={styles['add-image-arria']}>
                      <img src={verifyPhoto.back[0].link} className={styles['add-image-arria']} />
                      <Icons
                        className={styles['delete']}
                        icon={'cross'}
                        onClick={() => {
                          deleteFile(verifyPhoto.back[0], 0, verifyPhoto, setVerifyPhoto, 'back')
                        }}
                      />
                    </label>
                  </>
                ) : (
                  <label htmlFor="addAvatarBack" className={styles['add-image-arria']}>
                    <h2 className={styles.named}>Back</h2>
                    <img src={passport} className={styles['passport_up']} />
                    <label htmlFor="addAvatarBack">
                      <Icons icon="addBlue" />
                      <p>
                        Format: JPEG, JPG, PNG
                        <br />
                        Scale: from 450 x 275 px
                        <br />
                        Size: Up to 10 MB
                      </p>
                      <input
                        id="addAvatarBack"
                        type="file"
                        // accept="image/*, .pdf"
                        onChange={(e: any) => {
                          onSelectFile(e, verifyPhoto, setVerifyPhoto, 'back')
                        }}
                      />
                    </label>
                  </label>
                )}
              </div>
              <BaseButton
                className={styles.btn}
                children={'Send'}
                disabled={loadings}
                size="lgs"
                type="submit"
              />
            </form>
          )
        }}
      </Form>
    </div>
  )
}

export default PolygonForm
