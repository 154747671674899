/* eslint-disable no-console */
import React, { FC } from 'react'
import { IProps } from './ToggleBar.model'
import styles from './ToggleBar.module.scss'

export const ToggleBar: FC<IProps> = ({
  data,
  name,
  defaultChecked,
  onChange,
  style = 1,
  className = ''
}) => {
  const wrapperStyle = style === 1 ? 'radio-toolbar' : style === 2 ? 'cell-toolbar' : ''
  return (
    <div
      className={`${styles.togglebar} ${className} ${
        styles[wrapperStyle] ? styles[wrapperStyle] : ''
      }`}
    >
      {Object.values(data).map((item, index) => (
        <div key={index}>
          <input
            type="radio"
            id={item.value}
            name={name}
            value={item.value}
            defaultChecked={item.value === defaultChecked.value}
            onChange={event => {
              const result =
                data.find(value => value.value === event.target.value) || defaultChecked

              onChange(result)
            }}
            disabled={item.disabled}
          />
          <label htmlFor={item.value}>{item.title}</label>
          <div className={styles.cover} />
        </div>
      ))}
    </div>
  )
}
