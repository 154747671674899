import { action } from '../../../store/store.utils'
import {
  DELETE_SELECTED_MEMBER,
  SET_ALL_SELECTED_MEMBERS,
  SET_SELECTED_MEMBERS,
  DELETE_PROJECT_MEMBER,
  GET_PROJECT_MEMBERS_REQUEST,
  GET_PROJECT_MEMBERS_SUCCESS,
  GET_PROJECT_MEMBERS_ERROR
} from './CurrentTeam.constants'
import { AxiosError } from 'axios'

export const getProjectMembersRequest = () => action(GET_PROJECT_MEMBERS_REQUEST)

export const getProjectMemberSuccess = (payload: any) =>
  action(GET_PROJECT_MEMBERS_SUCCESS, payload)

export const getProjectMemberError = (payload: AxiosError) =>
  action(GET_PROJECT_MEMBERS_ERROR, payload)
export const deleteProjectMember = (payload: any) => action(DELETE_PROJECT_MEMBER, payload)
export const setSelectedMembers = (payload: any) => action(SET_SELECTED_MEMBERS, payload)
export const setSelectedAllMembers = (payload: any) => action(SET_ALL_SELECTED_MEMBERS, payload)
export const deleteSelectedMember = (payload: any) => action(DELETE_SELECTED_MEMBER, payload)
