import { AxiosError } from 'axios'
import { action } from 'store/store.utils'
import {
  GET_TASK_REQUEST,
  GET_TASK_SUCCESS,
  GET_TASK_ERROR,
  CLEAR_TASK_LIST
} from './CryptoBot.constants'
import { TAllTasksList } from './CryptoBot.model'

export const getTaskRequest = (payload?: any): TAllTasksList => action(GET_TASK_REQUEST, payload)

export const getTaskSuccess = (payload: any): TAllTasksList => action(GET_TASK_SUCCESS, payload)

export const getTaskError = (payload: AxiosError): TAllTasksList => action(GET_TASK_ERROR, payload)

export const clearTaskList = (): TAllTasksList => action(CLEAR_TASK_LIST)
