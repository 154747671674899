import { api } from 'services/api'

export async function sendPatchRequest<T>(url: string, data: T, headers: { apikey: string }) {
  try {
    const response = await api.patch(url, data, { headers })
    return { status: 'fulfilled', value: response.data }
  } catch (error) {
    return { status: 'rejected', reason: error }
  }
}
