import React, { FC } from 'react'
import { ReactComponent as DefaultAvatar } from 'assets/images/default_avatar.svg'
import styles from './NotificationListElement.module.scss'
import { INotificationsItem } from '../../Notifications.model'
import moment from 'moment'
import cls from 'classnames'

type Property = {
  notification: INotificationsItem
  onSelect: (notification: INotificationsItem) => void
  isSelected: boolean
}

const NotificationListElement: FC<Property> = ({ notification, onSelect, isSelected }) => {
  const { sender, isViewed } = notification

  const handleSelect = () => {
    onSelect(notification)
  }

  return (
    <li
      className={cls(styles['container'], isViewed ? styles['viewed'] : styles['new'], {
        [styles['active']]: isSelected
      })}
      onClick={handleSelect}
    >
      {!notification.isViewed && <div className={styles['notification-label']} />}
      <div className={styles['user']}>
        {sender.photo ? (
          <img className={styles['user-avatar']} src={sender.photo} alt={sender.fullName} />
        ) : (
          <DefaultAvatar className={styles['user-avatar']} />
        )}
      </div>
      <div className={styles['notification-title']}>
        <span className={styles['notification-title-bold']}>{notification.title}</span>
        <span className={styles['notification-title-light']}>{notification.message}</span>
      </div>
      <div className={styles['notification-date']}>
        {moment(notification.createdAt).format(`DD.MM.YY | hh:mm`)}
      </div>
    </li>
  )
}

export { NotificationListElement }
