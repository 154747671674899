import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './CreateInvoices.module.scss'
import cls from 'classnames'
import { Icons } from 'components/Icons'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { Form } from 'react-final-form'
import { Button } from 'components/UiKit/Button'
import DateInvoice from './components/DateInvoice/DateInvoice'
import SelectInvoice from './components/SelectInvoice/SelectInvoice'
import Checkbox from 'components/UiKit/Checkbox/Checkbox'
import { FormApi, FormState } from 'final-form'
import TableInvoice from './components/TableInvoice'
import toastr from 'utils/toastr'
import {
  TCreateInvoicesProps,
  TEntrepreneur,
  TEntrepreneursAmount,
  TInvoiceToDownload
} from './CreateInvoices.model'
import Offer from '../FormulatedInvoices/Offer/Offer'
import { generateZip } from '../Helpers/getZip'
import { banksToSelectOptions } from '../Helpers/banksToSelectOptions'
import { CURRENCIES } from './CreateInvoices.constants'

export type TFormValues = {
  formationDate: string
  currency: string
  bank: string
  download: boolean
}

const CreateInvoices = ({
  history,
  entrepreneurs,
  setInvoices,
  downloadInvoices,
  banks
}: TCreateInvoicesProps) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const [formValues, setFormValues] = useState({} as TFormValues)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState<boolean>(false)
  const downloadRef = useRef<any>([])
  const bankData = banksToSelectOptions(banks)

  useEffect(() => {
    if (formValues.download && downloadInvoices.length > 0) {
      generateZip(downloadRef, downloadInvoices).then(() =>
        history.push('/dashboard/entrepreneurs?tab=invoices')
      )
    }
  }, [downloadInvoices])

  const updateToDownload = useMemo(() => {
    return downloadInvoices.map((invoice: TInvoiceToDownload, i: number) => (
      <Offer
        invoice={invoice}
        key={invoice.id}
        isHidden={true}
        ref={(el: React.RefCallback<HTMLDivElement>) => (downloadRef.current[i] = el)}
      />
    ))
  }, [downloadInvoices])

  const cancelHandler = () => {
    history.push('/dashboard/entrepreneurs?tab=list')
  }

  const getItems = (entrepreneursAmount: TEntrepreneursAmount[]) => {
    const entreneursArr = entrepreneursAmount
      .map(({ id, amount }: TEntrepreneursAmount) => {
        const entrepreneur = entrepreneurs.find((item: TEntrepreneur) => item.id === id)

        const newEntrepreneur = {
          id,
          amount,
          name: entrepreneur.name,
          bank: entrepreneur.bank,
          invoiceNumber: new Date().getTime() + id
        }

        return JSON.stringify(newEntrepreneur)
      })
      .filter(Boolean)

    return entreneursArr
  }

  const handleCreateRequest = () => {
    const entrepreneursAmount = Object.entries(formValues)
      .filter(([key]) => key.startsWith('name'))
      .map(([key, value]) => {
        return { id: +key.split('name')[1], amount: +value }
      })

    const newInvoices = {
      bank: Number(formValues.bank),
      currency: formValues.currency,
      dateOfFormation: new Date(formValues.formationDate).toISOString(),
      entrepreneurs: getItems(entrepreneursAmount)
    }

    if (newInvoices.entrepreneurs.length === 0) {
      toastr('error', words['admin.entrepreneurs.noData'])
    } else {
      try {
        setInvoices(newInvoices)
        if (!formValues.download) {
          history.push('/dashboard/entrepreneurs?tab=invoices')
        }
        toastr('success', words['admin.entrepreneurs.successCreate'])
      } catch (err) {
        toastr('error', 'Error while creating')
      }
    }
  }

  const handleDownloadChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    form: FormApi,
    formState: FormState<any>
  ) => {
    form.initialize({
      ...formState.values,
      download: event.target.checked
    })
  }

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>{words['admin.entrepreneurs.newInvoices']}</h1>
      </section>
      <section>
        <div className={styles['block-go-back']}>
          <button
            type="button"
            className={styles['go-back']}
            onClick={event => {
              event.preventDefault()
              history.goBack()
            }}
          >
            <Icons icon="backArrowIcon" />
            <span className={styles['go-back-text']}>{words['back']}</span>
          </button>
        </div>
      </section>
      <section className={styles['form']}>
        <Form
          initialValues={{
            formationDate: '',
            currency: '',
            bank: '',
            download: false
          }}
          onSubmit={handleCreateRequest}
          render={({ form, handleSubmit }) => {
            const formState = form.getState()
            return (
              <form onSubmit={handleSubmit}>
                <div className={styles['form-wrapper']}>
                  <div className={styles['main-info']}>
                    <h2 className={styles['main-info-title']}>
                      {words['admin.entrepreneurs.mainInfo']}
                    </h2>
                    <label className={styles['label-for-field']}>
                      {words['entrepreneur.invoices.dateOfFormation']}
                      <span className={styles['red-note']}>*</span>
                    </label>

                    <DateInvoice form={form} formState={formState} name="formationDate" />
                    <SelectInvoice
                      name="currency"
                      label={words['entrepreneur.invoices.currency']}
                      form={form}
                      data={CURRENCIES}
                    />
                    <SelectInvoice
                      name="bank"
                      label={words['admin.entrepreneurs.bank']}
                      form={form}
                      data={bankData}
                    />
                    <div className={styles['form-checkbox']}>
                      <Checkbox
                        name="download"
                        label={words['admin.entrepreneurs.download']}
                        checked={formState.values.download}
                        onChange={e => handleDownloadChange(e, form, formState)}
                      />
                    </div>
                  </div>
                  <div
                    className={cls({
                      [styles['table']]: true,
                      [styles['table-shadow']]: !isScrolledToBottom
                    })}
                  >
                    <TableInvoice
                      form={form}
                      entrepreneurs={entrepreneurs}
                      setIsScrolledToBottom={setIsScrolledToBottom}
                      formState={formState}
                    />
                  </div>
                </div>
                <div className={styles['footer-btn-container']}>
                  <Button
                    onClick={cancelHandler}
                    disabled={false}
                    outline={true}
                    className="requesth"
                  >
                    {words['admin.productionCalendar.new.buttons.cancel']}
                  </Button>

                  <Button
                    type="submit"
                    className="requesth"
                    onClick={() => setFormValues({ ...formState.values } as TFormValues)}
                  >
                    {words['user.dayOffTracker.create']}
                  </Button>
                </div>
              </form>
            )
          }}
        />

        {updateToDownload}
      </section>
    </div>
  )
}
export default CreateInvoices
