import { Icons } from 'components/Icons'
import React from 'react'
import styles from './AlertNotification.module.scss'

export const AlertNotification = () => {
  return (
    <div className={styles.notification}>
      <Icons icon={'alertNotification'} />
      <div className={styles.alertDescription}>
        Lorem ipsum dolor sit amet, 4% adipiscing consectetur.
      </div>
    </div>
  )
}
