import React from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import styles from './SelectInvoice.module.scss'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import { IInvoiceSelect } from '../../CreateInvoice.model'
import { FormApi } from 'final-form'

interface ISelectInovice {
  name: string
  data: IInvoiceSelect[]
  label: string
  form: FormApi
  required: boolean
}

const SelectInvoice = ({ name, data, label, form, required }: ISelectInovice) => {
  const words = useSelector((state: TState) => state.global.language.words)
  return (
    <FieldFactory
      form={form}
      config={[
        {
          items: [
            {
              name: name,
              label: label,
              required: required,
              inputWrapperClassName: styles['select-wrap'],
              component: () => {
                return {
                  type: 'select',
                  props: {
                    placeholder: '',
                    options: data
                    // style: { borderRadius: '0px 10px 10px 0px', borderLeft: '0px' }
                  }
                }
              }
            }
          ]
        }
      ]}
      words={words}
    />
  )
}
export default SelectInvoice
