import toastr from 'toastr'
import { AxiosError } from 'axios'
import { errorMessagesParsing } from './toastrMessages'

export const enum EToastrTypeMessage {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error'
}

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-top-right',
  preventDuplicates: false,
  onclick: undefined,
  showDuration: 300,
  hideDuration: 1000,
  timeOut: 5000,
  extendedTimeOut: 1000,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut'
}

export default (typeMessage: string, message: string, error?: AxiosError) => {
  switch (typeMessage) {
    case 'error':
      if (error) {
        error && toastr.error(errorMessagesParsing(error))
      } else {
        toastr.error(message)
      }
      break

    case 'success':
      toastr.success(message)
      break

    case 'info':
      toastr.info(message)
      break

    default:
      toastr.info(message)
      break
  }
}
