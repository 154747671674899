import { Column } from 'react-table'
import { TableSortHeader } from '../../../../components/UiKit/Table'
import React from 'react'
import styles from '../SubcontractTasks.module.scss'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export type TTimeOffRequestsData = {
  fullName: string
  surname: string
  position: {
    id: number
    name: string
  }
  rank: {
    id: number
    name: string
  }
  level: {
    name: string
  }
  fromDate: string
  createdAt: string
  currency: number
  verification: string
}

type TColumnsManagerOptions = {
  role: number
  isOnlyManager: boolean
}
type TColumnsManager = (options: TColumnsManagerOptions) => Array<Column<TTimeOffRequestsData>>

export const Columns: TColumnsManager = () => {
  const words = useSelector((state: TState) => state.global.language.words)
  const status = (str: string) => {
    return str === 'true'
      ? 'accepted'
      : str === 'false'
      ? 'not verify'
      : str === 'inprogress'
      ? 'ready for'
      : 'no status'
  }
  const columnsManager: Array<Column<TTimeOffRequestsData>> = [
    {
      Header: words['user.subcontract.task.name'],
      id: 'fullName',
      accessor: d => d.fullName,
      minWidth: 210
    },
    {
      Header: words['user.subcontract.taskFilter.specialization'],
      id: 'fromDate',
      accessor: d => words[`${d.position.name}`],
      minWidth: 200
    },
    {
      Header: words['user.subcontract.taskFilter.level'],
      id: 'level',
      accessor: d => words[`${d.rank.name}`],
      minWidth: 200
    },
    {
      Header: ({ column }) => (
        <TableSortHeader id={column.id}>
          {words['user.subcontract.taskFilter.status']}
        </TableSortHeader>
      ),
      id: 'status',
      accessor: d => <div className={styles[status(d.verification)]}>{`${d.verification}`}</div>,
      minWidth: 70
    }
  ]

  return columnsManager
}
