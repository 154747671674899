import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import ProjectInvoicesComponent from './ProjectInvoices'
import { getInvoices, getProjects, removeInvoice } from './ProjectInvoices.thunk'
import { getProjectOptions } from '../Vacancies/CreateVacancy/CreateVacancy.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    displayAllFields: state.client.filter.multilineFilterFields.status,
    invoices: state.admin.projectInvoice.invoices,
    projects: state.admin.projectInvoice.projects,
    projectOptions: state.admin.vacancies.projectOptions
    // rank: state.admin.getUser.options.rank,
    // position: state.admin.getUser.options.positions,
    // loading: state.admin.getUser.loading
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInvoices,
      getProjects,
      removeInvoice,
      getProjectOptions
      // getUsers,
      // getRankPosition,
      // setProjectInvoice
    },
    dispatch
  )

export const ProjectInvoices = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectInvoicesComponent)
