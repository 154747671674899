import { connect } from 'react-redux'
import { Information } from './Information'
import { bindActionCreators, Dispatch } from 'redux'
import {
  fetchInformation,
  deleteInformation,
  createInformation,
  updateInformation
} from './Information.thunx'
import { withRouter } from 'react-router'
import { TState } from 'store'
import { postPolicy } from '../Policy/Policy.thunx'

export const mapStateToProps = (state: TState) => ({
  data: state.client.info,
  policyLoading: state.client.policy.loading
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { fetchInformation, deleteInformation, createInformation, updateInformation, postPolicy },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Information))
