const handleDefaultOption = (
  option:
    | {
        value: string
        label: string
      }
    | undefined
) => {
  if (option) {
    return option.value !== null ? option : { ...option, value: '' }
  }
  return {
    value: '',
    label: ''
  }
}

export { handleDefaultOption }
