import { ExecutorGoogleEvent, EventForCalendar } from '../PlaningCalendar.model'

export const parseGoogleEvents = (
  events: ExecutorGoogleEvent[],
  fallbackEvent: Partial<EventForCalendar>
): EventForCalendar[] => {
  const validEvents = events.filter(event => event.start !== undefined && event.end !== undefined)

  return validEvents.map(event => {
    const { start, end, title, description, id } = event
    const isExecutor = true
    const eventId = id
    const taskId = fallbackEvent.taskId !== undefined ? fallbackEvent.taskId : 0

    return {
      id: new Date(start || new Date()).getTime(),
      pretenderId: fallbackEvent.pretenderId || 0,
      start: new Date(start || new Date()),
      end: new Date(end || new Date()),
      title: title || fallbackEvent.title || '',
      description: description || fallbackEvent.description || '',
      isOverlap: fallbackEvent.isOverlap || false,
      isExecutor,
      eventId,
      taskId
    }
  })
}
