import React, { FC } from 'react'
import styles from './style.module.scss'
import { IProjectPreview } from '../../Projects.model'
import { withRouter, NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { Button } from '../../../../../components/UiKit/Button'

const ProjectPreview: FC<IProjectPreview> = ({ avatar, name, id }) => {
  const words = useSelector((state: TState) => state.global.language.words)

  const checkNameLength = (elem: any, defaultLength = 50) => {
    const newName = elem.substr(0, defaultLength)
    return elem.length > defaultLength ? `${newName}...` : elem
  }

  return (
    <div className={styles['project-wrapper']}>
      <div className={styles['project-content']}>
        <div className={styles['project-avatar']}>
          <img src={avatar} alt="Project avatar" />
        </div>
        <div className={styles['project-title']}>
          <div>{checkNameLength(name)}</div>
          <NavLink
            exact={true}
            className={styles['nav-link']}
            to={`/dashboard/projects/${id}?projectType=current-team`}
          >
            <Button size="exlg" type="button">
              {words['project.go']}
            </Button>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ProjectPreview)
