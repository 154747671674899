import React from 'react'
import { Column } from 'react-table'
import { TWords } from 'elements/SideBar/SideBar.config'
import cls from 'classnames'
import styles from './SubcontractHistoryTransaction.module.scss'
import { Icons } from 'components/Icons'
import moment from 'moment'

type TGetColumnsOptions = {
  transactions: TSubcontractHistoryTransactionData[]
}

export type TSubcontractHistoryTransactionData = {
  type: number
  sum: string
  token: string
  task: string
  date: string
}

type TGetColumns = (
  words: TWords,
  options?: TGetColumnsOptions
) => Array<Column<TSubcontractHistoryTransactionData>>

export const getColumns: TGetColumns = words => [
  {
    Header: words['user.subcontract.transactions.sum'],
    id: 'sum',
    accessor: ({ sum, type }) => (
      <span
        className={cls({
          [styles['color-price']]: true,
          [styles['color-price-red']]: type === 1,
          [styles['color-price-green']]: type === 2
        })}
      >
        <span
          className={cls({
            [styles['sign']]: true
          })}
        >
          {type === 1 ? '-' : '+'}
        </span>{' '}
        {sum}
      </span>
    ),
    width: 190
  },
  {
    Header: words['user.subcontract.transactions.token'],
    id: 'token',
    accessor: ({ token }) => (
      <span
        className={cls({
          [styles['token']]: true
        })}
      >
        <span className={styles['token-icon']}>
          <Icons icon="logoSmall" />
        </span>
        {token}
      </span>
    ),
    width: 190
  },
  {
    Header: words['user.subcontract.task'],
    id: 'task',
    accessor: ({ task }) => (
      <span
        className={cls({
          [styles['task']]: true
        })}
      >
        {task}
      </span>
    ),
    width: 510
  },
  {
    Header: words['user.subcontract.transactions.date'],
    id: 'date',
    accessor: ({ date }) => moment(date).format('DD.MM.YYYY'),
    width: 190
  }
]
