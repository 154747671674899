/* eslint-disable no-console */
import { Dispatch } from 'redux'
import { API, api } from '../../../services/api'
import { getTaskRequest, getTaskSuccess, getTaskError, clearTaskList } from './CryptoBot.actions'
import toastr from '../../../utils/toastr'
import { TState } from 'store'
import { AxiosError } from 'axios'

export const getTasks = (
  limit: number,
  page: any,
  url: string,
  filter: string = '',
  isClear: boolean = false,
  paginationLoading: boolean = false
) => async (dispatch: Dispatch, getData: () => TState): Promise<void> => {
  dispatch(getTaskRequest(paginationLoading))
  const words = getData().global.language.words

  try {
    const resp = await api.get(
      `${API.URL}${url}${
        url === '/tasks/own?status=1&' || url === '/tasks?planning=false&' ? '' : '?'
      }page=${page}${filter}&limit=${limit}`
    )
    if (
      url !== getData().client.subcontract.urlFor ||
      isClear ||
      filter !== getData().client.subcontract.filter ||
      page === '1'
    ) {
      dispatch(clearTaskList())
    }
    dispatch(
      getTaskSuccess({
        tasks: resp.data.payload.result,
        url: url,
        page: page,
        pageCount: resp.data.payload.pageCount,
        filter: filter,
        itemsCount: resp.data.payload.totalItems,
        paginationLoading: paginationLoading ? !paginationLoading : paginationLoading
      })
    )
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(getTaskError(err.response))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}
