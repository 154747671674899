import { EProfitFixationMode, IExchangeSettings } from '../../ControlPanel.types'

const isNotEqualToOrder = (values: IExchangeSettings) => {
  if (!values || !values.profitFixationMode) {
    return
  }

  return values.profitFixationMode !== EProfitFixationMode.ORDER
}

export { isNotEqualToOrder }
