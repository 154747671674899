import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Field } from 'react-final-form'
import { TState } from 'store'
import moment from 'moment'
import toastr, { EToastrTypeMessage } from 'utils/toastr'
import { EContractAddress, config } from 'globalConfigs'
import validation from 'utils/validation'
import queryString from 'query-string'

import { Textarea } from 'components/Textarea'
import { Input } from 'components/UiKit/Inputs'
import { DatePicker } from 'components/UiKit/DatePicker'
import { RedNote } from 'components/RedNote'
import { Button } from 'components/UiKit/Button'
import PurchaseModal from 'components/SubcontractModals/PurchaseModal'
import Modal from 'components/Modal'
import { TTaskRequestProps } from './TaskRequest.model'

import styles from './TaskRequest.module.scss'
import getWeb3 from 'getWeb3'
import { getHashSignature } from 'utils/getHashSignature'
import { getContractAbi } from 'utils/getContractAbi'
import { AbiItem } from 'web3-utils'
import Spinner from 'components/Spinner'
import { ETransactionMessage } from 'components/Spinner/types'
import { isEmpty } from 'lodash'

const TaskRequest: FC<TTaskRequestProps> = ({
  loading,
  error,
  history,
  createTaskRequest,
  currentTask,
  isTransaction,
  isTaskInTransaction,
  getTask
}) => {
  const [modalState, setModalState] = useState(false)
  const [openLoadingModal, setOpenLoadingModal] = useState<boolean>(false)
  const words = useSelector((state: TState) => state.global.language.words)
  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)
  const parsedQuery = queryString.parse(history.location.search)
  const taskId = parsedQuery.taskId
  const dispatch = useDispatch()

  useEffect(() => {
    if (isEmpty(currentTask) && taskId && typeof taskId === 'string') {
      getTask(+taskId, true)
    }
  }, [currentTask])

  function openModal() {
    setModalState(!modalState)
  }

  function onSubmit(values: any) {
    const request = new FormData()
    setOpenLoadingModal(true)

    request.append('comment', String(values.comment))
    request.append('makeProposalSignature', String(values.makeProposalSignature))
    request.append('budget', values.budget ? String(values.budget) : '0')
    request.append('deadline', values.deadline ? moment(values.deadline).toISOString() : '')
    request.append('taskId', String(taskId))
    createTaskRequest(request)
  }

  function closeLoadingModal() {
    return history.push(`/dashboard/subcontract?task=all-tasks&page=1`)
  }

  const handleMakeProposal = async (formValues: any): Promise<void> => {
    const budget = Number(formValues.budget)
    const web3 = await getWeb3()
    const { taskHash } = currentTask

    try {
      const addresses = await web3.eth.getAccounts()
      const makeProposalSignature = await getHashSignature(addresses[0], taskHash)
      const contractAbi: AbiItem[] = await getContractAbi(EContractAddress.FIX_DEAL_ESCROW)
      const contractInstance = new web3.eth.Contract(contractAbi, EContractAddress.FIX_DEAL_ESCROW)
      const deal = await contractInstance.methods.deals(taskHash).call()
      const gasPrice = await web3.eth.getGasPrice()
      const gasLimit = await contractInstance.methods
        .makeProposal(
          taskHash,
          deal.customer,
          budget,
          makeProposalSignature,
          formValues.isRepeatable
        )
        .estimateGas({ from: addresses[0] })

      await contractInstance.methods
        .makeProposal(
          taskHash,
          deal.customer,
          budget,
          makeProposalSignature,
          formValues.isRepeatable
        )
        .send({
          from: addresses[0],
          gasPrice,
          gas: gasLimit
        })
        .on('transactionHash', () => {
          dispatch(isTaskInTransaction(true))
          toastr(EToastrTypeMessage.INFO, words[`${ETransactionMessage.TRANSACTION_MESSAGE}`])
        })
        .on('receipt', () => {
          onSubmit({ ...formValues, makeProposalSignature })
          toastr(EToastrTypeMessage.SUCCESS, words[`${ETransactionMessage.TRANSACTION_SUCCESS}`])
        })
        .on('error', () => {
          toastr(EToastrTypeMessage.ERROR, words[`${ETransactionMessage.TRANSACTION_ERROR}`])
          dispatch(isTaskInTransaction(false))
        })
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(isTaskInTransaction(false))
    }
  }

  return (
    <>
      {isTransaction && <Spinner isBackground={true} isTransaction={isTransaction} />}
      <div className={styles.container}>
        <header className={styles.header}>
          <h1>{words['user.header.subcontract']}</h1>
        </header>

        <Form
          onSubmit={(values: any) => {
            handleMakeProposal(values)
          }}
        >
          {({ form, handleSubmit }) => {
            const formState = form.getState()
            const checkRequired = () => {
              if (formState.errors && Object.keys(formState.errors).length !== 0) {
                handleSubmit()
                toastr('error', words['user.editProfile.fillRequiredFields'])
              } else {
                handleSubmit()
              }
            }
            return (
              <main className={styles.main}>
                <form onSubmit={handleSubmit} name="form">
                  <section className={styles.form}>
                    <h2>{words['user.subcontract.taskRequest.header']}</h2>
                    <div className={styles.rows}>
                      <div className={styles.row}>
                        <div className={styles.message}>
                          <div className={styles.label}>
                            <label>
                              {words['user.subcontract.taskRequest.message']} {<RedNote />}
                            </label>
                          </div>
                          <Field
                            name="comment"
                            validate={validation.required(words['user.requiredMessage'])}
                          >
                            {({ input, meta }) => (
                              <Textarea
                                {...input}
                                isInvalid={meta.error && meta.submitFailed}
                                errorMessage={meta.error}
                              />
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className={styles.row}>
                        <div className={styles.budget}>
                          <div className={styles.label}>
                            <label>
                              {words['user.subcontract.taskRequest.budget']} {<RedNote />}
                            </label>
                          </div>
                          <Field
                            name="budget"
                            validate={validation.required(words['user.requiredMessage'])}
                          >
                            {({ input, meta }) => (
                              <Input
                                {...input}
                                isInvalid={meta.error && meta.submitFailed}
                                errorMessage={meta.error}
                                variant={'outlined'}
                                type={'number'}
                              />
                            )}
                          </Field>
                        </div>

                        <div className={styles.term}>
                          <div className={styles.label}>
                            <label>
                              {words['user.subcontract.taskRequest.term']} {<RedNote />}
                            </label>
                          </div>
                          <Field
                            name="deadline"
                            validate={validation.required(words['user.requiredMessage'])}
                          >
                            {({ input }) => (
                              //@ts-ignore
                              <DatePicker
                                {...input}
                                locale={currentLanguage}
                                name={input.name}
                                onChange={event => {
                                  if (event) {
                                    form.change(input.name, event)
                                  }
                                }}
                                autoComplete="off"
                                dateFormat={config.dateFormat}
                                selected={input.value ? new Date(input.value) : null}
                              />
                            )}
                          </Field>
                        </div>
                      </div>

                      {currentTask.isRepeatable && (
                        <div className={styles.checkboxes}>
                          <Field name={'isRepeatable'} type="checkbox">
                            {({ input }) => (
                              <Input
                                step="1"
                                variant="outlined"
                                type="checkbox"
                                placeholder="hel"
                                {...input}
                              />
                            )}
                          </Field>
                          <label>{words['user.createType.repeatedTask']}</label>
                        </div>
                      )}
                    </div>
                  </section>
                  <section className={styles.buttons}>
                    <div className={styles.bidsButton}>
                      <div className={styles.bidsAmount}>
                        <span>{words['user.subcontract.taskRequest.bids']}: </span>
                        <span>{5}</span>
                      </div>
                      <Button size={'md'} color={'green'} onClick={openModal}>
                        <span>{words['user.subcontract.taskRequest.buy']}</span>
                      </Button>
                    </div>
                    <div className={styles.actionsButton}>
                      <Button outline={true} size={'md'} onClick={() => history.goBack()}>
                        <span>{words['user.subcontract.reject']}</span>
                      </Button>
                      <Button
                        onClick={checkRequired}
                        size={'md'}
                        children={words['user.subcontract.reply']}
                      />
                    </div>
                  </section>
                </form>
              </main>
            )
          }}
        </Form>
        <PurchaseModal toggle={modalState} action={openModal} type={'bids'} />
        <Modal
          isShow={openLoadingModal}
          onClose={() => {
            if (!loading) closeLoadingModal()
          }}
          className={styles.LoadingModal}
        >
          {loading && !error && <p>{words['user.subcontract.taskRequest.loading']}</p>}
          {!loading && !error && (
            <div className={styles.success}>
              <p>{words['user.subcontract.taskRequest.success']}</p>
              <div className={styles.btn}>
                <Button onClick={closeLoadingModal}>
                  <span>Ok</span>
                </Button>
              </div>
            </div>
          )}
        </Modal>
      </div>
    </>
  )
}

export default TaskRequest
