import { channelsMinMax, exchangeSettingsLimits } from 'globalConfigs'
import {
  IChannelSettings,
  IExchangeSettings
} from 'screens/client/CryptoBot/components/ControlPanel/ControlPanel.types'

export function applyExchangeDefaultValues(settings: IExchangeSettings): IExchangeSettings {
  const updatedSettings: IExchangeSettings = structuredClone(settings)

  for (const key in updatedSettings) {
    const exchangeKey = key as keyof IExchangeSettings

    if (key in exchangeSettingsLimits && updatedSettings[exchangeKey] === null) {
      ;(updatedSettings as any)[exchangeKey] =
        exchangeSettingsLimits[key as keyof typeof exchangeSettingsLimits].min
    }
  }

  return updatedSettings
}

export function applyChannelDefaultValues(settings: IChannelSettings): IChannelSettings {
  const updatedSettings: IChannelSettings = structuredClone(settings)

  for (const key in updatedSettings) {
    const exchangeKey = key as keyof IChannelSettings

    if (key in channelsMinMax && updatedSettings[exchangeKey] === null) {
      ;(updatedSettings as any)[exchangeKey] =
        channelsMinMax[key as keyof typeof channelsMinMax].min
    }
  }

  return updatedSettings
}
