import React, { FC, useEffect, useState } from 'react'
import cls from 'classnames'
import { isUser } from 'utils/user'
import { ReactComponent as FacebookLogo } from 'assets/images/facebook.svg'
import { ReactComponent as InstagramLogo } from 'assets/images/instagram.svg'
import { ReactComponent as LinkedinLogo } from 'assets/images/linkedin.svg'
import { ReactComponent as LinkedinLogoDisable } from 'assets/images/linkedinDisable.svg'
import { ReactComponent as FacebookLogoDisable } from 'assets/images/facebookDisable.svg'
import { ReactComponent as InstagramLogoDisable } from 'assets/images/instagramDisable.svg'
import { ReactComponent as EmailLogo } from 'assets/images/email.svg'
import { ReactComponent as LocationLogo } from 'assets/images/location.svg'
import { ReactComponent as PhoneLogo } from 'assets/images/phone.svg'
import { ReactComponent as DefaultAvatar } from 'assets/images/default_avatar.svg'
import styles from './UserHeader.module.scss'
import { TState } from 'store'
import { useSelector } from 'react-redux'
import { TUserHeaderProps } from './UserHeader.model'
import { EditBtnMenu } from 'screens/client/Profile/components/EditBtnMenu'
import { TerminationInformationModal } from './TerminationInformationModal'
import { InfoField } from './components/InfoField/InfoField'
import { Skeleton } from 'components/Skeleton'

export const UserHeader: FC<TUserHeaderProps | any> = ({
  isSameUser = false,
  role,
  fullName,
  city,
  photo,
  rank,
  position,
  email,
  id,
  phoneNumber,
  socialNetworks,
  deactivated,
  terminationDate,
  deactivationComment,
  terminationInitiator,
  blackList
}: any) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const [open, setOpen] = useState(false)
  const [isInfoExists, setIsInfoExists] = useState(!!fullName)
  const showHiddenInfo = isSameUser || !isUser(role)
  console.log(isInfoExists)
  useEffect(() => {
    if (fullName) {
      setIsInfoExists(true)
    }
  }, [fullName])

  const toggleTerminationInformationModal = (): void => {
    setOpen(!open)
  }

  const userSkils =
    rank && rank.name && position && position.name
      ? `${words[rank.name]} ${words[position.name]}`
      : ''

  return (
    <div
      className={cls({
        [styles.main]: true,
        [styles['deactivated-profile']]: deactivated
      })}
    >
      <div className={styles.information}>
        <div className={styles['personal-info']}>
          {isInfoExists ? (
            photo ? (
              <img src={photo} alt="User photo" />
            ) : (
              <DefaultAvatar />
            )
          ) : (
            <Skeleton variant="circular" className={styles['img-placeholder']} />
          )}
          <div className={styles.socials}>
            <a href={socialNetworks && socialNetworks.linkedin} target="blank">
              {socialNetworks && socialNetworks.linkedin ? (
                <LinkedinLogo />
              ) : (
                <LinkedinLogoDisable />
              )}
            </a>
            <a href={socialNetworks && socialNetworks.facebook} target="blank">
              {socialNetworks && socialNetworks.facebook ? (
                <FacebookLogo />
              ) : (
                <FacebookLogoDisable />
              )}
            </a>
            <a href={socialNetworks && socialNetworks.instagram} target="blank">
              {socialNetworks && socialNetworks.instagram ? (
                <InstagramLogo />
              ) : (
                <InstagramLogoDisable />
              )}
            </a>
          </div>
        </div>

        <div className={styles.description}>
          <div className={styles['description-info']}>
            <span className={styles['description-name']}>{fullName || <Skeleton />}</span>
            <span className={styles['description-position']}>{userSkils || <Skeleton />}</span>
          </div>
        </div>
      </div>

      <div className={styles['main-info']}>
        <InfoField info={city}>
          <LocationLogo className={styles['main-info-logo']} />
          <span className={styles['main-info-text']}>{city}</span>
        </InfoField>
        <InfoField info={email}>
          <EmailLogo className={styles['main-info-logo']} />
          <span className={styles['main-info-text']}>{email}</span>
        </InfoField>
        <InfoField info={phoneNumber}>
          <PhoneLogo className={styles['main-info-logo']} />
          <span className={styles['main-info-text']}>{phoneNumber}</span>
        </InfoField>
      </div>

      <div className={styles['mood-scale-container']}>
        {showHiddenInfo && <EditBtnMenu userId={id} role={role} deactivated={deactivated} />}
        {isInfoExists ? (
          deactivated ? (
            <div className={styles.deactivated}>
              <div className={styles['deactivated-row']}>
                <span>{words['admin.team.deactivated']}</span>
                <div
                  onClick={toggleTerminationInformationModal}
                  className={styles['question-icon']}
                >
                  ?
                </div>
              </div>
            </div>
          ) : (
            <div className={styles['mood-scale']}>
              <div className={styles['row']}>
                <h2>{words['user.profile.moodScale']}:</h2>
              </div>
              <div className={styles['mood-scale-cap']} />
            </div>
          )
        ) : (
          <div className={styles['mood-scale']}>
            <div className={styles['row']}>
              <h2>{words['user.profile.moodScale']}:</h2>
            </div>
            <Skeleton variant="rectangular" className={styles['mood-scale-cap-placeholder']} />
          </div>
        )}
      </div>
      <TerminationInformationModal
        toggleTerminationInformationModal={toggleTerminationInformationModal}
        open={open}
        terminationDate={terminationDate}
        deactivationComment={deactivationComment}
        terminationInitiator={terminationInitiator}
        blackList={blackList}
      />
    </div>
  )
}
