import React, { ButtonHTMLAttributes, FC, Ref } from 'react'
import cls from 'classnames'
import styles from './BaseButton.module.scss'
import { ReactComponent as SpinnerSvg } from 'assets/images/spinner.svg'

export type TBaseProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean
  disabled?: boolean
  className?: string
  variant?: 'secondary' | 'small'
  outline?: boolean
  size?: 'default' | 'xs' | 'sm' | 'smb' | 'md' | 'mds' | 'lgs' | 'lg' | 'exlg' | 'percent'
  color?: 'default' | 'green' | 'red'
  innerRef?: Ref<HTMLButtonElement>
  onCheng?: any
  inpOnChange?: any
  index?: number
  checked?: boolean
  label?: string
  rounded?: boolean
  reverse?: boolean
  name?: string
}

export const BaseButton: FC<TBaseProps> = ({
  className = '',
  children,
  innerRef,
  variant,
  disabled = false,
  outline = false,
  loading = false,
  type = 'button',
  size = 'default',
  color,
  rounded,
  reverse = false,
  ...rest
}) => (
  <button
    {...rest}
    ref={innerRef}
    type={type}
    className={cls({
      [className]: !!className,
      [styles.btn]: true,
      [styles['btn-secondary']]: variant === 'secondary',
      [styles['btn-small']]: variant === 'small',
      [styles['btn-request-h']]: className === 'requesth',
      [styles['btn-outline']]: outline,
      [styles['btn-rounded']]: rounded,
      [styles[`btn-size-${size}`]]: true,
      [styles[`btn-color-${color}`]]: true,
      [styles['btn-disabled']]: disabled
    })}
    disabled={disabled || loading}
  >
    {loading ? <SpinnerSvg style={{ width: 24, height: 24 }} /> : children}
  </button>
)
