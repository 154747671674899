import { Dispatch } from 'redux'
import { API, api } from 'services/api'
import toastr from 'utils/toastr'

import {
  requestSetCreateItem,
  successSetCreateItem,
  errorSetCreateItem,
  requestGetGoodsCategories,
  successGetGoodsCategories,
  errorGetGoodsCategories,
  getCurrentItemRequest,
  getCurrentItemSuccess,
  getCurrentItemError,
  removeReviewRequest,
  removeReviewSuccess,
  removeReviewError,
  removeReviewAdminRequest,
  removeReviewAdminSuccess,
  removeReviewAdminError,
  deleteCategoryError,
  deleteCategoryRequest,
  setNewCategoryRequest,
  setNewCategorySuccess,
  setNewCategoryError,
  deleteAchievementCategorySuccess,
  deleteGoodsCategorySuccess
} from './GoodsItem.actions'
import history from 'utils/history'
import { USER_ROLE } from '../../../globalConfigs'
import { TState } from 'store'

export const setCreateItem: any = (body: any, id?: string) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  const method = id ? 'put' : 'post'
  const url = id ? `${API.URL}${API.GOODS}/${id}` : `${API.URL}${API.GOODS}`
  const words = getData().global.language.words
  dispatch(requestSetCreateItem())

  try {
    const response = await api[method](url, body)

    dispatch(successSetCreateItem(response))
    response.data.status === 'ok' &&
      toastr('success', id ? words['UPDATED_ITEM'] : words['CREATED_ITEM'])
    response.data.status === 'error' && toastr('error', response.data.errorMessage)
    history.push('/dashboard/point-system/store')
  } catch (error) {
    dispatch(errorSetCreateItem(error))
    // @ts-ignore
    toastr('error', error)
  }
}

export const getGoodsCategories: any = () => async (dispatch: Dispatch) => {
  dispatch(requestGetGoodsCategories())

  try {
    const response = await api.get(`${API.URL}${API.GOODS_CATEGORIES}`)
    dispatch(successGetGoodsCategories(response.data.payload.results))
  } catch (error) {
    dispatch(errorGetGoodsCategories(error))
    // @ts-ignore
    toastr('error', error)
  }
}

export const deleteGoodsCategory = (id: string | number, type: 'achievement' | 'good') => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(deleteCategoryRequest())
  const words = getData().global.language.words

  try {
    await api.delete(`${API.URL}${API.GOODS_CATEGORIES}/${id}`)
    if (type === 'good') {
      dispatch(deleteGoodsCategorySuccess({ id }))
    }
    if (type === 'achievement') {
      dispatch(deleteAchievementCategorySuccess({ id }))
    }
    toastr('success', words['SUCCESS_DELETED'])
  } catch (error) {
    // @ts-ignore
    dispatch(deleteCategoryError(error))
    // @ts-ignore
    toastr('error', error)
  }
}

export const getCurrentItem = (id: string) => async (dispatch: Dispatch) => {
  dispatch(getCurrentItemRequest())

  try {
    const response = await api.get(`${API.URL}${API.GOODS}/${id}`)
    const { result } = response.data.payload
    dispatch(getCurrentItemSuccess(result))
  } catch (error) {
    // @ts-ignore
    dispatch(getCurrentItemError(error))
    // @ts-ignore
    toastr('error', error)
  }
}

export const removeReview = (id: number | string, role: string) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  role === USER_ROLE ? dispatch(removeReviewRequest()) : dispatch(removeReviewAdminRequest())
  const words = getData().global.language.words

  try {
    await api.delete(`${API.URL}${API.REVIEW}/${id}`)
    role === USER_ROLE ? dispatch(removeReviewSuccess(id)) : dispatch(removeReviewAdminSuccess(id))
    toastr('success', words['SUCCESS_DELETED'])
  } catch (error) {
    role === USER_ROLE
      ? // @ts-ignore
        dispatch(removeReviewError(error))
      : // @ts-ignore
        dispatch(removeReviewAdminError(error))
    // @ts-ignore
    toastr('error', words['FILED_DELETE_REVIEW'], error)
  }
}

export const setNewCategory = (category: string, type: number) => async (dispatch: Dispatch) => {
  type TBody = { name: string; type: number }
  const body: TBody = { name: category, type }
  dispatch(setNewCategoryRequest())
  try {
    const response = await api.post(`${API.URL}${API.CATEGORIES}`, body)
    dispatch(setNewCategorySuccess(response.data.payload.result))
  } catch (error) {
    // @ts-ignore
    dispatch(setNewCategoryError(error))
    // @ts-ignore
    toastr('error', 'Category with this name already exists')
  }
}
