import React, { FC, useEffect } from 'react'
import styles from '../../Subcontract.module.scss'
import { PerformersList } from 'components/Subcontract/PerformersList/PerformersList'
import {
  SubcontractTasksFilter,
  TSubcontractFilterValues
} from 'components/Subcontract/SubcontractTasksFilter/SubcontractTasksFilter'
import Pagination from 'react-js-pagination'
import { TPerformersProps } from './Performers.model'
import { ESubcontractTabs } from 'components/Subcontract/subcontract.enum'
import queryString from 'query-string'
import { ReactComponent as Prev } from 'assets/images/prev.svg'
import { ReactComponent as Next } from 'assets/images/next.svg'
import { finishPoint } from '../../components/CreateTask/initialvalues'
import tasks_tablet from '../../../../../assets/images/tasks_tablet.svg'
import Loading from '../../../../../components/Spinner'
import { useSelector } from 'react-redux'
import { TState } from 'store'

const PerformersComponent: FC<TPerformersProps> = ({
  history,
  loading,
  usersArray,
  getUsers,
  clearStore,
  itemsCount,
  tokens,
  fetchAllTokensThunk
}) => {
  const parsed = queryString.parse(history.location.search)
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  const words = useSelector((state: TState) => state.global.language.words)

  const limit = 10

  const handlePageChange = (pagePag: number) => {
    const url = new URL((window.location as unknown) as string)
    url.searchParams.set('page', String(pagePag))
    window.history.pushState({}, '', url.toString())
    getUsers(10, pagePag, '/users', finishPoint(), true, true)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    fetchAllTokensThunk()
    getUsers(limit, parsed.page, '/users', finishPoint(), parsed.clear === 'true')
  }, [])

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>{words['user.header.subcontract']}</h1>
      </section>
      <section className={styles['list-filter']}>
        {usersArray.length === 0 ? (
          loading ? (
            <div className={styles.loadingWrapper}>
              <Loading />
            </div>
          ) : (
            <div className={styles['empty-list']}>
              <img src={tasks_tablet} alt="logo" />
              <h2>{words['user.subcontract.performersListIsEmpty']}</h2>
            </div>
          )
        ) : (
          <PerformersList getUsers={getUsers} urlEndpoint={'/users'} />
        )}

        <div className={styles.filter}>
          <SubcontractTasksFilter
            tokens={tokens}
            activeTab={ESubcontractTabs.EXECUTORS}
            onSubmit={function(values: TSubcontractFilterValues) {
              clearStore()
              history.push(
                `${history.location.pathname}?${queryString.stringify({
                  ...parsed,
                  page: 1,
                  rank: values.rank && values.rank.value !== '@ALL@' ? values.rank.value : '',
                  position:
                    values.position && values.position.value !== '@ALL@'
                      ? values.position.value
                      : '',
                  technologies: values.technology,
                  isClear: true
                })}`
              )
            }}
          />
          <div className={styles['pagination-container']}>
            <Pagination
              itemsCountPerPage={10}
              totalItemsCount={itemsCount}
              activePage={params.page ? +params.page : 0}
              onChange={handlePageChange}
              hideFirstLastPages={true}
              prevPageText={<Prev />}
              nextPageText={<Next />}
              linkClass={styles['pagination-item']}
              activeLinkClass={styles['pagination-active']}
              disabledClass={styles['pagination-disabled']}
            />
          </div>
        </div>
      </section>
    </div>
  )
}

export { PerformersComponent }
