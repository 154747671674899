import { TBaseFilterConfigData } from 'components/UiKit/Filter/Filter'
import { TWords } from 'elements/SideBar/SideBar.config'
import { discountData } from '../CreateInvoice/CreateInvoice'
import { TProject } from './ProjectInvoices.model'

export const getFilterConfig = ({
  displayAllFields,
  projects,
  words
}: {
  displayAllFields: boolean
  projects: TProject[]
  words: TWords
}): TBaseFilterConfigData[] => {
  const filter: TBaseFilterConfigData[] = [
    {
      name: 'project',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...projects],
      label: words['projectInvoices.project'],
      placeholder: '',
      width: '22%'
    },
    {
      name: 'durationFromDate',
      secondName: 'durationToDate',
      type: 'dateRangeNew',
      label: words['projects.invoices.duration'],
      fieldData: {
        from: '',
        to: ''
      },
      rangeContainerWidth: '21%'
    },
    {
      name: 'teamFrom',
      secondName: 'teamTo',
      type: 'inputRange',
      fieldData: { minValue: '', maxValue: '' },
      label: words['projectInvoices.numbersOfTeam'],
      rangeContainerWidth: '22%'
    },
    {
      name: 'priceFrom',
      secondName: 'priceTo',
      type: 'inputRange',
      fieldData: { minValue: '$', maxValue: '$' },
      label: words['projects.invoices.totalAmount'],
      rangeContainerWidth: '20%'
    }

    // {
    //   name: 'model',
    //   type: 'select',
    //   fieldData: [{ value: '@ALL@', label: words['all'] }, ...modelsData],
    //   label: words['project.model'],
    //   placeholder: words['project.model'],
    //   width: '22%'
    // }
  ]
  if (displayAllFields) {
    filter.push(
      {
        name: 'paymentFromDate',
        secondName: 'paymentToDate',
        type: 'dateRangeNew',
        label: words['projects.invoices.paymentDate'],
        fieldData: {
          from: '',
          to: ''
        },
        rangeContainerWidth: '22%'
      },
      {
        name: 'formationFromDate',
        secondName: 'formationToDate',
        type: 'dateRangeNew',
        label: words['projects.invoices.formationDate'],
        fieldData: {
          from: '',
          to: ''
        },
        rangeContainerWidth: '21%'
      },
      {
        name: 'discount',
        type: 'select',
        fieldData: [{ value: '@ALL@', label: words['all'] }, ...discountData],
        label: words['projects.invoices.discount'],
        placeholder: words['projects.invoices.formationDate'],
        width: '22%'
      }
    )
  }
  return filter
}
