type TBanksResp = {
  id: number
  name: string
  createdAt: string
  updatedAt: string
}

export const bankValuesToWords = (banks: TBanksResp[]): Record<string, string> =>
  banks.reduce((words, bank) => {
    return { ...words, [bank.name]: bank.name }
  }, {})
