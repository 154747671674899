import { Dispatch } from 'redux'
import { API, api } from '../../../services/api'
import toastr from 'utils/toastr'
import {
  createTaskRequestLoading,
  createTaskRequestSuccess,
  createTaskRequestError
} from './TaskRequest.action'
import { AxiosError } from 'axios'
import { TState } from 'store'
// import { words } from 'lodash'

export const createTaskRequest = (body: any) => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  const words = getData().global.language.words
  dispatch(createTaskRequestLoading())
  try {
    await api.post(`${API.URL}${API.TASK_REQUEST}`, body)
    dispatch(createTaskRequestSuccess())
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(createTaskRequestError(err.response))
    // toastr('error', err)
    toastr('error', words['TASK_REQUEST_ERROR'], err)
  }
}
