// import { clearOneProject } from './../../../CreateProject/CreateProject.thunk'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { getOneProject, deleteProject, restoreProject, getManagers } from '../../Projects.thunx'
import { TState } from 'store'

import ProjectComponent from './Project'

export const mapStateToProps = (state: TState) => {
  return {
    loading: state.admin.projects.loading,
    displayAllFields: state.client.filter.multilineFilterFields.status,
    projectInfo: state.admin.projects.project,
    positions: state.client.editProfile.positions,
    typeOfEmployment: state.admin.teamAdmin.options.typeOfEmployment,
    rank: state.client.editProfile.rank,
    managers: state.admin.projects.managers
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getOneProject,
      deleteProject,
      restoreProject,
      getManagers
    },
    dispatch
  )

export const Project = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectComponent)
