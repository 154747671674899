import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import styles from './styles.module.scss'
import toastr from '../../utils/toastr'
import { Field, Form } from 'react-final-form'
import validation from '../../utils/validation'
import { Input } from '../UiKit/Inputs'
import { Button } from '../UiKit/Button'
import cls from 'classnames'

type TModalProps = {
  toggle?: boolean
  action?: any
  type?: 'bids' | 'interview'
  size?: 'default' | 'md'
}

const PurchaseModal: FC<TModalProps> = ({ toggle, action, type, size = 'default' }) => {
  const words = useSelector((state: TState) => state.global.language.words)
  return (
    <div>
      <div onClick={action} className={cls({ [styles.overlay]: toggle })} />
      <div className={cls({ [styles.container]: true, [styles.active]: toggle })}>
        <div className={cls({ [styles.modal]: true, [styles[`modal-size-${size}`]]: true })}>
          <h2>
            {type === 'bids'
              ? words['user.subcontract.purchaseBidsModal.header']
              : words['user.subcontract.purchaseInterviewModal.header']}
          </h2>
          <Form
            onSubmit={(values: any) => {
              console.log(values)
            }}
          >
            {({ form, handleSubmit }) => {
              const formState = form.getState()
              const checkRequired = () => {
                if (formState.errors && Object.keys(formState.errors).length !== 0) {
                  toastr('error', 'Fill required fields!')
                } else {
                  handleSubmit()
                }
              }
              return (
                <form onSubmit={handleSubmit} name="form">
                  <section className={styles.form}>
                    <div className={styles.amount}>
                      <div className={styles.label}>
                        <label>
                          {type === 'bids'
                            ? words['user.subcontract.purchaseBidsModal.amount']
                            : words['user.subcontract.purchaseInterviewModal.amount']}
                        </label>
                      </div>
                      <Field name="amount" validate={validation.isNumber()}>
                        {({ input, meta }) => (
                          <Input
                            {...input}
                            isInvalid={meta.error && meta.submitFailed}
                            errorMessage={meta.error}
                            variant={'outlined'}
                          />
                        )}
                      </Field>
                    </div>
                    <div className={styles.price}>
                      <span>{words['user.subcontract.price']}: </span>
                      <span>{1200} TRL</span>
                    </div>
                  </section>
                  <section className={styles.buttons}>
                    <Button outline={true} size={'lg'} onClick={action}>
                      <span>{words['user.subcontract.cancelTaskModal.cancel']}</span>
                    </Button>
                    <Button onClick={checkRequired} size={'lg'}>
                      <span>{words['user.subcontract.taskRequest.buy']}</span>
                    </Button>
                  </section>
                </form>
              )
            }}
          </Form>
        </div>
      </div>
    </div>
  )
}

export default PurchaseModal
