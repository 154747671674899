import React from 'react'
import { ToggleBar } from 'components/ToggleBar'
import { IHeaderProps } from '../../Login.model'
// import history from 'utils/history'

import { ReactComponent as Logo } from 'assets/images/logo-new.svg'

import styles from './Header.module.scss'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { useAuth } from 'hooks/auth.hook'

const toggleBarOptions = [
  {
    value: '4',
    title: 'User'
  },
  {
    value: '2',
    title: 'Admin'
  }
]

const Header: React.FC<IHeaderProps> = ({ selectedType, setSelectedType }) => {
  const { loginWithRedirect } = useAuth()

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.headerWrapper}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <div className={styles.headerActions}>
            <ToggleBar
              name="login"
              data={toggleBarOptions}
              defaultChecked={{ title: 'current', value: `${selectedType}` }}
              onChange={setSelectedType}
              className={styles.loginHeader}
            />
            <div className={styles.signIn}>
              <BaseButton
                children="Sign in"
                size={'lgs'}
                className={styles.link}
                onClick={e => {
                  e.preventDefault()
                  loginWithRedirect()
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
