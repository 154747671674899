import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import VerificationAdminComponent from './VerificationAdmin'
import { updateUser, getUsers, createOffer } from './VerificationAdmin.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    userList: state.admin.verification.userList,
    loading: state.admin.verification.loading,
    itemsCount: state.admin.verification.itemsCount
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateUser,
      getUsers,
      createOffer
    },
    dispatch
  )

export const VerificationAdmin = connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationAdminComponent)
