import React, { FC } from 'react'
import styles from '../VotingDetails/VotingDetails.module.scss'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import Modal from 'components/Modal'
import { TVotingModalProps } from './VotingModal.model'
import { UserAvatar } from 'components/UserAvatar'

const VotingModal: FC<TVotingModalProps> = ({
  isShow,
  onClose,
  comment: { type, avatar, name, desc, percent }
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  return (
    <Modal className={styles['modal']} isShow={isShow} onClose={onClose}>
      <div className={styles['modal-content']}>
        <h2 className={styles['subtitle']}>
          {type === 'customer'
            ? words['user.voting.customerComment']
            : words['user.voting.performerComment']}
        </h2>
        <div>
          <div className={styles['avatar-wrapper']}>
            <UserAvatar className={styles['avatar']} photo={avatar} />
            <div className={styles['name']}>{name}</div>
          </div>
          <div className={styles['comment-desc']}>{desc}</div>
        </div>
        <div className={styles['budget']}>
          <h3 className={styles['title']}>{words['user.voting.budgetAllocation']}:</h3>
          <div className={styles['customer']}>
            <div className={styles['category-label']}>
              {words['user.subcontract.task.customer']}:
            </div>
            <div className={styles['value']}>{type === 'customer' ? percent : 100 - percent}%</div>
          </div>
          <div className={styles['executor']}>
            <div className={styles['category-label']}>{words['user.taskPayment.performer']}:</div>
            <div className={styles['value']}>{type === 'executor' ? percent : 100 - percent}%</div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export { VotingModal }
