import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import PlaningCalendarComponent from './PlaningCalendar'
import {
  setEvent,
  getEvents,
  changeEvent,
  deleteEvent,
  addEventToGoogleCalendar,
  updateEventToGoogleCalendar,
  deleteEventFromGoogleCalendar
} from './PlaningCalendar.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    taskArray: state.client.subcontract.tasks,
    eventsArray: state.client.planing.eventsArray,
    currentTask: state.client.planing.currentTask,
    eventsLoading: state.client.planing.loading,
    userAvatar: state.auth.avatar,
    task: state.client.subcontract.task
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setEvent,
      getEvents,
      changeEvent,
      deleteEvent,
      addEventToGoogleCalendar,
      updateEventToGoogleCalendar,
      deleteEventFromGoogleCalendar
      //   getUsers,
      //   clearStore,
      //   clearTask
    },
    dispatch
  )

export const PlaningCalendar = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaningCalendarComponent)
