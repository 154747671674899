import React, { useEffect } from 'react'
import { Filter } from '../../UiKit/Filter'
import { getVacanciesFilterConfig } from '../../../screens/admin/Vacancies/Vacancies.filter.config'
import { useSelector } from 'react-redux'
import { TState } from '../../../store'
import { Table as UIKitTable } from '../../UiKit/Table'
import { Columns } from '../../../screens/admin/Vacancies/Vacancies.columns'
import { useHistory, useParams } from 'react-router'
import { finishPointVacancies } from '../../../screens/client/Subcontract/components/CreateTask/initialvalues'
import styles from '../../../screens/admin/Vacancies/Vacancies.module.scss'
const ProjectVacancies = ({
  displayAllFields,
  rank,
  typeOfEmployment,
  positions,
  projectOptions,
  vacanciesArray,
  getSelectOptions,
  getProjectOptions,
  getProjectVacancies,
  getVacancyRequest,
  getStatusOptions,
  statusOptions
}: any) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const history = useHistory()
  const params: any = useParams()
  const columns = Columns({ role: 2, isOnlyManager: false })
  useEffect(() => {
    getSelectOptions()
    getProjectOptions()
    getStatusOptions()
    const filter = finishPointVacancies()
    getProjectVacancies(params.id, filter)
  }, [])
  const handleRowClick = (e: any) => {
    getVacancyRequest()
    history.push('/dashboard/vacancies/' + e.original.id)
  }
  return (
    <div className={styles['ttest']}>
      <div className={styles['vacancies-filter-projects']}>
        <Filter
          config={getVacanciesFilterConfig({
            positions,
            rank,
            displayAllFields,
            statusOptions,
            fromProject: true,
            typeOfEmployment,
            projectOptions,
            maxToDate: Date.now(),
            minFromDate: Date.now(),
            role: 2,
            words
          })}
          isMultilineBtn={true}
          withButton={false}
          defaultOpened={true}
        />
      </div>
      <div>
        <UIKitTable
          columns={columns}
          data={vacanciesArray}
          getData={() => ({})}
          onRowClick={handleRowClick}
          total={vacanciesArray.length}
        />
      </div>
    </div>
  )
}

export default ProjectVacancies
