import React from 'react'
import { Field } from 'react-final-form'
import { Select as NewUIKitSelect } from 'Select'
import { TSelectOption } from 'components/Select'
import { useSelector } from 'react-redux'
import { TState } from 'store'
const SelectProject = ({ validation, name, data, setData, form }: any) => {
  const words = useSelector((state: TState) => state.global.language.words)
  return (
    <Field
      name={name}
      validate={validation.composeValidators(validation.required(words['user.requiredMessage']))}
    >
      {({ input, meta }) => (
        <div data-tip="" data-for="model">
          <NewUIKitSelect
            label={name}
            defaultValue={data[0]}
            options={data}
            {...input}
            isInvalid={meta.error && meta.submitFailed && !meta.dirty}
            errorMessage={meta.error}
            emptyMessage={words['noOption']}
            onChange={event => {
              setData({
                value: (event as TSelectOption).value,
                label: (event as TSelectOption).label
              })
              form.change(name, event)
            }}
          />
        </div>
      )}
    </Field>
  )
}
export default SelectProject
