import { TBank } from 'screens/admin/Users/Users.model'
import { ISelect } from '../Entrepreneurs.model'

export const banksToSelectOptions = (banks: TBank[]): ISelect[] => {
  return banks.map(
    (bank): ISelect => {
      const { id, name } = bank
      return { value: id.toString(), label: name }
    }
  )
}
