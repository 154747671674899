import { IAction } from 'models'
import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
  REMOVE_NOTIFICATION,
  ADD_NOTIFICATION,
  SELECT_NOTIFICATION,
  REMOVE_SELECTED_NOTIFICATION
} from './Notifications.constants'
import { INotificationsItem } from './Notifications.model'
import { countNewNotifications } from './libs/helpers/helpers'

type IInitialState = {
  data: INotificationsItem[]
  selected: INotificationsItem | null
  error: string
  loading: boolean
  totalQuantity: number
}

export const initialBasketState: IInitialState = {
  data: [],
  selected: null,
  error: '',
  loading: false,
  totalQuantity: 0
}

export default (state = initialBasketState, action: IAction) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload as INotificationsItem[],
        totalQuantity: countNewNotifications(action.payload),
        loading: false
      }
    }

    case ADD_NOTIFICATION: {
      return {
        ...state,
        data: [action.payload as INotificationsItem, ...state.data],
        totalQuantity: state.totalQuantity + 1
      }
    }

    case SELECT_NOTIFICATION: {
      return {
        ...state,
        selected: action.payload as INotificationsItem,
        data: state.data.map(el => (el.id === action.payload.id ? { ...el, isViewed: true } : el)),
        totalQuantity: countNewNotifications(state.data, action.payload.id)
      }
    }

    case REMOVE_SELECTED_NOTIFICATION: {
      return {
        ...state,
        selected: null
      }
    }

    case REMOVE_NOTIFICATION: {
      return {
        ...state,
        data: state.data.filter(el => el.id !== action.payload),
        totalQuantity: countNewNotifications(state.data, action.payload)
      }
    }
    case GET_NOTIFICATIONS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    }

    default:
      return state
  }
}
