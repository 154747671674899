import { TFieldConfigRow } from 'components/UiKit/FieldFactory/FieldFactory.model'
import styles from '../../AuthRoute.module.scss'

export const fieldsConfig = (): TFieldConfigRow[] => {
  const nameRow: TFieldConfigRow = {
    items: [
      {
        name: 'fullName',
        label: 'Name',
        required: true,
        minLength: 2,
        inputWrapperClassName: styles['description-name'],
        component: () => {
          return {
            type: 'input',
            props: {
              variant: 'outlined',
              placeholder: 'Name',
              className: styles['input']
            }
          }
        }
      }
    ]
  }

  const emailRow: TFieldConfigRow = {
    items: [
      {
        name: 'email',
        label: 'Email',
        required: true,
        isEmail: true,
        inputWrapperClassName: styles['description-name'],
        component: () => {
          return {
            type: 'input',
            props: {
              type: 'email',
              variant: 'outlined',
              placeholder: 'Email',
              className: styles['input']
            }
          }
        }
      }
    ]
  }

  return [nameRow, emailRow]
}
