import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styles from '../VerificationAdmin.module.scss'
import { TUserVerifiinfoProps } from './UserVerifiinfo.model'
import Loading from '../../../../../components/Spinner'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { DatePicker } from 'components/UiKit/DatePicker'
import { Field, Form } from 'react-final-form'
import moment from 'moment'
import { formatterDateForDatePicker } from 'components/UiKit/Filter/Filter'
import BackButton from 'components/BackButton/BackButton'

const UserVerifiinfo: FC<TUserVerifiinfoProps> = ({
  user,
  loading,
  loadingOffer,
  getUserProfileInfo,
  createOffer,
  claimsList,
  getClaims,
  offerId
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const [openedPhoto, setOpenPhoto] = useState('')
  const [selectedUserAge, setSelectedUserAge] = useState(0)

  const onSubmit = (values: any) => {
    createOffer(values.DOBDate || birth, params.id, Math.round(+values.expireDate))
  }
  const format = 'dd.MM.yy'

  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)

  const initialValues = {
    userAge: ''
  }

  const loadings = loadingOffer || loading

  const isClaims =
    claimsList.filter((claim: any) => claim.credentialSubject.id === user.did).length === 0

  const diff_years = (dt2: any, dt1: any) => {
    let diff = (dt2 - dt1) / 1000
    diff /= 60 * 60 * 24
    setSelectedUserAge(Math.abs(Math.floor((diff * -1) / 365.25)))
  }

  const expireDate = {
    date: ''
  }

  const dayOfBirthDate = {
    date: ''
  }

  const params: any = useParams()
  const birth = user.dateOfBirth && new Date(user.dateOfBirth).getTime()
  const today = new Date().getTime()

  useEffect(() => {
    getUserProfileInfo(params.id, 'main-info', true, true)
    getClaims()
  }, [offerId])
  console.log(isClaims, claimsList, user.did)
  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>{words['admin.subcontract.verification']}</h1>
      </section>
      <BackButton />
      {loadings ? (
        <div className={styles.loadingWrapper}>
          <Loading />
        </div>
      ) : (
        <>
          <p>Please verify {user.fullName}</p>
          {isClaims ? (
            <Form
              initialValues={initialValues}
              onSubmit={values => {
                onSubmit(values)
              }}
            >
              {({ form, handleSubmit }) => (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <p>Selected user age: {selectedUserAge}</p>
                  <div className={styles.inputs}>
                    <Field name={'DOBDate'}>
                      {({ input }) => {
                        const firstDate = form.getState().values['DOBDate']
                        return (
                          <DatePicker
                            locale={currentLanguage}
                            name={input.name}
                            autoComplete="off"
                            strictParsing
                            placeholderText={'DOB Date'}
                            dateFormat={format}
                            required
                            value={input.value || null}
                            selected={input.value || null}
                            onChange={event => {
                              if (event) {
                                const timeReset = moment(event)
                                  .set({
                                    hour: 23,
                                    minute: 59,
                                    second: 59,
                                    millisecond: 999
                                  })
                                  .valueOf()
                                form.change(input.name, formatterDateForDatePicker(timeReset))
                                diff_years(moment(event).valueOf(), today)
                              }
                              if (event === null) {
                                form.change(input.name, '')
                              }
                            }}
                            selectsEnd
                            endDate={
                              (firstDate && new Date(firstDate)) || dayOfBirthDate.date || ''
                            }
                            maxDate={new Date() || ''}
                          />
                        )
                      }}
                    </Field>
                    <Field name={'expireDate'}>
                      {({ input }) => {
                        const firstDate = form.getState().values['expireDate']
                        return (
                          <DatePicker
                            locale={currentLanguage}
                            name={input.name}
                            autoComplete="off"
                            strictParsing
                            placeholderText={'Expiration Date'}
                            dateFormat={format}
                            required
                            value={input.value || null}
                            selected={input.value || null}
                            onChange={event => {
                              if (event) {
                                const timeReset = moment(event)
                                  .set({
                                    hour: 23,
                                    minute: 59,
                                    second: 59,
                                    millisecond: 999
                                  })
                                  .valueOf()
                                form.change(input.name, formatterDateForDatePicker(timeReset))
                              }
                              if (event === null) {
                                form.change(input.name, '')
                              }
                            }}
                            selectsEnd
                            endDate={(firstDate && new Date(firstDate)) || expireDate.date || ''}
                            minDate={new Date() || ''}
                          />
                        )
                      }}
                    </Field>
                  </div>
                  <BaseButton size={'md'} type="submit" disabled={loadings}>
                    Create Claim
                  </BaseButton>
                </form>
              )}
            </Form>
          ) : null}
          <div className={styles['user_idcard_photo']}>
            {user.userfiles &&
              user.userfiles.map((photo: any, i: number) => {
                return (
                  <div key={i} onClick={() => setOpenPhoto(photo.link)}>
                    <p>{photo.name.slice(0, 12)}...</p>
                  </div>
                )
              })}
          </div>
        </>
      )}
      {openedPhoto && (
        <div className={styles['photo-modal']} onClick={() => setOpenPhoto('')}>
          {/* <button onClick={() => setOpenPhoto('')}>close</button> */}
          <img src={openedPhoto} />
        </div>
      )}
    </div>
  )
}

export default UserVerifiinfo
