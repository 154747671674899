import React, { FC } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { IAccordionItem } from './model'
import cls from 'classnames'
import styles from './style.module.scss'

const AccordionItem: FC<IAccordionItem> = ({ to, title, isActive }) => (
  <li className={styles.accordion_li}>
    <NavLink
      exact={true}
      className={cls(styles.accordion_a, { [styles.accordion_a_active]: isActive })}
      to={to}
    >
      {title}
    </NavLink>
  </li>
)

export default withRouter(AccordionItem)
