/* eslint-disable no-console */
import { Dispatch } from 'redux'
import { API, api } from '../../../services/api'
import {
  getTaskRequest,
  getTaskSuccess,
  getTaskError,
  clearTaskList,
  getUsersSuccess,
  getOneTaskSuccess,
  updateTaskSuccess,
  clearUsersList,
  clearOneTask,
  clearAllTasks,
  changeTaskStatus,
  getInvoicesSuccess,
  clearInvoiceSuccess,
  getCancelledTasksRequest,
  getCancelledTasksSuccess
} from './Subcontract.actions'
import toastr from '../../../utils/toastr'
import { TState } from 'store'
import { AxiosError } from 'axios'
import { ITaskCancellation } from './Subcontract.model'
import { ETaskStatus } from './components/CreateTask/CreateTask.model'

export const getTasks = (
  limit: number,
  page: any,
  url: string,
  filter: string = '',
  isClear: boolean = false,
  paginationLoading: boolean = false
) => async (dispatch: Dispatch, getData: () => TState): Promise<void> => {
  dispatch(getTaskRequest(paginationLoading))
  const words = getData().global.language.words

  try {
    const path = url === '/tasks/own?status=1&' || url === '/tasks?planning=false&' ? '' : '?'
    const checkAccess = await api.get(`/google-calendar/check-access`)
    const resp = await api.get(`${API.URL}${url}${path}page=${page}${filter}&limit=${limit}`)
    const tasks = resp.data.payload.result.map((task: any) => {
      return {
        ...task,
        tokenStatus: checkAccess.data.status
      }
    })
    console.log('gettes', resp, limit)
    if (
      url !== getData().client.subcontract.urlFor ||
      isClear ||
      filter !== getData().client.subcontract.filter ||
      page === '1'
    ) {
      dispatch(clearTaskList())
    }
    dispatch(
      getTaskSuccess({
        tasks,
        url: url,
        page: page,
        pageCount: resp.data.payload.pageCount,
        filter: filter,
        itemsCount: resp.data.payload.totalItems,
        paginationLoading: paginationLoading ? !paginationLoading : paginationLoading
      })
    )
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(getTaskError(err.response))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}

export const getCancelledTasks = (page: number, limit: number, status: ETaskStatus) => async (
  dispatch: Dispatch
) => {
  dispatch(getCancelledTasksRequest())
  try {
    const { data } = await api.get(
      `${API.URL}/tasks/cancelled?page=${page}&limit=${limit}&status=${status}`
    )
    dispatch(getCancelledTasksSuccess(data))
  } catch (err) {
    console.log(err)
  }
}

export const getUsers = (
  limit: number,
  page: any,
  url: string,
  filter: string = '',
  isClear: boolean = false,
  paginationLoading: boolean = false
) => async (dispatch: Dispatch, getData: () => TState): Promise<void> => {
  dispatch(getTaskRequest(paginationLoading))
  const words = getData().global.language.words

  try {
    const resp = await api.get(`${API.URL}${url}?page=${page}${filter}&limit=${limit}`)
    if (
      url !== getData().client.subcontract.urlFor ||
      isClear ||
      filter !== getData().client.subcontract.filter ||
      page === '1'
    ) {
      dispatch(clearUsersList())
    }
    dispatch(
      getUsersSuccess({
        users: resp.data.payload.results,
        url: url,
        page: page,
        pageCount: resp.data.payload.pageCount,
        filter: filter,
        itemsCount: resp.data.payload.total,
        paginationLoading: paginationLoading ? !paginationLoading : paginationLoading
      })
    )
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(getTaskError(err.response))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}

export const getTask = (id: number, general: boolean) => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  console.log(general)
  dispatch(getTaskRequest())
  const words = getData().global.language.words
  // const ownerId = getData().auth.data.id

  try {
    const gesp = await api.get(`${API.URL}/tasks/${id}`)
    const joi = await api.get(`/google-calendar/check-access`)
    gesp.data.payload.result.tokenStatus = joi.data.status
    const resp = gesp
    // if (resp.data.payload.result.customer.id === ownerId || general) {
    dispatch(getOneTaskSuccess(resp.data.payload.result))
    // } else {
    //   throw new Error('This task is unavailable')
    // }
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(getTaskError(err.response))
    toastr('error', words['REQUEST_NOT_FOUND'], err)
  }
}

export const createInvoice = (invoice: any) => async (): Promise<void> => {
  try {
    await api.post(`${API.URL}/invoices`, invoice)
    toastr('success', 'InvoiceComponent created')
  } catch (error) {
    console.log(error)
  }
}

export const getInvoiceByTask = (taskId: any) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const { data } = await api.get(`${API.URL}/invoices/task/${taskId}`)
    dispatch(getInvoicesSuccess(data))
  } catch (error) {
    console.log(error)
  }
}

export const getProjectInvoices = (taskId: number) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const { data } = await api.get(`${API.URL}/invoices/project/${taskId}`)
    dispatch(getInvoicesSuccess(data))
  } catch (error) {
    console.log(error)
  }
}

export const getUserProjectInvoices = () => async (dispatch: Dispatch): Promise<void> => {
  try {
    const { data } = await api.get(`${API.URL}/invoices/project`)
    dispatch(getInvoicesSuccess(data))
  } catch (error) {
    console.log(error)
  }
}
// export const getInvoice = (taskId: any) => async (dispatch: Dispatch): Promise<void> => {
//  try {
//    const { data } = await api.get(`${API.URL}/invoices/${taskId}`)
//    dispatch(getInvoicesSuccess(data))
//  } catch (error) {}
// }

export const updateTaskInfo = (id: any, body: any) => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  const words = getData().global.language.words
  dispatch(getTaskRequest())
  try {
    const { data } = await api.put(`${API.URL}/tasks/${id}`, body)

    dispatch(updateTaskSuccess(data.payload.result))
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(getTaskError(err))
    toastr('error', words['REQUEST_NOT_FOUND'], err)
  }
}

export const clearStore = () => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  const words = getData().global.language.words
  dispatch(getTaskRequest())
  try {
    dispatch(clearTaskList())
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(getTaskError(err))
    toastr('error', words['REQUEST_NOT_FOUND'], err)
  }
}

export const clearTask = (isAll = false) => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  const words = getData().global.language.words
  dispatch(getTaskRequest())
  try {
    dispatch(clearOneTask())
    if (isAll) {
      dispatch(clearAllTasks())
    }
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(getTaskError(err))
    toastr('error', words['REQUEST_NOT_FOUND'], err)
  }
}

export const createTaskCancellation = (
  payload: Partial<ITaskCancellation>,
  taskId: number
) => async (dispatch: Dispatch) => {
  try {
    await api.post(`${API.URL}/task-cancellation/create`, payload)
    dispatch(changeTaskStatus({ ...payload, id: taskId }))
  } catch (error) {
    console.log(error)
  }
}

export const updateTaskCancellation = (payload: Partial<ITaskCancellation>, id: number) => async (
  dispatch: Dispatch
) => {
  try {
    await api.put(`${API.URL}/task-cancellation/${id}`, payload)
    dispatch(changeTaskStatus({ ...payload, id }))
  } catch (error) {
    console.log(error)
  }
}

export const clearInvoice = () => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(clearInvoiceSuccess())
  } catch (error) {
    console.log(error)
  }
}
