import React, { ChangeEvent, KeyboardEvent, useMemo, useState } from 'react'
import { IToken } from 'screens/admin/Token/Token.model'
import cls from 'classnames'
import styles from './SettingsSubcontract.module.scss'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { Input } from 'components/UiKit/Inputs'
import { Icons } from 'components/Icons'
import { filteredTokensByValue } from 'helpers/filteredTokensByValue'
import { getOnlyUniqueItems } from 'helpers/getOnlyUniqueItems'
import Button from 'components/Button'
import { convertTokenWord } from 'helpers/convertTokenWord'
import { fetchOneToken } from 'screens/admin/Token/Token.thunx'
import Spinner from 'components/Spinner'
import { AlertNotification } from 'components/AlertNotification'
import toastr from 'utils/toastr'
import { useSelector } from 'react-redux'
import { TState } from 'store'

interface ISettingsSubcontract {
  tokens: IToken[]
  allTokens: IToken[]
  changeAdminTokenStatusThunk: (value: IToken) => void
  addToken: (token: IToken) => void
}

export const SettingsSubcontract: React.FC<ISettingsSubcontract> = ({
  tokens,
  allTokens,
  changeAdminTokenStatusThunk,
  addToken
}) => {
  const [valueChoseToken, setValueChoseToken] = useState('')
  const [valueSearchToken, setValueSearchToken] = useState('')
  const [currentToken, setCurrentToken] = useState<IToken | null>(null)
  const [isActive, setIsActive] = useState(true)
  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [isLoadingToken, setIsLoadingToken] = useState(false)
  const words = useSelector((state: TState) => state.global.language.words)

  const filteredCustomTokens = useMemo(() => {
    const customTokens = tokens.filter(token => token.isCustom)

    const filteredTokensByName = filteredTokensByValue(customTokens, valueChoseToken, 'name')
    const filteredTokensByAddress = filteredTokensByValue(customTokens, valueChoseToken, 'address')

    return [...filteredTokensByName, ...filteredTokensByAddress].filter(getOnlyUniqueItems)
  }, [tokens, valueChoseToken])

  const filteredMainTokens = useMemo(() => {
    const mainTokens = tokens.filter(token => !token.isCustom)

    const filteredTokensByName = filteredTokensByValue(mainTokens, valueChoseToken, 'name')
    const filteredTokensByAddress = filteredTokensByValue(mainTokens, valueChoseToken, 'address')

    return [...filteredTokensByName, ...filteredTokensByAddress].filter(getOnlyUniqueItems)
  }, [tokens, valueChoseToken])

  const handleFilterTokens = (event: ChangeEvent<HTMLInputElement>): void => {
    setValueChoseToken(event.target.value.trimStart())
  }

  const handleFindToken = (event: ChangeEvent<HTMLInputElement>): void => {
    setValueSearchToken(event.target.value.trimStart())
  }

  const handleOpenAddModal = () => {
    setIsOpenAddModal(!isOpenAddModal)
  }

  const handleKeyPress = async (event: KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (!valueSearchToken.trim()) return

      setIsLoadingToken(true)
      setCurrentToken(null)

      const token = await fetchOneToken(valueSearchToken)
      const findedToken: IToken | null =
        (token && allTokens.find(item => item.address === token.address)) || null

      if (!findedToken || !('isAdminToken' in findedToken)) {
        setCurrentToken(token)
      }

      if (findedToken && !findedToken.isAdminToken) {
        setCurrentToken(findedToken)
      }

      if (findedToken && findedToken.isAdminToken) {
        setCurrentToken(null)
        toastr('error', words['admin.settings.tokenExist'])
      }

      setValueSearchToken('')
      setIsLoadingToken(false)
    }
  }

  const handleChangeTokenStatus = (token: IToken) => {
    try {
      changeAdminTokenStatusThunk(token)
    } catch (err) {
      console.log(err)
    }
  }

  const handleChangeCheckbox = () => {
    setIsActive(!isActive)
  }

  const handleAddToken = (event: any): void => {
    const mouseEvent = event as MouseEvent
    mouseEvent.preventDefault()
    setIsLoadingToken(true)

    try {
      if (currentToken) {
        addToken({ ...currentToken, userId: 0 })
        setCurrentToken(null)
        toastr('success', words['admin.settings.tokenImported'])
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoadingToken(false)
    }
  }

  return !isOpenAddModal ? (
    <div className={styles.modal}>
      <div className={styles.content}>
        <h3 className={styles.titleMargin}>{words['admin.settings.tokenManagement']}</h3>
        <div className={styles.fieldWrapperMargin}>
          <Input
            type="text"
            name="tokenAddress"
            placeholder="Search name or paste address"
            value={valueChoseToken}
            onChange={handleFilterTokens}
            variant="outlined"
          />
        </div>

        <div className={styles.tokensWrapper}>
          <div className={styles.insideTokensWrapper}>
            {filteredCustomTokens.length > 0 && (
              <>
                <div className={styles.subTitle}>Custom tokens</div>
                <div className={styles.customTokens}>
                  {filteredCustomTokens.map(token => {
                    if (token.isActive || (!isActive && !token.isActive)) {
                      return (
                        <div key={token.address} className={styles.tokenItem}>
                          <div className={styles.tokenItemWrapper}>
                            <img
                              src={token.logoURI}
                              alt="Token image"
                              className={cls({
                                [styles.tokenImage]: true,
                                [styles['gray-img']]: !token.isActive
                              })}
                            />

                            <div className={styles.tokenItemContent}>
                              <div
                                className={cls({
                                  [styles.tokenItemTitle]: true,
                                  [styles['is-activated']]: !token.isActive
                                })}
                              >
                                {token.symbol}
                              </div>

                              <div className={styles.tokenItemDescription}>{token.name}</div>
                            </div>
                          </div>

                          {token.isActive ? (
                            <div
                              className={styles.deactivatedButton}
                              onClick={() => handleChangeTokenStatus(token)}
                            >
                              <Icons icon="redBucket" />
                            </div>
                          ) : (
                            <div
                              className={styles.activatedButton}
                              onClick={() => handleChangeTokenStatus(token)}
                            >
                              <Icons icon="restoreIcon" />
                            </div>
                          )}
                        </div>
                      )
                    } else {
                      return null
                    }
                  })}
                </div>
              </>
            )}

            {filteredMainTokens.length > 0 && (
              <>
                <div className={styles.subTitle}>Main tokens</div>
                <div className={styles.mainTokens}>
                  {filteredMainTokens.map(token => {
                    if (token.isActive || (!isActive && !token.isActive)) {
                      return (
                        <div key={token.address} className={styles.tokenItem}>
                          <div className={styles.tokenItemWrapper}>
                            <img
                              src={token.logoURI}
                              alt="Token image"
                              className={cls({
                                [styles.tokenImage]: true,
                                [styles['gray-img']]: !token.isActive
                              })}
                            />

                            <div className={styles.tokenItemContent}>
                              <div
                                className={cls({
                                  [styles.tokenItemTitle]: true,
                                  [styles['is-activated']]: !token.isActive
                                })}
                              >
                                {token.symbol}
                              </div>

                              <div className={styles.tokenItemDescription}>{token.name}</div>
                            </div>
                          </div>

                          {token.isActive ? (
                            <div
                              className={styles.deactivatedButton}
                              onClick={() => handleChangeTokenStatus(token)}
                            >
                              <Icons icon="redBucket" />
                            </div>
                          ) : (
                            <div
                              className={styles.activatedButton}
                              onClick={() => handleChangeTokenStatus(token)}
                            >
                              <Icons icon="restoreIcon" />
                            </div>
                          )}
                        </div>
                      )
                    } else {
                      return null
                    }
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className={styles.actions}>
        <div className={styles.checkWrapper}>
          <Input
            name="checkbox"
            type="checkbox"
            label={words['admin.settings.tokenManagement']}
            className={'margin'}
            onClick={handleChangeCheckbox}
          />
        </div>

        <BaseButton
          children={words['admin.settings.add']}
          size="mds"
          color="default"
          variant="small"
          onClick={handleOpenAddModal}
        />
      </div>
    </div>
  ) : (
    <div className={styles.addModal}>
      <div>
        <div className={styles.closeAddModal} onClick={handleOpenAddModal}>
          <Icons icon="close" />
        </div>
        <div className={styles.title}>{words['admin.settings.addToken']}</div>
        <div className={styles.fieldWrapper}>
          <Input
            type="text"
            name="tokenAddress"
            placeholder="Search name or paste address"
            value={valueSearchToken}
            onChange={handleFindToken}
            variant="outlined"
            onKeyDown={handleKeyPress}
          />
        </div>

        {isLoadingToken && (
          <div className={styles.spinnerWrapper}>
            <Spinner size="button" />
          </div>
        )}

        <div className={styles.tokenItem}>
          {currentToken && (
            <>
              <div className={styles.tokenItemWrapper}>
                <img src={currentToken.logoURI} alt="Token image" className={styles.tokenImage} />

                <div className={styles.tokenItemContent}>
                  <div className={styles.tokenItemTitle}>{currentToken.symbol}</div>

                  <div className={styles.tokenItemDescription}>{currentToken.name}</div>
                </div>
              </div>
              <Button
                className={'btn-import'}
                children={words['admin.settings.import']}
                onClick={handleAddToken}
              />
            </>
          )}
        </div>
        <div className={styles.alertWrapper}>
          <AlertNotification />
        </div>
      </div>
      <div className={styles.totalTokens}>{convertTokenWord(filteredMainTokens.length)}</div>
    </div>
  )
}
