import React from 'react'
import styles from './Executor.module.scss'
import { useSelector } from 'react-redux'
import { ReactComponent as DefaultAvatar } from 'assets/images/default_avatar.svg'
import { TState } from 'store'
import { TTask } from '../../../Task/Task.model'

type TProps = {
  isExecutor: boolean
  changeExecutor: () => void
  currentTask: TTask
}

const Executor = ({ changeExecutor, isExecutor, currentTask }: TProps) => {
  const userId = useSelector((state: TState) => state.auth.data.id)
  return (
    <div className={styles.executorWrapper}>
      {currentTask && currentTask.customer.id === userId ? (
        <>
          <h2 className={styles.executorTitle}>Календарь исполнителя</h2>
          <div className={styles.executorInner}>
            <label className={styles.container}>
              <div className={styles.executorNameAvatar}>
                {currentTask && currentTask.executor && currentTask.executor.photo ? (
                  <img className={styles.avatar} src={currentTask.executor.photo} alt="avatar" />
                ) : (
                  <DefaultAvatar className={styles.avatar} />
                )}
                <p>{currentTask.executor && currentTask.executor.fullName}</p>
              </div>
              <input type="checkbox" checked={isExecutor} onChange={changeExecutor} />
              <span className={styles.checkmark} />
            </label>
          </div>
        </>
      ) : (
        <>
          <h2 className={styles.executorTitle}>Календарь заказчика</h2>
          <div className={styles.executorInner}>
            <label className={styles.container}>
              <div className={styles.executorNameAvatar}>
                {currentTask && currentTask.customer.photo ? (
                  <img className={styles.avatar} src={currentTask.customer.photo} alt="avatar" />
                ) : (
                  <DefaultAvatar className={styles.avatar} />
                )}
                <p>{currentTask && currentTask.customer.fullName}</p>
              </div>
              <input type="checkbox" checked={isExecutor} onChange={changeExecutor} />
              <span className={styles.checkmark} />
            </label>
          </div>
        </>
      )}
    </div>
  )
}
export default Executor
