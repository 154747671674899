import { TSelectOption } from '../../../components/Select'
import { TWords } from 'elements/SideBar/SideBar.config'
import { TBaseFilterConfigData } from '../../../components/UiKit/Filter/Filter'

// add localization
export const getVacanciesFilterConfig = ({
  rank,
  typeOfEmployment,
  projectOptions,
  positions,
  words,
  displayAllFields,
  managersOptions,
  englishLevel,
  statusOptions,
  fromProject
}: {
  positions: any
  rank: any
  typeOfEmployment: any
  projectOptions: any
  role: number
  usersData?: TSelectOption[]
  managersOptions?: any
  minFromDate: string | number
  maxToDate: string | number
  displayAllFields?: boolean
  englishLevel?: any
  statusOptions?: any
  words: TWords
  fromProject?: boolean
}): any[] => {
  const countOfHoursData = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' }
  ]
  let filter: TBaseFilterConfigData[] = []
  if (!fromProject) {
    filter = [
      {
        name: 'title',
        type: 'select',
        fieldData: positions,
        label: words['user.subcontract.taskFilter.specialization'],
        placeholder: '',
        width: '33.3%'
      },
      {
        name: 'rank',
        type: 'select',
        fieldData: rank,
        label: words['user.profile.career.level'],
        placeholder: '',
        width: '13.3%'
      },
      {
        name: 'typeOfEmployment',
        type: 'select',
        fieldData: typeOfEmployment,
        label: words['user.profile.career.occupation'],
        placeholder: '',
        width: '13.3%'
      },
      {
        name: 'project',
        type: 'select',
        fieldData: projectOptions,
        label: words['admin.vacancies.project'],
        placeholder: '',
        width: '30.8%'
      }
    ]
  } else {
    filter = [
      {
        name: 'title',
        type: 'select',
        fieldData: positions,
        label: words['user.subcontract.taskFilter.specialization'],
        placeholder: words['user.subcontract.taskFilter.specialization'],
        width: '33.3%'
      },
      {
        name: 'rank',
        type: 'select',
        fieldData: rank,
        label: words['user.profile.career.level'],
        placeholder: words['user.profile.career.level'],
        width: '13.3%'
      },
      {
        name: 'typeOfEmployment',
        type: 'select',
        fieldData: typeOfEmployment,
        label: words['user.profile.career.occupation'],
        placeholder: words['user.profile.career.occupation'],
        width: '13.3%'
      },
      {
        name: 'location',
        type: 'select',
        fieldData: [],
        label: 'Локация',
        placeholder: 'Локация',
        width: '23%'
      }
    ]
  }

  if (displayAllFields) {
    if (!fromProject) {
      filter.push(
        {
          name: 'location',
          type: 'select',
          fieldData: [],
          label: 'Локация',
          placeholder: 'Локация',
          width: '23%'
        },
        {
          name: 'dateOfClosingFrom',
          secondName: 'dateOfClosingTo',
          type: 'dateRangeNew',
          placeholder: '',
          fieldData: [{ value: '@ALL@', label: words['all'] }],
          label: 'Дата закрытия',
          width: '47%',
          selectRangeBoundsIndex: {
            min: Date.now(),
            max: Date.now()
          }
        },
        {
          name: 'recruiter',
          type: 'select',
          fieldData: managersOptions,
          label: 'Рекрутер',
          placeholder: 'Рекрутер',
          width: '34%'
        },
        {
          name: 'levelOfEnglish',
          type: 'select',
          fieldData: englishLevel,
          label: 'Уровень английского',
          placeholder: '',
          width: '13%'
        },
        {
          name: 'countOfHours',
          type: 'select',
          fieldData: countOfHoursData,
          label: 'Кол-во часов в день',
          placeholder: '',
          width: '13%'
        },
        {
          name: 'status',
          type: 'select',
          fieldData: statusOptions,
          label: 'Статус',
          placeholder: '',
          rangeContainerWidth: '50%',
          width: '13%'
        }
      )
    } else {
      filter.push(
        {
          name: 'dateOfClosingFrom',
          secondName: 'dateOfClosingTo',
          type: 'dateRangeNew',
          placeholder: '',
          fieldData: [{ value: '@ALL@', label: words['all'] }],
          label: 'Дата закрытия',
          width: '100%',
          rangeContainerWidth: '20%',
          selectRangeBoundsIndex: {
            min: Date.now(),
            max: Date.now()
          }
        },
        {
          name: 'recruiter',
          type: 'select',
          fieldData: [{ value: '@ALL@', label: words['all'] }],
          label: 'Рекрутер',
          placeholder: 'Рекрутер',
          width: '23%'
        },
        {
          name: 'status',
          type: 'select',
          fieldData: statusOptions,
          label: 'Статус',
          placeholder: '',
          width: '23%'
        }
      )
    }
  }
  return filter
}
