import React, { useRef } from 'react'
import styles from './TableInvoice.module.scss'
import InputInvoice from '../InputInvoice/InputInvoice'
import { FormApi, FormState } from 'final-form'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { TChecked } from 'screens/admin/Entrepreneurs/Entrepreneurs.model'
import { getBankName } from 'screens/admin/Entrepreneurs/Helpers/getBankName'

type TTableInvoiceProps = {
  form: FormApi
  formState: FormState<any>
  entrepreneurs: TChecked[]
  setIsScrolledToBottom: (value: boolean) => void
}

const TableInvoice = ({
  form,
  entrepreneurs,
  setIsScrolledToBottom,
  formState
}: TTableInvoiceProps) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const entrepreneursRef = useRef<HTMLDivElement>(null)
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    if (target.scrollTop + target.clientHeight === target.scrollHeight) {
      setIsScrolledToBottom(true)
    } else {
      setIsScrolledToBottom(false)
    }
  }

  return (
    <>
      <div className={styles['table-header-wrapper']}>
        <div className={styles['table-header']}>{words['admin.entrepreneurs.fullName']}</div>
        <div className={styles['table-header']}>{words['admin.entrepreneurs.bank']}</div>
        <div className={styles['table-header']}>{words['entrepreneur.invoices.amount']}</div>
      </div>
      <div className={styles['table-body-inner']} ref={entrepreneursRef} onScroll={handleScroll}>
        {entrepreneurs.length ? (
          entrepreneurs.map((entrepreneur: TChecked) => {
            return (
              <div key={entrepreneur.id} className={styles['table-body-wrapper']}>
                <div className={styles['table-body']}>{entrepreneur.fullName}</div>
                <div className={styles['table-body']}>{getBankName(entrepreneur)}</div>
                <div className={styles['table-body']}>
                  <InputInvoice
                    form={form}
                    name={`name${entrepreneur.id}`}
                    placeholder={formState.values.currency}
                  />
                </div>
              </div>
            )
          })
        ) : (
          <span className={styles['table-no-data']}>{words['admin.entrepreneurs.emptyList']}</span>
        )}
      </div>
    </>
  )
}
export default TableInvoice
