import React, { FC, useRef } from 'react'
import { generatePDF } from '../../Helpers/getPDF'
import invoiceIcon from '../../../../../assets/images/invoice_pdf_icon.svg'
import Offer from '../Offer/Offer'
interface DownloadInvoiceProps {
  d?: any
  componentRef: any
}
const DownloadInvoice: FC<DownloadInvoiceProps> = ({ d }) => {
  const downloadRef = useRef<any>(null)
  const handleDownloadClick = (e: any) => {
    e.stopPropagation()
    generatePDF(downloadRef, d.id)
  }
  return (
    <>
      <div style={{ cursor: 'pointer' }} onClick={(e: any) => handleDownloadClick(e)}>
        <img src={invoiceIcon} alt="invoice" />
      </div>
      <Offer isHidden={true} invoice={d} ref={downloadRef} />
    </>
  )
}

export default DownloadInvoice
