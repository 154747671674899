import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { getManagers, getProjects } from './Projects.thunx'
import { TState } from 'store'

import ProjectsComponent from './Projects'

export const mapStateToProps = (state: TState) => {
  return {
    displayAllFields: state.client.filter.multilineFilterFields.status,
    data: state.admin.projects.data.payload,
    loading: state.admin.projects.loading,
    managers: state.admin.projects.managers
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getProjects, getManagers }, dispatch)

export const Projects = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsComponent)
