import React from 'react'
import Checkbox from '../../../UiKit/Checkbox/Checkbox'
import { TState } from '../../../../store'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteSelectedMember,
  setSelectedAllMembers,
  setSelectedMembers
} from '../CurrentTeam.actions'

const SelectMember = ({ name, member }: any) => {
  const dispatch = useDispatch()
  const projectMembers = useSelector((state: TState) => state.admin.currentTeam.members)
  const selectedMembers = useSelector((state: TState) => state.admin.currentTeam.selectedMembers)
  const handleSelect = () => {
    const members = selectedMembers.filter((item: any) => member.id === item.id)
    if (name === 'selectAll' && selectedMembers.length === members.length) return true
    if (name === 'selectAll') return false
    return Boolean(members.length)
  }
  handleSelect()
  const handleCheck = (e: any) => {
    if (e.target.checked) {
      if (name === 'selectAll') {
        dispatch(setSelectedAllMembers(projectMembers))
      } else {
        dispatch(setSelectedMembers(member))
      }
    } else {
      dispatch(deleteSelectedMember(member.id))
    }
  }
  return (
    <div style={{ maxWidth: '40px' }}>
      <Checkbox checked={handleSelect()} onChange={(e: any) => handleCheck(e)} name={name} />
    </div>
  )
}

export default SelectMember
