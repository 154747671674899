import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'
import InterviewInvitationComponent from './InterviewInvitation'
import {
  sendInterviewInvitation,
  getTime,
  addEventToGoogleCalendar,
  getInvitedUser
} from './InterviewInvitation.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    time: state.client.interview.time,
    data: state.client.interview.data,
    userEmail: state.auth.data.email,
    invitedUser: state.client.interview.invitedUser
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { sendInterviewInvitation, getTime, addEventToGoogleCalendar, getInvitedUser },
    dispatch
  )

export const InterviewInvitation = connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewInvitationComponent)
