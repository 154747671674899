export function convertTokenWord(numberOfTokens: number): string {
  let word = ''

  if (numberOfTokens === 0) {
    word = ' импортированных токенов'
  } else if (numberOfTokens === 1) {
    word = ' импортированный токен'
  } else if (numberOfTokens >= 2 && numberOfTokens <= 4) {
    word = ' импортированных токена'
  } else if (numberOfTokens >= 5) {
    word = ' импортированных токенов'
  }

  return numberOfTokens + word
}
