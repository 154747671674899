import { IAction } from 'models'
import {
  CREATE_TASK_REQUEST,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_ERROR,
  GET_TASK_SUCCESS
} from './CreateTask.constants'

export type IInitialState = {
  error: string
  loading: boolean
  task: any
}

export const initialState: IInitialState = {
  error: '',
  loading: false,
  task: {}
}

export default (state = initialState, action: IAction): any => {
  switch (action.type) {
    case CREATE_TASK_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case CREATE_TASK_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false
      }
    case CREATE_TASK_ERROR:
      return {
        ...state,
        error: action.payload.data.error,
        loading: false
      }
    case GET_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        task: action.payload
      }
    default:
      return state
  }
}
