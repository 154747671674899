import { API, api } from '../../../../services/api'
import { AxiosError } from 'axios'
import { Dispatch } from 'redux'
import { setProjectOptions, setStatusOptions } from '../Vacancies.actions'
import { TState } from '../../../../store'

export function setVacancy(body: any) {
  return async function() {
    try {
      await api.post(`${API.URL}/vacancies`, body)
    } catch (error) {
      const err = error as AxiosError<any>
      console.log(err)
    }
  }
}

export const getProjectOptions = () => async (dispatch: Dispatch) => {
  try {
    const projects = (await api.get(`${API.URL}/projects`)).data.payload.results.map(
      (item: any) => {
        return {
          value: String(item.id),
          label: item.name
        }
      }
    )
    dispatch(setProjectOptions(projects))
  } catch (error) {
    const err = error as AxiosError<any>
    console.log(err)
  }
}

export const getStatusOptions = () => async (dispatch: Dispatch, getData: () => TState) => {
  const words = getData().global.language.words
  try {
    const status = (await api.get(`${API.URL}/vacancies-status`)).data.payload.result.map(
      (item: any) => {
        return {
          value: String(item.id),
          label: words[item.name]
        }
      }
    )
    dispatch(setStatusOptions(status))
  } catch (error) {
    const err = error as AxiosError<any>
    console.log(err)
  }
}
