import React, { FC, useState } from 'react'
import { TState } from 'store'
import { useSelector } from 'react-redux'
import styles from './EditVacancyBtn.module.scss'
import { ReactComponent as DotsBtn } from 'assets/images/icon_dots_vertical.svg'
import { useHistory, useParams } from 'react-router'
type TEditMenuProps = {
  deleted: Date | null
}

export const EditVacancyBtn: FC<TEditMenuProps> = ({ deleted }) => {
  const [open, setOpen] = useState(false)
  const [modalIsOpen, setModalOpen] = useState(false)
  const words = useSelector((state: TState) => state.global.language.words)
  const history = useHistory()
  const toggleOpen = () => setOpen(!open)
  const toggleModal = () => setModalOpen(!modalIsOpen)
  const params: any = useParams()
  return (
    <>
      <div className={styles.container}>
        {deleted && <div className={styles['archived-sign']}>{words['project.archived']}</div>}
        {open && <div className={styles.overlay} onClick={toggleOpen} />}
        <button className={styles['edit-btn']} onClick={toggleOpen} type="button">
          <DotsBtn />
        </button>
        {open && (
          <ul className={styles.menu}>
            <li onClick={() => history.push(`/dashboard/vacancies/${params.id}/edit`)}>
              {words['project.edit']}
            </li>
            <li onClick={toggleModal}>
              {deleted ? words['project.restore'] : words['project.toArchive']}
            </li>
            <li>{words['project.historyOfChanges']}</li>
          </ul>
        )}
      </div>
    </>
  )
}
