import React, { FC, InputHTMLAttributes } from 'react'
import styles from './Checkbox.module.scss'

export type TBaseCheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  label?: string
  value?: boolean | null
}

const Checkbox: FC<TBaseCheckboxProps> = ({ label, name, value, ...other }) => {
  return (
    <div onClick={(e: any) => e.stopPropagation()} className={styles['wrapper']}>
      <input
        id={name}
        className={styles['checkbox']}
        type="checkbox"
        name={name}
        defaultChecked={value}
        {...other}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  )
}

export default Checkbox
