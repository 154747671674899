import { action } from 'store/store.utils'
import {
  UPDATE_USER_SUCCESS,
  GET_QRCODE_SUCCESS,
  GET_SIGNUP_SUCCESS,
  GET_DID_SUCCESS,
  GET_IDENFY_TOKEN_SUCCESS,
  VERIFICATION_REQUEST,
  VERIFICATION_ERROR
} from './Idenfy.constants'
import { TIdenfyList } from './Idenfy.model'

export const verificationRequest = (): TIdenfyList => action(VERIFICATION_REQUEST)

export const verificationError = (payload?: any): TIdenfyList => action(VERIFICATION_ERROR, payload)

export const updateUserSuccess = (payload?: any): TIdenfyList =>
  action(UPDATE_USER_SUCCESS, payload)

export const getQrCodeSuccess = (payload?: any): TIdenfyList => action(GET_QRCODE_SUCCESS, payload)

export const getSignupSuccess = (payload?: any): TIdenfyList => action(GET_SIGNUP_SUCCESS, payload)

export const getUserDidSuccess = (payload?: any): TIdenfyList => action(GET_DID_SUCCESS, payload)

export const getIdenfyTokenSuccess = (payload?: any): TIdenfyList =>
  action(GET_IDENFY_TOKEN_SUCCESS, payload)
