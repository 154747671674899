/* eslint-disable no-console */
import { IAction } from 'models'
import {
  GET_OPTIONS_REQUEST,
  GET_OPTIONS_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS
} from './CreateInvoice.constants'

export type IInitialState = {
  error: string
  loading: boolean
  users: any
  options: any
}

export const initialState: IInitialState = {
  error: '',
  loading: false,
  users: [],
  options: []
}

export default (state = initialState, action: IAction): any => {
  switch (action.type) {
    case GET_USER_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: action.payload
      }
    }
    case GET_OPTIONS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case GET_OPTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        options: action.payload
      }
    }

    default:
      return state
  }
}
