import { TBaseFilterConfigData } from 'components/UiKit/Filter/Filter'
import { TWords } from 'elements/SideBar/SideBar.config'

export const getFilterConfig = ({
  displayAllFields,
  words,
  inputCurrencyClass,
  inputTimeClass,
  currentPeriodFrom,
  currentPeriodTo,
  rank,
  positions,
  typeOfEmployment,
  inputPercentClass
}: {
  displayAllFields: boolean
  words: TWords
  inputCurrencyClass: string
  inputTimeClass: string
  currentPeriodFrom?: string | (string | null)[] | null
  currentPeriodTo?: string | (string | null)[] | null
  inputPercentClass: string
  rank: string[]
  positions: string[]
  typeOfEmployment: string[]
}): TBaseFilterConfigData[] => {
  const periodSelectData = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' }
  ]
  const periodMinIndex = periodSelectData.findIndex(elem => elem.value === currentPeriodFrom)
  const periodMaxIndex = periodSelectData.findIndex(elem => elem.value === currentPeriodTo)

  const filter: TBaseFilterConfigData[] = [
    {
      name: 'fullName',
      type: 'input',
      label: words['project.team.name'],
      width: '25%'
    },
    {
      name: 'position',
      type: 'select',
      fieldData: positions,
      label: words['project.team.specialization'],
      placeholder: '',
      width: '25%'
    },
    {
      name: 'rank',
      type: 'select',
      fieldData: rank,
      label: words['project.team.level'],
      placeholder: '',
      width: '16%'
    },
    {
      name: 'location',
      type: 'select',
      fieldData: [],
      label: 'Локация',
      placeholder: 'Локация',
      width: '23%'
    }
  ]
  if (displayAllFields) {
    filter.push(
      {
        name: 'participationPeriodFrom',
        secondName: 'participationPeriodTo',
        type: 'selectRange',
        placeholder: '',
        fieldData: periodSelectData,
        label: words['project.team.period'],
        rangeContainerWidth: '27%',
        width: '50%',
        selectRangeBoundsIndex: {
          min: periodMinIndex >= 0 ? periodMinIndex : null,
          max: periodMaxIndex >= 0 ? periodMaxIndex : null
        }
      },
      {
        name: 'typeOfEmployment',
        type: 'select',
        fieldData: typeOfEmployment,
        label: words['project.team.type'],
        placeholder: '',
        width: '18%'
      },
      {
        name: 'workingHoursPerDayFrom',
        secondName: 'workingHoursPerDayTo',
        type: 'inputRange',
        inputType: 'number',
        label: words['project.team.hoursPerDay'],
        placeholder: '',
        inputContainerClass: inputTimeClass,
        rangeContainerWidth: '19%',
        fieldData: {
          minValue: '',
          maxValue: ''
        }
      },
      {
        name: 'salaryFrom',
        secondName: 'salaryTo',
        type: 'inputRange',
        inputType: 'number',
        label: words['project.team.paymentLevel'],
        placeholder: '',
        inputContainerClass: inputCurrencyClass,
        rangeContainerWidth: '25%',
        fieldData: {
          minValue: '',
          maxValue: ''
        }
      },
      {
        name: 'rateByInvoiceFrom',
        secondName: 'rateByInvoiceTo',
        type: 'inputRange',
        inputType: 'number',
        label: words['project.team.rate'],
        placeholder: '',
        inputContainerClass: inputCurrencyClass,
        rangeContainerWidth: '20%',
        fieldData: {
          minValue: '',
          maxValue: ''
        }
      },
      {
        name: 'realLoadFrom',
        secondName: 'realLoadTo',
        type: 'inputRange',
        inputType: 'number',
        label: 'Реальная загрузка',
        placeholder: '%',
        inputContainerClass: inputPercentClass,
        rangeContainerWidth: '20%',
        fieldData: {
          minValue: '',
          maxValue: ''
        }
      },
      {
        name: 'loadByInvoiceFrom',
        secondName: 'loadByInvoiceTo',
        type: 'inputRange',
        inputType: 'number',
        label: 'Загрузка по инвойсу',
        placeholder: '',
        rangeContainerWidth: '20%',
        fieldData: {
          minValue: '',
          maxValue: ''
        }
      }
    )
  }
  return filter
}
