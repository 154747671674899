import React, { useEffect } from 'react'
import styles from './VerificationBlockPass.module.scss'
import { useSelector } from 'react-redux'
import { TState } from '../../../store'
import { Icons } from '../../Icons'
import { Button } from 'components/UiKit/Button'
import { config } from 'globalConfigs'

interface IProps {
  status: string
  id: number
  getUserInfo: any
}

export const VerificationBlockPass = ({ status, id, getUserInfo }: IProps) => {
  const words = useSelector((state: TState) => state.global.language.words)

  useEffect(() => {
    loadBlockpassWidget()
  }, [status])

  const loadBlockpassWidget = () => {
    if (status === 'not_exist') {
      const blockpass = new window.BlockpassKYCConnect(
        config.blockPassClientID, // service client_id from the admin console
        {
          refId: id // assign the local user_id of the connected user
        }
      )

      blockpass.startKYCConnect()
      blockpass.on('KYCConnectCancel', () => {
        getUserInfo()
      })

      blockpass.on('KYCConnectClose', () => {
        getUserInfo()
      })

      blockpass.on('KYCConnectSuccess', () => {
        //add code that will trigger when data have been sent. ex:
        //alert('Success!')
      })
    }
  }

  const statusMessage = (): { status: string; icon: any } => {
    switch (status) {
      case 'approved':
        return { status: words['VERIFICATION_STATUS_5'], icon: <Icons icon="approved" /> }
      case 'rejected':
        return { status: words['VERIFICATION_STATUS_4'], icon: <Icons icon="rejected" /> }
      case 'inreview':
        return { status: words['VERIFICATION_STATUS_3'], icon: <Icons icon="inreview" /> }
      case 'waiting':
        return { status: words['VERIFICATION_STATUS_2'], icon: <Icons icon="waiting" /> }
      default:
        return {
          status: words['VERIFICATION_STATUS_1'],
          icon: null
        }
    }
  }

  if (!status) {
    return <div />
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.status}>
          <div className={styles.textStatus}>{`${
            words['user.profile.verificationStatus']
          } ${status && statusMessage().status}`}</div>
          {status === 'rejected' && (
            <div className={styles.message}>{words['user.profile.userMessage']}</div>
          )}

          {status === 'not_exist' && (
            <div className={styles.message}>{words['user.profile.startVerification']}</div>
          )}

          <div className={styles.imageStatus}>
            {statusMessage().icon}

            {status === 'not_exist' && (
              <Button id="blockpass-kyc-connect">{words['user.profile.verification']}</Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
