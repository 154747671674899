import React, { FC, memo, useState } from 'react'
import styles from './TableShowHeader.module.scss'
import cls from 'classnames'
import { Icons } from 'components/Icons'

export const TableShowHeader: FC<{
  show?: boolean
  id?: string
  children: any
  isActiveLevel: boolean
  isActiveName: boolean
  setName: (isName: any) => void
  setLevel: (isLevel: any) => void
}> = memo(
  ({ children, show = true, id = 'name', setName, setLevel, isActiveName, isActiveLevel }) => {
    const [isNameActive, setIsNameActive] = useState(isActiveName)
    const [isLevelActive, setIsLevelActive] = useState(isActiveLevel)
    const [isIconActive, setIsIconActive] = useState(true)

    const handleClick = () => {
      setIsIconActive(!isIconActive)
      if (id === 'name') {
        setIsNameActive(!isNameActive)
        setName(!isNameActive)
      } else if (id === 'level') {
        setIsLevelActive(!isLevelActive)
        setLevel(!isLevelActive)
      }
    }

    return (
      <div
        className={cls({
          [styles.sort]: true,
          [styles['active']]: !isNameActive || !isLevelActive
        })}
        onClick={handleClick}
      >
        {children}
        {show && (
          <>
            {' '}
            <Icons icon={isIconActive ? 'activeTable' : 'disableTable'} />
          </>
        )}
      </div>
    )
  }
)
