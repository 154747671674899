import { TSelectOption } from '../../../components/Select'
import { TBaseFilterConfigData } from '../../../components/UiKit/Filter/Filter'
import { TWords } from 'elements/SideBar/SideBar.config'

export const getFilterUsers = (
  words: TWords,
  {
    fullName = [],
    usersEmail = []
  }: {
    fullName: TSelectOption[]
    usersEmail: TSelectOption[]
  }
): TBaseFilterConfigData[] => {
  return [
    {
      name: 'id',
      type: 'input',
      label: 'ID:',
      placeholder: 'Id',
      width: '20%',
      inputType: 'number'
    },
    {
      name: 'fullName',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...fullName],
      label: words['admin.users.table.filters.userName'],
      placeholder: '',
      width: '30%'
    },
    {
      name: 'email',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...usersEmail],
      label: words['admin.users.table.Edit.email'],
      placeholder: '',
      width: '30%'
    }
    // TODO - add range balance
    // {
    //   name: 'fromBalance',
    //   secondName: 'toBalance',
    //   type: 'inputRange',
    //   inputType: 'number',
    //   label: 'Баланс:',
    //   fieldData: {
    //     minValue: 'от',
    //     maxValue: 'до'
    //   },
    //   width: 71
    // }
  ]
}
