import React from 'react'
// import styles from './PerformersList.module.scss'
import { PerformerInfo } from '../PerformerInfo/PerformerInfo'
import InfiniteScroll from 'react-infinite-scroll-component'
import queryString from 'query-string'
import history from 'utils/history'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { finishPoint } from 'screens/client/Subcontract/components/CreateTask/initialvalues'
import styles from './PerformersList.module.scss'

export const PerformersList = ({ getUsers, urlEndpoint }: any) => {
  // const stores = useSelector((state: TState) => state.client.subcontract.tasks)
  const usersArray = useSelector((state: TState) => state.client.subcontract.users)
  const parsed = queryString.parse(history.location.search)

  const pageCount = useSelector((state: TState) => state.client.subcontract.pageCount)

  const urlSearchParamses = new URLSearchParams(window.location.search)
  const paramses = Object.fromEntries(urlSearchParamses.entries())
  // const dispatch = useDispatch()
  const fetchMore = () => {
    const url = new URL((window.location as unknown) as string)
    url.searchParams.set('page', String((paramses.page ? +paramses.page : 0) + 1))
    window.history.pushState({}, '', url.toString())

    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    getUsers(10, params.page, urlEndpoint, finishPoint(), parsed.clear === 'true')
  }

  return (
    <>
      <div className={styles.container}>
        <InfiniteScroll
          dataLength={usersArray.length}
          next={fetchMore}
          hasMore={(paramses.page ? +paramses.page : 0) + 1 <= pageCount}
          loader={<h4>Loading...</h4>}
        >
          {usersArray
            .filter((user: any) => 'position' in user && user.position)
            .map((user: any) => (
              <PerformerInfo key={user.id} id={user.id} user={user} />
            ))}
        </InfiniteScroll>
      </div>
    </>
  )
}
