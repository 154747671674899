/* eslint-disable no-console */
import { Dispatch } from 'redux'
import { API, api, apiVetifi, apiIdenfy } from '../../../../../../../services/api'
import {
  updateUserSuccess,
  getQrCodeSuccess,
  getSignupSuccess,
  getIdenfyTokenSuccess,
  verificationRequest,
  verificationError,
  getUserDidSuccess
} from './Idenfy.actions'
import toastr from 'utils/toastr'
import { TState } from 'store'
import { AxiosError } from 'axios'
import { getUserProfileInfo } from 'screens/client/Profile/Profile.thunx'
import { pulling } from 'screens/auth/Login/Login.thunx'
import history from '../../../../../../../utils/history'
import { setUserVerification } from 'screens/auth/Login/Login.actions'
// import { getUserDidSuccess } from '../../Verification.actions'

export const sendVerificationData = (id: any, files: any, _user: any, did: any, status?: any) => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  dispatch(verificationRequest())
  const diff_years = (dt2: any, dt1: any) => {
    let diff = (dt2 - dt1) / 1000
    diff /= 60 * 60 * 24
    return Math.abs(Math.floor((diff * -1) / 365.25))
  }

  const birth = new Date('2000-01-01').getTime()
  const today = new Date().getTime()

  console.log(diff_years(birth, today))
  const words = getData().global.language.words
  try {
    const formData = new FormData()
    if (did) {
      formData.append('did', did)
    }
    status && formData.append('verificationIdenfy', status)
    status === 'claims' || status === 'true' && formData.append('verification', status)

    if(files) {
      files.forEach((file: string | Blob) => {
        if (file) {
          formData.append('userFiles', file)
        }
      })
    }

    api.put(`user/verification`, formData).then(async () => await getUserProfileInfo(id, 'main-info', false)(dispatch, getData))

    dispatch(
      updateUserSuccess()
    )
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(verificationError(err.response))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}

export const createVerificationSession = () => async (dispatch: Dispatch, getData: () => TState): Promise<void> => {
  dispatch(verificationRequest())
  const words = getData().global.language.words
  const userId = getData().auth.data.id
  try {
    const resp: any = await apiIdenfy.post(`token?successUrl=${window.location.href}&errorUrl=${
      window.location.href
    }&unverifiedUrl=${window.location.href}`, { clientId:userId })
    dispatch(getIdenfyTokenSuccess(resp.data))
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(verificationError(err.response))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}

export const getQrCodeSignUp = () => async (dispatch: Dispatch, getData: () => TState): Promise<void> => {
  dispatch(verificationRequest())
  const words = getData().global.language.words
  try {
    const resp: any = await apiVetifi.get('/polygon/sign-up')
    dispatch(
      getSignupSuccess({
        signupQr: resp.data
      })
    )
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const getWalletDids = setInterval(async () => {
      if (history.location.pathname !== '/dashboard/subcontract/verification') {
        dispatch(
          getSignupSuccess({
            signupQr: {}
          })
        )
        clearInterval(getWalletDids)
      }
      const status: any = await apiVetifi.get(`/polygon/status?sessionId=${resp.data.sessionId}`)
      // eslint-disable-next-line prettier/prettier
      if (status?.status === 200 || getData().auth.data.did) {
        dispatch(
          getUserDidSuccess({
            did: status.data.did
          })
        )
        sendVerificationData(
          getData().auth.data.id,
          [],
          getData().client.profile.user,
          status.data.did,
          'inprogress'
        )(dispatch, getData)
        clearInterval(getWalletDids)
        toastr('success', 'Did was received')
      }
    }, 1000)
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(verificationError(err.response))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}

export const getQrCodeSignIn = () => async (dispatch: Dispatch, getData: () => TState): Promise<void> => {
  dispatch(verificationRequest())
  const words = getData().global.language.words
  try {
    const qrclaim = { data: {}}
    const qrAllClaims: any = await apiVetifi.get(
      `${process.env.REACT_APP_ADMIN_DID}/claims`
    )

    const resp = await apiVetifi.get('/polygon/sign-in')

    const getVerificationStatus= setInterval(async () => {
      if (history.location.pathname !== '/dashboard/subcontract/verification' && history.location.pathname !== '/dashboard/subcontract/verification/claims') {
        dispatch(
          getQrCodeSuccess({
            signinQr : { sessionId: ''}
          })
        )
        clearInterval(getVerificationStatus)
      }
    const status: any = await apiVetifi.get(`/polygon/status?sessionId=${resp.data.sessionId}`)
      // eslint-disable-next-line prettier/prettier
      if (status?.status === 200) {
        sendVerificationData(getData().auth.data.id, [], {}, false, 'true')(dispatch, getData)
        dispatch(setUserVerification('true'))
        pulling()(dispatch)
        clearInterval(getVerificationStatus)
      }
    }, 10000)
    console.log(qrAllClaims.data.filter((claim: any) => claim.credentialSubject.id === getData().client.profile.user.did).length == 0)

    if (qrAllClaims.data.filter((claim: any) => claim.credentialSubject.id === getData().client.profile.user.did).length == 0) {
      createClaimsByIdenfy(23, 2)(dispatch, getData)
      return 
    } else if (getData().client.profile.user.verification !== 'claims') {
      sendVerificationData(getData().auth.data.id, [], {}, false, 'claims')(dispatch, getData)
    }

    dispatch(
      getQrCodeSuccess({
        claims: qrclaim.data , signinQr : resp.data , claimsList: qrAllClaims.data.filter((claim: any) => 
        claim.credentialSubject.id === getData().client.profile.user.did
      )
      })
    )
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(verificationError(err))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
    }
  }

export const qetClaimsQrCode = (offer: any) => async (dispatch: Dispatch, getData: () => TState): Promise<void> => {
  dispatch(verificationRequest())
  const words = getData().global.language.words

  try {
    const qrclaim = await apiVetifi.get(
      `${process.env.REACT_APP_ADMIN_DID}/claims/${offer}/qrcode`
    )
    dispatch(
      getQrCodeSuccess({
        claims: qrclaim.data , signinQr : getData().client.verificationClient.qrCode.signinQr
      })
    )
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(verificationError(err.response))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}

export const createClaimsByIdenfy = (userAge: number, id: number) => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  dispatch(verificationRequest())
  const words = getData().global.language.words
  try {
    const res: any = await api.get(`${API.CLIENT}/${id}`)
    await apiVetifi.post(`${process.env.REACT_APP_ADMIN_DID}/claims`, {
      credentialSchema:
        'https://raw.githubusercontent.com/iden3/claim-schema-vocab/main/schemas/json/KYCAgeCredential-v3.json',
      type: 'KYCAgeCredential',
      credentialSubject: {
        id: res.data.did,
        birthday: userAge,
        documentType: 2
      },
      expiration: 1893456000
    })
    sendVerificationData(getData().auth.data.id, [], {}, false, 'claims')(dispatch, getData)
    toastr('success', 'Claims was created')
  } catch (error) {
    const err = error as AxiosError<any>
    console.log(error)
    //@ts-ignore
    dispatch(verificationError(err))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}