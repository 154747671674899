import { TBaseFilterConfigData } from 'components/UiKit/Filter/Filter'
import { TSelectOption } from '../../../Select/Select.model'
import { TWords } from 'elements/SideBar/SideBar.config'

export const getFilterConfig = ({
  // loadOptions,
  categories,
  minPrice,
  maxPrice,
  numberOfPeople,
  words
}: {
  categories: TSelectOption[]
  minPrice: number
  maxPrice: number
  numberOfPeople: TSelectOption[]
  words: TWords
}): TBaseFilterConfigData[] => {
  return [
    {
      name: 'name',
      type: 'input',
      label: words['user.profile.table.goodsService'],
      width: '22.7%'
    },
    {
      name: 'categoryId',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...categories],
      label: words['user.pointsSystem.initiatives.table.category'],
      placeholder: '',
      width: '17%'
    },
    {
      name: 'numberOfPeople',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...numberOfPeople],
      label: words['user.profile.table.numberOfPeople'],
      placeholder: '',
      width: '8%'
    },
    {
      name: 'priceFrom',
      secondName: 'priceTo',
      type: 'inputRange',
      fieldData: { minValue: minPrice, maxValue: maxPrice },
      label: words['user.profile.table.price'],
      width: 43
    }
  ]
}
