import { TBaseFilterConfigData } from 'components/UiKit/Filter/Filter'
import { TSelectOption } from '../../../Select/Select.model'
import { TWords } from 'elements/SideBar/SideBar.config'

export const getFilterConfig = ({
  categories,
  minPrice,
  maxPrice,
  numberOfPeople,
  words,
  emptyMessage
}: {
  categories: TSelectOption[]
  minPrice: number
  maxPrice: number
  numberOfPeople: TSelectOption[]
  words: TWords
  emptyMessage: string
}): TBaseFilterConfigData[] => {
  return [
    {
      name: 'name',
      type: 'input',
      label: `${words['user.pointsSystem.market.table.name']}`,
      placeholder: `${words['user.pointsSystem.market.table.name.placeholder']}...`,
      emptyMessage,
      width: '26%'
    },
    {
      name: 'categoryId',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...categories],
      label: `${words['user.pointsSystem.market.table.category']}`,
      placeholder: '',
      width: '17%'
    },
    {
      name: 'priceFrom',
      secondName: 'priceTo',
      type: 'inputRange',
      fieldData: { minValue: minPrice, maxValue: maxPrice },
      label: `${words['user.pointsSystem.market.table.price']}`,
      width: 64
    },
    {
      name: 'numberOfPeople',
      type: 'select',
      fieldData: [{ value: '@ALL@', label: words['all'] }, ...numberOfPeople],
      label: `${words['user.profile.table.numberOfPeople']}`,
      placeholder: '',
      width: '17%'
    }
  ]
}
