import React from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'

import styles from '../../EditProfile.module.scss'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import {
  contactInfoFieldsConfig,
  personalInfoFieldsConfig,
  financialInfoFieldsConfig
} from './EditMainInfo.config'
import { Icons } from '../../../../../components/Icons'
import { FileView } from 'components/Profile/PersonalInfo/FileView'

export const EditMainInfo: any = ({ form, isAdmin, files, setFiles, deleteFile }: any) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const maritalStatus = useSelector((state: TState) => state.client.editProfile.maritalStatus)
  const academicDegree = useSelector((state: TState) => state.client.editProfile.academicDegree)
  const banks = useSelector((state: TState) => state.client.editProfile.banks)
  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)
  const amountOfChildren = form.getState().values.amountOfChildren

  const onSelectFile = (e: any) => {
    const f = e.target.files[0]
    if (f) {
      const newFiles = [...files]
      const blob = new Blob([f], { type: f.type })
      const url = URL.createObjectURL(blob)
      newFiles.push({ name: f.name, link: url, file: f })
      setFiles(newFiles)
    }
  }

  return (
    <>
      <section className={styles['contact-info-block']}>
        <h3>{words['user.editProfile.contactInfo']}</h3>
        <FieldFactory form={form} config={contactInfoFieldsConfig(words)} words={words} />
      </section>

      <section className={styles['private-info-block']}>
        <h3>{words['user.profile.mainInfo.personalInfo']}</h3>
        <FieldFactory
          currentLanguage={currentLanguage}
          form={form}
          config={personalInfoFieldsConfig(words, {
            amountOfChildren,
            maritalStatus,
            academicDegree
          })}
          words={words}
        />
      </section>

      <section className={styles['financial-info-block']}>
        <h3>{words['user.profile.mainInfo.financialInfo']}</h3>
        <FieldFactory
          form={form}
          config={financialInfoFieldsConfig(words, { banks })}
          words={words}
        />
      </section>

      {isAdmin && (
        <section className={styles['add-files-block']}>
          <h3>{words['user.profile.mainInfo.files']}</h3>
          {files &&
            files.length > 0 &&
            files.map((item: any, idx: number) => (
              <FileView isEdit item={item} idx={idx} deleteFile={deleteFile} key={String(idx)} />
            ))}
          <div className={styles['add-file-arria']}>
            <label htmlFor="addFile">
              <Icons icon="add" />
              <input
                id="addFile"
                type="file"
                // accept="image/*, .pdf"
                onChange={(e: any) => onSelectFile(e)}
              />
            </label>
          </div>
        </section>
      )}
    </>
  )
}
