import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { TState } from 'store'

import ProjectVacanciesComponent from './ProjectVacancies'
import { getSelectOptions } from '../../../screens/client/EditProfile/EditProfile.thunx'
import {
  getProjectOptions,
  getStatusOptions
} from '../../../screens/admin/Vacancies/CreateVacancy/CreateVacancy.thunk'
import { getProjectVacancies } from '../../../screens/admin/Vacancies/Vacancies.thunk'
import { getVacancyRequest } from '../../../screens/admin/Vacancies/Vacancies.actions'

export const mapStateToProps = (state: TState) => {
  return {
    displayAllFields: state.client.filter.multilineFilterFields.status,
    rank: state.client.editProfile.rank,
    typeOfEmployment: state.client.editProfile.typeOfEmployment,
    positions: state.client.editProfile.positions,
    projectOptions: state.admin.vacancies.projectOptions,
    vacanciesArray: state.admin.vacancies.vacanciesList,
    statusOptions: state.admin.vacancies.statusOptions
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getSelectOptions,
      getProjectOptions,
      getProjectVacancies,
      getVacancyRequest,
      getStatusOptions
    },
    dispatch
  )

export const ProjectVacancies = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectVacanciesComponent)
