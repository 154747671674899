import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import VacancyComponent from './Vacancy'
import { getVacancy } from '../Vacancies.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    vacancyItem: state.admin.vacancies.vacancy,
    loading: state.admin.vacancies.loading,
    statusOptions: state.admin.vacancies.statusOptions,
    test: '123'
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getVacancy
    },
    dispatch
  )

export const Vacancy = connect(
  mapStateToProps,
  mapDispatchToProps
)(VacancyComponent)
