import moment from 'moment'

interface IconsUpdateAndTime {
  date: string
  currentTime: string
}

export function formatDateAndTime(dateTimeString: string): IconsUpdateAndTime {
  const dateTime = moment(dateTimeString)
  const date = dateTime.format('YYYY-MM-DD')
  const currentTime = dateTime.format('HH:mm:ss')

  return { date, currentTime }
}
