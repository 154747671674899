import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'
import { qetClaimsQrCode } from '../Polygon/Polygon.thunk'

import ClaimsComponent from './Claims'

export const mapStateToProps = (state: TState) => {
  return {
    user: state.client.profile.user,
    id: state.auth.data.id,
    qrCodeLoading: state.client.verificationClient.loading,
    offerId: state.admin.verification.offerId,
    qrCode: state.client.verificationClient.qrCode,
    signup: state.client.verificationClient.signup,
    did: state.client.verificationClient.did
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      qetClaimsQrCode
    },
    dispatch
  )

export const Claims = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimsComponent)
