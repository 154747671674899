import React from 'react'
import styles from './Slide.module.scss'

export interface ISlideProps {
  id: number
  title: string
  description: string
  isIcons: boolean
  icons: React.ReactNode[]
  img: React.ReactNode
}

const Slide: React.FC<ISlideProps> = ({ title, description, isIcons, icons, img }) => {
  return (
    <div className={styles.slide}>
      <div className={styles.slideWrapper}>
        <h2 className={styles.slideTitle}>{title}</h2>

        <p className={styles.slideDescription}>{description}</p>

        {isIcons && (
          <div className={styles.slideIconsWrapper}>
            {icons.map((icon, index) => (
              <div key={index} className={styles.iconWrapper}>
                {icon}
              </div>
            ))}
          </div>
        )}
      </div>

      {img}
    </div>
  )
}
export default Slide
