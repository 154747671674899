/* eslint-disable no-console */
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import styles from './style.module.scss'
import { Field, Form } from 'react-final-form'
import { Select as NewUIKitSelect } from 'Select'
import { TSelectOption } from 'components/Select'
import { Input } from 'components/UiKit/Inputs'
import { getSelectOptions } from 'screens/client/EditProfile/EditProfile.thunx'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store'
import cn from 'classnames'
import Button from 'components/Button'
import { ReactComponent as IconCancel } from 'assets/images/cancel_cross_icon.svg'
import { DatePickerRangeNew } from 'components/DatePickerRangeNew'
import { ESubcontractFilterFields, ESubcontractTabs } from '../subcontract.enum'
import queryString from 'query-string'
import history from 'utils/history'
import moment from 'moment'
import { TWords } from 'elements/SideBar/SideBar.config'
import { IToken } from 'screens/admin/Token/Token.model'

export type TSubcontractFilterValues = {
  [ESubcontractFilterFields.CATEGORY]: TSelectOption | undefined
  [ESubcontractFilterFields.SPECIALIZATION]: TSelectOption
  [ESubcontractFilterFields.RANK]: TSelectOption
  [ESubcontractFilterFields.PUBLICATION_DATE_FROM]?: string | number
  [ESubcontractFilterFields.PUBLICATION_DATE_TO]?: string | number
  [ESubcontractFilterFields.BUDGET_FROM]?: string
  [ESubcontractFilterFields.BUDGET_TO]?: string
  [ESubcontractFilterFields.CURRENCY]?: any
  [ESubcontractFilterFields.SUBMITTING_DATE_FROM]?: string | number
  [ESubcontractFilterFields.SUBMITTING_DATE_TO]?: string
  [ESubcontractFilterFields.STATUS]?: TSelectOption
  [ESubcontractFilterFields.TECHNOLOGY]?: string
  [ESubcontractFilterFields.POSITION]?: TSelectOption
  [ESubcontractFilterFields.USER_RANK]?: TSelectOption
}

type TSubcontractTasksFilterProps = {
  activeTab: ESubcontractTabs
  onSubmit: (values: TSubcontractFilterValues) => any
  tokens: IToken[]
  // className: string
}

const subcontractFilterConfig: {
  [tab in ESubcontractTabs]: { name: ESubcontractFilterFields; isSelect?: boolean }[]
} = {
  [ESubcontractTabs.ALL_TASKS]: [
    { name: ESubcontractFilterFields.CATEGORY },
    { name: ESubcontractFilterFields.SPECIALIZATION },
    { name: ESubcontractFilterFields.RANK },
    { name: ESubcontractFilterFields.PUBLICATION_DATE_FROM },
    { name: ESubcontractFilterFields.PUBLICATION_DATE_TO },
    { name: ESubcontractFilterFields.BUDGET_FROM },
    { name: ESubcontractFilterFields.BUDGET_TO },
    { name: ESubcontractFilterFields.CURRENCY, isSelect: true }
  ],
  [ESubcontractTabs.MY_TASKS]: [
    { name: ESubcontractFilterFields.CATEGORY },
    { name: ESubcontractFilterFields.SPECIALIZATION },
    { name: ESubcontractFilterFields.RANK },
    { name: ESubcontractFilterFields.SUBMITTING_DATE_FROM },
    { name: ESubcontractFilterFields.SUBMITTING_DATE_TO },
    { name: ESubcontractFilterFields.STATUS, isSelect: true },
    { name: ESubcontractFilterFields.BUDGET_FROM },
    { name: ESubcontractFilterFields.BUDGET_TO },
    { name: ESubcontractFilterFields.CURRENCY, isSelect: true }
  ],
  [ESubcontractTabs.MY_RESPONDS]: [
    { name: ESubcontractFilterFields.CATEGORY },
    { name: ESubcontractFilterFields.SPECIALIZATION },
    { name: ESubcontractFilterFields.RANK },
    { name: ESubcontractFilterFields.SUBMITTING_DATE_FROM },
    { name: ESubcontractFilterFields.SUBMITTING_DATE_TO },
    { name: ESubcontractFilterFields.STATUS, isSelect: true },
    { name: ESubcontractFilterFields.BUDGET_FROM },
    { name: ESubcontractFilterFields.BUDGET_TO },
    { name: ESubcontractFilterFields.CURRENCY, isSelect: true }
  ],
  [ESubcontractTabs.EXECUTORS]: [
    { name: ESubcontractFilterFields.POSITION },
    { name: ESubcontractFilterFields.USER_RANK },
    { name: ESubcontractFilterFields.TECHNOLOGY }
  ],
  [ESubcontractTabs.DRAFTS]: [
    { name: ESubcontractFilterFields.CATEGORY },
    { name: ESubcontractFilterFields.SPECIALIZATION },
    { name: ESubcontractFilterFields.RANK },
    { name: ESubcontractFilterFields.SUBMITTING_DATE_FROM },
    { name: ESubcontractFilterFields.SUBMITTING_DATE_TO },
    { name: ESubcontractFilterFields.BUDGET_FROM },
    { name: ESubcontractFilterFields.BUDGET_TO },
    { name: ESubcontractFilterFields.CURRENCY, isSelect: true }
  ]
}

// TODO: Consider getting these options from the backend
export const categoryOptions = [
  { value: '1', label: 'Dedicated Team' },
  { value: '2', label: 'Time and Materials' },
  { value: '3', label: 'Fixed Price' },
  { value: '4', label: 'Project Based' }
]

const getStatusOptions = (words: TWords) => [
  { value: '2', label: words['TASK_STATUS_2'] },
  { value: '3', label: words['TASK_STATUS_3'] },
  { value: '4', label: words['TASK_STATUS_4'] },
  { value: '5', label: words['TASK_STATUS_5'] },
  { value: '6', label: words['TASK_STATUS_6'] }
]

const getCurrencyList = (tokens: IToken[]) => {
  return tokens.map((token: IToken) => {
    return { value: String(token.id), label: token.symbol }
  })
}

export const SubcontractTasksFilter: FC<TSubcontractTasksFilterProps> = ({
  activeTab,
  onSubmit,
  tokens
}) => {
  const dispatch = useDispatch()

  // const user = useSelector((state: TState) => state.client.profile.user)
  const level = useSelector((state: TState) => state.client.editProfile.rank)
  const positions = useSelector((state: TState) => state.client.editProfile.positions)
  const parsed = queryString.parse(history.location.search)
  const words = useSelector((state: TState) => state.global.language.words)
  const statusOptions = getStatusOptions(words)

  const config = useMemo(() => subcontractFilterConfig[activeTab], [activeTab])
  const allOption = useMemo(() => ({ value: '@ALL@', label: words['all'] }), [words])

  useEffect(() => {
    dispatch(getSelectOptions())
  }, [])

  const isFieldExists = useCallback((field: string) => config.find(item => item.name === field), [
    config
  ])

  const initialValues = useMemo(() => {
    const types = (data: any) => {
      return data && typeof data === 'string' ? data : `${data}`
    }

    const values: TSubcontractFilterValues = {
      category:
        allOption &&
        categoryOptions.find(
          (item: any) => +item.value === (parsed.category ? +parsed.category : '')
        )
          ? categoryOptions.find(
              (item: any) => +item.value === (parsed.category ? +parsed.category : '')
            )
          : allOption,
      specialization:
        positions &&
        positions.find(
          (item: any) => +item.value === (parsed.specialization ? +parsed.specialization : '')
        )
          ? positions.find(
              (item: any) => +item.value === (parsed.specialization ? +parsed.specialization : '')
            )
          : { value: '@ALL@', label: words['all'] },
      level:
        level &&
        (level.find((item: any) => +item.value === (parsed.level ? +parsed.level : ''))
          ? level.find((item: any) => +item.value === (parsed.level ? +parsed.level : ''))
          : { value: '@ALL@', label: words['all'] }),
      status:
        statusOptions &&
        (statusOptions.find((item: any) => +item.value === (parsed.status ? +parsed.status : ''))
          ? statusOptions.find((item: any) => +item.value === (parsed.status ? +parsed.status : ''))
          : { value: '@ALL@', label: words['all'] }),
      // publicationDateFrom: parsed.fromDate ? dates(parsed.fromDate) : '',
      publicationDateFrom: parsed.fromDate ? moment(+parsed.fromDate).valueOf() : '',
      // publicationDateTo: moment(parsed.toDate).valueOf(),
      publicationDateTo: parsed.toDate ? moment(+parsed.toDate).valueOf() : '',
      budgetFrom:
        parsed.fromPrice === null || parsed.fromPrice === undefined ? '' : types(parsed.fromPrice),
      budgetTo:
        parsed.toPrice === null || parsed.toPrice === undefined ? '' : types(parsed.toPrice),
      currency:
        tokens &&
        (getCurrencyList(tokens).find(
          (item: any) => +item.value === (parsed.currency ? +parsed.currency : '')
        )
          ? getCurrencyList(tokens)
          : { value: '@ALL@', label: words['all'] }),
      technology: parsed.technologies ? types(parsed.technologies) : '',
      position:
        positions &&
        positions.find((item: any) => +item.value === (parsed.position ? +parsed.position : ''))
          ? positions.find((item: any) => +item.value === (parsed.position ? +parsed.position : ''))
          : { value: '@ALL@', label: words['all'] },
      rank:
        level &&
        (level.find((item: any) => +item.value === (parsed.rank ? +parsed.rank : ''))
          ? level.find((item: any) => +item.value === (parsed.rank ? +parsed.rank : ''))
          : { value: '@ALL@', label: words['all'] })
    }

    // config.forEach(item => {
    //   values[item.name] = (item.isSelect ? allOption : '') as any
    // })

    return values
  }, [categoryOptions, positions, level, statusOptions, activeTab])

  if (!level || !positions) return <div className={styles.wrapper} />
  return (
    <div className={styles.wrapper}>
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          onSubmit(values as TSubcontractFilterValues)
        }}
      >
        {({ form }) => (
          <div className={styles.container}>
            <div className={styles.inputs}>
              {isFieldExists(ESubcontractFilterFields.CATEGORY) && (
                <Field name="category">
                  {({ input }) => (
                    <div className={styles.inputWrapper}>
                      <p>{words['user.subcontract.taskFilter.category']}</p>
                      <NewUIKitSelect
                        options={[allOption, ...categoryOptions]}
                        {...input}
                        onChange={e => {
                          form.change(input.name, e as TSelectOption)
                        }}
                      />
                    </div>
                  )}
                </Field>
              )}
              {isFieldExists(ESubcontractFilterFields.SPECIALIZATION) && (
                <Field name="specialization">
                  {({ input }) => (
                    <div className={styles.inputWrapper}>
                      <p>{words['user.subcontract.taskFilter.specialization']}</p>
                      <NewUIKitSelect
                        options={[allOption, ...positions]}
                        {...input}
                        onChange={e => {
                          form.change(input.name, e as TSelectOption)
                        }}
                      />
                    </div>
                  )}
                </Field>
              )}
              {isFieldExists(ESubcontractFilterFields.RANK) && (
                <Field name="level">
                  {({ input }) => (
                    <div className={styles.inputWrapper}>
                      <p>{words['user.subcontract.taskFilter.level']}</p>
                      <NewUIKitSelect
                        options={[allOption, ...level]}
                        {...input}
                        onChange={e => {
                          form.change(input.name, e as TSelectOption)
                        }}
                      />
                    </div>
                  )}
                </Field>
              )}
              {isFieldExists(ESubcontractFilterFields.POSITION) && (
                <Field name="position">
                  {({ input }) => (
                    <div className={styles.inputWrapper}>
                      <p>{words['user.subcontract.taskFilter.specialization']}</p>
                      <NewUIKitSelect
                        options={[allOption, ...positions]}
                        {...input}
                        onChange={e => {
                          form.change(input.name, e as TSelectOption)
                        }}
                      />
                    </div>
                  )}
                </Field>
              )}
              {isFieldExists(ESubcontractFilterFields.USER_RANK) && (
                <Field name="rank">
                  {({ input }) => (
                    <div className={styles.inputWrapper}>
                      <p>{words['user.subcontract.taskFilter.level']}</p>
                      <NewUIKitSelect
                        options={[allOption, ...level]}
                        {...input}
                        onChange={e => {
                          form.change(input.name, e as TSelectOption)
                        }}
                      />
                    </div>
                  )}
                </Field>
              )}
              {isFieldExists(ESubcontractFilterFields.PUBLICATION_DATE_FROM) && (
                <div className={styles.inputWrapper}>
                  <DatePickerRangeNew
                    label={words['user.subcontract.taskFilter.publicationDate']}
                    name="publicationDateFrom"
                    secondName="publicationDateTo"
                    form={form}
                    fieldData={{ from: '', to: '' }}
                  />
                </div>
              )}
              {isFieldExists(ESubcontractFilterFields.SUBMITTING_DATE_FROM) && (
                <div className={styles.inputWrapper}>
                  <DatePickerRangeNew
                    label={words['user.subcontract.taskFilter.deliveryDate']}
                    name="submittingDateFrom"
                    secondName="submittingDateTo"
                    form={form}
                    fieldData={{ from: '', to: '' }}
                  />
                </div>
              )}
              {isFieldExists(ESubcontractFilterFields.STATUS) && (
                <Field name="status">
                  {({ input }) => (
                    <div className={styles.inputWrapper}>
                      <p>{words['user.subcontract.taskFilter.status']}</p>
                      <NewUIKitSelect
                        options={[allOption, ...statusOptions]}
                        {...input}
                        onChange={e => {
                          form.change(input.name, e as TSelectOption)
                        }}
                      />
                    </div>
                  )}
                </Field>
              )}
              {isFieldExists(ESubcontractFilterFields.BUDGET_FROM) && (
                <div className={cn(styles.inputWrapper, styles.budget)}>
                  <p>
                    <span>{words['user.subcontract.taskFilter.budget']}</span>
                    <span>{words['user.subcontract.taskFilter.currency']}</span>
                  </p>
                  <div className={styles.field}>
                    <Field name="budgetFrom">
                      {({ input }) => (
                        <div className={styles.budgetInput}>
                          <Input
                            variant="outlined"
                            {...input}
                            onChange={e => {
                              const value = e.currentTarget.value
                              if (+value) form.change(input.name, value)
                            }}
                          />
                        </div>
                      )}
                    </Field>
                    <div className={styles.dash} />
                    <Field name="budgetTo">
                      {({ input }) => (
                        <div className={styles.budgetInput}>
                          <Input
                            variant="outlined"
                            {...input}
                            onChange={e => {
                              const value = e.currentTarget.value
                              if (+value) form.change(input.name, e.currentTarget.value)
                            }}
                          />
                        </div>
                      )}
                    </Field>
                    <Field name="currency">
                      {({ input }) => (
                        <div className={styles.currency}>
                          <NewUIKitSelect
                            options={[allOption, ...getCurrencyList(tokens)]}
                            placeholder=""
                            {...input}
                            onChange={e => {
                              form.change(input.name, e as TSelectOption)
                            }}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
              )}
              {isFieldExists(ESubcontractFilterFields.TECHNOLOGY) && (
                <Field name="technology">
                  {({ input }) => (
                    <div className={cn(styles.inputWrapper, styles.input)}>
                      <p>{words['user.subcontract.taskFilter.technology']}</p>
                      <Input
                        variant="outlined"
                        {...input}
                        onChange={e => {
                          form.change(input.name, e.currentTarget.value)
                        }}
                      />
                    </div>
                  )}
                </Field>
              )}
            </div>
            <div className={styles.buttons}>
              <div className={styles.cancel}>
                <Button
                  className="btn-discharge"
                  onClick={() => {
                    form.reset()
                    form.submit()
                    history.push(
                      `${history.location.pathname}?${queryString.stringify({
                        ...parsed,
                        page: 1,
                        category: '',
                        currency: '',
                        rank: '',
                        specialization: '',
                        fromPrice: '',
                        toPrice: '',
                        fromDate: '',
                        toDate: '',
                        status: '',
                        isClear: ''
                      })}`
                    )
                  }}
                >
                  <IconCancel /> <span>{words['user.subcontract.taskFilter.reset']}</span>
                </Button>
              </div>
              <div className={styles.acceptBtn}>
                <Button className="btn-primary-outline" type="submit" onClick={() => form.submit()}>
                  {words['user.subcontract.taskFilter.apply']}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
  )
}
