import { Dispatch } from 'redux'
import { AxiosError } from 'axios'
import toastr from 'utils/toastr'
import {
  sendInterviewInvitationError,
  sendInterviewInvitationLoading,
  sendInterviewInvitationSuccess,
  getTimeRequest,
  getTimeSuccess,
  getTimeError,
  getInvitedUserRequest,
  getInvitedUserSuccess
} from './InterviewInvitation.actions'
import { API, api } from 'services/api'
import { TState } from 'store'
import { IGoogleEvent } from './InterviewInvitation.model'
import history from 'utils/history'

export const sendInterviewInvitation = (body: any) => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  dispatch(sendInterviewInvitationLoading())
  const words = getData().global.language.words
  try {
    await api.post(`${API.URL}${API.INTERVIEW_REQUEST}`, body)
    dispatch(sendInterviewInvitationSuccess())
  } catch (error) {
    const err = error as AxiosError
    dispatch(sendInterviewInvitationError(err))
    toastr('error', words['FILED_PROCESS_REQUEST'], err)
  }
}

export const addEventToGoogleCalendar = (body: IGoogleEvent) => async () => {
  try {
    await api.post(`${API.URL}${API.GOOGLE_CALENDAR_REQUEST}/create-meet`, body)
    toastr('success', 'Interview invite was success added!')
    history.goBack()
  } catch (err) {
    toastr('error', 'Something went wrong!')
    console.log(err)
  }
}

export const getInvitedUser = (id: number) => async (dispatch: Dispatch) => {
  dispatch(getInvitedUserRequest())
  try {
    const invitedUser = await api.get(`${API.URL}${API.CLIENT}/${id}`)
    dispatch(getInvitedUserSuccess(invitedUser.data.email))
  } catch (e) {
    console.log(e)
  }
}

export const getTime = (data: any) => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  dispatch(getTimeRequest())
  const words = getData().global.language.words
  try {
    const response = await api.get(
      `${API.URL}${API.GOOGLE_CALENDAR_REQUEST}/free-time?calendarId=${data.email}&date=${
        data.date
      }&fromTime=${data.from}&toTime=${data.to}&timeZone=${data.timezone}`
    )
    const payload = {
      [data.id]: response.data
    }
    dispatch(getTimeSuccess(payload))
  } catch (error) {
    const err = error as AxiosError
    dispatch(getTimeError(err))
    toastr('error', words['FILED_PROCESS_REQUEST'], err)
  }
}
