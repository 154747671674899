import { API, api } from 'services/api'
import { headers } from '../constants/constants'
import { IChannelSettings } from '../../ControlPanel.types'

export async function deleteChannelsSettings(endpoint: string, channels: IChannelSettings[]) {
  try {
    channels.forEach(async channel => {
      await api.delete(`${API.URL}${endpoint}`, {
        headers,
        data: { channelName: channel.channelName }
      })
    })
  } catch (error) {
    console.log(error)
  }
}
