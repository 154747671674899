import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import CryptoBotComponent from './CryptoBot'
import { getTasks } from './CryptoBot.thunk'
import { getBotInfo, updateBotInfo } from './components/ControlPanel/ControlPanel.thunk'

export const mapStateToProps = (state: TState) => {
  return {
    state: state.client,
    user: state.auth.data,
    exchangeType: state.client.controlPanel.botInfo.exchangeType
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getTasks,
      updateBotInfo,
      getBotInfo
    },
    dispatch
  )

export const CryptoBot = connect(
  mapStateToProps,
  mapDispatchToProps
)(CryptoBotComponent)
