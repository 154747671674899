import React, { FC, useRef } from 'react'
import { generatePDF } from './getPDF'
import invoiceIcon from '../../assets/images/invoice_pdf_icon.svg'
import InvoiceModal from '../ProjectModals/InvoiceModal'
// import { useDispatch } from 'react-redux'
// import { TState } from '../../store'
// import { getOneProjectInvoice } from '../Project/Invoices/Invoices.thunk'
interface DownloadInvoiceProps {
  d?: any
  componentRef?: any
}
const DownloadInvoice: FC<DownloadInvoiceProps> = ({ d }) => {
  const downloadRef = useRef<any>(null)
  const handleDownloadClick = (e: any) => {
    e.stopPropagation()
    generatePDF(downloadRef, d.id)
  }
  return (
    <>
      <div style={{ cursor: 'pointer' }} onClick={(e: any) => handleDownloadClick(e)}>
        <img src={invoiceIcon} alt="invoice" />
      </div>
      <InvoiceModal isHidden={true} ref={downloadRef} invoice={d} toggle={true} />
    </>
  )
}

export default DownloadInvoice
