import React, {FC, forwardRef} from 'react'
import invoiceStyles from "../../screens/client/Subcontract/components/SubcontractInvoice/invoice.module.scss";
import logo from "../../assets/images/logo-black.svg";
import classNames from "classnames";
import moment from "moment";
import { useSelector } from "react-redux";
import { TState } from "../../store";

interface TInvoiceUiProps {
  task?: any
  project?: any
  invoice?: any
  type: 'task' | 'project'
  ref? :any
  isModal?: boolean
  toggle?: boolean
}

const InvoiceComponent: FC<TInvoiceUiProps> = forwardRef(({ invoice, type }, ref: any) => {
  const parseData = (data:string) => {
    const parsedDataStart = data.split('-')
    const date = parsedDataStart[2].substr(0,2)
    return parsedDataStart[1] +'.' + date +'.' +  parsedDataStart[0]
  }
  const words = useSelector((state: TState) => state.global.language.words)
  const calculateCountOfHours = (firstDate : any, secondDate : any, workingHoursPerDay: any) => {
    const fromDate = new Date(firstDate)
    const toDate = new Date(secondDate)
    const days = (date1: any, date2: any) =>{
      const difference = date2.getTime() - date1.getTime();
      return Math.ceil(difference / (1000 * 3600 * 24));
    }
    const countOfDays = days(fromDate, toDate)
    return workingHoursPerDay * countOfDays
  }
  moment.locale('en');
  return (
    <div className={classNames({[invoiceStyles.invoiceWrapper] : type === 'task'})}>
      <div className={ classNames({[invoiceStyles.invoice__modal] : true})}>
        <div ref={ref}  className={invoiceStyles.invoice__modal}>
          <div className={invoiceStyles.invoice}>
            {type === 'task' &&
              <div className={invoiceStyles.invoice__left}>
                <div className="invoice__head">
                  <h3 className={invoiceStyles.invoice__title}>Invoice</h3>
                  <span className={invoiceStyles.invoice__number}>№ {String(invoice.id).padStart(6, '0')}</span>
                </div>
                <div className={invoiceStyles.invoice__companyInfo}>
                  {/* eslint-disable-next-line prettier/prettier */}
                  <h4 className={invoiceStyles.invoice__companyInfoName}>{invoice?.task?.customer?.fullName}</h4>
                </div>
                <div className={invoiceStyles.invoice__recipient}>
                  <span>Bill To:</span>
                  <h4 className={invoiceStyles.invoice__recipientOwner}>
                    {invoice?.task?.type === '4' ? invoice.task.companyName : invoice?.task?.executor?.fullName}
                  </h4>
                </div>
              </div>
            }
            {type === 'project' &&
              <div className={invoiceStyles.invoice__left}>
                <div className="invoice__head">
                  <h3 className={invoiceStyles.invoice__title}>Invoice</h3>
                  <span style={{ marginTop: '10px', display: 'block' }} className={invoiceStyles.invoice__number}>№ {invoice?.number}</span>
                </div>
                <div style={{ marginTop: '16px' }} className={invoiceStyles.invoice__companyInfo}>
                  {/* eslint-disable-next-line prettier/prettier */}
                  <h4 className={invoiceStyles.invoice__companyInfoName}>TECHNORELY HOLDING INC</h4>
                  <ul>
                    <li>Business Number: 760353680BC0001</li>
                    <li>Incorporation Number: BC1160255</li>
                    <li>Address: 208-6939, Hastings st., Burnaby, BC, Canada,  postal code v5b 1s9</li>
                    <li>Bank name: ROYAL BANK OF CANADA</li>
                    <li>Bank address: 439 North Road, Coquitlam, BC,Canada , postal code v3k 3v9</li>
                    <li>ABA (Routing number): 021000021</li>
                    <li>Bank account (USD): 01200 003 4003679</li>
                    <li>SWIFT: ROYCCAT2</li>
                  </ul>
                </div>
                <div className={invoiceStyles.invoice__recipient}>
                  <span>Bill To:</span>
                  <h4 className={invoiceStyles.invoice__recipientOwner}>
                    {invoice?.project?.companyName}
                  </h4>
                  Company number: {invoice?.project?.registerNumber} <br/>
                  {invoice?.project?.fullAddress}
                </div>
              </div>
            }
            {type === 'task' &&
              <div className={invoiceStyles.invoice__right}>
                <img src={logo} />
                <ul className={invoiceStyles.invoice__price}>
                  <li className={invoiceStyles.invoice__priceItem}>
                    <span className={invoiceStyles.invoice__priceInfo}>Model of working:</span>
                    <span className={invoiceStyles.invoice__priceData}>
                    {invoice?.task?.type === '1' &&
                      'Dedicated Team'
                    }
                      {invoice?.task?.type === '2' &&
                        'Time And Materials'
                      }
                      {invoice?.task?.type === '3' &&
                        'Fixed Price'
                      }
                      {invoice?.task?.type === '4' &&
                        'Project Based'
                      }
                  </span>
                  </li>
                  <li className={invoiceStyles.invoice__priceItem}>
                    <span className={invoiceStyles.invoice__priceInfo}>Date of Payment:</span>
                    <span className={invoiceStyles.invoice__priceData}>{moment(invoice.dateOfPayment).format('MMM Do YYYY')}</span>
                  </li>
                  <li className={invoiceStyles.invoice__priceItemTotal}>
                    <span>Amount:</span>
                    <span className={invoiceStyles.invoice__priceAmount}> {invoice?.task?.price ? invoice?.task?.price.toFixed(2) + ' ' + 'USD'  : 'negotiable'} </span>
                  </li>
                </ul>
              </div>
            }
            {type === 'project' &&
              <div style={{ alignItems: 'flex-end' }} className={invoiceStyles.invoice__right}>
                <img width={132} src={logo} />
                <ul style={{ paddingTop: '20px' }} className={invoiceStyles.invoice__price}>
                  <li className={invoiceStyles.invoice__priceItem}>
                    <span className={invoiceStyles.invoice__priceInfo}>Date</span>
                    <span className={invoiceStyles.invoice__priceData}>{moment(invoice?.formationDate).format('ll')}</span>
                  </li>
                  <li className={invoiceStyles.invoice__priceItem}>
                    <span className={invoiceStyles.invoice__priceInfo}>Payment Terms:</span>
                    <span className={invoiceStyles.invoice__priceData}>{invoice?.project?.paymentMethod}</span>
                  </li>
                  <li className={invoiceStyles.invoice__priceItem}>
                    <span className={invoiceStyles.invoice__priceInfo}>Due Date:</span>
                    <span className={invoiceStyles.invoice__priceData}>{moment(invoice?.paymentDate).format('ll')}</span>
                  </li>
                  <li className={classNames(invoiceStyles.invoice__priceItemTotal, invoiceStyles.invoice__priceItemProject)}>
                    <span>Balance Due:</span>
                    <span className={classNames(invoiceStyles.invoice__priceAmount,invoiceStyles.invoice__priceAmountProject )}>${invoice?.totalAmount}</span>
                  </li>
                </ul>
              </div>
            }
          </div>
          {type === 'task' &&
            <table className={invoiceStyles.invoice__table}>
             <tbody>
             <tr>
                {invoice?.task?.type !== '4' &&
                  <th className={invoiceStyles.invoice__tableTitle}>Item</th>
                }
                {invoice?.task?.type === '1' &&
                  <th className={invoiceStyles.invoice__tableTitle}>Specialization</th>
                }
                {invoice?.task?.type === '4' &&
                  <th className={invoiceStyles.invoice__tableTitle}>Specialization</th>
                }
                {invoice?.task?.type !== '3' &&
                  <th className={invoiceStyles.invoice__tableTitle}>Period</th>
                }
                {invoice?.task?.type === '2' &&
                  <th className={invoiceStyles.invoice__tableTitle}>Hours</th>
                }
                {invoice?.task?.type === '2' &&
                  <th className={invoiceStyles.invoice__tableTitle}>Rate</th>
                }
                {invoice?.task?.type === '3' &&
                  <th className={invoiceStyles.invoice__tableTitle}>Date of Payment:</th>
                }
                <th className={invoiceStyles.invoice__tableTitle}>Amount</th>
              </tr>
              <tr>
                <td>{invoice?.task?.title}</td>
                {invoice?.task?.type === '1' &&
                  invoice?.task.specializations.map((specialization:any,index:number) => <td key={index}> {specialization.label} </td>)
                }
                {invoice?.task?.type !== '3' &&
                  <td> { invoice?.task?.startProjectDate ? parseData(invoice?.task?.startProjectDate) + ' - ' + parseData(invoice?.task?.deadline) : 'no terms' }  </td>
                }
                {invoice?.task?.type === '2' &&
                  <th>{invoice.task.price}</th>
                }
                {invoice?.task?.type === '2' &&
                  <th>{20 + ' ' + 'USD' }</th>
                }
                {invoice?.task?.type === '3' &&
                  <td>04.04.2023</td>
                }
                <td>{invoice?.task?.price ? invoice?.task?.price.toFixed(2) + ' ' + 'USD' : 'negotiable'}</td>
              </tr>
             </tbody>
            </table>
          }
          {type === 'project' &&
            <>
              <table className={classNames(invoiceStyles.invoice__table, invoiceStyles.invoice__tableProject)}>
                <thead>
                <tr>
                  <th className={invoiceStyles.invoice__tableTitle} style={{ minWidth: '113px', maxWidth:'150px' }}>Item</th>
                  <th className={invoiceStyles.invoice__tableTitle} style={{ minWidth: '65px' }}>Period</th>
                  <th className={invoiceStyles.invoice__tableTitle}>Hours</th>
                  <th className={invoiceStyles.invoice__tableTitle}>Rate</th>
                  <th className={invoiceStyles.invoice__tableTitle}>Amount</th>
                </tr>
                </thead>
                <tbody>
                  { invoice?.usersDates?.map((user: any) => <tr key={user.id}>
                    <td>{words[user.user.position.name]}</td>
                    <td>{ moment(user.from).format('L') + ' - ' + moment(user.to).format('L') }</td>
                    <td>{calculateCountOfHours(user.from, user.to, user.user.workingHoursPerDay)}</td>
                    <td>$10.00</td>
                    <td>${calculateCountOfHours(user.from, user.to, user.user.workingHoursPerDay) * 10}</td>
                  </tr>  ) }
                </tbody>
              </table>
              <div style={{ padding: '30px' }}>
                <ul className={invoiceStyles.invoice__discount}>
                  <li className={classNames(invoiceStyles.invoice__priceItem, invoiceStyles.invoice__discount)}>
                    <span className={invoiceStyles.invoice__priceInfo}>Amount Due:</span>
                    <span className={invoiceStyles.invoice__priceData}>${invoice?.totalAmount}</span>
                  </li>
                  <li className={classNames(invoiceStyles.invoice__priceItem, invoiceStyles.invoice__discount)}>
                    <span className={invoiceStyles.invoice__priceInfo}>{invoice?.discountAmount ? 'Discount (20% to be paid in shares):' : ''}</span>
                    <span className={invoiceStyles.invoice__priceData}>{invoice?.discountAmount || ''}</span>
                  </li>
                </ul>
              </div>
              <div className={invoiceStyles.invoice__notes}>
                <span className={invoiceStyles.invoice__notesTitle}>Notes:</span> <br/>
                PAY ATTENTION!!!! Please, write in Details of payment: Service agreement № {moment(invoice?.formationDate).format('L')} dated {moment(invoice?.formationDate).format('ll')}.
                The Invoice is valid electronically.
              </div>
            </>
          }
        </div>
      </div>
    </div>
  )
})

export default InvoiceComponent
