import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import VerificationComponent from './Verification'
import { getUserProfileInfo } from 'screens/client/Profile/Profile.thunx'
import { updateUser } from 'screens/client/EditProfile/EditProfile.thunx'

export const mapStateToProps = (state: TState) => {
  return {
    user: state.client.profile.user,
    id: state.auth.data.id
  }
}

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateUser,
      getUserProfileInfo
    },
    dispatch
  )

export const Verification = connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationComponent)
