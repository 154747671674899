import React, { FC, useState } from 'react'
import Modal from 'components/Modal'
import { Field, Form } from 'react-final-form'
import styles from './ProfileModalPointsTransferForm.module.scss'
import { Button } from 'components/UiKit/Button'
import { Input } from 'components/UiKit/Inputs'
import { TProfileModalPointsTransferForm } from './ProfileModalPointsTransferForm.model'
import { Icons } from 'components/Icons'
import validation from 'utils/validation'
import { Textarea } from 'components/Textarea'
import { EPointsEdit } from 'globalConfigs'
import { Select } from 'Select'
import { TSelectOption } from 'components/Select'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { TUpdatePointsBalanceBody } from '../../../../admin/Users/Users.model'
import { RedNote } from '../../../../../components/RedNote'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export const ProfileModalPointsTransferForm: FC<TProfileModalPointsTransferForm> = ({
  open,
  togglePointsTransfer,
  users,
  changeUserPointsTransfer
}) => {
  const [user, setUser] = useState()
  const words = useSelector((state: TState) => state.global.language.words)

  return (
    <Modal isShow={open} onClose={togglePointsTransfer} className={styles.modal}>
      <Form
        onSubmit={(values: { [key: string]: any }) => {
          const body: TUpdatePointsBalanceBody = {
            transactions: [
              {
                // @ts-ignore
                receiverID: user.value,
                amount: Number(values.points) || 0,
                comment: values.comments || ''
              }
            ]
          }
          changeUserPointsTransfer(body)
        }}
      >
        {({ form, handleSubmit }) => {
          const formState = form.getState()
          return (
            <form onSubmit={handleSubmit} className={styles.form}>
              <header>{words['user.profile.table.translatePoints']}</header>

              <div className={styles['form-row']}>
                <div>
                  <label className={styles['users-select-label']}>
                    {words['admin.settings.pointsSystem.balanceEdit.userName']}
                    <RedNote />
                    <Field
                      name="users"
                      validate={validation.composeValidators(
                        validation.required(words['user.requiredMessage'])
                      )}
                    >
                      {({ input, meta }) => (
                        <div data-tip="" data-for="users" id="users">
                          <Select
                            placeholder={words['user.dayOffTracker.enterSurnameAndName']}
                            options={users}
                            className={styles['users-select']}
                            {...input}
                            isInvalid={meta.error && meta.submitFailed}
                            onChange={event => {
                              setUser({
                                // @ts-ignore
                                value: (event as TSelectOption).value,
                                label: (event as TSelectOption).label
                              })
                              form.change('users', event)
                            }}
                            emptyMessage={words['noOption']}
                          />

                          {meta.error && meta.submitFailed && (
                            <ReactTooltip
                              id="users"
                              anchorSelect="#users"
                              place="bottom"
                              variant="error"
                              content={meta.error}
                            />
                          )}
                        </div>
                      )}
                    </Field>
                  </label>
                </div>
                <div className={styles['balance-input-container']}>
                  <div className={styles['input-label']}>
                    {words['admin.settings.pointsSystem.balanceEdit.points']}
                    <RedNote />
                  </div>
                  <Field
                    name="points"
                    validate={validation.composeValidators(
                      validation.required(words['user.requiredMessage']),
                      validation.isNumber(),
                      validation.min(0)
                    )}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className={styles['div-wrapper']}>
                          <button
                            onClick={() => {
                              !formState.values.points ||
                              typeof formState.values.points !== 'number'
                                ? form.change('points', -EPointsEdit.STEP)
                                : form.change(
                                    'points',
                                    Number(formState.values.points) - EPointsEdit.STEP
                                  )
                            }}
                            type="button"
                            disabled={!formState.values.points || formState.values.points <= 0}
                          >
                            <Icons icon="remove" />
                          </button>
                          <div className={styles['input-wrapper']}>
                            <Input
                              variant="outlined"
                              type="number"
                              isInvalid={meta.error && meta.submitFailed}
                              errorMessage={meta.error}
                              {...input}
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              !formState.values.points ||
                              typeof formState.values.points !== 'number'
                                ? form.change('points', EPointsEdit.STEP)
                                : form.change(
                                    'points',
                                    Number(formState.values.points) + EPointsEdit.STEP
                                  )
                            }}
                          >
                            <Icons icon="add" />
                          </button>
                        </div>
                      )
                    }}
                  </Field>
                </div>
              </div>

              <div className={styles['comments-wrapper']}>
                <Field
                  name="comments"
                  validate={validation.composeValidators(
                    validation.required(words['user.requiredMessage']),
                    validation.minValue(2)
                  )}
                >
                  {({ input, meta }) => {
                    return (
                      <div>
                        <Textarea
                          {...input}
                          className={styles['comments']}
                          title={
                            <>
                              {words['manager.dayOffTracker.details.comment']}
                              <RedNote />
                            </>
                          }
                          placeholder={
                            words['admin.settings.pointsSystem.balanceEdit.comment.placeHolder']
                          }
                          isInvalid={meta.error && meta.submitFailed}
                          errorMessage={meta.error}
                        />
                      </div>
                    )
                  }}
                </Field>
              </div>

              <div className={styles['form-row-btn']}>
                <Button type="submit" className={styles['div-btn-wrapper']}>
                  {words['admin.PointsSystem.balanceEdit.buttons.apply']}
                </Button>
              </div>
            </form>
          )
        }}
      </Form>
    </Modal>
  )
}
