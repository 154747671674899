import React, { FC, useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import history from 'utils/history'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import styles from './Projects.module.scss'
import { getFilterConfig } from './Projects.filter.config'
import { Filter } from '../../../components/UiKit/Filter'
import queryString from 'query-string'
import { Button } from '../../../components/UiKit/Button'
import { Icons } from '../../../components/Icons'
import { TProjectItem, TProjectsProps } from './Projects.model'
import ProjectPreview from './components/ProjectPreview/ProjectPreview'
import Pagination from '../../../components/Pagination/Pagination'
import { ReactComponent as Prev } from 'assets/images/prev.svg'
import { ReactComponent as Next } from 'assets/images/next_green.svg'
import { EPageLimits } from '../../../globalConfigs'
import { IToggleBarData, ToggleBar } from 'components/ToggleBar'
import Loading from '../../../components/Spinner'

const Projects: FC<TProjectsProps> = ({
  displayAllFields,
  getProjects,
  getManagers,
  data,
  loading,
  managers
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const parsed = queryString.parse(history.location.search)
  const [pageNumber, setPageNumber] = useState(1)

  const toggleBarOptions = [
    {
      value: 'active',
      title: words['project.active']
    },
    {
      value: 'deleted',
      title: words['project.archive']
    }
  ]

  const [selectedType, setSelectedType] = useState<IToggleBarData>(
    parsed.deleted === 'true' ? toggleBarOptions[1] : toggleBarOptions[0]
  )

  const handlePageChange = (page: number) => {
    setPageNumber(page)
    getProjects(page, `${location.search}`, EPageLimits.PROJECTS)
  }

  // useEffect(() => {

  // }, [])

  useEffect(() => {
    getManagers()
    getProjects(pageNumber, `${location.search}`, EPageLimits.PROJECTS)
  }, [location.search])

  const projectsArr =
    data &&
    data.results
      .sort((a: TProjectItem, b: TProjectItem) => a.id - b.id)
      .map((project: TProjectItem, i: number) => (
        <ProjectPreview key={i} name={project.name} avatar={project.avatar} id={project.id} />
      ))

  if (!parsed.deleted) {
    return <Redirect to={{ search: 'deleted=false', pathname: history.location.pathname }} />
  }

  return (
    <div className={styles['container']}>
      <section>
        <div className={styles.header}>
          <h1>{words['project.projects']}</h1>
        </div>
      </section>

      <section className={styles['buttons']}>
        <div className={styles['tabs']}>
          <ToggleBar
            name="active-projects-no-active"
            data={toggleBarOptions}
            defaultChecked={selectedType}
            onChange={event => {
              if (event.value === 'deleted') {
                history.push(
                  `${history.location.pathname}?${queryString.stringify({
                    deleted: 'true'
                  })}`
                )
              }
              if (event.value !== 'deleted') {
                history.push(
                  `${history.location.pathname}?${queryString.stringify({
                    deleted: 'false'
                  })}`
                )
              }
              setSelectedType(event)
            }}
          />
        </div>
        <div className={styles['links']}>
          <button>
            <Icons icon={'timeIcon'} /> {words['project.history']}
          </button>
          <Button
            size="lgs"
            type="button"
            onClick={() => history.push('/dashboard/create-project')}
          >
            {words['project.create']}
          </Button>
        </div>
      </section>

      <section className={styles['filter']}>
        <Filter
          config={getFilterConfig({
            displayAllFields,
            managers,
            words
          })}
          isMultilineBtn={true}
          withButton={false}
          defaultOpened={true}
        />
      </section>

      <section className={styles['projects']}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%'
            }}
          >
            <Loading />
          </div>
        ) : data && data.total > 0 ? (
          projectsArr
        ) : (
          <div className={styles['no-data']}>{words['noData']}</div>
        )}
      </section>

      {data && data.total > EPageLimits.PROJECTS ? (
        <section className={styles.pagination}>
          <Pagination
            pageSize={EPageLimits.PROJECTS}
            totalCount={data && data.total}
            currentPage={pageNumber}
            onPageChange={handlePageChange}
            siblingCount={0}
            arrowLeft={<Prev />}
            arrowRight={<Next />}
          />
        </section>
      ) : null}
    </div>
  )
}

export default Projects
