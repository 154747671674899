export const GET_PROJECT_INVOICES_REQUEST: string = 'GET_PROJECT_INVOICES_REQUEST'
export const GET_PROJECT_INVOICES_SUCCESS: string = 'GET_PROJECT_INVOICES_SUCCESS'
export const GET_PROJECT_INVOICES_ERROR: string = 'GET_PROJECT_INVOICES_ERROR'
export const GET_ONE_PROJECT_INVOICE_SUCCESS: string = 'GET_ONE_PROJECT_INVOICE_SUCCESS'
export const SELECT_PROJECT_INVOICE: string = 'SELECT_PROJECT_INVOICE'

export const DELETE_SELECTED_PROJECT_INVOICE: string = 'DELETE_SELECTED_PROJECT_INVOICE'
export const DELETE_PROJECT_INVOICE: string = 'DELETE_PROJECT_INVOICE'

export const SELECT_ALL_PROJECT_INVOICES: string = 'SELECT_ALL_PROJECT_INVOICES'
