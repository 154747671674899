export interface IWeb3InitialState {
  userAddress: string | null
  trlTokenPrice: string | null
  isTransaction: boolean
}

export interface ITransactionReceipt {
  transactionHash: string
  transactionIndex: number
  blockHash: string
  blockNumber: number
  from: string
  to: string
  cumulativeGasUsed: number
  gasUsed: number
  contractAddress?: string
  logs: Log[]
  status: boolean | string
  logsBloom: string
  events?: {
    [event: string]: EventLog
  }
}

interface Log {
  address: string
  blockHash: string
  blockNumber: number
  data: string
  logIndex: number
  topics: string[]
  transactionHash: string
  transactionIndex: number
  id: string
  type: string
  event: string
  returnValues: {
    [key: string]: any
  }
}

interface EventLog {
  event: string
  address: string
  returnValues: {
    [key: string]: any
  }
  logIndex: number
  transactionIndex: number
  transactionHash: string
  blockHash: string
  blockNumber: number
  raw?: {
    data: string
    topics: string[]
  }
}

export interface ISignTaskThunkProps {
  task: any
  handleTransactionHash?: () => void
  handleReceipt?: () => void
}

export interface IDeal {
  chousedProposal: string
  contractor: string
  contractorSignature: string
  currency: string
  customer: string
  ethDeal: boolean
  proposals: IProposal[]
  refundContractorPercent: string
  refundCustomerPercent: string
  status: EDealStatus
  taskHash: string
  taskSignature: string
}

export interface IProposal {
  amount: string
  contractorSignature: string
  from: string
  taskSignature: string
  to: string
}

export const enum EDealStatus {
  CREATED = 0,
  ACCEPTED = 1,
  IN_PROGRESS = 2,
  REQUEST_CANCEL_BY_CLIENT = 3,
  REQUEST_CANCEL_BY_CONTRACTOR = 4,
  CANCELLED = 5,
  STOP_WITH_PROBLEM = 6,
  CANCELLED_BY_SUPPORT_COMMENT_FROM_CUSTOMER = 7,
  CANCELLED_BY_SUPPORT_COMMENT_FROM_CONTRACTOR = 8,
  CANCELLED_BY_SUPPORT_COMMENT_FROM_BOTH = 9,
  EXECUTED = 10,
  DONE = 11
}
