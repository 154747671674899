/* eslint-disable */
import React, { FC } from 'react'
import { TLoginProps } from './Login.model'

import styles from './Login.module.scss'
import Header from './components/Header/Header'
import { Slider } from 'components/Slider'
import { Navigation } from 'swiper/modules'
import Slide, { ISlideProps } from './components/Slide/Slide'

import { IToggleBarData } from 'components/ToggleBar'
import Spinner from 'components/Spinner'
import { useAuth } from 'hooks/auth.hook'
import { Icons } from 'components/Icons'

const slideInfo: ISlideProps[] = [
  {
    id: 1,
    title: 'FIXED PRICE MODEL',
    description:
      'Reliable and transparent Fixed-Price services to ensure the best value for every investment. On our platform, clients receive high-quality results while professionals work diligently to deliver projects on time and within budget, providing everyone with peace of mind and guaranteed outcomes.',
    isIcons: true,

    icons: [
      <Icons icon="predictableCosts" />,
      <Icons icon="timelyDelivery" />,
      <Icons icon="expertTeam" />,
      <Icons icon="hightQualityResults" />,
      <Icons icon="flexibility" />,
      <Icons icon="transparency" />
    ],
    img: <Icons icon="fixedPriceLogin" />
  },
  {
    id: 2,
    title: 'TIME AND MATERIALS MODEL',
    description:
      'The Time and Materials model provides flexibility and transparency for both clients and professionals. With this model, projects are handled efficiently, and clients pay only for the actual time and resources used, ensuring fair and precise billing.',
    isIcons: true,
    icons: [
      <Icons icon="flexibleBudget" />,
      <Icons icon="transparentProcess" />,
      <Icons icon="adaptableSolutions" />,
      <Icons icon="realTimeTracking" />,
      <Icons icon="costEfficiency" />,
      <Icons icon="customizedApproach" />
    ],
    img: <Icons icon="timeAndMaterials" />
  },
  {
    id: 3,
    title: 'DEDICATED TEAM MODEL',
    description:
      'With Dedicated Team model, both clients and professionals enjoy dedicated focus and consistent project attention. Our carefully selected teams work solely on your projects, fitting in perfectly with your workflow and company culture to achieve exceptional outcomes.',
    isIcons: true,
    icons: [
      <Icons icon="exclusiveFocus" />,
      <Icons icon="seamlessIntegration" />,
      <Icons icon="consistentQuality" />,
      <Icons icon="specializedExpertise" />,
      <Icons icon="fullCommitment" />,
      <Icons icon="alignedGoals" />
    ],
    img: <Icons icon="dedicatedTeam" />
  },
  {
    id: 4,
    title: 'PROJECT-BASED MODEL',
    description:
      'Our flexible and tailored Project-Based model offers that the specific needs of both clients and performers are met with precision. All performers collaborate closely with clients to deliver customized solutions that align with their goals, ensuring a successful project outcome.',
    isIcons: true,
    icons: [
      <Icons icon="tailoredSolutions" />,
      <Icons icon="collaborativeApproach" />,
      <Icons icon="scalableExecution" />,
      <Icons icon="clientFocus" />,
      <Icons icon="innovativeSolutions" />,
      <Icons icon="continuousImprovements" />
    ],
    img: <Icons icon="projectBase" />
  }
]

export const Login: FC<TLoginProps> = ({ role, setUserRole }) => {
  const sliderOptions = {
    modules: [Navigation],
    slidesPerView: 1
  }

  const handleChangeRole = (data: IToggleBarData) => {
    setUserRole(+data.value)
  }

  const { isLoading } = useAuth()

  return (
    <div className={styles.wrapper}>
      <Header selectedType={role} setSelectedType={handleChangeRole} />

      <div className={styles.container}>
        <div className={styles.sliderWrapper}>
          <Slider<ISlideProps>
            className={styles.loginSlider}
            options={sliderOptions}
            slide={Slide}
            slideInfo={slideInfo}
          />
        </div>

        {isLoading && (
          <div className={styles.spinnerOverlay}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  )
}
