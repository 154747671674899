import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './Entrepreneurs.module.scss'
import { useSelector } from 'react-redux'
import { ToggleBar } from '../../../components/ToggleBar'
import { getToggleBarOptionsForEntrepreneurs } from '../../../globalConfigs'
import { TState } from '../../../store'
import {
  getEntrepreneurAdminType,
  setDefaultMainToggleBarStateForEntrepreneurs
} from '../../../utils/toggleBarsValue'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import { BaseButton } from '../../../components/UiKit/Button/BaseButton'
import FormulatedInvoices from './FormulatedInvoices/FormulatedInvoices'
import { generatePDF } from './Helpers/getPDF'
import { Button } from 'components/UiKit/Button'
import { Icons } from 'components/Icons'
import EntrepreneursList from './EntrepreneursList/EntrepreneursList'
import Offer from './FormulatedInvoices/Offer/Offer'
import { finishPointVacancies } from './initialValues'
import { TEntrepreneursProps } from './Entrepreneurs.model'
import toastr from 'utils/toastr'

const Entrepreneurs: FC<TEntrepreneursProps> = ({ getInvoices, getUsersWithBanks, users }) => {
  const offerRefs = useRef<any>([])
  const words = useSelector((state: TState) => state.global.language.words)
  const history = useHistory()
  const parsed = queryString.parse(history.location.search)

  const selectedInvoices = useSelector(
    (state: TState) => state.admin.entrepreneurs.selectedInvoices
  )
  const [selectedTaskType, setSelectedTaskType] = useState(
    setDefaultMainToggleBarStateForEntrepreneurs(parsed.tab, words)
  )
  useEffect(() => {
    getInvoices(finishPointVacancies())
    offerRefs.current = offerRefs.current.slice(0, selectedInvoices.length)
  }, [selectedInvoices])

  const handleDownloadClick = () => {
    if (offerRefs.current.length === 0) {
      toastr('warning', `You don't have any selected invoices`)
    }
    offerRefs.current.forEach((invoice: any, index: number) => {
      generatePDF(invoice, index, 'all')
    })
  }

  useEffect(() => {
    getUsersWithBanks()
  }, [])

  return (
    <>
      <div className={styles.container}>
        <section className={styles.header}>
          <h1>{words['entrepreneur.conducting']}</h1>
        </section>
        <div className={styles.wrapBar}>
          <ToggleBar
            name={'entrepreneur'}
            data={getToggleBarOptionsForEntrepreneurs(words)}
            defaultChecked={selectedTaskType}
            onChange={event => {
              history.push(
                `${history.location.pathname}?${queryString.stringify({
                  tab: getEntrepreneurAdminType(event.value)
                  // clear: 'true'
                })}`
              )
              setSelectedTaskType(event)
            }}
          />
          {parsed.tab === 'invoices' && (
            <BaseButton
              onClick={handleDownloadClick}
              style={{ marginTop: '25px' }}
              size={'exlg'}
              color={'green'}
            >
              {words['entrepreneur.invoices.downloadSelected']}
            </BaseButton>
          )}
          {parsed.tab === 'list' && (
            <div className={styles['buttons']}>
              <button className={styles['history']}>
                {' '}
                <Icons icon="timeIcon" /> {words['admin.entrepreneurs.history']}
              </button>
              <Button
                className="requesth"
                onClick={() => history.push('/dashboard/entrepreneurs/create-invoices')}
              >
                {words['admin.entrepreneurs.create']}
              </Button>
            </div>
          )}
        </div>
        {parsed.tab === 'invoices' && (
          <FormulatedInvoices
            componentRef={offerRefs.current[2]}
            parsed={parsed}
            invoicesArray={[]} // TODO: here must be invoicesArray
            getInvoices={getInvoices}
          />
        )}
        {parsed.tab === 'list' && <EntrepreneursList entrepreneurs={users} />}
        {selectedInvoices.map((invoice: any) => {
          // eslint-disable-next-line react/jsx-key
          return (
            <Offer
              key={invoice.id}
              isHidden={true}
              invoice={invoice}
              ref={(el: any) => (offerRefs.current[invoice.id] = el)}
            />
          )
        })}
      </div>
    </>
  )
}

export default Entrepreneurs
