import {
  IExchangeSettings,
  IChannelSettings,
  GlobalConfig,
  TChannels
} from 'screens/client/CryptoBot/components/ControlPanel/ControlPanel.types'
import { fetchSettingsFromCryptoBot } from './fetchSettingsFromCryptoBot'
import { sendInitialSettings } from './sendInitialSettings'

export async function postBotSettings(
  updatedExchangeSettings: IExchangeSettings,
  updatedChannels: IChannelSettings[] | TChannels[],
  updatedGlobalSettings: GlobalConfig,
  botId: string
) {
  try {
    const exchangeSettingsFromBot = await fetchSettingsFromCryptoBot(
      `/bot/${botId}/exchanges-settings`
    )

    let result = null

    if (!exchangeSettingsFromBot.length) {
      result = await sendInitialSettings(
        updatedExchangeSettings,
        updatedChannels,
        updatedGlobalSettings,
        botId
      )

      return result
    }

    return result
  } catch (error) {
    console.error(error)
    return null
  }
}
