import React, { FC } from 'react'
import cls from 'classnames'
import { TVotingStatusBarProps } from './VotingStatusBar.model'
import styles from './VotingStatusBar.module.scss'

const VotingStatusBar: FC<TVotingStatusBarProps> = ({ percent, color }) => {
  return (
    <div className={cls(styles['status-bar'], styles[color])}>
      <div className={styles['progress']} style={{ width: `${percent}%` }} />
      <div className={styles['last']} />
    </div>
  )
}

export { VotingStatusBar }
