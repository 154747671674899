/* eslint-disable no-console */
import { Dispatch } from 'redux'
import { API, api, apiVetifi } from '../../../../services/api'
import {
  updateUserRequest,
  updateUserSuccess,
  updateUserError,
  getUsersRequest,
  getUsersSuccess,
  getUsersError,
  polygonLoginRequest,
  polygonLoginError,
  polygonOfferSuccess
} from './VerificationAdmin.actions'
import toastr from 'utils/toastr'
import { TState } from 'store'
import { AxiosError } from 'axios'
import { transformApiResToFabricFormat } from 'utils/transformApiResToFabricFormat'
import moment, { Moment } from 'moment'

export const updateUser = (limit: number, page: any, url: string, filter: string = '') => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  dispatch(updateUserRequest())
  const words = getData().global.language.words

  try {
    const resp = await api.get(`${API.URL}${url}?page=${page}${filter}&limit=${limit}`)
    dispatch(
      updateUserSuccess({
        user: resp.data.payload.results
      })
    )
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(updateUserError(err.response))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}

export const getUsers = (limit: number, page: any, filter: string = '') => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  dispatch(getUsersRequest())
  const words = getData().global.language.words

  try {
    const { data } = await api.get(`${API.CLIENT}?page=${page}${filter}&limit=${limit}`)
    const positions = (await api.get(`${API.URL}${API.POSITIONS}`)).data.payload.result.map(
      (item: any) => transformApiResToFabricFormat(words, item)
    )
    const rank = (await api.get(`${API.URL}${API.RANK}`)).data.payload.result.map((item: any) =>
      transformApiResToFabricFormat(words, item)
    )
    dispatch(
      getUsersSuccess({ users: data.payload.results, total: data.payload.total, positions, rank })
    )
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(getUsersError(err))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}

export const createOffer = (_userAge: Moment, id: number, expiration: number) => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  dispatch(polygonLoginRequest())
  const words = getData().global.language.words
  try {
    const res: any = await api.get(`${API.CLIENT}/${id}`)
    const { data } = await apiVetifi.post(`polygon/${process.env.REACT_APP_ADMIN_DID}/claims`, {
      credentialSchema:
        'https://raw.githubusercontent.com/iden3/claim-schema-vocab/main/schemas/json/KYCAgeCredential-v3.json',
      type: 'KYCAgeCredential',
      credentialSubject: {
        id: res.data.did,
        birthday: +moment(_userAge).format('YYYYMMDD'),
        documentType: 2
      },
      expiration: Math.round(expiration / 1000)
    })
    const updatedUser = await api.put(`${API.CLIENT}/${id}`, {
      ...res.data,
      offerId: data.id,
      verification: 'claims'
    })
    console.log('updatedUser', updatedUser)
    dispatch(polygonOfferSuccess(data.id))
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(polygonLoginError(err))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}
