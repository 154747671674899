import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Field, Form } from 'react-final-form'
import { TState } from 'store'
import toastr from '../../utils/toastr'
import cls from 'classnames'
import validation from '../../utils/validation'
import { Button } from '../UiKit/Button'
import { RedNote } from '../RedNote'
import { Textarea } from '../Textarea'
import styles from './styles.module.scss'

type TModalProps = {
  toggle?: boolean
  action?: any
  size?: 'default' | 'md'
}

const ProposeTaskModal: FC<TModalProps> = ({ toggle, action, size = 'default' }) => {
  const words = useSelector((state: TState) => state.global.language.words)
  return (
    <div>
      <div onClick={action} className={cls({ [styles.overlay]: toggle })} />
      <div className={cls({ [styles.container]: true, [styles.active]: toggle })}>
        <div className={cls({ [styles.modal]: true, [styles[`modal-size-${size}`]]: true })}>
          <h2>{words['user.subcontract.suggestTask']}</h2>
          <Form
            onSubmit={() => {
              null
            }}
          >
            {({ form, handleSubmit }) => {
              const formState = form.getState()
              const checkRequired = () => {
                if (formState.errors && Object.keys(formState.errors).length !== 0) {
                  toastr('error', 'Fill required fields!')
                } else {
                  handleSubmit()
                }
              }
              return (
                <form onSubmit={handleSubmit} name="form">
                  <section className={styles.form}>
                    <div className={styles.message}>
                      <div className={styles.label}>
                        <label>
                          {words['user.taskSelection.comment']} {<RedNote />}
                        </label>
                      </div>
                      <Field
                        name="comment"
                        validate={validation.required(words['user.requiredMessage'])}
                      >
                        {({ input, meta }) => (
                          <Textarea
                            {...input}
                            isInvalid={meta.error && meta.submitFailed}
                            errorMessage={meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </section>
                  <section className={`${styles.buttons} ${styles['buttons-suggest']}`}>
                    <Button outline={true} size={'lg'} onClick={action}>
                      <span>{words['user.subcontract.cancelTaskModal.cancel']}</span>
                    </Button>
                    <Button onClick={checkRequired} size={'lg'}>
                      <span>{words['user.taskSelection.suggest']}</span>
                    </Button>
                  </section>
                </form>
              )
            }}
          </Form>
        </div>
      </div>
    </div>
  )
}

export default ProposeTaskModal
