import React, { useEffect, useMemo, useState } from 'react'
import { utils, write } from 'xlsx'
import { Table as UIKitTable } from '../../../../components/UiKit/Table'
import cls from 'classnames'
import { Columns } from './Table.columns'
import styles from './Table.module.scss'
import Checkbox from 'components/UiKit/Checkbox/Checkbox'
// import { IChecked, ICheckedList, IEntrepreneur } from '../ProjectInvoices.model'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { TChecked, TInvoice, TInvoiceToDownload } from '../ProjectInvoices.model'
import { Icons } from 'components/Icons'
import { ConfirmAction } from 'components/ConfirmAction'
import toastr from 'utils/toastr'
import DownloadInvoice from './DownloadInvoice/DownloadInvoice'

type TTableProps = {
  invoices: TInvoice[]
  getInvoices: () => void
  removeInvoice: (id: number) => void
}

const Table = ({ invoices, removeInvoice }: TTableProps) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const [isShowConfirm, setIsShowConfirm] = useState(false)
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<null | number>(null)
  const [checkedInvoices, setCheckedInvoices] = useState<TChecked>({})
  const [currentCheckedInvoices, setCurrentCheckedInvoices] = useState([] as TInvoice[])
  const [invoicesToDownload, setInvoicesToDownload] = useState<TInvoiceToDownload[]>([])
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false)
  const handleClick = (id: number) => {
    setSelectedInvoiceId(id)
    setIsShowConfirm(true)
  }

  function downloadExcelFile() {
    const workbook = utils.book_new()
    const worksheet = utils.json_to_sheet(invoicesToDownload)
    utils.book_append_sheet(workbook, worksheet, 'Invoices')
    const buffer = write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'invoices.xlsx'
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(url)
    document.body.removeChild(link)
  }

  const handleSave = () => {
    if (!invoicesToDownload.length) {
      toastr('error', words['projectInvoices.downloadError'])
    } else {
      downloadExcelFile()
    }
  }

  const handleConfirmSuccess = (e: React.MouseEvent<HTMLElement>, id: number) => {
    e.stopPropagation()
    removeInvoice(id)
    setIsShowConfirm(false)
    setSelectedInvoiceId(null)
  }

  const handleConfirmCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setIsShowConfirm(false)
    setSelectedInvoiceId(null)
  }

  const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>, value: TInvoice) => {
    setCheckedInvoices(old => {
      const newName = value.number + value.id
      const newObject = {
        ...value,
        checked: e.target.checked
      }
      return { ...old, [newName]: newObject }
    })
  }

  const columns = Columns({
    role: 2,
    invoices,
    checkedInvoices,
    setCheckedInvoices,
    isAllChecked,
    setIsAllChecked,
    currentCheckedInvoices
  })
  const columnsMemoized = useMemo(() => columns, [isAllChecked])

  useEffect(() => {
    setCurrentCheckedInvoices(invoices
      .map((entrepreneur: TInvoice) => {
        return checkedInvoices && checkedInvoices[entrepreneur.number + entrepreneur.id]
      })
      .filter(item => item !== undefined && item.checked) as TInvoice[])
  }, [checkedInvoices])

  useEffect(() => {
    setInvoicesToDownload(
      currentCheckedInvoices.map(invoice => {
        return {
          project: invoice.projectName,
          number: invoice.number,
          durationFrom: invoice.start ? convertDate(invoice.start) : '-',
          durationTo: invoice.end ? convertDate(invoice.end) : '-',
          team: invoice.userDates && invoice.userDates.length ? invoice.userDates.length : 0,
          totalAmount: invoice.totalAmount,
          formationDate: convertDate(invoice.formationDate),
          paymentDate: convertDate(invoice.paymentDate),
          discount: invoice.discount,
          discountAmount: `${invoice.discountAmount} ${invoice.discountUnit}`
        }
      })
    )
  }, [currentCheckedInvoices])

  useEffect(() => {
    if (currentCheckedInvoices.length < invoices.length) {
      setIsAllChecked(false)
    }
    if (currentCheckedInvoices.length === invoices.length) {
      setIsAllChecked(true)
    }
    if (invoices.length === 0) {
      setIsAllChecked(false)
    }
  }, [currentCheckedInvoices])

  const convertDate = (date: Date) => {
    const currentDate = new Date(date)
    const day = currentDate.getDate()
    const month = currentDate.getMonth() + 1
    const year = currentDate.getFullYear()
    return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`
  }

  const dataToRows = () => {
    return invoices.map(invoice => {
      return {
        check: (
          <Checkbox
            name={invoice.id.toString()}
            checked={
              checkedInvoices &&
              checkedInvoices[invoice.number + invoice.id] &&
              checkedInvoices[invoice.number + invoice.id].checked
            }
            onChange={e => handleChangeCheckbox(e, invoice)}
          />
        ),
        project: invoice.projectName,
        invoiceNumber: invoice.number,
        durationFromDate: `${invoice.start ? convertDate(invoice.start) : ''} -
            ${invoice.end ? convertDate(invoice.end) : ''}`,
        team: invoice.userDates && invoice.userDates.length,
        totalAmount: `${invoice.totalAmount} $`,
        formationDate: convertDate(invoice.formationDate),
        paymentDate: convertDate(invoice.paymentDate),
        discount: invoice.discount ? invoice.discount : '-',
        discountAmount:
          invoice.discountAmount && invoice.discountUnit
            ? `${invoice.discountAmount} ${
                invoice.discountUnit === 'USD' ? '$' : invoice.discountUnit
              }`
            : '-',
        download: <DownloadInvoice invoice={invoice} />,
        buttons: (
          <div className={styles['buttons-wrapper']}>
            <div className={styles['button']} onClick={() => handleClick(invoice.id)}>
              <Icons className={styles['button']} icon="cross" />
              {isShowConfirm && selectedInvoiceId === invoice.id && (
                <ConfirmAction
                  onCancel={(e: React.MouseEvent<HTMLElement>) => handleConfirmCancel(e)}
                  onSuccess={(e: React.MouseEvent<HTMLElement>) =>
                    handleConfirmSuccess(e, invoice.id)
                  }
                  children={words['projectInvoices.delete']}
                  successLabel={words['projectInvoices.yes']}
                  cancelLabel={words['projectInvoices.no']}
                />
              )}
            </div>
          </div>
        )
      }
    })
  }

  return (
    <Form
      onSubmit={() => ({})}
      render={() => {
        return (
          <form className={styles['primary-form']}>
            <div className={styles['table-form']}>
              <Field name={'table'}>
                {() => (
                  <div data-tip="" data-for="model" className={styles['table-wrapper']}>
                    <UIKitTable
                      data={dataToRows()}
                      columns={columnsMemoized}
                      total={invoices.length}
                      getData={() => ({})}
                      classNameToRow={cls({
                        [styles.header]: true
                      })}
                      tableHeight={'544px'}
                      noData={words['admin.entrepreneurs.emptyList']}
                      onSelectedRows={() => ({})}
                      onRowClick={() => ({})}
                    />
                    <div className={styles['save']} onClick={handleSave}>
                      .xlsx
                    </div>
                  </div>
                )}
              </Field>
            </div>
          </form>
        )
      }}
    />
  )
}
export default Table
