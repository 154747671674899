import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { Header as HeaderComponent } from './Header'
import { getUserPhoto, pulling } from '../../screens/auth/Login/Login.thunx'
import { addToCart, removeFromCart } from '../../screens/client/Basket/Basket.actions'
import {
  getTotalPriseSelector,
  getTotalQuantitySelector,
  getDataSelector
} from '../../screens/client/Basket/Basket.reducer'
import { getPointsSettings } from 'screens/admin/Settings/components/SettingsPointsSystem/SettingsPointsSystem.thunx'
import { checkUserProfile } from 'screens/client/Profile/Profile.thunx'
import { comparePolicy, getPolicy, setAcceptPolicyThunk } from 'screens/client/Policy/Policy.thunx'
import {
  deleteNotifications,
  fetchNotifications,
  viewNotification
} from 'screens/client/Notifications/Notifications.thunx'
import { removeSelectedNotification } from 'screens/client/Notifications/Notifications.actions'
import { updateUserInfo } from 'screens/client/EditProfile/EditProfile.thunx'

export const mapStateToProps = (state: any) => ({
  user: state.auth.data,
  userInfo: state.client.profile.user,
  totalQuantity: getTotalQuantitySelector(state),
  totalPrice: getTotalPriseSelector(state),
  basket: getDataSelector(state),
  notifications: state.client.notifications.data,
  totalNotifications: state.client.notifications.totalQuantity,
  pointsFundPerMonth: state.admin.settings.pointsSystem.result.pointsFundPerMonth,
  pointsFund: state.admin.settings.pointsSystem.result.pointsFund,
  dataUsers: state.client.newRequestTimeOff.users.results,
  userPhoto: state.auth.avatar,
  profileStatus: state.client.profile.profile.status,
  profileLoading: state.client.profile.profile.loading,
  trlTokenPrice: state.client.web3.trlTokenPrice,
  policy: state.client.policy.data,
  policyLoading: state.client.policy.loading,
  acceptPolicy: state.auth.data.acceptPolicy
})

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      pulling,
      getPointsSettings,
      addToCart,
      removeFromCart,
      getUserPhoto,
      checkUserProfile,
      getPolicy,
      comparePolicy,
      setAcceptPolicyThunk,
      fetchNotifications,
      deleteNotifications,
      removeSelectedNotification,
      viewNotification,
      updateUserInfo
    },
    dispatch
  )
})

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent)

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(Header))
