import React, { FC } from 'react'
import { IProps } from './TimePick.module'
import { useDispatch } from 'react-redux'
import { changeTimeStatus, setData } from '../../InterviewInvitation.actions'
import cls from 'classnames'
import styles from './TimePick.module.scss'

const TimePick: FC<IProps> = ({ time, data, date }) => {
  const dispatch = useDispatch()
  const setTimeData = (arr: any) => {
    const timeActive = arr.filter((item: any) => {
      return item.status === 'active'
    })
    const payload = data.map((item: any) => {
      if (item.date === date) {
        return {
          ...item,
          time: timeActive
        }
      }
      return item
    })
    dispatch(setData(payload))
  }
  const changeStatus = (item: any, status: string) => {
    const arr =
      time &&
      time.map((obj: any) => {
        if (obj.id === item.id) {
          return { ...obj, status: status }
        }
        return obj
      })
    const payload = { [`${date}`]: arr }
    dispatch(changeTimeStatus(payload))
    setTimeData(arr)
  }
  const onClick = (item: any) => {
    if (item.status === 'available') {
      changeStatus(item, 'active')
    } else {
      changeStatus(item, 'available')
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.timeButtons}>
        {time &&
          time.map(item => (
            <div
              key={item.id}
              className={cls({
                [styles.time]: true,
                [styles.available]: item.status === 'available',
                [styles.busy]: item.status === 'busy',
                [styles.active]: item.status === 'active'
              })}
            >
              <button
                type={'button'}
                onClick={() => onClick(item)}
                disabled={item.status === 'busy'}
              >
                {item.value}
              </button>
            </div>
          ))}
      </div>
    </div>
  )
}

export default TimePick
