// import { AxiosError } from 'axios'
import { action } from 'store/store.utils'
import {
  UPDATE_USER_SUCCESS,
  GET_QRCODE_SUCCESS,
  GET_SIGNUP_SUCCESS,
  GET_DID_SUCCESS,
  VERIFICATION_REQUEST,
  VERIFICATION_ERROR
} from './Polygon.constants'
import { TPolygonList } from './Polygon.model'

export const verificationRequest = (): TPolygonList => action(VERIFICATION_REQUEST)

export const verificationError = (payload?: any): TPolygonList =>
  action(VERIFICATION_ERROR, payload)

export const verificationSuccess = (payload?: any): TPolygonList =>
  action(UPDATE_USER_SUCCESS, payload)

export const updateUserSuccess = (payload?: any): TPolygonList =>
  action(UPDATE_USER_SUCCESS, payload)

export const getQrCodeSuccess = (payload?: any): TPolygonList => action(GET_QRCODE_SUCCESS, payload)

export const getSignupSuccess = (payload?: any): TPolygonList => action(GET_SIGNUP_SUCCESS, payload)

export const getUserDidSuccess = (payload?: any): TPolygonList => action(GET_DID_SUCCESS, payload)
