import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import styles from './ChannelsSettings.module.scss'
import { Form } from 'react-final-form'
import { ToggleButton } from 'components/UiKit/Button/ToggleButton'
import { TChannels, IChannelSettings } from '../ControlPanel/ControlPanel.types'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import CustomInputField from 'components/UiKit/CustomInput/CustomInputField'
import {
  restAtBreakevenModeSelectOptions,
  takeProfitModeSelectOptions,
  stopLossModeModeSelectOptions,
  openOrderTypeSelectOptions,
  closeOrderTypeSelectOptions
} from 'globalConfigs'
import { TChannelsSettingsProps } from './ChannelsSettings.model'
import BackButton from 'components/BackButton/BackButton'
import { FormApi } from 'final-form'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { validateChannelsSettings } from './libs/validation/validation'
import { isEqualToManualMultiple } from './libs/helpers/isEqualToManualMultiple.helper'

const ChannelsSettings: React.FC<TChannelsSettingsProps> = ({
  history,
  botSettings,
  saveSettings,
  getBotSettings,
  getBotInfo,
  botInfo
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const [channelName, setChannelName] = useState<string>()
  const [initialValues, setInitialValues] = useState<TChannels | TChannels[] | null>(null)
  const [channelsSettings, setChannelsSettings] = useState<any>(null)
  const { botId, exchangeType, strategyType } = botInfo

  useEffect(() => {
    if (!botId) {
      getBotInfo()
    }
  }, [])

  useEffect(() => {
    if (botId && exchangeType) {
      getBotSettings(botId, exchangeType, strategyType)
    }
  }, [channelName, botId, exchangeType, strategyType])

  useMemo(() => {
    if (!botSettings) {
      return
    }
    const { channels } = botSettings

    const updatedChannels = structuredClone(channels)

    setChannelsSettings(updatedChannels)

    if (channelName) {
      const currentChannel = updatedChannels.filter(
        (channel: IChannelSettings) => channel.channelName === channelName
      )
      setChannelName(channelName)
      setInitialValues(currentChannel[0])
    } else {
      setChannelName(updatedChannels[0].channelName)
      setInitialValues(updatedChannels[0])
    }
  }, [botSettings])

  async function onSubmit(values: IChannelSettings, form: FormApi<IChannelSettings>) {
    try {
      saveSettings({ channels: values })

      form.initialize(values)
    } catch (error) {
      console.log(error)
    }
  }

  function handleResetFormSettings(form: FormApi<IChannelSettings, Partial<IChannelSettings>>) {
    form.reset()
  }

  const handleChannelChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (!botSettings) {
      return
    }

    const currentChannelName = event.target.value
    setChannelName(currentChannelName)

    for (const channel of channelsSettings) {
      if (channel.channelName === currentChannelName) {
        setInitialValues(channel)

        return
      }
    }
    setInitialValues(channelsSettings[0])
  }

  if (strategyType && strategyType !== 'custom') {
    history.goBack()
  }

  function channelsSelectOptions() {
    if (!botSettings) {
      return
    }

    return botSettings.channels.map(channel => {
      return (
        <option value={channel.channelName} key={channel.channelName}>
          {channel.channelName}
        </option>
      )
    })
  }

  return (
    <>
      <div className={styles.backBattonWrapper}>
        <BackButton />
      </div>

      <section className={styles.settingsSection}>
        <Form
          initialValues={initialValues as TChannels}
          onSubmit={onSubmit}
          validate={validateChannelsSettings}
        >
          {({ handleSubmit, form, pristine }) => {
            const currentValues = form.getState().values as TChannels
            return (
              <form onSubmit={handleSubmit} name="form" className={styles.form}>
                <h2>{words['crypto-bot.channelSettings']}</h2>

                <div className={styles.channelsWrapper}>
                  <select
                    className={styles.channelNameSelect}
                    name="channelName"
                    value={channelName}
                    onChange={handleChannelChange}
                  >
                    {channelsSelectOptions()}
                  </select>
                </div>

                <div className={styles.mainSettings}>
                  <div className={styles.toggleWrapper}>
                    <ToggleButton label={words['crypto-bot.activateChannel']} name="active" />
                  </div>

                  <div className={styles.orderWrapper}>
                    <CustomInputField
                      type="select"
                      options={openOrderTypeSelectOptions}
                      name="openOrderType"
                      label={words['crypto-bot.openPositionOrderType']}
                    />

                    <CustomInputField
                      type="select"
                      options={closeOrderTypeSelectOptions}
                      name="closeOrderType"
                      label={words['crypto-bot.closePositionOrderType']}
                    />
                  </div>

                  <div className={styles.toggleWrapper}>
                    <ToggleButton
                      label={words['crypto-bot.recalculateTpsl']}
                      name="enableMultiplierTPSL"
                    />
                  </div>
                </div>

                <div className={styles.blockContainer}>
                  <h5 className={styles.blockTittle}>Take profit</h5>

                  <div className={styles.select}>
                    <CustomInputField
                      type="select"
                      options={takeProfitModeSelectOptions}
                      name="takeProfitMode"
                      label={words['crypto-bot.takeProfitMode']}
                    />

                    <div className={styles.toggleWrapper}>
                      <ToggleButton
                        label={words['crypto-bot.setRebatePercentage']}
                        name="manualTsRollbackPercentage"
                        disabled={!currentValues.tSInsteadTP}
                      />
                    </div>
                  </div>

                  <div className={styles.inputsWrapper}>
                    <CustomInputField
                      type="number"
                      name="takeProfit"
                      label="Take profit % (default)"
                      placeholder="%"
                    />

                    {isEqualToManualMultiple(currentValues) && (
                      <CustomInputField
                        type="number"
                        name="numberOfTakes"
                        label={words['crypto-bot.numberOfTP']}
                        placeholder={words['crypto-bot.enterTheNumber']}
                      />
                    )}

                    <CustomInputField
                      type="number"
                      name="tsRollbackPercentage"
                      label={words['crypto-bot.rollbackPercentage']}
                      placeholder={words['crypto-bot.enterTheNumber']}
                      disabled={
                        !currentValues.tSInsteadTP || !currentValues.manualTsRollbackPercentage
                      }
                    />

                    <ToggleButton
                      label={words['crypto-bot.trailingInsteadOfTP']}
                      name="tSInsteadTP"
                    />
                  </div>
                </div>

                <div className={styles.blockContainer}>
                  <h5 className={styles.blockTittle}>Stop loss</h5>

                  <div className={styles.select}>
                    <CustomInputField
                      type="select"
                      options={stopLossModeModeSelectOptions}
                      name="stopLossMode"
                      label={words['crypto-bot.stopLossMode']}
                    />
                  </div>

                  <div className={styles.inputsWrapper}>
                    <CustomInputField
                      type="number"
                      name="stopLoss"
                      label="Stop loss % (default)"
                      placeholder="%"
                    />

                    <CustomInputField
                      type="select"
                      options={restAtBreakevenModeSelectOptions}
                      name="restAtBreakevenMode"
                      label={words['crypto-bot.breakEvenStop']}
                    />
                  </div>
                </div>

                <div className={styles.blockContainer}>
                  <h5 className={styles.blockTittle}>{words['crypto-bot.autoAveraging']}</h5>

                  <div className={styles.toggleContainer}>
                    <div className={styles.toggleWrapper}>
                      <ToggleButton label={words['crypto-bot.activate']} name="autoAveraging" />
                    </div>

                    <div className={styles.toggleWrapper}>
                      <ToggleButton
                        label={words['crypto-bot.averagingToPositionSize']}
                        name="averageToPositionSize"
                      />
                    </div>
                  </div>

                  <div className={styles.autoAveragingInputs}>
                    <CustomInputField
                      type="number"
                      name="maxAutoAveraging"
                      label={words['crypto-bot.maxAutoAveraging']}
                    />

                    <CustomInputField
                      type="number"
                      name="firstStepAveraging"
                      label={words['crypto-bot.firstAveragingStep']}
                      placeholder="%"
                    />

                    <CustomInputField
                      type="number"
                      name="percentagePosToAver"
                      label={words['crypto-bot.percentForAveraging']}
                      placeholder="%"
                    />

                    <CustomInputField
                      type="number"
                      name="otherStepsAveraging"
                      label={words['crypto-bot.otherAveragingSteps']}
                      placeholder="%"
                    />

                    <CustomInputField
                      type="number"
                      name="autoAveragingTP"
                      label={words['crypto-bot.takeProfitAfterAveraging']}
                      placeholder="%"
                    />

                    <CustomInputField
                      type="number"
                      name="secondStepAveraging"
                      label={words['crypto-bot.secondAveragingStep']}
                      placeholder="%"
                    />
                  </div>
                </div>

                <div className={styles.blockContainer}>
                  <h5 className={styles.blockTittle}>{words['crypto-bot.averagingBySignal']} </h5>

                  <div className={styles.toggleWrapper}>
                    <ToggleButton label={words['crypto-bot.averagingBySignal']} name="averaging" />
                  </div>

                  <div className={styles.inputsWrapper}>
                    <CustomInputField
                      type="number"
                      name="maxChannelAveraging"
                      label={words['crypto-bot.maxAveragingByChannel']}
                    />
                  </div>
                </div>

                <div className={styles.actionBtnsWrapper}>
                  <BaseButton
                    outline={false}
                    color={'default'}
                    className={'requesth'}
                    disabled={pristine}
                    onClick={() => handleResetFormSettings(form)}
                    children={words['crypto-bot.cancelSettings']}
                  />

                  <BaseButton
                    outline={false}
                    color={'default'}
                    className={'requesth'}
                    disabled={pristine}
                    type="submit"
                    children={words['crypto-bot.save']}
                  />
                </div>
              </form>
            )
          }}
        </Form>
      </section>
    </>
  )
}

export default ChannelsSettings
