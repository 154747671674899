import { AxiosError } from 'axios'
import { action } from 'store/store.utils'
// import { AxiosError } from 'axios'
import {
  CREATE_TASK_REQUEST,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_ERROR,
  GET_TASK_SUCCESS
} from './CreateTask.constants'
import { TCreateTask } from './CreateTask.model'

export const createTaskRequest = (): TCreateTask => action(CREATE_TASK_REQUEST)

export const createTaskSuccess = (): TCreateTask => action(CREATE_TASK_SUCCESS)

export const createTaskError = (payload: AxiosError): TCreateTask =>
  action(CREATE_TASK_ERROR, payload)

export const getTaskSuccess = (payload: any): TCreateTask => action(GET_TASK_SUCCESS, payload)
