import { action } from 'store/store.utils'
import { TSendTaskRequest } from './TaskRequest.model'
import {
  TASK_REQUEST_LOADING,
  TASK_REQUEST_SUCCESS,
  TASK_REQUEST_ERROR
} from './TaskRequest.costants'
import { AxiosError } from 'axios'

export const createTaskRequestLoading = (): TSendTaskRequest => action(TASK_REQUEST_LOADING)
export const createTaskRequestSuccess = (): TSendTaskRequest => action(TASK_REQUEST_SUCCESS)
export const createTaskRequestError = (payload: AxiosError): TSendTaskRequest =>
  action(TASK_REQUEST_ERROR, payload)
