import { Dispatch } from 'redux'
import {
  getGoodRequest,
  getGoodSuccess,
  getGoodError,
  createReviewError,
  createReviewRequest,
  createReviewSuccess
} from './GoodsItemDetails.actions'
import { api } from 'services/api'
import { API } from '../../../services/api'
import toastr from '../../../utils/toastr'
import { TState } from 'store'

import { TCreateReviewBody } from './GoodsItemDetails.model'
import { TAutorReview } from '../../../components/Reviews/components/CreateReview/CreateReview'
import { AxiosError } from 'axios'

export const getGood = (id: string) => async (dispatch: Dispatch) => {
  dispatch(getGoodRequest())
  try {
    const response = await api.get(`${API.GOODS}/${id}`)
    dispatch(getGoodSuccess(response.data))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(getGoodError(err))
  }
}

export const createReview = (body: TCreateReviewBody, authorReview: TAutorReview) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(createReviewRequest())
  const words = getData().global.language.words

  try {
    const response = await api.post(API.REVIEW, body)
    dispatch(createReviewSuccess({ comment: response.data, authorReview }))
    toastr('success', words['SUCCESS_TRANSFERRED'])
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(createReviewError(err))
    toastr('error', words['FILED_TRANSFER_REVIEW'], err)
  }
}
