import { FC, forwardRef, useEffect } from 'react'
import InvoiceComponent from '../../../../../components/Invoice/InvoiceComponent'
// eslint-disable-next-line @typescript-eslint/no-duplicate-imports
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getInvoiceByTask } from '../../Subcontract.thunk'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import { TState } from '../../../../../store'

type TInvoiceProps = {
  invoice: any
  ref?: any
  fromTask?: boolean
}

const SubcontractInvoice: FC<TInvoiceProps> = forwardRef(({ invoice, fromTask }, ref: any) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const parsed = queryString.parse(history.location.search)
  const subcontractInvoice = useSelector((state: TState) => state.client.subcontract.invoice)
  const invoiceToDownload = fromTask ? subcontractInvoice : invoice
  useEffect(() => {
    if (fromTask) {
      const getProjectInvoice = () => {
        dispatch(getInvoiceByTask(parsed.taskId))
      }
      getProjectInvoice()
    }
  }, [])
  return (
    <div style={{ position: 'absolute', left: '-9999px' }}>
      <InvoiceComponent type="task" ref={ref} invoice={invoiceToDownload} />
    </div>
  )
})

export default SubcontractInvoice
