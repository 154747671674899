import { Dispatch } from 'redux'
import {
  ExchangeInfo,
  GlobalConfig,
  IChannelSettings,
  IExchangeSettings,
  IStandardSettings,
  TExchangeType,
  TPositionsInfo,
  TStrategyType
} from './ControlPanel.types'
import {
  fetchBotSettings,
  getBotInfoAction,
  getBotSettingsAction,
  getExchangeInfoAction,
  getGraphStatisticAction,
  getPositionsInfoAction
} from './ControlPanel.actions'
import { api, API } from 'services/api'
import { handleAxiosError } from 'utils/handleAxiosError'
import { fetchSettingsFromCryptoBot } from 'utils/fetchSettingsFromCryptoBot'
import { sendPatchRequest } from 'utils/sendPatchRequest'
import toastr from 'utils/toastr'
import { headers } from './libs/constants/constants'
import { TState } from 'store'
import { handleDefaultBotSettings } from 'utils/handleDefaultBotSetings'
import { deleteExchangeSettings } from './libs/helpers/deleteExchangeSettings.helper'
import { postBotSettings } from 'utils/postBotSettings'
import { deleteChannelsSettings } from './libs/helpers/deleteChannelsSettings.helper'
import { updatedChannelsSettings } from 'utils/updatedChannelsSettings'

export const getBotSettings = (
  botId: string,
  exchangeType: TExchangeType | null,
  strategyType: TStrategyType | null
) => async (dispatch: Dispatch) => {
  dispatch(fetchBotSettings())

  try {
    const exchangeSettingsFromBot = await fetchSettingsFromCryptoBot(
      `/bot/${botId}/exchanges-settings`
    )

    if (exchangeSettingsFromBot.length) {
      const channelsSettingsFromBot = await fetchSettingsFromCryptoBot(
        `/bot/${botId}/channels-settings`
      )

      const globalSettingsFromBot = await fetchSettingsFromCryptoBot(`/bot/${botId}/settings`)
      const settings: IStandardSettings = {
        exchanges: exchangeSettingsFromBot[0],
        channels: channelsSettingsFromBot,
        global: globalSettingsFromBot
      }

      dispatch(getBotSettingsAction(settings))
    } else {
      const defaultSettings = await fetchSettingsFromCryptoBot('/bot/default-settings')
      const settings = handleDefaultBotSettings({
        defaultSettings,
        exchangeType,
        strategyType
      })
      dispatch(getBotSettingsAction(settings))
    }
  } catch (error) {
    handleAxiosError(error)
  }
}

export const getExchangeInfoThunk = (botId: string) => async (dispatch: Dispatch) => {
  dispatch(fetchBotSettings())

  try {
    const exchangeInfoFromServer: ExchangeInfo[] = (await api.get(
      `${API.URL}/bot/${botId}/statistics`,
      {
        headers
      }
    )).data

    dispatch(getExchangeInfoAction(exchangeInfoFromServer))
  } catch (error) {
    handleAxiosError(error)
  }
}

export const getPositionsInfoThunk = (botId: string) => async (dispatch: Dispatch) => {
  dispatch(fetchBotSettings())

  try {
    const positionsInfoFromServer: TPositionsInfo = (await api.get(
      `${API.URL}/bot/${botId}/trading/positions`,
      {
        headers
      }
    )).data

    dispatch(getPositionsInfoAction(positionsInfoFromServer))
  } catch (error) {
    handleAxiosError(error)
  }
}

export const getGraphStatisticThunk = (params: any, botId: string) => async (
  dispatch: Dispatch
) => {
  dispatch(fetchBotSettings())
  try {
    const graphStatistic = (await api.get(`${API.URL}/bot/${botId}/statistics/data`, {
      headers,
      params
    })).data
    console.log('🚀 ~ file: ControlPanel.thunk.ts:98 ~ graphStatistic:', graphStatistic)

    dispatch(getGraphStatisticAction(graphStatistic))
  } catch (error) {
    handleAxiosError(error)
  }
}
//TODO rewrite this method when instance will be raise
export const getInstanceThunk = (
  values: {
    exchange: TExchangeType
    key: string
    secret: string
  },
  user: any
) => async () => {
  try {
    const { data } = await api.post(
      `${API.URL}/bot/keys`,
      {
        exchange: values.exchange,
        key: values.key,
        secret: values.secret
      },
      {
        headers
      }
    )
    await api.post(`${API.URL}/crypto-bot`, {
      ...data,
      selectedExchange: values.exchange,
      user: user.id
    })
  } catch (error) {
    console.log(error)
  }
}

export const resetSettings = ({
  user,
  exchange,
  strategyType,
  botId
}: {
  user: any
  exchange: TExchangeType
  strategyType: TStrategyType
  botId: string
}) => async (dispatch: Dispatch) => {
  try {
    const defaultSettings = await fetchSettingsFromCryptoBot('/bot/default-settings')
    const { data } = await api.get(`${API.URL}/crypto-bot/settings/${user.id}`)

    const initSettings = handleDefaultBotSettings({
      defaultSettings,
      exchangeType: exchange,
      strategyType
    })

    const updatedGlobalSettings = {
      instanceId: data.instance_id,
      ownerName: user.fullName
    }

    dispatch(
      getBotSettingsAction({
        ...initSettings,
        global: updatedGlobalSettings
      })
    )

    const channelsPromises = initSettings.channels.map((channel: any) => {
      return sendPatchRequest(`${API.URL}/bot/${botId}/channels-settings`, channel, headers)
    })

    const globalPromises = [
      sendPatchRequest(
        `${API.URL}/bot/${botId}/exchanges-settings`,
        initSettings.exchanges,
        headers
      ),
      sendPatchRequest(`${API.URL}/bot/${botId}/settings`, updatedGlobalSettings, headers)
    ]

    const allPromises = [...channelsPromises, ...globalPromises]

    const result = await Promise.all(allPromises)
    const isSuccess = result.every(promise => promise.status === 'fulfilled')

    if (isSuccess) {
      toastr('success', 'Settings have been reset')
    }
  } catch (error) {
    console.log(error)
  }
}

export const saveSettings = ({
  exchanges,
  channels,
  listener
}: {
  exchanges?: IExchangeSettings
  channels?: IChannelSettings
  listener?: (a: boolean) => void
}) => async (dispatch: Dispatch, getData: () => TState) => {
  const botSettings = getData().client.controlPanel.settings
  const { botId, exchangeType } = getData().client.controlPanel.botInfo
  const user = getData().auth.data
  const words = getData().global.language.words

  if (!botSettings || !botId || !exchangeType) {
    return
  }

  const updatedChannels = channels
    ? updatedChannelsSettings(botSettings.channels, channels)
    : structuredClone(botSettings.channels)

  const exchangeSettings = exchanges ? exchanges : botSettings.exchanges
  const updatedExchangeSettings = structuredClone({
    ...exchangeSettings,
    exchange: exchangeType
  })

  const { data } = await api.get(`${API.URL}/crypto-bot/settings/${user.id}`)

  const updatedGlobalSettings: GlobalConfig = {
    instanceId: data.instance_id,
    ownerName: user.fullName
  }

  let results

  try {
    const exchangeSettingsFromBot = await fetchSettingsFromCryptoBot(
      `/bot/${botId}/exchanges-settings`
    )

    if (!exchangeSettingsFromBot.length) {
      results = postBotSettings(
        updatedExchangeSettings,
        updatedChannels,
        updatedGlobalSettings,
        botId
      )
    } else {
      if (exchanges) {
        results = await sendPatchRequest(
          `${API.URL}/bot/${botId}/exchanges-settings`,
          updatedExchangeSettings,
          headers
        )
      }
      if (channels) {
        const channelsPromises = updatedChannels.map((channel: IChannelSettings) => {
          return sendPatchRequest(`${API.URL}/bot/${botId}/channels-settings`, channel, headers)
        })

        const allPromises = channelsPromises
        results = await Promise.all(allPromises)
      }
    }

    if (results) {
      dispatch(
        getBotSettingsAction({
          exchanges: updatedExchangeSettings,
          channels: updatedChannels,
          global: updatedGlobalSettings
        })
      )
      toastr('success', `${words['crypto-bot.settingsSaved']}`)
      if (listener) {
        listener(true)
      }
    }
  } catch (error) {
    toastr('error', 'Error')
  }
}

export const getBotInfo = () => async (dispatch: Dispatch, getData: () => TState) => {
  try {
    const { data } = await api.get(`${API.URL}/crypto-bot/settings/${getData().auth.data.id}`)
    dispatch(getBotInfoAction(data))
  } catch (error) {
    const currentError = error as Error
    console.log(currentError.message)
    throw new Error(currentError.message)
  }
}

export const updateBotInfo = (updatedInfo: {
  selectedExchange?: TExchangeType
  selectedStrategy?: TStrategyType
}) => async (dispatch: Dispatch, getData: () => TState) => {
  try {
    const { selectedExchange, selectedStrategy } = updatedInfo
    const { botId, strategyType, exchangeType } = getData().client.controlPanel.botInfo
    const userId = getData().auth.data.id
    const isNewStrategy = selectedStrategy && strategyType && strategyType !== selectedStrategy
    const isNewExchange = exchangeType !== selectedExchange

    if (botId && exchangeType && (isNewStrategy || isNewExchange)) {
      const exchangeSettingsFromBot = await fetchSettingsFromCryptoBot(
        `/bot/${botId}/exchanges-settings`
      )

      const channelsSettingsFromBot = (await fetchSettingsFromCryptoBot(
        `/bot/${botId}/channels-settings`
      )) as IChannelSettings[]

      if (channelsSettingsFromBot.length) {
        deleteChannelsSettings(`/bot/${botId}/channels-settings`, channelsSettingsFromBot)
      }

      if (exchangeSettingsFromBot.length) {
        await deleteExchangeSettings(`/bot/${botId}/exchanges-settings`, exchangeType)
      }

      const defaultSettings = await fetchSettingsFromCryptoBot('/bot/default-settings')
      const settings = handleDefaultBotSettings({
        defaultSettings,
        exchangeType: selectedExchange ? selectedExchange : exchangeType,
        strategyType: selectedStrategy ? selectedStrategy : strategyType
      })
      dispatch(getBotSettingsAction(settings))
    }

    const { data } = await api.patch(`${API.URL}/crypto-bot/${userId}`, updatedInfo)
    dispatch(getBotInfoAction(data))
  } catch (error) {
    console.log(error)
  }
}
