import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { ReactComponent as DefaultAvatar } from 'assets/images/default_avatar.svg'
import styles from './TaskInfoDetails.module.scss'

interface ITaskInfoDetails {
  taskInfo: any
  userPhoto: string
}

export const TaskInfoDetails: React.FC<ITaskInfoDetails> = ({ taskInfo, userPhoto }) => {
  const words = useSelector((state: TState) => state.global.language.words)

  if (!taskInfo.title) {
    return null
  }

  return (
    <section className={styles['personal-info']}>
      <h3 className={styles.taskTitle}>{taskInfo.title}</h3>

      <div className={styles['info-task']}>
        <div>
          <p className={styles.infoDescription}>{words['user.subcontract.task.customer']}:</p>

          <div className={styles.userInfoDescription}>
            <div>
              {userPhoto ? (
                <img className={styles.avatar} src={userPhoto} alt="avatar" />
              ) : (
                <DefaultAvatar className={styles.avatar} />
              )}
            </div>

            <div className={styles['user-info']}>
              <p className={styles.userName}>{taskInfo.customer.fullName}</p>

              <p className={styles.userRating}>
                {words['user.subcontract.reviews']}:{' '}
                <span className={styles.negativeRating}>-0</span>
                <span className={styles.positiveRating}> +5</span>
              </p>
            </div>
          </div>
        </div>

        <div>
          <p className={styles.infoDescription}>{words['user.subcontract.task.budget']}:</p>

          <div className={styles.taskPrice}>
            {taskInfo.price} {taskInfo.token && taskInfo.token.symbol}
          </div>
        </div>

        <div>
          {taskInfo.fixedDeadline && (
            <>
              <p className={styles.infoDescription}>{words['user.subcontract.task.term']}:</p>

              <div className={styles.deadline}>
                {words['user.subcontract.until'] +
                  ' ' +
                  moment(taskInfo.deadline).format(`DD.MM.YYYY`)}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  )
}
