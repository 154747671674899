import PrivateRoute from './PrivatRoute'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { TState } from 'store'
import { isEmpty } from 'lodash'
export const mapStateToProps = ({ auth }: TState) => ({
  isAuthenticated: !isEmpty(auth.data),
  role: auth.role
})

export const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute)
