import React from 'react'
import styles from '../../CreateInvoices.module.scss'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import cls from 'classnames'
import { Field } from 'react-final-form'
import { DatePicker } from 'components/UiKit/DatePicker'
import { config } from 'globalConfigs'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import { FORMAT_MMDDYYYY } from 'screens/client/NewRequestTimeOff/NewRequestTimeOff'
import toastr from 'utils/toastr'
import { FormApi, FormState } from 'final-form'
import validation from 'utils/validation'

type TDateInvoiceProps = {
  form: FormApi
  formState: FormState<any>
  name: string
}

const DateInvoice = ({ form, formState, name }: TDateInvoiceProps) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)
  const moment = extendMoment(Moment)
  return (
    <Field
      name={name}
      validate={validation && validation.composeValidators(validation.required('error'))}
    >
      {({ input, meta }) => {
        if (meta.error && meta.submitFailed) {
          toastr('error', words['user.editProfile.fillRequiredFields'])
        }
        return (
          <>
            <DatePicker
              locale={currentLanguage}
              isInvalid={meta.error && meta.submitFailed}
              isNextYear={true}
              className={cls({
                [styles.dateInvalid]: meta.error && meta.submitFailed
              })}
              dateFormat={config.dateFormat}
              selected={input.value && new Date(input.value)}
              onChange={(date: Date) => {
                const formatDate = date
                  ? moment(date).format(FORMAT_MMDDYYYY)
                  : moment().format(FORMAT_MMDDYYYY)
                form.change(input.name, formatDate)
              }}
              endDate={new Date(formState.values.lastDay)}
              startDate={new Date(input.value)}
            />
          </>
        )
      }}
    </Field>
  )
}
export default DateInvoice
