/* eslint-disable no-console */
import React, { FC, useState } from 'react'
import styles from './PlaningCalendar.module.scss'
import queryString from 'query-string'
import { TPlaningCalendarProps } from './PlaningCalendar.model'
import Calendar from 'screens/client/InterviewInvitation/components/Calendar/Calendar'
import BigCalendars from './components/BigCalendar'
import { Icons } from 'components/Icons'
import Spinner from 'components/Spinner'
import Executor from './components/Executor/Executor'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { useSelector } from 'react-redux'
import { TState } from 'store'

const PlaningCalendar: FC<TPlaningCalendarProps> = ({
  history,
  setEvent,
  getEvents,
  eventsArray,
  eventsLoading,
  changeEvent,
  deleteEvent,
  currentTask,
  addEventToGoogleCalendar,
  updateEventToGoogleCalendar,
  deleteEventFromGoogleCalendar
}) => {
  const parsed = queryString.parse(history.location.search)
  const words = useSelector((state: TState) => state.global.language.words)

  const [value, setValue] = useState<Date | null | undefined | [Date | null, Date | null]>(
    new Date()
  )
  const [isExecutor, setIsExecutor] = useState(true)
  const [usedTime, setUsedTime] = useState(0)

  React.useEffect(() => {
    getEvents(parsed.taskId)
  }, [])

  const changeExecutor = () => {
    getEvents(parsed.taskId)
    setIsExecutor(!isExecutor)
  }

  return (
    <>
      <div className={styles.container}>
        <section className={styles.header}>
          <h1
            onClick={() => {
              history.goBack()
            }}
          >
            <Icons icon="backArrow" className={styles['back-arrow']} />
            {words['user.subcontract.nav.planning']}
          </h1>
        </section>
        {!eventsLoading ? (
          <div className={styles.planing}>
            <div className={styles.date}>
              <Calendar value={value} onChange={setValue} />

              <Executor
                changeExecutor={changeExecutor}
                isExecutor={isExecutor}
                currentTask={currentTask}
              />
            </div>
            <BigCalendars
              setEvent={setEvent}
              eventsArray={eventsArray}
              changeEvent={changeEvent}
              deleteEvent={deleteEvent}
              getEvents={getEvents}
              isExecutor={isExecutor}
              changedDay={value}
              taskId={parsed.taskId}
              setUsedTime={setUsedTime}
              currentTask={currentTask}
              addEventToGoogleCalendar={addEventToGoogleCalendar}
              updateEventToGoogleCalendar={updateEventToGoogleCalendar}
              deleteEventFromGoogleCalendar={deleteEventFromGoogleCalendar}
            />
            <div className={styles.info}>
              <h3 className={styles.infoTitle}>
                {words['user.planingCalendar.scheduled']}:{' '}
                <span className={styles.less}>{usedTime}</span> из{' '}
                <span className={styles.correct}>{currentTask && currentTask.workingHours}</span>{' '}
                {words['user.planingCalendar.hours']}
              </h3>
              <div className={styles.infoButtons}>
                <BaseButton
                  type="button"
                  outline={true}
                  children={words['user.planingCalendar.skip']}
                />
                <BaseButton type="button" children={words['user.planingCalendar.plan']} />
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.calendarSpinner}>
            <Spinner />
          </div>
        )}
      </div>
    </>
  )
}

export default PlaningCalendar
