import React, { useEffect, useState } from 'react'
import styles from './SettingsTaskCancellation.module.scss'
import './SettingsTaskCancelledPagination.scss'
import { IToggleBarData, ToggleBar } from 'components/ToggleBar'
import { ETaskCancellationOptions, getTaskCancellationToggleBarOptions } from 'globalConfigs'
import queryString from 'query-string'
import { getTaskCancellationType } from 'utils/toggleBarsValue'
import SingleTaskCancellation from './components/SingleTaskCancellation/SingleTaskCancellation'
import Spinner from 'components/Spinner'
import { ReactComponent as Prev } from 'assets/images/arrow-left-green.svg'
import { ReactComponent as Next } from 'assets/images/arrow-right-green.svg'
import Pagination from 'rc-pagination'
import { ETaskStatus } from 'screens/client/Subcontract/components/CreateTask/CreateTask.model'
import { TSettingsProps } from '../../Settings.model'
import history from 'utils/history'
import { useSelector } from 'react-redux'
import { TState } from 'store'

const SettingsTaskCancellation: React.FC<
  Pick<TSettingsProps, 'cancelledTasks' | 'loading' | 'getCancelledTasks'>
> = ({ cancelledTasks, loading, getCancelledTasks }) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const parsed = queryString.parse(history.location.search)
  const taskCancellationOptions = getTaskCancellationToggleBarOptions(words)
  const [selectedTaskCancellationType, setSelectedTaskCancellationType] = useState<IToggleBarData>(
    getSelectedTaskCancellationType()
  )

  const limit = 4

  function getSelectedTaskCancellationType() {
    switch (parsed.taskCancellationType) {
      case ETaskCancellationOptions.WAITING:
        return taskCancellationOptions[0]
      case ETaskCancellationOptions.CANCELLED:
        return taskCancellationOptions[1]
      default:
        return taskCancellationOptions[0]
    }
  }

  useEffect(() => {
    if (!parsed.page) {
      history.push('/dashboard/settings?page=1&type=taskCancellation&taskCancellationType=waiting')
    }
    if (parsed.taskCancellationType === 'waiting') {
      getCancelledTasks(Number(parsed.page), limit, ETaskStatus.WITH_VIOLATION)
    }

    if (parsed.taskCancellationType === 'cancelled') {
      getCancelledTasks(Number(parsed.page), limit, ETaskStatus.CANCELLED)
    }
  }, [])

  const taskCancellationTotal = cancelledTasks && cancelledTasks.cancelledTasksTotalItems

  const cancelledList =
    cancelledTasks &&
    cancelledTasks.cancelledTasks &&
    cancelledTasks.cancelledTasks.map(
      (task: any) => task && <SingleTaskCancellation key={task.id} task={task} history={history} />
    )

  const handlePageChange = (e: number) => {
    const params = new URLSearchParams({
      ['page']: e.toString(),
      ['taskCancellationType']: selectedTaskCancellationType.value,
      ['type']: 'taskCancellation'
    })
    history.replace({ pathname: location.pathname, search: params.toString() })
  }

  return (
    <div className={styles.container}>
      <div className={styles.toggle}>
        <ToggleBar
          name="active-task-cancellation-no-active"
          data={taskCancellationOptions}
          defaultChecked={selectedTaskCancellationType}
          style={2}
          onChange={event => {
            history.push(
              `${history.location.pathname}?${queryString.stringify({
                page: 1,
                type: parsed.type,
                taskCancellationType: getTaskCancellationType(event.value)
              })}`
            )
            setSelectedTaskCancellationType(event)
          }}
        />
      </div>
      <section className={styles['tasks-wrapper']}>
        {loading && (
          <div className={styles['spinner-wrapper']}>
            <Spinner />
          </div>
        )}
        {!loading && cancelledList ? cancelledList : null}
        {!loading && cancelledTasks && !cancelledTasks.cancelledTasksTotalItems && (
          <div className={styles['no-tasks']}>{words['user.subcontract.taskListIsEmpty']}</div>
        )}
      </section>
      <section className={styles['pagination-container']}>
        {!loading && (
          <Pagination
            className="task-cancelled-pagination"
            defaultCurrent={1}
            current={Number(parsed.page)}
            pageSize={limit}
            total={taskCancellationTotal}
            onChange={handlePageChange}
            prevIcon={
              <button className={styles['prev-button']}>
                <Prev />
              </button>
            }
            nextIcon={
              <button className={styles['next-button']}>
                <Next />
              </button>
            }
            hideOnSinglePage={true}
            jumpNextIcon={<div>...</div>}
            jumpPrevIcon={<div>...</div>}
          />
        )}
      </section>
    </div>
  )
}
export default SettingsTaskCancellation
