import { connect } from 'react-redux'
import SideBar from './SideBar'
import { bindActionCreators, Dispatch } from 'redux'
import { clearStore } from 'screens/client/Subcontract/Subcontract.thunk'
import { TState } from 'store'

export const mapStateToProps = ({ auth }: TState) => ({
  role: auth.role
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ clearStore }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBar)
