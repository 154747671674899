import { Dispatch } from 'redux'
import { API, api } from '../../../services/api'
import { AxiosError } from 'axios'
import {
  deleteProjectInvoiceSuccess,
  getOneProjectInvoiceSuccess,
  getProjectInvoicesError,
  getProjectInvoicesRequest,
  getProjectInvoicesSuccess
} from './Invoices.actions'

export const getProjectInvoices: any = (search: string = '', id: string) => async (
  dispatch: Dispatch
) => {
  dispatch(getProjectInvoicesRequest())
  try {
    const response: any = await api.get(
      `${API.URL}/project-invoice/own/${id}${search}${search ? '&' : '?'}`
    )
    dispatch(getProjectInvoicesSuccess(response.data))
  } catch (error) {
    const err = error as AxiosError<any>
    await dispatch(getProjectInvoicesError(err))
  }
}

export const getOneProjectInvoice: any = (id: string) => async (dispatch: Dispatch) => {
  dispatch(getProjectInvoicesRequest())
  try {
    const response: any = await api.get(`${API.URL}/project-invoice/${id}`)
    dispatch(getOneProjectInvoiceSuccess(response.data))
  } catch (error) {
    const err = error as AxiosError<any>
    await dispatch(getProjectInvoicesError(err))
  }
}

export const deleteProjectInvoice: any = (id: string) => async (dispatch: Dispatch) => {
  try {
    await api.delete(`${API.URL}/project-invoice/${id}`)
    dispatch(deleteProjectInvoiceSuccess(id))
  } catch (error) {
    const err = error as AxiosError<any>
    await dispatch(getProjectInvoicesError(err))
  }
}
