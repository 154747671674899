import Web3 from 'web3'
import axios from 'axios'

export const getContractABI = async (address: string) => {
  try {
    const url = `https://api-testnet.bscscan.com/api?module=contract&action=getabi&address=${address}`
    const result = await axios.get(url)

    return JSON.parse(result.data.result)
  } catch (err) {
    // console.log(err)
  }
}

const getWeb3 = async (): Promise<Web3> => {
  if (window.ethereum) {
    const web3 = new Web3(window.ethereum as any)

    return web3
  }
  // Legacy dapp browsers...
  else if (window.web3) {
    // Use Mist/MetaMask's provider.
    const web3 = window.web3
    console.log('Injected web3 detected.')
    return web3
  }
  // Fallback to localhost; use dev console port by default...
  else {
    const provider = new Web3.providers.HttpProvider(
      process.env.WEB3_PROVIDER || 'https://data-seed-prebsc-1-s1.binance.org:8545/'
    )
    const web3 = new Web3(provider)
    console.log('No web3 instance injected, using Local web3.')
    return web3
  }
}

export default getWeb3
