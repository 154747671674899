import React from 'react'
import { Icons } from 'components/Icons'

export type TWords = { [key: string]: string }
export type TSidebarSubLink = {
  to: string
  title: string
  icon: React.ReactElement
  tab?: string
}
export type TSidebarLink = {
  disabled?: boolean
  exact?: boolean
  title: string
  icon: React.ReactElement
  path: string
  commonPath: string
  content: TSidebarSubLink[]
}
export type TAdminSideBar = ReturnType<typeof adminSideBar> & { [key: string]: TSidebarLink }
export type TClientSideBar = ReturnType<typeof clientSideBar> & { [key: string]: TSidebarLink }

export type TSideBarMenu = TAdminSideBar | TClientSideBar
export type TAdminSideBarMenu = (words: TWords) => TAdminSideBar
export type TClientSideBarMenu = (words: TWords) => TClientSideBar

export const adminSideBar = (words: TWords) => {
  return {
    time_of_requests: {
      title: words['sidebar.dayOffTracker'],
      icon: <Icons icon="timeOffRequests" />,
      path: '/dashboard/requests?status=1',
      commonPath: '/dashboard/requests',
      content: []
    },
    point_system: {
      title: words['sidebar.pointsSystem'],
      icon: <Icons icon="pointIcon" />,
      path: '/dashboard/point-system/store',
      commonPath: '/dashboard/point-system',
      content: [
        {
          to: '/dashboard/point-system/store',
          title: words['sidebar.pointsSystem.market'],
          icon: <Icons icon="point" />
        },
        {
          to: '/dashboard/point-system/achievements',
          title: words['admin.settings.pointsSystem.achivements'],
          icon: <Icons icon="point" />
        },
        {
          to: '/dashboard/point-system/requests',
          title: words['sidebar.pointsSystem.requests'],
          icon: <Icons icon="point" />
        }
        //     {
        //       to: '/dashboard/point-system/history',
        //       title: 'История',
        //       icon: <Icons icon="point" />
        //     },
      ]
    },
    team: {
      title: words['sidebar.team'],
      icon: <Icons icon="TeamIcon" />,
      path: '/dashboard/teamAdmin',
      commonPath: '/dashboard/teamAdmin',
      content: []
    },
    projects: {
      title: words['project.projects'],
      path: '/dashboard/projects',
      icon: <Icons icon="projects" />,
      commonPath: '/dashboard/projects',
      content: [
        {
          to: '/dashboard/projects',
          title: words['project.projects'],
          icon: <Icons icon="point" />,
          tab: 'deleted'
        },
        {
          to: '/dashboard/projects/invoices',
          title: words['projectInvoices.invoices'],
          icon: <Icons icon="point" />
        }
      ]
    },
    vacancies: {
      title: words['vacancies'],
      icon: <Icons icon="vacancies" />,
      path: '/dashboard/vacancies?vacancy=all-vacancies-admin',
      commonPath: '/dashboard/vacancies',
      content: []
    },
    users: {
      title: words['sidebar.users'],
      icon: <Icons icon="users" />,
      path: '/dashboard/users',
      commonPath: '/dashboard/users',
      content: []
    },
    entrepreneurs: {
      title: words['entrepreneur.conducting'],
      icon: <Icons icon="entrepreneurs" />,
      path: '/dashboard/entrepreneurs?tab=list',
      commonPath: '/dashboard/entrepreneurs',
      content: []
    },
    subcontract: {
      title: words['sidebar.subcontract'],
      icon: <Icons icon="subcontract" />,
      path: '/dashboard/subcontract?task=all-tasks-admin&page=1&fromPrice=',
      commonPath: '/dashboard/subcontract',
      content: [
        {
          to: '/dashboard/subcontract?task=all-tasks-admin&page=1&fromPrice=',
          title: words['sidebar.tasks'],
          icon: <Icons icon="point" />,
          tab: 'all-tasks'
        },
        {
          to: '/dashboard/subcontract/verification?page=0',
          title: words['admin.subcontract.verification'],
          icon: <Icons icon="point" />
        }
      ]
    },
    faq: {
      path: '/dashboard/faq?type=processes',
      title: 'FAQ',
      icon: <Icons icon="FaqLogo" />,
      commonPath: '/dashboard/faq',
      content: []
    }
  }
}

export const clientSideBar = (words: TWords) => {
  return {
    profile: {
      exact: false,
      title: words['user.header.profile'],
      icon: <Icons icon="profile" />,
      path: '/dashboard/profile',
      commonPath: '/dashboard/profile',
      content: []
    },
    time_of_requests: {
      title: words['sidebar.dayOffTracker'],
      icon: <Icons icon="timeOffRequests" />,
      path: '/dashboard/requests?status=1',
      commonPath: '/dashboard/requests',
      content: []
    },
    point_system: {
      exact: false,
      title: words['sidebar.pointsSystem'],
      icon: <Icons icon="pointIcon" />,
      path: '/dashboard/point-system/store',
      commonPath: '/dashboard/point-system',
      content: [
        {
          to: '/dashboard/point-system/store',
          title: words['sidebar.pointsSystem.market'],
          icon: <Icons icon="point" />
        },
        {
          to: '/dashboard/point-system/achievements',
          title: words['admin.settings.pointsSystem.achivements'],
          icon: <Icons icon="point" />
        }
        // {
        //   to: '/dashboard/point-system/lottery',
        //   title: 'Лотерея',
        //   icon: <Icons icon="point" />
        // },
      ]
    },
    team: {
      title: words['sidebar.team'],
      icon: <Icons icon="TeamIcon" />,
      path: '/dashboard/team',
      commonPath: '/dashboard/team',
      content: []
    },
    subcontract: {
      title: words['sidebar.subcontract'],
      icon: <Icons icon="subcontract" />,
      path: '/dashboard/subcontract?task=all-tasks&page=1&fromPrice=',
      commonPath: '/dashboard/subcontract',
      content: [
        {
          to: '/dashboard/subcontract?page=1&task=all-tasks',
          title: words['sidebar.tasks'],
          icon: <Icons icon="point" />,
          tab: 'all-tasks'
        },
        {
          to: '/dashboard/subcontract/performers?page=1',
          title: words['user.subcontract.nav.performers'],
          icon: <Icons icon="point" />
        },
        {
          to: '/dashboard/subcontract/invoices',
          title: words['projectInvoices.invoices'],
          icon: <Icons icon="point" />
        },
        {
          to: '/dashboard/subcontract/voting',
          title: words['user.voting.voting'],
          icon: <Icons icon="point" />
        }
      ]
    },
    cryptoBot: {
      // title: words['sidebar.cryptoBot'],
      title: 'cryptoBot',
      icon: <Icons icon="cryptoBot" />,
      path: '/dashboard/cryptoBot?typeStep=&apiSetted=false',
      commonPath: '/dashboard/cryptoBot',
      content: [],
      disabled: true
    },
    faq: {
      title: 'FAQ',
      icon: <Icons icon="FaqLogo" />,
      path: '/dashboard/faq?type=processes',
      commonPath: '/dashboard/faq',
      content: []
    }
  }
}
