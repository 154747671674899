import React, { FC, forwardRef } from 'react'
import cls from 'classnames'
import styles from './styles.module.scss'
import logo from '../../../../../assets/images/offer_logo_icon.svg'
import moment from 'moment'
import { ToWords } from 'to-words'

interface IOfferProps {
  invoice: any
  action?: any
  toggle?: boolean
  isHidden?: boolean
  ref?: any
}

const Offer: FC<IOfferProps> = forwardRef(({ isHidden, invoice, action, toggle }, ref: any) => {
  const toWords = new ToWords({
    localeCode: 'en-US'
  })
  // TODO get data from settings
  return (
    <div
      onClick={action}
      className={cls({ [styles.overlay]: toggle, [styles.overlayHidden]: isHidden })}
    >
      <div style={{ position: 'relative' }} onClick={(e: any) => e.stopPropagation()}>
        <div
          ref={ref}
          className={cls({
            [styles.container]: true,
            [styles.active]: true,
            [styles.hidden]: isHidden
          })}
        >
          <div className={cls({ [styles.modal]: true, [styles[`modal-size-${15}`]]: true })}>
            <h2 style={{ textAlign: 'left' }} className={styles['offer__title']}>
              Invoice (offer) / Інвойс (оферта)
            </h2>
            <div className={styles['offer__count']}>
              <span>
                № <b>{invoice.id}</b>
              </span>
              <span className={styles['offer__line']} />
              <span>
                dated <b>{moment(invoice.dateOfFormation).format('L')}</b>
              </span>
            </div>
          </div>
          <div className={styles['offer__info']}>
            <div className={styles['offer__info--wrapper']}>
              <div className={styles['offer__info-item']}>
                <b>Contractor:</b> <br /> Private entrepreneur Yenin Mykola Serhiiovych Individual
                Number 3447507555, address: Ukraine, 61058, Kharkivska region, Kharkiv city, Kultury
                b. 11, apt. 10, date and number of registration in United State Register of Legal
                Individual Entrepreneurs and Public Organizations of Ukraine 23.10.2018, 2 480 000
                0000 218646
              </div>
              <div className={styles['offer__info-item']}>
                <b>Виконавець:</b> <br /> Фізична особа–підприємець Єнін Микола Сергійович
                реєстраційний номер облікової картки платника податків та інших обов’язкових
                платежів платежів платежів 3447507555, місце проживання Україна, 61058, Харківська
                обл., м. Харків, вул. вул. Культури, буд. 11, кв. 10, дата та номер запису в Єдиному
                державному державному реєстрі юридичних осіб, фізичних осіб-підприємців та
                громадських формувань 23.10.2018, 2 480 000 0000 218646
              </div>
            </div>
            <div className={styles['offer__info--wrapper']}>
              <div className={styles['offer__info-item']}>
                <b>Customer:</b> <br />
                TECHNORELY HOLDING INC <br /> Business Number: 760353680BC0001 <br />
                Incorporation Number: BC1160255 <br /> Address: 208-6939, Hastings st., Burnaby, BC,
                Canada, postal code v5b 1s9
              </div>
              <div className={styles['offer__info-item']}>
                <b>Замовник:</b> <br />
                ТЕХНОРЕЛАЙ ХОЛДІНГ <br /> Код компанії: 760353680BC0001 <br />
                Номер регістрації: BC1160255 <br /> 208-6939, вул. Гастінгс, Бернабі, Британська
                Колумбія, Канада, поштовий індекс v5b 1с9
              </div>
            </div>
            <div className={styles['offer__info--wrapper']}>
              <div className={styles['offer__info-item']}>
                <b>Subject matter: </b>
                Software Development
              </div>
              <div className={styles['offer__info-item']}>
                <b>Предмет:</b> Розробка програмного забезпечення
              </div>
            </div>
            <div className={styles['offer__info--wrapper']}>
              <div className={styles['offer__info-item']}>
                <b>Currency:</b> {invoice.currency}
              </div>
              <div className={styles['offer__info-item']}>
                <b>Валюта:</b> долар США
              </div>
            </div>
            <div className={styles['offer__info--wrapper']}>
              <div className={styles['offer__info-item']}>
                <b>Price (amount) of the services :</b> {invoice.amount.toFixed(2)}
              </div>
              <div className={styles['offer__info-item']}>
                <b>Ціна (загальна вартість) послуг:</b> {invoice.amount.toFixed(2)}
              </div>
            </div>
            <div className={styles['offer__info--wrapper']}>
              <div className={styles['offer__info-item']}>
                <b>Terms of payments and acceptation:</b>
                Postpayment of 100% upon the services delivery. The services being rendered at the
                location of the Contractor.
              </div>
              <div className={styles['offer__info-item']}>
                <b>Умови оплати та передачі:</b> 100% післяплата за фактом виконання послуг. Послуги
                надаються за місцем реєстрації Виконавця.
              </div>
            </div>
            <div className={styles['offer__info--wrapper']}>
              <div className={styles['offer__info-item']}>
                <b>Customer Bank information:</b> <br />
                TECHNORELY HOLDING INC <br />
                Bank name: ROYAL BANK OF CANADA <br /> Bank address: 439 North Road, Coquitlam,
                BC,Canada , postal code v3k 3v9 <br /> ABA (Routing number): 021000021 <br /> Bank
                account (USD): 01200 003 <br /> SWIFT: ROYCCAT2
              </div>
              <div className={styles['offer__info-item']}>
                <b>Банківські реквізити Замовника:</b> <br />
                ТЕХНОРЕЛАЙ ХОЛДІНГ <br />
                Банк: ROYAL BANK OF CANADA <br /> Адреса банку: 439 North Road, Coquitlam, BC,Canada
                , postal code v3k 3v9 <br /> ABA (Routing number): 021000021 <br /> Банківський
                рахунок(USD): 01200 003 4003679 <br /> SWIFT: ROYCCAT2
              </div>
            </div>
            <div className={styles['offer__info--wrapper']}>
              <div className={styles['offer__info-item']}>
                <b>Contractor Bank information:</b> <br />
                Private Entrepreneur Yenin Mykola Serhiiovych <br />
                PJSC CB <q>PrivatBank</q> <br /> Bank SWIFT code PBANUA2X <br /> IBAN Code:
                UA243206490000026000052711492 <br /> Account in the correspondent bank 001-1-000080{' '}
                <br /> SWIFT of correspondence bank CHASUS33 <br /> Correspondent bank: JP Morgan
                Chase Bank,New York ,USA
              </div>
              <div className={styles['offer__info-item']}>
                <b>Банківські реквізити Замовника:</b> <br />
                ТЕХНОРЕЛАЙ ХОЛДІНГ <br />
                Банк: ROYAL BANK OF CANADA <br /> Адреса банку: 439 North Road, Coquitlam, BC,Canada
                , postal code v3k 3v9 <br /> ABA (Routing number): 021000021 <br /> Банківський
                рахунок(USD): 01200 003 4003679 <br /> SWIFT: ROYCCAT2
              </div>
            </div>
          </div>
          {isHidden && (
            <>
              <div style={{ marginTop: '100px' }} className={styles['offer__table']}>
                <div className={`${styles['offer__column']} ${styles['offer__column-title']}`}>
                  №
                </div>
                <div className={`${styles['offer__column']} ${styles['offer__column-title']}`}>
                  Description / Опис
                </div>
                <div className={`${styles['offer__column']} ${styles['offer__column-title']}`}>
                  Quantity / Кількість
                </div>
                <div className={`${styles['offer__column']} ${styles['offer__column-title']}`}>
                  Price,USD / Ціна, Долар США
                </div>
                <div className={`${styles['offer__column']} ${styles['offer__column-title']}`}>
                  Amount, USD / Загальна вартість, долар США
                </div>
                <div className={styles['offer__column']}>01</div>
                <div className={styles['offer__column']}>
                  Software Development/Розробка програмного забезпечення
                </div>
                <div className={styles['offer__column']}>1</div>
                <div className={styles['offer__column']}>{invoice.amount.toFixed(2)}</div>
                <div className={styles['offer__column']}>{invoice.amount.toFixed(2)}</div>
                <div className={styles['offer__column']}>{''}</div>
                <div className={styles['offer__column']}>{''}</div>
                <div className={styles['offer__column']}>{''}</div>
                <div className={styles['offer__column']}>
                  <b>Total / Усього:</b>
                </div>
                <div className={styles['offer__column']}>{invoice.amount.toFixed(2)}</div>
                <div style={{ gridColumn: '1/5' }} className={styles['offer__column']}>
                  Total to pay: {toWords.convert(Number(invoice.amount))} {invoice.currency}, 0
                  cents/ Усього до сплати: чотириста доларів США, 0 центів
                </div>
                <div className={styles['offer__column']}>{invoice.amount.toFixed(2)}</div>
              </div>
              <div className={styles['offer__statement']}>
                <div>
                  <p>
                    All charges of correspondent banks are at the Contractor’s expenses. / Усі
                    комісії банків-кореспондентів сплачує виконавець.
                  </p>
                  <p>
                    This Invoice is an offer to enter into the agreement. Payment according hereto
                    shall be deemed as an acceptation of the offer to enter into the agreement on
                    the terms and conditions set out herein. Payment according hereto may be made
                    not later than 31.08.2019./ Цей Інвойс є пропозицією укласти договір. Оплата за
                    цим Інвойсом є прийняттям пропозиції укласти договір на умовах, викладених в
                    цьому Інвойсі. Оплата за цим інвойсом може бути здійснена не пізніше 31.08.2019.
                  </p>
                  <p>
                    Please note, that payment according hereto at the same time is the evidence of
                    the work performance and the service delivery in full scope, acceptation thereof
                    and the confirmation of final mutual installments between Parties. / Оплата
                    згідно цього Інвойсу одночасно є свідченням виконання робіт та надання послуг в
                    повному обсязі, їх їх їх їх прийняття, а також підтвердженням кінцевих
                    розрахунків між Сторонами.
                  </p>
                  <p>
                    Payment according hereto shall be also the confirmation that Parties have no
                    claims each other and have no intention to submit any claims. The agreement
                    shall not include include include penalty and fine clauses. / Оплата згідно
                    цього Інвойсу є підтвердженням Сторони не мають взаємних претензій та не мають
                    наміру направляти рекламації. Договір не передбачає штрафних санкцій.
                  </p>
                  <p>
                    The Parties shall not be liable for non-performance or improper performance of
                    the obligations under the agreement during the term of insuperable force
                    circumstances. / Сторони звільняються від відповідальності за невиконання чи
                    неналежне виконання зобов’язань за договором на час дії форс-мажорних обставин.
                  </p>
                  <p>
                    Any disputes arising out of the agreement between the Parties shall be settled
                    by the competent court at the location of a defendant. / Всі спори, що виникнуть
                    між Сторонами по договору будуть розглядатись компетентним судом за
                    місцезнаходження відповідача.
                  </p>
                  <div className={styles['offer__contractor']}>
                    <b>Contractor/Виконавець:</b> <br />
                    <span className={styles['offer__contractor--name']}>
                      (Yenin Mykola Serhiiovych / {invoice.name})
                    </span>
                  </div>
                </div>
              </div>
              <img style={{ margin: '0 auto', display: 'block' }} src={logo} alt="logo" />
            </>
          )}
        </div>
        {!isHidden && (
          <div
            style={{ top: '153vh', paddingBottom: '40px' }}
            className={cls({
              [styles.container]: true,
              [styles.active]: true,
              [styles.hidden]: isHidden
            })}
          >
            <div style={{ marginTop: '40px' }} className={styles['offer__table']}>
              <div className={`${styles['offer__column']} ${styles['offer__column-title']}`}>№</div>
              <div className={`${styles['offer__column']} ${styles['offer__column-title']}`}>
                Description / Опис
              </div>
              <div className={`${styles['offer__column']} ${styles['offer__column-title']}`}>
                Quantity / Кількість
              </div>
              <div className={`${styles['offer__column']} ${styles['offer__column-title']}`}>
                Price,USD / Ціна, Долар США
              </div>
              <div className={`${styles['offer__column']} ${styles['offer__column-title']}`}>
                Amount, USD / Загальна вартість, долар США
              </div>
              <div className={styles['offer__column']}>01</div>
              <div className={styles['offer__column']}>
                Software Development/Розробка програмного забезпечення
              </div>
              <div className={styles['offer__column']}>1</div>
              <div className={styles['offer__column']}>{invoice.amount.toFixed(2)}</div>
              <div className={styles['offer__column']}>{invoice.amount.toFixed(2)}</div>
              <div className={styles['offer__column']}>{''}</div>
              <div className={styles['offer__column']}>{''}</div>
              <div className={styles['offer__column']}>{''}</div>
              <div className={styles['offer__column']}>
                <b>Total / Усього:</b>
              </div>
              <div className={styles['offer__column']}>{invoice.amount.toFixed(2)}</div>
              <div style={{ gridColumn: '1/5' }} className={styles['offer__column']}>
                Total to pay: {toWords.convert(Number(invoice.amount))} {invoice.currency}, 0 cents/
                Усього до сплати чотириста доларів США, 0 центів
              </div>
              <div className={styles['offer__column']}>{invoice.amount.toFixed(2)}</div>
            </div>
            <div className={styles['offer__statement']}>
              <div>
                <p>
                  All charges of correspondent banks are at the Contractor’s expenses. / Усі комісії
                  банків-кореспондентів сплачує виконавець.
                </p>
                <p>
                  This Invoice is an offer to enter into the agreement. Payment according hereto
                  shall be deemed as an acceptation of the offer to enter into the agreement on the
                  terms and conditions set out herein. Payment according hereto may be made not
                  later than 31.08.2019./ Цей Інвойс є пропозицією укласти договір. Оплата за цим
                  Інвойсом є прийняттям пропозиції укласти договір на умовах, викладених в цьому
                  Інвойсі. Оплата за цим інвойсом може бути здійснена не пізніше 31.08.2019.
                </p>
                <p>
                  Please note, that payment according hereto at the same time is the evidence of the
                  work performance and the service delivery in full scope, acceptation thereof and
                  the confirmation of final mutual installments between Parties. / Оплата згідно
                  цього Інвойсу одночасно є свідченням виконання робіт та надання послуг в повному
                  обсязі, їх їх їх їх прийняття, а також підтвердженням кінцевих розрахунків між
                  Сторонами.
                </p>
                <p>
                  Payment according hereto shall be also the confirmation that Parties have no
                  claims each other and have no intention to submit any claims. The agreement shall
                  not include include include penalty and fine clauses. / Оплата згідно цього
                  Інвойсу є підтвердженням Сторони не мають взаємних претензій та не мають наміру
                  направляти рекламації. Договір не передбачає штрафних санкцій.
                </p>
                <p>
                  The Parties shall not be liable for non-performance or improper performance of the
                  obligations under the agreement during the term of insuperable force
                  circumstances. / Сторони звільняються від відповідальності за невиконання чи
                  неналежне виконання зобов’язань за договором на час дії форс-мажорних обставин.
                </p>
                <p>
                  Any disputes arising out of the agreement between the Parties shall be settled by
                  the competent court at the location of a defendant. / Всі спори, що виникнуть між
                  Сторонами по договору будуть розглядатись компетентним судом за місцезнаходження
                  відповідача.
                </p>
                <div className={styles['offer__contractor']}>
                  <b>Contractor/Виконавець:</b> <br />
                  <span className={styles['offer__contractor--name']}>
                    (Yenin Mykola Serhiiovych / {invoice.name})
                  </span>
                </div>
              </div>
            </div>
            <img src={logo} alt="logo" />
          </div>
        )}
      </div>
    </div>
  )
})
export default Offer
