import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import styles from '../../Task.module.scss'
import { Icons } from 'components/Icons'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { isEmpty } from 'lodash'

export const ButtonCondition: any = ({
  taskInfo,
  currentUser,
  parsed,
  history,
  openModal,
  verifiedStatus,
  role,
  invoice,
  handleDownloadInvoice,
  handleAcceptTask,
  markExecuted,
  handleSignTask
}: any) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const [isHidden, setIsHidden] = useState(true)

  useEffect(() => {
    setIsHidden(checkIsBtnHidden())
  }, [taskInfo])

  const checkIsBtnHidden = () => {
    let result = false
    if (isEmpty(taskInfo)) {
      return true
    }

    if (currentUser.id === taskInfo.customer.id) {
      return true
    }

    taskInfo.taskResponses.forEach((response: any) => {
      if (response.authorId === currentUser.id) {
        result = true
      }
    })
    return result
  }

  return (
    <>
      {parsed.from === 'my-tasks' ? (
        <div style={{ display: 'flex' }}>
          <>
            {taskInfo.type !== '4' && invoice && (
              <BaseButton
                size="percent"
                type="button"
                outline={true}
                className={styles['light-btn']}
                style={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onClick={handleDownloadInvoice}
              >
                <Icons icon="invoces" className={styles['btn__svg']} />
                {words['user.subcontract.task.downloadInvoice']}
              </BaseButton>
            )}
            <BaseButton
              size="lgs"
              // disabled={parsed.from === 'admin'}
              children={words['user.subcontract.edit']}
              type="button"
              className={styles['light-btn']}
              outline={true}
              onClick={() =>
                history.push(
                  `/dashboard/subcontract/edittask?taskId=${parsed.taskId}&taskType=${
                    taskInfo.type
                  }`
                )
              }
            />
            {taskInfo && taskInfo.status && taskInfo.status.id === 9 && (
              <BaseButton
                children={words['user.subcontract.acceptTask']}
                onClick={handleAcceptTask}
                size="percent"
                className={styles['light-btn']}
                outline={true}
              />
            )}
            <BaseButton
              // disabled={parsed.from === 'admin'}
              children={words['user.subcontract.cancel']}
              size="percent"
              onClick={openModal}
              className={styles.btn}
            />
          </>
        </div>
      ) : parsed.from === 'my-answers' ? (
        <div>
          <BaseButton
            children={words['user.subcontract.cancel']}
            type="button"
            className={styles['light-btn']}
            onClick={openModal}
          />
          <BaseButton
            children={words['user.subcontract.reportImplementation']}
            onClick={markExecuted}
            size="percent"
            type="submit"
            className={styles.btn}
          />
        </div>
      ) : parsed.from === 'drafts' ? (
        <div>
          <BaseButton
            children={words['user.subcontract.cancel']}
            type="button"
            className={styles['light-btn']}
            onClick={openModal}
          />
          <BaseButton
            children={words['user.subcontract.signAndPublish']}
            size="percent"
            type="submit"
            className={styles['btn-sign']}
            onClick={handleSignTask}
          />
        </div>
      ) : role === 2 ? (
        <BaseButton
          children={words['admin.subcontract.violationCancel']}
          type="button"
          className={styles['btn-sign']}
          disabled={taskInfo.status ? taskInfo.status.id === 6 : false}
          onClick={openModal}
        />
      ) : (
        !isHidden && (
          <BaseButton
            children={verifiedStatus === 'true' ? words['user.subcontract.reply'] : 'Verification'}
            size="percent"
            className={styles.btn}
            onClick={
              verifiedStatus === 'true'
                ? () => history.push(`/dashboard/subcontract/task-request?taskId=${taskInfo.id}`)
                : () => history.push(`/dashboard/subcontract/verification`)
            }
          />
        )
      )}
    </>
  )
}
