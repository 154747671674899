import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import VacanciesComponent from './Vacancies'
import { getVacancies } from './Vacancies.thunk'
import { getProjectOptions, getStatusOptions } from './CreateVacancy/CreateVacancy.thunk'
import { getSelectOptions } from '../../client/EditProfile/EditProfile.thunx'
import { getVacancyRequest } from './Vacancies.actions'

export const mapStateToProps = (state: TState) => {
  return {
    vacanciesArray: state.admin.vacancies.vacanciesList,
    displayAllFields: state.client.filter.multilineFilterFields.status,
    managersData: state.client.newRequestTimeOff.managers,
    englishLevel: state.client.editProfile.englishLevel,
    statusOptions: state.admin.vacancies.statusOptions,
    test: '123'
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getVacancies,
      getStatusOptions,
      getProjectOptions,
      getSelectOptions,
      getVacancyRequest
    },
    dispatch
  )

export const Vacancies = connect(
  mapStateToProps,
  mapDispatchToProps
)(VacanciesComponent)
