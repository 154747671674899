import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import { DatePicker } from 'components/UiKit/DatePicker'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { config } from 'globalConfigs'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import { FORMAT_MMDDYYYY } from 'screens/client/NewRequestTimeOff/NewRequestTimeOff'
import { TState } from 'store'
import styles from './DateProject.module.scss'

const DateProject = ({ name, form, formState, validation, selected }: any) => {
  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)
  const words = useSelector((state: TState) => state.global.language.words)
  const [currentDate, setCurrentDate] = useState('')
  useEffect(() => {
    if (selected) {
      setCurrentDate(selected)
    } else {
      setCurrentDate('')
    }
  }, [selected])
  const moment = extendMoment(Moment)
  return (
    <Field
      name={name}
      validate={
        validation &&
        validation.composeValidators(validation.required(words['user.requiredMessage']))
      }
    >
      {({ input, meta }) => (
        <DatePicker
          name={name}
          locale={currentLanguage}
          isInvalid={meta.error && (meta.error && meta.submitFailed)}
          isNextYear={true}
          errorMessage={meta.error}
          className={cn({
            [styles.dateInvalid]: meta.error && (meta.error && meta.submitFailed)
          })}
          dateFormat={config.dateFormat}
          selected={currentDate ? new Date(currentDate) : null}
          onChange={(date: Date) => {
            const formatDate = date
              ? moment(date).format(FORMAT_MMDDYYYY)
              : moment().format(FORMAT_MMDDYYYY)
            form.change(input.name, formatDate)
            setCurrentDate(formatDate)
          }}
          endDate={new Date(formState.values.lastDay)}
          startDate={new Date(input.value)}
        />
      )}
    </Field>
  )
}
export default DateProject
