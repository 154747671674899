import React, { useEffect, useRef } from 'react'
import { Filter } from '../../UiKit/Filter'
import { getFilterConfig } from './CurrentTeam.filter.config'
import styles from '../../../screens/admin/Projects/components/Project/Project.module.scss'
import teamStyles from '../TeamArchive/TeamArchive.module.scss'
import queryString from 'query-string'
import history from '../../../utils/history'
import { useSelector } from 'react-redux'
import { TState } from '../../../store'
import { Columns } from './CurrentTeam.columns'
import { TeamTable } from '../../UiKit/Table'
import { useParams } from 'react-router'

const CurrentTeam = ({
  displayAllFields,
  rank,
  positions,
  typeOfEmployment,
  members,
  getProjectTeam
}: any) => {
  const parsed = queryString.parse(history.location.search)
  const words = useSelector((state: TState) => state.global.language.words)
  const columns = Columns(teamStyles['formatCell'], teamStyles['formatHeader'], positions, rank)
  const params: any = useParams()
  const teamRef: any = useRef(null)
  const scrollToBottom = () => {
    // eslint-disable-next-line prettier/prettier
    teamRef.current?.scrollIntoView()
  }
  useEffect(() => {
    getProjectTeam(params.id, `${location.search}`)
    scrollToBottom()
  }, [])
  return (
    <div ref={teamRef}>
      <div>
        <Filter
          config={getFilterConfig({
            displayAllFields,
            words,
            rank,
            positions,
            typeOfEmployment,
            inputCurrencyClass: styles['currency-input'],
            inputPercentClass: styles['percent-input'],
            inputTimeClass: styles['time-input'],
            currentPeriodFrom: parsed.periodFrom,
            currentPeriodTo: parsed.periodTo
          })}
          isMultilineBtn={true}
          withButton={false}
          defaultOpened={true}
        />
      </div>
      <div className={teamStyles['team-archive-table']}>
        <TeamTable
          total={0}
          data={members}
          noData={words['noData']}
          getData={() => ({})}
          columns={columns}
          classNameToRow="TeamItemRow"
          hiddenColumns={[]}
        />
      </div>
    </div>
  )
}

export default CurrentTeam
