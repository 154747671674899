import {
  DELETE_PROJECT_MEMBER,
  DELETE_SELECTED_MEMBER,
  GET_PROJECT_MEMBERS_SUCCESS,
  SET_ALL_SELECTED_MEMBERS,
  SET_SELECTED_MEMBERS
} from './CurrentTeam.constants'
import { IInitialState } from './CurrentTeam.model'
import { IAction } from 'models'

export const initialState: IInitialState = {
  members: [],
  selectedMembers: [],
  error: '',
  loading: false
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_PROJECT_MEMBERS_SUCCESS:
      return {
        ...state,
        members: action.payload
      }
    case SET_SELECTED_MEMBERS:
      return {
        ...state,
        selectedMembers: [...state.selectedMembers, action.payload]
      }
    case SET_ALL_SELECTED_MEMBERS:
      return {
        ...state,
        selectedMembers: action.payload
      }
    case DELETE_SELECTED_MEMBER:
      return {
        ...state,
        selectedMembers: state.selectedMembers.filter((member: any) => member.id !== action.payload)
      }
    case DELETE_PROJECT_MEMBER:
      return {
        ...state,
        members: state.members.filter((member: any) => member.id !== action.payload)
      }
    default:
      return state
  }
}
