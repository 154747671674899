import React from 'react'
import styles from './SingleTaskCancellation.module.scss'
import { Button } from 'components/UiKit/Button'
import { convertDate } from 'helpers/helpers'
import { History } from 'history'
import { Icons } from 'components/Icons'
import { ETaskStatus } from 'screens/client/Subcontract/components/CreateTask/CreateTask.model'
import { useSelector } from 'react-redux'
import { STATUS_OF_REQUESTS_TIME_OFF } from 'globalConfigs'
import { TState } from 'store'

interface ISingleTaskCancellationProps {
  task: any
  history: History
}

const SingleTaskCancellation = ({ task, history }: ISingleTaskCancellationProps) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const taskStatus = STATUS_OF_REQUESTS_TIME_OFF(words)[4]
  const handleClick = () =>
    history.push(`/dashboard/settings/task-cancellation/${task && task.id && task.id}`)
  return (
    <div className={styles['wrapper']}>
      <div className={styles['title-wrapper']}>
        <h3 className={styles['title']}>{task && task.title}</h3>
        {task.status.id === ETaskStatus.WITH_VIOLATION ? (
          <Button onClick={handleClick} className={styles['confirm-button']}>
            {words['admin.settings.subcontract.confirmCancellation']}
          </Button>
        ) : (
          <span className={styles['cancelled']}>{taskStatus}</span>
        )}
      </div>
      <div className={styles['description']}>
        {task.description.length < 420
          ? task.description
          : task.description.substring(0, 420) + '...'}
      </div>
      <div className={styles['info']}>
        <div className={styles['deadline']}>
          {task.deadline ? (
            <>
              <span className={styles['date-icon']}>
                <Icons icon="calendarTask" />
              </span>{' '}
              {words['user.subcontract.until']}
              <div className={styles['date']}>{convertDate(task.deadline)}</div>
            </>
          ) : null}
        </div>
        <div className={styles['price']}>
          {task.price} {task.token && task.token.symbol}
        </div>
      </div>
    </div>
  )
}
export default SingleTaskCancellation
