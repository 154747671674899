import React, { FC, forwardRef } from 'react'
import InvoiceComponent from '../Invoice/InvoiceComponent'
import styles from '../SubcontractModals/styles.module.scss'
import cls from 'classnames'

type TModalProps = {
  toggle: boolean
  action?: any
  invoice: any
  ref?: any
  isHidden?: boolean
}

const InvoiceModal: FC<TModalProps> = forwardRef(
  ({ invoice, toggle, action, isHidden }, ref: any) => {
    return (
      <div
        onClick={action}
        className={cls({ [styles.overlay]: toggle, [styles.hideModalToDownload]: isHidden })}
      >
        <div
          onClick={(e: any) => e.stopPropagation()}
          style={{ borderRadius: '0' }}
          className={cls({
            [styles.container]: true,
            [styles.active]: toggle,
            [styles.hideModalToDownload]: isHidden
          })}
        >
          <div
            style={{ padding: '0', paddingBottom: '119px' }}
            className={cls({ [styles.modal]: true, [styles[`modal-size-default}`]]: true })}
          >
            <InvoiceComponent
              ref={ref}
              project={invoice}
              invoice={invoice}
              toggle={true}
              isModal={true}
              type="project"
            />
          </div>
        </div>
      </div>
    )
  }
)

export default InvoiceModal
