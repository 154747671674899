export const enum ETransactionMessage {
  TRANSACTION_SUCCESS = 'TRANSACTION_SUCCESS',
  TRANSACTION_MESSAGE = 'TRANSACTION_MESSAGE',
  TRANSACTION_ERROR = 'TRANSACTION_ERROR',
  TRANSACTION_CONFIRM = 'TRANSACTION_CONFIRM'
}

export type TSpanProps = {
  size?: 'default' | 'button'
  isBackground?: boolean
  isTransaction?: boolean
}
