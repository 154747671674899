import { Dispatch } from 'redux'
import { API, api } from '../../../services/api'
import { AxiosError } from 'axios'
import toastr from '../../../utils/toastr'
import {
  getVacanciesSuccess,
  getVacancyRequest,
  getVacancySuccess,
  updateVacancySuccess
} from './Vacancies.actions'

export const getVacancies = (filter: any = '') => async (dispatch: Dispatch): Promise<void> => {
  try {
    const resp = await api.get(`${API.URL}/vacancies?${filter}`)
    dispatch(getVacanciesSuccess({ data: resp.data }))
  } catch (error) {
    const err = error as AxiosError<any>
    console.log(err)
    toastr('error', 'FAILED TO LOAD VACANCIES', err)
  }
}

export const getVacancy = (id: string) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const resp = await api.get(`${API.URL}/vacancies/${id}`)
    dispatch(getVacancySuccess({ data: resp.data }))
  } catch (error) {
    const err = error as AxiosError<any>
    toastr('error', 'FAILED TO LOAD VACANCY', err)
  }
}

export const updateVacancy = (id: string, vacancy: any, updatedVacancy: any) => async (
  dispatch: Dispatch
): Promise<void> => {
  dispatch(getVacancyRequest())
  try {
    await api.put(`${API.URL}/vacancies/${id}`, vacancy)
    dispatch(updateVacancySuccess(updatedVacancy))
  } catch (error) {
    const err = error as AxiosError<any>
    toastr('error', 'FAILED TO UPDATE VACANCY', err)
  }
}

export const getProjectVacancies = (id: string, filter: any = '') => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    const resp = await api.get(`${API.URL}/vacancies/project/${id}?${filter}`)
    dispatch(getVacanciesSuccess({ data: resp.data }))
  } catch (error) {
    const err = error as AxiosError<any>
    console.log(err)
    toastr('error', 'FAILED TO LOAD VACANCIES', err)
  }
}
